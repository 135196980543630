import {Dispatch} from "redux";

import {IAgent} from "../../agent/actions/fetch_agent_detail_at_route";
import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {IAgency} from "./fetch_agency_detail_at_route";

export const fetchAgencyAgentsTypes = createRequestActionTypes({view: "Agency", type: "GET", name: "AgencyAgents"});

export const fetchAgencyAgentsAtRoute =
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IAgency) => (dispatch: Dispatch) => {
        dispatch({type: fetchAgencyAgentsTypes.start});
        const url = apiLink.agencies.agents({})({agencyId: prevActionResult.id});

        return getRequest(services, url)
            .then((res: IAgent[]) => {
                dispatch({type: fetchAgencyAgentsTypes.success, result: res});
                return res;
            })
            .catch((err) => {
                dispatch({type: fetchAgencyAgentsTypes.error, err});
            });
    };
