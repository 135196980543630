import * as React from "react";
import {css, keyframes} from "@emotion/react";

interface IProps {
    size?: string;
    color?: string;
    margin?: string;
    speedMultiplier?: number;
    className?: string;
}

const grid = keyframes`
    0% {transform: scale(1)}
    50% {transform: scale(0.5); opacity: 0.7}
    100% {transform: scale(1);opacity: 1}
`;

const random = (top: number): number => Math.random() * top;

export const GridLoader = (props: IProps) => {
    return (
        <span css={wrapper(props)} className={props.className}>
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
            <span css={gridLoader(random(100), props)} />
        </span>
    );
};

GridLoader.defaultProps = {
    margin: "2px",
    speedMultiplier: 1,
    color: "#9069c0",
    size: "15px"
};

const wrapper = (props: IProps) => {
    return css`
        width: ${parsePixelsToNumber(props.size) * 3 + parsePixelsToNumber(props.margin) * 6}px;
        font-size: 0;
        display: flex;
        flex-wrap: wrap;
    `;
};

const gridLoader = (rand: number, props: IProps) => {
    const {speedMultiplier} = props;

    return css`
        display: inline-block;
        background-color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        margin: ${props.margin};
        border-radius: 100%;
        ${speedMultiplier &&
        css`
            animation: ${grid} ${(rand / 100 + 0.6) / speedMultiplier}s ${rand / 100 - 0.2}s infinite ease;
        `};
    `;
};

const parsePixelsToNumber = (pixelSize?: string) => {
    return pixelSize ? parseFloat(pixelSize.replace("px", "")) : 0;
};
