import {combineReducers} from "redux";

import {fetchLastSearchSuggestionsTypes} from "../../search/actions/fetch_last_search_suggestions";
import {ISearchSuggestions} from "../../search/actions/fetch_search_all_action";
import {ILastSearch, ILoadLocalStorageLastSearchToStore, SET_LOCAL_STORAGE_LAST_SEARCH} from "../actions/load_local_storage_last_search_to_store";
import {reduceRequestState, RequestState} from "../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../utils/request_response_utils/factories/reduce_response";

export interface ILastSearchStore {
    lastSearch: ILastSearch[];
    lastSearchSuggestions: ISearchSuggestions[];
    lastSearchSuggestionsRequest: RequestState;
}

export function lastSearchCustomReducer(state: string[] = [], action: ILoadLocalStorageLastSearchToStore) {
    switch (action.type) {
        case SET_LOCAL_STORAGE_LAST_SEARCH:
            return action.lastSearch;
        default:
            return state;
    }
}

export const lastSearchReducer = combineReducers({
    lastSearch: lastSearchCustomReducer,
    lastSearchSuggestions: reduceResponse(fetchLastSearchSuggestionsTypes),
    lastSearchSuggestionsRequest: reduceRequestState(fetchLastSearchSuggestionsTypes)
});
