import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const ChatCloudIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M5,10A4.55,4.55,0,0,1,3,9.5L.64,9.91A.52.52,0,0,1,.13,9.7.49.49,0,0,1,0,9.19l.56-2A4.74,4.74,0,0,1,.08,5,5,5,0,0,1,5,0a6.91,6.91,0,0,1,1,.1h0A5,5,0,0,1,5,10ZM3.1,8.73l.1,0A4.35,4.35,0,0,0,5,9.19,4.21,4.21,0,0,0,9.23,5,4.17,4.17,0,0,0,5.91.91h0A5.16,5.16,0,0,0,5,.81,4.21,4.21,0,0,0,.85,5a4,4,0,0,0,.51,2l0,.16-.56,2Z" />

            <path d="M7.24,4.69H3.3a.4.4,0,0,1-.41-.41.38.38,0,0,1,.41-.4H7.24a.41.41,0,1,1,0,.81Z" fill={props.secondaryFillColor} />

            <path d="M6.22,6.48H3.3a.4.4,0,0,1-.41-.41.38.38,0,0,1,.41-.41h3a.41.41,0,0,1,.41.41A.45.45,0,0,1,6.22,6.48Z" fill={props.secondaryFillColor} />
        </SvgIcon>
    );
};
