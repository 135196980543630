import {ISetViewTypeAction} from "../../../app/interfaces/IAction";
import {RequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";

export const reduceViewType =
    (requestTypes: RequestActionTypes) =>
    (state: string | null = null, action: ISetViewTypeAction): string | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.viewType;
            default:
                return state;
        }
    };
