import {matchPath} from "react-router";
import {filter, map, uniq} from "lodash";
import {appPath} from "@web2/gh_routes";

import {IBreadcrumb} from "../../../app/utils/breadcrumbs";
import {OfferDealType} from "../../../offer/utils/constants_offer";
import {searchViewAlgolytics} from "../init_algolytics";
import {getTrackedDesktopSiteData, getTrackedMobileSiteData} from "../tracked_site";

export interface ISearchPageViewHitData {
    breadcrumbs: IBreadcrumb[];
    currentMapBounds: {
        geoBoxBottomLeft: string | null;
        geoBoxTopRight: string | null;
    };
    searchParams: {
        investment?: string;
        deal_type?: OfferDealType;
        location?: string;
        size__gte?: string;
        size__lte?: string;
        price__lte?: string;
        price__gte?: string;
        rooms?: string;
        sort?: string;
        offer_type: string;
        distance?: string;
        name?: string;
    };
    collectionCount: number;
    isMobile: boolean | null;
    selectedLocation: string;
}

export const searchPageViewHit = (searchData: ISearchPageViewHitData) => {
    const {searchParams, collectionCount, isMobile, selectedLocation, currentMapBounds, breadcrumbs} = searchData;

    const parseBreadCrumbsToLocation = () => {
        //Only non invasive way of getting address string is to parse breadcrumbs. However, we need to get rid of non-address parts of them.
        const pathMatch = appPath.searchResult.universal;
        const addressElementsArray = filter(breadcrumbs, (b) => matchPath(b.link, {exact: true, strict: true, path: pathMatch})) as {
            label: string;
            link: string;
        }[];
        const addressArray = map(addressElementsArray, (aE) => aE.label);

        return addressArray.join(", ") || null;
    };

    const parseSelectedLocation = () => {
        if (searchParams.investment) {
            return {search_investment_name: selectedLocation, search_location_address: null};
        }

        return {search_investment_name: null, search_location_address: selectedLocation};
    };

    const parsePropertyType = (offerType: string) => {
        if (offerType) {
            const offerTypeArray = offerType.split(",");

            return {
                market_type: searchParams.deal_type === "rent" ? "aftermarket" : uniq(filter(map([...offerTypeArray], (o) => o.split("__")[0]))),
                property_type: uniq(filter(map([...offerTypeArray], (o) => o.split("__")[1])))
            };
        }

        return {market_type: null, property_type: null};
    };

    const parseRooms = (rooms?: string) => {
        if (rooms) {
            return {
                rooms_lower: rooms.split(",")[0],
                rooms_upper: rooms.slice(-1)[0]
            };
        }

        return {
            rooms_lower: null,
            rooms_upper: null
        };
    };

    const {rooms_lower, rooms_upper} = parseRooms(searchParams.rooms);
    const {market_type, property_type} = parsePropertyType(searchParams.offer_type);
    const {search_investment_name, search_location_address} = parseSelectedLocation();
    const search_location = parseBreadCrumbsToLocation();

    const readySearchData = {
        map_bounds: currentMapBounds,
        search_area_lower: searchParams.size__gte,
        search_area_upper: searchParams.size__lte,
        search_deal_type: searchParams.deal_type,
        search_distance: searchParams.distance || null,
        search_google_place: searchParams.name,
        search_investment_id: searchParams.investment,
        search_investment_name,
        search_location,
        search_location_address,
        search_location_id: searchParams.location,
        search_market_type: market_type,
        search_offers_count: collectionCount,
        search_price_lower: searchParams.price__gte,
        search_price_upper: searchParams.price__lte,
        search_property_market_type: searchParams.offer_type,
        search_property_type: property_type,
        search_rooms_lower: rooms_lower,
        search_rooms_upper: rooms_upper,
        search_sort: searchParams.sort,
        view_type: "offer_list"
    };

    const data = {
        ...(isMobile ? getTrackedMobileSiteData() : getTrackedDesktopSiteData()),
        ...readySearchData
    };
    searchViewAlgolytics(data);
};
