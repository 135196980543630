import {css} from "@linaria/core";

import {getThemeBreakpoint, getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";

export const mobileFilterHolder = css`
    position: relative;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: unset;
        margin-right: 1rem;
        margin-bottom: 1rem;
        flex: 1;
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        margin-bottom: 0 !important;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const filterHolder = css`
    position: relative;
    display: none;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: unset;
        flex: 1;
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        max-width: 250px;
        min-width: 137px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const customFilterHolder = css`
    position: relative;
    display: none;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: block;
        width: calc(20% - 10px);
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: auto;
        max-width: 172px;
    }

    @media (min-width: 1440px) {
        max-width: 200px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const filterLabelText = css`
    padding-right: 1rem;
    display: flex;
    align-items: center;
    line-height: 1.2;

    span {
        padding-left: 0.5rem;
    }
`;

export const dropdownList = css`
    list-style: none;
    padding: 0.8rem 0;
    margin: 0;
`;

export const dropdownListElement = css`
    transition:
        background-color 100ms ease-out 0s,
        opacity 100ms ease-out 0s;
    color: ${getThemeVariable("colors-gray_darker")};
    line-height: 1.35;
    padding: 0;
    white-space: nowrap;

    &:hover {
        background-color: ${getThemeVariable("colors-gray_very_bright")};
    }
`;

export const resultsList = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const searchLoaderHolder = css`
    width: 100%;
    height: 170px;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
`;

export const filterTitle = css`
    margin: 5px 0;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5833333333;
`;
