import {Dispatch} from "redux";
import {capitalize, isEmpty} from "@web2/nodash";
import {safelyParsePage} from "@web2/string_utils";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {ghCommonUrl} from "../../../app/utils/read_environment_variables";
import {updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {generatePrevNextMetaLinks} from "../../../meta_data/utils/generate_prev_next_links";
import {UDealTypesPL} from "../../utils/constants_offer";
import {locationNameParser, locationNameWhereParser} from "../../utils/location_name_parser";
import {getFeaturesListFromParam, getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {UrlTagSlug} from "../url_utils/tags";
import {getOfferListDescription} from "../utils/get_offer_list_description";
import {getOfferListTitle} from "../utils/get_offer_list_title";

export const DEFAULT_SELECTED_LOCATION = "Cała Polska";

export const updateOfferListMetaData =
    (
        _1: Partial<IServices>,
        route: IRouteState<{
            offerType: string;
            type?: UDealTypesPL;
            tag?: string;
        }>
    ) =>
    (dispatch: Dispatch, getState: () => IStore) => {
        const state = getState();
        const offerTypePL = capitalize(convertUrlOfferTypeToPL(route.params.offerType));
        const page = safelyParsePage(route.query.page);
        const baseUrl = `${ghCommonUrl}${route.pathname}`;
        const currentLocationName = getCurrentLocationName(state.offerList);
        const currentLocationNameWhere = getCurrentLocationNameWhere(state.offerList);
        const dealType = getDealType(route.params);

        const title = getOfferListTitle({
            offersCount: state.offerList.offers.meta?.total_offers_not_collapsed || 0,
            currentLocationName,
            dealType,
            offerType: route.params.offerType,
            offerTypePL,
            page,
            tag: route.params.tag
        });

        const description = getOfferListDescription({
            offersCount: state.offerList.offers.meta?.total_offers_not_collapsed || 0,
            currentLocationName,
            currentLocationNameWhere,
            dealType,
            offerTypePL,
            page,
            tag: route.params.tag
        });

        const {relNext, relPrev} = generatePrevNextMetaLinks(page, state.offerList.offers.pageCount, baseUrl, {});
        const urlWithPage = appendQueryString(baseUrl, {page});

        const hasGeoBoxParam = !!route.query.geo_box_bottom_left;

        const canonical = page === 1 ? baseUrl : urlWithPage;
        const robots = (() => {
            const isVendorListing = !!route.query.agency || !!route.query.developer || !!route.query.agent;
            const isInvestment = !!route.query.investment;
            const offersLength = state.offerList.offers.offers.length;

            const foundTag = getUrlTagDataBySlug(route.params.tag);
            const hasMarketTag = foundTag && foundTag.tag === UrlTagSlug.PRIMARY_MARKET;
            const {page: pageParam, market: marketParam, ...otherParamsThanMarketAndPage} = route.query;
            const hasOtherParams = !isEmpty(otherParamsThanMarketAndPage);

            const isPageIndexable = safelyParsePage(pageParam) < 7;
            const firstPage = safelyParsePage(pageParam) === 1;
            const hasSeoDescription = !!state.offerList.seoDescription;

            const features = getFeaturesListFromParam(route.query.features);

            const hasMultipleParams = Object.keys(route.query).length > 1 || (features && features.length > 1);

            switch (true) {
                // exceptions first
                case isVendorListing:
                case isInvestment:
                    return "noindex, nofollow";
                case (hasMarketTag || marketParam) && hasOtherParams:
                case hasGeoBoxParam:
                    return "noindex, follow";

                // regular listings
                case hasMultipleParams:
                    return "noindex, follow";
                case offersLength === 0 && hasSeoDescription && firstPage:
                case offersLength > 0 && isPageIndexable:
                    return "index, follow";
                default:
                    return "noindex, follow";
            }
        })();

        dispatch(
            updateMetaData(title, description, route, {
                canonical: hasGeoBoxParam ? undefined : canonical,
                robots,
                relNext,
                relPrev
            })
        );
        return Promise.resolve(true);
    };

/*
 * helpers
 */

const convertUrlOfferTypeToPL = (oT: string) => {
    switch (oT) {
        case "dzialki":
            return "działki";
        case "mieszkania":
            return "mieszkania";
        case "domy":
            return "domy";
        case "nieruchomosci":
        default:
            return "nieruchomości";
    }
};

const getDealType = (params: {type?: string; offerType: string}) => {
    if (params.type === "do-wynajecia") {
        return `do wynajęcia`;
    }
    if (params.type === "na-sprzedaz") {
        return "na sprzedaż";
    }
    if (params.offerType === "dzialki") {
        return "na sprzedaż";
    }
    return "";
};

interface IGetCurrentLocationOfferList {
    latestQuery: {geo_point?: string; search_name?: string; investment?: string; geo_box_bottom_left?: string; geo_box_top_right?: string};
    investment: {investment: {name: string} | null};
    location: {
        location: {
            path: {
                name: string;
                slug: string;
                type: string;
            }[];
            short_name: string;
            name_declension_where: string;
        } | null;
    };
}

const getCurrentLocationName = (offerList: IGetCurrentLocationOfferList) => {
    if (offerList.latestQuery.geo_point && offerList.latestQuery.search_name) {
        return offerList.latestQuery.search_name;
    }
    if (offerList.latestQuery.investment && offerList.investment.investment) {
        return offerList.investment.investment.name;
    }
    if (offerList.location.location) {
        return locationNameParser(offerList.location.location.path);
    }
    if (offerList.latestQuery.geo_box_bottom_left || offerList.latestQuery.geo_box_top_right) {
        return "";
    }
    return DEFAULT_SELECTED_LOCATION;
};

const getCurrentLocationNameWhere = (offerList: IGetCurrentLocationOfferList) => {
    if (offerList.latestQuery.geo_point && offerList.latestQuery.search_name) {
        return `w ${offerList.latestQuery.search_name}`;
    }
    if (offerList.latestQuery.investment && offerList.investment.investment) {
        return `w inwestycji ${offerList.investment.investment.name}`;
    }
    if (offerList.location.location) {
        return locationNameWhereParser(offerList.location.location.path, offerList.location.location.name_declension_where);
    }
    return "";
};
