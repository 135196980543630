import {matchPath} from "react-router";
import {appPath, IFullOfferDetailParams} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";

export const validateOfferDetailFetchAtRoute =
    (services: Partial<IServices>, route: IRouteState<IFullOfferDetailParams>, prevRoute: IPrevRouteState | null) => async (): Promise<boolean> => {
        // prevent refetching offer data
        const prevRouteMatch = prevRoute ? matchPath<Record<string, string>>(prevRoute.pathname, {path: appPath.offer.detail.base}) : null;
        const wasDataFetched = route.params.offerId === prevRouteMatch?.params.offerId;
        return !wasDataFetched;
    };
