import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css} from "@linaria/core";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";
import {pluralize} from "@web2/string_utils";

import {ISimilarOffersFetch} from "../../application/actions/fetch_multilead_offers";
import {UserData} from "../../application/components/Application";
import {ApplicationDirectSubmitCTA} from "../../application/components/ApplicationDirectSubmitCTA";
import {ApplicationModal} from "../../application/components/ApplicationModal";
import {ApplicationSourceSection, getOfferApplicationSource} from "../../application/utils/ApplicationSource";
import {TrackApplicationPayload} from "../../application/utils/track_application";
import {
    handleOfferBoxMetaClick,
    IOfferBoxOffer,
    OfferBox,
    OFFERBOX_MAX_MOBILE_BREAKPOINT,
    OFFERBOX_WIDTH
} from "../../offer/detail/components/offer_box/OfferBox";
import {FullOfferApplicationModalHeader} from "../../offer/utils/constants_offer";
import {getThemeBreakpoint, getThemeVariable} from "../../styles/linaria_variable_factory";
import {AlgolyticsSourceSection} from "../../tracking/algolytics/interaction/application_sent_hit";
import {gtmMultileadView} from "../../tracking/google_tag_manager/gtm_multilead";
import {gtmSimilarOfferClick} from "../../tracking/google_tag_manager/gtm_similar_offer_click";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {setFavouriteOffer} from "../actions/load_local_storage_favourites_to_store";
import {Paragraph} from "../atoms/Paragraph";
import {IStore} from "../reducers/hybrid_reducer";
import {TermsRodo} from "./TermsRodo";

interface IProps {
    closeModal: () => void;
    offerId: string | undefined;
    similarOffers: ISimilarOffersFetch[];
    applicationUserData?: UserData;
    applicationTrackingPayload?: TrackApplicationPayload;
    inactiveOffer?: boolean;
}

export enum LeadType {
    STANDARD,
    MULTILEAD
}

const propertyPlural = pluralize(["nieruchomość", "nieruchomości", "nieruchomości"]);
const matchingPlural = pluralize(["dopasowaną", "dopasowane", "dopasowane"]);

export const ModalSimilarOffers = (props: IProps) => {
    const dispatch = useDispatch();
    const favouriteOffers = useSelector((store: IStore) => store.favourites.favourites);
    const {inactiveOffer, similarOffers} = props;

    const [applicationOfferData, setApplicationOfferData] = useState<{
        offer: IOfferBoxOffer;
        gtmSource: GtmSource;
    } | null>(null);

    useEffect(() => {
        gtmMultileadView();
    }, []);

    const closeAskForPriceModal = () => setApplicationOfferData(null);

    const renderSimilarOffers = useCallback(
        (off: ISimilarOffersFetch) => {
            const isFavourite = favouriteOffers?.includes(off.id);
            const offerUrlParams = parseOfferSlugToObject(off.slug);

            const onOfferBoxClick = (e: React.MouseEvent<HTMLElement>) => {
                gtmSimilarOfferClick();

                handleOfferBoxMetaClick(e, () => window.open(appLink.fullOffer.detail.base(offerUrlParams), "_blank"));
            };

            return (
                <div className={offerBoxWrapper} key={off.id} onClick={onOfferBoxClick}>
                    <OfferBox
                        isFavourite={isFavourite}
                        isInvestment={!!off.investment}
                        isVisited={false}
                        offer={off}
                        openApplicationModal={setApplicationOfferData}
                        setIsFavourite={() => {
                            dispatch(setFavouriteOffer(off.id));
                        }}
                        className={panelOfferBox}
                        renderCTA={
                            inactiveOffer ? null : (
                                <ApplicationDirectSubmitCTA
                                    off={off}
                                    applicationUserData={props.applicationUserData}
                                    applicationTrackingPayload={props.applicationTrackingPayload}
                                />
                            )
                        }
                    />
                    <p className={termsParagraph}>
                        <TermsRodo isPrivate={off.is_private} offerId={props.offerId} vendorName={off.agency?.name} type={off.agency?.type} />
                    </p>
                </div>
            );
        },
        [favouriteOffers, setFavouriteOffer]
    );

    const getSimilarPropertiesText = () => {
        if (!props.similarOffers) {
            return;
        }
        const offerCounterNumber = props.similarOffers.length;
        const offerCounterToString = offerCounterNumber == 1 ? "jedną" : offerCounterNumber == 2 ? "dwie" : "trzy";

        return `Znaleźliśmy ${offerCounterToString} ${propertyPlural(offerCounterNumber)} ${matchingPlural(offerCounterNumber)} do Twoich oczekiwań.`;
    };

    const inactiveOfferHeading = "Niestety, ta oferta jest już nieaktualna";
    const inactiveOfferSubHeading = "Sprawdź inne oferty w okolicy dopasowane do Twoich oczekiwań";
    const hasSimilarOffers = similarOffers.length > 0;

    return (
        <div className={modalContent}>
            <Paragraph className={title}>{!inactiveOffer ? "Zapytaj o podobne oferty w okolicy" : inactiveOfferHeading}</Paragraph>
            {hasSimilarOffers && <Paragraph className={subtitle}>{!inactiveOffer ? getSimilarPropertiesText() : inactiveOfferSubHeading}</Paragraph>}
            <div className={offersList}>
                {props.similarOffers.map((offer: ISimilarOffersFetch) => {
                    return <React.Fragment key={offer.id}>{renderSimilarOffers(offer)}</React.Fragment>;
                })}
            </div>

            {applicationOfferData && (
                <ApplicationModal
                    modalHeader={FullOfferApplicationModalHeader.ASK_DEVELOPER_PRICE}
                    modalState={!!applicationOfferData}
                    onModalClose={closeAskForPriceModal}
                    applicationSource={getOfferApplicationSource(applicationOfferData.offer.market_type, true)}
                    applicationSourceSection={ApplicationSourceSection.MODAL}
                    algolyticsSourceSection={AlgolyticsSourceSection.BUTTON}
                    gtmSource={applicationOfferData.gtmSource}
                    viewType={ViewType.SIMILAR_OFFER_MODAL}
                    offerId={applicationOfferData.offer.id}
                    leadType={LeadType.MULTILEAD}
                />
            )}
        </div>
    );
};

const offersList = css`
    min-height: 310px;
    display: flex;
    align-items: start;
    width: 100%;
    overflow-x: auto;
    margin: 2.5rem auto 3rem auto;
    flex-direction: column;

    & > div {
        margin-bottom: 1.5rem;
    }

    @media (min-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        flex-direction: row;
        width: auto;
    }
`;
const modalContent = css`
    width: auto;
    max-height: 80rem;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        min-width: 81rem;
        height: 100%;
    }
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
`;
const title = css`
    color: ${getThemeVariable("colors-brand_primary")};
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 4.8rem;
    text-align: center;
    margin: 1rem 0;
`;
const subtitle = css`
    font-size: ${getThemeVariable("fonts-font_size_big")};
    line-height: 2.4rem;
    font-weight: 500;
    text-align: center;
`;

const panelOfferBox = css`
    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        border-radius: ${getThemeVariable("other-border_radius")};
        border-width: 1px;
    }
`;
const offerBoxWrapper = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const termsParagraph = css`
    display: flex;
    flex-grow: 0;
    font-size: 0.8rem;
    color: ${getThemeVariable("colors-gray_darker")};
    max-width: ${OFFERBOX_WIDTH}px;
    padding: 0 1rem 1rem 1rem;
`;
