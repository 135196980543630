import {AnyAction} from "redux";

import {setOfferModalStateActionTypes} from "../actions/toggle_offer_modal_at_route";

export const offerModalStateReducer = (state = false, action: AnyAction): boolean => {
    switch (action.type) {
        case setOfferModalStateActionTypes.open:
            return true;
        case setOfferModalStateActionTypes.close:
            return false;
        default:
            return state;
    }
};
