import {pluralize} from "@web2/string_utils";

import {
    availableDeclinations,
    beDeclinations,
    flatsDeclinations,
    housesDeclinations,
    investmentsDeclinations,
    newDeclinations,
    offersDeclinations,
    offersDeclinationsWhich,
    roomsDeclinations,
    studioDeclinations
} from "../dictionaries/declinations";

export const returnPlurizedValues = (value: number) => {
    return {
        availablePluralize: pluralize(availableDeclinations)(value),
        bePluralize: pluralize(beDeclinations)(value),
        offersPluralize: pluralize(offersDeclinations)(value),
        offersPluralizeWhich: pluralize(offersDeclinationsWhich)(value),
        flatsPluralize: pluralize(flatsDeclinations)(value),
        studioPluralize: pluralize(studioDeclinations)(value),
        housesPluralize: pluralize(housesDeclinations)(value),
        investmentsPluralize: pluralize(investmentsDeclinations)(value),
        newPluralize: pluralize(newDeclinations)(value),
        roomsPluralize: pluralize(roomsDeclinations)(value)
    };
};
