import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const DocumentIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M8.32,9.86H1.68a.82.82,0,0,1-.81-.81V1A.82.82,0,0,1,1.68.14H6.62L9.13,2.65v6.4A.82.82,0,0,1,8.32,9.86ZM1.68.75a.19.19,0,0,0-.2.2v8.1a.19.19,0,0,0,.2.2H8.32a.19.19,0,0,0,.2-.2V2.9L6.38.75Z" />

            <path d="M7,3.71H2.33A.32.32,0,0,1,2,3.38a.32.32,0,0,1,.32-.32H7a.32.32,0,0,1,.33.32A.37.37,0,0,1,7,3.71Z" fill={props.secondaryFillColor} />

            <path d="M7,5.12H2.33A.32.32,0,0,1,2,4.8a.3.3,0,0,1,.32-.33H7a.33.33,0,0,1,.33.33A.36.36,0,0,1,7,5.12Z" fill={props.secondaryFillColor} />

            <path d="M6.09,6.66H2.33A.32.32,0,0,1,2,6.34.3.3,0,0,1,2.33,6H6.09a.33.33,0,1,1,0,.65Z" fill={props.secondaryFillColor} />

            <path d="M5,8.08H2.33A.32.32,0,0,1,2,7.75a.29.29,0,0,1,.32-.32H5a.32.32,0,0,1,.32.32A.36.36,0,0,1,5,8.08Z" fill={props.secondaryFillColor} />

            <polygon points="6.62 0.14 9.13 2.65 9.13 2.9 6.38 2.9 6.38 0.14 6.62 0.14" fill={props.secondaryFillColor} />
        </SvgIcon>
    );
};
