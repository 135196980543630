import {matchPath} from "react-router";
import {appPath} from "@web2/gh_routes";
import {getIsMobile} from "@web2/react_utils";

import {showPhoneNumberAlgolytics} from "../init_algolytics";
import {getTrackedDesktopSiteData, getTrackedMobileSiteData} from "../tracked_site";

interface IAlgolyticsShowPhoneHitData {
    id?: string;
    agency?: {
        id: string;
        name: string;
    };
    agent?: {
        id: string;
        name: string;
        last_name?: string;
    };
    investment?: {
        id: string;
        name: string;
    } | null;
}

export enum PHONE_NUMBER_INTERACTION_TYPE {
    CALL = "call_phone",
    REVEAL = "reveal_number"
}

export const showPhoneNumberAlgolyticsHit = (
    currentPath: string,
    offerData: IAlgolyticsShowPhoneHitData,
    action_type: PHONE_NUMBER_INTERACTION_TYPE,
    isModal = false
) => {
    if (!offerData.id) {
        return;
    }

    const viewType = (() => {
        const matchApplicationPath = (path: string) => matchPath(currentPath, {exact: true, strict: true, path});
        if (isModal) {
            return "modal";
        }
        if (matchApplicationPath(appPath.fullOffer.detail.base)) {
            return "full_offer";
        }

        if (matchApplicationPath(appPath.agency.detail)) {
            return "agency";
        }
        if (matchApplicationPath(appPath.developer.detail)) {
            return "developer";
        }
        if (matchApplicationPath(appPath.investment.detail)) {
            return "investment";
        }
        if (matchApplicationPath(appPath.searchResult.universal) || matchApplicationPath(appPath.searchResult.universalTag)) {
            return "offer_list";
        }

        return null;
    })();

    function agentFullname(agent?: {name: string; last_name?: string}): string {
        return agent ? `${agent.name} ${agent.last_name}` : "";
    }

    const hitData = {
        offer_id: offerData.id,
        agency_id: offerData.agency ? offerData.agency.id : null,
        agency_name: offerData.agency ? offerData.agency.name : null,
        agent_id: offerData.agent ? offerData.agent.id : null,
        agent_name: offerData.agent ? agentFullname(offerData.agent) : null,
        investment_id: offerData.investment ? offerData.investment.id : null,
        investment_name: offerData.investment ? offerData.investment.name : null
    };

    const data = {
        ...(getIsMobile() ? getTrackedMobileSiteData() : getTrackedDesktopSiteData()),
        ...hitData,
        view_type: viewType,
        action_type
    };
    showPhoneNumberAlgolytics(data);
};
