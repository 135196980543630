import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const PlanIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "18.83"} height={props.height || "21"} viewBox="0 0 18.83 21">
            <polygon
                points="0 0 0 17 10.33 17 10.33 16 8 16 8 14.67 7 14.67 7 16 1 16 1 8 7 8 7 10.67 8 10.67 8 10 12.25 10 12.25 9 8 9 8 3.42 7 3.42 7 7 1 7 1 1 17.83 1 17.83 9 15.17 9 15.17 7.67 14.17 7.67 14.17 10 17.83 10 17.83 20 13.5 20 13.5 16.67 12.5 16.67 12.5 21 18.83 21 18.83 0 0 0"
                fill={props.mainColor || "#1d1d1b"}
            />
        </svg>
    );
};
