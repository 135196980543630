import {Dispatch} from "redux";
import {appLink, IArticleDetailAndListParams} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IArticleDetailResponse} from "./fetch_article_detail";

export const updateArticleDetailMetaData =
    (
        _1: Partial<IServices>,
        route: IRouteState<IArticleDetailAndListParams>,
        prevRoute: IPrevRouteState | null,
        prevActionResult: {article: IArticleDetailResponse}
    ) =>
    (dispatch: Dispatch) => {
        // ugly exception as per CU-2jfeh52
        const lang = prevActionResult.article.id === "7f29dba1-c595-44f7-8087-14a24b9dd225" ? "uk" : "pl";

        const url = `${ghCommonUrl}${appLink.article.detailOrCategoryList({slug: route.params.slug})}`;

        const title = `${prevActionResult.article.title}`;
        const description = prevActionResult.article.lead;
        const twoHours = 7200 * 1000; // variable used to convert Date.now() to CET
        const robots = `${Date.parse(prevActionResult.article.publication_date) > Date.now() + twoHours ? "noindex" : "index"}, follow`;
        const maxImagePreview = "large";

        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                hideSuffix: false,
                image: prevActionResult.article.image.a_img_1210x420,
                lang,
                maxImagePreview
            })
        );

        return Promise.resolve(true);
    };
