import * as React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTheme} from "@emotion/react";
import includes from "@bugsnag/core/lib/es-utils/includes";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {BounceLoader} from "@web2/ui_utils";

import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {IOfferBoxOffer, IOnOfferBoxModalOpen, OfferBox} from "../../detail/components/offer_box/OfferBox";

interface IStateProps {
    favourites: string[];
}

interface IOwnProps {
    offerId: string;
    visitedOffers: string[];
    setFavouriteOffer: typeof setFavouriteOffer;
    openApplicationModal: IOnOfferBoxModalOpen;
    onOfferClick: (e: React.MouseEvent<HTMLElement>, offer: {slug: string}) => void;
}

interface IProps extends IStateProps, IOwnProps {}

const OfferListMapInfoWindowC = (props: IProps) => {
    const [offer, setOffer] = useState<IOfferBoxOffer | null>(null);
    const [isMobile, setMobile] = useState(false);
    const theme = useTheme();
    const desktop = theme.breakpoints.numeric.screen_md || 1024;

    useEffect(() => {
        setMobile(window.innerWidth < desktop);
    });

    useEffect(() => {
        const url = apiLink.offerList.base({})(null);
        getRequest({}, appendQueryString(url, {ids: props.offerId})).then((res: {data: IOfferBoxOffer[]}) => setOffer(res.data[0]));
    }, []);

    const isVisited = includes(props.visitedOffers, props.offerId);
    const isFavourite = includes(props.favourites, props.offerId);

    if (!offer) {
        return (
            <div className={loaderHolder}>
                <BounceLoader color="#9069c0" />
                <div className={infoWindowTooltipArrow} />
            </div>
        );
    }
    return (
        <>
            <div onClick={isMobile ? () => null : (e) => props.onOfferClick(e, offer)}>
                <MapOfferBox
                    offer={offer}
                    isVisited={isVisited}
                    isFavourite={isFavourite}
                    setIsFavourite={props.setFavouriteOffer}
                    openApplicationModal={props.openApplicationModal}
                    isInvestment={!!offer.investment}
                    forceSliderInit
                />
            </div>

            <div className={infoWindowTooltipArrow} />
        </>
    );
};

function mapStateToProps(state: IStore) {
    return {
        favourites: state.favourites.favourites
    };
}

export const OfferListMapInfoWindow = connect(mapStateToProps)(OfferListMapInfoWindowC);

const loaderHolder = css`
    width: 306px;
    height: 289px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 21px 2px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.4) 0 2px 21px 0;
    }
`;

const MapOfferBox = styled(OfferBox)`
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 21px 2px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.4) 0 2px 21px 0;
    }
`;

const infoWindowTooltipArrow = css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    bottom: 6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: white transparent transparent;
    border-radius: 3px;
    z-index: 1;
`;
