import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const TreesIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M7.1.35,9.33,6.8a.39.39,0,0,1-.23.49s-.07,0-.11,0H4.5A.35.35,0,0,1,4.12,7a.15.15,0,0,1,0-.12L6.38.39A.4.4,0,0,1,6.87.16.24.24,0,0,1,7.1.35Z"
                fill={props.secondaryFillColor}
            />

            <path d="M5.78,5.79A2.57,2.57,0,0,0,3.21,3.22a2.55,2.55,0,0,0-.3,5.09V9.56a.3.3,0,0,0,.3.3.28.28,0,0,0,.31-.3V8.31A2.53,2.53,0,0,0,5.78,5.79Zm-2.26,2V6.09a.3.3,0,0,0-.31-.3.27.27,0,0,0-.26.3V7.75a2,2,0,0,1-1.7-2,2,2,0,0,1,2-2,2,2,0,0,1,2,2A2,2,0,0,1,3.52,7.75Z" />

            <path d="M6.65,9.86a.29.29,0,0,1-.3-.3V5.41a.29.29,0,0,1,.3-.3.27.27,0,0,1,.3.3V9.56C6.91,9.71,6.8,9.86,6.65,9.86Z" />
        </SvgIcon>
    );
};
