import * as React from "react";
import {css, Theme} from "@emotion/react";
import {appLink} from "@web2/gh_routes";

interface Props {
    className?: string;
    url?: string;
}

export const AcceptTermsLabel = (props: Props) => {
    const {url = appLink.terms(), className} = props;

    return (
        <div css={termsStyles} className={className}>
            Akceptuję{" "}
            <a href={url} target="_blank" rel="noopener nofollow" css={linkStyles}>
                regulamin serwisu
            </a>
        </div>
    );
};

const termsStyles = (theme: Theme) => css`
    color: ${theme.colors.gray};
    text-align: center;
    font-size: 1rem;
`;

const linkStyles = (theme: Theme) => css`
    color: ${theme?.colors.blue};
    text-decoration: underline;
`;
