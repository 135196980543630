import React from "react";
import {css, cx} from "@linaria/core";
import {isUndefined} from "lodash";

import {getThemeBreakpoint, getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IProps {
    className?: string;
    count?: string | number;
    name: string;
    title?: string;
    url: string;
}

export const OfferListSeoLinkListItem = (props: IProps) => {
    return (
        <li className={cx(listItem, props.className)}>
            <a className={link} href={props.url} title={props.title || props.name}>
                {props.name}
                {!isUndefined(props.count) && <span> ({props.count})</span>}
            </a>
        </li>
    );
};

const listItem = css`
    margin: 0.7rem 0;
    padding-right: 2rem;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        flex-basis: 25%;
        padding-right: 0.6rem;
    }

    span {
        color: ${getThemeVariable("colors-gray_brighter")};
    }
`;

const link = css`
    color: ${getThemeVariable("colors-gray_darker")};
    text-decoration: none;

    &:hover,
    &:focus {
        color: ${getThemeVariable("colors-gray_darker")};
        text-decoration: underline;
    }
`;
