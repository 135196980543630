import React from "react";
import {css, Theme} from "@emotion/react";
import {darken} from "polished";
import {Button} from "@web2/button";

import {Footer} from "../../footer/Footer";
import {OfferTypeSection} from "../../homepage/components/OfferTypeSection";
import {Flex} from "../atoms/Flex";

export const Error500Dynamic = () => {
    return (
        <>
            <section css={error500Holder}>
                <Flex alignItems="center" justifyContent="space-between">
                    <div css={error500CopyHolder}>
                        <h1 css={error500Heading}>Uuups!</h1>

                        <p css={error500Copy}>Serwis jest chwilowo niedostępny, przepraszamy za utrudnienia.</p>

                        <p css={error500ErrorCode}>kod błędu: 500</p>

                        <Button variant="success" size="lg" onClick={() => window.location.reload()}>
                            Odśwież stronę
                        </Button>

                        <Button variant="link" size="lg" css={backButton} onClick={() => history.back()}>
                            Powróć
                        </Button>
                    </div>
                </Flex>
            </section>

            <OfferTypeSection />

            <Footer />
        </>
    );
};

const backButton = (theme: Theme) => css`
    margin-left: 2rem;
    color: ${theme.colors.gray_light};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        color: ${darken(0.1, theme.colors.gray_light)};
    }
`;

const error500Holder = (theme: Theme) => css`
    max-width: 1300px;
    margin: 3rem auto;
    padding: 0 1rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 3rem 1.5rem;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 6rem 1.5rem;
    }
`;

const error500CopyHolder = (theme: Theme) => css`
    padding: 1rem 2rem 7rem;
    max-width: 100%;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        min-height: 350px;
        max-width: 552px;
    }

    @media (min-width: ${theme.breakpoints.screen_lg}) {
        padding: 0;
    }
`;

const error500Heading = (theme: Theme) => css`
    margin: 2rem 0 0;
    color: ${theme.colors.gray_darker};
    font-size: 4.5rem;
    font-weight: 600;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    line-height: 1.5;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 5.5rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 6.5rem;
    }
`;

const error500Copy = (theme: Theme) => css`
    margin: 0.5rem 0;
    font-weight: 400;
    line-height: 1.58;
    font-size: 1.6rem;
    color: ${theme.colors.gray_darker};
`;

const error500ErrorCode = (theme: Theme) => css`
    font-size: 1.6rem;
    line-height: 1.56;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${theme.colors.gray_light};
`;
