import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const FacilitiesIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "12.04mm"} height={props.height || "9.89mm"} viewBox="0 0 34.12 28.02">
            <path
                d="M27.36,7.68,17.61.14l-.05,0L17.42.05l-.13,0H17l-.13,0-.14.07,0,0L6.93,7.68A.76.76,0,0,0,6.8,8.73a.73.73,0,0,0,.59.3.78.78,0,0,0,.46-.16L9.22,7.81V17.87a.77.77,0,0,0,.22.53.75.75,0,0,0,.53.22H24.32a.75.75,0,0,0,.75-.75V7.81l1.37,1.06A.8.8,0,0,0,26.9,9a.74.74,0,0,0,.6-.3A.75.75,0,0,0,27.36,7.68Zm-3.79,9.44H10.72V6.65l6.43-5,6.42,5Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M14.44,21v-1a6.12,6.12,0,0,0-1.26-3.8,4.23,4.23,0,0,0-1.92-1.42A11.32,11.32,0,0,1,8,12.77a2.76,2.76,0,0,0-3.11-.3L3.8,9.84A2,2,0,0,0,.32,9.55a2,2,0,0,0-.14,1.89l2.2,4.85a6.18,6.18,0,0,0,1.26,1.82l3.48,3.48a1.71,1.71,0,0,0-.37,1.06v3.62A1.75,1.75,0,0,0,8.5,28h5.31a1.75,1.75,0,0,0,1.75-1.75V22.65A1.75,1.75,0,0,0,14.44,21ZM1.58,10.36a.5.5,0,0,1,.84.07l1.26,2.94a6.88,6.88,0,0,0,1.46,2.15l2.57,2.57L8.77,17,6.2,14.46a5,5,0,0,1-.56-.66,1.24,1.24,0,0,1,1.42.11,12.81,12.81,0,0,0,3.65,2.32,2.69,2.69,0,0,1,1.27.93,4.67,4.67,0,0,1,1,2.88v.86H8.55L4.7,17.05a4.54,4.54,0,0,1-1-1.38L1.54,10.82A.5.5,0,0,1,1.58,10.36ZM14.06,26.27a.25.25,0,0,1-.25.25H8.5a.25.25,0,0,1-.25-.25V22.65a.25.25,0,0,1,.25-.25h5.31a.25.25,0,0,1,.25.25Z"
                fill={props.mainColor || "#37474f"}
            />
            <rect x="12.56" y="23.96" width="0.56" height="1" fill={props.mainColor || "#37474f"} />
            <path
                d="M32.58,10.82a.47.47,0,0,0,0-.46.5.5,0,0,0-.85.08l-1.26,2.93A6.56,6.56,0,0,1,29,15.52l-2.57,2.57L25.35,17l2.57-2.57a5.09,5.09,0,0,0,.57-.66,1.25,1.25,0,0,0-1.44.13,12.72,12.72,0,0,1-3.6,2.28,2.88,2.88,0,0,0-1.31.95A4.59,4.59,0,0,0,21.19,20v.87h4.38l3.85-3.85a4.75,4.75,0,0,0,1-1.38Z"
                fill="#fff"
            />
            <path
                d="M33.81,9.55a2,2,0,0,0-3.49.29l-1.13,2.63a2.78,2.78,0,0,0-3.13.32,11.13,11.13,0,0,1-3.15,2,4.27,4.27,0,0,0-2,1.44A6.11,6.11,0,0,0,19.69,20v1a1.74,1.74,0,0,0-1.13,1.63v3.62A1.75,1.75,0,0,0,20.31,28h5.31a1.75,1.75,0,0,0,1.75-1.75V22.65A1.76,1.76,0,0,0,27,21.59l3.47-3.48a6.18,6.18,0,0,0,1.26-1.82L34,11.44A2,2,0,0,0,33.81,9.55ZM25.57,20.9H21.19V20a4.59,4.59,0,0,1,.95-2.87,2.88,2.88,0,0,1,1.31-.95,12.72,12.72,0,0,0,3.6-2.28,1.25,1.25,0,0,1,1.44-.13,5.09,5.09,0,0,1-.57.66L25.35,17l1.06,1.06L29,15.52a6.56,6.56,0,0,0,1.46-2.15l1.26-2.93a.5.5,0,0,1,.85-.08.47.47,0,0,1,0,.46l-2.2,4.85a4.75,4.75,0,0,1-1,1.38Zm.3,5.37a.25.25,0,0,1-.25.25H20.31a.25.25,0,0,1-.25-.25V22.65a.25.25,0,0,1,.25-.25h5.31a.25.25,0,0,1,.25.25Z"
                fill={props.mainColor || "#37474f"}
            />
            <rect x="21" y="23.96" width="0.56" height="1" fill={props.mainColor || "#37474f"} />
            <path
                d="M4.7,17.05,8.55,20.9h4.39V20a4.67,4.67,0,0,0-1-2.88,2.69,2.69,0,0,0-1.27-.93,12.81,12.81,0,0,1-3.65-2.32,1.24,1.24,0,0,0-1.42-.11,5,5,0,0,0,.56.66L8.77,17,7.71,18.09,5.14,15.52a6.88,6.88,0,0,1-1.46-2.15L2.42,10.43a.5.5,0,0,0-.84-.07.5.5,0,0,0,0,.46l2.21,4.85A4.54,4.54,0,0,0,4.7,17.05Z"
                fill="#fff"
            />
        </svg>
    );
};
