import {Request} from "express";
import {Headers} from "node-fetch";

import {IABTestConfiguration} from "../interfaces/IABTestConfiguration";
import {buildABTestCookie} from "./ab_testing_utils";

export type IAppendServerRequestHeadersMethod = (headers: Headers) => void;
/**
 * Parse cookies to find Google Optimize AB test variant or - if cookie was no set - define new AB test variant.
 * Set newly defined AB test variant in res.locals in order to populate this value in the request flow.
 * @param abTestConfig {IABTestConfiguration} AB test configuration object
 * @param domain {string} domain value will be set on AB test cookie
 * @param req {Request} request object
 * @param appendServerRequestHeadersMethod {function} method used by request headers service to append additional headers
 * @returns {number} Google Optimize AB test variant
 */
export const specifyABTestVariant = (
    {id, expires, variants}: IABTestConfiguration,
    domain: string,
    req?: Request,
    appendServerRequestHeadersMethod?: IAppendServerRequestHeadersMethod
): number => {
    const abTestVariantFromCookie = getABTestVariantFromCookies(id, req);

    const abTestVariant = drawABTestVariant(variants);

    if (!abTestVariantFromCookie && appendServerRequestHeadersMethod) {
        const headers = new Headers({"set-cookie": buildABTestCookie(id, abTestVariant, expires, domain)});
        appendServerRequestHeadersMethod(headers);
    }

    return abTestVariantFromCookie ? parseInt(abTestVariantFromCookie) : abTestVariant;
};

/**
 * Parse cookies to find Google Optimize AB test variant
 * @param abTestId {string} id of the Google Optimize AB test
 * @param req {Request} request object
 * @returns {number | null} Google Optimize AB test variant
 */
export const getABTestVariantFromCookies = (abTestId: string, req?: Request): string | null => {
    if (!req) {
        return null;
    }

    return req.cookies[abTestId] ?? null;
};

/**
 * Draw new AB test variant.
 * @param variants {number} total Google Optimize variants including original variant
 * @returns {number} new Google Optimize AB test variant
 */
const drawABTestVariant = (variants: number): number => {
    return Math.floor(Math.random() * (variants + 1));
};
