import type {LatLngLiteral} from "leaflet";

interface IOptions {
    reversedValues?: boolean;
}

export const convertToLatLngLiteral = (values: number[], options: IOptions = {}): LatLngLiteral => {
    return {
        lat: options.reversedValues ? values[1] : values[0],
        lng: options.reversedValues ? values[0] : values[1]
    } as LatLngLiteral;
};
