import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const LockIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "20.1"} height={props.height || "20.1"} viewBox="0 0 20.1 20.1">
            <path
                d="M12.07,9H8a1.87,1.87,0,0,0-1.87,1.87v2.35A1.87,1.87,0,0,0,8,15.1h4a1.87,1.87,0,0,0,1.87-1.87V10.88A1.87,1.87,0,0,0,12.07,9Zm-2,4.08a1,1,0,1,1,1-1A1,1,0,0,1,10.05,13.09Z"
                fill={props.mainColor || "#e57373"}
            />
            <path
                d="M12.07,7.91a3.42,3.42,0,0,1,.45,0V6.32a2.16,2.16,0,0,0-2.15-2.16H9.64A2.16,2.16,0,0,0,7.49,6.32V8a3.21,3.21,0,0,1,.55,0Z"
                fill={props.mainColor || "#e57373"}
            />
            <path
                d="M10.05,0A10.05,10.05,0,1,0,20.1,10.05,10.05,10.05,0,0,0,10.05,0Zm5,13.23a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10.88A3,3,0,0,1,6.4,8.4h0V6.32A3.25,3.25,0,0,1,9.64,3.06h.73a3.26,3.26,0,0,1,3.25,3.26v2A3,3,0,0,1,15,10.88Z"
                fill={props.mainColor || "#e57373"}
            />
        </svg>
    );
};
