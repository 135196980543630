import * as React from "react";
import {css, cx} from "@linaria/core";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";

import {ISimilarOffersFetch} from "../../application/actions/fetch_multilead_offers";
import {IApplicationModalAgencyOffers} from "../../application/components/ApplicationModalAgencyOffers";
import {roomsPluralism} from "../../offer/utils/utils";
import {m_0, m_0a, p_0, w50, w65} from "../../styles/helpers_linaria";
import {getThemeBreakpoint, getThemeVariable} from "../../styles/linaria_variable_factory";
import {sizeFormat} from "../utils/number";
import {MultileadPrice} from "./MultileadPrice";

interface IMultileadConfirmation {
    multileadOffersView: boolean;
    activeOffers: ISimilarOffersFetch[];
    offer: IApplicationModalAgencyOffers;
}
export const MultileadConfirmation = (props: IMultileadConfirmation) => {
    const {multileadOffersView, activeOffers} = props;
    const offerTitle = (offer: ISimilarOffersFetch | IApplicationModalAgencyOffers) => {
        const isInvestment = offer.investment;
        const propertySize = offer.property.size?.toString();
        const rooms = offer.property.room_number ? ` ${offer.property.room_number} ${roomsPluralism(offer.property.room_number)}` : "";

        return `${isInvestment ? "Nowe m" : "M"}ieszkanie,${rooms}, ${propertySize ? sizeFormat(propertySize) + " m2" : ""}`;
    };

    const getLocation = (offer: ISimilarOffersFetch) => {
        const location = offer.property.address;
        return (
            <>
                <address className={cx(offerInfo, address)}>{location}</address>
            </>
        );
    };
    const getOfferLink = (slug: string) => {
        const offerUrlParams = parseOfferSlugToObject(slug);

        return appLink.fullOffer.detail.base(offerUrlParams);
    };

    return (
        <>
            <p className={cx(confirmationTitle, !multileadOffersView && w50, !multileadOffersView && m_0a)}>Twoje zapytania zostały wysłane</p>
            {multileadOffersView && (
                <div className={confirmationDetails}>
                    <ul className={cx(p_0, m_0)}>
                        {props.offer && (
                            <li className={listItem}>
                                <a href={getOfferLink(props.offer.slug)} target="_blank" className={listItemLink}>
                                    <img
                                        className={vendorImage}
                                        src={
                                            props.offer.investment
                                                ? props.offer.investment?.developer?.logo_picture?.a_log_100
                                                : props.offer.agency?.logo_picture?.a_log_100
                                        }
                                        width="60"
                                        loading="lazy"
                                        alt="logo developera"
                                    />
                                    <div className={offerSummary}>
                                        {offerTitle(props.offer)}
                                        {props.offer.investment && props.offer.investment.name && (
                                            <span className={cx(offerInfo, investmentFont)}>{props.offer.investment.name}</span>
                                        )}
                                        <address className={cx(offerInfo, address)}>{props.offer.property.location?.short_name}</address>
                                    </div>
                                    <MultileadPrice price={props.offer.price} isInvestment={!!props.offer.investment} />
                                </a>
                            </li>
                        )}
                        {[...activeOffers].map((offer) => (
                            <li className={listItem} key={offer.id}>
                                <a href={getOfferLink(offer.slug)} target="_blank" className={listItemLink}>
                                    <img
                                        className={vendorImage}
                                        src={offer?.investment?.developer?.logo_picture?.a_log_100}
                                        width="60"
                                        loading="lazy"
                                        alt="logo developera"
                                    />

                                    <div className={offerSummary}>
                                        {offerTitle(offer)}
                                        {offer.investment && offer.investment.name && (
                                            <span className={cx(offerInfo, investmentFont)}>{offer.investment.name}</span>
                                        )}
                                        {getLocation(offer)}
                                    </div>
                                    <MultileadPrice price={offer.price} isInvestment={!!offer.investment} />
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <p className={cx(confirmationFooter, !multileadOffersView && w65)}>
                Oczekuj na kontakt od dewelopera lub agenta, który poinformuje Cię o szczegółach oferty i dokładnej cenie.
            </p>
        </>
    );
};

const offerSummary = css`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30rem);
    font-size: ${getThemeVariable("fonts-font_size_base")};

    @media (max-width: ${getThemeBreakpoint().screen_md}) {
        width: auto;
    }
`;

const investmentFont = css`
    font-size: 0.9rem;
`;

const address = css`
    font-size: 1rem;
    margin-top: 0.75rem;
`;

const offerInfo = css`
    font-style: normal;
    color: ${getThemeVariable("colors-gray_warm")};
`;
const confirmationFooter = css`
    font-size: ${getThemeVariable("fonts-font_size_big")};
    text-align: center;
    margin: 1.6rem auto 2rem auto;
`;
const confirmationTitle = css`
    color: ${getThemeVariable("colors-brand_primary")};
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 4.8rem;
    text-align: center;
    margin: 1rem 0 4rem 0;
    @media (max-width: ${getThemeBreakpoint().screen_md}) {
        margin: 4rem 0;
    }
`;
const confirmationDetails = css`
    background: ${getThemeVariable("colors-text_white")};
    text-align: left;
    padding: 0 2rem;
    margin: 0 4rem;

    @media (max-width: ${getThemeBreakpoint().screen_sm}) {
        margin: 0;
    }
`;

const listItem = css`
    list-style: none;
    border-bottom: 1px solid ${getThemeVariable("colors-gray_very_bright")};

    &:last-of-type {
        border: none;
    }
`;

const vendorImage = css`
    margin: 0 2rem 0 0;
`;

const listItemLink = css`
    display: flex;
    align-items: center;
    padding: 2rem 0;
    text-decoration: none;
    width: 100%;
    color: ${getThemeVariable("colors-text_color")};

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: ${getThemeVariable("colors-text_color")};
    }

    @media (max-width: ${getThemeBreakpoint().screen_md}) {
        display: block;
    }
`;
