import {combineReducers, Reducer} from "redux";

import {reduceRequestState, RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchIndividualOfferDetailActionTypes, IndividualOfferDetailResponse} from "../actions/fetch_individual_offer_detail";
import {fetchIndividualOffersActionTypes, IndividualOfferListOffer} from "../actions/fetch_individual_offers_action";

export interface IndividualOffersStore {
    list: IndividualOfferListOffer[];
    detail: IndividualOfferDetailResponse;
    page: number;
    offerCount: number;
    listRequestState: RequestState;
}

const individualOffersReducerObj: Record<keyof IndividualOffersStore, Reducer<any, any>> = {
    list: reduceResponseField(fetchIndividualOffersActionTypes, "results", null),
    detail: reduceResponse(fetchIndividualOfferDetailActionTypes),
    page: reduceResponseField(fetchIndividualOffersActionTypes, "page", 1),
    offerCount: reduceResponseField(fetchIndividualOffersActionTypes, "count", 0),
    listRequestState: reduceRequestState(fetchIndividualOffersActionTypes)
};

export const individualOffersReducer = combineReducers(individualOffersReducerObj);
