import * as React from "react";
import styled from "@emotion/styled";
import {pluralize} from "@web2/string_utils";

const getRemainingTextLength = (text: string | null, maxValue: number) => {
    if (text) {
        return maxValue - text.length;
    }
    return maxValue;
};
const chartPluralizm = pluralize(["znak", "znaki", "znaków"]);

interface IProps {
    value: string | null;
    maxLength: number;
}

export const TextLimitCounter = (props: IProps) => {
    const remainingTextLength = getRemainingTextLength(props.value, props.maxLength);

    return <TextLimitWrapper>{`Pozostało: ${remainingTextLength} ${chartPluralizm(remainingTextLength)}`}</TextLimitWrapper>;
};

const TextLimitWrapper = styled.span`
    font-size: ${(props) => props.theme?.fonts?.font_size_small};
    margin: 1rem 0 2rem 0;
    padding-right: 1rem;
    display: flex;
    justify-content: end;
    width: 100%;
`;
