import React from "react";
import {css, Theme} from "@emotion/react";
import {darken} from "polished";
import {Button} from "@web2/button";

export const Activated = (props: {onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void}) => {
    const {onClick} = props;

    return (
        <div css={activatedPackageWrapper}>
            <div css={textPayments}>System płatności jest jeszcze niedostępny dlatego otrzymujesz dostęp do danych ofert bezpłatnie.</div>
            <Button css={styledButtonCTAModalPrivateOffersPachageActivated} onClick={onClick}>
                Przejdź do oferty
            </Button>
        </div>
    );
};

const textPayments = css`
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
`;

const activatedPackageWrapper = css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
`;

export const styledButtonCTAModalPrivateOffersPachageActivated = (theme: Theme) => css`
    display: flex;
    width: 80%;
    border-radius: ${theme.borders.border_radius_base};
    background-color: ${theme.colors.green_darker};
    border-color: ${theme.colors.green_darker};

    &:hover,
    &:active,
    &:focus {
        background-color: ${darken(0.1, theme.colors.green_darker)};
        border-color: ${darken(0.1, theme.colors.green_darker)};
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 24.7rem;
    }
`;
