import {useTransition} from "react";
import {useHistory} from "react-router";

export const useTransitioningHistoryPush = () => {
    const history = useHistory();
    const [isTransitioning, startTransition] = useTransition();

    const transitionHistory = (url: string, state?: Record<string, unknown>) => {
        startTransition(() => {
            history.push(decodeURIComponent(url), state);
        });
    };

    return {isTransitioning, transitionHistory};
};
