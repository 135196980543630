import React, {useEffect} from "react";
import ReactModal from "react-modal";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

import {CloseButtonIcon} from "./CloseButtonIcon";

export type IModalType = "full" | "window" | "full-desktop";

export interface IModal {
    children?: React.ReactNode;
    type?: IModalType;
    modalRootId?: string;
    onModalClose?: (e: React.MouseEvent) => void;
    modalState: boolean;
    closeTimeout?: number;
    className?: string;
    overlayClassName?: string;
    contentLabel?: string;
    closeButton?: boolean;
    closeButtonClassName?: string;
    closeButtonIconClassName?: string;
    header?: string | JSX.Element;
    contentStyles?: object;
    overlayStyles?: object;
    shouldCloseOnOverlayClick?: boolean;
    zIndex?: number;
}

export interface IThemeProps {
    type?: IModalType;
}

const StyledReactModal = styled(ReactModal)<IThemeProps>`
    position: absolute;
    background-color: #fff;
    outline: none;
    padding: 0;
    overflow: hidden;

    ${(props) =>
        props.type === "full-desktop" &&
        css`
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        `}

    ${(props) =>
        props.type === "full" &&
        css`
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            // casting: we have to use 'old' and 'new' breakpoints structure because this package is published and consumed by GH. GH uses old @mono 'screen_xx' format, RP (design system) uses 'xx'
            @media (min-width: ${props.theme.breakpoints.screen_sm ? props.theme.breakpoints.screen_sm : "1024px"}) {
                top: initial;
                left: initial;
                bottom: initial;
                right: initial;
            }
        `}

  ${(props) =>
        props.type === "window" &&
        css`
            top: initial;
            left: initial;
            bottom: initial;
            right: initial;
        `}
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const Modal: React.FC<IModal> = ({
    children,
    type = "full",
    modalRootId = "#root",
    modalState,
    closeTimeout = 150,
    className,
    overlayClassName,
    contentLabel = "modal",
    closeButton = true,
    closeButtonClassName,
    closeButtonIconClassName,
    header,
    contentStyles,
    overlayStyles,
    shouldCloseOnOverlayClick,
    zIndex = 9000,
    onModalClose
}) => {
    useEffect(() => {
        if (process.env.EXEC_ENV === "browser") {
            ReactModal.setAppElement(modalRootId);
        }
    }, [modalRootId]);

    const handleModalClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onModalClose && onModalClose(e);
    };

    const classNameValue = classNames("modal-content", className);
    const overlayClassNameValue = classNames("modal-overlay", overlayClassName);
    const closeButtonClassNameValue = classNames("modal-close-button", closeButtonClassName);

    const modalFullOverlay: React.CSSProperties = {
        position: "fixed",
        zIndex,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, .65)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    const modalCloseButton = closeButton && (
        <CloseButton onClick={handleModalClose} className={closeButtonClassNameValue}>
            <CloseButtonIcon className={closeButtonIconClassName} />
        </CloseButton>
    );

    const modalHeader = (
        <div>
            {header}
            {closeButton && modalCloseButton}
        </div>
    );

    return (
        <StyledReactModal
            type={type}
            isOpen={modalState}
            ariaHideApp={false}
            onRequestClose={handleModalClose}
            closeTimeoutMS={closeTimeout}
            className={classNameValue}
            overlayClassName={overlayClassNameValue}
            contentLabel={contentLabel || ""}
            style={{
                content: contentStyles,
                overlay: overlayStyles || modalFullOverlay
            }}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            {modalHeader}
            {children}
            {modalCloseButton}
        </StyledReactModal>
    );
};
