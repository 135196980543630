import * as React from "react";
import {ReactNode} from "react";
import {css} from "@linaria/core";

import {BurgerMenu} from "./BurgerMenu";

interface IProps {
    logo: {
        img: string;
        url: string;
        alt: string;
    };
    middleElement?: ReactNode;
    arrows: {
        left: ReactNode;
    };

    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    onBack: () => void;
    label: string | ReactNode;
    navState: number[];
    favouritesCount?: number;
    isMobile?: boolean;
}

export const LogoAndBurger = (props: IProps) => {
    const {logo, arrows, isOpen, setOpen, setClose, onBack, label, navState} = props;
    return (
        <nav className={logoAndBurgerStyles}>
            <a className={logoHolder} href={logo.url}>
                <img className={logoStyle} src={logo.img} alt={logo.alt} width={132} height={30} />
            </a>

            {props.middleElement && props.middleElement}

            <BurgerMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setClose={setClose}
                onBack={onBack}
                label={label}
                navState={navState}
                arrow={arrows.left}
                favouritesCount={props.favouritesCount}
            />
        </nav>
    );
};

const logoAndBurgerStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const logoHolder = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;
`;

const logoStyle = css`
    display: block;
`;
