import {capitalizeFirst} from "@web2/nodash";

import {getMetaPage} from "../../../meta_data/utils/get_meta_page";
import {OfferTypePL} from "../../utils/constants_offer";
import {firstLetterUppercase} from "../../utils/investment_utils";
import {DEFAULT_SELECTED_LOCATION} from "../actions/update_offer_list_meta_data";
import {getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {UrlTagSlug} from "../url_utils/tags";

interface IProps {
    offersCount: number;
    currentLocationName: string;
    dealType: string;
    offerType: string;
    offerTypePL: string;
    page: number;
    tag?: string;
}

export const getOfferListTitle = (props: IProps) => {
    const {currentLocationName, tag, offerType, offerTypePL, dealType, page, offersCount} = props;
    const locationTitle = currentLocationName === DEFAULT_SELECTED_LOCATION ? "Polska" : currentLocationName;

    const twoRoom = offerTypePL.toLowerCase() == "domy" ? "dwupokojowe" : "2 pokojowe";
    const twoRoomString =
        offerType.toLowerCase() !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${twoRoom}`
            : `${capitalizeFirst(twoRoom)} ${offerTypePL.toLowerCase()}`;

    const threeRoom = offerTypePL.toLowerCase() == "domy" ? "trzypokojowe" : "3 pokojowe";
    const threeRoomString =
        offerType.toLowerCase() !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${threeRoom}`
            : `${capitalizeFirst(threeRoom)} ${offerTypePL.toLowerCase()}`;

    const fourRoom = offerTypePL.toLowerCase() == "domy" ? "czteropokojowe" : "4 pokojowe";
    const fourRoomString =
        offerType.toLowerCase() !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${fourRoom}`
            : `${capitalizeFirst(fourRoom)} ${offerTypePL.toLowerCase()}`;

    const fiveRoom = offerTypePL.toLowerCase() == "domy" ? "pięciopokojowe" : "5 pokojowe";
    const fiveRoomString =
        offerType.toLowerCase() !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${fiveRoom}`
            : `${capitalizeFirst(fiveRoom)} ${offerTypePL.toLowerCase()}`;

    /*
     * tagged titles
     */
    const foundTag = getUrlTagDataBySlug(tag);

    // TODO: code below looks like a switch-case candidate after seostorm is over
    // TODO: verify with SEO if 'kawalerki' and 'dwupokojowe' tags should be entirely handled via configuration in `tags.ts`
    if (foundTag?.tag === UrlTagSlug.STUDIO && offerType.toLowerCase() !== OfferTypePL.house) {
        const studios = offersCount > 1 ? "kawalerki" : "kawalerka";
        const propertyForSingle = offerTypePL.toLowerCase() === OfferTypePL.apartment ? "mieszkanie dla singla" : "nieruchomości dla singla";

        return `${firstLetterUppercase(studios)} ${dealType} ${locationTitle} - ${propertyForSingle}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.TWO_ROOM && (dealType == "na sprzedaż" || dealType == "do wynajęcia")) {
        return `${twoRoomString} ${dealType} ${locationTitle}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.THREE_ROOM && (dealType == "na sprzedaż" || dealType == "do wynajęcia")) {
        return `${threeRoomString} ${dealType} ${locationTitle}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.FOUR_ROOM && (dealType == "na sprzedaż" || dealType == "do wynajęcia")) {
        return `${fourRoomString} ${dealType} ${locationTitle}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.FIVE_ROOM && (dealType == "na sprzedaż" || dealType == "do wynajęcia")) {
        return `${fiveRoomString} ${dealType} ${locationTitle}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.PRIMARY_MARKET) {
        return `Rynek pierwotny ${locationTitle} - ${offerTypePL.toLowerCase()} ${dealType}${getMetaPage(page)}`;
    }

    if (foundTag?.tag === UrlTagSlug.INDIVIDUAL_OFFERS) {
        return `${firstLetterUppercase(offerTypePL)} ${dealType} ${locationTitle} bez pośredników`;
    }

    if (foundTag?.metaText) {
        return `${firstLetterUppercase(offerTypePL)} ${foundTag.metaText} ${dealType == "do wynajęcia" ? `${dealType} ` : ""}${locationTitle}${getMetaPage(
            page
        )}`;
    }

    /*
     * regular title
     */
    return `${firstLetterUppercase(offerTypePL)} ${dealType} ${locationTitle}${getMetaPage(page)}`;
};
