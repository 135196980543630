import {useEffect, useMemo, useRef} from "react";
import {css, Theme} from "@emotion/react";
import {each, map} from "lodash";
import {GoogleMap, IGoogleMapApi, MarkerGroupDefinition} from "@web2/map";

import {GOOGLE_MAPS_API_KEY} from "../../app/constants/keys";
import {getNormalizedCoordinates} from "../../app/utils/get_normalized_coordinates";
import {ISimilarOffersFetch} from "../../application/actions/fetch_multilead_offers";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {ViewType} from "../../tracking/view_type/view_type";

import aftermarketMarker from "../../styles/assets/svg/aftermarket_marker.svg";
import centerOfferPin from "../../styles/assets/svg/center_offer_pin.svg";
import checkedOffer from "../../styles/assets/svg/checked_offer.svg";
import primaryMarketMarker from "../../styles/assets/svg/primary_market_marker.svg";

interface IProps {
    markers: Pick<ISimilarOffersFetch, "coordinates" | "market_type" | "id">[];
    type: VendorType;
    viewType: ViewType | null;
    offer: {
        coordinates: {
            lat: number;
            lon: number;
        };
        market_type?: OfferMarketType;
        id?: string;
    };
    activeOffers: ISimilarOffersFetch[];
}

const MultileadMap = (props: IProps) => {
    const mapRef = useRef<IGoogleMapApi>(null);

    useEffect(() => {
        const bounds = mapRef.current?.getMapInstance()?.getBounds();

        const allMarkersInView = props.markers.every((marker) => bounds?.contains(getNormalizedCoordinates(marker.coordinates)));
        if (!allMarkersInView && bounds) {
            each(props.markers, (marker) => {
                bounds.extend(getNormalizedCoordinates(marker.coordinates));
            });
            mapRef.current?.getMapInstance()?.fitBounds(bounds);
        }
    }, [props.markers, mapRef]);

    const mapConfig = useMemo(
        () => ({
            maxZoom: 16,
            zoom: 12,
            center: {lat: props.offer?.coordinates?.lat, lng: props.offer?.coordinates?.lon},
            scrollwheel: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true
        }),
        [props.offer?.coordinates?.lat, props.offer?.coordinates?.lon]
    );

    const offerMarkers: Record<string, MarkerGroupDefinition> = useMemo(() => {
        return {
            default: {
                list: map([...props.markers, props.offer], (marker) => {
                    const checkedMarker = props.activeOffers.find((offer) => offer.id === marker.id);
                    const MULTILED_OFFER_LIST_MAP_ICON_POSITION_OFFSET = checkedMarker ? 13 : 10;

                    return {
                        id: marker.id,
                        coords: [getNormalizedCoordinates(marker.coordinates).lng, marker.coordinates.lat],
                        icon: checkedMarker
                            ? checkedOffer
                            : marker.id === props.offer.id
                              ? centerOfferPin
                              : marker.market_type === OfferMarketType.PRIMARY_MARKET
                                ? primaryMarketMarker
                                : aftermarketMarker,
                        options: {
                            scaledSize: checkedMarker
                                ? {width: 25, height: 25}
                                : marker.id === props.offer.id
                                  ? {width: 44, height: 60}
                                  : {width: 20, height: 20},
                            anchorPoint: {width: MULTILED_OFFER_LIST_MAP_ICON_POSITION_OFFSET, height: MULTILED_OFFER_LIST_MAP_ICON_POSITION_OFFSET}
                        }
                    };
                })
            }
        };
    }, [props.activeOffers]);

    /**
     * Main render
     */
    return (
        <>
            <div css={mapHolder}>
                <GoogleMap ref={mapRef} apiKey={GOOGLE_MAPS_API_KEY} config={mapConfig} markers={offerMarkers} fitBoundsOnUpdate={false} />
            </div>
        </>
    );
};

export const mapHolder = (theme: Theme) => css`
    position: relative;
    width: 100%;
    background: #f2f2f2;
    .gm-style-iw {
        padding: 0;
        background: transparent;
        box-shadow: none;
        top: 5px;
        border-radius: 0;
        max-width: 350px !important;
        max-height: 336px !important;

        .gm-ui-hover-effect {
            display: none !important;
        }

        .map-tooltip-holder {
            padding-bottom: 0.5rem;

            @media (max-width: calc(${theme.breakpoints.screen_md} - 1px)) {
                display: none;
            }

            .map-tooltip {
                background-color: rgba(55, 71, 79, 0.85);
                box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0;
                padding: 0.8rem 1.5rem;
                color: #fff;
                border-radius: 0.4rem;

                &:after {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid rgba(55, 71, 79, 0.85);
                    z-index: 1;
                }
            }
        }
    }

    .gm-style-iw-d {
        max-height: 336px !important;
        max-width: 350px !important;
        overflow: hidden !important;
        display: flex;
        justify-self: center;
        align-items: center;
    }
    .gm-style-iw-t::after {
        display: none;
    }
`;
export default MultileadMap;
