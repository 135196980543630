import {Dispatch} from "redux";
import {IAuthorDetailParams} from "@web2/gh_routes";
import {safelyParsePage} from "@web2/string_utils";

import {IAuthorDetailArticlesResponse} from "../../app/interfaces/response/author_detail";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export const fetchAuthorArticlesTypes = createRequestActionTypes({view: "Author", type: "GET", name: "AuthorArticles"});

export const fetchAuthorArticlesAtRoute = (services: Partial<IServices>, route: IRouteState<IAuthorDetailParams>) => (dispatch: Dispatch) => {
    dispatch({type: fetchAuthorArticlesTypes.start});
    const page = safelyParsePage(route.query.page);
    const url = appendQueryString(apiLink.articles.authorArticles({})(null), {author__slug: route.params.authorSlug, page});

    return getRequest({}, url).then((response: IAuthorDetailArticlesResponse) => {
        const result = {
            count: response.count,
            next: response.next,
            page: response.page,
            page_size: response.page_size,
            previous: response.previous,
            articles: response.results
        };

        dispatch({type: fetchAuthorArticlesTypes.success, result: result});
        return result;
    });
};
