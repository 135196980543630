// Please do not change string values. They were introduced by FM and are used for analytics ever since, we want to have continuity in naming.

export const mapSizeClickGTMEvent = (nextSize: "small" | "large") => {
    if ((window as any).dataLayer) {
        if (nextSize === "small") {
            return (window as any).dataLayer.push({event: "map_decrease"});
        }
        if (nextSize === "large") {
            return (window as any).dataLayer.push({event: "map_enlarge"});
        }
    }
    return null;
};
