import * as React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {omit} from "lodash";
import {numberWithDelimiter} from "@web2/string_utils";

import {getThemeVariable} from "../../../../styles/linaria_variable_factory";
import {filterTitle} from "../../atoms/atoms";
import {RANGE_SETTINGS} from "./config";
import {IRangeFilterProps, IRangeFilterState, TPossibleParams, UPossibleParams} from "./models";
import {objectMap} from "./utils";

export class ObsoleteRangeFilterWithHistogram extends React.PureComponent<IRangeFilterProps, IRangeFilterState> {
    public static defaultProps = {
        autoSubmit: false,
        isMobile: false,
        onClose: () => ({})
    };

    constructor(props: IRangeFilterProps) {
        super(props);

        const stateFromProps = {
            [`${this.nameGTE}` as UPossibleParams]: this.initialValueMin,
            [`${this.nameLTE}` as UPossibleParams]: this.initialValueMax
        } as TPossibleParams;

        this.state = {
            ...stateFromProps,
            touched: false,
            triggerSubmit: true,
            valueMaxTemp: this.initialValueMax,
            valueMinTemp: this.initialValueMin,
            minValid: true,
            maxValid: true
        };
    }

    public componentDidUpdate(prevProps: Readonly<IRangeFilterProps>, prevState: Readonly<IRangeFilterState>, snapshot?: any) {
        // update input values when params update externally
        if (prevProps.params[this.nameLTE] !== this.props.params[this.nameLTE]) {
            this.setState({valueMaxTemp: this.initialValueMax});
        }
        if (prevProps.params[this.nameGTE] !== this.props.params[this.nameGTE]) {
            this.setState({valueMinTemp: this.initialValueMin});
        }
    }

    public componentWillUnmount() {
        if (!this.props.isMobile && this.state.triggerSubmit && this.state.touched) {
            // this.handleSubmit(false);
        }
    }
    private get lowerBound(): number {
        return this.props.filterBounds.lower_bound;
    }
    private get upperBound(): number {
        return this.props.filterBounds.upper_bound;
    }

    private get name(): string {
        return this.props.name;
    }

    private get nameLTE(): UPossibleParams {
        return `${this.name}__lte` as UPossibleParams;
    }

    private get nameGTE(): UPossibleParams {
        return `${this.name}__gte` as UPossibleParams;
    }

    private get isFullPrice(): boolean {
        return this.props.name === "price";
    }

    private get isPricePerSqMeter(): boolean {
        return this.props.name === "price_per_sqm";
    }

    private formatWithDelimiter(value: string | number): string {
        return numberWithDelimiter(value);
    }

    private get initialValueMax(): string {
        const propsValue = this.props.params[this.nameLTE];
        const {upper_bound} = this.props.filterBounds;
        if (propsValue !== undefined) {
            return this.parsedValue(this.validateBounds(propsValue, "upper"));
        }

        if (upper_bound > 0) {
            return this.parsedValue(upper_bound);
        }

        return this.parsedValue(this.rangeSettingMax);
    }

    private get initialValueMin(): string {
        const propsValue = this.props.params[this.nameGTE];
        const {lower_bound} = this.props.filterBounds;

        if (propsValue !== undefined) {
            return this.parsedValue(this.validateBounds(propsValue, "lower"));
        }

        if (lower_bound > 0) {
            return this.parsedValue(lower_bound);
        }

        return this.parsedValue(this.rangeSettingMin);
    }

    private undelimitedNumber = (value: string): number => {
        return parseInt(value.split(" ").join(""), 10);
    };

    private get rangeSettingMin(): number {
        return RANGE_SETTINGS[this.name.toUpperCase() + "__GTE"];
    }

    private get rangeSettingMax(): number {
        return RANGE_SETTINGS[this.name.toUpperCase() + "__LTE"];
    }

    private parsedValue(value: string | number): string {
        if (value) {
            return this.isFullPrice || this.isPricePerSqMeter ? this.formatWithDelimiter(value) : value.toString();
        }
        return "";
    }

    private validateBounds(value: string, type: "lower" | "upper"): string {
        const {lower, upper} = (this.props as any).value;

        const minMaxSuffix = type === "upper" ? "__LTE" : "__GTE";
        const range = RANGE_SETTINGS[this.name.toUpperCase() + minMaxSuffix];

        const val = parseInt(value.split(" ").join(""));

        if (type === "lower") {
            if (val < range) {
                this.setState({minValid: false});
                return range.toString();
            }
            if (upper) {
                return val > upper ? upper.toString() : value;
            }
            return val > this.upperBound ? this.upperBound.toString() : value;
        }

        //max value Check
        if (val > this.rangeSettingMax) {
            this.setState({maxValid: false});
            return range.toString();
        }
        this.setState({maxValid: true});

        if (lower) {
            return val < lower ? lower.toString() : value;
        }

        return val < this.lowerBound ? this.lowerBound.toString() : value;
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target as Partial<{name: UPossibleParams; value: string}>;
        const inputName = name as UPossibleParams;
        const newValue = (value && value.split(" ").join("")) || "";
        // if it is empty string or it's not possible to parse new value into number, then do nothing.
        // bad values: "0", any other string.
        if (newValue === "" || Number(newValue)) {
            const newState = {
                [inputName]: this.isFullPrice || this.isPricePerSqMeter ? numberWithDelimiter(newValue as string) : value
            } as TPossibleParams;

            this.setState(newState);
        }

        if ((name as any) === "valueMinTemp" && value) {
            const validValue = this.validateBounds(newValue, "lower");
            this.props.onChange(this.props.name, {
                upper: (this.props as any).value.upper, // unchanged
                lower: this.undelimitedNumber(validValue)
            });
            this.setState({[inputName]: numberWithDelimiter(validValue as string)} as TPossibleParams);
        }
        if ((name as any) === "valueMaxTemp" && value) {
            const validValue = this.validateBounds(newValue, "upper");

            this.props.onChange(this.props.name, {
                upper: this.undelimitedNumber(validValue),
                lower: (this.props as any).value.lower // unchanged
            });
        }
    };

    public handleSubmit = (triggerClose = true) => {
        const state = omit(this.state, ["triggerSubmit", "touched"]);
        const params = {
            ...(objectMap(state, this.undelimitedNumber) as TPossibleParams),
            valueMaxTemp: undefined,
            valueMinTemp: undefined
        };

        if (this.isPricePerSqMeter) {
            this.props.onChange(this.props.name, {lower: params.price_per_sqm__gte, upper: params.price_per_sqm__lte});
            this.props.onAfterChange(this.props.name, {lower: params.price_per_sqm__gte, upper: params.price_per_sqm__lte});
        } else if (this.isFullPrice) {
            this.props.onChange(this.props.name, {lower: params.price__gte, upper: params.price__lte});
            this.props.onAfterChange(this.props.name, {lower: params.price__gte, upper: params.price__lte});
        } else {
            this.props.onChange(this.props.name, {lower: params.size__gte, upper: params.size__lte});
            this.props.onAfterChange(this.props.name, {lower: params.size__gte, upper: params.size__lte});
        }

        if (triggerClose) {
            this.setState(
                {
                    triggerSubmit: false
                },
                () => this.props.onClose()
            );
        }
    };

    public handleInputBlur = () => {
        const {valueMinTemp, valueMaxTemp} = this.state;

        const valueMin = valueMinTemp !== "" ? this.undelimitedNumber(valueMinTemp) : this.lowerBound;
        const valueMax = valueMaxTemp !== "" ? this.undelimitedNumber(valueMaxTemp) : this.upperBound;
        if (valueMax > this.rangeSettingMax) {
            this.setState({maxValid: false});
            return this.setNewState(this.undelimitedNumber(valueMinTemp), this.rangeSettingMax);
        }

        if (valueMinTemp === "") {
            return this.setNewState(this.lowerBound, this.undelimitedNumber(valueMaxTemp));
        }
        if (valueMaxTemp === "") {
            return this.setNewState(this.undelimitedNumber(valueMinTemp), this.upperBound);
        }

        if (valueMax < valueMin && valueMin > 0) {
            return this.setNewState(this.undelimitedNumber(valueMinTemp), this.undelimitedNumber(valueMinTemp));
        }
    };

    public handleInputFocus = (name: string) => {
        const {lower, upper} = (this.props as any).value;

        if (name === "valueMinTemp") {
            this.setState({valueMinTemp: "", maxValid: this.state.maxValid, minValid: true});
            this.props.onChange(this.props.name, {
                lower: "",
                upper: upper ? upper : ""
            });
            return;
        }
        this.props.onChange(this.props.name, {
            lower: lower ? lower : "",
            upper: ""
        });
        this.setState({valueMaxTemp: "", maxValid: true, minValid: this.state.minValid});
    };

    private setNewState = (min: number, max: number) => {
        const newMin = this.parsedValue(min);
        const newMax = this.parsedValue(max);

        const newState = {
            [`${this.nameGTE}`]: newMin,
            [`${this.nameLTE}`]: newMax
        } as TPossibleParams;

        this.setState(
            {
                ...newState,
                touched: true,
                valueMaxTemp: newMax,
                valueMinTemp: newMin
            },
            this.autoSetState
        );
    };

    private autoSetState = () => {
        if (this.props.autoSubmit) {
            this.handleSubmit();
        }
    };

    public render() {
        const {footerComponent: FooterComponent, title} = this.props;
        return (
            <div className={sliderHolder}>
                {title && <p className={filterTitle}>{title}</p>}

                <div className={wideFilterHolder}>
                    <RangeFilterInput
                        data-test-id={this.props.testId && this.props.testId.lower}
                        type="string"
                        name="valueMinTemp"
                        value={this.state.valueMinTemp}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                        onFocus={() => this.handleInputFocus("valueMinTemp")}
                        inputMode="numeric"
                        autoComplete="off"
                        valid={this.state.minValid}
                    />

                    <div className={filterInputsDivider} />

                    <RangeFilterInput
                        data-test-id={this.props.testId && this.props.testId.upper}
                        type="string"
                        name="valueMaxTemp"
                        value={this.state.valueMaxTemp}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                        onFocus={() => this.handleInputFocus("valueMaxTemp")}
                        min={this.props.filterBounds.lower_bound}
                        inputMode="numeric"
                        autoComplete="off"
                        valid={this.state.maxValid}
                    />
                </div>

                {FooterComponent && this.props.params.offer_type !== "lot" && (
                    <>
                        <div className={filterInputsDivider} />

                        <FooterComponent />
                    </>
                )}
            </div>
        );
    }
}

const sliderHolder = css`
    margin-bottom: 2rem;
`;

const wideFilterHolder = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RangeFilterInput = styled.input<{valid: boolean}>`
    width: 100%;
    padding: 8px 13px 7px;
    color: ${getThemeVariable("colors-gray_darker")};
    font-size: 14px;
    outline: 0;
    line-height: 1.64;
    -webkit-appearance: none;
    display: block;
    max-width: 100%;
    transition: border 250ms ease-out;
    border: 1px solid ${({valid}) => (valid ? "#bbbbbb" : getThemeVariable("colors-brand_danger"))};
    border-radius: 4px;
    background-color: #fbfcfd;
    font-weight: 400;
    text-align: right;

    &[type="number"] {
        text-align: right;
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:active,
    &:focus {
        border-color: ${({valid}) => (valid ? "#5a9de6" : getThemeVariable("colors-brand_danger"))};
    }
`;

const filterInputsDivider = css`
    width: 8px;
    height: 2px;
    min-width: 8px;
    margin: 0 13px;
    background-color: ${getThemeVariable("colors-gray_brighter")};
`;
