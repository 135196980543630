import {ReactNode, useMemo} from "react";
import styled from "@emotion/styled";

import {paragraphStyles} from "../../app/atoms/Paragraph";
import {replaceContactInText} from "../utils/replace_contact_in_text";

interface IExternalContentProps {
    children?: ReactNode;
    content?: string;
    removeContactData?: boolean;
}

/**
 * Component used to hide any contact information that can be delivered in `content` prop.
 * when `removeContacts=true` - phone numbers and emails are removed
 */

export const ExternalContent = (props: IExternalContentProps) => {
    const content = useMemo(() => {
        if (!props.content) return "";

        const replacedContent = replaceContactInText(props.content);
        return linesToParagraph(props.removeContactData ? replacedContent : props.content);
    }, [props.content, props.removeContactData]);

    return content ? <ExternalContentStyled dangerouslySetInnerHTML={{__html: content}} /> : <ExternalContentStyled>{props.children}</ExternalContentStyled>;
};

export const ExternalContentStyled = styled.div`
    color: ${(props) => props.theme.colors.gray_dark};

    p {
        ${paragraphStyles};
        color: ${(props) => props.theme.colors.gray_dark};
        font-size: 1.2rem;

        @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
            font-size: 1.4rem;
        }

        + p {
            margin-top: 1.5rem;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    h3,
    h4 {
        font-weight: 500;
    }
`;

export const linesToParagraph = (text: string) => {
    if (text.includes("<p>")) {
        return text;
    }

    return text
        .split("\n")
        .map((line) => line.trim())
        .map((line) => `<p>${line}</p>`)
        .join("");
};
