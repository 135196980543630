import {ghCommonUrl} from "../../../app/utils/read_environment_variables";
import {hitGoogleTagManager} from "../gtm_init";

export enum PromoTypes {
    BIG = "big",
    SMALL = "small"
}

interface IPromo {
    type: PromoTypes;
    title: string;
    bannerImageUrl: string;
    position: number;
}

export const gtmPromoClick = (props: IPromo): boolean => {
    const gtmData = {
        event: "promoClick",
        ecommerce: {
            promoClick: {
                promotions: [
                    {
                        name: `${props.type}_${props.title}`,
                        creative: ghCommonUrl + props.bannerImageUrl,
                        position: props.position
                    }
                ]
            }
        }
    };
    hitGoogleTagManager(gtmData);
    return true;
};

export const gtmPromoView = (promotions: IPromo[]): boolean => {
    const promos = promotions.map((promo) => ({
        name: `${promo.type}_${promo.title}`,
        creative: ghCommonUrl + promo.bannerImageUrl,
        position: promo.position
    }));

    const gtmData = {
        event: "promoView",
        ecommerce: {
            promoView: {
                promotions: promos
            }
        }
    };
    hitGoogleTagManager(gtmData);
    return true;
};
