import {appendQueryString} from "../../app/utils/append_query_string";

export const generatePrevNextMetaLinks = (parsedPage: number, maxPage: number, fullUrl: string, restQuery: Record<string, string | string[]>) => {
    const getPrevPage: (page: number) => {page?: number} = (p: number) => (p <= 2 ? {} : {page: p - 1}); // do not add `page` param to first page link
    const relPrev = parsedPage === 1 ? undefined : appendQueryString(appendQueryString(fullUrl, restQuery), getPrevPage(parsedPage));
    const relNext = parsedPage < maxPage ? appendQueryString(appendQueryString(fullUrl, restQuery), {page: parsedPage + 1}) : undefined;

    return {
        relPrev,
        relNext
    };
};
