import {DependencyList, RefObject, useEffect} from "react";

export const useClickOutside = (ref: RefObject<HTMLElement>, onClickOutside: (event: Event) => void, deps?: DependencyList) => {
    useEffect(() => {
        const clickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
                onClickOutside(event);
            }
        };

        if (window.PointerEvent) {
            document.addEventListener("pointerdown", clickOutside, true);
        } else {
            document.addEventListener("touchstart", clickOutside, true);
            document.addEventListener("mousedown", clickOutside, true);
        }

        return () => {
            if (window.PointerEvent) {
                document.removeEventListener("pointerdown", clickOutside, true);
            } else {
                document.removeEventListener("touchstart", clickOutside, true);
                document.removeEventListener("mousedown", clickOutside, true);
            }
        };
    }, [ref, ...(deps || [])]);
};
