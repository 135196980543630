import * as React from "react";
import styled from "@emotion/styled";

import {IOfferDetailFormPlaceholder} from "../../app/interfaces/response/offer_detail";

interface IOwnProps {
    config: IOfferDetailFormPlaceholder;
}

export const ApplicationPlaceholder = (props: IOwnProps) => {
    const {config} = props;
    const replaceAll = (text: string, q = " ", r = ""): string => text.split(q).join(r);

    if (!config.form_placeholder_text && !config.form_placeholder_phone) {
        return null;
    }

    return (
        <Wrapper>
            {config.form_placeholder_text && <p>{config.form_placeholder_text}</p>}

            {config.form_placeholder_phone && <a href={`tel:${replaceAll(config.form_placeholder_phone)}`}>{config.form_placeholder_phone}</a>}
        </Wrapper>
    );
};

/*
Styles
 */
const Wrapper = styled("div")`
    flex: 1;
    text-align: center;
    line-height: 1.6;

    a {
        color: ${(props) => props.theme?.colors.blue};
        text-decoration: none;
        font-size: 18px;

        &:hover {
            text-decoration: underline;
        }
    }
`;
