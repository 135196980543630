import {Dispatch} from "redux";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAddIndividualOfferMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Dodaj darmowe ogłoszenie - sprzedaj mieszkanie, dom lub działkę! gethome.pl`;
    const description = "Jak sprzedać lub wynająć nieruchomość? Dodaj darmowe ogłoszenie na gethome.pl";
    const url = route.url;
    const robots = "index, follow";
    dispatch(
        updateMetaData(title, description, route, {
            canonical: url,
            url,
            hideSuffix: true,
            robots
        })
    );

    return Promise.resolve(true);
};
