import {Dispatch} from "redux";
import {IInvestmentDetailParams} from "@web2/gh_routes";

import {IAction} from "../../app/interfaces/IAction";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {getMappedLocationPath} from "../../app/utils/get_offer_location_obj_from_path";
import {GtmContentType} from "../google_tag_manager/utils/gtm_content_type";
import {getGtmMarketType, getSearchResultsMarketType, GTMMarketType} from "./utils/get_market_type";
import {getOfferDetailContentPropertyType} from "./utils/get_offer_detail_content_property_type";
import {getGtmOfferListViewType} from "./utils/get_offer_list_page_type";
import {ViewType} from "./view_type";

export interface ISetViewTypeAction extends IAction {
    current: ViewType;
    data: Partial<IViewTypeMetaData>;
}

export interface IViewTypeMetaData {
    searchParams: string; // Parametry widoczne w URL po wyszukiwaniu i zawężeniu przez filtry, np. price_per_sqm__gte=10&price_per_sqm__lte=11551&rooms=2,3
    contentRegion: string; // Kombinacja trzech poziomów szczegółowości lokalizacji, np. mazowieckie_warszawa_wola (full_name)
    contentRealtor: string; // Nazwa agencji pośrednictwa (o ile możliwe do określenia)
    contentMarket: GTMMarketType; // Grupowanie stron po wybranym rynku
    contentPropertyType: GtmContentType;
    regionSlug: string;
}

export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
const setViewType =
    (viewType: ViewType, data: Partial<IViewTypeMetaData> = {}) =>
    async (dispatch: Dispatch) => {
        dispatch({type: SET_VIEW_TYPE, current: viewType, data});
        return true;
    };

export const setHomepageViewTypeAtRoute = () => setViewType(ViewType.HOMEPAGE);
export const setBlogArticleListViewTypeAtRoute = () => setViewType(ViewType.BLOG);

// offer
export const setOfferListViewTypeAtRoute =
    (services: Partial<IServices>, route: IRouteState<IInvestmentDetailParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const searchParams = appendQueryString("", route.query).substr(1) || undefined;
        const contentRegion = getState().offerList.location.location?.full_name ?? undefined;
        const regionSlug = getState().offerList.location.location?.slug;

        const dealType = getState().offerList.latestQuery.deal_type;
        const pageType = getGtmOfferListViewType(dealType);

        const contentMarket = getSearchResultsMarketType(getState().search.formValues.offer_type, dealType) ?? undefined;

        return dispatch(
            setViewType(pageType, {
                searchParams,
                contentRegion,
                contentMarket,
                regionSlug
            })
        );
    };

export const setAgentViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const contentRealtor = getState().agent.agent.agency.name;

    return dispatch(
        setViewType(ViewType.AGENT, {
            contentRealtor,
            contentPropertyType: GtmContentType.AGENT
        })
    );
};

export const setAgencyViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const contentRealtor = getState().agency.agency.name;

    return dispatch(
        setViewType(ViewType.AGENCY, {
            contentRealtor,
            contentPropertyType: GtmContentType.AGENCY
        })
    );
};

export const setDeveloperViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const contentRealtor = getState().agency?.developer?.name;

    return dispatch(
        setViewType(ViewType.DEVELOPER, {
            contentRealtor,
            contentPropertyType: GtmContentType.DEVELOPER
        })
    );
};

export const setOfferDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const mappedLocationPath = getMappedLocationPath(getState().offer.offer.property.location?.path);
    const townSlug = mappedLocationPath?.TOWN?.slug;
    const citySlug = mappedLocationPath?.CITY?.slug;
    const regionSlug = townSlug || citySlug || getState().offer.offer.property.location?.slug;

    const viewType = getState().offer.offerDetailViewType === "offerList" ? ViewType.OFFER_IN_LISTING : ViewType.OFFER;
    const contentRealtor = getState().offer.offer.agency?.name;
    const contentRegion = getState().offer.offer.property.location?.full_name;
    const contentMarket = getGtmMarketType(getState().offer.offer.market_type) ?? undefined;
    const contentPropertyType = getOfferDetailContentPropertyType(getState().offer.offer.property.type) ?? undefined; // "apartment", "house",  "lot"

    return dispatch(
        setViewType(viewType, {
            contentRealtor,
            contentRegion,
            contentMarket,
            contentPropertyType,
            regionSlug
        })
    );
};

export const setInvestmentDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const contentRealtor = getState().investment.investment?.developer.name;
    const contentRegion = getState().investment.investment?.location.full_name;
    const regionSlug = getState().investment.investment?.location.slug;

    return dispatch(
        setViewType(ViewType.INVESTMENT, {
            contentRealtor,
            contentRegion,
            contentMarket: GTMMarketType.PRIMARY_MARKET,
            contentPropertyType: GtmContentType.INVESTMENT,
            regionSlug
        })
    );
};

export const setArticleDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    return dispatch(setViewType(ViewType.BLOG_ARTICLE, {}));
};

export const setArticleAuthorsViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    return dispatch(setViewType(ViewType.BLOG_AUTHORS, {}));
};

export const setArticleAuthorViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const contentRealtor = getState().authors.authorDetail.author.name;

    return dispatch(
        setViewType(ViewType.BLOG_AUTHOR, {
            contentRealtor,
            contentPropertyType: GtmContentType.AUTHOR
        })
    );
};

export const setPageDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    return dispatch(setViewType(ViewType.PAGES_PAGE, {}));
};
