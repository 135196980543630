import * as React from "react";
import {MouseEvent, ReactNode, useRef, useState} from "react";
import {useTheme} from "@emotion/react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {OFFER_LIST_LIST_TESTID} from "@web2/gh_page_object_models";
import {OFFER_BOX_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";
import {HeartIcon} from "@web2/icons";
import {useCallbackOnIntersection, useMounted} from "@web2/react_utils";
import {useUserDevice} from "@web2/user-device";

import {IInvestmentSellStatusType} from "../../../../app/interfaces/response/investment";
import {IOfferBoxPicture, IOfferBoxPlanPicture} from "../../../../app/interfaces/response/offer_detail";
import {ICoordinates} from "../../../../app/interfaces/response/offer_list";
import {sizeFormat} from "../../../../app/utils/number";
import {IApplicationOfferInvestment} from "../../../../application/components/Application";
import {getThemeVariable} from "../../../../styles/linaria_variable_factory";
import {favouriteClickAlgolyticsHit} from "../../../../tracking/algolytics/click/favourite_click_hit";
import {GtmSource} from "../../../../tracking/google_tag_manager/utils/gtm_source";
import {OfferDealType, OfferMarketType, PropertyType} from "../../../utils/constants_offer";
import {getIsNewOffer} from "../../../utils/get_is_new_offer";
import {getOfferHeading} from "../../../utils/get_offer_heading";
import {OfferBadge} from "./components/OfferBadge";
import {OfferBoxDetail} from "./components/OfferBoxDetail";
import {OFFER_BOX_GALLERY_HEIGHT, OfferBoxGallery} from "./components/OfferBoxGallery";
import {SingleGalleryImagePlaceholder} from "./components/SingleGalleryImagePlaceholder";
import {OfferBoxGeneratedDescription} from "./OfferBoxGeneratedDescription";

export interface IOfferBoxOffer {
    created_at: string;
    coordinates: ICoordinates;
    deal_type: OfferDealType;
    description?: string;
    id: string;
    investment:
        | (IApplicationOfferInvestment & {
              id: string;
              investment_summary?: {
                  offer_count: number;
              };
              is_active: boolean;
              limited_presentation: boolean;
              name: string;
              offer_count?: number;
              sell_status: IInvestmentSellStatusType;
              slug: string;
              location?: {
                  path: {name: string; slug: string}[];
                  short_name: string;
              };
          })
        | null;
    market_type: OfferMarketType;
    name: string;
    offer_type?: string[];
    pictures: IOfferBoxPicture[] | null;
    price: {
        total: number | null;
        per_sqm: number | null;
        currency: string;
    };
    property: {
        address?: string | null; // address is not available on 'lots' offers
        address_details?: {
            city: string;
            street: string;
            district: string;
            housing_estate: string;
        } | null;
        attic: boolean | null;
        balconies?: number | null;
        bathroom_number?: number | null;
        building_year?: number | null;
        floors?: number;
        garden?: number | null;
        group_lot_info?: {shape?: string | null};
        heat?: string | null;
        location?: {
            path: {
                name: string;
                slug: string;
                type: string;
            }[];
            short_name: string;
        } | null;
        lot_size?: number | null;
        lot_type?: string | null;
        plan_picture?: IOfferBoxPlanPicture | null;
        room_number?: number | null;
        size: number | null;
        terraces?: number | null;
        type: PropertyType;
    };
    show_price?: boolean;
    show_price_m2?: boolean;
    slug: string;
    is_private: boolean;
    overbudget: boolean;
}

export type IOnOfferBoxModalOpen = (data: {offer: IOfferBoxOffer; gtmSource: GtmSource}) => void;

interface IProps {
    offer: IOfferBoxOffer;
    className?: string;
    isFavourite?: boolean;
    isInvestment?: boolean;
    isInvestmentWithHref?: boolean;
    isStaticFavourite?: boolean;
    isVisited: boolean;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    setIsFavourite: (offerId: string) => void;
    preventLazyLoad?: boolean;
    loadImagesInitially?: boolean;
    allowImageFadein?: boolean; // will raise LCP when used after user visits page
    forceSliderInit?: boolean;
    openApplicationModal: IOnOfferBoxModalOpen;
    renderCTA?: ReactNode;
}

export const OfferBox = (props: IProps) => {
    const {loadImagesInitially = true} = props;
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const [showGallery, setShowGallery] = useState(loadImagesInitially);
    const holderRef = useRef(null);

    const toggleDescription = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDescriptionOpen((prevState) => !prevState);
    };

    const {offer} = props;
    const {property} = props.offer;
    const propertyType = property.type;
    const isMounted = useMounted();
    const {isMobile} = useUserDevice();
    const theme = useTheme();

    const isNew = getIsNewOffer(offer);

    const toggleFavourite = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        favouriteClickAlgolyticsHit({offer: {id: offer.id}, isMobile: isMobile, checked: !props.isFavourite});
        props.setIsFavourite(offer.id);
    };

    const loadImage = () => {
        if (!showGallery) {
            setShowGallery(true);
        }
    };

    useCallbackOnIntersection({ref: holderRef, callback: loadImage, deps: [], threshold: 0});

    const offerUrlParams = parseOfferSlugToObject(offer.slug);
    const offerBoxHref = appLink.fullOffer.detail.base(offerUrlParams);
    const offerPropertyType = props.offer.property.type;
    const propertySize = props.offer.property.size ? props.offer.property.size.toString() : "";
    const hrefTitle = `${getOfferHeading(offer).heading} ${offerPropertyType === PropertyType.LOT ? ` ${sizeFormat(propertySize)} m2` : ""}`;

    return (
        <OfferBoxHolder
            data-testid={OFFER_LIST_LIST_TESTID.OFFER_BOX}
            className={props.className}
            onClick={props.onClick}
            renderCTA={!!props.renderCTA}
            propertyType={propertyType}
            ref={holderRef}
        >
            {offer.is_private || offer.investment ? <OfferBadge isPrivateOffer={offer.is_private} investment={offer.investment} /> : null}

            <a href={offerBoxHref} title={hrefTitle} className={offerBoxLink} data-testid={OFFER_BOX_TESTID.LINK}>
                <aside className={offerBoxGalleryLink}>
                    {showGallery ? (
                        <OfferBoxGallery
                            offerPictures={offer.pictures}
                            propertyPlanPicture={property.plan_picture}
                            offerName={offer.name}
                            preventLazyLoad={props.preventLazyLoad}
                            forceSliderInit={props.forceSliderInit}
                            allowImageFadeIn={props.allowImageFadein}
                        />
                    ) : (
                        <div className={galleryPlaceholderStyle}>
                            <SingleGalleryImagePlaceholder />
                        </div>
                    )}

                    {offer.description && (
                        <div className={descriptionToggleBtn} onClick={toggleDescription}>
                            i
                        </div>
                    )}

                    {isMounted && (
                        <>
                            {isNew && <NewOfferLabel>Nowa oferta</NewOfferLabel>}

                            {props.isVisited && <VisitedOfferLabel>Odwiedzona</VisitedOfferLabel>}
                        </>
                    )}

                    <FavIcon onClick={toggleFavourite}>
                        {(props.isFavourite && isMounted) || props.isStaticFavourite ? (
                            <HeartIcon height={24} width={24} />
                        ) : (
                            <HeartIcon height={24} width={24} mainColor={theme.colors.text_color} />
                        )}
                    </FavIcon>
                </aside>

                <OfferBoxDetail
                    offer={offer}
                    openApplicationModal={props.openApplicationModal}
                    noTooltip={!!props.isStaticFavourite}
                    isInvestmentWithHref={props.isInvestmentWithHref}
                    isInvestment={!!props.isInvestment}
                    renderCTA={props.renderCTA}
                />
            </a>

            {offer.description && <OfferBoxGeneratedDescription isOpen={isDescriptionOpen} onClose={toggleDescription} offer={offer} />}
        </OfferBoxHolder>
    );
};

export const handleOfferBoxMetaClick = (e: React.MouseEvent<HTMLElement>, callback: () => void) => {
    if (!e.ctrlKey && !e.metaKey) {
        e.preventDefault();
        e.stopPropagation();

        callback();
    }
};

export const OFFERBOX_FULL_WIDTH_BREAKPOINT = 640;
export const OFFERBOX_WITH_CTA_BUTTON_HEIGHT = 344;
export const OFFERBOX_WITH_CTA_BUTTON_HEIGHT_LOTS = 344;
export const OFFERBOX_STANDARD_HEIGHT = 272;
export const OFFERBOX_WIDTH = 306;
export const OFFERBOX_MAX_WIDTH = 360;
export const OFFERBOX_MAX_MOBILE_BREAKPOINT = 639;

export const OfferBoxHolder = styled.div<{
    renderCTA?: boolean | undefined;
    propertyType?: PropertyType;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 400ms ease-out;
    border-radius: ${getThemeVariable("other-border_radius")};
    border: 1px solid ${getThemeVariable("colors-gray_very_dark")};
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    height: ${(props) =>
        props.renderCTA
            ? props.propertyType === PropertyType.LOT
                ? OFFERBOX_WITH_CTA_BUTTON_HEIGHT_LOTS + "px"
                : OFFERBOX_WITH_CTA_BUTTON_HEIGHT + "px"
            : OFFERBOX_STANDARD_HEIGHT + "px"};
    width: ${OFFERBOX_WIDTH}px;
    min-width: ${OFFERBOX_WIDTH}px;
    margin-right: 0.5rem;

    &:hover {
        box-shadow: 0 2px 21px 2px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        width: 100%;
        max-width: ${OFFERBOX_MAX_WIDTH}px;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
        margin-right: 0;
    }
`;

const offerBoxLink = css`
    color: ${getThemeVariable("colors-text_color")};
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: ${getThemeVariable("colors-text_color")};
        text-decoration: none;
    }
`;

const OfferLabel = styled.div`
    position: absolute;
    top: 8px;
    left: 10px;
    padding: 0.3rem 1rem 0.2rem;
    border-radius: 0.3rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.6;
    color: white;
`;

const offerBoxGalleryLink = css`
    position: relative;
`;

const galleryPlaceholderStyle = css`
    height: ${OFFER_BOX_GALLERY_HEIGHT}px;
    width: 100%;
`;

const NewOfferLabel = styled(OfferLabel)`
    background-color: ${getThemeVariable("colors-brand_success")};
`;

const VisitedOfferLabel = styled(OfferLabel)`
    background-color: ${getThemeVariable("colors-gray_warm")};
`;

export const FavIcon = styled.div`
    position: absolute;
    z-index: 2;
    height: 30px;
    width: 30px;
    top: 0.8rem;
    right: 1.1rem;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const descriptionToggleBtn = css`
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    right: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    box-shadow: 1px 1px ${getThemeVariable("colors-gray_warm")};
`;
