import * as React from "react";
import {useTheme} from "@emotion/react";
import {CloseButtonIcon} from "@web2/icons";

import {ArrowDownIcon} from "../../../offer/list/components/icons/ArrowDownIcon";
import {ArrowUpIcon} from "../../../offer/list/components/icons/ArrowUpIcon";
import {filterHolder, filterLabelText} from "../atoms/atoms";
import {ChildHolderWide} from "../atoms/ChildHolder";
import {FilterLabel} from "../atoms/FilterLabel";

interface IProps {
    toggleDropdown: (isOpen: boolean) => void;
    label: string;
    isDropdownOpened: boolean;
    hasValue: boolean;
    isDeactivated?: boolean;
    onClearField?: () => void;
    isMobileWidth: boolean;
    children: JSX.Element;
    rightDropdown?: boolean;
    onClear?: () => void;
}

export const SearchFiltersFilterMore = (props: IProps) => {
    const onLabelClick = () => {
        props.toggleDropdown(true);
    };

    const onUpArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.toggleDropdown(false);
    };

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            props.toggleDropdown(false);
        }

        if (e.key === "Enter") {
            props.toggleDropdown(false);
        }
    };
    const onClearFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (props.onClear) {
            props.onClear();
        }

        props.toggleDropdown(false);
    };

    const theme = useTheme();
    return (
        <div className={filterHolder} onKeyDown={onKeyDownHandler}>
            {props.label && (
                <FilterLabel
                    isActive={props.isDropdownOpened}
                    hasValues={props.hasValue}
                    tabIndex={-1}
                    onClick={props.isDeactivated ? () => null : props.isDropdownOpened ? onUpArrowClick : onLabelClick}
                >
                    <span className={filterLabelText}>{props.label}</span>
                    {props.hasValue ? (
                        <div onClick={(e) => onClearFieldClick(e)}>
                            <CloseButtonIcon mainColor={theme.colors.brand_primary} width={9} height={9} />
                        </div>
                    ) : props.isDropdownOpened ? (
                        <ArrowUpIcon size="1.2" fillColor={theme.colors.brand_primary} />
                    ) : (
                        <ArrowDownIcon size="1.2" fillColor={theme.colors.brand_primary} />
                    )}
                </FilterLabel>
            )}

            {(props.isDropdownOpened || props.isMobileWidth) && <ChildHolderWide rightDropdown={props.rightDropdown}>{props.children}</ChildHolderWide>}
        </div>
    );
};
