import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const EducationIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "4.2mm"} height={props.height || "3.44mm"} viewBox="0 0 11.92 9.75">
            <polygon points="5.96 0 0 3.25 5.96 6.5 10.83 3.84 10.83 7.58 11.92 7.58 11.92 3.25 5.96 0" fill={props.mainColor || "#fff"} />
            <polygon points="2.17 5.51 2.17 7.68 5.96 9.75 9.75 7.68 9.75 5.51 5.96 7.58 2.17 5.51" fill={props.mainColor || "#fff"} />
        </svg>
    );
};
