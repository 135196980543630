import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {bugsnagReleaseStage, buildImageTag} from "../read_environment_variables";

const apiKey = "bc0635c3d111c0b2298f04a69b756514"; // GH-web2-client

export const bugsnagClient = Bugsnag.start({
    apiKey,
    appVersion: buildImageTag,
    plugins: [new BugsnagPluginReact()],
    releaseStage: bugsnagReleaseStage,
    enabledReleaseStages: ["production", "staging"]
});
