import {capitalizeFirst} from "@web2/nodash";
import {numberWithDelimiter} from "@web2/string_utils";

import {ILocationStatisticsElementApartment} from "../../../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../../../app/utils/number";
import {returnPlurizedValues} from "../../utils/return-pluralized-values";
import {Heading} from "./Heading";

export const AfterMarketCity = (props: {
    locationName: string;
    locationDeclination: string | undefined;
    statistics: ILocationStatisticsElementApartment | undefined;
}) => {
    const {locationName, locationDeclination, statistics} = props;

    const minSize = statistics?.min_size;
    const maxSize = statistics?.max_size;

    const headingString = `Rynek wtórny ${locationName}`;
    const pluralized = statistics?.count && returnPlurizedValues(statistics.count);

    const spaceRange = (
        <>
            {minSize !== maxSize ? (
                <>
                    od <strong>{minSize || 0}</strong> m<sup>2</sup> do <strong>{maxSize}</strong> m<sup>2</sup>
                </>
            ) : (
                <>
                    <strong>{minSize || 0}</strong> m<sup>2</sup>
                </>
            )}
        </>
    );

    return (
        <>
            <Heading content={headingString} />
            {statistics && pluralized !== 0 && (
                <p>
                    Interesuje Cię rynek wtórny? {locationDeclination && capitalizeFirst(locationDeclination)} mamy{" "}
                    {(statistics?.count && numberWithDelimiter(sizeFormat(statistics?.count))) || 0} {pluralized ? pluralized.offersPluralizeWhich : "ofert"} z
                    rynku wtórnego o powierzchni {spaceRange}.{" "}
                    {statistics.count > 1 ? (
                        <>
                            Średnia powierzchnia mieszkania z drugiej ręki {locationDeclination} to {statistics?.avg_size} m<sup>2</sup>.
                        </>
                    ) : null}
                </p>
            )}
        </>
    );
};
