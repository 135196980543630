import {filter, includes, isEmpty, isEqual, pick} from "lodash";
import {Dispatch} from "redux";
import {createFormActions, createFormActionTypes} from "@web2/form2";

import {IFeaturesTypes, SearchFeaturesApartment, SearchFeaturesHouse, SearchFeaturesLots} from "../../app/interfaces/response/server_list_response";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {ISearchResultRouteParams} from "../../offer/list/actions/fetch_offer_list_at_route";
import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {searchFormFields} from "../utils/form_types";
import {fromQueryToFormValues} from "../utils/from_query_values";
import {TypeSearchFilterOptions} from "../utils/TypeSearchFilterOptions";
import {SearchTab} from "./fetch_search_all_action";
import {fetchSearchInvestmentById} from "./fetch_search_investment";

const Multi_query_search_actions = "offer/MULTI_QUERY_SEARCH_FORM";
export const searchFormTypes = createFormActionTypes(Multi_query_search_actions);
export const searchFormActions = createFormActions<Record<keyof typeof searchFormFields, any>>(searchFormTypes);
export type FormValuesType = Record<keyof typeof searchFormFields, any>;

export const resetSearchFormWithQuery = (query: IOfferListQuery) => async (dispatch: Dispatch, getState: () => IStore) => {
    const formValues: FormValuesType = fromQueryToFormValues(searchFormFields, query);

    const calculateOfferType = () => {
        if (formValues.deal_type !== "rent") {
            return isEmpty(query.offer_type_query) ? formValues.offer_type : query.offer_type_query;
        }
        const foundAfterMarket = filter(
            formValues.offer_type,
            (oT) => oT === TypeSearchFilterOptions.AFTERMARKET_APARTMENT || oT === TypeSearchFilterOptions.AFTERMARKET_HOUSE
        );
        if (foundAfterMarket) {
            return foundAfterMarket;
        }
        return [TypeSearchFilterOptions.AFTERMARKET_APARTMENT, TypeSearchFilterOptions.AFTERMARKET_HOUSE];
    };

    const {location} = getState().offerList.location;
    const mainFormValues = await prepareSearchValue(query, location);

    const initialFormValues = {
        ...formValues,
        ...(mainFormValues ? mainFormValues : {}), // main priority - special case fields,
        offer_type: calculateOfferType()
    };

    await dispatch(resetSearchForm(initialFormValues));
    return initialFormValues;
};

export const resetSearchForm = (formValues: FormValuesType) => async (dispatch: Dispatch) => {
    dispatch(searchFormActions.replace(formValues));
};

export const resetClientOnlySearchFormAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams, {searchFormValues?: FormValuesType}>, prevRoute: IPrevRouteState | null) =>
    async (dispatch: Dispatch, getState: () => IStore): Promise<boolean> => {
        if (prevRoute == null) {
            // server side we do not reset form, because `Search` mount lifecycle does this instead
            return true;
        }

        const {latestQuery} = getState().offerList;
        const queryFormValues = pick(latestQuery, [
            "location",
            "search",
            "search_name",
            "offer_type",
            "deal_type",
            "rooms",
            "price__gte",
            "price__lte",
            "price_per_sqm__gte",
            "price_per_sqm__lte",
            "size__gte",
            "size__lte",
            "geo_point",
            "investment",
            "offer_type_query",
            "floor__lte",
            "floor__gte",
            "construction_year__lte",
            "construction_year__gte",
            "available_from__lte",
            "available_from__gte",
            "features",
            "is_private"
        ]) as IOfferListQuery;

        if (!route.state?.searchFormValues) {
            await dispatch(resetSearchFormWithQuery(queryFormValues));
            return true;
        }

        const offerType = route.state?.searchFormValues.offer_type.join(",");
        const featuresArray: string[] = [];
        const includesHouses = includes(offerType, "house");
        const includesApartment = includes(offerType, "apartment");
        // assign only valid features
        route.state?.searchFormValues.features.forEach((feature: IFeaturesTypes) => {
            if (includesHouses && !includesApartment) {
                Object.values(SearchFeaturesHouse).map((v) => {
                    v == feature ? featuresArray.push(feature) : null;
                });
            }
            if (includesApartment && !includesHouses) {
                Object.values(SearchFeaturesApartment).map((v) => {
                    v == feature ? featuresArray.push(feature) : null;
                });
            }
            if (!includesApartment && !includesHouses) {
                Object.values(SearchFeaturesLots).map((v) => {
                    v == feature ? featuresArray.push(feature) : null;
                });
            }
        });

        const validFormValues = {
            ...route.state?.searchFormValues,
            deal_type: latestQuery.deal_type,
            features: featuresArray
        };

        const currentFormValues = getState().search.formValues;
        if (!isEqual(validFormValues, currentFormValues)) {
            await dispatch(resetSearchForm(validFormValues));
        }

        return true;
    };

const prepareSearchValue = async (query: IOfferListQuery, location: ILocation | null) => {
    if (query.geo_point) {
        // searching google places
        const [lat, lng] = query.geo_point.split("|");
        return {
            search: {
                label: query.search_name || "",
                place: {
                    description: query.search_name
                },
                location: {
                    lat,
                    lng
                },
                tabType: SearchTab.Places
            }
        };
    }

    if (query.location && location) {
        return {
            search: {
                label: location.short_name,
                suggestions: location,
                tabType: SearchTab.SearchSuggestions
            }
        };
    }
    if (query.investment) {
        const values = await fetchSearchInvestment(query);
        return values;
    }
    return null;
};

export const fetchSearchInvestment = async (query: IOfferListQuery) => {
    const investmentId = query.investment;
    const investment = await fetchSearchInvestmentById(investmentId as string);
    return {
        search: {
            label: investment.name,
            investment,
            tabType: SearchTab.SearchSuggestions
        }
    };
};
