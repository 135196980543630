import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const ShoppingBasketIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "4.17mm"} height={props.height || "3.75mm"} viewBox="0 0 11.82 10.62">
            <path
                d="M11.28,3.91H8.71L6.36.24A.53.53,0,0,0,5.91,0a.51.51,0,0,0-.44.24L3.11,3.91H.54A.55.55,0,0,0,0,4.47a.77.77,0,0,0,0,.15L1.39,9.81a1.06,1.06,0,0,0,1,.81h7a1.08,1.08,0,0,0,1-.81l1.37-5.19V4.47A.55.55,0,0,0,11.28,3.91ZM5.91,8.39A1.12,1.12,0,1,1,7,7.27,1.11,1.11,0,0,1,5.91,8.39ZM4.3,3.91,5.91,1.44,7.52,3.91Z"
                fill={props.mainColor || "#fff"}
            />
        </svg>
    );
};
