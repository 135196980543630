import {upperFirst} from "lodash";
import {Dispatch} from "redux";
import {appLink, IArticleDetailAndListParams} from "@web2/gh_routes";

import {IArticleListArticle} from "../../app/interfaces/response/article_list";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {ArticleCategorySlug, getCategoryNameExceptionsBySlug} from "../utils/category_name_utils";

export const updateBlogCategoryMetaData =
    (
        _1: Partial<IServices>,
        route: IRouteState<IArticleDetailAndListParams>,
        prevRoute: IPrevRouteState | null,
        prevActionResult: {page: number; count: number; page_size: number; articles: IArticleListArticle[]}
    ) =>
    (dispatch: Dispatch, getState: () => IStore) => {
        const baseUrl = `${ghCommonUrl}${appLink.article.detailOrCategoryList({slug: route.params.slug})}`;
        const baseUrlWithPage = (page: number) => `${baseUrl}?page=${page}`;

        const category = getState().articles.categoryList.categories.find((catData) => catData.slug === route.params.slug);
        const categoryName = (getCategoryNameExceptionsBySlug(route.params.slug) || category?.name || "").toLowerCase();

        const page = parseInt(route.query.page, 10) || 1;
        const currentUrl = appendQueryString(appendQueryString(baseUrl, {}), {page});
        const nextPage = page + 1;
        const prevPage = page - 1;
        const pageCount = Math.ceil(prevActionResult.count / prevActionResult.page_size);
        const isFirstPage = page === 1;

        const relNext = route.params.slug !== ArticleCategorySlug.DISTRICTS_DESCRIPTION && nextPage <= pageCount ? baseUrlWithPage(nextPage) : undefined;
        const relPrev = (() => {
            if (isFirstPage || route.params.slug === ArticleCategorySlug.DISTRICTS_DESCRIPTION) {
                return;
            }
            if (page > 2) {
                return baseUrlWithPage(prevPage);
            }
            return baseUrl;
        })();

        const canonical = page === 1 ? baseUrl : currentUrl;
        const description = `Chcesz być na bieżąco z najnowszymi informacjami? Sprawdź naszą serię artykułów z kategorii ${categoryName} na blogu GetHome${
            isFirstPage ? "" : ` - strona ${page}`
        }. ✅`;

        const title = upperFirst(`${upperFirst(categoryName)} - Gethome Blog${!isFirstPage ? ` - strona ${page}` : ""}`);

        const robots = "index, follow";

        dispatch(
            updateMetaData(title, description, route, {
                canonical,
                relNext,
                relPrev,
                robots,
                hideSuffix: true
            })
        );
        return Promise.resolve();
    };
