import {Dispatch} from "redux";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {IOfferSummary, IPlaceholderConfiguration} from "../../app/interfaces/response/offer_list";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";

export interface IAgent {
    about: string;
    about_data:
        | {
              agent_address: {
                  city: string;
                  street: string;
                  building_number: string;
                  apartment_number: string;
              };
              experience: number | null;
              satisfied_customer_count: number | null;
              voivodeships: string[];
              offers: {
                  apartment_sell: number;
                  apartment_rent: number;
                  house_sell: number;
                  house_rent: number;
                  lot_sell: number;
                  lot_rent: number;
              };
          }
        | {};
    agency: {
        created_at: string;
        id: string;
        is_active: boolean;
        lead_form_configuration: IPlaceholderConfiguration;
        link: string;
        name: string;
        logo_picture: {
            a_log_360: string;
            a_log_180: string;
            a_log_100: string;
        } | null;
        slug: string;
        status: number;
        type: string;
        updated_at: string;
    };
    background_picture: {
        a_bg_768x340: string;
        a_bg_1366x460: string;
        a_bg_1440x460: string;
        a_bg_1920x460: string;
    } | null;
    created_at: string;
    email: string;
    facebook: string;
    id: string;
    last_name: string;
    license_number: string;
    link: string;
    linkedin: string;
    name: string;
    offer_summary: IOfferSummary;
    overbudget: boolean;
    picture: {
        a_img_180x180: string;
        a_img_100x100: string;
    } | null;
    phone_number: string;
    slug: string;
    specialization: string;
    updated_at: string;
}

export const fetchAgentDetailTypes = createRequestActionTypes({view: "Agent", type: "GET", name: "AgentDetail"});

export const fetchAgentDetailAtRoute = (services: Partial<IServices>, route: IRouteState<{agentSlug: string}>) => (dispatch: Dispatch) => {
    dispatch({type: fetchAgentDetailTypes.start});
    const agentSlug = route.params.agentSlug;
    const url = apiLink.agent.detail({})({agentSlug: agentSlug});

    return getRequest({}, url)
        .then((response: IAgent) => {
            dispatch({type: fetchAgentDetailTypes.success, result: response});
            return response;
        })
        .catch(
            catch404(async (err) => {
                dispatch({type: fetchAgentDetailTypes.error, err});
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        )
        .catch((err) => {
            dispatch({type: fetchAgentDetailTypes.error, err});
        });
};
