import {v4 as uuid} from "uuid";

import {IGtmProduct} from "../utils/get_gtm_product";
import {GtmContactType} from "../utils/gtm_contact_type";
import {getGtmSource} from "../utils/gtm_source";
import {gtmEcommerceEventWithOffer, gtmEcommerceEventWithoutOffer, IEventWithOfferProps, IEventWithoutOfferProps} from "./gtm_event_with_offer";

export enum GtmInquiryOverbudget {
    FALSE = 0, // overbudget: false
    TRUE // overbudget: true
}

interface IGtmInquiry {
    event: "inquiry";
    contactTypeHit?: GtmContactType;
    offer_status?: GtmInquiryOverbudget;
    ecommerce: {
        purchase: {
            actionField: {
                affiliation: string;
                id: string;
                revenue: "1.00";
                shipping: "0.00";
                tax: "0.00";
            };
            products: [IGtmProduct];
        };
    };
}

export const gtmInquiryWithOffer = (props: IEventWithOfferProps) => {
    gtmEcommerceEventWithOffer(props, getGtmInquiryData);
};

export const gtmInquiryWithoutOffer = (props: IEventWithoutOfferProps) => {
    gtmEcommerceEventWithoutOffer(props, getGtmInquiryData);
};

const getGtmInquiryData = (product: IGtmProduct, props: IEventWithOfferProps | IEventWithoutOfferProps): IGtmInquiry => ({
    event: "inquiry",
    contactTypeHit: props.contactType,
    offer_status: props.offer_status,
    ecommerce: {
        purchase: {
            actionField: {
                affiliation: getGtmSource(props.gtmSource),
                revenue: "1.00",
                shipping: "0.00",
                tax: "0.00",
                id: uuid()
            },
            products: [product]
        }
    }
});
