import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateHomepageMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Gethome | ogłoszenia nieruchomości - mieszkania, domy, działki`;
    const description = `Ogłoszenia nieruchomości - mieszkania, domy, działki na sprzedaż i wynajem. Oferty bezpośrednie, od agencji oraz deweloperów - sprawdź gethome.pl!✅`;
    const robots = "index, follow";
    const url = `${ghCommonUrl}${appLink.mainPage()}`;

    dispatch(updateMetaData(title, description, route, {robots, canonical: url}));

    return Promise.resolve(true);
};
