import {RefObject, useCallback, useEffect, useRef} from "react";
import {useLocation} from "react-router";
import {debounce} from "lodash";
import {useStateRef} from "@web2/react_utils";

import {autoSearchGTMEvent} from "../../../../../tracking/google_tag_manager/auto_search_toggle";
import {useTriggerMapSearch} from "../use_map_trigger_search";
import {IGoogleMapApi} from "./GoogleMapOfferListDesktop";

export const useOfferListMapEventsDesktop = (mapRef: RefObject<Pick<IGoogleMapApi, "getMapInstance" | "closeInfoWindows">>) => {
    const [userInteracted, setUserInteracted, userInteractedRef] = useStateRef(false);
    const [dragSearchEnabled, setDragSearchEnabled, dragSearchEnabledRef] = useStateRef(true);

    const location = useLocation();

    // we disable one following map event trigger because after init there is a single 'zoom_changed' event that can trigger our search callback
    const disabledMapEventTriggersCountRef = useRef(1);

    useEffect(() => {
        // we update map on location change (ie. after filter change), but we don't want it to trigger additional search on next `idle` event
        disabledMapEventTriggersCountRef.current = 1;
    }, [location]);

    const triggerSearch = useTriggerMapSearch({
        mapRef,
        onAfterSearch: () => setUserInteracted(false)
    });

    const addMapEventListeners = useCallback(() => {
        if (mapRef.current == null) {
            throw new Error("onInitSuccess: ref not found");
        }
        const map = mapRef.current.getMapInstance();
        if (map == null) {
            throw new Error("onInitSuccess: map instance not found");
        }
        google.maps.event.addListener(map, "dragend", () => {
            if (!userInteractedRef.current && !dragSearchEnabledRef.current) {
                setUserInteracted(true);
            }
            if (dragSearchEnabledRef.current) triggerSearch();
        });
        google.maps.event.addListener(
            map,
            "zoom_changed",
            debounce(() => {
                if (disabledMapEventTriggersCountRef.current <= 0) {
                    if (dragSearchEnabledRef.current) {
                        triggerSearch();
                    } else {
                        setUserInteracted(true);
                    }
                } else {
                    --disabledMapEventTriggersCountRef.current;
                }
            }, 500)
        );

        window.addEventListener("click", () => {
            mapRef.current?.closeInfoWindows();
        });
    }, []);

    useEffect(() => {
        mapRef.current?.closeInfoWindows();
    }, [location]);

    const toggleDragSearch = () => {
        autoSearchGTMEvent(!dragSearchEnabled);
        setDragSearchEnabled(!dragSearchEnabled);
    };

    return {
        triggerSearch,
        toggleDragSearch,
        addMapEventListeners,
        disabledMapEventTriggersCountRef,
        userInteracted,
        dragSearchEnabled
    };
};
