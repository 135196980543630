import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {MultiPolygon} from "geojson";
import {includes} from "lodash";
import {Checkbox} from "@web2/form";
import {capitalize} from "@web2/nodash";
import {useClickOutside, useMounted} from "@web2/react_utils";
import {numberWithDelimiter} from "@web2/string_utils";
import {BounceLoader, CenterBox} from "@web2/ui_utils";

import {renderSimpleApp} from "../../../../client/utils/create_hydrate_app";
import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {googleMapsDefaultConfig} from "../../../app/constants/google_maps_default_config";
import {GOOGLE_MAPS_API_KEY} from "../../../app/constants/keys";
import {useOfferBoxHoverListingAction} from "../../../app/hooks/use_offer_box_hover_map_open_offerbox_infowindow";
import {getNormalizedCoordinates} from "../../../app/utils/get_normalized_coordinates";
import {isServer} from "../../../app/utils/read_environment_variables";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {ApplicationModal} from "../../../application/components/ApplicationModal";
import {ApplicationSourceSection, getOfferApplicationSource} from "../../../application/utils/ApplicationSource";
import {getThemeBreakpoint, getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {AlgolyticsSourceSection} from "../../../tracking/algolytics/interaction/application_sent_hit";
import {IGtmOffer} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {gtmClickMapPin} from "../../../tracking/google_tag_manager/gtm_click_map_pin";
import {GtmSource} from "../../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../../tracking/view_type/view_type";
import {IOfferBoxOffer} from "../../detail/components/offer_box/OfferBox";
import {FullOfferApplicationModalHeader, OfferMarketType, OfferTypeSinglePL} from "../../utils/constants_offer";
import {translateOfferType} from "../../utils/utils";
import {DEFAULT_MARKERS_PER_PAGE, fetchOfferListMarkers, IListingMarker} from "../actions/fetch_offer_list_markers";
import {useMapControls} from "../map_utils/use_map_controls";
import {MapIcon} from "./icons/MapIcon";
import {RefreshIcon} from "./icons/RefreshIcon";
import {SatelliteIcon} from "./icons/SatelliteIcon";
import {IGoogleMapApi} from "./map/common/GoogleMapOfferList";
import {MarkerGroupDefinition} from "./map/common/markers";
import {useOfferListMapEvents} from "./map/common/use_offer_list_map_events";
import {OfferListMapInfoWindow} from "./OfferListMapInfoWindow";

import aftermarketIcon from "../../../styles/assets/svg/aftermarket_marker.svg";
import lotMarketIcon from "../../../styles/assets/svg/lot_market_marker.svg";
import primaryMarketIcon from "../../../styles/assets/svg/primary_market_marker.svg";

const LazyGoogleMap = React.lazy(() => import(/* webpackChunkName: "lazy_google_map_chunk" */ "./map/common/GoogleMapOfferList"));

interface IProps extends RouteComponentProps<{}> {
    viewType: ViewType | null;
    markers: IListingMarker[] | null;
    markersRequestState: RequestState;
    locationData: {
        location: {
            outline?: MultiPolygon;
        } | null;
    };
    manualMapBrowsingMode: boolean;
    displayLimitInfo?: boolean;
    hoveredOfferId: string | null;
    favouriteOffers: string[];
    visitedOffers: string[];
    setFavouriteOffer: typeof setFavouriteOffer;
    onOfferClick: (e: React.MouseEvent<HTMLElement>, offer: IGtmOffer) => void;
    isMobileModal?: boolean;
    gestureHandling?: "greedy" | "cooperative"; // https://developers.google.com/maps/documentation/javascript/interaction#controlling_gesture_handling
}

enum MapType {
    ROADMAP = "roadmap",
    SATELLITE = "satellite"
}

const OfferListMapMobileC = (props: IProps) => {
    const infoWindowRef = useRef<HTMLElement | null>(null);
    const infoWindowRefHandler = (elem: HTMLElement | null) => {
        infoWindowRef.current = elem;
    };

    const isMounted = useMounted();
    const [applicationOfferData, setApplicationOfferData] = useState<{offer: IOfferBoxOffer; gtmSource: GtmSource} | null>(null);
    const mapRef = useRef<IGoogleMapApi>(null);

    const {
        type: {mapType, onSetSatelliteMapType, onSetRoadMapType},
        setMapZoom,
        toggleMapSize
    } = useMapControls(mapRef);

    const {triggerSearch, toggleDragSearch, addMapEventListeners, disabledMapEventTriggersCountRef, userInteracted, dragSearchEnabled} =
        useOfferListMapEvents(mapRef);

    const panToMarker = (map: google.maps.Map, markerPosition?: google.maps.LatLng | google.maps.LatLngLiteral | null) => {
        // Every user or script interaction with map ends with 'idle' event, which we use to trigger new search callback.
        // Below we center over the offer marker, but since we don't want it to trigger a new search,
        // we have to ignore a single `idle` event caused by this move.
        if (markerPosition) {
            disabledMapEventTriggersCountRef.current = 1;
            // both `panTo` and `panBy` will be "batched" to a single action
            map.panTo(markerPosition);
            map.panBy(0, -150);
        }
    };

    /**
     * Lifecycle
     */

    useOfferBoxHoverListingAction(mapRef, panToMarker, props.hoveredOfferId);

    useClickOutside(infoWindowRef, () => {
        infoWindowRef.current = null;
        mapRef.current && mapRef.current.closeInfoWindows();
    });

    const dispatch = useDispatch();
    useEffect(() => {
        // fetch markers on mount in mobile view
        dispatch(fetchOfferListMarkers({}));
    }, []);

    /**
     * Configs
     */

    //following config shouldn't change.
    const mapConfig = useMemo(
        () => ({
            ...googleMapsDefaultConfig,
            maxZoom: 19,
            minZoom: 2,
            scrollwheel: true,
            streetViewControl: false,
            disableDefaultUI: true,
            fullscreenControl: false,
            rotateControl: true,
            tilt: 45,
            heading: 90,
            gestureHandling: props.gestureHandling
        }),
        []
    );

    // bug? seems like we should use the same values on options.anchorPoint and infoWindow.pixelOffset.height,
    // otherwise, the infoWindow position can change depending on how many times you hover over the marker
    const ICON_POSITION_OFFSET = 8;

    // 2 following configs should only change when new markers/polygons are coming down the pipe.
    const offerMarkers: Record<string, MarkerGroupDefinition> | null = useMemo(
        () =>
            isServer || !props.markers
                ? null
                : {
                      default: {
                          list: props.markers.map((marker) => {
                              return {
                                  id: marker.id,
                                  coords: [getNormalizedCoordinates(marker.coordinates).lng, marker.coordinates.lat],
                                  icon: includes(marker.offer_type, "lot")
                                      ? lotMarketIcon
                                      : marker.market_type === "aftermarket"
                                        ? aftermarketIcon
                                        : primaryMarketIcon,
                                  options: {scaledSize: {width: 15, height: 15}, anchorPoint: {width: ICON_POSITION_OFFSET, height: ICON_POSITION_OFFSET}},
                                  infoWindow: {
                                      content: renderMarkerTooltip(marker.offer_type, marker.market_type, marker.price?.total, marker.investment?.name),
                                      pixelOffset: {width: 0, height: ICON_POSITION_OFFSET, equals: () => false},
                                      disableAutoPan: false
                                  },
                                  onOpen: () => {
                                      setTimeout(() => {
                                          gtmClickMapPin();
                                          const element = document.querySelector(`#infowindow-${marker.id}`);
                                          if (element) {
                                              // NOTE: we need random key so `infoWindowRefHandler` is triggered on every render
                                              const randomKey = Math.random().toString(36).substring(2);
                                              renderSimpleApp(
                                                  element,
                                                  <div ref={infoWindowRefHandler} key={randomKey}>
                                                      <OfferListMapInfoWindow
                                                          offerId={marker.id}
                                                          setFavouriteOffer={props.setFavouriteOffer}
                                                          visitedOffers={props.visitedOffers}
                                                          openApplicationModal={setApplicationOfferData}
                                                          onOfferClick={props.onOfferClick}
                                                      />
                                                  </div>
                                              );
                                              const map = mapRef.current && mapRef.current.getMapInstance();
                                              map && panToMarker(map, getNormalizedCoordinates(marker.coordinates));
                                          }
                                      }, 0);
                                  },
                                  infoWindowOfferBox: {
                                      content: `<div id=infowindow-${marker.id}  style="padding: 15px 15px 0 15px;"/>`
                                  }
                              };
                          })
                      }
                  },
        [props.markers]
    );

    const locationPolygon = useMemo(
        () =>
            props.locationData.location && props.locationData.location?.outline
                ? {
                      default: {
                          list: [
                              {
                                  coords: props.locationData.location.outline.coordinates[0],
                                  options: {
                                      strokeColor: "#e81d31",
                                      fillColor: "transparent",
                                      strokeWeight: 1.1
                                  }
                              }
                          ]
                      }
                  }
                : undefined,
        [props.locationData]
    );
    // Don't auto zoom out/in when user scrolled the map
    const shouldFitBoundOnUpdate = !(includes(props.location.search, "geo_box_bottom_left") && includes(props.location.search, "geo_box_top_right"));

    /**
     * Render
     */

    function renderMarkerTooltip(marker_offer_type: string[], market_type?: OfferMarketType, price?: number, investment_name?: string) {
        const generateHeading = (offerType: string) => {
            if (marker_offer_type[0] !== "primary_market") {
                return capitalize(offerType);
            }

            if (includes(marker_offer_type[1], "apartment")) {
                return "Nowe " + offerType;
            }
            if (includes(marker_offer_type[1], "house")) {
                return "Nowy " + offerType;
            }
            if (includes(marker_offer_type[1], "lot")) {
                return "Nowa " + offerType;
            }
            return capitalize(offerType);
        };
        const offerType = translateOfferType(marker_offer_type[1], OfferTypeSinglePL);
        const firstLine = investment_name ? `${capitalize(investment_name)}` : generateHeading(offerType);
        const secondLine = price ? `${numberWithDelimiter(price)} zł` : "";

        return `
            <div class="map-tooltip-holder">
                <div class="map-tooltip">
                    <p>${firstLine}</p>

                    <p>${secondLine}</p>
                </div>
            </div>
        `;
    }

    /**
     * Main render
     */
    if (!isMounted) {
        return null;
    }

    return (
        <MapHolder isMobileModal={props.isMobileModal}>
            {props.displayLimitInfo && (
                <div className={limitInfo}>Na mapie pokazujemy do {DEFAULT_MARKERS_PER_PAGE} wyników. Przybliż mapę lub wybierz filtry</div>
            )}
            <div className={mapAndControlsHolder}>
                <div className={topMapElementsHolder}>
                    <RefreshButtonsHolder userInteracted={userInteracted}>
                        {userInteracted ? (
                            <div onClick={triggerSearch} className={refreshHolder}>
                                <RefreshIcon />
                                Ponów wyszukiwanie tutaj
                            </div>
                        ) : (
                            <>
                                {props.markersRequestState === RequestState.Waiting ? (
                                    <div>Ładowanie danych...</div>
                                ) : (
                                    <Checkbox
                                        name="refresh_on_move"
                                        onAfterChange={() => null}
                                        onChange={toggleDragSearch}
                                        value={dragSearchEnabled}
                                        label="Wyszukaj podczas przesuwania mapy"
                                    />
                                )}
                            </>
                        )}
                    </RefreshButtonsHolder>
                </div>

                <MapControlsHolder isMobileModal={props.isMobileModal}>
                    <MapControlType>
                        {mapType === MapType.SATELLITE && (
                            <div className={mapTypeControlWrapper} onClick={onSetRoadMapType}>
                                <MapIconWrapper>
                                    <MapIcon />
                                </MapIconWrapper>

                                <div className={mapTypeControlText}>Mapa</div>
                            </div>
                        )}

                        {mapType === MapType.ROADMAP && (
                            <div className={mapTypeControlWrapper} onClick={onSetSatelliteMapType}>
                                <MapIconWrapper>
                                    <SatelliteIcon />
                                </MapIconWrapper>

                                <div className={mapTypeControlText}>Satelita</div>
                            </div>
                        )}
                    </MapControlType>

                    <MapControlZoom>
                        <div onClick={() => setMapZoom("increase")}>+</div>

                        <hr className={zoomDivider} />

                        <div onClick={() => setMapZoom("decrease")}>-</div>
                    </MapControlZoom>
                </MapControlsHolder>

                {(props.markers || locationPolygon) && (
                    <React.Suspense
                        fallback={
                            <CenterBox>
                                <BounceLoader />
                            </CenterBox>
                        }
                    >
                        <LazyGoogleMap
                            preventFittingPolygonBounds={props.manualMapBrowsingMode}
                            ref={mapRef}
                            onInitSuccess={addMapEventListeners}
                            apiKey={GOOGLE_MAPS_API_KEY}
                            config={mapConfig}
                            markers={offerMarkers}
                            fitBoundsOnUpdate={shouldFitBoundOnUpdate}
                            polygons={locationPolygon}
                        />
                    </React.Suspense>
                )}
            </div>

            {applicationOfferData && (
                <ApplicationModal
                    modalHeader={FullOfferApplicationModalHeader.ASK_DEVELOPER_PRICE}
                    modalState={!!applicationOfferData}
                    onModalClose={() => setApplicationOfferData(null)}
                    applicationSource={getOfferApplicationSource(applicationOfferData.offer.market_type)}
                    applicationSourceSection={ApplicationSourceSection.MODAL}
                    algolyticsSourceSection={AlgolyticsSourceSection.BUTTON}
                    gtmSource={applicationOfferData.gtmSource}
                    viewType={props.viewType}
                    offerId={applicationOfferData.offer.id}
                />
            )}
        </MapHolder>
    );
};

export const OfferListMapMobile = withRouter(OfferListMapMobileC);

/**
 * Styles
 */

interface IListElementsProps {
    isMapBig?: boolean;
    isMobileModal?: boolean;
}

const MapHolder = styled.aside<IListElementsProps>`
    position: fixed;
    left: 0;
    background: #efefef;
    height: calc(100vh - ${(props) => (props.isMobileModal ? getThemeVariable("main_nav-height") : "100px")});
    top: ${(props) => (!props.isMobileModal ? "100px" : "unset")};
    display: ${(props) => (!props.isMobileModal ? "none" : "unset")};
    width: ${(props) => (!props.isMobileModal ? "100%" : "100vw")};

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: flex;
        position: sticky;
        flex-direction: column;
        width: calc(100% - ${(props) => (props.isMapBig ? getThemeVariable("listing_sizes-sm") : getThemeVariable("listing_sizes-md"))});
        top: ${(props) => (!props.isMobileModal ? "156px" : "unset")};
        height: calc(100vh - ${(props) => (props.isMobileModal ? getThemeVariable("main_nav-height") : "156px")});
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        top: ${(props) => (!props.isMobileModal ? "108px" : "unset")};
        height: calc(100vh - ${(props) => (props.isMobileModal ? getThemeVariable("main_nav-height") : "108px")});
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg_ipad}) {
        width: calc(100% - ${(props) => (props.isMapBig ? getThemeVariable("listing_sizes-sm") : getThemeVariable("listing_sizes-lg"))});
    }

    @media (min-width: ${getThemeBreakpoint().screen_xl}) {
        width: calc(100% - ${(props) => (props.isMapBig ? getThemeVariable("listing_sizes-sm") : getThemeVariable("listing_sizes-xl"))});
    }

    .gm-style-iw {
        padding: 0;
        cursor: pointer;
        background: transparent;
        box-shadow: none;
        top: -1rem;
        border-radius: 0;
        max-width: 350px !important;

        .gm-ui-hover-effect {
            display: none !important;
        }

        .map-tooltip-holder {
            padding-bottom: 0.5rem;
            user-select: none;

            @media (max-width: calc(${getThemeBreakpointCorrect().screen_md} - 1px)) {
                display: none;
            }

            .map-tooltip {
                background-color: rgba(55, 71, 79, 0.85);
                box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0;
                padding: 0.8rem 1.5rem;
                color: #fff;
                border-radius: 4px;

                &:hover {
                    display: none !important;
                }

                > p {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0;
                    padding: 0;
                }

                &:after {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid rgba(55, 71, 79, 0.85);
                    z-index: 1;
                }
            }
        }
    }

    .gm-style-iw-d {
        max-width: 350px !important;
        overflow: hidden !important;
        display: flex;
        justify-self: center;
        align-items: center;
    }

    .gm-style-iw-t::after {
        display: none;
    }

    .gm-bundled-control {
        left: 5px;
    }
`;

const MapBtn = styled.div`
    background-color: white;
    border-radius: ${getThemeVariable("other-border_radius")};
    color: ${getThemeVariable("colors-gray_dark")};
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
`;

const mapAndControlsHolder = css`
    position: relative;
    height: 100%;
`;

const topMapElementsHolder = css`
    position: absolute;
    z-index: 1;
    top: 0.6rem;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const limitInfo = css`
    padding: 0.9rem 1.5rem 0.7rem;
    background-color: rgba(55, 71, 79, 0.81);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.53;
    width: 100%;
`;

interface IRefreshButtonsHolderProps {
    userInteracted?: boolean;
}

const ToggleMapSize = styled(MapBtn)<IRefreshButtonsHolderProps>`
    display: none;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin: 0 1.5rem 0 1rem;

    svg {
        width: 20px;
        height: 20px;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: flex;
    }
`;

const RefreshButtonsHolder = styled(MapBtn)<IRefreshButtonsHolderProps>`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    padding: 0.8rem 1.2rem;
    font-weight: 500;
    cursor: pointer;
    background-color: ${(props) => (props.userInteracted ? getThemeVariable("colors-brand_danger") : "#fff")};

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin-left: 0;
    }
`;

const refreshHolder = css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    svg {
        width: 20px;
        height: 20px;
        margin-right: 1rem;
    }
`;

const MapControlsHolder = styled.div<{isMobileModal?: boolean}>`
    position: ${(props) => (props.isMobileModal ? "fixed" : "absolute")};
    z-index: 1;
    bottom: 20px;
    right: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        flex-direction: row;
    }
`;

const mapTypeControlWrapper = css`
    display: flex;
`;

const mapTypeControlText = css`
    padding-left: 1.2rem;
    display: none;

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: block;
    }
`;

const MapControlZoom = styled(MapBtn)`
    text-decoration: none;
    outline: 0;
    appearance: none;
    border: 0;
    background-color: white;
    text-align: center;
    color: rgb(102, 102, 102);
    font-size: 1.8rem;
    font-weight: 600;
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
    }
`;

const zoomDivider = css`
    width: 60%;
    margin: 1px auto;
    background-color: rgb(230, 230, 230);
    display: block;
    height: 1px;
    border: 0;
    color: rgb(55, 71, 79);
`;

const MapControlType = styled(MapBtn)`
    height: 30px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    outline: 0;
    appearance: none;
    border: 0;
    background-color: white;
    text-align: center;
    color: rgb(102, 102, 102);
    font-size: 1.4rem;
    cursor: pointer;
    margin: 0 0 1rem 0;

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin: 0 1rem 0 0;
        width: 110px;
    }
`;

const MapIconWrapper = styled.span`
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        border-right: 2px solid rgb(241, 241, 241);
    }
`;
