import {ViewType} from "../../view_type/view_type";
import {hitGoogleTagManager} from "../gtm_init";
import {getGtmProductWithOffer, getGtmProductWithoutOffer, IGtmProduct} from "../utils/get_gtm_product";
import {GtmContactType} from "../utils/gtm_contact_type";
import {GtmSource} from "../utils/gtm_source";
import {IGtmOffer, IGtmProductData} from "./gtm_event_typings";
import {GtmInquiryOverbudget} from "./gtm_inquiry";

export interface IEventWithOfferProps {
    offer: IGtmOffer;
    contactType?: GtmContactType;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    listPosition?: number | "na";
    listPrefix?: string;
    listSuffix?: string;
    offer_status?: GtmInquiryOverbudget;
}

export interface IEventWithoutOfferProps extends IGtmProductData {
    contactType: GtmContactType;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    offer_status?: GtmInquiryOverbudget;
}

export interface IGtmData {
    event: string;
    contactTypeHit?: GtmContactType;
    ecommerce?: {
        checkout?: unknown;
        add?: unknown;
        click?: unknown;
        detail?: unknown;
        currencyCode?: unknown;
        impressions?: object[];
        purchase?: unknown;
    };
}

type IGtmPropsFn<T> = (product: IGtmProduct, props: T) => IGtmData;

export const gtmEcommerceEventWithOffer = (props: IEventWithOfferProps, getGtmProps: IGtmPropsFn<IEventWithOfferProps>): boolean => {
    const product = getGtmProductWithOffer(props);
    const gtmData = getGtmProps(product, props);
    hitGoogleTagManager(gtmData);
    return true;
};

export const gtmEcommerceEventWithoutOffer = (props: IEventWithoutOfferProps, getGtmProps: IGtmPropsFn<IEventWithoutOfferProps>): boolean => {
    const product = getGtmProductWithoutOffer(props);
    const gtmData = getGtmProps(product, props);
    hitGoogleTagManager(gtmData);
    return true;
};
