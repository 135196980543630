import * as React from "react";

export const SortIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.11491 1.77712C7.90691 1.77712 7.69891 1.82453 7.50987 1.91934L1.7558 4.79638C1.74513 4.80171 1.72439 4.81179 1.72498 4.84675L7.50987 7.77357C7.88794 7.9632 8.34187 7.9632 8.71994 7.77357L14.474 4.89653C14.5054 4.81179 14.4847 4.80171 14.474 4.79579L8.71994 1.91934C8.53091 1.82453 8.32291 1.77712 8.11491 1.77712ZM8.11491 9.10038C7.72498 9.10038 7.33506 9.01149 6.9795 8.83371L1.22543 5.95668C0.802314 5.74512 0.539796 5.32023 0.539796 4.84675C0.539203 4.37327 0.801722 3.94838 1.22543 3.73623L6.9795 0.859196C7.69002 0.50364 8.5398 0.50364 9.25031 0.859196L15.0044 3.73623C15.4281 3.94838 15.6906 4.37327 15.69 4.84675C15.69 5.32023 15.4275 5.74512 15.0044 5.95668L9.25031 8.83371C8.89476 9.01149 8.50483 9.10038 8.11491 9.10038Z"
                fill="#37474F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.11487 15.4674C7.72495 15.4674 7.33502 15.3785 6.97946 15.2007L0.327611 11.8751C0.0348706 11.7287 -0.0836479 11.3725 0.0627224 11.0798C0.209685 10.7871 0.565834 10.6685 0.857982 10.8149L7.50983 14.1405C7.88791 14.3308 8.34183 14.3308 8.71991 14.1405L15.3718 10.8149C15.6639 10.6685 16.0201 10.7871 16.167 11.0798C16.3134 11.3725 16.1949 11.7287 15.9021 11.8751L9.25028 15.2007C8.89472 15.3785 8.5048 15.4674 8.11487 15.4674Z"
                fill="#37474F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.11487 12.3332C7.72495 12.3332 7.33561 12.2449 6.97946 12.0671L0.327611 8.74091C0.0348706 8.59454 -0.0836479 8.23899 0.0627224 7.94625C0.209685 7.65351 0.565834 7.5344 0.857982 7.68077L7.50983 11.007C7.88791 11.196 8.34183 11.196 8.71991 11.007L15.3718 7.68077C15.6639 7.5344 16.0201 7.65351 16.167 7.94625C16.3134 8.23899 16.1949 8.59454 15.9021 8.74091L9.25028 12.0671C8.89472 12.2443 8.5048 12.3332 8.11487 12.3332Z"
                fill="#37474F"
            />
        </svg>
    );
};
