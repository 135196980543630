import {IGtmProduct} from "../utils/get_gtm_product";
import {GtmContactType} from "../utils/gtm_contact_type";
import {GtmSource} from "../utils/gtm_source";
import {gtmEcommerceEventWithOffer, gtmEcommerceEventWithoutOffer, IEventWithOfferProps, IEventWithoutOfferProps} from "./gtm_event_with_offer";

// 'add' event should be considered the same as 'view'

interface IGtmAdd {
    event: "add";
    contactTypeHit?: GtmContactType;
    ecommerce: {
        add: {
            products: [IGtmProduct];
        };
    };
}

export const gtmAddWithOffer = (props: IEventWithOfferProps) => {
    gtmEcommerceEventWithOffer(props, getGtmAddData);
};

export const gtmAddWithoutOffer = (props: IEventWithoutOfferProps) => {
    gtmEcommerceEventWithoutOffer(props, getGtmAddData);
};

const getGtmAddData = (product: IGtmProduct, props: IEventWithOfferProps | IEventWithoutOfferProps): IGtmAdd => ({
    event: "add",
    contactTypeHit: props.contactType,
    ecommerce: {
        add: {
            products: [product]
        }
    }
});
