import {FormActionTypes, IFormValuesAction} from "./create_form_action_types";

export const reduceFormValues =
    <T>(formTypes: FormActionTypes, initialValue: T) =>
    (state: T = initialValue, action: IFormValuesAction<T>): Partial<T> => {
        switch (action.type) {
            case formTypes.clear:
                return initialValue;
            case formTypes.update:
                return {...state, ...action.formValues};
            case formTypes.replace:
                return action.formValues;
            default:
                return state;
        }
    };
