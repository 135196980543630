import * as React from "react";
import {useEffect, useState} from "react";
import {css} from "@linaria/core";
import {isEmpty} from "lodash";

import {ExpansionPanelReadMore} from "../../../app/components/ExpansionPanelReadMore";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {GenericListingDescription} from "../generic_listings/GenericListingDescription";
import {useGenericListingDescription} from "../generic_listings/hooks/useGenericListingDescription";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {wrapper} from "./atoms/atoms";
import {BuildingSvgIcon} from "./icons/BuildingSvgIcon";
import {OfferListLocationStatsTrigger} from "./OfferListLocationStatsTrigger";
import {OfferListStatsBody} from "./OfferListStatsBody";
import {OfferListStatsHeaderInfo} from "./OfferListStatsHeaderInfo";

interface IOwnProps {
    locationDescription: string;
    offerCount: number;
    offerLocation: {
        statistics: ILocationStatistics | {} | null;
    };
    shouldRenderSeoDescription: boolean;
    shouldRenderLocationStats: boolean;
}

interface IProps extends IOwnProps {
    isMapBig: boolean;
    latestQuery: IOfferListQuery;
}

export const OfferListSeoDescription = (props: IProps) => {
    const {displayGenericListingCondition} = useGenericListingDescription();
    const isLot = props.latestQuery.offer_type === TypeSearchFilterOptions.LOT;
    const [statsHolderClass, setStatsHolderClass] = useState("isDeactivated");

    const shouldRender = props.shouldRenderSeoDescription || displayGenericListingCondition;

    const statistics =
        props.offerLocation.statistics && !isEmpty(props.offerLocation.statistics) ? (props.offerLocation.statistics as ILocationStatistics) : null;

    const displayStats = props.shouldRenderLocationStats && statistics && !displayGenericListingCondition && props.offerCount !== 0 && !isLot;

    useEffect(() => {
        if (statsHolderClass === "inDeactivation") {
            setTimeout(() => setStatsHolderClass("isDeactivated"), 500);
        }
    }, [statsHolderClass]);

    if (!shouldRender) return null;

    const renderContent = () => (
        <>
            {statistics && <OfferListStatsHeaderInfo params={props.latestQuery} locationStatistics={statistics} isMapBig={props.isMapBig} />}
            {displayStats && (
                <OfferListStatsBody
                    hiderClass={statsHolderClass}
                    setBodyClose={() => setStatsHolderClass("inDeactivation")}
                    params={props.latestQuery}
                    locationStatistics={statistics}
                    isMapBig={props.isMapBig}
                />
            )}

            {props.shouldRenderSeoDescription && (
                <ExpansionPanelReadMore
                    minHeight={120}
                    descriptionJsx={
                        <>
                            <div dangerouslySetInnerHTML={{__html: props.locationDescription}} />
                            <GenericListingDescription />
                        </>
                    }
                />
            )}
        </>
    );

    return (
        <div className={wrapper}>
            <div className={descriptionHolder}>
                <div className={sectionTitleAndShowMoreButtonHolder}>
                    <div className={sectionTitleHolder}>
                        <BuildingSvgIcon />
                        <div className={sectionTitle}>Poznaj region</div>
                    </div>
                    {displayStats && (
                        <OfferListLocationStatsTrigger
                            isStatsOpen={statsHolderClass === "isActive"}
                            setStatsOpen={() => setStatsHolderClass(statsHolderClass === "isActive" ? "inDeactivation" : "isActive")}
                            className={mobileStatsTrigger}
                        />
                    )}
                </div>
                {renderContent()}
            </div>
        </div>
    );
};

/**
 * Styles
 */

const descriptionHolder = css`
    margin-top: 2rem;
    padding: 1rem;
    background: ${getThemeVariable("colors-gray_warmer")};
    border: 1px solid ${getThemeVariable("colors-gray_bright")};
    border-radius: 0.5rem;
`;

const sectionTitleAndShowMoreButtonHolder = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const sectionTitleHolder = css`
    display: flex;
    align-items: center;
    margin: 2rem 0 2rem 0;
    text-decoration: underline;
    text-decoration-color: ${getThemeVariable("colors-brand_primary")};
`;

const sectionTitle = css`
    font-size: 2.5rem;
    font-weight: 300;
    margin: 0 0 0 1rem;
`;

const mobileStatsTrigger = css`
    margin-left: -1.2rem;
`;
