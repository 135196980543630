import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IAgent} from "./fetch_agent_detail_at_route";

export const updateAgentMetaData =
    (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>, prevRoute: IPrevRouteState | null, prevActionResult: IAgent) => (dispatch: Dispatch) => {
        const url = `${ghCommonUrl}${appLink.agent.detail({agentSlug: prevActionResult.slug})}`;
        const name = `${prevActionResult.name} ${prevActionResult.last_name ?? ""}`;

        const title = `${name} agent nieruchomości`;
        const description = `${name} to agent nieruchomości w firmie ${prevActionResult.agency.name}. Sprawdź aktualne oferty agenta i skontaktuj się 📞 za pośrednictwem portalu gethome.pl!`;
        const robots = "noindex";

        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                hideSuffix: false
            })
        );

        return Promise.resolve(true);
    };
