import * as React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {filter, isEqual, range} from "lodash";

import {updateRoomBounds} from "../../../search/utils/room_field_data_translations";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IOwnProps {
    error?: string[];
    name: string;
    id: string;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
    options: {
        value: number;
        label: string;
    }[];
}

export const GroupSelect = (props: IOwnProps) => {
    const {value, name, options, onChange: onChangeProps} = props;

    const onChange = (room: number) => {
        // Check if user unchecked all values one by one.
        if (isEqual(value, [room])) {
            return props.onChange(name, []);
        }

        const {lower, upper} = updateRoomBounds(
            {
                lower: (value.length && Math.min(...value)) || "",
                upper: (value.length && Math.max(...value)) || ""
            },
            room
        );
        const newValue = range(lower || 0, (upper || 5) + 1);
        return onChangeProps(name, filter(newValue));
    };

    return (
        <ul className={list}>
            {options.map((o) => (
                <FilterBlockItem key={o.label} active={value.some((element) => element === o.value)} onClick={() => onChange(o.value)}>
                    <div>{o.label}</div>
                </FilterBlockItem>
            ))}
        </ul>
    );
};

const list = css`
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style-type: none;
`;

const FilterBlockItem = styled.li<{active: boolean}>`
    display: flex;
    align-content: center;
    flex: 1;
    text-align: center;
    height: 4rem;
    user-select: none;
    letter-spacing: -0.1px;
    transition: opacity 100ms ease-out;
    z-index: ${(props) => (props.active ? 1 : 0)};

    &:first-of-type > div {
        border-radius: ${getThemeVariable("other-border_radius")} 0 0 ${getThemeVariable("other-border_radius")};
    }

    &:last-of-type > div {
        border-radius: 0 ${getThemeVariable("other-border_radius")} ${getThemeVariable("other-border_radius")} 0;
    }

    > div {
        flex: 1;
        margin-left: -0.1rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.64;
        white-space: nowrap;
        color: ${(props) => (props.active ? getThemeVariable("colors-brand_primary") : getThemeVariable("colors-gray_darker"))};
        border: 1px solid ${getThemeVariable("colors-gray_brighter")};
        background-color: ${(props) => (props.active ? "#eee8f6" : "white")};
    }
`;
