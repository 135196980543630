import {Dispatch} from "redux";
import {IPageDetailParams} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";

export interface IPageDetailResponse {
    created_at: string;
    updated_at: string;
    title: string;
    slug: string;
    description: string;
    content: string;
}

export const fetchPageDetailTypes = createRequestActionTypes({view: "Page", type: "GET", name: "pageDetail"});

export const fetchPageDetailAtRoute = (services: Partial<IServices>, route: IRouteState<IPageDetailParams>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPageDetailTypes.start});

    const url = apiLink.pages.page({})({pageSlug: route.params.slug});

    return getRequest({}, url)
        .then(async (response: IPageDetailResponse) => {
            dispatch({type: fetchPageDetailTypes.success, result: response});
            return response;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        );
};
