import {css, keyframes} from "@emotion/react";
import styled from "@emotion/styled";
import {darken, lighten} from "polished";

interface IProps {
    className?: string;
    height: string;
    isText?: boolean;
    width: string;
    testId?: string;
}

export const SkeletonBox = (props: IProps) => {
    return <SkeletonHolder isText={props.isText} width={props.width} height={props.height} className={props.className} data-testid={props.testId} />;
};

const textSkeletonStyle = css`
    opacity: 0.4;
    border-radius: 0.4rem;
    display: block;
`;

const gradientAnimation = keyframes`
    0% {
        background-position: -100px;
    }
    50% {
        background-position: 0;

    }
    100% {
        background-position: -100px;

    }
`;

const waveAnimation = keyframes`
    0% {
        transform: translateX(-100%);
    }
    50% {
        /* +0.5s of delay between each loop */
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }`;

interface ISkeletonHolderProps {
    height: string;
    isText?: boolean;
    width: string;
}

const SkeletonHolder = styled.div<ISkeletonHolderProps>`
    ${(props) => props.isText && textSkeletonStyle}
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: relative;
    background: linear-gradient(
        45deg,
        ${(props) => lighten(0.2, props.theme.colors.gray_brighter)},
        ${(props) => darken(0.2, props.theme.colors.gray_very_bright)},
        ${(props) => lighten(0.2, props.theme.colors.gray_brighter)}
    );
    animation: ${gradientAnimation} 2s ease infinite;
    background-size: 400%;
    overflow: hidden;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, transparent, ${(props) => props.theme.colors.body_bg}, transparent);
        animation: ${waveAnimation} 3s ease infinite;
        transform: translateX(-100%);
        opacity: 0.25;
    }
`;
