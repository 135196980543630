import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {isEqual} from "lodash";

import {setLocalStorageLastSearch} from "../../app/actions/load_local_storage_last_search_to_store";
import {useTransitioningHistoryPush} from "../../app/hooks/use_transitioning_history_push";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {parseSearch} from "../../app/utils/request_response_utils/parse_search";
import {MAPVIEW_PARAM} from "../../offer/list/components/OfferListDynamic";
import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {DealTypeSlug, OfferMarketType} from "../../offer/utils/constants_offer";
import {filterInteractionPushToGTMEvent} from "../../tracking/google_tag_manager/filter_interaction";
import {SearchTab, SuggestType} from "../actions/fetch_search_all_action";
import {createDynamicTabUrl} from "./create_dynamic_url";
import {SearchFormValuesType} from "./form_types";
import {getStringQueryValuesFromFormValues} from "./get_string_query_values_from_form";
import {getSearchLocation} from "./getSearchLocation";
import {ISearchInputValue} from "./ISearchInputValue";
import {parseFormDealTypeToSlug, parseFormOfferTypeToSlug} from "./parse_offer_type_to_form_values";
import {validateQueryValues} from "./validate_query_values";
import {validateSearchQuery} from "./validate_search_query";

export const useSearchSubmit = () => {
    const browserLocation = useLocation();
    const offerListLocation = useSelector((state: IStore) => state.offerList.location.location);
    const latestQuery = useSelector((store: IStore) => store.offerList.latestQuery);
    const dispatch = useDispatch();
    const {isTransitioning, transitionHistory} = useTransitioningHistoryPush();

    const onSubmit = (formValues: SearchFormValuesType, additionalParams?: Record<string, unknown>) => {
        const searchValue = formValues["search"] as ISearchInputValue;
        const isOnMapView = browserLocation.search.includes(MAPVIEW_PARAM);

        const selectedLocationType =
            (searchValue.tabType === SearchTab.SearchSuggestions &&
                searchValue.suggestions.suggest_type === SuggestType.location &&
                searchValue.suggestions.location_type) ||
            offerListLocation?.location_type;

        dispatch(setLocalStorageLastSearch(searchValue));

        const queryValuesRaw = getStringQueryValuesFromFormValues(formValues);
        const offerTypeFormValue = formValues["offer_type"];
        const queryValues = validateQueryValues(queryValuesRaw);
        const parsedSearch = parseSearch(browserLocation.search); /// useLocation
        const {sort} = parsedSearch;
        const finalQuery = {...queryValues} as Record<string, string | string[] | number | boolean>;
        const offerType = parseFormOfferTypeToSlug(formValues.offer_type);
        const marketType = getMarketTypeFromOfferType(formValues.offer_type);
        const dealType = parseFormDealTypeToSlug(offerType !== "dzialki" ? formValues.deal_type : undefined);
        const {location, subLocation, subSubLocation} = getSearchLocation(offerListLocation, searchValue);

        // Clear filters that do not match specific conditions.
        const query = validateSearchQuery(finalQuery, offerType, offerTypeFormValue, dealType);

        /**
         * Exceptions / specific behaviour
         */
        // Below are cases which should be triggered only if location didn't change - user only switched filters.
        const [currentLocation, currentSubLocation, currentSubSubLocation] = offerListLocation
            ? offerListLocation.slug.split("/")
            : [undefined, undefined, undefined];
        const [formLocation, formSubLocation, formSubSubLocation] =
            searchValue.tabType === SearchTab.SearchSuggestions ? searchValue.suggestions.slug.split("/") : [undefined, undefined, undefined];

        const baseParams = {
            offerType,
            type: dealType,
            sort,
            ...(marketType && dealType !== DealTypeSlug.RENT ? {market: marketType} : {}),
            ...query,
            ...additionalParams,
            ...(isOnMapView ? {[MAPVIEW_PARAM]: "1"} : {})
        };

        const locationChanged = !isEqual([currentLocation, currentSubLocation, currentSubSubLocation], [formLocation, formSubLocation, formSubSubLocation]);

        // handle filtering after previously moving map
        const {geo_box_bottom_left, geo_box_top_right} = parsedSearch;
        if (geo_box_bottom_left && geo_box_top_right && !locationChanged && searchValue.tabType !== SearchTab.SearchSuggestions) {
            const allParams = {
                geo_box_bottom_left,
                geo_box_top_right,
                ...baseParams,
                ...(marketType ? {market: marketType} : {})
            };
            const url = getOfferListUrl(allParams, selectedLocationType);
            return transitionHistory(url, {searchFormValues: formValues});
        }

        // handle filtering after coming from agencyDetail view
        if (parsedSearch.agency && !locationChanged) {
            const params = {...query, agency: parsedSearch.agency, sort, offerType, type: dealType};
            const url = getOfferListUrl(params, selectedLocationType);
            return transitionHistory(url, {searchFormValues: formValues});
        }

        // redirect from listing to INVESTMENT, DEVELOPER, AGENCY and AGENT
        if (searchValue.tabType === SearchTab.SearchSuggestions && searchValue.suggestions.suggest_type !== "location") {
            return (window.location.href = decodeURIComponent(createDynamicTabUrl(searchValue.suggestions, location, subLocation, subSubLocation)));
        }

        /**
         * Standard filtering
         */
        const {distance} = parseSearch(browserLocation.search);

        const params = {
            ...(searchValue.tabType === SearchTab.Places
                ? {
                      // places tab should not use standard GH location, add Places specific params
                      geo_point: `${searchValue.location.lat}|${searchValue.location.lng}`,
                      search_name: searchValue.label,
                      distance: distance === "5km" ? "5km" : "1km" // Cross-promo: RP is linking to GH offer lists in "5km" distance from places}
                  }
                : {
                      location,
                      subLocation,
                      subSubLocation
                  }),
            ...baseParams
        };

        filterInteractionPushToGTMEvent(formValues, latestQuery);
        const url = getOfferListUrl(params, selectedLocationType);
        return transitionHistory(url, {searchFormValues: formValues});
    };
    return {onSubmit, isTransitioning};
};

const getMarketTypeFromOfferType = (formOfferType: string[]) => {
    const hasAftermarket = formOfferType.some((ot) => ot.match(OfferMarketType.AFTERMARKET));
    const hasPrimaryMarket = formOfferType.some((ot) => ot.match(OfferMarketType.PRIMARY_MARKET));

    switch (true) {
        case hasAftermarket && !hasPrimaryMarket:
            return OfferMarketType.AFTERMARKET;
        case !hasAftermarket && hasPrimaryMarket:
            return OfferMarketType.PRIMARY_MARKET;
        default:
            return null;
    }
};
