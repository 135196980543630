import {hitGoogleTagManager} from "./gtm_init";

export const gtmClickMapPin = () => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "usability",
        GAEventAction: "map_pin"
    };
    hitGoogleTagManager(gtmData);
};
