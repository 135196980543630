import * as React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {SEARCH_SUGGESTION_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";
import {ArrowWithOrientationIcon} from "@web2/icons";
import {numberFormat} from "@web2/string_utils";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    title: string;
    subTitle?: string;
    idx?: number;
    propertiesCount?: number;
    propertiesFormat?: string | 0;
    activeItemId?: number | null;
    scrollId?: string;
}

export const ResultsListItem = (props: IProps) => {
    const isItemActive = () => {
        if (props.idx !== undefined && props.activeItemId !== undefined) {
            return props.idx === props.activeItemId && true;
        }
        return false;
    };

    return (
        <li data-testid={SEARCH_SUGGESTION_TESTID.WRAPPER}>
            <ListItem id={`scrollableItem-${props.idx}`} onClick={(e) => props.onClick(e)} isActive={isItemActive()}>
                <TitlesHolder subtitleVisible={!!props.subTitle}>
                    <span data-testid={SEARCH_SUGGESTION_TESTID.TITLE}>{props.title}</span>
                    {props.subTitle && <span>{props.subTitle}</span>}
                </TitlesHolder>

                {props.propertiesCount != undefined && props.propertiesFormat && (
                    <div className={propertyCountHolder}>
                        {numberFormat(props.propertiesCount)} {props.propertiesFormat}
                        <StyledArrow orientation="right" width={14} height={14} fill="#787993" strokeWidth={1} />
                    </div>
                )}
            </ListItem>
        </li>
    );
};

const ListItem = styled.div<{isActive: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid ${getThemeVariable("colors-gray_very_bright")};
    background: ${(props) => (props.isActive ? getThemeVariable("colors-gray_very_bright") : "")};

    &:hover {
        background: ${getThemeVariable("colors-gray_very_bright")};
    }
`;

const TitlesHolder = styled.div<{subtitleVisible: boolean}>`
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: ${getThemeVariable("fonts-font_size_root")};

    span:first-of-type {
        font-size: ${getThemeVariable("fonts-font_size_base")};
        margin-bottom: 0.5rem;
    }

    span:last-of-type {
        color: ${(props) => (props.subtitleVisible ? getThemeVariable("colors-gray") : "")};
    }
`;

const propertyCountHolder = css`
    font-size: ${getThemeVariable("fonts-font_size_root")};
    color: ${getThemeVariable("colors-gray")};
    display: flex;
    align-items: center;
`;

const StyledArrow = styled(ArrowWithOrientationIcon)`
    margin-left: 0.5rem;
`;
