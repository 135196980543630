import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const ShopCartIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M8.58,6.72h-6a.58.58,0,0,1-.57-.48L1.33,2.42h0L1,.83H.43A.31.31,0,0,1,.11.52.31.31,0,0,1,.43.21h.69a.54.54,0,0,1,.53.43L1.9,2,9.31,2h0a.59.59,0,0,1,.58.59s0,.08,0,.11L9.14,6.25A.58.58,0,0,1,8.58,6.72ZM2.64,6.09h5.9l.71-3.45L2,2.67ZM1.12.83Z" />

            <path
                d="M8.12,9.79A1.25,1.25,0,1,1,9.37,8.54,1.26,1.26,0,0,1,8.12,9.79ZM8.12,8a.61.61,0,0,0-.61.61A.61.61,0,1,0,8.12,8Z"
                fill={props.secondaryFillColor}
            />

            <path
                d="M2.55,9.79A1.25,1.25,0,1,1,3.81,8.54,1.25,1.25,0,0,1,2.55,9.79ZM2.55,8a.61.61,0,0,0-.61.61.62.62,0,0,0,.61.61A.61.61,0,0,0,2.56,8Z"
                fill={props.secondaryFillColor}
            />
        </SvgIcon>
    );
};
