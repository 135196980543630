import * as htmlparser from "htmlparser2";
import {Dispatch} from "redux";
import {IArticleDetailAndListParams} from "@web2/gh_routes";

import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export interface IArticleRelatedArticles {
    id: string;
    updated_at: string;
    publication_date: string;
    content_updated_date: string;
    title: string;
    slug: string;
    lead: string;
    text: string;
    image: {
        a_img_310x175: string; // list size
        a_img_555x321: string; // list size
    };
    author: {
        name: string;
    };
}

type IArticleRelatedArticlesResponse = IArticleRelatedArticles[];

export const fetchRelatedArticlesAtRouteTypes = createRequestActionTypes({view: "Blog", type: "GET", name: " article Detail - related articles"});

export const fetchRelatedArticlesAtRoute = (services: Partial<IServices>, route: IRouteState<IArticleDetailAndListParams>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchRelatedArticlesAtRouteTypes.start});
    const articleApiLink = apiLink.articles.article.related({})({articleSlug: route.params.slug});
    return getRequest({}, articleApiLink)
        .then(async (response: IArticleRelatedArticlesResponse) => {
            const result = response.map((article: IArticleRelatedArticles) => {
                article.text = ParseHtml(article.text);
                return article;
            });

            dispatch({type: fetchRelatedArticlesAtRouteTypes.success, result});
            return result;
        })
        .catch(async (err) => {
            const result: [] = [];
            dispatch({type: fetchRelatedArticlesAtRouteTypes.success, result});
            return result;
        });
};

const MAX_ARTICLE_TEXT_LENGTH = 300;
const ParseHtml = (htmlBody: string): string => {
    const articleText = htmlBody;
    let returnedText = "";

    let textLength = 0;
    const parser = new htmlparser.Parser(
        {
            ontext: (text: string) => {
                textLength = textLength + text.length;

                if (textLength >= MAX_ARTICLE_TEXT_LENGTH) {
                    returnedText = returnedText + text.substring(0, MAX_ARTICLE_TEXT_LENGTH);
                    parser.parseComplete("");
                }
                returnedText += text;
            }
        },
        {decodeEntities: false, lowerCaseTags: true, recognizeSelfClosing: true}
    );

    parser.write(articleText);
    return returnedText;
};
