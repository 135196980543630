import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}
export const YouTubeIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M9.79107 2.59307C9.67609 2.16279 9.33727 1.82398 8.90699 1.70898C8.12707 1.5 5 1.5 5 1.5C5 1.5 1.87291 1.5 1.09299 1.70898C0.662793 1.82398 0.323906 2.16279 0.208926 2.59307C0 3.37291 0 5.00006 0 5.00006C0 5.00006 0 6.62717 0.208926 7.40693C0.323906 7.83721 0.662793 8.17611 1.09299 8.29109C1.87291 8.5 5 8.5 5 8.5C5 8.5 8.12707 8.5 8.90699 8.29109C9.33727 8.17611 9.67609 7.83721 9.79107 7.40693C9.99998 6.62717 9.99998 5.00006 9.99998 5.00006C9.99998 5.00006 9.99998 3.37291 9.79107 2.59307ZM3.99994 6.50008V3.50002L6.59797 5.00006L3.99994 6.50008Z" />
        </SvgIcon>
    );
};
