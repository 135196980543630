import {useState} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@web2/button";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {applicationV2Post} from "../actions/application_v2_post";
import {ISimilarOffersFetch} from "../actions/fetch_multilead_offers";
import {TrackApplicationPayload, trackApplicationPost} from "../utils/track_application";
import {UserData} from "./Application";
import {FormSuccessSend} from "./ApplicationForm";

interface IProps {
    off: ISimilarOffersFetch;
    applicationUserData?: UserData;
    applicationTrackingPayload?: TrackApplicationPayload;
}

export const ApplicationDirectSubmitCTA = (props: IProps) => {
    const {off, applicationUserData} = props;
    const viewType = useSelector((store: IStore) => store.viewType.current);

    const [isApplicationSent, setIsApplicationSent] = useState(false);

    const applicationDirectSubmit = (off: ISimilarOffersFetch) => {
        const applicationData = {
            ...(off && {
                offer: off.id,
                ...(off.agent && {agent: off.agent.id}),
                ...(off.investment && {investment: off.investment.id}),
                ...(off.agency && {agency: off.agency.id})
            }),
            ...(off.investment && {
                agency: off.investment.developer?.id,
                investment: off.investment.id
            }),
            source: applicationUserData?.source,
            source_section: applicationUserData?.source_section,
            full_name: applicationUserData?.full_name,
            email: applicationUserData?.email,
            text: applicationUserData?.text,
            phone: applicationUserData?.phone
        };
        applicationV2Post(applicationData)
            .then((res) => {
                if (props.applicationTrackingPayload) {
                    trackApplicationPost({
                        ...props.applicationTrackingPayload,
                        offer: off,
                        gtmInquiryData: {
                            ...props.applicationTrackingPayload.gtmInquiryData,
                            gtmSource: GtmSource.MULTILEAD
                        }
                    });
                }

                setIsApplicationSent(true);
            })
            .catch((err) => null);
    };

    return (
        <>
            {!isApplicationSent ? (
                <ApplicationButton
                    variant="primary"
                    fullWidth
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        applicationDirectSubmit(off);
                    }}
                >
                    Zapytaj o ofertę
                </ApplicationButton>
            ) : (
                <FormSuccessSend css={successMargin}>Zgłoszenie zostało wysłane</FormSuccessSend>
            )}
        </>
    );
};

const ApplicationButton = styled(Button)`
    display: flex;
    justify-self: flex-end;
    border-radius: 0;
`;
const successMargin = css`
    margin: 1rem 0 1rem;
`;
