import {MouseEvent, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {useRevealedNumber} from "../../app/hooks/use_revealed_number";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {ApplicationModal} from "../../application/components/ApplicationModal";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {PHONE_NUMBER_INTERACTION_TYPE, showPhoneNumberAlgolyticsHit} from "../../tracking/algolytics/click/show_phone_number_click";
import {AlgolyticsSourceSection} from "../../tracking/algolytics/interaction/application_sent_hit";
import {IGtmOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {GtmInquiryOverbudget, gtmInquiryWithOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_inquiry";
import {GtmContactType} from "../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {replaceContactInText} from "../utils/replace_contact_in_text";
import {ExternalContentStyled, linesToParagraph} from "./ExternalContent";

interface IMaskedExternalContentProps {
    content: string;
    offer: IGtmOffer;
    fallbackNumber?: string | null;
    isPrimaryMarket?: boolean;
    gtmSource: GtmSource;
    hidePhoneNumber: boolean;
}

/**
 * Component used to hide any contact information that can be delivered in `content` prop.
 * - phone numbers are replaced by buttons handling `revealed numbers` logic
 * - emails and urls are replaced with application buttons
 */

export const ExternalContentMaskedContacts = (props: IMaskedExternalContentProps) => {
    const parsedContent = useMemo(() => linesToParagraph(replaceContactInText(props.content)), [props.content]);

    const {getRotatedNumber, rotatedNumber, isRotatedNumberLoading} = useRevealedNumber({
        uuid: props.offer.id,
        fallbackNumber: props.fallbackNumber,
        isPrimaryMarket: props.isPrimaryMarket
    });

    const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
    const viewType = useSelector((store: IStore) => store.viewType.current);

    const onContactCLick = (e?: MouseEvent<HTMLDivElement>) => {
        const target = e?.target as HTMLDivElement;

        if (target?.dataset.contactType === "phone") {
            if (props.hidePhoneNumber) {
                return;
            }

            // open dialer or fetch rotated number
            if (rotatedNumber) {
                showPhoneNumberAlgolyticsHit(window.location.pathname, {id: props.offer.id}, PHONE_NUMBER_INTERACTION_TYPE.CALL);
                gtmInquiryWithOffer({
                    viewType,
                    offer: props.offer,
                    offer_status: props.offer?.overbudget ? GtmInquiryOverbudget.TRUE : GtmInquiryOverbudget.FALSE,
                    contactType: GtmContactType.PHONE,
                    gtmSource: props.gtmSource
                });
                window.location.href = `tel:+${rotatedNumber}`;
            } else {
                showPhoneNumberAlgolyticsHit(window.location.pathname, {id: props.offer.id}, PHONE_NUMBER_INTERACTION_TYPE.REVEAL);
                gtmInquiryWithOffer({
                    viewType,
                    offer: props.offer,
                    offer_status: props.offer?.overbudget ? GtmInquiryOverbudget.TRUE : GtmInquiryOverbudget.FALSE,
                    contactType: GtmContactType.PHONE,
                    gtmSource: props.gtmSource
                });
                getRotatedNumber().then((res) => {
                    if (res === "404") {
                        setIsApplicationModalOpen(true);
                    }
                });
            }
            return;
        }

        if (target.dataset.contactType === "message") {
            setIsApplicationModalOpen(true);
            return;
        }
    };

    const phoneNumberReplacementText = getPhoneNumberReplacementText(isRotatedNumberLoading, props.hidePhoneNumber);

    return (
        <>
            <MaskedStyledContent
                onClick={onContactCLick}
                dangerouslySetInnerHTML={{__html: parsedContent}}
                isRotatedNumberLoading={isRotatedNumberLoading}
                phoneNumberButtonText={rotatedNumber || phoneNumberReplacementText}
            />
            <ApplicationModal
                modalState={isApplicationModalOpen}
                viewType={viewType}
                applicationSource={props.isPrimaryMarket ? ApplicationSource.OFFER_PRIMARY_MARKET : ApplicationSource.OFFER_AFTERMARKET}
                applicationSourceSection={ApplicationSourceSection.DESCRIPTION}
                algolyticsSourceSection={AlgolyticsSourceSection.OFFER_DESCRIPTION}
                gtmSource={GtmSource.ASK_FOR_OFFER}
                onModalClose={() => setIsApplicationModalOpen(false)}
                offerId={props.offer.id}
            />
        </>
    );
};

const getPhoneNumberReplacementText = (isRotatedNumberLoading: boolean, hidePhoneNumber: boolean) => {
    switch (true) {
        case isRotatedNumberLoading:
            return "•••";
        case hidePhoneNumber:
            return null;
        default:
            return "Pokaż numer";
    }
};

interface IMaskedContentProps {
    isRotatedNumberLoading: boolean;
    phoneNumberButtonText: string | null;
}

const CONTACT_PLACEHOLDER_WIDTH = "12rem";
const CONTACT_PLACEHOLDER_HEIGHT = "2.6rem";
const contactPlaceholderCommonStyles = css`
    width: ${CONTACT_PLACEHOLDER_WIDTH};
    height: ${CONTACT_PLACEHOLDER_HEIGHT};

    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: calc(-${CONTACT_PLACEHOLDER_HEIGHT} / 2);
    left: 0;
`;

const MaskedStyledContent = styled(ExternalContentStyled)<IMaskedContentProps>`
    p {
        line-height: 2;
    }

    & span[data-contact-type] {
        cursor: pointer;
        width: ${CONTACT_PLACEHOLDER_WIDTH};

        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        vertical-align: middle;
    }

    ${(props) =>
        props.phoneNumberButtonText &&
        css`
            & span[data-contact-type="phone"]::before {
                ${contactPlaceholderCommonStyles};
                content: "${props.phoneNumberButtonText}";

                border: 1px solid ${props.theme.colors.brand_primary};
                border-radius: ${props.theme.other.border_radius};
                color: ${props.theme.colors.brand_primary};

                background-color: ${props.isRotatedNumberLoading ? props.theme.colors.gray_bright : "#fff"};
                transition: background-color 0.2s ease-in-out;
            }
        `}

    & span[data-contact-type="message"]::before {
        ${contactPlaceholderCommonStyles};
        content: "Zapytaj o ofertę";
        border: 1px solid ${(props) => props.theme.colors.brand_primary};
        border-radius: ${(props) => props.theme.other.border_radius};
        color: ${(props) => props.theme.colors.brand_primary};
    }
`;
