import {IInvestmentSellStatusType} from "../../app/interfaces/response/investment";
import {IApplicationPicture, IOfferBoxPicture, IOfferBoxPlanPicture} from "../../app/interfaces/response/offer_detail";
import {ICoordinates} from "../../app/interfaces/response/offer_list";
import {apiLink} from "../../app/routes/api_link";
import {appendQueryString} from "../../app/utils/append_query_string";
import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {IApplicationOfferInvestment} from "../components/Application";

export interface ISimilarOffersFetch {
    agency: {
        id: string;
        name: string;
        logo_picture?: {
            a_log_100: string;
        } | null;
        slug: string;
        type?: VendorType;
        status: number;
    } | null;
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
        picture: {
            a_img_100x100: string;
        } | null;
        market_type?: OfferMarketType;
    } | null;
    deal_type: OfferDealType;
    id: string;
    market_type: OfferMarketType;
    name: string;
    offer_type?: string[];
    pictures: (IOfferBoxPicture & IApplicationPicture)[] | null;
    is_private: boolean;
    property: {
        address?: string | null; // address is not available on 'lots' offers
        attic: boolean | null;
        balconies?: number | null;
        bathroom_number?: number | null;
        building_year?: number | null;
        floors?: number;
        garden?: number | null;
        group_lot_info?: {shape?: string | null};
        heat?: string | null;
        location?: {
            path: {
                name: string;
                slug: string;
                type: string;
            }[];
            short_name: string;
        } | null;
        lot_size?: number | null;
        lot_type?: string | null;
        plan_picture?: IOfferBoxPlanPicture | null;
        room_number?: number | null;
        size: number | null;
        terraces?: number | null;
        type: PropertyType;
    };
    investment:
        | (IApplicationOfferInvestment & {
              id: string;
              investment_summary?: {
                  offer_count: number;
              };
              is_active: boolean;
              limited_presentation: boolean;
              name: string;
              offer_count?: number;
              sell_status: IInvestmentSellStatusType;
              slug: string;
              location?: {
                  path: {name: string; slug: string}[];
                  short_name: string;
              };
          })
        | null;
    slug: string;
    show_price?: boolean;
    show_price_m2?: boolean;
    created_at: string;
    coordinates: ICoordinates;
    price: {
        total: number | null;
        per_sqm: number | null;
        currency: string;
    };
    overbudget: boolean;
}
const PRIORITY = 1; // means filtering out excluded offers

export const fetchMultiLeadOffers = (
    offerId: string | undefined,
    uniqueAgency = true,
    count: number,
    market_type?: OfferMarketType
): Promise<ISimilarOffersFetch[] | null> => {
    if (!offerId) {
        return new Promise((resolve) => resolve(null));
    }

    const url = appendQueryString(apiLink.offers.similar({})({offerId: offerId}), {
        ...(uniqueAgency ? {unique_agency: 1} : {}),
        size: count,
        market_type: market_type,
        recommendation_priority: PRIORITY
    });

    return getRequest({}, url).catch((err) => {
        notifyBugsnag(err, "fetchMultiLeadOffers: caught error while fetching multilead offers");
        return [];
    });
};
