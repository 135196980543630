import * as React from "react";
import classNames from "classnames";

interface IProps {
    mainColor?: string;
    className?: string;
}

export const HomeIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className)} width={14} height={14} viewBox="0 0 10 10">
            <path
                d="M9.83,4.91,5.81.53a1.18,1.18,0,0,0-1.62,0l-4,4.38a.66.66,0,0,0,0,.92A.68.68,0,0,0,.68,6a.66.66,0,0,0,.45-.21l.15-.16L1.55,8.8a1.09,1.09,0,0,0,1.09,1H7.36a1.1,1.1,0,0,0,1.09-1l.27-3.17.15.16a.68.68,0,0,0,.92,0A.66.66,0,0,0,9.83,4.91ZM7.66,4.29l-.37,4.4H2.71l-.37-4.4L5,1.39Z"
                fill={props.mainColor || "#8b6aac"}
            />
        </svg>
    );
};
