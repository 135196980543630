import {Dispatch} from "redux";

import {createDebouncedAction} from "../../app/utils/request_response_utils/create_debounced_action";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {loadGoogleMapsApi} from "../utils/load_google_maps_api";

export interface AutocompletePrediction {
    description: string;
    matched_substrings: PredictionSubstring[];
    place_id: string;
    structured_formatting: AutocompleteStructuredFormatting;
    terms: PredictionTerm[];
    types: string[];
}

interface AutocompleteStructuredFormatting {
    main_text: string;
    main_text_matched_substrings: PredictionSubstring[];
    secondary_text: string;
}

interface PredictionSubstring {
    length: number;
    offset: number;
}

interface PredictionTerm {
    offset: number;
    value: string;
}

enum PlacesServiceStatus {
    INVALID_REQUEST = "INVALID_REQUEST",
    NOT_FOUND = "NOT_FOUND",
    OK = "OK",
    OVER_QUERY_LIMIT = "OVER_QUERY_LIMIT",
    REQUEST_DENIED = "REQUEST_DENIED",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    ZERO_RESULTS = "ZERO_RESULTS"
}

export const fetchPlaceListTypes = createRequestActionTypes({
    view: "OfferList",
    type: "GET",
    name: "fetchPlaceSuggestions"
});

const fetchPlaceListSimple = (input: string) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPlaceListTypes.start});

    await loadGoogleMapsApi(["places"]);

    return new Promise((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
            {input, componentRestrictions: {country: "pl"}},
            (predictions: AutocompletePrediction[] | null, status: PlacesServiceStatus) => {
                if (status === PlacesServiceStatus.ZERO_RESULTS) {
                    const result = {places: []};
                    dispatch({type: fetchPlaceListTypes.success, result});
                    return resolve([]); // important for batched fetch
                }
                if (status === PlacesServiceStatus.OK) {
                    const result = {places: predictions};
                    dispatch({type: fetchPlaceListTypes.success, result});
                    return resolve(predictions); // important for batched fetch
                }
                return reject(status);
            }
        );
    });
};

export const {action: fetchPlaceList, clear: stopFetchPlaceListSearch} = createDebouncedAction(fetchPlaceListSimple, 500);
export const resetPlaceListSearch = () => ({type: fetchPlaceListTypes.reset});

// Because of google places api prices we want to keep the number of calls at minimum.
export const optimizedFetchPlaceList = (() => {
    let latestSearchInput = "";
    return (searchInput: string) => async (dispatch: Dispatch) => {
        if (latestSearchInput !== searchInput) {
            latestSearchInput = searchInput;
            return dispatch(fetchPlaceList(searchInput));
        }
    };
})();
