import * as React from "react";

import {OfferDealType} from "../../utils/constants_offer";
import {dealTypeWrapper, getOfferTypePluralizedWithCount, getRecommendedName} from "../../utils/utils";
import {UrlTagSlug} from "../url_utils/tags";
import {OfferListSeoLinkListItem} from "./OfferListSeoLinkListItem";

interface IProps {
    name: string;
    number: number;
    url: string;
    offerType: string;
    dealType: OfferDealType | undefined;
    tag: UrlTagSlug | undefined;
}

export const OfferListLocationsItem = (props: IProps) => {
    const {offerType, dealType, name, url, tag} = props;

    const dynamicName = getRecommendedName(dealType, offerType, tag)(name);
    const title = dealTypeWrapper(dealType)(`${offerType} ${name} - lista ${getOfferTypePluralizedWithCount(offerType, props.number)}`);

    return <OfferListSeoLinkListItem url={url} title={title} name={dynamicName} count={props.number} />;
};
