import {useRef} from "react";
import {IRange} from "@web2/form_fields";
import {usePrevious} from "@web2/react_utils";

import {SearchFormValuesType} from "./form_types";

type ICachedPricesRecord = Record<string, IRange<string>>;

// price values should be cached for all deal types ('sell', 'rent', 'all') and 'lots' offer type

export const useCachedSearchPrices = (offerType: string[]) => {
    /*
     * private
     */

    const cachedPriceRef = useRef<ICachedPricesRecord>({});
    const cachedPricePerSqmRef = useRef<ICachedPricesRecord>({});

    const prevOfferType = usePrevious(offerType, offerType);

    const cachePrice = (key: string, values: ICachedPricesRecord) => {
        cachedPriceRef.current = {...cachedPriceRef.current, [key]: values.price};
        cachedPricePerSqmRef.current = {...cachedPricePerSqmRef.current, [key]: values.price_per_sqm};
    };

    const getCacheKey = (value: string | string[], formValues: {offer_type: string[]; deal_type: string}, name?: string) => {
        if (!name) return null;

        if (name === "deal_type") {
            // will read cached prices for this deal type
            return Array.isArray(value) ? value[0] : value || "all";
        }

        if (name == "offer_type") {
            // will read cached prices for 'lots' or previously selected 'deal_type'
            return formValues.offer_type[0] === "lot" ? "lot" : formValues.deal_type || "all";
        }
    };

    /*
     * public
     */
    const getCachedPrices = (value: string | string[], formValues: SearchFormValuesType, name?: string) => {
        const key = getCacheKey(value, formValues, name);
        return key
            ? {
                  price: cachedPriceRef.current[key] || {upper: "", lower: ""},
                  pricePerSqm: cachedPricePerSqmRef.current[key] || {upper: "", lower: ""}
              }
            : null;
    };

    const setCachedPrices = (formValues: SearchFormValuesType, name?: string) => {
        if (name === "deal_type" || (name == "offer_type" && formValues.offer_type[0] === "lot")) {
            // store prices when changing between deal types or while selecting 'lots'.
            const prevDealTypeKey = formValues.deal_type || "all";
            cachePrice(prevDealTypeKey, formValues);
        }

        if (name == "offer_type") {
            if (prevOfferType[0] === "lot") {
                // cache 'lot' prices when changing from `lots` offer type
                cachePrice("lot", formValues);
            } else {
                // cache 'offer' prices when changing from `non-lot` offer types - in this case
                cachePrice(formValues.deal_type || "all", formValues);
            }
        }
    };
    return {
        getCachedPrices,
        setCachedPrices
    };
};
