import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const BuildingIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.08mm"} height={props.height || "8.77mm"} viewBox="0 0 25.73 24.85">
            <path
                d="M25,10.24H17.66V.75A.76.76,0,0,0,16.91,0H.75A.76.76,0,0,0,0,.75V24.1a.76.76,0,0,0,.75.75H21.27a4.48,4.48,0,0,0,4.46-4.49V11A.74.74,0,0,0,25,10.24ZM16.16,23.35H1.5V1.5H16.16Zm8.07-3a3,3,0,0,1-3,3H17.66V11.74h6.57Z"
                fill={props.mainColor || "#8b6aac"}
            />
            <path d="M6.4,9.36a.74.74,0,0,0,.75-.75V5.68a.75.75,0,0,0-1.5,0V8.61A.75.75,0,0,0,6.4,9.36Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M11.25,9.36A.74.74,0,0,0,12,8.61V5.68a.75.75,0,0,0-1.5,0V8.61A.75.75,0,0,0,11.25,9.36Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M19.53,16.47h2.91a.75.75,0,1,0,0-1.5H19.53a.75.75,0,0,0,0,1.5Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M19.53,20.38h2.91a.75.75,0,0,0,0-1.5H19.53a.75.75,0,0,0,0,1.5Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M6.4,15.22a.74.74,0,0,0,.75-.75V11.54a.75.75,0,0,0-1.5,0v2.93A.75.75,0,0,0,6.4,15.22Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M11.25,15.22a.74.74,0,0,0,.75-.75V11.54a.75.75,0,0,0-1.5,0v2.93A.75.75,0,0,0,11.25,15.22Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M6.4,21.39a.75.75,0,0,0,.75-.75V17.71a.75.75,0,0,0-1.5,0v2.93A.76.76,0,0,0,6.4,21.39Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M11.25,21.39a.75.75,0,0,0,.75-.75V17.71a.75.75,0,0,0-1.5,0v2.93A.76.76,0,0,0,11.25,21.39Z" fill={props.accentColor || "#8b6aac"} />
        </svg>
    );
};
