import {ILocation} from "../list/actions/fetch_location_by_slug_at_route";

export function getLocationTypeToFetch(location: ILocation): {within: string; type: string}[] {
    const {location_type, parents} = location;
    const parent = parents[parents.length - 1];

    switch (location_type) {
        case "Miasto":
            if (location.children?.length) {
                return [
                    {
                        type: "Dzielnica",
                        within: location.id
                    },
                    {
                        type: "Miasto,Miejscowosc",
                        within: parents[0]
                    }
                ];
            }

            return [
                {
                    type: "Miasto,Miejscowosc",
                    within: parents[0]
                }
            ];

        case "Miejscowość":
            return [
                {
                    type: "Miejscowosc",
                    within: parent
                }
            ];

        case "Dzielnica":
            if (location.children?.length) {
                return [
                    {
                        type: "Osiedle",
                        within: location.id
                    },
                    {
                        type: "Dzielnica",
                        within: parents[0]
                    }
                ];
            }

            return [
                {
                    type: "Dzielnica",
                    within: parent
                }
            ];

        case "Osiedle":
            return [
                {
                    type: "Osiedle",
                    within: parent
                },
                {
                    type: "Dzielnica",
                    within: parents[0]
                }
            ];

        default:
            // case Województwo
            return [
                {
                    type: "Miasto,Miejscowosc",
                    within: location.id
                }
            ];
    }
}
