import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const LinkedinIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M1.00246 3.62982H2.76049V8.92029H1.00246V3.62982ZM1.89324 1.07959C1.29154 1.07959 0.898438 1.47461 0.898438 1.99309C0.898438 2.5009 1.28004 2.9074 1.87023 2.9074H1.88145C2.49465 2.9074 2.87652 2.50088 2.87652 1.99309C2.86502 1.47461 2.49467 1.07959 1.89324 1.07959ZM7.07693 3.50555C6.14375 3.50555 5.72568 4.01883 5.49246 4.37879V3.62982H3.73391C3.75719 4.12613 3.73391 8.92029 3.73391 8.92029H5.49246V5.96572C5.49246 5.80748 5.50396 5.64982 5.55023 5.53646C5.67756 5.22057 5.96689 4.89344 6.45223 4.89344C7.08898 4.89344 7.34328 5.37881 7.34328 6.08969V8.92027H9.10156V5.88662C9.10156 4.26164 8.23434 3.50555 7.07693 3.50555Z" />
        </SvgIcon>
    );
};
