// handles form values with special case for `search` field
import {SearchTab} from "../actions/fetch_search_all_action";
import {searchFormFields} from "./form_types";
import {ISearchInputValue} from "./ISearchInputValue";
import {QueryValues, toQueryValues} from "./to_query_values";

export const toQueryValuesWithSearch = <T>(formValues: Record<keyof T, unknown>): Record<string, string | number | number[]> => {
    // get valid query values
    const queryValues: QueryValues = toQueryValues(searchFormFields, formValues);
    const searchValue = (formValues as {search: ISearchInputValue})["search"] as ISearchInputValue;
    const {search, ...validQueryValues} = queryValues;
    switch (searchValue.tabType) {
        case SearchTab.SearchSuggestions:
            return {
                location: searchValue.suggestions.id,
                ...validQueryValues
            };

        case SearchTab.Places:
            return {
                lat: searchValue.location.lat,
                lng: searchValue.location.lng,
                name: searchValue.place && searchValue.place.description,
                ...validQueryValues
            };

        default:
            return {
                ...validQueryValues
            };
    }
};
