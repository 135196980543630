import {appLink, ISearchResultUniversalParams} from "@web2/gh_routes";

import {appendQueryString, IQueryParams} from "../../../app/utils/append_query_string";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {OfferType, UOfferTypes} from "../../utils/constants_offer";
import {validateOfferListTagWithParams} from "./tag_utils";
import {UrlTagSlug} from "./tags";

interface IData extends ISearchResultUniversalParams, IQueryParams {
    offerType: UOfferTypes | OfferType;
    tag?: UrlTagSlug;
}

/**
 * Returns valid offer list url, appends non-seo-friendly params
 * @param params
 * @param locationType
 */

export const getOfferListUrl = (params: IData, locationType?: LocationTypes) => {
    const validatedParams = validateOfferListTagWithParams(params, locationType);
    const {offerType, tag, type, location, subLocation, subSubLocation, ...restParams} = validatedParams;
    const pathParams = {
        offerType,
        type,
        location,
        subLocation,
        subSubLocation
    };

    const url = tag ? appLink.searchResult.universalTag({tag, ...pathParams}) : appLink.searchResult.universal(pathParams);
    return appendQueryString(url, restParams || {});
};
