import {css, Theme} from "@emotion/react";
import {IOpenStreetMap, LazyOpenStreetMap} from "@web2/open-street-map";
import {themeGh} from "@web2/themes";

interface IOpenStreetMapGH extends IOpenStreetMap {
    labelsInsideMarkers?: boolean | undefined;
}
export const OpenStreetMapGH = (props: IOpenStreetMapGH) => {
    return (
        <div css={[mapContainerStyle(themeGh, props.labelsInsideMarkers), props.labelsInsideMarkers && labelsAboveMarkers]}>
            <LazyOpenStreetMap {...props} />
        </div>
    );
};

const mapContainerStyle = (theme: Theme, labelsInsideMarkers: boolean | undefined) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    .leaflet-popup-tip {
        background-color: transparent;
    }

    .leaflet-container {
        width: 100%;
        height: 100%;
    }

    .leaflet-control-attribution {
        font-size: 1rem;

        & a {
            color: ${theme.colors.gray};
        }
    }

    .leaflet-popup-content-wrapper {
        overflow: hidden;
        padding: 0;
        background-color: transparent;
        border-radius: 4px;
    }

    .leaflet-popup-content {
        margin: 0;
    }

    ${labelsInsideMarkers
        ? css`
              .leaflet-tooltip {
                  background-color: rgba(255, 255, 255, 0.8);
                  z-index: 1;
                  margin-top: 0;
                  padding: 0 0.8rem;
                  border-radius: 2rem;
                  font-size: 1.2rem;
                  box-shadow: none;

                  &:before {
                      display: none;
                  }
              }
          `
        : css`
              .leaflet-tooltip {
                  background-color: transparent;
                  box-shadow: none;
                  border: none;
                  font-size: 1.2rem;
              }
          `};

    .cluster-icon {
        width: 36px;
        height: 36px;
        position: relative;

        img {
            height: 36px;
        }

        div.count {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            justify-content: center;
            justify-items: center;
            border: 1px solid #fff;
            background-color: #fff;
            height: 1.6rem;
            min-width: 1.6rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            ${theme.borders.border_radius_base};
            color: ${theme.colors.brand_primary};
            font-size: 11px;
            transform: translateY(-25%);
            max-height: 1.6rem;
        }
    }

    @media (max-width: ${theme.breakpoints_correct.screen_md}) {
        .leaflet-bottom.leaflet-right {
            .leaflet-control {
                position: fixed;
                bottom: 0;
                right: 0;
            }

            .leaflet-control-zoom {
                margin-bottom: 2rem;
            }
        }
    }
`;

const labelsAboveMarkers = css`
    .leaflet-label-pane {
        z-index: 600;
    }
`;
