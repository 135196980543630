// Please do not change string values. They were introduced by FM and are used for analytics ever since, we want to have continuity in naming.

export const mapTypeGTMEvent = (nextType: "terrain" | "satellite") => {
    if ((window as any).dataLayer) {
        if (nextType === "terrain") {
            return (window as any).dataLayer.push({event: "map_mode_terrain"});
        }
        if (nextType === "satellite") {
            return (window as any).dataLayer.push({event: "map_mode_satellite"});
        }
    }
    return null;
};
