import React from "react";
import {LISTING_GENERIC_TEXT} from "@web2/gh_page_object_models/offer_list/test_ids";

import {UrlTagSlug} from "../../url_utils/tags";
import {useTagFromPathname} from "../hooks/useTagFromPathname";
import {IGenericSeoDescription} from "../interfaces/interfaces_generic_listings";
import {DefaultGenericContent} from "./atoms/DefaultGenericContent";
import {PrimaryMarketCity} from "./atoms/PrimaryMarketCity";
export const GenericListingComponent = (props: IGenericSeoDescription) => {
    const {statistics, location, locationObject} = props;

    const locationDeclinationWhere = location?.name_declension_where || "";
    const city = locationObject.CITY?.name;
    const town = locationObject.TOWN?.name;
    const locationName = city || town || "";
    const additionalStatsAparments = statistics.statisticsLocation?.additional_stats.apartment_sell_primary_market_unique_investments;
    const hasTag = useTagFromPathname();

    const statsLocationApartmentSellPrimaryMarket = statistics.statisticsLocation?.apartment.sell.primary_market;
    const hasPrimaryMarket = statsLocationApartmentSellPrimaryMarket && statsLocationApartmentSellPrimaryMarket.count > 0;
    const renderGenericTextSwitch = (tag: UrlTagSlug | undefined) => {
        switch (tag) {
            case UrlTagSlug.PRIMARY_MARKET:
                return hasPrimaryMarket ? (
                    <PrimaryMarketCity
                        locationName={locationName}
                        locationDeclination={locationDeclinationWhere}
                        statistics={statsLocationApartmentSellPrimaryMarket}
                        uniqueInvestments={additionalStatsAparments}
                    />
                ) : null;
            default:
                return <DefaultGenericContent {...props} />;
        }
    };

    return <div data-testid={LISTING_GENERIC_TEXT}>{renderGenericTextSwitch(hasTag)}</div>;
};
