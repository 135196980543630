import {Dispatch} from "redux";
import {IFullOfferDetailParams} from "@web2/gh_routes";

import {IArticleListResponse} from "../../app/interfaces/response/article_list";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

const defaultParams = {
    districts_only: "True"
};
export const fetchDistrictArticleListActionTypes = createRequestActionTypes({
    name: "districtArticleListArticles",
    type: "GET",
    view: "Blog"
});

export const fetchDistrictArticleListAtRoute =
    (services: Partial<IServices>, route: IRouteState<IFullOfferDetailParams>) => (dispatch: Dispatch, getState: () => IStore) => {
        if (getState().articles.districtArticleList && getState().articles.districtArticleList.length) {
            return Promise.resolve(true);
        }
        dispatch({type: fetchDistrictArticleListActionTypes.start});

        const url = appendQueryString(apiLink.articles.base({})(null), {...defaultParams, sort: "title"});
        return getRequest({}, url)
            .then((response: IArticleListResponse) => {
                const result = {
                    page: response.page,
                    page_size: response.page_size,
                    count: response.count,
                    articles: response.results
                };
                dispatch({type: fetchDistrictArticleListActionTypes.success, result});
            })
            .catch((err) => console.error(err));
    };
