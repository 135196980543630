export const debounce = (func: () => void | Promise<any | void>, wait: number) => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    return (...args: []) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // null is not working hence undefined
            timeout = undefined;
            func.apply(this, ...args);
        }, wait);
    };
};
