import {Dispatch} from "redux";
import {appPath} from "@web2/gh_routes";

import {IStore} from "../../reducers/hybrid_reducer";
import {apiLink} from "../../routes/api_link";
import {IRouteState} from "../../routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../services/IServices";
import {appendQueryString} from "../../utils/append_query_string";
import {enableAuthorization, ghCommonUrl} from "../../utils/read_environment_variables";
import {createRequestActionTypes} from "../../utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../utils/request_response_utils/request";
import {catch5xx} from "../../utils/request_response_utils/response_error";
import {enable302ResponseState} from "../../utils/request_response_utils/response_state/response_state_actions";

export const fetchIsAuthenticatedTypes = createRequestActionTypes({type: "GET", name: "user/FETCH_IS_AUTHENTICATED"});

export const requireAuthenticationAtRoute = (services: Partial<IServices>, route: IRouteState<{}>) => async (dispatch: Dispatch) => {
    if (!enableAuthorization) {
        return false;
    }

    const isAuthenticated = await fetchIsAuthenticated(services)(dispatch);
    if (!isAuthenticated) {
        const {origin} = new URL(ghCommonUrl);
        const kmLoginUrl = appendQueryString(apiLink.userApi.oauth.authorize(), {
            next: route.pathname,
            redirect_uri: `${origin}${appPath.oauth.redirect}`
        });
        dispatch(enable302ResponseState(kmLoginUrl));
        return false;
    }

    return isAuthenticated;
};

export const fetchIsAuthenticated =
    (services: Partial<IServices>) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        if (!enableAuthorization) {
            return false;
        }
        dispatch({type: fetchIsAuthenticatedTypes.start});
        return getRequest(services, apiLink.userApi.users.info())
            .then((res) => {
                const isAuthenticated = (res && res.is_authenticated) || false;
                dispatch({type: fetchIsAuthenticatedTypes.success, result: isAuthenticated});
                return isAuthenticated;
            })
            .catch(
                catch5xx(() => {
                    dispatch({type: fetchIsAuthenticatedTypes.error});

                    return false;
                })
            );
    };

export const checkIsAuthenticated =
    () =>
    async (dispatch: Dispatch, getState: () => IStore): Promise<boolean> => {
        return getState().isAuthenticated.response;
    };
