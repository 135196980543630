import {Dispatch, SetStateAction} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router";
import {css} from "@linaria/core";
import {isEmpty} from "@web2/nodash";
import {useUserDevice} from "@web2/user-device";

import {AdPlacement} from "../../../advertisement/components/AdPlacement";
import {AdTargetingPageName, getAdTargetingString} from "../../../advertisement/get_ad_targeting";
import {PlacementDest} from "../../../advertisement/PlacementDest";
import {PlacementPage} from "../../../advertisement/PlacementPage";
import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {currentStaticsDir} from "../../../app/constants/current_statics_dir";
import {useDebouncedOfferBoxMouseEvents} from "../../../app/hooks/use_debounced_offer_box_mouse_events";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferModalDetail} from "../../../app/interfaces/response/offer_detail";
import {IOfferInvestment, IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {ghCommonUrl} from "../../../app/utils/read_environment_variables";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {parseSearch} from "../../../app/utils/request_response_utils/parse_search";
import {Footer} from "../../../footer/Footer";
import {getThemeBreakpoint, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {IGtmOffer} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {gtmOfferClick} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_offer_click";
import {gtmOfferListEvents} from "../../../tracking/google_tag_manager/gtm_offer_list_events";
import {GtmContactType} from "../../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../../tracking/view_type/view_type";
import {IViewTypeMetaData} from "../../../tracking/view_type/view_type_actions";
import {handleOfferBoxMetaClick, OFFERBOX_FULL_WIDTH_BREAKPOINT} from "../../detail/components/offer_box/OfferBox";
import {getHousingPlatformUrl} from "../../utils/constants_offer";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {getDefaultOfferCountPerPage} from "../actions/fetch_offer_list_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {getFeaturesListFromParam} from "../url_utils/tag_utils";
import {getOfferListAffixes} from "../utils/get_offer_list_affixes";
import {OfferListDynamicMap} from "./OfferListDynamicMapsContainer";
import {OfferListHeader} from "./OfferListHeader";
import {OfferListList} from "./OfferListList";
import {OfferListLocations} from "./OfferListLocations";
import {OfferListSeoDescription} from "./OfferListSeoDescription";
import {OfferListSeoLinks} from "./OfferListSeoLinks";

const placeholder1239x242 = `${ghCommonUrl}/${currentStaticsDir}/platforma_baner_1239x242.png`;
const adPlaceholder = {
    image: placeholder1239x242,
    url: getHousingPlatformUrl({source: "listing"})
};

interface IStateProps {
    locationData: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    latestQuery: IOfferListQuery;
    modalOfferRequestState: RequestState;
    offersData: {
        meta: IOfferListApiResponseMeta;
        collection_count: number;
        offers: IOfferListOfferResponse[];
        requestState: RequestState;
        page: number;
        pageCount: number;
    };
    currentModalOfferSlug: string | null;
    investment: {
        investment: IOfferInvestment | null;
        requestState: RequestState;
    };
    favouriteOffers: string[];
    visitedOffers: string[];
    viewType: ViewType | null;
    viewTypeData: Partial<IViewTypeMetaData>;
    offerModalState: boolean;
    seoDescription: string;
    offer: IOfferModalDetail;
    heading_h1?: string;
}

interface Props extends IStateProps {
    isMapBig: boolean;
    setMapBig: Dispatch<SetStateAction<boolean>>;
    latestQuery: IOfferListQuery;
    setFavouriteOffer: typeof setFavouriteOffer;
    goToOffer: (slug: string) => void;
    prevPath: string | null;
    setPrevPath: (path: string | null) => void;
}

export const OfferListDynamicBodyC = (props: Props) => {
    const location = useLocation();
    const {isMobile} = useUserDevice();

    const {hoveredOfferId, onMouseEnterOfferBox, onMouseLeaveOfferBox} = useDebouncedOfferBoxMouseEvents(500, true);

    const activeFeatures = getFeaturesListFromParam(props.latestQuery.features);

    const adTargeting = getAdTargetingString({
        locationPath: props.locationData.location?.path,
        pageNames: [AdTargetingPageName.LISTING]
    });

    // casting as ILocationStatistics - TS does not omit {} when using !isEmpty()
    const locationStatistics =
        props.locationData.statistics && !isEmpty(props.locationData.statistics) ? (props.locationData.statistics as ILocationStatistics) : null;

    const shouldRenderLocationStats = () => {
        if (!locationStatistics || !props.locationData.location || props.offersData.meta.to_extend) {
            return false;
        }
        const {page, size__gte, size__lte, rooms, price__lte, price__gte} = parseSearch(location.search);
        const pageInt = parseInt(page as string, 10) || 1;
        return !(pageInt !== 1 || size__gte || size__lte || rooms || price__lte || price__gte);
    };

    const shouldRenderSeoDescription = () => {
        if (!props.seoDescription) {
            return false;
        }
        const {page, size__gte, size__lte, rooms, price__lte, price__gte} = parseSearch(location.search);
        const pageInt = parseInt(page as string, 10) || 1;
        return !(pageInt !== 1 || size__gte || size__lte || rooms || price__lte || price__gte);
    };

    const {gtmListPrefix, gtmListSuffix} = getOfferListAffixes(props.latestQuery);

    const onListOfferClick = (e: React.MouseEvent<HTMLElement>, offer: IGtmOffer) => {
        // gtmIndex is calculated because we don't report listPosition for recommended offers
        const gtmIndex = props.offersData.offers.findIndex((offr) => offr.slug === offer.slug);
        const listPosition =
            Number.isFinite(gtmIndex) && gtmIndex >= 0
                ? (props.offersData.page - 1) * getDefaultOfferCountPerPage({offerType: props.latestQuery.offer_type}) + gtmIndex
                : "na";

        gtmOfferClick({
            offer: offer,
            viewType: ViewType.OFFER_LIST_ALL,
            contactType: GtmContactType.UNKNOWN,
            gtmSource: GtmSource.UNKNOWN,
            listPosition,
            listPrefix: gtmListPrefix,
            listSuffix: gtmListSuffix
        });

        gtmOfferListEvents.offerBox.click();

        handleOfferBoxMetaClick(e, () => props.goToOffer(offer.slug));
    };

    return (
        <>
            <section className={props.isMapBig ? listHolderBig : listSmallMapSizes}>
                <OfferListHeader
                    latestQuery={props.latestQuery}
                    offerLocation={props.locationData}
                    shouldRenderLocationStats={shouldRenderLocationStats()}
                    offerCount={props.offersData.meta?.total_offers_not_collapsed || 0}
                    isMapBig={props.isMapBig}
                    investment={props.investment}
                    page={props.offersData.page}
                />
                <AdPlacement
                    dest={PlacementDest.DESKTOP}
                    page={PlacementPage.offer_list__desktop}
                    className={adPlacementStyle}
                    placeholder={adPlaceholder}
                    breakpoint={OFFERBOX_FULL_WIDTH_BREAKPOINT}
                    target={adTargeting}
                    fetchPriority="high"
                />
                <OfferListList
                    latestQuery={props.latestQuery}
                    offersData={props.offersData}
                    currentModalOfferSlug={props.currentModalOfferSlug}
                    combinedOffersList={props.offersData.offers}
                    favouriteOffers={props.favouriteOffers}
                    visitedOffers={props.visitedOffers}
                    setFavouriteOffer={props.setFavouriteOffer}
                    isMapBig={props.isMapBig}
                    onMouseEnterOfferBox={onMouseEnterOfferBox}
                    onMouseLeaveOfferBox={onMouseLeaveOfferBox}
                    modalOfferRequestState={props.modalOfferRequestState}
                    onOfferClick={onListOfferClick}
                    prevPath={props.prevPath}
                    setPrevPath={props.setPrevPath}
                    viewType={props.viewType}
                    offerModalState={props.offerModalState}
                    adTargeting={adTargeting}
                    offer={props.offer}
                />

                <OfferListSeoDescription
                    offerLocation={props.locationData}
                    locationDescription={props.seoDescription}
                    latestQuery={props.latestQuery}
                    isMapBig={props.isMapBig}
                    shouldRenderSeoDescription={shouldRenderSeoDescription()}
                    offerCount={props.offersData.meta?.total_offers_not_collapsed || 0}
                    shouldRenderLocationStats={shouldRenderLocationStats()}
                />

                {props.locationData.recommended.length > 0 && (
                    <OfferListLocations
                        recommended={props.locationData.recommended}
                        offerType={props.latestQuery.offer_type}
                        dealType={props.latestQuery.deal_type}
                        location={props.locationData.location}
                    />
                )}

                <OfferListSeoLinks
                    apartmentStatistics={locationStatistics?.apartment[props.latestQuery.deal_type ?? "all"]}
                    locationStatistics={locationStatistics}
                    offerType={props.latestQuery.offer_type}
                    dealType={props.latestQuery.deal_type}
                    location={props.locationData.location}
                    latestQuery={props.latestQuery}
                    activeFeatures={activeFeatures}
                    offerListMeta={props.offersData.meta}
                />

                <Footer />
            </section>

            {!isMobile && (
                <OfferListDynamicMap
                    latestQuery={props.latestQuery}
                    setMapBig={props.setMapBig}
                    locationData={props.locationData}
                    viewType={props.viewType}
                    hoveredOfferId={hoveredOfferId}
                    offersData={props.offersData}
                    favouriteOffers={props.favouriteOffers}
                    isMapBig={props.isMapBig}
                    investment={props.investment}
                    goToOffer={props.goToOffer}
                    visitedOffers={props.visitedOffers}
                    setFavouriteOffer={props.setFavouriteOffer}
                />
            )}
        </>
    );
};

function mapStateToProps(state: IStore): IStateProps {
    return {
        locationData: state.offerList.location,
        latestQuery: state.offerList.latestQuery,
        offersData: state.offerList.offers,
        currentModalOfferSlug: state.offer.offer?.slug,
        favouriteOffers: state.favourites.favourites,
        visitedOffers: state.visited.offers,
        modalOfferRequestState: state.offer.offerRequestState,
        investment: state.offerList.investment,
        viewType: state.viewType.current,
        viewTypeData: state.viewType.currentData,
        offerModalState: state.offerList.offerModalState,
        offer: state.offer.offer,
        seoDescription: state.offerList.seoDescription,
        heading_h1: state.metaData.customMetaData?.heading_h1
    };
}

export const OfferListDynamicBody = connect(mapStateToProps, {})(OfferListDynamicBodyC);
// added default export to handle lazy load in client side routing of offer detail entry point
export default OfferListDynamicBody;

const listHolderBig = css`
    width: ${getThemeVariable("listing_sizes-full")};
    font-size: 30px;
    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        width: ${getThemeVariable("listing_sizes-sm")};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg_ipad}) {
        width: ${getThemeVariable("listing_sizes-lg")};
    }

    @media (min-width: ${getThemeBreakpoint().screen_xl}) {
        width: ${getThemeVariable("listing_sizes-sm")};
    }
`;

const listSmallMapSizes = css`
    width: ${getThemeVariable("listing_sizes-full")};

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        width: ${getThemeVariable("listing_sizes-md")};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg_ipad}) {
        width: ${getThemeVariable("listing_sizes-lg")};
    }

    @media (min-width: ${getThemeBreakpoint().screen_xl}) {
        width: ${getThemeVariable("listing_sizes-xl")};
    }
`;

const adPlacementStyle = css`
    display: none;

    @media (min-width: ${OFFERBOX_FULL_WIDTH_BREAKPOINT}px) {
        display: block;
    }
`;
