import {combineReducers, Reducer} from "redux";

import {IAgencyOffer} from "../../agency/actions/fetch_developer_offers_at_route";
import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchAgentDetailTypes, IAgent} from "../actions/fetch_agent_detail_at_route";
import {fetchAgentOffersTypes} from "../actions/fetch_agent_offers_at_route";

export interface IAgentStore {
    agent: IAgent;
    agentOffers: IAgencyOffer[];
}

const agentReducerObj: Record<keyof IAgentStore, Reducer<any, any>> = {
    agent: reduceResponse(fetchAgentDetailTypes),
    agentOffers: reduceResponse(fetchAgentOffersTypes)
};

export const agentReducer = combineReducers(agentReducerObj);
