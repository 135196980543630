import {styled} from "@linaria/react";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IDealtTypeListElement {
    isActive?: boolean;
}

export const DropdownListElementBody = styled.div<IDealtTypeListElement>`
    padding: 1rem 1.5rem;
    display: block;
    text-decoration: none;
    color: ${(props) => (props.isActive ? getThemeVariable("colors-brand_primary") : "unset")};
    cursor: pointer;
    background-color: ${(props) => (props.isActive ? getThemeVariable("colors-filter_active_bg") : "unset")};
`;
