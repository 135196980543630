import * as React from "react";
import {css} from "@linaria/core";
import {includes, reduce} from "lodash";

import {IFeaturesTypes, ISearchFeature} from "../../../app/interfaces/response/server_list_response";
import {ISearchPageUrlTag, searchPageUrlTags} from "../../../offer/list/url_utils/tags";
import {OfferMarketType} from "../../../offer/utils/constants_offer";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {ListElementCheckboxBody} from "../atoms/ListElementCheckboxBody";

interface IOwnProps {
    name: string;
    onChange: (fieldName: string, value: IFeaturesTypes[]) => void;
    value: IFeaturesTypes[];
    marketType?: OfferMarketType;
    offerType: string;
    offersMeta: {
        features?: ISearchFeature[];
    } | null;
}

type IFeature = {label: string; featureData: ISearchPageUrlTag; marketType?: OfferMarketType};

const apartmentFeatureList: IFeature[] = [
    {label: "ogródek", featureData: searchPageUrlTags.GARDEN},
    {label: "balkon", featureData: searchPageUrlTags.BALCONIES},
    {label: "taras", featureData: searchPageUrlTags.TERRACES},
    {label: "ostatnie piętro", featureData: searchPageUrlTags.LAST_FLOOR},
    {label: "do remontu", featureData: searchPageUrlTags.RENOVATION},
    {label: "do zamieszkania", featureData: searchPageUrlTags.INHABITANCY}
];

const houseFeatureList: IFeature[] = [
    {label: "wolnostojący", featureData: searchPageUrlTags.DETACHED},
    {label: "szeregowy", featureData: searchPageUrlTags.TERRACED},
    {label: "bliźniak", featureData: searchPageUrlTags.SEMI_DETACHED},
    {label: "z tarasem", featureData: searchPageUrlTags.TERRACES},
    {label: "z garażem", featureData: searchPageUrlTags.GARAGE},
    {label: "z poddaszem użytkowym", featureData: searchPageUrlTags.ATTIC}
];

const lotFeatureList: IFeature[] = [
    {label: "budowlana", featureData: searchPageUrlTags.BUILDING},
    {label: "rekreacyjna", featureData: searchPageUrlTags.RECREATION},
    {label: "nad jeziorem", featureData: searchPageUrlTags.LAKE},
    {label: "nad morzem", featureData: searchPageUrlTags.SEA},
    {label: "w górach", featureData: searchPageUrlTags.MOUNTAINS},
    {label: "uzbrojona", featureData: searchPageUrlTags.UTILITIES},
    {label: "z domkiem", featureData: searchPageUrlTags.HOUSE},
    {label: "nad rzeką", featureData: searchPageUrlTags.RIVER}
];

export const FeaturesFilter = (props: IOwnProps) => {
    const {value, name} = props;

    // offerType comes in something like 'lot' or 'aftermarket__apartment,primary_market__houses' etc.
    const isLot = props.offerType === "lot";
    const includesHouses = includes(props.offerType, "house");
    const includesApartments = includes(props.offerType, "apartment");
    const isHouse = includesHouses && !includesApartments && !isLot;
    const isApartment = includesApartments && !includesHouses && !isLot;

    const availableFeatures = reduce(
        props.offersMeta?.features,
        (acc: IFeaturesTypes[], feat) => {
            acc.push(feat.feature);
            return acc;
        },
        []
    );

    const calculateTypeArray = (type: IFeaturesTypes) => {
        if (value.includes(type)) {
            return value.filter((val) => val !== type);
        }
        return [...value, type];
    };

    const onChange = (type: IFeaturesTypes) => {
        const newValue = calculateTypeArray(type);
        props.onChange(name, newValue);
    };

    return (
        <div className={listElementIndent}>
            {isApartment && (
                <>
                    Cechy dodatkowe
                    {apartmentFeatureList.map(getFeatureCheckboxIteratee(value, onChange, availableFeatures, props.marketType))}
                </>
            )}
            {isHouse && (
                <>
                    Cechy dodatkowe
                    {houseFeatureList.map(getFeatureCheckboxIteratee(value, onChange, availableFeatures, props.marketType))}
                </>
            )}
            {isLot && (
                <>
                    Cechy dodatkowe
                    {lotFeatureList.map(getFeatureCheckboxIteratee(value, onChange, availableFeatures, props.marketType))}
                </>
            )}
        </div>
    );
};

const getFeatureCheckboxIteratee =
    (value: IFeaturesTypes[], onChange: (type: IFeaturesTypes) => void, availableFeatures: IFeaturesTypes[], marketType?: OfferMarketType) =>
    (feature: IFeature) => {
        const checkboxValue = (feature.featureData.featureName && value.includes(feature.featureData.featureName)) || false;
        const isDisabled = !availableFeatures.find((el) => el === feature.featureData.featureName) && !checkboxValue;

        return feature.marketType === marketType ? (
            <ListElementCheckboxBody
                name={feature.featureData.featureName || feature.label}
                label={feature.label}
                onChange={() => feature.featureData.featureName && onChange(feature.featureData.featureName)}
                value={checkboxValue}
                disabled={isDisabled}
                key={feature.featureData.featureName}
            />
        ) : null;
    };

const listElementIndent = css`
    color: ${getThemeVariable("colors-gray_darker")};
    line-height: 1.35;
    white-space: nowrap;
    margin-top: 1rem;
`;
