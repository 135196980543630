import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const ArrowUpIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M1.3,7.6l3.7-3.7l3.7,3.7c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1L5.8,2.4C5.4,2,4.7,2,4.4,2.4L0.3,6.5 C0,6.8-0.1,7.2,0.2,7.6s0.7,0.4,1.1,0.1C1.3,7.6,1.3,7.6,1.3,7.6z" />
        </SvgIcon>
    );
};
