import * as React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {filter, isEmpty, isEqual} from "lodash";
import {bindActionCreators, Dispatch} from "redux";
import {form, IFormProps} from "@web2/form2";
import {SEARCH_DROPDOWN_TESTID} from "@web2/gh_page_object_models";
import {usePrevious} from "@web2/react_utils";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {ILastSearchStore} from "../../app/reducers/last_search_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {OfferDealType} from "../../offer/utils/constants_offer";
import {getThemeBreakpoint, getThemeVariable} from "../../styles/linaria_variable_factory";
import {autocompleteClickGTMEvent} from "../../tracking/google_tag_manager/autocomplete_click";
import {gtmEventSearchUse} from "../../tracking/google_tag_manager/gtm_search_use";
import {fetchLastSearchSuggestions} from "../actions/fetch_last_search_suggestions";
import {fetchAllSearchLists, resetFetchAllSearchLists, SearchTab, stopFetchAllSearchLists} from "../actions/fetch_search_all_action";
import {optimizedFetchPlaceList} from "../actions/fetch_search_places_action";
import {clearLabelOfActiveValue, resetActiveDropdownItem, setDropdownItemDirection} from "../actions/set_active_dropdown_item";
import {getItemOnClick, setItemOnEnter} from "../actions/set_item_on_enter";
import {updateCurrentTab} from "../actions/update_current_tab";
import {ISearchStore} from "../reducers/search_reudcer";
import {ISearchInputValue} from "../utils/ISearchInputValue";
import {SearchAutocompleteDropdown} from "./SearchAutocompleteDropdown";
import {SearchAutocompleteInput} from "./SearchAutocompleteInput";

interface ISearchAutocompleteFormValues {
    search: any;
}

interface IStateProps {
    search: ISearchStore;
    lastSearch: ILastSearchStore;
}

interface IActionsProps {
    setDropdownItemDirection: typeof setDropdownItemDirection;
    resetActiveDropdownItem: typeof resetActiveDropdownItem;
    fetchAllSearchLists: any;
    stopFetchAllSearchLists: typeof stopFetchAllSearchLists;
    resetFetchAllSearchLists: typeof resetFetchAllSearchLists;
    optimizedFetchPlaceList: any;
    updateCurrentTab: typeof updateCurrentTab;
    setItemOnEnter: any;
    clearLabelOfActiveValue: typeof clearLabelOfActiveValue;
    fetchLastSearchSuggestions: typeof fetchLastSearchSuggestions;
}

interface IOwnProps extends IFormProps<ISearchAutocompleteFormValues> {
    autoFocus?: boolean;
    dealType?: OfferDealType;
    isHomepageSearch?: boolean;
    isSearchDropdownOpen: boolean;
    onDropdownStatusChange: (isOpen: boolean) => void;
    setSearchDropdownOpen: (isOpen: boolean) => void;
    wrapperRef: React.MutableRefObject<HTMLElement | null>;
}

interface IProps extends IStateProps, IActionsProps, IOwnProps {}

const SearchAutocompleteC = (props: IProps) => {
    let latestSearchedLabel: string | null = null;
    const searchProps = props.getFieldProps("search");
    const prevSearchProps = usePrevious(searchProps, searchProps);

    /**
     * Lifecycle
     */

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        const {label} = searchProps.value;
        if (isEmpty(label)) {
            return;
        }
        latestSearchedLabel = label;
        (async () => {
            const searchTab = await props.fetchAllSearchLists(label);
            if (searchTab != null) {
                switchTab(searchTab, true);
            }
        })();
    }, []);

    useEffect(() => {
        if (props.lastSearch.lastSearch.length) {
            const foundLocations = filter(props.lastSearch.lastSearch, (lS) => lS.suggest_type === "location");
            const foundLocation = foundLocations[foundLocations.length - 1];
            if (foundLocation && foundLocation.suggest_type === "location") {
                props.fetchLastSearchSuggestions(foundLocation.id, props.dealType);
            }
        } else {
            props.fetchLastSearchSuggestions();
        }
    }, [props.lastSearch.lastSearch]);

    useEffect(() => {
        const {label: prevLabel, ...prevRestProps} = prevSearchProps.value;
        const {label, ...restProps} = searchProps.value;

        if (isEmpty(label)) {
            return;
        }
        if (prevLabel === label && latestSearchedLabel === label) {
            return;
        }
        // fetch again only when input value changes
        // OR for server side load.
        if (isEqual(prevRestProps, restProps) || prevLabel !== label) {
            (async () => {
                latestSearchedLabel = label;
                const searchTab = await props.fetchAllSearchLists(label);
                props.clearLabelOfActiveValue();
                if (searchTab != null) {
                    switchTab(searchTab, true);
                }
            })();
        }
    }, [searchProps.value]);

    useEffect(() => {
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            // all fetches should stop on unmount
            props.stopFetchAllSearchLists();
            // prevent old data to appear after re-visit component
            props.resetFetchAllSearchLists();
        };
    }, []);

    /**
     * Callbacks
     */

    const handleClickOutside = (event: Event) => {
        if (props.wrapperRef && props.wrapperRef.current && !props.wrapperRef.current.contains(event.target as Node)) {
            closeDropdown();
            props.resetActiveDropdownItem();
            props.onDropdownStatusChange(false);
        }
    };

    /**
     * Callbacks - state manipulation
     */

    const openDropdown = () => {
        autocompleteClickGTMEvent();
        props.setSearchDropdownOpen(true);
        props.onDropdownStatusChange(true);
    };

    const closeDropdown = () => {
        props.setSearchDropdownOpen(false);
    };

    /**
     * Callbacks - lifecycle
     */

    const switchTab = (tabType: SearchTab, automatedInitialChange = false) => {
        const searchProps = props.getFieldProps("search");

        // update store tab
        props.updateCurrentTab(tabType);
        // this allows to update `distance` according to next selected tab
        if (props.search.currentTab !== tabType || automatedInitialChange) {
            props.resetActiveDropdownItem();
        }
        // fetch places data on user interaction change (not automated)
        if (!automatedInitialChange && props.search.currentTab !== tabType && tabType === SearchTab.Places) {
            props.optimizedFetchPlaceList(searchProps.value.label);
        }
    };

    /**
     * Callbacks - Input
     */

    // return string value of placeholder and boolean whether it is an actual placeholder
    const createInputPlaceholder = (): [string, boolean] => {
        const searchProps = props.getFieldProps("search");

        if (searchProps.value.tabType === SearchTab.Placeholder && searchProps.value.placeholder) {
            return [searchProps.value.placeholder.full_name_reverted, false];
        }
        return ["Gdzie chcesz mieszkać?", true];
    };

    const getActiveItemLabel = (id: number | null): string | undefined => {
        const inputValue = props.values["search"].label;

        if (id == null) {
            return;
        }

        const {currentTab, places, fetchPlacesRequest} = props.search;
        const {lastSearch, lastSearchSuggestions, lastSearchSuggestionsRequest} = props.lastSearch;

        if (isEmpty(inputValue)) {
            if (lastSearchSuggestionsRequest === RequestState.Success) {
                const lastSearchSet = [...lastSearch, ...lastSearchSuggestions];
                if (id < lastSearchSet.length) {
                    const item = lastSearchSet[id];
                    if (item.suggest_type !== "place") {
                        return item.name;
                    }
                    if (item.suggest_type === "place") {
                        return item.description;
                    }
                }
            }
            return;
        }

        if (currentTab === SearchTab.SearchSuggestions) {
            if (fetchSuggestionsRequest === RequestState.Success) {
                return suggestions[id] && suggestions[id].name;
            }
            return;
        }
        if (currentTab === SearchTab.Places) {
            if (fetchPlacesRequest === RequestState.Success) {
                return places[id] && places[id].description;
            }
            return;
        }
        return;
    };

    /**
     * Callbacks - Dropdown
     */

    const onKeyDownHandler = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            closeDropdown();
            props.resetActiveDropdownItem();
            props.onDropdownStatusChange(false);
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
            openDropdown();
            props.setDropdownItemDirection("next");
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            openDropdown();
            props.setDropdownItemDirection("previous");
        }
        if (e.key === "Enter") {
            if (props.isSearchDropdownOpen) {
                gtmEventSearchUse();

                const itemIsSet = await props.setItemOnEnter();
                if (itemIsSet) {
                    closeDropdown();
                    props.resetActiveDropdownItem();
                    props.onDropdownStatusChange(false);
                    props.onChange(itemIsSet);
                    setTimeout(() => props.onAfterChange && props.onAfterChange(searchProps.name, itemIsSet.search), 0);
                }
                // NOTE: we do not close dropdown when Enter did not found item
            } else {
                closeDropdown();
                // we submit on every enter
                props.onSubmit && props.onSubmit();
            }
        }

        //Note: should it run on default? commented because of homepage search problems (submitting when typing)
        // setTimeout(() => {
        //     const searchProps = props.getFieldProps("search");
        //     props.onAfterChange && props.onAfterChange(searchProps.name, searchProps.value);
        // }, 0);
    };

    const onLinkClick = async (option: ISearchInputValue) => {
        const searchProps = props.getFieldProps("search");
        closeDropdown();
        const createdOption = (await getItemOnClick(option)) as ISearchInputValue;
        props.onChange({search: createdOption});
        props.resetActiveDropdownItem();
        setTimeout(() => props.onAfterChange && props.onAfterChange(searchProps.name, option), 0);
        gtmEventSearchUse();
        return;
    };

    /**
     * Render
     */

    const {fetchPlacesRequest, fetchSuggestionsRequest} = props.search;
    const requestStateObj = {
        fetchPlacesRequest,
        fetchSuggestionsRequest: fetchSuggestionsRequest
    };
    const {places, suggestions} = props.search;
    const [placeholder, isPlaceholder] = createInputPlaceholder();

    return (
        <div className={relativeWrapper} onKeyDown={onKeyDownHandler}>
            <SearchAutocompleteInput
                {...searchProps}
                autoFocus={props.autoFocus}
                onAfterChange={props.onAfterChange as any}
                isPlaceholderValue={isPlaceholder}
                openDropdown={openDropdown}
                closeDropdown={closeDropdown}
                activeItemLabel={getActiveItemLabel(props.search.activeDropdownItem.id)}
                placeholder={placeholder}
                isDropdownOpen={props.isSearchDropdownOpen}
                isHomepageSearch={props.isHomepageSearch}
            />

            <SearchDropdown isOpen={props.isSearchDropdownOpen} isHomepage={props.isHomepageSearch} data-testid={SEARCH_DROPDOWN_TESTID.WRAPPER}>
                <SearchAutocompleteDropdown
                    dealType={props.dealType}
                    dropdownIsOpen={props.isSearchDropdownOpen}
                    search={searchProps.value}
                    selectedTab={props.search.currentTab}
                    requestState={requestStateObj}
                    activeItem={props.search.activeDropdownItem}
                    places={places}
                    suggestions={suggestions}
                    onLinkClick={onLinkClick}
                    lastSearch={props.lastSearch}
                />
            </SearchDropdown>
        </div>
    );
};

export const SearchAutocomplete = connect(mapStateToProps, mapActionsToProps)(form<ISearchAutocompleteFormValues, IProps>(SearchAutocompleteC));

function mapStateToProps(state: IStore): IStateProps {
    return {
        search: state.search,
        lastSearch: state.lastSearch
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                fetchLastSearchSuggestions,
                resetActiveDropdownItem,
                fetchAllSearchLists,
                setDropdownItemDirection,
                stopFetchAllSearchLists,
                resetFetchAllSearchLists,
                optimizedFetchPlaceList,
                updateCurrentTab,
                setItemOnEnter,
                clearLabelOfActiveValue
            },
            dispatch
        )
    };
}

const relativeWrapper = css`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

interface ISearchDropdown {
    isOpen: boolean;
    isHomepage?: boolean;
}

const SearchDropdown = styled.div<ISearchDropdown>`
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    position: absolute;
    z-index: 2;
    top: ${(props) => (props.isHomepage ? "calc(100% + 12px)" : "50px")};
    left: 0;
    width: 100%;
    max-height: 300px;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: ${getThemeVariable("other-border_radius")};
    background: ${getThemeVariable("colors-body_bg")};

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        width: ${(props) => (props.isHomepage ? "100%" : "700px")};
        max-height: 600px;
        padding: 20px 0 20px 20px;
        overflow: hidden;
    }
`;
