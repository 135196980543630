import React, {useEffect} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {FetchStateType, useFetch} from "@web2/react_utils";

import {LeadType} from "../../app/components/ModalSimilarOffers";
import {IOfferDetailFormPlaceholder, IOfferDetailLocationPathElement} from "../../app/interfaces/response/offer_detail";
import {getMappedLocationPath} from "../../app/utils/get_offer_location_obj_from_path";
import {ApplicationModalPrivateOffers} from "../../monetization_testing/modals/ApplicationModalPrivateOffers";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {IGtmOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {ViewType} from "../../tracking/view_type/view_type";
import {applicationFetchOffer} from "../actions/application_fetch_offer";
import {fetchMultiLeadOffers} from "../actions/fetch_multilead_offers";
import {ApplicationModalAgencyOffers} from "./ApplicationModalAgencyOffers";
import {IApplicationModalContentProps} from "./ApplicationModalForm";
import {InactiveOfferModal} from "./InactiveOfferModal";

export enum ApplicationSteps {
    FORM,
    PHONE_CONTACT,
    CONFIRMATION,
    SIMILAR_OFFERS
}

interface IProps extends Omit<IApplicationModalContentProps, "offer" | "voivodeshipSlug" | "vendorRodoData"> {
    modalHeader?: string;
    modalState: boolean;
    onModalClose: (e?: React.MouseEvent) => void;
    isOfferListModal?: boolean;
    viewType: ViewType | null;
    offerId: string | undefined;
    leadType?: LeadType;
    investment?: {
        id: string;
        developer: {
            id: string;
            name: string;
            logo_picture: {
                a_log_100: string;
            } | null;
            slug: string;
            type: VendorType;
        };
        lead_form_configuration?: IOfferDetailFormPlaceholder;
        location: {
            short_name: string;
        };
        name: string;
        main_image: {
            o_img_500: string;
        };
        phone_clip?: string | null;
    } | null;
    onOfferClick?: (e: React.MouseEvent<any, any>, offer: IGtmOffer) => void;
}

export interface IApplicationModalHolder {
    currentPage?: number;
    hasNoSimilarOffers?: boolean;
    investment?: boolean;
}

// TODO: remove private offers/business test code after confirmation from PM
export const scopedCities: string[] = [];
export const isScopedCity = (cityName: string | undefined) => {
    return cityName ? scopedCities.includes(cityName) : false;
};
export const meetsPrivateOffersModalConditions = (offer?: {
    is_private: boolean;
    property?: {
        location?: {
            path?: {
                name: string;
                type: string;
                slug: string;
            }[];
        } | null;
    };
}) => {
    const isPrivate = offer?.is_private;

    const locationPath = offer?.property?.location?.path;
    const cityName = getMappedLocationPath<IOfferDetailLocationPathElement>(locationPath)?.CITY?.name;
    return isPrivate && isScopedCity(cityName);
};

export const ApplicationModal = (props: IProps) => {
    const {fetchStart: fetchOffer, response: offer, fetchState} = useFetch(() => applicationFetchOffer(props.offerId, 3));
    const {fetchStart: fetchSimilarOffer, response: similarOffers} = useFetch(() =>
        fetchMultiLeadOffers(props.offerId, true, isForSale ? 5 : 3, marketTypeToFetch)
    );

    const isForSale = !!(offer && (offer?.deal_type == OfferDealType.SELL || offer?.investment));
    const marketTypeToFetch = !isForSale || offer?.property.type === PropertyType.LOT ? OfferMarketType.AFTERMARKET : OfferMarketType.PRIMARY_MARKET;

    const {modalState, offerId, onModalClose, investment} = props;
    const activeModal = !!(fetchState === FetchStateType.Waiting || fetchState === FetchStateType.None || offer || investment);

    useEffect(() => {
        if (props.modalState && props.offerId) {
            fetchOffer();
        }
    }, [props.modalState, props.offerId]);

    useEffect(() => {
        if (offer || !activeModal) {
            fetchSimilarOffer();
        }
        return;
    }, [offer, activeModal]);

    const isPrivate = offer?.is_private;
    const hasNoSimilarOffers = similarOffers?.length === 0;

    return (
        <>
            {activeModal ? (
                <>
                    <>
                        {(!isPrivate || !meetsPrivateOffersModalConditions(offer)) && (
                            <ApplicationModalAgencyOffers
                                offer={offer}
                                fetchState={fetchState}
                                {...props}
                                fetchSimilarOffer={fetchSimilarOffer}
                                similarOffers={similarOffers}
                            />
                        )}
                    </>
                    <>
                        {offer && meetsPrivateOffersModalConditions(offer) && (
                            <ApplicationModalPrivateOffers fetchOffer={fetchOffer} offer={offer} fetchState={fetchState} {...props} />
                        )}
                    </>
                </>
            ) : (
                <InactiveOfferModal
                    modalState={modalState}
                    similarOffers={similarOffers}
                    offerId={offerId}
                    onModalClose={onModalClose}
                    hasNoSimilarOffers={hasNoSimilarOffers}
                    investment={!!props.investment?.id}
                />
            )}
        </>
    );
};

// CSS classes and components used outside ApplicationModal

export const successModal = (theme: Theme) => css`
    height: auto;
    width: auto;
    border-radius: ${theme.other.border_radius};
    border: 1px solid ${theme.colors.gray_very_dark};
`;

export const ApplicationModalHolder = styled.div<IApplicationModalHolder>`
    overflow: auto;
    height: ${(props) => (props.currentPage === ApplicationSteps.SIMILAR_OFFERS || props.currentPage === ApplicationSteps.CONFIRMATION ? "auto" : "100%")};
    padding: ${(props) =>
        props.currentPage === ApplicationSteps.SIMILAR_OFFERS || props.currentPage === ApplicationSteps.CONFIRMATION ? "2rem 2.5rem" : "0 1.6rem 1.6rem"};

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: ${(props) => (props.currentPage === ApplicationSteps.SIMILAR_OFFERS || props.currentPage === ApplicationSteps.CONFIRMATION ? "auto" : "81rem")};
        height: ${(props) =>
            props.currentPage === ApplicationSteps.SIMILAR_OFFERS || props.currentPage === ApplicationSteps.CONFIRMATION
                ? !props.hasNoSimilarOffers
                    ? "auto"
                    : "10rem"
                : "53rem"};
        padding: ${(props) =>
            props.currentPage === ApplicationSteps.SIMILAR_OFFERS || props.currentPage === ApplicationSteps.CONFIRMATION ? "2rem 2.5rem" : "0 2.8rem 2.8rem"};
    }
`;
