export function retrieveIDFromSlug(slug: string): string {
    const parts = slug.split("-");
    const ID = parts[parts.length - 1];

    if (!isNaN(Number(ID))) {
        return ID;
    }

    return slug;
}
