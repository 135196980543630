import {combineReducers, Reducer} from "redux";

import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferInvestment, IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {reduceRequestState, RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../../app/utils/request_response_utils/factories/reduce_response";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {offerModalStateReducer} from "../../reducers/offer_modal_state_reducer";
import {OfferDealType} from "../../utils/constants_offer";
import {fetchLocationBySlugTypes, ILocation} from "../actions/fetch_location_by_slug_at_route";
import {fetchLocationStatisticsTypes} from "../actions/fetch_location_statistics";
import {fetchOfferListTypes} from "../actions/fetch_offer_list";
import {fetchOfferListInvestmentTypes} from "../actions/fetch_offer_list_investment";
import {fetchOfferListMarkersTypes, IListingMarker} from "../actions/fetch_offer_list_markers";
import {fetchRecommendedLocationsTypes} from "../actions/fetch_recommended_locations";
import {fetchOfferListSeoDescriptionTypes} from "../actions/fetch_search_seo_description";
import {SortType} from "../components/OfferListSortButton";
import {reduceLatestQuery} from "./reduce_latest_query";

export interface IOfferListQuery
    extends Partial<
        ICommonOfferListQueryParams & {
            location: string;
        }
    > {
    offer_type: TypeSearchFilterOptions;
    page: string;
    per_page: number;
    deal_type: OfferDealType;
    sort?: SortType;
}

export interface ICommonOfferListQueryParams {
    agency: string;
    agent: string;
    geo_box_bottom_left: string;
    geo_box_top_right: string;
    geo_point: string;
    investment: string;
    search_name: string;
    offer_type_query: string;
    price__gte: string;
    price__lte: string;
    price_per_sqm__gte: string;
    price_per_sqm__lte: string;
    rooms: string;
    size__gte: string;
    size__lte: string;
    sort: SortType;
    features: string;
    is_private?: string;

    floor__gte: string;
    floor__lte: string;
    construction_year__gte: string;
    construction_year__lte: string;
    available_from__gte: string;
    available_from__lte: string;
}

export interface IOfferListStore {
    offers: {
        meta: IOfferListApiResponseMeta;
        collection_count: number;
        offers: IOfferListOfferResponse[];
        requestState: RequestState;
        pageCount: number;
        page: number;
    };
    location: {
        location: ILocation | null;
        locationRequestState: RequestState;
        statistics: ILocationStatistics | {} | null; // location statistics can be an empty object {} - that should only happen on staging after DB restore
        statisticsRequestState: RequestState;
        statisticsLatestQuery: Record<string, string | number>;
        recommended: ILocation[];
        recommendedRequestState: RequestState;
        recommendedLatestQuery: string[];
    };
    markers: {
        markers: IListingMarker[] | null;
        latestQuery: Record<string, string | number>;
        markersRequestState: RequestState;
    };
    investment: {
        investment: IOfferInvestment | null;
        requestState: RequestState;
    };
    latestQuery: IOfferListQuery;
    offerModalState: boolean;
    seoDescription: string;
    seoDescriptionLatestQuery: string;
}

const offerListReducerObj: Record<keyof IOfferListStore, Reducer<any, any>> = {
    offers: combineReducers({
        meta: reduceResponseField(fetchOfferListTypes, "meta"),
        collection_count: reduceResponseField(fetchOfferListTypes, "collectionCount", 0),
        offers: reduceResponseField(fetchOfferListTypes, "data", []),
        requestState: reduceRequestState(fetchOfferListTypes),
        pageCount: reduceResponseField(fetchOfferListTypes, "pageCount", 0),
        page: reduceResponseField(fetchOfferListTypes, "page", 0)
    }),
    location: combineReducers({
        location: reduceResponse(fetchLocationBySlugTypes),
        locationRequestState: reduceRequestState(fetchLocationBySlugTypes),
        statistics: reduceResponse(fetchLocationStatisticsTypes),
        statisticsRequestState: reduceRequestState(fetchLocationStatisticsTypes),
        statisticsLatestQuery: reduceLatestQuery(fetchLocationStatisticsTypes),
        recommended: reduceResponse(fetchRecommendedLocationsTypes),
        recommendedRequestState: reduceRequestState(fetchRecommendedLocationsTypes),
        recommendedLatestQuery: reduceLatestQuery(fetchRecommendedLocationsTypes)
    }),
    markers: combineReducers({
        markers: reduceResponseField(fetchOfferListMarkersTypes, "result", null),
        latestQuery: reduceResponseField(fetchOfferListMarkersTypes, "latestQuery"),
        markersRequestState: reduceRequestState(fetchOfferListMarkersTypes)
    }),
    // TODO: do we even need this investment logic at this point? We don't link to investment listings in search anymore
    investment: combineReducers({
        investment: reduceResponse(fetchOfferListInvestmentTypes),
        requestState: reduceRequestState(fetchOfferListInvestmentTypes)
    }),
    latestQuery: reduceLatestQuery(fetchOfferListTypes),
    offerModalState: offerModalStateReducer,
    seoDescription: reduceResponse(fetchOfferListSeoDescriptionTypes),
    seoDescriptionLatestQuery: reduceLatestQuery(fetchOfferListSeoDescriptionTypes)
};

export const offerListReducer = combineReducers(offerListReducerObj);
