import * as React from "react";
import {BounceLoader} from "@web2/ui_utils";

import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {SearchTab} from "../../actions/fetch_search_all_action";
import {AutocompletePrediction} from "../../actions/fetch_search_places_action";
import {IActiveDropdownItemStore} from "../../reducers/active_dropdown_item_reducer";
import {ISearchInputValue} from "../../utils/ISearchInputValue";
import {loadGoogleMapsApi} from "../../utils/load_google_maps_api";
import {resultsList, searchLoaderHolder} from "../atoms/atoms";
import {ResultsInfo} from "../atoms/ResultsInfo";
import {ResultsListItem} from "../atoms/ResultsListItem";
import {googlePlacesDivId} from "../Search";

interface IOwnProps {
    fetchPlacesRequest: RequestState;
    places: AutocompletePrediction[];
    activeItem: IActiveDropdownItemStore;
    onLinkClick: (option: ISearchInputValue) => void;
}

interface IProps extends IOwnProps {}

export const fetchGooglePlace = async (place: AutocompletePrediction, callback: (option: ISearchInputValue) => void) => {
    await loadGoogleMapsApi(["places"]);

    const {place_id: placeId} = place;
    const _googlePlacesTabResults = document.getElementById(googlePlacesDivId);
    const service = new google.maps.places.PlacesService(_googlePlacesTabResults as HTMLDivElement);

    service.getDetails({placeId}, (placeDetail) => {
        const placeLocation = placeDetail?.geometry?.location;

        if (placeLocation) {
            const lat = placeLocation.lat();
            const lng = placeLocation.lng();
            callback({
                label: place.description,
                tabType: SearchTab.Places,
                place: place,
                location: {lat, lng}
            });
        }
        callback({label: "", tabType: null});
    });
};

export const PlacesTab = (props: IProps) => {
    const {fetchPlacesRequest, places} = props;
    if (fetchPlacesRequest === RequestState.Success && places.length === 0) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }

    if (fetchPlacesRequest === RequestState.Success || fetchPlacesRequest === RequestState.Waiting) {
        if (places.length === 0) {
            return (
                <div className={searchLoaderHolder}>
                    <BounceLoader color="#9069c0" />
                </div>
            );
        }

        return (
            <ul className={resultsList}>
                {props.places.map((place, idx) => (
                    <ResultsListItem
                        key={idx}
                        idx={idx}
                        activeItemId={props.activeItem.id}
                        title={place.description}
                        onClick={() => fetchGooglePlace(place, props.onLinkClick)}
                    />
                ))}
            </ul>
        );
    }
    return null;
};
