import {Request} from "express";

import {IABTestConfiguration} from "../interfaces/IABTestConfiguration";
import {IAppendServerRequestHeadersMethod, specifyABTestVariant} from "./ab_testing_ssr";

export const getABTestData = (
    abTest: IABTestConfiguration,
    domain: string,
    req: Request,
    appendServerRequestHeadersMethod?: IAppendServerRequestHeadersMethod
): string => {
    const variant = specifyABTestVariant(abTest, domain, req, appendServerRequestHeadersMethod);
    return `${abTest.id}.${variant}`;
};
