import {RefObject, useEffect} from "react";

import {IGoogleMapApi} from "../../offer/list/components/map/common/GoogleMapOfferList";
import {gtmOfferListEvents} from "../../tracking/google_tag_manager/gtm_offer_list_events";

/**
 * Hook binds hovering over an offerbox to map marker click and pan
 * @param mapRef: ref to GoogleMap component
 * @param panFunction: custom pan function
 * @param hoveredOfferId: string, hovered offer.id
 * @param markerEventToTrigger: configure which event type to trigger (event docs: https://developers.google.com/maps/documentation/javascript/reference/marker#Marker-Events)
 */
export const useOfferBoxHoverListingAction = (
    mapRef: RefObject<IGoogleMapApi>,
    panFunction: (map: google.maps.Map, position?: google.maps.LatLng | null) => void,
    hoveredOfferId?: string | null,
    markerEventToTrigger?: "offerboxHover" | "click"
) => {
    // Bind offerHover with maps markers.
    useEffect(() => {
        const map = mapRef.current && mapRef.current.getMapInstance();
        if (map && mapRef.current) {
            if (hoveredOfferId) {
                const marker = mapRef.current.getMarkerRefById(hoveredOfferId);
                if (marker) {
                    // trigger marker event
                    mapRef.current.triggerMapEvent(marker, markerEventToTrigger || "click");
                    gtmOfferListEvents.offerBox.hover();

                    // pan to marker
                    const markerPosition = marker.getPosition();
                    panFunction(map, markerPosition);
                }
            } else {
                mapRef.current.closeInfoWindows();
            }
        }
    }, [hoveredOfferId]);
};
