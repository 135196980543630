import {getViewTypeName, ViewType} from "../view_type/view_type";
import {IViewTypeMetaData} from "../view_type/view_type_actions";
import {hitGoogleTagManager} from "./gtm_init";

/**
 * Public methods
 */

export const gtmPageView = (viewType: ViewType | null, currentData: Partial<IViewTypeMetaData>) => gtmCommonPageView(viewType, currentData);
export const gtmVirtualPageView = (viewType: ViewType | null, currentData: Partial<IViewTypeMetaData>) => gtmCommonPageView(viewType, currentData, true);

/**
 * Private methods
 */

interface IGtmPageView {
    event?: "vpv";
    pageType: string;
    searchParams: string;
    contentRealtor: string;
    contentRegion: string;
    contentMarket: string;
    contentPropertyType: string;
    regionSlug: string;
}

const gtmCommonPageView = (viewType: ViewType | null, currentData: Partial<IViewTypeMetaData>, virtualPageView = false) => {
    const gtmData: IGtmPageView = {
        pageType: viewType != null ? getViewTypeName(viewType) : "na",
        searchParams: currentData.searchParams ?? "na",
        contentRealtor: currentData.contentRealtor ?? "na",
        contentRegion: currentData.contentRegion ?? "na",
        contentMarket: currentData.contentMarket ?? "na",
        contentPropertyType: currentData.contentPropertyType ?? "na",
        regionSlug: currentData.regionSlug ?? ""
    };
    if (virtualPageView) {
        gtmData.event = "vpv";
    }
    hitGoogleTagManager(gtmData);
};
