import {each, isFinite, map, reduce} from "lodash";
import {Dispatch} from "redux";

import {PoiType} from "../constants/PoiType";
import {consoleError} from "../utils/logger";
import {createRequestActionTypes, RequestActionTypes} from "../utils/request_response_utils/factories/create_request_action_types";
import {getExternalRequest} from "../utils/request_response_utils/request";
import {catch5xx, catch504, catchTimeout, catchUnknownError} from "../utils/request_response_utils/response_error";

interface IOverpassAround {
    radius: number;
    latitude: number;
    longitude: number;
}

interface IOverpassTags {
    [key: string]: any;
}

interface IOverpassNode {
    id: number;
    lat: number;
    lon: number;
    tags?: IOverpassTags;
    type: "node";
}

interface IOverpassWay {
    id: number;
    nodes: number[];
    tags?: IOverpassTags;
    type: "way";
}

interface IOverpassRelation {
    id: number;
    members: {
        type: string;
        ref: number;
        role: string;
    }[];
    tags?: IOverpassTags;
    type: "relation";
}

type IOverpassElement = IOverpassNode | IOverpassWay | IOverpassRelation;

export interface IOverpassPlace {
    id: number;
    lat: number;
    lng: number;
    name: string;
    tags: IOverpassTags;
    type: "node" | "way" | "relation";
}

interface IOverpassResponse {
    elements: IOverpassElement[];
}

export const fetchOverpassMapPoiTypes = createRequestActionTypes({type: "GET", name: "OverpassPOI"});
export const fetchOverpassMapPoi = createFetchOverpassMapPoi(fetchOverpassMapPoiTypes);

export const fetchInvestmentDetailOverpassPoiTypes = createRequestActionTypes({
    type: "GET",
    view: "Investment",
    name: "OverpassPOI"
});
export const fetchInvestmentDetailOverpassPoi = createFetchOverpassMapPoi(fetchInvestmentDetailOverpassPoiTypes);

function createFetchOverpassMapPoi(requestType: RequestActionTypes) {
    return (poiType: PoiType, around: IOverpassAround) =>
        async (dispatch: Dispatch): Promise<IOverpassPlace[] | true> => {
            dispatch({type: requestType.start});
            const nodes = poiType === PoiType.ALL ? getAllNodes() : getNodes(poiType);
            const data = `
[out:json][timeout:25];
(
${map(nodes, (node) => `node[${node.value}](around:${around.radius},${around.latitude},${around.longitude});`).join("\n")}
${map(nodes, (node) => `way[${node.value}](around:${around.radius},${around.latitude},${around.longitude});`).join("\n")}
${map(nodes, (node) => `relation[${node.value}](around:${around.radius},${around.latitude},${around.longitude});`).join("\n")}
);
out body;
>;
out skel qt;
    `;
            const url = `https://ovp.rynekpierwotny.pl/api/interpreter`;
            return getExternalRequest({}, `${url}?data=${data}`, 5000)
                .then((result: IOverpassResponse) => {
                    const nodeMap = reduce<IOverpassElement, Record<string, IOverpassNode>>(
                        result.elements,
                        (acc, elem) => (elem.type === "node" ? {...acc, [elem.id]: elem} : acc),
                        {}
                    );
                    const wayMap = reduce<IOverpassElement, Record<string, IOverpassWay>>(
                        result.elements,
                        (acc, elem) => (elem.type === "way" ? {...acc, [elem.id]: elem} : acc),
                        {}
                    );
                    const elements = reduce(
                        result.elements,
                        (acc: IOverpassPlace[], element: IOverpassElement): IOverpassPlace[] => {
                            // valid element should contain tags
                            const elementTags = element.tags;
                            if (elementTags == null) {
                                return acc;
                            }
                            // valid element should define name or we display default name
                            const elementName = elementTags.name || getDefaultNameByTags(nodes, elementTags);
                            if (elementName == null) {
                                return acc;
                            }
                            // consider element type and act accordingly
                            if (element.type === "node") {
                                return [
                                    ...acc,
                                    {
                                        name: elementName,
                                        id: element.id,
                                        tags: elementTags,
                                        type: element.type,
                                        lat: element.lat,
                                        lng: element.lon
                                    }
                                ];
                            }
                            if (element.type === "way") {
                                const [lat, lng] = getCenterLatLng(nodeMap, element.nodes);
                                if (lat === 0 && lng === 0) {
                                    return acc;
                                }
                                return [
                                    ...acc,
                                    {
                                        name: elementName,
                                        id: element.id,
                                        tags: elementTags,
                                        type: element.type,
                                        lat,
                                        lng
                                    }
                                ];
                            }
                            if (element.type === "relation") {
                                let sumLat = 0;
                                let sumLng = 0;
                                let count = 0;
                                each(element.members, (member) => {
                                    if (member.type !== "way") {
                                        return;
                                    }
                                    if (member.role === "inner") {
                                        return;
                                    }
                                    const way = wayMap[member.ref];
                                    if (way == null) {
                                        return;
                                    }
                                    const [lat, lng] = getCenterLatLng(nodeMap, way.nodes);
                                    if (lat === 0 && lng === 0) {
                                        return;
                                    }
                                    sumLat += lat;
                                    sumLng += lng;
                                    ++count;
                                });
                                if (count === 0) {
                                    return acc;
                                }
                                return [
                                    ...acc,
                                    {
                                        name: elementName,
                                        id: element.id,
                                        tags: elementTags,
                                        type: element.type,
                                        lat: sumLat / count,
                                        lng: sumLng / count
                                    }
                                ];
                            }
                            return acc; // type not recognized
                        },
                        [] as IOverpassPlace[]
                    );
                    // dispatch calculated places
                    dispatch({type: requestType.success, result: {elements}});
                    return elements;
                })
                .catch(catchTimeout((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catch504((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catch5xx((err) => handleOverpassFetchError(dispatch, err, requestType.success)))
                .catch(catchUnknownError((err) => handleOverpassFetchError(dispatch, err, requestType.success)));
        };
}

const handleOverpassFetchError = (dispatch: Dispatch, err: {name: string}, successRequestType: string): true => {
    consoleError("createFetchOverpassPoi error: ", err);
    dispatch({type: successRequestType, result: {elements: []}});
    return true;
};

export const resetOverpassMapPoi = () => ({type: fetchOverpassMapPoiTypes.reset});

/**
 * Helper
 */

function getCenterLatLng(nodeMap: Record<string, IOverpassNode>, nodes: number[]): [number, number] {
    let sumLat = 0;
    let sumLng = 0;
    let count = 0;
    each(nodes, (nodeId) => {
        const node = nodeMap[nodeId] as IOverpassNode;
        if (node && isFinite(node.lat) && isFinite(node.lon)) {
            sumLat += node.lat;
            sumLng += node.lon;
            ++count;
        }
    });
    return [sumLat / count, sumLng / count];
}

export const realPoiTypes = [PoiType.EDUCATION, PoiType.TRANSPORT, PoiType.FOOD, PoiType.SPORT, PoiType.ENTERTAINMENT, PoiType.HEALTH, PoiType.SHOPS] as const;

function getNodes(poiType: PoiType): {value: string; label: string}[] {
    switch (poiType) {
        case PoiType.EDUCATION:
            return [
                {value: "amenity=kindergarten", label: "Żłobek"},
                {value: "amenity=school", label: "Szkoła"},
                {value: "amenity=college", label: "Szkoła wyższa"},
                {value: "amenity=university", label: "Uniwersytet"}
            ];
        case PoiType.TRANSPORT:
            return [
                {value: "highway=bus_stop", label: "Przystanek autobusowy"},
                {value: "amenity=bus_station", label: "Przystanek autobusowy"},
                {value: "public_transport=station", label: "Przystanek autobusowy"},
                {value: "railway=tram_stop", label: "Przystanek tramwajowy"},
                {value: "station=subway", label: "Stacja metra"},
                {value: "railway=station", label: "Stacja kolejowa"}
            ];
        case PoiType.FOOD:
            return [
                {value: "amenity=restaurant", label: "Restauracja"},
                {value: "amenity=cafe", label: "Kawiarnia"}
            ];
        case PoiType.SPORT:
            return [
                {value: "leisure=fitness_centre", label: "Fitness"},
                {value: "leisure=fitness_station", label: "Siłownia plenerowa"},
                {value: "leisure=pitch", label: "Boisko"},
                {value: "leisure=swimming_pool", label: "Basen"},
                {value: "leisure=horse_riding", label: "Stadnina koni"},
                {value: "sport=tennis", label: "Korty tenisowe"},
                {value: "leisure=sports_centre", label: "Centrum sportowe"}
            ];
        case PoiType.ENTERTAINMENT:
            return [
                {value: "leisure=playground", label: "Plac zabaw"}
                // {value: "amenity=cinema",           label: "Kino"},
                // {value: "amenity=theatre",          label: "Teatr"},
                // {value: "leisure=ice_rink",         label: "Lodowisko"}
            ];
        case PoiType.HEALTH:
            return [
                {value: "amenity=hospital", label: "Szpital"},
                {value: "amenity=clinic", label: "Klinika/Przychodnia"},
                {value: "amenity=doctors", label: "Lekarz"}
            ];
        case PoiType.SHOPS:
            return [
                {value: "shop=mall", label: "Galeria handlowa"},
                {value: "shop=department_store", label: "Dom towarowy"},
                {value: "shop=convenience", label: "Sklep osiedlowy"},
                {value: "shop=deli", label: "Delikatesy"},
                {value: "shop=supermarket", label: "Supermarket"},
                {value: "amenity=marketplace", label: "Bazarek"},
                {value: "shop=greengrocer", label: "Sklep z warzywami"},
                {value: "shop=bakery", label: "Piekarnia"},
                {value: "shop=health_food", label: "Zdrowa żywność"}
            ];
        default:
            return [];
    }
}

function getAllNodes(): {value: string; label: string}[] {
    return reduce(realPoiTypes, (acc: any, poiType) => [...acc, ...getNodes(poiType)], []);
}

// returns default name based on nodes (defined by poiType) and tags for given element
function getDefaultNameByTags(nodes: {value: string; label: string}[], tags: IOverpassTags): string | null {
    for (let i = 0; i < nodes.length; ++i) {
        const {value, label} = nodes[i];
        const [key, val] = value.split("=");
        if (key && val && tags[key] === val) {
            return label;
        }
    }
    return null;
}

// returns default poi-type based on all nodes and tags for given element
export function getPoiTypeByTags(tags: IOverpassTags): PoiType | null {
    for (const poiType of realPoiTypes) {
        const nodes = getNodes(poiType);
        for (let i = 0; i < nodes.length; ++i) {
            const {value} = nodes[i];
            const [key, val] = value.split("=");
            if (key && val && tags[key] === val) {
                return poiType;
            }
        }
    }
    return null;
}

export function getDetailedPoiTypeByTags(tags: IOverpassTags): {type: PoiType; subType: string} | null {
    for (const poiType of realPoiTypes) {
        const nodes = getNodes(poiType);
        for (let i = 0; i < nodes.length; ++i) {
            const {value, label} = nodes[i];
            const [key, val] = value.split("=");
            if (key && val && tags[key] === val) {
                return {
                    type: poiType,
                    subType: label
                };
            }
        }
    }
    return null;
}
