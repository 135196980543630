import {combineReducers} from "redux";

import {fetchOverpassMapPoiTypes, IOverpassPlace} from "../actions/fetch_overpass_map_poi";
import {reduceRequestState, RequestState} from "../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponseField} from "../utils/request_response_utils/factories/reduce_response";

export interface IOverpassMapPoiStore {
    elements: IOverpassPlace[];
    elementsRequest: RequestState;
}

export const overpassMapPoiReducer = combineReducers({
    elements: reduceResponseField(fetchOverpassMapPoiTypes, "elements", []),
    elementsRequest: reduceRequestState(fetchOverpassMapPoiTypes)
});
