import {combineReducers} from "redux";

import {reduceRequestState, RequestState} from "../../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../../utils/request_response_utils/factories/reduce_response";
import {fetchIsAuthenticatedTypes} from "../actions/is_authenticated_actions";

export interface IsAuthenticatedStore {
    response: boolean;
    requestState: RequestState;
}

export const isAuthenticatedReducer = combineReducers({
    response: reduceResponse<boolean>(fetchIsAuthenticatedTypes, false),
    requestState: reduceRequestState(fetchIsAuthenticatedTypes)
});
