import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import {ApplicationFormFields, Checkbox} from "@web2/form";
import {FormikForm, handleFormikPost, IFormikSubmitFn} from "@web2/formik_utils";
import {themeGh} from "@web2/global_styles";
import {AtEmailIcon, CloseButtonIcon, PersonFillIcon, PhoneFillIcon} from "@web2/icons";
import {useMounted} from "@web2/react_utils";
import {useUserDevice} from "@web2/user-device";

import {applicationV2Post} from "../../application/actions/application_v2_post";
import {ISimilarOffersFetch} from "../../application/actions/fetch_multilead_offers";
import {emptyApplicationValues, UserData} from "../../application/components/Application";
import {IApplicationModalAgencyOffers} from "../../application/components/ApplicationModalAgencyOffers";
import {MultileadCTA} from "../../application/components/MultileadCTA";
import {ApplicationSource} from "../../application/utils/ApplicationSource";
import {TrackApplicationPayload, trackApplicationPost} from "../../application/utils/track_application";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {MultiOfferBox} from "../../offer/detail/components/offer_box/MultiOfferBox";
import {OFFERBOX_MAX_MOBILE_BREAKPOINT} from "../../offer/detail/components/offer_box/OfferBox";
import {pb_sm} from "../../styles/helpers";
import {
    gtmModalMultileadClose,
    gtmMultilead,
    gtmMultileadCheckbox,
    gtmMultileadQuestionChange,
    gtmMultileadSend
} from "../../tracking/google_tag_manager/gtm_multilead";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {setFavouriteOffer} from "../actions/load_local_storage_favourites_to_store";
import {Paragraph} from "../atoms/Paragraph";
import {IStore} from "../reducers/hybrid_reducer";
import {toJsonValues} from "../utils/form/to_json_values";
import {osmMapEnable} from "../utils/read_environment_variables";
import {MultileadConfirmation} from "./MultileadConfirmation";
import {MultileadOsmMap} from "./MultileadOsmMap";

const LazyMultileadMap = React.lazy(() => import(/* webpackChunkName: "lazy_multilead_google_map" */ "./MultileadMap"));

interface IProps {
    closeModal: () => void;
    offerId: string | undefined;
    similarOffers: ISimilarOffersFetch[];
    applicationUserData?: UserData;
    applicationTrackingPayload?: TrackApplicationPayload;
    inactiveOffer?: boolean;
    viewType: ViewType | null;
    type: VendorType | undefined;
    offer: IApplicationModalAgencyOffers;
    onApplicationSuccess: () => void;
}

export const ModalMultilead = (props: IProps) => {
    const {isMobile} = useUserDevice();
    const favouriteOffers = useSelector((store: IStore) => store.favourites.favourites);
    const {inactiveOffer, similarOffers} = props;
    const [activeOffers, setActiveOffers] = useState(similarOffers.slice(0, 3));
    const [isApplicationSent, setIsApplicationSent] = useState(false);
    const theme = useTheme();
    const isMounted = useMounted();

    useEffect(() => {
        gtmMultilead(props.offer.market_type, props.offer.deal_type);
    }, []);

    const renderSimilarOffers = useCallback(
        (off: ISimilarOffersFetch, index: number) => {
            const [checkboxValue, setCheckboxValue] = useState(index < 3);
            const onOfferBoxClick = (off: ISimilarOffersFetch) => {
                checkBoxHandler();
                gtmMultileadCheckbox(!checkboxValue, off.market_type);

                if (!checkboxValue) {
                    setActiveOffers([...activeOffers, off]);
                    return;
                }
                setActiveOffers(activeOffers.filter((offer) => offer.id !== off.id));
            };
            const checkBoxHandler = () => {
                setCheckboxValue(!checkboxValue);
            };

            return (
                <>
                    <div css={offerBoxWrapper} key={off.id} onClick={(e) => onOfferBoxClick(off)}>
                        <MultiOfferBox isInvestment={!!off.investment} offer={off} css={panelOfferBox} />
                    </div>
                    <div css={multileadCheckboxContainer} onClick={() => onOfferBoxClick(off)}>
                        <Checkbox
                            css={checkboxStyle}
                            value={checkboxValue}
                            name={off.id}
                            onAfterChange={() => null}
                            onChange={() => {
                                onOfferBoxClick(off);
                                return checkBoxHandler;
                            }}
                        />
                    </div>
                </>
            );
        },
        [favouriteOffers, setFavouriteOffer, activeOffers]
    );

    const SimilarOffersList = props.similarOffers.map((offer: ISimilarOffersFetch, index) => {
        return <li key={offer.id}>{renderSimilarOffers(offer, index)}</li>;
    });

    // TODO: change textForOffers to dynamic text based on the offer type from multilead
    const textForOffers = "Zainteresowała mnie nieruchomość w Państwa inwestycji. Proszę o niezobowiązujący kontakt w sprawie szczegółów.";
    const inactiveOfferHeading = "Niestety, ta oferta jest już nieaktualna";
    const hasSimilarOffers = similarOffers.length > 0;
    const [multileadOffersView, setMultileadOffersView] = useState(true);

    const onModalCloseClick = () => {
        if (!multileadOffersView) {
            setMultileadOffersView(true);
            return;
        }
        props.closeModal();
        gtmModalMultileadClose();
    };

    const toggleMultileadView = () => {
        setMultileadOffersView(!multileadOffersView);
        gtmMultileadQuestionChange();
    };

    const applicationSubmit: IFormikSubmitFn<typeof emptyApplicationValues> = (formValues, formikHelpers) => {
        const jsonValues = toJsonValues(ApplicationFormFields, formValues);
        gtmMultileadSend(activeOffers.length);
        activeOffers.forEach((off) => {
            const applicationData = {
                ...(off && {
                    offer: off.id,
                    ...(off.agent && {agent: off.agent.id}),
                    ...(off.investment && {investment: off.investment.id}),
                    ...(off.agency && {agency: off.agency.id})
                }),
                ...(off.investment && {
                    agency: off.investment.developer?.id,
                    investment: off.investment.id
                }),
                source: ApplicationSource.MULTILEAD,
                source_section: props.applicationUserData?.source_section
            };

            handleFormikPost(applicationV2Post({...jsonValues, ...applicationData}), formikHelpers, {
                onSuccess: () => {
                    if (props.applicationTrackingPayload) {
                        trackApplicationPost({
                            ...props.applicationTrackingPayload,
                            offer: off,
                            source: ApplicationSource.MULTILEAD,
                            gtmInquiryData: {
                                ...props.applicationTrackingPayload.gtmInquiryData,
                                gtmSource: GtmSource.MULTILEAD
                            }
                        });
                    }
                    setIsApplicationSent(true);
                    props.onApplicationSuccess();
                }
            });
        });
    };

    return (
        <>
            {!isApplicationSent ? (
                <FormikForm
                    onSubmit={applicationSubmit}
                    onChange={() => null}
                    initialValues={{
                        email: props.applicationUserData?.email || "",
                        full_name: props.applicationUserData?.full_name || "",
                        phone: props.applicationUserData?.phone || "",
                        text: props.similarOffers.length ? textForOffers : props.applicationUserData?.text || "",
                        new_offers_consent: props.applicationUserData?.new_offers_consent || false,
                        financing_services: props.applicationUserData?.financing_services || false
                    }}
                    enableReinitialize
                >
                    <div css={modalContent(themeGh, hasSimilarOffers)}>
                        <div css={closeButtonWrapper} onClick={onModalCloseClick}>
                            <CloseButtonIcon mainColor={theme.colors.gray} />
                        </div>
                        <Paragraph css={title}>{!inactiveOffer ? "Wybierz i zapytaj o podobne oferty w najbliższej okolicy." : inactiveOfferHeading}</Paragraph>
                        {multileadOffersView ? (
                            <Paragraph css={subtitle}>
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMultileadView();
                                    }}
                                >
                                    {!inactiveOffer ? "Zmień dane i treść zapytania" : ""}
                                </a>
                            </Paragraph>
                        ) : null}
                        <div css={offersListContainer}>
                            {multileadOffersView ? (
                                <ul css={offersList}>{SimilarOffersList}</ul>
                            ) : (
                                props.offer && (
                                    <div css={ApplicationContainer}>
                                        <FormikForm.Textarea name="text" rows={4} errorOnBottom />

                                        <FormikForm.Input
                                            name="full_name"
                                            icon={<PersonFillIcon size="1.8" fillColor="#999" />}
                                            placeholder="Imię i nazwisko"
                                            errorOnBottom
                                        />

                                        <FormikForm.Input name="email" icon={<AtEmailIcon size="1.8" fillColor="#999" />} placeholder="Email" errorOnBottom />

                                        <div css={[pb_sm]}>
                                            <FormikForm.Input
                                                name="phone"
                                                icon={<PhoneFillIcon size="1.8" fillColor="#999" />}
                                                placeholder="Telefon"
                                                errorOnBottom
                                            />
                                        </div>
                                    </div>
                                )
                            )}

                            {!isMobile && (
                                <div css={[multileadMap, !multileadOffersView ? hideView : null]}>
                                    {isMounted && (
                                        <>
                                            {!osmMapEnable ? (
                                                <LazyMultileadMap
                                                    offer={props.offer}
                                                    markers={props.similarOffers}
                                                    activeOffers={activeOffers}
                                                    type={props.type ? props.type : VendorType.DEVELOPER}
                                                    viewType={props.viewType}
                                                />
                                            ) : (
                                                <MultileadOsmMap
                                                    offer={props.offer}
                                                    markers={props.similarOffers}
                                                    activeOffers={activeOffers}
                                                    type={props.type ? props.type : VendorType.DEVELOPER}
                                                    viewType={props.viewType}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <MultileadCTA offersLength={activeOffers.length} multileadOffersView={multileadOffersView} toggleMultileadView={toggleMultileadView} />
                    </div>
                </FormikForm>
            ) : (
                <div css={modalContent(themeGh, multileadOffersView)}>
                    <div css={closeButtonWrapper} onClick={onModalCloseClick}>
                        <CloseButtonIcon mainColor={theme.colors.gray} />
                    </div>
                    <MultileadConfirmation multileadOffersView={multileadOffersView} activeOffers={activeOffers} offer={props.offer} />
                </div>
            )}
        </>
    );
};

const multileadMap = (theme: Theme) => css`
    order: 1;
    display: flex;
    flex: 1;
    min-height: 34rem;
    margin-bottom: 2rem;
    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        order: 2;
        margin-bottom: 0;
    }
`;
const hideView = css`
    display: none;
`;
const offersListContainer = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0 3rem 0;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        flex-direction: row;
    }
`;

const offersList = (theme: Theme) => css`
    flex: 2;
    order: 2;
    min-height: 310px;
    display: flex;
    width: 100%;
    max-width: 59.4rem;
    align-items: start;
    flex-direction: column;
    margin: 0;
    padding: 0;

    & > li {
        list-style: none;
        position: relative;
        width: auto;
        margin: 0 auto 3.6rem auto;
        @media (min-width: ${theme.breakpoints_correct.screen_sm}) {
            width: calc(100% - 35px);
            margin: 0 0 1.6rem 0;
        }
    }

    & li & > div {
        margin-bottom: 1.5rem;
    }

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        margin-right: 2rem;
        order: 1;
        width: auto;
        max-height: 56rem;
        overflow-x: auto;
    }
`;

const ApplicationContainer = (theme: Theme) => css`
    flex: 2;
    order: 2;
    min-height: 310px;
    display: flex;
    width: 100%;
    align-items: start;
    flex-direction: column;
    margin-right: 2rem;
    padding: 0;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        order: 1;
        width: auto;
        max-height: 56rem;
        overflow-x: auto;
        display: block;
    }

    & > * {
        width: 100%;
    }
`;

const modalContent = (theme: Theme, hasSimilarOffers: boolean) => css`
    width: auto;
    height: auto;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        min-width: ${!hasSimilarOffers ? 60 : 98.6}rem;
        max-height: 80rem;
    }
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
`;
const title = (theme: Theme) => css`
    color: ${theme.colors.brand_primary};
    font-size: 2.3rem;
    font-weight: 400;
    line-height: 3.2rem;
    text-align: left;
    margin: 1rem 0 0 0;
`;
const subtitle = (theme: Theme) => css`
    font-size: ${theme.fonts.font_size_base};
    line-height: 2.1rem;
    font-weight: 400;
    text-align: left;
`;

const panelOfferBox = (theme: Theme) => css`
    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        border-radius: ${theme.other.border_radius};
        border-width: 1px;
    }
`;
const offerBoxWrapper = css`
    display: flex;
    justify-content: start;
    width: 100%;
`;
const multileadCheckboxContainer = css`
    order: 1;
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(249, 249, 249);
    position: absolute;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    box-shadow: rgb(0 0 0 / 19%) 0 1rem 2rem;
    @media (min-width: 768px) {
        right: -2rem;
        top: 50%;
        transform: translateY(-50%);
        bottom: unset;
        left: unset;
    }
    &:hover {
        cursor: pointer;
    }
`;
const checkboxStyle = css`
    & > div > div {
        margin: 0;
    }
`;

const closeButtonWrapper = (theme: Theme) => css`
    display: block;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    top: unset;
    right: unset;

    > svg {
        margin-top: 0.5rem;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        margin-left: 2.5rem;
        position: absolute;
        top: 2.2rem;
        right: 2.7rem;
    }
`;
