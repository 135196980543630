import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateFavouriteMetaData = (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => (dispatch: Dispatch) => {
    const url = `${ghCommonUrl}${appLink.favourites()}`;
    const title = `Polubione oferty`;
    const description = `Polubione oferty mieszkań i domów na sprzedaż oraz na wynajem. Bezpośrednio od deweloperów i biur nieruchomości`;
    const robots = "noindex";

    dispatch(updateMetaData(title, description, route, {canonical: url, url, robots, hideSuffix: false}));

    return Promise.resolve(true);
};
