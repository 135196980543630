import {FC, ReactNode, useEffect, useRef, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {isEqual, pick} from "lodash";
import * as Yup from "yup";
import {Button, IButtonProps} from "@web2/button";
import {FormikForm, IFormikSubmitFn} from "@web2/formik_utils";
import {appLink} from "@web2/gh_routes";
import {AtEmailIcon} from "@web2/icons";
import {Modal} from "@web2/modal2";
import {usePrevious} from "@web2/react_utils";

import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {di, mb_md, mb_xl} from "../../styles/helpers";
import {gtmNotificationConsentSubmit, gtmNotificationsBtnClick} from "../../tracking/google_tag_manager/gtm_user_com_notification_hits";
import {errorMessages} from "../utils/form_error_messages";
import {AcceptTermsLabel} from "./AcceptTerms";
import {ModalFormSuccessMessage} from "./ModalFormSuccessMessage";

interface IProps extends Omit<IButtonProps, "href" | "target" | "title"> {
    children?: ReactNode;
    latestQuery: IOfferListQuery;
    isSubmitted: boolean;
    setIsSubmitted: (isSubmitted: boolean) => void;
}

const mapImage = require("../../../assets/location_notifications_subscribe.svg");

const emptyInitialValues = {
    email: ""
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email(errorMessages.incorrectEmail).required(errorMessages.required)
});

export const NotificationSubscriptionButton: FC<IProps> = (props) => {
    const {latestQuery, isSubmitted, setIsSubmitted} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalClosingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // weird typing because TS incorrectly assumes this is a NodeJS type

    const theme = useTheme();

    const onButtonClick = () => {
        gtmNotificationsBtnClick();
        setIsModalOpen(true);
    };

    const onSubmit: IFormikSubmitFn<typeof emptyInitialValues> = (formValues) => {
        gtmNotificationConsentSubmit(formValues);
        setIsSubmitted(true);

        modalClosingTimeoutRef.current = setTimeout(() => {
            setIsModalOpen(false);
        }, 2500);
    };

    // clear timeout when user closes success modal
    useEffect(() => {
        return () => {
            if (isSubmitted && !isModalOpen) {
                modalClosingTimeoutRef.current && clearTimeout(modalClosingTimeoutRef.current);
            }
        };
    }, [isModalOpen]);

    // reset modal on some query changes to allow new subscription
    const previousQuery = usePrevious(latestQuery, latestQuery);
    useEffect(() => {
        const queryValuesToPick = ["search_name", "offer_type", "deal_type", "location"];
        const isPickedQueryEqual = isEqual(pick(latestQuery, queryValuesToPick), pick(previousQuery, queryValuesToPick));
        if (isSubmitted && !isPickedQueryEqual) setIsSubmitted(false);
    }, [latestQuery]);

    return (
        <>
            <Button {...props} onClick={onButtonClick} disabled={isSubmitted}>
                {props.children}
            </Button>

            <StyledModal
                modalState={isModalOpen}
                onModalClose={() => setIsModalOpen(false)}
                type="window"
                closeButton={!isSubmitted}
                isSubmitted={isSubmitted}
                contentStyles={{maxHeight: "100%", overflow: "auto"}}
            >
                <ModalContentHolder isSubmitted={isSubmitted}>
                    {isSubmitted ? (
                        <ModalFormSuccessMessage>Udało Ci się zapisać na powiadomienia! &#128578;</ModalFormSuccessMessage>
                    ) : (
                        <>
                            <div css={modalHeading}>Nie przegap okazji!</div>
                            <div css={modalSubHeading}>Powiadomimy Cię o interesujących ofertach w tej lokalizacji.</div>
                            <img css={imageStyle} src={mapImage} width="168px" height="168px" />

                            <div css={formHolder}>
                                <FormikForm onSubmit={onSubmit} initialValues={emptyInitialValues} validationSchema={validationSchema}>
                                    <FormikForm.Input
                                        placeholder="email"
                                        name="email"
                                        type="email"
                                        icon={<AtEmailIcon fillColor={theme.colors.gray_lighter} css={iconStyle} size="2" />}
                                    />
                                    <FormikForm.Button type="submit" variant="success" fullWidth css={mb_md}>
                                        Zapisz
                                    </FormikForm.Button>
                                    <div css={administrativeCopy}>
                                        Administratorem danych jest Property Group Sp.z o.o.{" "}
                                        <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                                            więcej o&nbsp;ochronie danych.
                                        </a>{" "}
                                        <AcceptTermsLabel css={[mb_xl, di]} />
                                    </div>
                                </FormikForm>
                            </div>
                        </>
                    )}
                </ModalContentHolder>
            </StyledModal>
        </>
    );
};

const StyledModal = styled(Modal)<{isSubmitted: boolean}>`
    transition: all 0.5s;

    ${(props) =>
        props.isSubmitted &&
        css`
            border-radius: ${props.theme.other.border_radius};
            border: 1px solid ${props.theme.colors.gray_very_dark};
            margin: 0 1rem;
        `}
`;

const ModalContentHolder = styled.div<{isSubmitted: boolean}>`
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.isSubmitted ? "auto" : "100%")};
    padding: ${(props) => (props.isSubmitted ? "2rem 2.5rem" : "0 1.6rem 1.6rem")};
    overflow: auto;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: ${(props) => (props.isSubmitted ? "auto" : "81rem")};
        height: auto;
    }
`;

const iconStyle = (theme: Theme) => css`
    transform: scale(1.5);
    margin-top: -0.4rem;
    margin-left: -0.3rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        transform: scale(1.8);
        margin-left: 0;
    }
`;

const modalHeading = (theme: Theme) => css`
    font-size: 2rem;
    margin: 2.3rem 0 1rem 0;
    color: ${theme.colors.brand_primary};

    @media (min-width: ${theme.breakpoints.screen_md}) {
        align-self: center;
    }
`;

const modalSubHeading = (theme: Theme) => css`
    font-size: 1.4rem;
    color: ${theme.colors.gray_darker};
    margin-bottom: 3.6rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin-bottom: 2.4rem;
        align-self: center;
    }
`;

const imageStyle = (theme: Theme) => css`
    align-self: center;
    margin-bottom: 3.6rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin-bottom: 1.6rem;
    }
`;

// form styles
const formHolder = css`
    align-self: center;
    margin-bottom: 0.8rem;
    max-width: 510px;
`;

const checkboxStyles = css`
    margin-bottom: 2rem;
`;

const administrativeCopy = (theme: Theme) => css`
    font-size: 1rem;
    color: ${theme.colors.gray};
    margin-bottom: 0.8rem;

    a {
        text-decoration: underline;
    }
`;
