import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {OFFER_LIST_LIST_TESTID} from "@web2/gh_page_object_models";
import {SkeletonBox} from "@web2/skeleton_box";

import {OFFER_BOX_GALLERY_HEIGHT} from "./components/OfferBoxGallery";
import {OFFERBOX_STANDARD_HEIGHT, OFFERBOX_WITH_CTA_BUTTON_HEIGHT, OfferBoxHolder} from "./OfferBox";

interface IProps {
    simulateShowAskForOffer?: boolean; // offer boxes with `showAskForOffer` are taller
}
export const OfferBoxSkeleton = (props: IProps) => {
    return (
        <SkeletonHolder data-testid={OFFER_LIST_LIST_TESTID.SKELETON_OFFER_BOX} renderCTA={props.simulateShowAskForOffer}>
            <SkeletonBox className={galleryHolder} height={`${OFFER_BOX_GALLERY_HEIGHT}px`} width="auto" />
            <div className={desriptionHolder}>
                <div className={typeHolder}>
                    <SkeletonBox isText height="100%" width="80px" />
                    <SkeletonBox isText height="100%" width="130px" />
                </div>
                <div className={priceAndLocationHolder}>
                    <div className={priceHolder}>
                        <SkeletonBox isText height="2.2rem" width="130px" />
                        <SkeletonBox isText height="1.6rem" width="90px" />
                    </div>
                    <SkeletonBox isText className={addressSkeleton} height="1rem" width="210px" />
                </div>
            </div>
        </SkeletonHolder>
    );
};

const SkeletonHolder = styled(OfferBoxHolder)`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: ${(props) => (props.renderCTA ? OFFERBOX_WITH_CTA_BUTTON_HEIGHT + "px" : OFFERBOX_STANDARD_HEIGHT + "px")};
`;

const galleryHolder = css`
    flex: 0 0 ${OFFER_BOX_GALLERY_HEIGHT}px;
`;

const desriptionHolder = css`
    position: relative;
    padding: 1rem;
    height: 100%;
    flex: 0 1 100%;
`;

const typeHolder = css`
    display: flex;
    justify-content: space-between;
    height: 1.2rem;
    margin-bottom: 1.4rem;
`;

const priceAndLocationHolder = css`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const priceHolder = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.3rem;
`;

const addressSkeleton = css`
    margin-top: 1rem;
`;
