import {omit} from "lodash";

export const validateQuery = (
    validQueryKeys: string[],
    initQuery: Record<string, string | string[]>,
    updateQuery: Record<string, string | string[]>
): Record<string, string | string[]> => {
    const validQuery = omit(initQuery, validQueryKeys);
    return {...validQuery, ...updateQuery};
};
