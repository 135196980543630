import {Dispatch} from "redux";

import {IArticleCategory, IArticleCategoryListResponse} from "../../app/interfaces/response/article_category_list";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export const fetchArticleCategoriesListActionTypes = createRequestActionTypes({name: "articleListCategories", type: "GET", view: "Blog"});

export type IArticleCategoriesListResult = {page: number; page_size: number; count: number; categories: IArticleCategory[]};

export const fetchArticleCategoriesListAtRoute =
    () =>
    (dispatch: Dispatch, getState: () => IStore): Promise<IArticleCategoriesListResult | void> => {
        if (getState().articles.categoryList && getState().articles.categoryList.categories.length) {
            return Promise.resolve(getState().articles.categoryList);
        }
        dispatch({type: fetchArticleCategoriesListActionTypes.start});

        const url = apiLink.articles.categories({})(null);

        return getRequest({}, url)
            .then((response: IArticleCategoryListResponse) => {
                const result = {
                    page: response.page,
                    page_size: response.page_size,
                    count: response.count,
                    categories: response.results
                };
                dispatch({type: fetchArticleCategoriesListActionTypes.success, result});
                return Promise.resolve(result);
            })
            .catch((err) => console.error(err));
    };
