import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {MultileadCTA} from "../components/MultileadCTA";

/*
 * WARNING! Backend needs to add validation for new sources, remember to contact them before making changes in this file.
 */
export enum ApplicationSource {
    // LEGACY = 1, // legacy forms that should not be used
    AGENCY = 2,
    DEVELOPER = 3,
    AGENT = 4,
    OFFER_AFTERMARKET = 5,
    OFFER_PRIMARY_MARKET = 6,
    OFFER_PRICE_INQUIRY_AFTERMARKET = 7,
    OFFER_PRICE_INQUIRY_PRIMARY_MARKET = 8,
    INVESTMENT = 9,
    MULTILEAD = 14
}

export const getOfferApplicationSource = (market_type: OfferMarketType, isPriceInquiry = false) => {
    switch (true) {
        case market_type === "primary_market" && isPriceInquiry:
            return ApplicationSource.OFFER_PRICE_INQUIRY_PRIMARY_MARKET;
        case market_type === "primary_market":
            return ApplicationSource.OFFER_PRIMARY_MARKET;
        case market_type === "aftermarket" && isPriceInquiry:
            return ApplicationSource.OFFER_PRICE_INQUIRY_AFTERMARKET;
        case market_type === "aftermarket":
            return ApplicationSource.OFFER_AFTERMARKET;
        default:
            notifyBugsnag(
                {message: `Unknown offer.market_type "${market_type}"`, filename: "ApplicationSource.ts"},
                `getOfferApplicationSource: Unknown offer.market_type "${market_type}"`
            );
            console.error("getOfferApplicationSource: Unknown offer.market_type");
            return null;
    }
};

/*
 * WARNING! Backend needs to add validation for new sources, remember to contact them before making changes in this file.
 */
export enum ApplicationSourceSection {
    UNKNOWN,
    MODAL,
    SIDEBAR,
    BOTTOM,
    GALLERY,
    DESCRIPTION
}
