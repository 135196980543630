import * as React from "react";
import {cx} from "@linaria/core";
import {Button} from "@web2/button";

import {StatsIcon} from "./icons/StatsIcon";

interface IProps {
    isStatsOpen: boolean;
    setStatsOpen: () => void;
    className?: string;
}

export const OfferListLocationStatsTrigger = (props: IProps) => {
    return (
        <Button
            variant="primary"
            inverted
            onClick={props.setStatsOpen}
            className={cx(props.className, props.isStatsOpen && "opened")}
            icon={<StatsIcon />}
            size="sm"
            style={{color: "#37474f", border: "0 none", transition: "background-color 100ms ease-out", textDecoration: "underline"}}
        >
            Statystyki regionu
        </Button>
    );
};
