import {Dispatch} from "redux";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {apiPath} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl, isDevelopment, stageHost} from "../../app/utils/read_environment_variables";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {getSectionFromSlug, SiteMapSection} from "../utils/siteMapUtils";

export interface ISiteMapSectionData {
    section: string;
    item: {
        title: string;
        url: string;
        count?: number;
    }[];
}

export interface ISiteMapResponse {
    results: ISiteMapSectionData[];
}

export const fetchSiteMapTypes = createRequestActionTypes({view: "SiteMap", type: "GET", name: "SiteMap"});
export const fetchFirstLevelTypes = createRequestActionTypes({view: "SiteMapFirstLevelInfo", type: "GET", name: "SiteMapFirstLevelInfo"});

export const fetchSiteMapsAtRoute = (services: Partial<IServices>, route: IRouteState<{siteMapSlug: string}>) => (dispatch: Dispatch) => {
    dispatch({type: fetchSiteMapTypes.start});
    dispatch({type: fetchFirstLevelTypes.start});

    let url = isDevelopment && stageHost ? stageHost : ghCommonUrl;
    let jsonName = apiPath.siteMap;
    let fistLevel = true;

    const siteMapSlug = route.params.siteMapSlug;
    const isArticle = getSectionFromSlug(siteMapSlug) === SiteMapSection.Articles;
    const isNewestOffers = getSectionFromSlug(siteMapSlug) === SiteMapSection.NewestOffers;

    if (siteMapSlug && !isArticle && !isNewestOffers) {
        if (getSectionFromSlug(siteMapSlug)) {
            jsonName = "/sitemap-" + getSectionFromSlug(siteMapSlug) + ".json";
            fistLevel = false;
        }
    }
    url = url + jsonName;

    return getRequest({}, url)
        .then((response: ISiteMapResponse | any) => {
            if (isArticle) {
                const articlesResponse = response.filter((obj: any) => obj.section === SiteMapSection.Articles);
                response = articlesResponse;
                fistLevel = false;
            }
            if (isNewestOffers) {
                const newestOffersResponse = response.filter((obj: any) => obj.section === SiteMapSection.NewestOffers);
                response = newestOffersResponse;
                fistLevel = false; //
            }
            const result = {response, fistLevel};

            dispatch({type: fetchSiteMapTypes.success, result: response});
            dispatch({type: fetchFirstLevelTypes.success, result: fistLevel});
            return result;
        })
        .catch(
            catch404(async (err) => {
                dispatch({type: fetchSiteMapTypes.error, err});
                dispatch({type: fetchFirstLevelTypes.error, err});
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        )
        .catch((err) => {
            dispatch({type: fetchSiteMapTypes.error, err});
            dispatch({type: fetchFirstLevelTypes.error, err});
        });
};
