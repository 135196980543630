import {deburr} from "lodash";

import {ILocation} from "../offer/list/actions/fetch_location_by_slug_at_route";

interface AdTargetingOptions {
    locationPath?: ILocation["path"];
    pageNames?: AdTargetingPageName | AdTargetingPageName[];
}

export const enum AdTargetingPageName {
    LISTING = "listing",
    BLOG = "blog",
    HOMEPAGE = "glowna"
}

/**
 * Return string used by Smart Ad Server to target ads. Returns format required by placement: "wojewodztwo=mazowieckie;city=warszawa;strona=listing"
 * https://help.smartadserver.com/articles/Knowledge/Tagging-guide-2-implementation#keywords
 */
export const getAdTargetingString = (options: AdTargetingOptions): string => {
    let targetingArr: string[] = [];

    if (options.locationPath) {
        targetingArr = [...targetingArr, ...getTargetingFromLocationPath(options.locationPath)];
    }

    if (options.pageNames) {
        typeof options.pageNames === "string"
            ? targetingArr.push(`strona=${options.pageNames}`)
            : options.pageNames.forEach((name) => targetingArr.push(`strona=${options.pageNames}`));
    }

    return targetingArr.join(";");
};

type LocationPathElement = ILocation["path"][0];

const getTargetingFromLocationPath = (locationPath: ILocation["path"]) => {
    return locationPath.reduce<string[]>((accumulator: string[], currentValue: LocationPathElement) => {
        const keyName = deburr(currentValue.type.toLowerCase());
        accumulator.push(`${keyName}=${currentValue.slug}`);
        return accumulator;
    }, []);
};
