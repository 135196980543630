import {hitGoogleTagManager} from "./gtm_init";

export const gtmEventSearchUse = () => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "usability",
        GAEventAction: "search"
    };
    hitGoogleTagManager(gtmData);
};
