import * as React from "react";
import {getUnixTime, parse} from "date-fns";
import {includes, isEqual, some} from "lodash";
import {IRange} from "@web2/form_fields";
import {areaFormat, dateTimeFormat} from "@web2/string_utils";

import {RoundDirection} from "../../app/utils/number";
import {OfferDealType} from "../../offer/utils/constants_offer";
import {SearchTypeDot} from "../components/atoms/SearchTypeDot";
import {availableFromOptions} from "../components/filters/AvailableFromSelect";
import {shortPrice} from "../components/filters/obsolete_range_filter/utils";
import {TypeSearchFilterOptions} from "./TypeSearchFilterOptions";

export const getAreaFilterLabel = (value: IRange<string>, label: string) => {
    const {lower, upper} = value;

    if (lower && upper) {
        return (
            <React.Fragment>
                <span>{`${areaFormat(parseInt(lower, 10))} - ${areaFormat(parseInt(upper, 10))}`}&nbsp;</span>
            </React.Fragment>
        );
    }
    if (lower) {
        return (
            <React.Fragment>
                <span>{` od ${areaFormat(parseInt(lower, 10))}`}&nbsp;</span>
            </React.Fragment>
        );
    }
    if (upper) {
        return (
            <React.Fragment>
                <span>{` do ${areaFormat(parseInt(upper, 10))}`}&nbsp;</span>
            </React.Fragment>
        );
    }
    return label;
};

export const getPriceFilterLabel = (value: IRange<string>, defaultLabel: string, labelSuffix?: string) => {
    const {lower, upper} = value;

    // add non-breakable space to suffix
    const suffix = labelSuffix ? `${String.fromCharCode(160)}${labelSuffix}` : "";

    const shortenedLowerPrice = parseInt(lower) < 1000 ? lower : shortPrice(parseInt(lower, 10), RoundDirection.DOWN);
    const shortenerUpperPrice = parseInt(upper) < 1000 ? upper : shortPrice(parseInt(upper, 10), RoundDirection.UP);

    if (lower && upper) {
        return (
            <>
                Cena <br />
                <span>{` ${shortenedLowerPrice} - ${shortenerUpperPrice}${suffix}`}</span>
            </>
        );
    }
    if (lower) {
        return (
            <>
                Cena <br />
                <span>{` od ${shortenedLowerPrice}${suffix}`}</span>
            </>
        );
    }
    if (upper) {
        return (
            <>
                Cena <br />
                <span>{` do ${shortenerUpperPrice}${suffix}`}</span>
            </>
        );
    }
    return defaultLabel;
};

export const getTypeLabel = (value: TypeSearchFilterOptions[] | [], label: string) => {
    if (value.length === 0) {
        // filter values are not loaded yet
        return label;
    }

    if (isEqual(value, ["lot"])) {
        return (
            <>
                <SearchTypeDot type="lot" />
                Działki
            </>
        );
    }
    const primaryMarketPresent = some(value, (v) => includes(v, "primary_market"));
    const afterMarketPresent = some(value, (v) => includes(v, "aftermarket"));

    const housesPresent = some(value, (v) => includes(v, "house"));
    const apartmentsPresent = some(value, (v) => includes(v, "apartment"));
    const filterLabel = apartmentsPresent && housesPresent ? "Nieruchomości" : apartmentsPresent ? "Mieszkania" : "Domy";

    return (
        <>
            {primaryMarketPresent && <SearchTypeDot type="primary" />}
            {afterMarketPresent && <SearchTypeDot type="after" />}
            {filterLabel}
        </>
    );
};

export const getDealTypeLabel = (value: OfferDealType | undefined | [], label: string) => {
    switch (value) {
        case OfferDealType.RENT:
            return "Do wynajęcia";
        case OfferDealType.SELL:
            return "Na sprzedaż";
        default:
            return label;
    }
};

export const getAvailableFromLabel = (value: IRange<unknown>, defaultLabel: string): string | JSX.Element => {
    const {upper} = value as IRange<string>;
    if (upper) {
        const providedUpperDate = getUnixTime(parse(upper, dateTimeFormat.shortDateDashed, new Date()));
        const closestToRequestedDateSortedOptions = [...availableFromOptions].sort((a, b) => {
            return (
                Math.abs(providedUpperDate - getUnixTime(parse(a.value, dateTimeFormat.shortDateDashed, new Date()))) -
                Math.abs(providedUpperDate - getUnixTime(parse(b.value, dateTimeFormat.shortDateDashed, new Date())))
            );
        });
        return closestToRequestedDateSortedOptions[0].label;
    }
    return defaultLabel;
};
