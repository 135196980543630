import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const NotVisibleIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "6.07mm"} height={props.height || "5.29mm"} viewBox="0 0 17.22 15">
            <path
                d="M7.72,10.2a.76.76,0,0,0,.21,0,.69.69,0,0,0,.65-.46.68.68,0,0,0-.44-.86A1.51,1.51,0,0,1,7.2,8a.68.68,0,1,0-1.29.43A2.85,2.85,0,0,0,7.72,10.2Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M8.61,14a7.51,7.51,0,0,0,2.25-.36.68.68,0,0,0-.42-1.3,6,6,0,0,1-1.83.29c-3.92,0-6.49-3.94-7.15-5.08A13.12,13.12,0,0,1,3.12,5.23a.68.68,0,0,0-.05-1,.67.67,0,0,0-1,0,14.55,14.55,0,0,0-2,2.88.72.72,0,0,0,0,.61C.2,8.05,3.21,14,8.61,14Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M8.61,1.05a7.77,7.77,0,0,0-4.2,1.29L2.27.2a.67.67,0,0,0-1,0,.67.67,0,0,0,0,1L14.94,14.8a.71.71,0,0,0,.49.2.68.68,0,0,0,.48-1.16l-2-2a13.85,13.85,0,0,0,3.23-4,.66.66,0,0,0,0-.61C17,6.94,14,1.05,8.61,1.05ZM10,7.94,8.16,6.09A1.69,1.69,0,0,1,8.61,6,1.48,1.48,0,0,1,10.09,7.5,1.62,1.62,0,0,1,10,7.94Zm2.92,2.93L11,9a2.93,2.93,0,0,0,.41-1.47A2.84,2.84,0,0,0,7.14,5.07L5.4,3.33a6.36,6.36,0,0,1,3.21-.91c3.92,0,6.48,3.94,7.15,5.08A13.05,13.05,0,0,1,12.94,10.87Z"
                fill={props.mainColor || "#37474f"}
            />
        </svg>
    );
};
