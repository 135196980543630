import {Dispatch} from "redux";
import {appPath} from "@web2/gh_routes";

import {apiLink} from "../../routes/api_link";
import {IRouteState} from "../../routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../services/IServices";
import {notifyBugsnag} from "../../utils/bugsnag/notify_bugsnag";
import {postRequest} from "../../utils/request_response_utils/request";
import {enable302ResponseState} from "../../utils/request_response_utils/response_state/response_state_actions";

export const redirectAfterOauthLoginAtRoute =
    (services: Partial<IServices>, route: IRouteState<{code?: string; next?: string}>) => async (dispatch: Dispatch) => {
        const {code, next} = route.query;

        if (code) {
            await postRequest(services, apiLink.userApi.oauth.token(), {code}).catch((err) => {
                notifyBugsnag(err, "code validation error in redirectAfterOauthLoginAtRoute");
                // TODO: We should read message from error - we need clear message from backend here
                // TODO: find way to show error here
            });
        }

        // Redirect to place where user clicked `login` button (`next` query param) or to main page
        dispatch(enable302ResponseState((next as string) || appPath.mainPage));
        return false;
    };
