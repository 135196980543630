import {isEqual} from "lodash";
import {Dispatch} from "redux";

import {ILocationStatisticsResponse} from "../../../app/interfaces/response/location_statistics";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {normalizeLocationStatisticsResponse} from "../../utils/normalize_location_statistics_response";

export const fetchLocationStatisticsTypes = createRequestActionTypes({
    name: "locationStatistics",
    type: "GET",
    view: "offer_list"
});

export const fetchLocationStatisticsAtRoute = (services: Partial<IServices>) => (dispatch: Dispatch, getState: () => IStore) => {
    const locationId = getState().offerList.location.location?.id;
    if (!locationId) {
        return Promise.resolve(true);
    }

    const params = {slug: locationId};
    if (isEqual(params, getState().offerList.location.statisticsLatestQuery)) {
        // prevent refetch
        return Promise.resolve(true);
    }
    dispatch({type: fetchLocationStatisticsTypes.start, latestQuery: params});

    const url = apiLink.locations.statistics({})(params);
    return getRequest(services, url, "fetchLocationStatisticsAtRoute")
        .then((response: ILocationStatisticsResponse) => {
            const result = normalizeLocationStatisticsResponse(response);
            dispatch({type: fetchLocationStatisticsTypes.success, result});
            return result;
        })
        .catch(catchAbortedError(() => false));
};
