import * as React from "react";

export const BuildingSvgIcon = () => {
    return (
        <svg width="37" height="37" viewBox="0 0 37 37">
            <defs>
                <mask id="a" x="0" y="0" width="37" height="37" maskUnits="userSpaceOnUse">
                    <polygon points="0 37 37 37 37 0 0 0 0 37" fill="#fff" fillRule="evenodd" />
                </mask>
            </defs>

            <path d="M26.37,34H8.63a.57.57,0,0,0-.63.5v1a.57.57,0,0,0,.63.5.58.58,0,0,0,.64-.5V35h17.1a.51.51,0,1,0,0-1Z" fill="#37474f" fillRule="evenodd" />

            <path
                d="M33.33,26H30.67c-.37,0-.67.22-.67.5v3c0,.28.3.5.67.5h2.66c.37,0,.67-.22.67-.5v-3C34,26.22,33.7,26,33.33,26Zm-.66,3H31.33V27h1.34Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M9,8.59A.62.62,0,0,0,8.36,8H.64A.62.62,0,0,0,0,8.59V20.41A.62.62,0,0,0,.64,21a.62.62,0,0,0,.65-.59V9.18H8.36A.62.62,0,0,0,9,8.59Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M6.33,19H3.67c-.37,0-.67.22-.67.5v1c0,.28.3.5.67.5s.66-.22.66-.5V20H5.67v2.5c0,.28.3.5.66.5s.67-.22.67-.5v-3C7,19.22,6.7,19,6.33,19Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M13.67,15h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H13.67c-.37,0-.67.22-.67.5v3h0C13,14.78,13.3,15,13.67,15Zm.66-3h1.34v2H14.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M17.15,29.14a.51.51,0,0,0-.15.36.47.47,0,0,0,.15.35.48.48,0,0,0,.7,0A.47.47,0,0,0,18,29.5a.51.51,0,0,0-.15-.36A.5.5,0,0,0,17.15,29.14Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M6.33,11H3.67c-.37,0-.67.22-.67.5v3c0,.28.3.5.67.5H6.33c.37,0,.67-.22.67-.5v-3C7,11.22,6.7,11,6.33,11Zm-.66,3H4.33V12H5.67Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M13.67,23h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H13.67c-.37,0-.67.22-.67.5v3h0C13,22.78,13.3,23,13.67,23Zm.66-3h1.34v2H14.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M20.67,9h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H20.67c-.37,0-.67.22-.67.5v3C20,8.78,20.3,9,20.67,9Zm.66-3h1.34V8H21.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M20,22.5c0,.28.3.5.67.5h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H20.67c-.37,0-.67.22-.67.5ZM21.33,20h1.34v2H21.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path d="M36.36,8H28.64a.6.6,0,1,0,0,1.2h7.07v24A2,2,0,0,1,37,35V8.6A.62.62,0,0,0,36.36,8Z" fill="#37474f" fillRule="evenodd" />

            <path
                d="M13.67,9h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H13.67c-.37,0-.67.22-.67.5v3C13,8.78,13.3,9,13.67,9Zm.66-3h1.34V8H14.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M20,14.5c0,.28.3.5.67.5h2.66c.37,0,.67-.22.67-.5v-3c0-.28-.3-.5-.67-.5H20.67c-.37,0-.67.22-.67.5ZM21.33,12h1.34v2H21.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M33.33,19H30.67c-.37,0-.67.22-.67.5v3c0,.28.3.5.67.5h2.66c.37,0,.67-.22.67-.5v-3C34,19.22,33.7,19,33.33,19Zm-.66,3H31.33V20h1.34Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M15.6,33.33v-6h4.8v6a.6.6,0,1,0,1.2,0v-6h1.8a.67.67,0,0,0,0-1.33H12.6a.67.67,0,0,0,0,1.33h1.8v6a.6.6,0,1,0,1.2,0Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <path
                d="M26.39,0H10.61A.61.61,0,0,0,10,.61V32.39a.61.61,0,1,0,1.21,0V1.22H25.79V32.39a.61.61,0,1,0,1.21,0V.61A.61.61,0,0,0,26.39,0Z"
                fill="#37474f"
                fillRule="evenodd"
            />

            <g mask="url(#a)">
                <path
                    d="M3.5,22C1.57,22,0,25.18,0,29.1,0,32.54,1.2,33.71,2.8,34a5.36,5.36,0,0,0,.7,0,5.36,5.36,0,0,0,.7,0C5.8,33.71,7,32.54,7,29.1,7,25.18,5.43,22,3.5,22m32,10.78v0a3.27,3.27,0,0,0-1.06-.17H34.3l0,0h-.09c0-.06-.07-.12-.1-.19a2.41,2.41,0,0,0-1.69-1,2.56,2.56,0,0,0-2.85,1.3c-.2,0-.4.09-.58.15a5.74,5.74,0,0,0-.89.32,1.89,1.89,0,0,0-1.15,1.1c-.49,2,2.25,2.09,4.74,2.09.77,0,2.55-.14,2.78-.14a2.74,2.74,0,0,0,2-.74,1.54,1.54,0,0,0,.46-1.07,1.86,1.86,0,0,0-1.36-1.63"
                    fill="#9069c0"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
};
