import {numberWithDelimiter} from "@web2/string_utils";

import {sizeFormat} from "../../../../../app/utils/number";
import {IStatsLocationDetailed} from "./AveragePricesRent";

export const ListItemRooms = (props: {
    rooms: number;
    commonString: string;
    price: number | null | undefined;
    apartmentRentStats?: IStatsLocationDetailed | undefined;
    flatNameVariant?: string;
}) => {
    const {rooms, commonString, price, apartmentRentStats, flatNameVariant} = props;

    if (rooms === 1 && apartmentRentStats) {
        return (
            <li>{`kawalerki ${commonString} ${
                apartmentRentStats.one_room.min_price && numberWithDelimiter(sizeFormat(apartmentRentStats.one_room.min_price))
            } zł;`}</li>
        );
    }

    return price ? (
        <li>{`${flatNameVariant} ${rooms}-pokojowe ${commonString} ${numberWithDelimiter(sizeFormat(price))} zł${rooms === 4 ? "." : ";"}`}</li>
    ) : null;
};
