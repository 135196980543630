import {Dispatch} from "redux";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAgencyRegisterMetaData = (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => (dispatch: Dispatch) => {
    const title = `Oferta dla biur nieruchomości`;
    const description = `Pomagamy dotrzeć do osób poszukujących nieruchomości. Zarejestruj swoje biuro na gethome.pl. Zapraszamy do współpracy!✅`;
    const robots = "index, follow";

    dispatch(updateMetaData(title, description, route, {robots, hideSuffix: false}));

    return Promise.resolve(true);
};
