import {isBrowser} from "../app/utils/read_environment_variables";

/**
 * DOCS: https://support.smartadserver.com/s/article/Tagging-guide
 */
export const sasErrorVariableName = "sasError";
let isSmartAdServerInitialized = false;
export const initSmartAdServer = (site?: string) => {
    // prevent initialization when ENV variable is missing
    if (site == null) {
        return;
    }
    // initialize only in browser
    if (!isBrowser) {
        return;
    }
    // initialize only once
    if (isSmartAdServerInitialized) {
        return;
    }
    // fetch/init W2T
    const firstScriptElem = document.getElementsByTagName("script")[0];
    const newScriptElem = document.createElement("script");
    newScriptElem.async = true;
    newScriptElem.onerror = () => (win[sasErrorVariableName] = true);
    newScriptElem.src = "//ced.sascdn.com/tag/2907/smart.js";
    firstScriptElem.parentNode && firstScriptElem.parentNode.insertBefore(newScriptElem, firstScriptElem);
    // snippet
    const win = window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    win.sas = win.sas || {};
    win.sas.cmd = win.sas.cmd || [];
    win.sas.cmd.push(function () {
        win.sas.setup({networkid: 2907, domain: "https://www14.smartadserver.com", async: true});
    });
    isSmartAdServerInitialized = true;
};
