import * as React from "react";
import {css, cx} from "@linaria/core";

import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";

interface IProps {
    favouritesCount: number;
    className?: string;
}

export const FavouritesCountBadge = (props: IProps) => {
    if (props.favouritesCount === 0) {
        return null;
    }
    return <div className={cx(favouritesCountBadge, props.className)}>{props.favouritesCount}</div>;
};

const favouritesCountBadge = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${getThemeVariable("colors-brand_danger")};
    color: #fff;
    border-radius: 50%;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1rem;
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        position: absolute;
        right: 0.5rem;
        top: 0.2rem;
        margin-left: 0;
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        right: 1.5rem;
    }
`;
