export const SEARCH_INPUT_FIELD_TESTID = "search-input-field";
export const OFFER_LIST_HEADING_TEXT = "offer-list-heading-text";

export const FILTER_NUMBER_OF_ROOMS_TESTID = {
    DROPDOWN_FILTER_ROOMS: "number-of-rooms",
    CHECKBOX_NUMBER_OF_ROOMS: "checkbox-number-of-rooms"
};
export const TRANSACTION_TYPE_TESTID = {
    TRANSACTION_TYPE: "transaction-type",
    FOR_SALE: "for-sale",
    FOR_RENT: "for-rent"
};
export const OFFER_LIST_LIST_TESTID = {
    OFFER_LIST_HEADING_TEXT: "offer-list-heading-text",
    OFFER_LIST_WRAPPER: "offer-list-wrapper",
    SKELETON_OFFER_BOX: "skeleton-offer-box",
    OFFER_BOX: "offer-box",
    NUMBER_OF_ROOMS_BANNER: "number-of-rooms-banner"
};

export const OFFER_BOX_TESTID = {
    NUMBER_OF_ROOMS_OFFERBOX: "number-of-rooms-offerbox",
    VARIANT_WORD_ROOMS: "variant-word-rooms",
    HEADER_OFFERBOX: "header-offerbox",
    MONTHLY_FEE_OR_ONE_TIME_FEE: "monthly-fee-or-one-time-fee",
    LINK: "offer-box-link"
};

export const FILTER_PROPERTY_TYPE_TESTID = {
    PROPERTY_TYPE: "property-type",
    LIST_PROPERTY_TYPE: "list-property-type",
    CHECKBOX_PRIMARY_MARKET: "checkbox-primary-market",
    CHECKBOX_PRIMARY_MARKET_HAUSES: "checkbox-primary-market-houses",
    CHECKBOX_PRIMARY_MARKET_APARTMENT: "checkbox-primary-market-apartment",
    CHECKBOX_AFTERMARKET: "checkbox-aftermarket",
    CHECKBOX_AFTERMARKET_HAUSES: "checkbox-aftermarket-hauses",
    CHECKBOX_AFTERMARKET_APARTMENT: "checkbox-aftermarket-apartment",
    CHECKBOX_LOTS: "checkbox-lots"
};

export const SEARCH_DROPDOWN_TESTID = {
    WRAPPER: "search-dropdown--wrapper",
    LAST_SEARCH_HEADLINE: "search-dropdown--last-search-headline",
    SUGGESTED_LOCATIONS_HEADLINE: "search-dropdown--suggested-locations-headline",
    SUGGESTIONS_NOT_FOUND: "search-dropdown--suggestions-not-found"
};

export const SEARCH_SUGGESTION_TESTID = {
    WRAPPER: "search-suggestion--wrapper",
    TITLE: "search-suggestion--title"
};

export const LISTING_GENERIC_TEXT = "listing--generic-text";

export const SORT_BUTTON_TESTID = {
    DROPDOWN_SORT_BUTTON: "dropdown-sort-button",
    SORT_LIST: "sort-list"
};
