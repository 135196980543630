import {PlacementSiteId} from "./PlacementSiteId";

export enum PlacementPage {
    article_detail__desktop = "/article_detail/desktop",
    article_detail__mobile = "/article_detail/mobile",
    article_list__desktop = "/article_list/desktop",
    article_list__mobile = "/article_list/mobile",
    homepage__desktop = "/homepage/desktop",
    homepage__mobile = "/homepage/mobile",
    offer_list__desktop = "/offer_list/desktop",
    offer_list__mobile = "/offer_list/mobile"
}

export const getPageId = (siteId: PlacementSiteId, page: PlacementPage) => {
    if (siteId === PlacementSiteId.prod) {
        switch (page) {
            case PlacementPage.article_detail__desktop:
                return 1779162;
            case PlacementPage.article_detail__mobile:
                return 1779163;
            case PlacementPage.article_list__desktop:
                return 1779164;
            case PlacementPage.article_list__mobile:
                return 1779165;
            case PlacementPage.homepage__desktop:
                return 1779166;
            case PlacementPage.homepage__mobile:
                return 1779167;
            case PlacementPage.offer_list__desktop:
                return 1779168;
            case PlacementPage.offer_list__mobile:
                return 1779169;
            default:
                throw new Error(`getPageId: Invalid page; siteId: ${siteId}, page: ${page}`);
        }
    } else {
        switch (page) {
            case PlacementPage.article_detail__desktop:
                return 1775343;
            case PlacementPage.article_detail__mobile:
                return 1775368;
            case PlacementPage.article_list__desktop:
                return 1775336;
            case PlacementPage.article_list__mobile:
                return 1775340;
            case PlacementPage.homepage__desktop:
                return 1775327;
            case PlacementPage.homepage__mobile:
                return 1775369;
            case PlacementPage.offer_list__desktop:
                return 1775322;
            case PlacementPage.offer_list__mobile:
                return 1775324;
            default:
                throw new Error(`getPageId: Invalid page; siteId: ${siteId}, page: ${page}`);
        }
    }
};
