import * as React from "react";
import {Suspense} from "react";
import {useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css, cx} from "@linaria/core";
import {ArrowWithOrientationIcon} from "@web2/icons";
import {Modal} from "@web2/modal";
import {BounceLoader} from "@web2/ui_utils";

import {IOfferModalDetail} from "../../../app/interfaces/response/offer_detail";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {getThemeBreakpoint} from "../../../styles/linaria_variable_factory";
import {PageDirection} from "./OfferListList";

interface IProps extends RouteComponentProps {
    isPrev: boolean;
    isNext: boolean;
    isLoading: boolean;
    onPageChange: (pageDirection: PageDirection) => void;
    onModalClose: () => void;
    modalState: boolean;
    offer: {offer: IOfferModalDetail};
}

const OfferModalDynamic = React.lazy(() => import(/* webpackChunkName: "OfferModalDynamic" */ "../../detail/components/OfferModalDynamic"));

const OfferListModalOfferC = (props: IProps) => {
    const offerFeatures = useSelector((store: IStore) => store.offer.offerFeatures);

    return (
        <>
            {props.isPrev && (
                <div className={cx(modalOfferArrow, leftModalOfferArrow)} onClick={() => props.onPageChange(PageDirection.PREV)}>
                    <ArrowWithOrientationIcon orientation="left" fill="#fff" strokeWidth={1} width={41} height={23} />
                </div>
            )}
            <Modal
                modalState={props.modalState}
                onModalClose={props.onModalClose}
                type="full"
                closeButton={false}
                contentStyles={modalContentStyles}
                overlayStyles={modalOverlayStyles}
            >
                {props.isLoading ? (
                    <div className={loaderWrapper}>
                        <BounceLoader color="#9069c0" />
                    </div>
                ) : (
                    <Suspense
                        fallback={
                            <div className={loaderWrapper}>
                                <BounceLoader color="#9069c0" />
                            </div>
                        }
                    >
                        <OfferModalDynamic onModalClose={props.onModalClose} offer={props.offer?.offer} offerFeatures={offerFeatures} />
                    </Suspense>
                )}
            </Modal>

            {props.isNext && (
                <div className={cx(modalOfferArrow, rightModalOfferArrow)} onClick={() => props.onPageChange(PageDirection.NEXT)}>
                    <ArrowWithOrientationIcon orientation="right" fill="#fff" strokeWidth={1} width={41} height={23} />
                </div>
            )}
        </>
    );
};

export const OfferListModalOffer = withRouter<IProps, typeof OfferListModalOfferC>(OfferListModalOfferC);

const modalContentStyles = {
    maxWidth: "1024px",
    height: "100vh",
    outline: "none"
};

const modalOverlayStyles = {
    backgroundColor: "rgba(55, 71, 79, 0.8)",
    position: "fixed",
    zIndex: "8000",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const size = 120;

const modalOfferArrow = css`
    position: fixed;
    z-index: 8001;
    top: calc(50vh - 90px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 400ms ease 100ms;
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
    margin: 0;

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }

    @media (max-width: calc( ${getThemeBreakpoint().screen_lg} - 1px)) {
        display: none;
    }
`;

const leftModalOfferArrow = css`
    left: 0;
    width: ${size / 2}px;
    height: ${size}px;
    border-bottom-right-radius: ${size * 2}px;
    border-top-right-radius: ${size * 2}px;
`;

const rightModalOfferArrow = css`
    right: 0;
    width: ${size / 2}px;
    height: ${size}px;
    border-bottom-left-radius: ${size * 2}px;
    border-top-left-radius: ${size * 2}px;
`;

const loaderWrapper = css`
    position: relative;
    padding: 0;
    background: #fff;
    height: 100%;
    width: 100vw;
    max-width: 1024px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;
