import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateForAgentMetaData = (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => (dispatch: Dispatch) => {
    const title = `Oferta dla biur nieruchomości`;
    const description = `Pomagamy dotrzeć do osób poszukujących nieruchomości. Zarejestruj swoje biuro na gethome.pl. Zapraszamy do współpracy!✅`;
    const robots = "index, follow";
    const url = `${ghCommonUrl}${appLink.forAgent()}`;

    dispatch(updateMetaData(title, description, route, {robots, hideSuffix: false, canonical: url}));

    return Promise.resolve(true);
};
