import {includes, omit} from "lodash";

import {IQueryParams} from "../../app/utils/append_query_string";
import {UDealTypesPL, UOfferTypes} from "../../offer/utils/constants_offer";

// TODO: consider moving this logic to `validateOfferListParams`
export const validateSearchQuery = (query: IQueryParams, offerType: UOfferTypes, offerTypeFormValue: string[], dealType?: UDealTypesPL) => {
    let newQuery = {...query};
    if (offerType === "dzialki") {
        newQuery = omit(newQuery, [
            "rooms",
            "floor__gte",
            "floor__lte",
            "construction_year__gte",
            "construction_year__lte",
            "available_from__gte",
            "available_from__lte"
        ]);
    }

    const isPrimaryMarket = !!offerTypeFormValue.find((o) => o.includes("primary_market"));
    const isAftermarket = !!offerTypeFormValue.find((o) => o.includes("aftermarket"));
    if (isPrimaryMarket && !isAftermarket) {
        newQuery = omit(newQuery, ["is_private"]);
    }

    offerTypeFormValue.map((o) => {
        if (includes(o, "aftermarket")) {
            newQuery = omit(newQuery, ["available_from__gte", "available_from__lte"]);
        }
        if (includes(o, "house")) {
            newQuery = omit(newQuery, ["floor__gte", "floor__lte"]);
        }
    });
    if (dealType === "do-wynajecia") {
        newQuery = omit(newQuery, ["available_from__gte", "available_from__lte"]);
    }
    return newQuery;
};
