import {useCallback, useEffect, useRef, useTransition} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {CloseIcon} from "@web2/icons";
import {LatLngTuple} from "@web2/open-street-map";
import {FetchStateType, useFetch} from "@web2/react_utils";

import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferInvestment} from "../../../app/interfaces/response/offer_list";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {osmMapEnable} from "../../../app/utils/read_environment_variables";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {IGtmOffer} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {ViewType} from "../../../tracking/view_type/view_type";
import {IOfferBoxOffer, OfferBox} from "../../detail/components/offer_box/OfferBox";
import {OfferBoxSkeleton} from "../../detail/components/offer_box/OfferBoxSkeleton";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {useOfferListMapCommonLogic} from "./map/use_offer_list_map_common_logic";
import {OfferListMapMobile} from "./OfferListMapMobile";
import {OfferListMapOsm} from "./OfferListMapOsm";

interface IStateProps {
    onMapOfferClick: (e: React.MouseEvent<HTMLElement>, mapOffer: IGtmOffer) => void;
    locationData: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    collectionCount: number;

    investment: {
        investment: IOfferInvestment | null;
        requestState: RequestState;
    };
    favouriteOffers: string[];
    visitedOffers: string[];
    viewType: ViewType | null;
}

interface Props extends IStateProps {
    setFavouriteOffer: typeof setFavouriteOffer;
}

export interface IStateLocation {
    currentBounds?: LatLngTuple[];
    offerId?: string;
}

export const OfferListDynamicMobileMaps = (props: Props) => {
    const location = useLocation<IStateLocation>();

    const {isMapInManualBrowsingMode, mapPolygon} = useOfferListMapCommonLogic(props);
    const offerRef = useRef<string | null>(null);
    const [_, startTransition] = useTransition();

    const markers = useSelector((state: IStore) => state.offerList.markers.markers);
    const markersRequestState = useSelector((state: IStore) => state.offerList.markers.markersRequestState);

    const resetSelectedOfferHandler = () => {
        _resetSelectedOffer();
        offerRef.current = null;
    };

    const offerFetcher = useCallback(() => {
        if (offerRef.current) {
            const url = apiLink.offerList.base({})(null);

            return getRequest({}, appendQueryString(url, {ids: offerRef.current})).then((res: {data: IOfferBoxOffer[]}) => res.data[0]);
        }
        return Promise.resolve();
    }, [offerRef.current]);

    const {
        fetchStart: fetchSelectedOffer,
        response: selectedOffer,
        fetchState,
        fetchReset: _resetSelectedOffer
    } = useFetch<IOfferBoxOffer | void>(() => offerFetcher());

    const isSelectedOfferFavourite = props.favouriteOffers.includes(selectedOffer?.id?.toString() || "");
    const setMobileSelectedOfferHandler = (id: string) => {
        offerRef.current = id;
        startTransition(() => {
            fetchSelectedOffer();
        });
    };

    useEffect(() => {
        if (location.state && location.state.offerId) {
            offerRef.current = location.state.offerId;
            startTransition(() => {
                fetchSelectedOffer();
            });
        }
    }, [location.key]);

    return (
        <>
            {!osmMapEnable && (
                <OfferListMapMobile
                    hoveredOfferId={null}
                    isMobileModal
                    gestureHandling="greedy"
                    manualMapBrowsingMode={isMapInManualBrowsingMode}
                    locationData={mapPolygon()}
                    markers={markers}
                    markersRequestState={markersRequestState}
                    favouriteOffers={props.favouriteOffers}
                    visitedOffers={props.visitedOffers}
                    setFavouriteOffer={props.setFavouriteOffer}
                    onOfferClick={props.onMapOfferClick}
                    viewType={props.viewType}
                />
            )}
            {osmMapEnable && (
                <>
                    <OfferListMapOsm
                        hoveredOfferId={null}
                        isMobileModal
                        gestureHandling="greedy"
                        locationData={mapPolygon()}
                        initBounds={location.state?.currentBounds}
                        markers={markers}
                        markersRequestState={markersRequestState}
                        isMobileOfferOpen={!!offerRef.current}
                        onOfferClick={setMobileSelectedOfferHandler}
                    />
                    {fetchState !== FetchStateType.None ? (
                        <MobileOfferBoxContainer>
                            {!selectedOffer ? (
                                <OfferBoxSkeleton />
                            ) : (
                                <>
                                    <CloseMobileOfferButton
                                        className={closeButtonHolder}
                                        onClick={() => {
                                            resetSelectedOfferHandler();
                                        }}
                                    >
                                        <CloseIcon size="1.6" />
                                    </CloseMobileOfferButton>
                                    <MapOfferBox
                                        key={selectedOffer.id}
                                        offer={selectedOffer}
                                        isVisited={false}
                                        isFavourite={isSelectedOfferFavourite}
                                        setIsFavourite={props.setFavouriteOffer}
                                        openApplicationModal={() => setMobileSelectedOfferHandler}
                                        isInvestment={!!selectedOffer.investment}
                                        forceSliderInit
                                    />
                                </>
                            )}
                        </MobileOfferBoxContainer>
                    ) : null}
                </>
            )}
        </>
    );
};

const MobileOfferBoxContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    z-index: 2;
`;

const MapOfferBox = styled(OfferBox)`
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 21px 2px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.4) 0 2px 21px 0;
    }
`;
const HEADER_SIZE = "5.6rem";

const CloseMobileOfferButton = styled.div<{className?: string}>``;
const closeButtonHolder = css`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${HEADER_SIZE};
    height: ${HEADER_SIZE};
    cursor: pointer;
    background-color: ${getThemeVariable("colors-gray_very_bright")};
    border-radius: 100%;
    position: absolute;
    z-index: 8002;
    top: -2.8rem;
`;
