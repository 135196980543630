import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {usePrevious} from "@web2/react_utils";

import {IStore} from "../../src/app/reducers/hybrid_reducer";
import {appPathDataFetcher} from "../../src/app/routes/data_fetcher/app_path_data_fetcher";
import {IGivenRouteState, IPrevRouteState} from "../../src/app/routes/data_fetcher/create_app_path_data_fetcher";
import {createRequestIdService} from "../../src/app/services/create_request_id_service";
import {createRouteService} from "../../src/app/services/create_route_service";
import {ghCommonUrl, isBrowser} from "../../src/app/utils/read_environment_variables";
import {disableResponseState} from "../../src/app/utils/request_response_utils/response_state/response_state_actions";
import {ResponseState} from "../../src/app/utils/request_response_utils/response_state/response_state_reducer";
import {getClientRouteState} from "./get_client_route_state";

type Options = {enableRouteHandler: boolean};
export const useRouteHandler = (options?: Options) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    // route handler is enabled by default, but in some special cases we want to disable it,
    // ie. to avoid double fetch when we wrap the parent component in something else that is already using it
    const {enableRouteHandler = true} = options || {};

    const requestIdRef = useRef(createRequestIdService());

    const responseState = useSelector((store: IStore) => store.responseState);
    const {state, url} = responseState;

    const prevLocation = usePrevious(location, location);

    useEffect(() => {
        if (!enableRouteHandler) {
            return;
        }

        if (responseState.state !== ResponseState.STATE_200) {
            dispatch(disableResponseState());
        }

        if (prevLocation.key !== location.key) {
            // proceed with route change logic
            const currentRoute: IGivenRouteState = getClientRouteState(location);
            const prevRoute: IPrevRouteState = getClientRouteState(prevLocation);
            // calculate store
            const routeService = createRouteService();
            routeService.init(currentRoute);
            dispatch(appPathDataFetcher({requestId: requestIdRef.current, routeService}, currentRoute, prevRoute));
            return;
        }
    }, [location.key]);

    const {pathname, search} = location;

    if (pathname.slice(-1) !== "/") {
        // this trailing logic is for browser only - SSR has its own
        if (isBrowser && ghCommonUrl.match(window.location.host)) {
            return history.push(`${pathname}/${search}`);
        }
    }
    // handle response state
    switch (state) {
        case ResponseState.STATE_301:
            if (url) {
                return history.push(url);
            }
            break;
        case ResponseState.STATE_302:
            if (url) {
                return history.push(url);
            }
            break;
        case ResponseState.STATE_404:
        default:
            break;
    }
};
