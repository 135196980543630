import {identity, mapValues, pickBy} from "lodash";

const validationWhitelistedKeys = [
    "price__gte",
    "price__lte",
    "size__gte",
    "size__lte",
    "rooms",
    "sort",
    "page",
    "price_per_sqm__lte",
    "price_per_sqm__gte",
    "floor__lte",
    "floor__gte",
    "construction_year__lte",
    "construction_year__gte",
    "available_from__lte",
    "available_from__gte",
    "features",
    "is_private"
];

const doNotTransformList = ["rooms", "available_from__lte", "features", "is_private", "mapView"];

/**
 * Function validates whitelisted query params by converting values to absolute numbers.
 * Ignores selected values from transformation.
 */
const absoluteNumber = (value: string | number) => Math.abs(+value) || 0; // Get rid of NaNs, default to 0.

export const validateQueryValues = (queryValuesRaw: Record<string, string | number>): Record<string, string | number> => {
    const parsedValues = mapValues(queryValuesRaw, (value, key) => {
        if (validationWhitelistedKeys.includes(key)) {
            // omit selected params from transformations
            if (doNotTransformList.includes(key)) {
                return value;
            }
            // transform param value to absolute number
            return absoluteNumber(value).toString();
        }
        // discard param
        return "";
    });

    return pickBy(parsedValues, identity);
};
