import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {BREADCRUMBS_TEST_IDS} from "@web2/gh_page_object_models";
import {HomeIcon} from "@web2/icons";
import {capitalize, capitalizeFirst} from "@web2/nodash";

import {SeparatorIcon} from "./SeparatorIcon";

interface IBreadcrumb {
    label: string;
    link: string;
}

const lastBreadcrumb = (list: IBreadcrumb[]): IBreadcrumb => list[list.length - 1];

interface Props {
    className?: string;
    breadcrumbs: IBreadcrumb[];
    variant?: "simple";
}

export const Breadcrumbs = (props: Props) => {
    const links = props.breadcrumbs.slice(0, props.breadcrumbs.length - 1);
    const lastBreadcrumbElement = lastBreadcrumb(props.breadcrumbs);

    const listRef = useRef<HTMLOListElement | null>(null);
    const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

    const className = classNames(props.className);
    const resizeHandler = useCallback(() => {
        listRef.current && (listRef.current.scrollLeft += 50);
        listRef.current && listRef.current.scrollLeft > 0 ? setIsOverlayVisible(true) : setIsOverlayVisible(false);
    }, []);

    useEffect(() => {
        listRef.current && (listRef.current.scrollLeft += 2000);
        listRef.current && listRef.current.scrollLeft > 0 ? setIsOverlayVisible(true) : setIsOverlayVisible(false);
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    function render(breadcrumb: IBreadcrumb, i: number) {
        return (
            <li itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem" key={i}>
                <a href={breadcrumb.link} target="_self" itemProp="item" data-testid={`${BREADCRUMBS_TEST_IDS.ITEM}`}>
                    <span itemProp="name">{capitalize(breadcrumb.label)}</span>
                    <meta itemProp="position" content={`${i + 2}`} />
                </a>

                <SeparatorIcon />
            </li>
        );
    }

    return (
        <BreadcrumbsHolder className={className} variant={props.variant} data-testid={BREADCRUMBS_TEST_IDS.HOLDER}>
            <nav css={[breadcrumbsNav]} id="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                <ol css={breadcrumbsList} id="breadcrumbs-list" ref={listRef}>
                    <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <a href="/" itemProp="item" data-testid={`${BREADCRUMBS_TEST_IDS.ITEM}`}>
                            <span css={[hideText]} itemProp="name">
                                Strona główna
                            </span>

                            <HomeIcon className="root" />
                        </a>

                        <SeparatorIcon />

                        <meta itemProp="position" content="1" />
                    </li>

                    {links.map((breadcrumb: IBreadcrumb, i: number) => render(breadcrumb, i))}

                    {lastBreadcrumbElement && (
                        <li css={prMd} data-testid={`${BREADCRUMBS_TEST_IDS.ITEM}`}>
                            {capitalizeFirst(lastBreadcrumbElement.label)}
                        </li>
                    )}
                </ol>

                {/*We don't want this half transparent blend for now, but I think that we will use it in the near future*/}
                {/*{isOverlayVisible && <span css={[bgOverlay]} id="breadcrumbs-overlay" />}*/}
            </nav>
        </BreadcrumbsHolder>
    );
};

/*
Styles
//  */
interface IBreadcrumbsHolder {
    variant?: "simple";
}

const BreadcrumbsHolder = styled.section<IBreadcrumbsHolder>`
    max-width: 100%;
    overflow: hidden;

    ${(props) =>
        props.variant === "simple" &&
        css`
            padding: 0.5rem 1.5rem;
            position: relative;
            z-index: 2;
            height: 45px;
            background-color: white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        `}
`;

const breadcrumbsNav = css`
    position: relative;
`;

const breadcrumbsList = (theme: Theme) => css`
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    > li {
        color: ${theme.colors.gray};
        fill: ${theme.colors.gray};
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;

        > a {
            color: inherit;
            fill: ${theme.colors.gray};
        }

        &:last-child {
            color: ${theme.colors.gray_light};
            fill: ${theme.colors.gray_light};

            > a {
                color: inherit;
                fill: ${theme.colors.gray_light};
            }

            > i {
                visibility: hidden;
                padding: 0;
            }
        }
    }
`;

const hideText = css`
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
`;

const prMd = css`
    padding-right: 1rem;
`;

const bgOverlay = css`
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
    left: 0;
    position: absolute;
    top: 0;
    width: 4rem;
    bottom: 0;
    display: block;
`;
