import {useSelector} from "react-redux";

import {IStore} from "../../../../../app/reducers/hybrid_reducer";
import {LocationTypes} from "../../../../../app/utils/get_location_from_path";
import {OfferDealType, OfferTypePL} from "../../../../utils/constants_offer";
import {firstLetterUppercase} from "../../../../utils/investment_utils";
import {getOfferListUrl} from "../../../url_utils/get_offer_list_url";
import {UrlTagSlug} from "../../../url_utils/tags";

export const FindAndLive = (props: {
    variant: number;
    locationDeclination?: (string | undefined)[] | string | undefined;
    locationName?: string;
    locationSlug: string | undefined;
    locationType: LocationTypes | undefined;
    hasPrimaryOffers?: boolean;
}) => {
    const individualOffersCount = useSelector((store: IStore) => store.offerList.offers.meta?.total_individual_offers);

    const {variant, locationDeclination, locationName, locationSlug, locationType} = props;

    const locatitionSlugWithSubLocations = locationSlug?.split("/");

    const commonStringOne = "znajdź i zamieszkaj";
    const commonStringTwo = "w wymarzonym M";
    const commonStringFour = "we własnym M";
    const commonStringThree = (dealType?: boolean) => `dzięki katalogowi ofert nieruchomości ${(dealType && "na wynajem") || ""} gethome.pl.`;
    const secondaryMarketLinkText = `mieszkania bezpośrednio ${locationName}`;
    const primaryMarketLinkText = `nowe mieszkania ${locationName}`;

    if (variant === 1) {
        return (
            <p>
                {`Chcesz mieszkać w jednej z największych metropolii? A może marzysz o życiu w mieście 15-minutowym? A może szukasz idealnego kompromisu?`}{" "}
                <strong> {firstLetterUppercase(commonStringOne)}</strong> {`${commonStringTwo} ${commonStringThree(true)}`}
            </p>
        );
    }
    if (variant === 2) {
        return (
            <p>
                {`Już teraz `}
                <strong>{commonStringOne}</strong> {`${commonStringTwo} na terenie ${locationDeclination}!`}
            </p>
        );
    }
    if (variant === 3) {
        return (
            <>
                <p>
                    Dzięki filtrom znajdziesz zarówno interesujące{" "}
                    {props.hasPrimaryOffers ? (
                        <a
                            href={getOfferListUrl(
                                {
                                    offerType: OfferTypePL.apartment,
                                    type: OfferDealType.SELL,
                                    location: locatitionSlugWithSubLocations?.[0],
                                    subLocation: locatitionSlugWithSubLocations?.[1],
                                    subSubLocation: locatitionSlugWithSubLocations?.[2],
                                    tag: UrlTagSlug.PRIMARY_MARKET
                                },
                                locationType
                            )}
                            title={primaryMarketLinkText}
                        >
                            {primaryMarketLinkText}
                        </a>
                    ) : (
                        primaryMarketLinkText
                    )}
                    , jak i{" "}
                    {locationType === LocationTypes.CITY && individualOffersCount > 0 ? (
                        <a
                            href={getOfferListUrl(
                                {
                                    offerType: OfferTypePL.apartment,
                                    type: OfferDealType.SELL,
                                    location: locatitionSlugWithSubLocations?.[0],
                                    subLocation: locatitionSlugWithSubLocations?.[1],
                                    subSubLocation: locatitionSlugWithSubLocations?.[2],
                                    tag: UrlTagSlug.INDIVIDUAL_OFFERS
                                },
                                locationType
                            )}
                            title={secondaryMarketLinkText}
                        >
                            {secondaryMarketLinkText}
                        </a>
                    ) : (
                        secondaryMarketLinkText
                    )}
                    .
                </p>
                <p>
                    Niezależnie czego szukasz, <strong>znajdź i zamieszkaj</strong> we własnym M dzięki gethome.pl.
                </p>
            </>
        );
    }
    if (variant === 4) {
        return (
            <p>
                <strong>{firstLetterUppercase(commonStringOne)}</strong> {`${commonStringFour} ${commonStringThree()}`}
            </p>
        );
    }
    return null;
};
