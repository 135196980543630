import * as React from "react";
import {useRouteMatch} from "react-router";
import {css} from "@linaria/core";
import {appPath} from "@web2/gh_routes";

import {ExpansionPanelReadMore} from "../../../app/components/ExpansionPanelReadMore";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {IOfferType, OfferDealType, OfferTypeEN, OfferTypePL} from "../../utils/constants_offer";
import {getRecommendedUrl, locationOfferCount, locationToParams, translateOfferType} from "../../utils/utils";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {UrlTagSlug} from "../url_utils/tags";
import {wrapper} from "./atoms/atoms";
import {OfferListLocationsItem} from "./OfferListLocationsItem";

interface IProps {
    recommended: ILocation[];
    dealType: OfferDealType | undefined;
    offerType: TypeSearchFilterOptions;
    location: {
        location_type: LocationTypes;
    } | null;
}

export const OfferListLocations = (props: IProps) => {
    const offerTypeUrl = translateOfferType(props.offerType);
    const offerTypeEN = translateOfferType(props.offerType, OfferTypeEN) as IOfferType;
    const offerTypePL = translateOfferType(props.offerType, OfferTypePL);

    const foundTag = useRouteMatch<{tag?: UrlTagSlug}>(appPath.searchResult.universalTag)?.params.tag;

    let tag: UrlTagSlug | undefined;
    if (foundTag === UrlTagSlug.STUDIO || foundTag === UrlTagSlug.TWO_ROOM) {
        tag = getUrlTagDataBySlug(foundTag)?.tag;
    }

    const url = (location: ILocation) => getRecommendedUrl(props.dealType, offerTypeUrl, locationToParams(location), tag);

    const recommendedList = (recommendedItems: ILocation[]) => {
        return (
            <ul className={list}>
                {recommendedItems.map((item) => {
                    const count = locationOfferCount(offerTypeEN, props.dealType, tag)(item);
                    if (count === 0) {
                        return;
                    }
                    return (
                        <OfferListLocationsItem
                            key={item.id}
                            name={item.name}
                            number={count}
                            url={url(item)}
                            offerType={offerTypePL}
                            dealType={props.dealType}
                            tag={tag}
                        />
                    );
                })}
            </ul>
        );
    };

    const hasEstate = props.recommended.some((location) => location.location_type === LocationTypes.ESTATE);

    const getRecommendedLocationHeading = (location: LocationTypes | null): string => {
        switch (location) {
            case LocationTypes.VOIVODESHIP:
            case LocationTypes.COUNTY:
            case LocationTypes.TOWN:
            case LocationTypes.DISTRICT:
                if (hasEstate) {
                    return "Oferty w okolicy";
                }
                return "Polecane lokalizacje";

            case LocationTypes.ESTATE:
            case LocationTypes.CITY:
                if (hasDistrict) {
                    return "Polecane lokalizacje";
                }
                return "Oferty w okolicy";

            default:
                return "Polecane lokalizacje";
        }
    };
    const hasDistrict = props.recommended.some((location) => location.location_type === LocationTypes.DISTRICT);
    const hasCityOrTown = props.recommended.some((location) => location.location_type === LocationTypes.CITY || location.location_type === LocationTypes.TOWN);

    let recommendedItems = props.recommended;
    let recommendedItemsRest: ILocation[] = [];

    if (props.location?.location_type === LocationTypes.CITY) {
        recommendedItems = props.recommended.filter((item) => item.location_type === LocationTypes.DISTRICT);
        recommendedItemsRest = props.recommended.filter((item) => item.location_type !== LocationTypes.DISTRICT);
    }

    if (props.location?.location_type === LocationTypes.DISTRICT || props.location?.location_type === LocationTypes.ESTATE) {
        recommendedItems = props.recommended.filter((item) => item.location_type === LocationTypes.DISTRICT);
        recommendedItemsRest = props.recommended.filter((item) => item.location_type === LocationTypes.ESTATE);
    }

    return (
        <div className={wrapper}>
            {(props.location?.location_type === LocationTypes.CITY && hasDistrict && hasCityOrTown) ||
            ((props.location?.location_type === LocationTypes.DISTRICT || props.location?.location_type === LocationTypes.ESTATE) &&
                hasDistrict &&
                hasEstate) ? (
                <>
                    <h3 className={heading}>Oferty w okolicy</h3>

                    <ExpansionPanelReadMore
                        descriptionJsx={recommendedList(recommendedItemsRest)}
                        lessCopy="Mniej"
                        moreCopy="Więcej"
                        expandLinkSide="left"
                        minHeight={95}
                    />

                    <h3 className={heading}>Polecane lokalizacje</h3>

                    <ExpansionPanelReadMore
                        descriptionJsx={recommendedList(recommendedItems)}
                        lessCopy="Mniej"
                        moreCopy="Więcej"
                        expandLinkSide="left"
                        minHeight={95}
                    />
                </>
            ) : (
                <>
                    <h3 className={heading}>{getRecommendedLocationHeading(props.location?.location_type || null)}</h3>

                    <ExpansionPanelReadMore
                        descriptionJsx={recommendedList(props.recommended)}
                        lessCopy="Mniej"
                        moreCopy="Więcej"
                        expandLinkSide="left"
                        minHeight={95}
                    />
                </>
            )}
        </div>
    );
};

/**
 * Styles
 */

const heading = css`
    color: ${getThemeVariable("colors-brand_primary")};
`;

const list = css`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style-type: none;
`;
