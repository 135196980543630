import {Dispatch} from "redux";
import {IArticleDetailAndListParams} from "@web2/gh_routes";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {IFetchAction, IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {getIsCategorySlug} from "../utils/get_is_category_slug";
import {IArticleCategoriesListResult} from "./fetch_article_category_list_at_route";

/**
 * Selects which actions set should be triggered on this path. If slug matches any of the known categories - return category list actions,
 * otherwise we should return article detail related actions.
 * @param fetchActions
 */
export const selectArticlePathActions =
    (fetchActions: {articleDetailActions: IFetchAction<IStore>; articleCategoryActions: IFetchAction<IStore>}) =>
    (
        services: Partial<IServices>,
        route: IRouteState<IArticleDetailAndListParams>,
        _prevRoute: IPrevRouteState | null,
        prevActionResult: IArticleCategoriesListResult
    ) =>
    async (dispatch: Dispatch) => {
        const isCategorySlug = getIsCategorySlug(route.params.slug, prevActionResult.categories);
        const selectedActions = isCategorySlug ? fetchActions.articleCategoryActions : fetchActions.articleDetailActions;

        return Promise.resolve(dispatch(selectedActions(services, route, _prevRoute, prevActionResult)));
    };
