import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchFirstLevelTypes, fetchSiteMapTypes} from "../actions/fetchSiteMapsAtRoute";

export interface IItem {
    title: string;
    url: string;
    count?: number;
}
export interface ISection {
    section: string;
    items: IItem[];
}

export interface ISiteMapStore {
    siteMap: ISection[];
    fistLevel: boolean;
}

const siteMapReducerObj: Record<keyof ISiteMapStore, Reducer<any, any>> = {
    siteMap: reduceResponse(fetchSiteMapTypes),
    fistLevel: reduceResponse(fetchFirstLevelTypes)
};

export const siteMapReducer = combineReducers(siteMapReducerObj);
