import {getLocationFromPath, LocationTypes} from "../../../app/utils/get_location_from_path";
import {OfferDealType} from "../../../offer/utils/constants_offer";
import {ViewType} from "../../view_type/view_type";
import {GtmContactType} from "../utils/gtm_contact_type";
import {getGtmSource, GtmSource} from "../utils/gtm_source";

export interface IGetGtmDimensionsOffer {
    agency?: {
        name: string;
        status: number;
    } | null;
    agent?: {
        name: string;
        last_name?: string;
    } | null;
    deal_type: OfferDealType;
    property: {
        distance_from_region?: {
            distance?: number;
        };
        floor?: number | null;
        location?: {
            path?: {
                name?: string;
                type: string;
            }[];
        } | null;
        rooms?: number | null;
        room_number?: number | null;
        size: number | null;
    };
    investment?: {
        developer?: {
            name: string;
        } | null;
        name: string;
    } | null;
}

export interface IGtmDimensions {
    dimension1?: string; // Pole dynamiczne. Nazwa województwa
    dimension2?: string; // Pole dynamiczne. Nazwa miejscowości lub miasta
    dimension3?: string; // Pole dynamiczne. Dzielnica
    dimension4?: string; // Typ oferty. Możliwa wartość: 'sprzedaż' lub 'wynajem'
    dimension5?: string; // Powierzchnia nieruchomości, np. '42.50'
    dimension6?: string; // Liczba pokojów w nieruchomości, np. '3'
    dimension7: string; // Nazwa biura pośrednictwa (bez nazwiska agenta)
    dimension8: string; // Nazwa pośrednika (imię i nazwisko)
    dimension9?: string; // Odległość nieruchomości od centrum w km, np. '12.9'
    dimension10?: string; // Piętro. Dla parteru wartość '0'
    dimension11: string; // Możliwa wartość: 'telefon', 'formularz' - zależnie od wykonanej akcji, jeżeli nie możemy tego określić = 'na'
    dimension13: string; // Miejsce, z którego został wypełniony formularz lub odsłonięty / kliknięty numer telefonu. Możliwe wartości: sidebar, galeria, na dole, zapytaj o ofertę, zapytaj o cenę, mobile floating,, jeżeli nie możemy tego określić = 'na'
    dimension14: string; // Nazwa dewelopera. Tylko dla rynku pierwotnego.
    dimension15: string; // Nazwa projektu. Tylko dla rynku pierwotnego.
    dimension20: string; // status agencji (Premium -3, Standard - 2, Low - 1)
}

export const calculateGtmOfferDimensions = (contactType: GtmContactType, gtmSource: GtmSource, offer?: IGetGtmDimensionsOffer): IGtmDimensions => {
    const cityOrTown =
        getLocationFromPath(offer?.property.location?.path, LocationTypes.CITY)?.name ||
        getLocationFromPath(offer?.property.location?.path, LocationTypes.TOWN)?.name;

    return {
        dimension1: getLocationFromPath(offer?.property.location?.path, LocationTypes.VOIVODESHIP)?.name || "na",
        dimension2: cityOrTown || "na",
        dimension3: getLocationFromPath(offer?.property.location?.path, LocationTypes.DISTRICT)?.name || "na",
        dimension4: offer?.deal_type === OfferDealType.SELL ? "sprzedaż" : offer?.deal_type === OfferDealType.RENT ? "wynajem" : "na",
        dimension5: offer?.property.size?.toString() || "na",
        dimension6: offer?.property.rooms ? offer?.property.rooms?.toString() : offer?.property.room_number ? offer?.property.room_number?.toString() : "na",
        dimension7: !offer?.investment?.developer?.name && offer?.agency?.name ? offer?.agency?.name : "na",
        dimension8: (offer?.agent && `${offer.agent.name} ${offer.agent.last_name}`) || "na",
        dimension9: offer?.property.distance_from_region?.distance?.toString() || "na",
        dimension10: offer?.property.floor?.toString() || "na",
        dimension11: contactType,
        dimension13: getGtmSource(gtmSource),
        dimension14: offer?.investment?.developer?.name || "na",
        dimension15: offer?.investment?.name || "na",
        dimension20: offer?.agency?.status?.toString() || "0"
    };
};

// Without offer:

export const calculateGtmDimensionsWithoutOffer = (props: {
    agent?: {name: string; last_name?: string} | null;
    agency?: {name: string; status: number} | null;
    investment?: {name: string; developer?: {name: string} | null} | null;
    contactType: GtmContactType;
    gtmSource: GtmSource;
    viewType: ViewType | null;
}): IGtmDimensions => {
    return {
        dimension7: props.agency?.name || "na",
        dimension8: props.agent ? `${props.agent.name} ${props.agent.last_name}` : "na",
        dimension11: props.contactType,
        dimension13: getGtmSource(props.gtmSource),
        dimension14:
            props.viewType === ViewType.DEVELOPER && props.agency
                ? props.agency.name
                : props.viewType === ViewType.INVESTMENT && props.investment?.developer?.name
                  ? props.investment.developer.name
                  : "na",
        dimension15: props.investment?.name || "na",
        dimension20: props.agency?.status?.toString() || "na"
    };
};
