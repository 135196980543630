import {searchFormFields, SearchFormValuesType} from "./form_types";
import {parseListedQueryValuesToString} from "./from_query_values";
import {toQueryValuesWithSearch} from "./to_query_values_with_search";

export const getStringQueryValuesFromFormValues = (formValues: SearchFormValuesType): Record<string, string | number> => {
    // handle special case for `search` field
    const queryValues = toQueryValuesWithSearch<typeof searchFormFields>(formValues);
    // change values to strings
    return parseListedQueryValuesToString(queryValues);
};
