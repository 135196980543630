import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const BusIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "3.14mm"} height={props.height || "3.73mm"} viewBox="0 0 8.91 10.58">
            <path
                d="M4.46,0C2,0,0,.28,0,2.23V7.8A1.64,1.64,0,0,0,.56,9v1a.55.55,0,0,0,.55.55h.56A.56.56,0,0,0,2.23,10V9.47H6.68V10a.56.56,0,0,0,.56.55H7.8A.55.55,0,0,0,8.35,10V9A1.64,1.64,0,0,0,8.91,7.8V2.23C8.91.28,6.92,0,4.46,0ZM2,8.35a.84.84,0,0,1-.84-.83A.85.85,0,0,1,2,6.68a.84.84,0,0,1,.83.84A.83.83,0,0,1,2,8.35Zm5,0a.83.83,0,0,1-.83-.83A.84.84,0,0,1,7,6.68a.85.85,0,0,1,.84.84A.84.84,0,0,1,7,8.35ZM7.8,5H1.11V2.23H7.8Z"
                fill={props.mainColor || "#fff"}
            />
        </svg>
    );
};
