import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const MapIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "4.23mm"} height={props.height || "4.23mm"} viewBox="0 0 12 12">
            <polygon points="4 9.26 8 10.67 8 2.74 4 1.33 4 9.26" fill="none" />
            <path
                d="M11.67,0l-.11,0L8,1.4,4,0,.24,1.27A.32.32,0,0,0,0,1.59V11.67A.33.33,0,0,0,.33,12l.11,0L4,10.6,8,12l3.76-1.27a.32.32,0,0,0,.24-.32V.33A.33.33,0,0,0,11.67,0ZM8,10.67,4,9.26V1.33L8,2.74Z"
                fill={props.mainColor || "#fff"}
            />
        </svg>
    );
};
