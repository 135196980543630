import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const PromoIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "20.11"} height={props.height || "20.1"} viewBox="0 0 20.11 20.1">
            <path d="M12.85,12.27A1.11,1.11,0,1,0,14,13.37,1.11,1.11,0,0,0,12.85,12.27Z" fill={props.mainColor || "#5e35b1"} />
            <path d="M7.32,7.84A1.11,1.11,0,1,0,6.21,6.73,1.11,1.11,0,0,0,7.32,7.84Z" fill={props.mainColor || "#5e35b1"} />
            <path
                d="M10.05,0A10.05,10.05,0,1,0,20.11,10.05,10.05,10.05,0,0,0,10.05,0ZM7.32,4.52A2.22,2.22,0,1,1,5.1,6.73,2.21,2.21,0,0,1,7.32,4.52ZM6,14.93a.56.56,0,0,1-.79-.78l8.61-8.61a.56.56,0,0,1,.78,0,.54.54,0,0,1,0,.78Zm6.86.66a2.22,2.22,0,1,1,2.21-2.22A2.22,2.22,0,0,1,12.85,15.59Z"
                fill={props.mainColor || "#5e35b1"}
            />
        </svg>
    );
};
