import * as React from "react";
import {dateTimeFormat, formatPL, pluralize} from "@web2/string_utils";
import {numberWithDelimiter} from "@web2/string_utils";

import {IInvestmentParkingEntity, IInvestmentSellStatusType} from "../../app/interfaces/response/investment";

//pluralizers

export const offerPlural = pluralize(["oferta", "oferty", "ofert"]);

export const buildingCountPlural = pluralize(["budynek wielorodzinny", "budynki wielorodzinne", "budynków wielorodzinnych"]);

export const aboveFloorsPlural = pluralize(["kondygnacja naziemna", "kondygnacje naziemne", "kondygnacji naziemnych"]);

export const belowFloorsPlural = pluralize(["kondygnacja podziemna", "kondygnacje podziemne", "kondygnacji podziemnych"]);

export const housePlural = pluralize(["dom", "domy", "domów"]);

export const aboveFloorsDeclensionWhatPlural = pluralize(["kondygnację naziemną", "kondygnacje naziemne", "kondygnacji naziemnych"]);

export const apartmentsPlural = pluralize(["lokal mieszkalny", "lokale mieszkalne", "lokali mieszkalnych"]);

export const parkingPlural = pluralize(["parking", "parkingi", "parkingi"]);

//helpers
export const investmentRealization = (constructionDate: string, availableDate: string) => {
    const startDate = formatPL(new Date(constructionDate), dateTimeFormat.quarter);
    const finishDate = formatPL(new Date(availableDate), dateTimeFormat.quarter);

    return (
        <>
            Rozpoczęcie budowy w {startDate} roku. <br />
            Data oddania do użytkowania {finishDate} roku.
        </>
    );
};

export const apartmentFloorsCopy = (aboveFloors: number, underFloors: number) => {
    return `${aboveFloors} ${aboveFloorsPlural(aboveFloors)} wraz z parterem oraz ${
        underFloors > 0 ? `${underFloors} ${belowFloorsPlural(underFloors)}` : "nie posiada kondygnacji podziemnych"
    }`;
};

export const houseFloorsCopy = (aboveFloors: number) => {
    return `Domy posiadają ${aboveFloors} ${aboveFloorsDeclensionWhatPlural(aboveFloors)} wraz z parterem`;
};

export const storageAvailability = (isAvailable: boolean | null) => {
    if (isAvailable === null) {
        return "Informacje u dewelopera";
    }

    if (isAvailable) {
        return "Posiada";
    }

    return "Nie przewidziano";
};

export const apartmentParking = (parkingList?: IInvestmentParkingEntity[]): string | JSX.Element => {
    if (parkingList && parkingList.length > 0) {
        return (
            <>
                W inwestycji przewidziano {parkingPlural(parkingList.length)}:
                <ul>
                    {parkingList.map((parking, i) => (
                        <li key={i}>
                            {parking.name}
                            {parking.description ? `: ${parking.description}` : ""}
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    return "W inwestycji nie przewidziano parkingów";
};

export const firstLetterUppercase = (value: string): string => {
    return `${value.charAt(0).toUpperCase()}${value.substring(1)}`;
};

export const getIsLimitedInvestment = (investment?: null | {limited_presentation: boolean; sell_status: IInvestmentSellStatusType}): boolean => {
    if (!!investment) {
        return (
            investment.limited_presentation ||
            investment.sell_status === IInvestmentSellStatusType.PLANNED ||
            investment.sell_status === IInvestmentSellStatusType.SOLD
        );
    }

    return false;
};

export const roomsSizeRange = (min: number, max: number) => {
    if (min === max) {
        return `${min} m2`;
    }

    return `od ${min} do ${max} m2`;
};

export const investmentPriceRange = (min: number, max: number, sqmMin: number | null, sqmMax: number | null) => {
    if (!(min || max)) {
        return "Informacja u dewelopera";
    }

    const sqmPrice = sqmMin && sqmMax ? `, koszt metra kwadratowego wynosi od ${numberWithDelimiter(sqmMin)} do ${numberWithDelimiter(sqmMax)} zł` : "";

    return `Kształtują się w zakresie od ${numberWithDelimiter(min)} do ${numberWithDelimiter(max)} zł${sqmPrice}`;
};

export const roomsNumberRange = (min: number, max: number): string => {
    if (min === max) {
        return `${min}`;
    }

    return `${min} - ${max}`;
};

export const rentPriceRange = (min: number | null, max: number | null) => {
    if (min === null || max === null) {
        return "Informacja u dewelopera";
    }

    if (min === 0 || max === 0) {
        return "W inwestycji znajdują się mieszkania bezczynszowe";
    }

    if (min === max) {
        return `${min} zł/m2`;
    }

    return `od ${min} do ${max} zł/m2`;
};
