import {apiLink} from "../../routes/api_link";
import {IServices} from "../../services/IServices";
import {appendQueryString} from "../../utils/append_query_string";
import {enableAuthorization, isBrowser} from "../../utils/read_environment_variables";
import {getRequest} from "../../utils/request_response_utils/request";

interface ISyncUserSessionResponse {
    redirect_required: boolean;
    redirect_url: string;
}

/**
 * This clientside function fetches Konto Mieszkaniowe cookie when user has no cookies stored on his machine.
 * Unfortunately we can't access and check usersessionid cookie directly in client, probably due to it being a HttpOnly cookie.
 *
 * We assume that a returning user already has a csrftoken cookie saved.
 *
 * When HTML is returned from cache this will be the first fetch to user-sync API and should set the usersessionid cookie.
 */
export const getSyncUserSessionCookieOnClient = () => {
    if (!isBrowser) {
        throw new Error("syncUserSessionCookieOnClient should not be used in server environment");
    }

    if (!document.cookie) {
        syncUserSession({}, "");
    }
};

export const syncUserSession = async (services: Partial<IServices>, next: string): Promise<string | null> => {
    return getRequest(services, appendQueryString(apiLink.userApi.session.sync(), {next})).then((response: ISyncUserSessionResponse) => {
        if (!enableAuthorization) {
            return null;
        }

        return response.redirect_required ? response.redirect_url : null;
    });
};
