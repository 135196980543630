import {isFinite} from "lodash";
import {capitalize} from "@web2/nodash";

import {retrieveIDFromSlug} from "../../../app/utils/id_from_slug";
import {getOfferTitleWithLocation} from "../../../offer/utils/utils";
import {getGtmMarketType} from "../../view_type/utils/get_market_type";
import {getViewTypeName, ViewType} from "../../view_type/view_type";
import {calculateGtmDimensionsWithoutOffer, calculateGtmOfferDimensions, IGtmDimensions} from "../ecommerce_events/calculate_gtm_offer_dimensions";
import {IGtmOffer, IGtmProductData} from "../ecommerce_events/gtm_event_typings";
import {getGtmInquiryBrand} from "./get_gtm_inquiry_brand";
import {getGtmNameAndId} from "./get_gtm_offer_name_and_id";
import {GtmContactType} from "./gtm_contact_type";
import {GtmSource} from "./gtm_source";

export interface IGtmProduct extends IGtmDimensions {
    name: string;
    id: string;
    price: string; // "1.00"
    brand: string;
    category: string;
    variant: string;
    quantity: number; // 1
    list?: string;
    position?: number | "na";
}

interface IGtmPropsWithOfferData {
    offer: IGtmOffer;
    contactType?: GtmContactType;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    listPosition?: number | "na";
    listPrefix?: string;
    listSuffix?: string;
}

export const getGtmProductWithOffer = (props: IGtmPropsWithOfferData): IGtmProduct => {
    const list = isFinite(props.listPosition)
        ? {
              list: `${props.listPrefix}_${props.listSuffix}`,
              position: props.listPosition
          }
        : {};
    return {
        ...calculateGtmOfferDimensions(props.contactType ? props.contactType : GtmContactType.UNKNOWN, props.gtmSource, props.offer),
        name: capitalize(getOfferTitleWithLocation(props.offer)),
        id: props.offer ? retrieveIDFromSlug(props.offer.slug) : "na",
        price: "1.00",
        brand: getGtmInquiryBrand({offer: props.offer, viewType: props.viewType}) || "na", // Możliwe wartości: 'dom', 'mieszkanie', 'działka', 'deweloper', 'inwestycja', 'agencja', 'agent'
        category: (props.offer && getGtmMarketType(props.offer.market_type)) || "na", // market type
        variant: props.viewType ? getViewTypeName(props.viewType) : "na",
        quantity: 1,
        ...list
    };
};

// Without offer

interface IGtmPropsWithoutOfferData extends IGtmProductData {
    contactType: GtmContactType;
    gtmSource: GtmSource;
    viewType: ViewType | null;
}

export const getGtmProductWithoutOffer = (props: IGtmPropsWithoutOfferData) => {
    return {
        ...getGtmNameAndId(props),
        ...calculateGtmDimensionsWithoutOffer(props),
        price: "1.00",
        brand: getGtmInquiryBrand({viewType: props.viewType}) || "na", // Możliwe wartości: 'deweloper', 'inwestycja', 'agencja', 'agent'
        category: props.investment ? "pierwotny" : "na", // market type
        variant: props.viewType ? getViewTypeName(props.viewType) : "na",
        quantity: 1
    };
};
