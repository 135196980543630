import * as React from "react";

import {RoundDirection, roundToNumber} from "../../../../app/utils/number";

export function objectMap(object: IStringIndex<any>, mapFn: any) {
    return Object.keys(object).reduce((result: IStringIndex<any>, key) => {
        result[key] = mapFn(object[key]);

        return result;
    }, {});
}

interface IStringIndex<T = string> {
    [key: string]: T;
}

export const shortPrice = (value: number, roundDirection: RoundDirection): string => {
    if (value >= 1000000) {
        return number2string(roundToNumber(value, 100000, roundDirection), 1000000, "mln", 1);
    }
    if (value >= 1000) {
        return number2string(roundToNumber(value, 1000, roundDirection), 1000, "tys");
    }
    return number2string(roundToNumber(value, 1000, roundDirection), 1, "");
};

export const number2string = (value: number, denominator: number, suffix: string, radix = 0): string =>
    `${(value / denominator).toFixed(radix)}${suffix ? ` ${suffix}` : ``}`;

export interface IRheostatRequiredProps {
    max: number;
    min: number;
    values: number[];
}

export interface IRheostatLabel {
    labelFormatter(value: React.ReactText, roundDirection: RoundDirection): string;
}

export interface IAlgorithm {
    getValue(value: number, min: number, max: number): number;
    getPosition(value: number, min: number, max: number): number;
}

export type TOrientation = "horizontal" | "vertical";

export const PERCENT_EMPTY = 0;
export const PERCENT_FULL = 100;
export const HORIZONTAL = "horizontal";
export const VERTICAL = "vertical";

export function getHandleFor(event: React.SyntheticEvent) {
    return Number(event.currentTarget.getAttribute("data-handle-key"));
}

export function stopPropagation(event: React.SyntheticEvent | Event) {
    event.stopPropagation();
}

export function killEvent(event: React.SyntheticEvent | Event) {
    stopPropagation(event);
    event.preventDefault();
}
