import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const EditIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "5.52mm"} height={props.width || "5.52mm"} viewBox="0 0 15.64 15.64">
            <path
                d="M.76,15.64H4.67a.74.74,0,0,0,.54-.23l8.8-9a.77.77,0,0,0,.22-.54A.75.75,0,0,0,14,5.28L10.36,1.65a.77.77,0,0,0-1.07,0l-9.06,9a.75.75,0,0,0-.23.55v3.65A.77.77,0,0,0,.76,15.64Zm.76-4.1L9.83,3.29l2.56,2.55-8,8.25H1.52Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M11.77,0H10.7a.77.77,0,0,0,0,1.09l3.63,3.61a.75.75,0,0,0,.54.22.77.77,0,0,0,.53-1.32Z" fill={props.mainColor || "#37474f"} />
        </svg>
    );
};
