export interface ITrackedSiteData {
    site: string;
}

export const getTrackedMobileSiteData = (): ITrackedSiteData => ({
    site: "GetHome Mobile"
});

export const getTrackedDesktopSiteData = (): ITrackedSiteData => ({
    site: "GetHome"
});
