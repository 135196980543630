import {styled} from "@linaria/react";

import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";

interface FilterLabelProps {
    isActive: boolean;
    hasValues: boolean;
    showOnMobile?: boolean;
}

export const FilterLabel = styled.div<FilterLabelProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 140px;
    height: 38px;
    padding: 0.9rem 1rem;
    border: ${(props) =>
        props.isActive || props.hasValues ? `1px solid ${getThemeVariable("colors-brand_primary")}` : `1px solid ${getThemeVariable("colors-gray_brighter")}`};
    border-radius: ${getThemeVariable("other-border_radius")};
    cursor: pointer;
    font-size: 1.3rem;
    color: ${(props) => (props.hasValues ? getThemeVariable("colors-brand_primary") : getThemeVariable("colors-gray_dark"))};
    background-color: ${(props) => (props.isActive || props.hasValues ? getThemeVariable("colors-filter_active_bg") : "unset")};
    transition:
        background-color 100ms ease-out,
        opacity 100ms ease-out;

    &:focus {
        outline: none;
    }

    &:hover {
        border: 1px solid ${getThemeVariable("colors-brand_primary")};
        background-color: ${getThemeVariable("colors-filter_active_hover_bg")};
    }

    @media (max-width: calc(${getThemeBreakpointCorrect().screen_md} - 1px)) {
        display: ${(props) => (props.showOnMobile ? "flex" : "none")};
    }
`;
