export const phoneNumberRevealGTMEvent = () => {
    if ((window as any).dataLayer) {
        return (window as any).dataLayer.push({event: "phone_number_reveal"});
    }
    return null;
};

export const phoneNumberCallGTMEvent = () => {
    if ((window as any).dataLayer) {
        return (window as any).dataLayer.push({event: "phone_number_call"});
    }
    return null;
};
