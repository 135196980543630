import {GeoJSON} from "geojson";
import {assign, each, isEmpty, map, reduce} from "lodash";

import {IMapInternalData} from "./GoogleMap";

type gMapPolygon = google.maps.Polygon;
type gMapPolygonOpts = google.maps.PolygonOptions;
type gMapInfoWindowOpts = google.maps.InfoWindowOptions;

export interface PolygonOptions {
    /** Indicates whether this Polygon handles mouse events. Defaults to true. */
    clickable?: boolean;
    /**
     * If set to true, the user can drag this shape over the map.
     * The geodesic property defines the mode of dragging. Defaults to false.
     */
    draggable?: boolean;
    /**
     * If set to true, the user can edit this shape by dragging the control points
     * shown at the vertices and on each segment. Defaults to false.
     */
    editable?: boolean;
    /** The fill color. All CSS3 colors are supported except for extended named colors. */
    fillColor?: string;
    /** The fill opacity between 0.0 and 1.0 */
    fillOpacity?: number;
    /**
     * When true, edges of the polygon are interpreted as geodesic and will follow
     * the curvature of the Earth. When false, edges of the polygon are rendered as
     * straight lines in screen space. Note that the shape of a geodesic polygon may
     * appear to change when dragged, as the dimensions are maintained relative to
     * the surface of the earth. Defaults to false.
     */
    geodesic?: boolean;
    /**
     * The stroke color.
     * All CSS3 colors are supported except for extended named colors.
     */
    strokeColor?: string;
    /** The stroke opacity between 0.0 and 1.0 */
    strokeOpacity?: number;
    /**
     * The stroke position. Defaults to CENTER.
     * This property is not supported on Internet Explorer 8 and earlier.
     */
    strokePosition?: google.maps.StrokePosition;
    /** The stroke width in pixels. */
    strokeWeight?: number;
    visible?: boolean;
    zIndex?: number;
}

export interface PolygonDefinition {
    /** Polygon points coordinates in form of [[-12.040397656836609,-77.03373871559225, ...]]. */
    coords: GeoJSON.Position[][];
    infoWindow?: gMapInfoWindowOpts;
    options?: PolygonOptions;
}

export interface PolygonGroupDefinition {
    /** List of polygon definitions assigned to particular group. */
    list: PolygonDefinition[];
    /** Options for the whole polygon group. */
    options?: PolygonOptions;
}

/**
 * Updates all polygons on the map from given definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupsDef {Dictionary<PolygonGroupDefinition>} polygon groups definition
 * @returns {Dictionary<gMapPolygon[]>} the state of map's polygons after update
 */
export function updatePolygons(mapData: IMapInternalData, groupsDef: Record<string, PolygonGroupDefinition>): Record<string, gMapPolygon[]> {
    removeAllPolygons(mapData.polygons);

    const iteratee = (acc: Record<string, gMapPolygon[]>, groupDef: PolygonGroupDefinition, groupName: string) => {
        acc[groupName] = updatePolygonGroup(mapData, groupName, groupDef);
        return acc;
    };

    return reduce(groupsDef, iteratee, {} as Record<string, gMapPolygon[]>);
}

/**
 * Updates polygons belonging to particular group, from given group definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the polygon group to update
 * @param groupDef {PolygonGroupDefinition} definition of polygon group to update
 * @returns {gMapPolygon[]} list of updated polygons belonging to particular group
 */
export function updatePolygonGroup(mapData: IMapInternalData, groupName: string, groupDef: PolygonGroupDefinition): gMapPolygon[] {
    return map(groupDef.list, (polygonDef) => addPolygon(mapData, groupName, groupDef, polygonDef));
}

/**
 * Adds polygon to the map assigning it to particular group. Also handles polygon events.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the polygon should be assigned to
 * @param groupDef {PolygonGroupDefinition} definition of group the polygon should be assigned to
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 * @returns {gMapPolygon} newly created polygon instance
 */
export function addPolygon(mapData: IMapInternalData, groupName: string, groupDef: PolygonGroupDefinition, polygonDef: PolygonDefinition): gMapPolygon {
    const polygonOptions = buildPolygonOptions(mapData, groupName, groupDef, polygonDef);
    const polygon = new google.maps.Polygon(polygonOptions);

    addPolygonInfoWindow(mapData, polygon, polygonDef);

    return polygon;
}

/**
 * Adds InfoWindow with defined behavior to given polygon.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param polygon {gMapPolygon} polygon instance to add InfoWindow to
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 */
export function addPolygonInfoWindow(mapData: IMapInternalData, polygon: gMapPolygon, polygonDef: PolygonDefinition): void {
    if (polygonDef.infoWindow && polygonDef.infoWindow.content) {
        const infoWindow = new google.maps.InfoWindow(polygonDef.infoWindow);

        // infoWindow events
        polygon.addListener("mouseover", () => {
            infoWindow.open(mapData.map, polygon);
        });
        polygon.addListener("mouseout", () => {
            infoWindow.close();
        });
    }
}

/**
 * Builds and returns options for particular polygon. Options can be assigned to single polygon as well as for
 * the whole polygon group. Options for single polygons have bigger precedence.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the polygon is assigned to
 * @param groupDef {PolygonGroupDefinition} definition of group the polygon is assigned to, with group options
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 * @returns {gMapPolygonOpts} merged polygon options
 */
export function buildPolygonOptions(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: PolygonGroupDefinition,
    polygonDef: PolygonDefinition
): gMapPolygonOpts {
    const paths: Record<string, number>[] = [];

    for (const coords of polygonDef.coords[0]) {
        const pathCoords = {lat: coords[1], lng: coords[0]};
        paths.push(pathCoords);
    }

    // extend default polygon data
    const defaultOptions = getDefaultPolygonOptions(mapData);
    const groupOptions = getGroupPolygonOptions(groupDef.options);
    const polygonOptions = assign({}, defaultOptions, groupOptions, polygonDef.options, {
        paths: paths
    }) as gMapPolygonOpts;

    return polygonOptions;
}

/**
 * Returns default polygon options.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @returns {gMapPolygonOpts} default polygon options
 */
export function getDefaultPolygonOptions(mapData: IMapInternalData): gMapPolygonOpts {
    const defaultOptions = {
        map: mapData.map,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35
    };

    return defaultOptions;
}

/**
 * Returns options to be applied to whole polygon group.
 * @param groupOptions {PolygonOptions|undefined} options assigned to whole polygon group definition
 * @returns {PolygonOptions|{}}
 */
export function getGroupPolygonOptions(groupOptions: PolygonOptions | undefined): PolygonOptions | {} {
    return groupOptions || {};
}

/**
 * Removes all polygons rendered on the map.
 * @param polygons {Dictionary<gMapPolygon[]> polygons renderes on the map, divided into groups.
 */
export function removeAllPolygons(polygons: Record<string, gMapPolygon[]>): void {
    each(polygons, (polygon, groupName) => {
        removePolygonGroup(polygons, groupName);
    });
}

/**
 * Removes all polygons rendered on the map, belonging to particular group.
 * @param polygons {Dictionary<gMapPolygon[]> polygons renderes on the map, divided into groups.
 * @param groupName {string} name of the group of polygons to remove
 */
export function removePolygonGroup(polygons: Record<string, gMapPolygon[]>, groupName: string): void {
    if (isEmpty(polygons[groupName])) {
        return;
    }

    for (const polygon of polygons[groupName]) {
        polygon.setMap(null);
    }

    polygons[groupName] = []; // necessary to remove all references to polygons
}
