import React from "react";
import {css} from "@emotion/react";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const NoPrint = (props: IProps) => {
    return (
        <div css={noPrint} className={props.className}>
            {props.children}
        </div>
    );
};

const noPrint = css`
    @media print {
        display: none !important;
    }
`;
