import * as React from "react";
import classNames from "classnames";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const LoupeIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <svg
            width={props.size ? props.size : "17"}
            height={props.size ? props.size : "17"}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8544 12.5514C10.3858 12.0828 10.3858 11.323 10.8544 10.8544C11.323 10.3857 12.0828 10.3857 12.5514 10.8544L15.9456 14.2485C16.4142 14.7171 16.4142 15.4769 15.9456 15.9455C15.4769 16.4142 14.7171 16.4142 14.2485 15.9455L10.8544 12.5514Z"
                fill={props.fillColor ? props.fillColor : "#009688"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6.6C0 10.2451 2.95492 13.2 6.6 13.2C10.2451 13.2 13.2 10.2451 13.2 6.6C13.2 2.95492 10.2451 0 6.6 0C2.95492 0 0 2.95492 0 6.6ZM11.2 6.6C11.2 9.14051 9.14051 11.2 6.6 11.2C4.05949 11.2 2 9.14051 2 6.6C2 4.05949 4.05949 2 6.6 2C9.14051 2 11.2 4.05949 11.2 6.6Z"
                fill={props.fillColor ? props.fillColor : "#009688"}
            />
        </svg>
    );
};
