import {FormikProps} from "formik";

export interface IFormFieldProps<TName, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
    onBlur: (e?: React.FocusEvent) => void;
    checked: boolean;
}

/*
 * TODO: This function should return specific set of props based on the type of a field.
 * For example for Checkbox we should return base set of props + `checked` field.
 * There is a chance that we can do this by using union type and enum with field types.
 */

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getFormikFieldProps = <FieldValueType, FormValuesType extends Record<string, any>>(
    props: FormikProps<FormValuesType>,
    fieldName: keyof FormValuesType
): IFormFieldProps<string, FieldValueType> => ({
    error: props.touched[fieldName] && props.errors[fieldName] ? (props.errors[fieldName] as string[]) : undefined,
    value: props.values[fieldName],
    onChange: (name, value) => {
        props.setFieldValue(name, value);
    },
    onBlur: props.handleBlur,
    name: fieldName as string,
    onAfterChange: () => null,
    checked: !!props.values[fieldName]
});
