import React, {useCallback, useRef} from "react";
import ReactSelect, {ActionMeta, SelectInstance} from "react-select";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {find, isArray} from "lodash";
import {FormErrorMessage} from "@web2/form_fields";
import {IFormFieldProps} from "@web2/form2";

import {useSelectTheme} from "./use_select_theme";

export type SelectSize = "lg" | "sm";

export interface SelectOption<TValue = string> {
    label?: string;
    value?: TValue;
}

export interface IPropsSelect extends IFormFieldProps<string, string> {
    label?: string;
    value: string;
    required?: boolean;
    options: SelectOption[];
    clearable?: boolean;
    searchable?: boolean;
    groupClassName?: string;
    className?: string;
    id?: string;
    errorOnBottom?: boolean;
    markable?: boolean;
    refHandler?: (elem: HTMLElement | null) => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    showPlaceholderAsOption?: boolean;
    defaultMenuIsOpen?: boolean;
    placeholder?: string;
    size?: SelectSize;
    error?: string[];
    isMulti?: boolean;
    name: string;
    onAfterChange: (fieldName: string, value: string) => void;
    onChange: (fieldName: string, value: string) => void;
    onBlur?: () => void;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    triggerOnAfterChange?: boolean;
}

export const Select = (props: IPropsSelect) => {
    const ref = useRef<SelectInstance>(null);

    const theme = useTheme();
    const {placeholder = "Wybierz", showPlaceholderAsOption = false} = props;

    const {styles} = useSelectTheme();
    const onChange = useCallback(
        (value: any, actionMeta: ActionMeta<unknown>): void => {
            if (props.onChange) {
                if (value && isArray(value)) {
                    const valueArr: any = value.map((val) => val.value);
                    valueArr.join(",");
                    props.onChange(props.name, valueArr);
                    return;
                }
                if (value && !isArray(value)) {
                    props.onChange(props.name, value.value as string);
                    return;
                }
                props.onChange(props.name, "" as string);
            }
        },
        [props]
    );

    const findOption = useCallback((): undefined | SelectOption | SelectOption[] => {
        if (!props.isMulti) {
            return find(props.options, (o) => o.value === props.value);
        } else {
            const optionsArr: SelectOption[] = [];
            if (props.value && isArray(props.value)) {
                const multiValues = props.value;
                multiValues.forEach((val) => {
                    const option = find(props.options, (o) => o.value === val);
                    if (option) {
                        optionsArr.push(option);
                    }
                });
            }
            return optionsArr;
        }
    }, [props.options, props.name, props.value]);

    return (
        <SelectWrapper error={props.error} errorOnBottom={props.errorOnBottom} ref={props.refHandler} data-testid="select-wrapper">
            {props.label && (
                <Label htmlFor={props.id} data-testid={`select-label-${props.name}`}>
                    {props.label}
                    {props.required && (
                        <span css={required} data-testid={`select-required-asterisk-${props.name}`}>
                            *
                        </span>
                    )}
                </Label>
            )}

            {props.error && !props.errorOnBottom && <FormErrorMessage error={props.error} />}

            <div css={innerWrapperHolder}>
                <ReactSelect
                    ref={ref}
                    defaultMenuIsOpen={props.defaultMenuIsOpen}
                    name={props.name}
                    value={findOption()}
                    onChange={(newValue, actionMeta) => onChange(newValue, actionMeta)}
                    options={showPlaceholderAsOption ? [{value: "", label: placeholder}, ...props.options] : props.options}
                    isClearable={props.clearable}
                    isSearchable={props.searchable}
                    placeholder={placeholder}
                    tabSelectsValue={false}
                    instanceId={props.name}
                    id={props.id}
                    onMenuOpen={props.onMenuOpen}
                    onMenuClose={props.onMenuClose}
                    onBlur={props.onBlur}
                    isMulti={props.isMulti}
                    closeMenuOnSelect={props.closeMenuOnSelect}
                    blurInputOnSelect={props.closeMenuOnSelect}
                    hideSelectedOptions={props.hideSelectedOptions}
                    styles={{
                        ...styles,
                        control: (provided) => ({
                            ...provided,
                            height: props.isMulti ? "auto" : "4.2rem",
                            minHeight: props.isMulti ? "4.2rem" : "unset",
                            border: props.error ? "1px solid " + theme.colors?.brand_danger : "1px solid " + theme.colors?.gray_brighter,
                            boxShadow: props.error ? "inset 0 0 0 1px " + theme.colors?.brand_danger : "unset"
                        })
                    }}
                />
            </div>

            {props.error && props.errorOnBottom && <FormErrorMessage error={props.error} />}
        </SelectWrapper>
    );
};

Select.defaultProps = {
    clearable: false,
    searchable: false,
    ignoreAccents: false,
    markable: false
};

Select.defaultValue = "";

interface ISelectWrapperThemeProps {
    error?: string[] | null;
    errorOnBottom?: boolean;
}

interface ILabelThemeProps {}

export const SelectWrapper = styled.div<ISelectWrapperThemeProps>`
    margin-bottom: ${(props) => props.theme.forms.form_group_margin_bottom};

    ${(props) =>
        props.error &&
        props.errorOnBottom &&
        css`
            margin-bottom: 0;
        `}
`;

const innerWrapperHolder = (theme: Theme) => css`
    height: ${theme.forms.input_height_base ?? "4.2rem"};
`;

export const Label = styled.label<ILabelThemeProps>`
    text-align: left;
    font-size: ${(props) => props.theme.forms.label_font_size};
    width: 100%;
    margin-bottom: ${(props) => props.theme.forms.label_vertical_margin};
    margin-right: ${(props) => props.theme.forms.label_horizontal_margin};
    font-weight: ${(props) => props.theme.forms.label_font_weight};
    color: ${(props) => props.theme.forms.label_color ?? "#909090"};
    line-height: ${(props) => props.theme.fonts.line_height_base};
    display: block;
`;

const required = (theme: Theme) => css`
    color: ${theme.colors.brand_danger};
`;
