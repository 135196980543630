import React, {CSSProperties, FC, ReactNode} from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {cssVariableFactory} from "@web2/linaria_styles";
import {themeGh} from "@web2/themes";

type ButtonType = "button" | "submit" | "reset";
type ButtonSize = "lg" | "sm";
type ButtonVariant = "success" | "danger" | "primary" | "link" | "dark" | "bright" | "white";

const getThemeVariable = cssVariableFactory<typeof themeGh>();

export interface IButtonProps {
    children?: ReactNode;
    type?: ButtonType;
    size?: ButtonSize;
    variant?: ButtonVariant;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    inverted?: boolean | undefined;
    disabled?: boolean;
    dataTestId?: string;
    fullWidth?: boolean;
    icon?: JSX.Element;
    isLoading?: boolean;
    loaderIcon?: JSX.Element;
    style?: CSSProperties;

    //button can be used as="div" or link (pass 'href' prop or specify as="a")
    href?: string;
    target?: string;
    title?: string;
    as?: "a" | "button" | "div";
}

interface IThemeProps {
    size: ButtonSize;
    inverted: boolean | undefined;
    fullWidth?: boolean;
    as?: string;
    href?: string;
    title?: string;
    target?: string;
    icon?: JSX.Element;
    isLoading?: boolean;
    hasChildren?: boolean;
}

export const Button: FC<IButtonProps> = (props: IButtonProps) => {
    return (
        <ButtonBase
            as={props.as || props.href ? "a" : "button"}
            data-testid={props.dataTestId}
            size={props.size as ButtonSize}
            className={cx(
                props.className,
                props.variant === "primary" && button__primary,
                props.variant === "primary" && props.inverted && button__primary__inverted,
                props.variant === "success" && button__success,
                props.variant === "success" && props.inverted && button__success__inverted,
                props.variant === "danger" && button__danger,
                props.variant === "danger" && props.inverted && button__danger__inverted,
                props.variant === "dark" && button__dark,
                props.variant === "dark" && props.inverted && button__dark__inverted,
                props.variant === "bright" && button__bright,
                props.variant === "bright" && props.inverted && button__bright__inverted,
                props.variant === "white" && button__white,
                props.variant === "white" && props.inverted && button__white__inverted,
                props.variant === "link" && button__link
            )}
            onClick={props.onClick}
            inverted={props.inverted}
            disabled={props.disabled || props.isLoading}
            isLoading={props.isLoading}
            fullWidth={props.fullWidth}
            href={props.href}
            target={props.href && props.target}
            title={props.title}
            type={props.type || "button"}
            icon={props.icon}
            style={props.style}
            hasChildren={!!props.children}
        >
            <ButtonContentWrapper isLoading={props.isLoading} fullWidth={props.fullWidth} className={cx(!!props.icon && buttonContentWrapper__isIcon)}>
                {props.icon}
                {props.children}
            </ButtonContentWrapper>

            {props.isLoading && props.loaderIcon && <div className={loaderIconWrapper}>{props.loaderIcon}</div>}
        </ButtonBase>
    );
};

const ButtonBase = styled.button<IThemeProps>`
    // prevent Safari from showing rounded corners and gradient backgrounds.
    -webkit-appearance: none !important;
    position: relative;
    display: inline-flex;
    margin-bottom: 0;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    font-weight: ${getThemeVariable("buttons-btn_font_weight")};
    color: ${(props) => (props.inverted ? getThemeVariable("buttons-btn_default_bg") : getThemeVariable("buttons-btn_default_color"))};
    background-color: ${(props) => (props.inverted ? "transparent" : getThemeVariable("buttons-btn_default_bg"))};
    border-color: ${getThemeVariable("buttons-btn_default_border")};
    user-select: none;
    transition: ${getThemeVariable("buttons-btn_transition")};
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    height: ${(props) =>
        props.size === "lg"
            ? getThemeVariable("buttons-btn_height_large")
            : props.size === "sm"
              ? getThemeVariable("buttons-btn_height_small")
              : getThemeVariable("buttons-btn_height_base")};
    padding: ${(props) =>
        props.size === "lg"
            ? `${getThemeVariable("padding-padding_large_vertical")} ${getThemeVariable("padding-padding_large_horizontal")}`
            : props.size === "sm"
              ? `${getThemeVariable("padding-padding_small_vertical")} ${getThemeVariable("padding-padding_small_horizontal")}`
              : `${getThemeVariable("padding-padding_base_vertical")} ${getThemeVariable("padding-padding_base_horizontal")}`};
    font-size: ${(props) =>
        props.size === "lg"
            ? getThemeVariable("fonts-font_size_large")
            : props.size === "sm"
              ? getThemeVariable("fonts-font_size_small")
              : getThemeVariable("fonts-font_size_base")};
    line-height: ${(props) =>
        props.size === "lg"
            ? getThemeVariable("fonts-line_height_large")
            : props.size === "sm"
              ? getThemeVariable("fonts-line_height_small")
              : getThemeVariable("fonts-line_height_base")};
    border-radius: ${(props) =>
        props.size === "lg"
            ? getThemeVariable("buttons-btn_border_radius_large")
            : props.size === "sm"
              ? getThemeVariable("buttons-btn_border_radius_small")
              : getThemeVariable("buttons-btn_border_radius_base")};

    svg {
        transition: ${getThemeVariable("buttons-btn_transition_icon")};
        fill: ${(props) =>
            props.icon
                ? props.inverted
                    ? getThemeVariable("buttons-btn_default_color_hover")
                    : getThemeVariable("buttons-btn_default_color")
                : "transparent"};
        margin-right: ${(props) => (props.icon && props.hasChildren ? "1rem" : 0)};
    }

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${(props) => (props.inverted ? "transparent" : getThemeVariable("buttons-btn_default_bg_hover"))};
        color: ${(props) => (props.inverted ? getThemeVariable("buttons-btn_default_color_hover") : getThemeVariable("buttons-btn_default_color"))};
        border-color: ${getThemeVariable("buttons-btn_default_border_hover")};
        outline: 0 !important;
        text-decoration: none;
    }

    &.disabled,
    &[disabled] {
        cursor: ${getThemeVariable("buttons-btn_cursor_disabled")};
        opacity: 0.65;

        &:hover,
        &:focus,
        &.focus {
            background-color: ${(props) => (props.inverted ? "transparent" : getThemeVariable("buttons-btn_default_bg"))};
            border-color: ${getThemeVariable("buttons-btn_default_border")};
        }
    }
`;

const button__primary = css`
    color: ${getThemeVariable("buttons-btn_primary_color")};
    background-color: ${getThemeVariable("buttons-btn_primary_bg")};
    border-color: ${getThemeVariable("buttons-btn_primary_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_primary_bg_hover")};
        color: ${getThemeVariable("buttons-btn_primary_color")};
        border-color: ${getThemeVariable("buttons-btn_primary_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_primary_bg")};
            border-color: ${getThemeVariable("buttons-btn_primary_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_primary_color")};
    }
`;

const button__primary__inverted = css`
    color: ${getThemeVariable("buttons-btn_primary_bg")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_primary_color_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_primary_bg")};
    }
`;

const button__success = css`
    color: ${getThemeVariable("buttons-btn_success_color")};
    background-color: ${getThemeVariable("buttons-btn_success_bg")};
    border-color: ${getThemeVariable("buttons-btn_success_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_success_bg_hover")};
        color: ${getThemeVariable("buttons-btn_success_color")};
        border-color: ${getThemeVariable("buttons-btn_success_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_success_bg")};
            border-color: ${getThemeVariable("buttons-btn_success_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_success_color")};
    }
`;

const button__success__inverted = css`
    color: ${getThemeVariable("buttons-btn_success_bg")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_success_color_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_success_bg")};
    }
`;

const button__danger = css`
    color: ${getThemeVariable("buttons-btn_danger_color")};
    background-color: ${getThemeVariable("buttons-btn_danger_bg")};
    border-color: ${getThemeVariable("buttons-btn_danger_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_danger_bg_hover")};
        color: ${getThemeVariable("buttons-btn_danger_color")};
        border-color: ${getThemeVariable("buttons-btn_danger_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_danger_bg")};
            border-color: ${getThemeVariable("buttons-btn_danger_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_danger_color")};
    }
`;

const button__danger__inverted = css`
    color: ${getThemeVariable("buttons-btn_danger_bg")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_danger_color_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_danger_bg")};
    }
`;

const button__dark = css`
    color: ${getThemeVariable("buttons-btn_dark_color")};
    background-color: ${getThemeVariable("buttons-btn_dark_bg")};
    border-color: ${getThemeVariable("buttons-btn_dark_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_dark_bg_hover")};
        color: ${getThemeVariable("buttons-btn_dark_color")};
        border-color: ${getThemeVariable("buttons-btn_dark_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_dark_bg")};
            border-color: ${getThemeVariable("buttons-btn_dark_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_dark_color")};
    }
`;

const button__dark__inverted = css`
    color: ${getThemeVariable("buttons-btn_dark_color_inverted")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_dark_color_inverted_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_dark_color_inverted")};
    }
`;

const button__bright = css`
    color: ${getThemeVariable("buttons-btn_bright_color")};
    background-color: ${getThemeVariable("buttons-btn_bright_bg")};
    border-color: ${getThemeVariable("buttons-btn_bright_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_bright_bg_hover")};
        color: ${getThemeVariable("buttons-btn_bright_color")};
        border-color: ${getThemeVariable("buttons-btn_bright_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_bright_bg")};
            border-color: ${getThemeVariable("buttons-btn_bright_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_bright_color")};
    }
`;

const button__bright__inverted = css`
    color: ${getThemeVariable("buttons-btn_bright_color_inverted")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_bright_color_inverted_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_bright_color_inverted")};
    }
`;

const button__white = css`
    color: ${getThemeVariable("buttons-btn_white_color")};
    background-color: ${getThemeVariable("buttons-btn_white_bg")};
    border-color: ${getThemeVariable("buttons-btn_white_border")};

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: ${getThemeVariable("buttons-btn_white_bg_hover")};
        color: ${getThemeVariable("buttons-btn_white_color")};
        border-color: ${getThemeVariable("buttons-btn_white_border_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: ${getThemeVariable("buttons-btn_white_bg")};
            border-color: ${getThemeVariable("buttons-btn_white_border")};
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_white_color")};
    }
`;

const button__white__inverted = css`
    color: ${getThemeVariable("buttons-btn_white_bg")};
    background-color: transparent;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background-color: transparent;
        color: ${getThemeVariable("buttons-btn_white_color_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_white_bg")};
    }
`;

const button__link = css`
    color: ${getThemeVariable("buttons-btn_link_color")};
    background-color: transparent;
    border-style: none;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        background: none;
        color: ${getThemeVariable("buttons-btn_link_color_hover")};
    }

    &.disabled,
    &[disabled] {
        &:hover,
        &:focus,
        &.focus {
            background: transparent;
        }
    }

    svg {
        fill: ${getThemeVariable("buttons-btn_link_color")};
    }
`;

const ButtonContentWrapper = styled.span<{isLoading?: boolean; fullWidth?: boolean}>`
    opacity: ${(props) => (props.isLoading ? 0.2 : 1)};
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const buttonContentWrapper__isIcon = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const loaderIconWrapper = css`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
