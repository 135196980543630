import {Dispatch} from "redux";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateBuylandoMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Działki na sprzedaż - Sprzedaj Swój Grunt - gethome.pl`;
    const description = `Chcesz sprzedać grunt inwestycyjny z przeznaczeniem pod zabudowę jednorodzinną lub wielorodzinną? Posiadasz działkę o dużym potencjale? Dobrze trafiłeś. Sprawdź!`;
    const robots = "index, follow";

    dispatch(updateMetaData(title, description, route, {robots, hideSuffix: true}));

    return Promise.resolve(true);
};
