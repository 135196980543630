import {ListElementCheckboxBody} from "./atoms/ListElementCheckboxBody";

interface Props {
    name: string;
    onChange: (fieldName: string, value: boolean) => void;
    onAfterChange: (fieldName: string, value: boolean) => void;
    value: boolean;
    autoSubmit?: boolean;
}

export const IndividualOffersFilter = (props: Props) => {
    const onChange = (name: string, value: boolean) => {
        props.onChange(props.name, value);
        props.autoSubmit && props.onAfterChange(props.name, value);
    };

    return <ListElementCheckboxBody name="oferty-prywatne" label="Oferty prywatne" onChange={onChange} value={props.value} />;
};
