import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {IOfferBoxOffer} from "../../offer/detail/components/offer_box/OfferBox";
import {IDeveloper} from "./fetch_developer_at_route";

export interface IAgencyOffer extends IOfferBoxOffer {}

interface IDeveloperOffersResponse {
    meta: {
        total_investments: number;
        total_offers: number;
        location_description: string;
        total_results: number;
    };
    data: IAgencyOffer[];
}

export const fetchDeveloperOffersTypes = createRequestActionTypes({
    view: "Agency",
    type: "GET",
    name: "DeveloperDetailOffers"
});

export const fetchDeveloperOffersAtRoute =
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IDeveloper) => (dispatch: Dispatch) => {
        dispatch({type: fetchDeveloperOffersTypes.start});

        const url = appendQueryString(apiLink.offers.base({})(null), {developer: prevActionResult.id});

        return getRequest(services, url)
            .then((res: IDeveloperOffersResponse) => {
                dispatch({type: fetchDeveloperOffersTypes.success, result: res.data});
                return res;
            })
            .catch((err) => {
                dispatch({type: fetchDeveloperOffersTypes.error, err});
            });
    };
