import * as React from "react";
import {ReactNode, useState} from "react";
import {css} from "@linaria/core";
import classNames from "classnames";

import {getThemeBreakpointCorrect} from "../styles/linaria_variable_factory";

export interface IProps {
    title: string;
    panelArrowIcon: JSX.Element;
    open?: boolean;
    alwaysOpen?: boolean;
    children: ReactNode;
}

export const PanelExpandable = (props: IProps) => {
    const openState = props.alwaysOpen || props.open;

    const [isOpen, setIsOpen] = useState(openState);

    const onClick = () => {
        if (!props.alwaysOpen) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div className={panelExpandable}>
            <div className={panelExpandableHeader} onClick={onClick}>
                <h3 className={panelExpandableHeadline}>{props.title}</h3>
                <div className={classNames(panelExpandableIconWrapper, {[panelExpandableIconWrapperOpen]: isOpen})}>{props.panelArrowIcon}</div>
            </div>

            <div className={classNames(panelExpandableBody, {[panelExpandableBodyOpen]: isOpen})}>{props.children}</div>
        </div>
    );
};

const panelExpandable = css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
`;

const panelExpandableHeader = css`
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
`;

const panelExpandableHeadline = css`
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 0.8rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin-top: 1.5rem;
    }
`;

const panelExpandableIconWrapper = css`
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: none;
    }
`;

const panelExpandableIconWrapperOpen = css`
    transform: rotate(180deg);
`;

const panelExpandableBody = css`
    display: flex;
    padding: 0;
    transition: max-height 0.2s ease-in-out;
    max-height: 0;
    overflow: hidden;
    flex-direction: column;
    list-style: none;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        max-height: initial;
        overflow: initial;
    }
`;

const panelExpandableBodyOpen = css`
    max-height: 900px;
`;
