import * as React from "react";

export const SingleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                d="M12.33,7.51a2.75,2.75,0,1,0-2-.8A2.75,2.75,0,0,0,12.33,7.51Zm0-4.74a2,2,0,1,1-2,2A2,2,0,0,1,12.33,2.77Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M16.75,17.33V12.12a.38.38,0,0,0-.76,0v5.21a.24.24,0,0,1-.24.24h-.93V10.85a.39.39,0,1,0-.77,0V26.92a.25.25,0,0,1-.24.25h-.88a.28.28,0,0,1-.26-.25v-6a.38.38,0,0,0-.75,0v6a.25.25,0,0,1-.25.25h-.88a.25.25,0,0,1-.25-.25V10.85a.39.39,0,0,0-.11-.27.37.37,0,0,0-.64.27v6.72H8.86a.24.24,0,0,1-.18-.07.22.22,0,0,1-.07-.17V10.85a2.27,2.27,0,0,1,2.26-2.27h2.85A2.29,2.29,0,0,1,16,10.85a.38.38,0,1,0,.76,0,3,3,0,0,0-3-3H10.87a3,3,0,0,0-3,3v6.48a1,1,0,0,0,1,1h.93v8.59a1,1,0,0,0,1,1h.88a.92.92,0,0,0,.66-.17A.93.93,0,0,0,13,28h.89a1,1,0,0,0,.71-.33,1,1,0,0,0,.27-.73V18.33h.93A1,1,0,0,0,16.75,17.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M21.84,23.84a1.14,1.14,0,0,0-1.23,0c-.36.16-.61.73-.93,1.51l-.43-2.17a.34.34,0,0,0-.3-.24H17a.3.3,0,0,0-.29.26c-.13.8-.22,1.44-.3,1.92,0,.26-.07.48-.1.66a1.17,1.17,0,0,1-.09.61c-.06.09,0,.1,0,.12a.05.05,0,0,1,0,0v.09c0,.76,0,1.09.15,1.26a.31.31,0,0,0,.22.09h3.09a.33.33,0,0,0,.14,0,.55.55,0,0,0,.23-.56l.44-.07a.36.36,0,0,0,.22-.2l.62-1.79a1.07,1.07,0,0,0,.76-.74A.73.73,0,0,0,21.84,23.84Zm-.33.6c0,.12-.16.27-.48.35a.3.3,0,0,0-.19.19l-.56,1.77-.56.1a.39.39,0,0,0-.2.13.45.45,0,0,0,0,.23.49.49,0,0,1,0,.17h-1v-1a.29.29,0,0,0-.29-.29.35.35,0,0,0-.22.08.29.29,0,0,0-.09.21v1h-1a4.69,4.69,0,0,1,0-.75c0-.21.09-.8.25-1.81.06-.36.13-.77.2-1.23h1.43l.55,2.82a.35.35,0,0,0,.48.23,2.58,2.58,0,0,0,.48-1.05,4.77,4.77,0,0,1,.65-1.32.47.47,0,0,1,.59,0,.1.1,0,0,1,0,.09A.1.1,0,0,1,21.51,24.44Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M16.36,19.71a.33.33,0,0,0-.1.29l.2,1v.26A1.52,1.52,0,0,0,18,22.59a1.5,1.5,0,0,0,1.5-1.37,1,1,0,0,0,0-.24l.24-.91a.32.32,0,0,0-.08-.28.3.3,0,0,0-.28-.09l-.71.17a1.47,1.47,0,0,0-.65-.15,1.52,1.52,0,0,0-.63.14l-.71-.21A.26.26,0,0,0,16.36,19.71Zm.88.76a.33.33,0,0,0,.24,0,.92.92,0,0,1,.45-.12.94.94,0,0,1,.47.12.26.26,0,0,0,.21,0l.34-.08-.12.51V21a.54.54,0,0,1,0,.18.91.91,0,0,1-.9.81.92.92,0,0,1-.91-.81A.74.74,0,0,1,17,21a.29.29,0,0,0,0-.13l-.13-.52Z"
                fill="#37474f"
                fillRule="evenodd"
            />
        </svg>
    );
};
