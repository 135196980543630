import * as React from "react";
import {css, cx} from "@linaria/core";
import {filter, includes, isEqual, map, max, min, range} from "lodash";
import {FILTER_NUMBER_OF_ROOMS_TESTID} from "@web2/gh_page_object_models";

import {updateRoomBounds} from "../../utils/room_field_data_translations";
import {dropdownList, dropdownListElement} from "../atoms/atoms";
import {ListElementCheckboxBody} from "../atoms/ListElementCheckboxBody";

interface IOwnProps {
    error?: string[];
    name: string;
    id: string;
    onAfterChange: (fieldName: string, value: number[]) => void;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
}

export const RoomsSelect = (props: IOwnProps) => {
    const options = [
        {value: 1, label: "1 pokój"},
        {value: 2, label: "2 pokoje"},
        {value: 3, label: "3 pokoje"},
        {value: 4, label: "4 pokoje"},
        {value: 5, label: "5 pokoi i więcej"}
    ];
    const {value, name} = props;

    const onChange = (room: number) => {
        // Check if user unchecked all values one by one.
        if (isEqual(value, [room])) {
            return props.onChange(name, []);
        }
        const {lower, upper} = updateRoomBounds({lower: min(value) || "", upper: max(value) || ""}, room);
        const newValue = range(lower || 0, (upper || 5) + 1);
        return props.onChange(name, filter(newValue));
    };

    return (
        <ul className={cx(dropdownList, list)}>
            {map(options, (o) => (
                <li data-testid={FILTER_NUMBER_OF_ROOMS_TESTID.CHECKBOX_NUMBER_OF_ROOMS} className={dropdownListElement} key={o.label}>
                    <ListElementCheckboxBody name={o.label} label={o.label} onChange={() => onChange(o.value)} value={includes(value, o.value)} />
                </li>
            ))}
        </ul>
    );
};

const list = css`
    min-width: 190px;
`;
