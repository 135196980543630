import React from "react";
import {css, Theme} from "@emotion/react";
import {useIsMobile} from "@web2/react_utils";

import {TermsRodo} from "../../app/components/TermsRodo";
import {AlgolyticsSourceSection} from "../../tracking/algolytics/interaction/application_sent_hit";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {IApplicationResponse} from "../actions/application_v2_post";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {TrackApplicationPayload} from "../utils/track_application";
import {Application, IApplicationV2DataOwnProps, UserData} from "./Application";

export interface IApplicationModalContentProps extends IApplicationV2DataOwnProps {
    onSuccess?: (res: IApplicationResponse) => void;
    applicationSource: ApplicationSource | null;
    applicationSourceSection: ApplicationSourceSection;
    algolyticsSourceSection: AlgolyticsSourceSection | undefined;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    voivodeshipSlug?: string;
    setApplicationUserData?: (userData: UserData) => void;
    setApplicationTrackingPayload?: (payload: TrackApplicationPayload) => void;
}

export const ApplicationModalForm = (props: IApplicationModalContentProps) => {
    const {offer, investment} = props;

    const isMobile = useIsMobile(true);

    return (
        <div css={applicationModalFormHolder}>
            <Application
                offer={offer}
                investment={investment}
                applicationSource={props.applicationSource}
                applicationSourceSection={props.applicationSourceSection}
                algolyticsSourceSection={props.algolyticsSourceSection}
                gtmSource={props.gtmSource}
                onSuccess={props.onSuccess}
                setApplicationUserData={props.setApplicationUserData}
                setApplicationTrackingPayload={props.setApplicationTrackingPayload}
            />
            {isMobile && (
                <p css={mobileRodoTerms}>
                    <TermsRodo isPrivate={!!offer?.is_private} offerId={props.offer?.id} vendorName={offer?.agency?.name || investment?.developer?.name} />
                </p>
            )}
        </div>
    );
};

export const applicationModalFormOfferInfo = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;

const applicationModalFormHolder = css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
`;

export const mobileRodoTerms = (theme: Theme) => css`
    color: ${theme.colors.gray_darker};
    font-size: 1rem;
    margin: 1.5rem 0 0;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;
