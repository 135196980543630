import {Dispatch} from "redux";
import {getCookieFromString} from "@web2/request_utils";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {syncUserSession} from "../../app/user/actions/sync_user_session";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {enable302ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";

// only force session sync on views that are irrelevant in SEO context
// we need this to avoid scenarios when user posted an offer on first "pageview" and was logged in only on GH

const cookieName = "usersessionid";
export const forceSessionSyncAtRoute = (services: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => async (dispatch: Dispatch) => {
    const setCookies = services.serverResponseHeaders?.fetchHeaders()["set-cookie"];
    const userSessionIdCookieValue = (setCookies?.[0] && getCookieFromString(cookieName, setCookies?.[0])) || "";

    if (userSessionIdCookieValue) {
        const requestCookie = `${cookieName}=${userSessionIdCookieValue}`;
        services.serverRequestHeaders?.appendHeaders({cookie: requestCookie});
    }

    const syncUrl = await syncUserSession(services, ghCommonUrl + route.url);
    if (syncUrl) {
        dispatch(enable302ResponseState(syncUrl));
    }

    return Promise.resolve(true);
};
