import * as React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {map} from "lodash";

interface IProps {
    className?: string;
    error?: string[] | null;
}

export const FormErrorMessage: React.FC<IProps> = (props) => {
    const className = classNames(props.className, "form-error-message-holder");
    const errorMessage = props.error && map(props.error, (item, idx) => <span key={idx}>{item}</span>);

    return <ErrorMessage className={className}>{errorMessage}</ErrorMessage>;
};

/*
Styles
 */

const ErrorMessage = styled("p")`
    color: #e81d31;
    margin-top: 2px;
    margin-bottom: -12px;
    font-size: 10px;
    text-align: right;
    line-height: 1.6;
    text-transform: lowercase;

    &.bottom-error {
        position: absolute;
        bottom: 12px;
        right: 0;
    }
`;
