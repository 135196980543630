import {isEmpty} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {IActiveItemAction} from "../reducers/active_dropdown_item_reducer";
import {ISearchSuggestions, SearchTab} from "./fetch_search_all_action";
import {AutocompletePrediction} from "./fetch_search_places_action";

type IActiveItem = {label: string; id: number};

const createSuggestionsActiveItem = (suggestions: ISearchSuggestions[], index: number): IActiveItem => {
    const suggestion = suggestions[index];
    return {
        label: suggestion.name ?? "",
        id: index
    };
};

const createPlaceActiveItem = (places: AutocompletePrediction[], index: number): IActiveItem => {
    const place = places[index];
    return {
        label: place.description,
        id: index
    };
};

const createSetActiveItemAction = (item: IActiveItem): IActiveItemAction => ({type: SET_ACTIVE_DROPDOWN_ITEM, item});

const getPreviousId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return length - 1;
    }
    if (0 < index && index < length) {
        return index - 1;
    }
    return null;
};

const getNextId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return 0;
    }
    if (index < length - 1) {
        return index + 1;
    }
    return null;
};

export const SET_ACTIVE_DROPDOWN_ITEM = "search_engine/SET_ACTIVE_DROPDOWN_ITEM";
export const RESET_ACTIVE_DROPDOWN_ITEM = "search_engine/RESET_ACTIVE_DROPDOWN_ITEM";

export const setDropdownItemDirection = (direction: "next" | "previous") => (dispatch: Dispatch, getState: () => IStore) => {
    const {search} = getState();
    const {formValues, currentTab, activeDropdownItem, places, suggestions, fetchPlacesRequest, fetchSuggestionsRequest} = search;
    const {lastSearch, lastSearchSuggestions, lastSearchSuggestionsRequest} = getState().lastSearch;
    const inputValue = formValues["search"].label;

    if (isEmpty(inputValue)) {
        if (lastSearchSuggestionsRequest === RequestState.Success) {
            const lastSearchSet = [...lastSearch, ...lastSearchSuggestions];
            const previousId = getPreviousId(lastSearchSet.length, activeDropdownItem.id);
            const nextId = getNextId(lastSearchSet.length, activeDropdownItem.id);
            const directionId = direction === "next" ? nextId : previousId;

            if (directionId !== null) {
                const currentEntity = lastSearchSet[directionId];
                const {suggest_type} = currentEntity;

                if (currentEntity.suggest_type === "location") {
                    const activeItemLocation = {
                        label: currentEntity.short_name ?? "",
                        id: directionId
                    };
                    scrollToTabItem(directionId);
                    return dispatch(createSetActiveItemAction(activeItemLocation));
                }

                if (suggest_type === "agent" || suggest_type === "agency" || suggest_type === "developer" || suggest_type === "investment") {
                    const activeItem = {
                        label: currentEntity.name ?? "",
                        id: directionId
                    };
                    scrollToTabItem(directionId);
                    return dispatch(createSetActiveItemAction(activeItem));
                }
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }

    if (currentTab === SearchTab.SearchSuggestions) {
        if (fetchSuggestionsRequest === RequestState.Success) {
            const previousId = getPreviousId(suggestions.length, activeDropdownItem.id);
            const nextId = getNextId(suggestions.length, activeDropdownItem.id);
            const directionId = direction === "next" ? nextId : previousId;

            if (directionId !== null) {
                const activeItem = createSuggestionsActiveItem(suggestions, directionId);
                scrollToTabItem(directionId);
                return dispatch(createSetActiveItemAction(activeItem));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }

    if (currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            const previousId = getPreviousId(places.length, activeDropdownItem.id);
            const nextId = getNextId(places.length, activeDropdownItem.id);
            const directionId = direction === "next" ? nextId : previousId;

            if (directionId !== null) {
                const activeItemPlace = createPlaceActiveItem(places, directionId);
                scrollToTabItem(directionId);
                return dispatch(createSetActiveItemAction(activeItemPlace));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
};

export const resetActiveDropdownItem = () => ({type: RESET_ACTIVE_DROPDOWN_ITEM});

export const CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM = "search_engine/CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM";
export const clearLabelOfActiveValue = () => ({type: CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM});

const scrollToTabItem = (index: number) => {
    const tabItem = document.getElementById("scrollableItem-" + index);

    tabItem && tabItem.scrollIntoView(false);
};
