import {css, Theme} from "@emotion/react";
import {darken} from "polished";
import {Button} from "@web2/button";
import {themeGh} from "@web2/global_styles";

export const PackageOption = (props: {header: string; subHeader: string; price: string; onClick: () => void; isActive: boolean}) => {
    const {header, subHeader, price, onClick, isActive} = props;

    return (
        <div css={packageOptionWrapper(themeGh, isActive)}>
            <div css={optionHeader}>
                <div css={mainHeader}>{header}</div>
                <div css={subHeaderStyle}>{subHeader}</div>
            </div>
            <div css={priceValue}>{price} zł</div>
            <div>
                <Button css={optionStyledButtonCTA(themeGh, isActive)} onClick={onClick}>
                    {isActive && "✅ "}Wybierz
                </Button>
            </div>
        </div>
    );
};

const packageOptionWrapper = (theme: Theme, isActive: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    width: 100%;
    min-width: 20rem;
    height: 19.2rem;
    background-color: ${theme.colors.body_bg};
    border-radius: ${theme.borders.border_radius_base};
    border: ${isActive ? `solid ${theme.colors.brand_primary} 0.3rem` : "solid transparent 0.3rem"};

    @media screen and (min-width: ${theme.breakpoints.screen_xs}) {
        min-width: 36.55rem;
    }
`;

const priceValue = (theme: Theme) => css`
    font-size: 3.2rem;
    font-weight: 600;
    color: ${theme.colors.brand_success};
`;

const optionHeader = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const mainHeader = css`
    font-size: 1.8rem;
    font-weight: 700;
`;

const subHeaderStyle = css`
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
`;

const optionStyledButtonCTA = (theme: Theme, isActive: boolean) => css`
    display: flex;
    border-radius: ${theme.borders.border_radius_base};
    background-color: ${isActive ? theme.colors.brand_primary : theme.colors.body_bg};
    border-color: ${theme.colors.brand_primary};
    color: ${theme.colors.brand_primary};

    &:hover,
    &:active,
    &:focus {
        background-color: ${darken(0.1, theme.colors.brand_primary)};
        border-color: ${theme.colors.brand_primary};
        color: ${theme.colors.text_white};
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: fit-content;
    }
`;
