import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const EquipmentIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.47mm"} height={props.height || "7.94mm"} viewBox="0 0 24 22.5">
            <path d="M16.53,1.28A.75.75,0,0,0,15.47.22L12,3.69,8.53.22A.75.75,0,0,0,7.47,1.28l3.47,3.47h2.12Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M21.25,4.75H2.75A2.75,2.75,0,0,0,0,7.5V17a2.75,2.75,0,0,0,2.75,2.75h18.5A2.75,2.75,0,0,0,24,17V7.5A2.75,2.75,0,0,0,21.25,4.75ZM22.5,17a1.25,1.25,0,0,1-1.25,1.25H2.75A1.25,1.25,0,0,1,1.5,17V7.5A1.25,1.25,0,0,1,2.75,6.25h18.5A1.25,1.25,0,0,1,22.5,7.5Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M16.5,21h-9a.75.75,0,0,0,0,1.5h9a.75.75,0,0,0,0-1.5Z" fill={props.mainColor || "#37474f"} />
            <path d="M3.53,12.28l4-4A.75.75,0,0,0,6.47,7.22l-4,4a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M9.47,7.22,6,10.72a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0l3.5-3.5A.75.75,0,0,0,9.47,7.22Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M4.47,12.22,3,13.72a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0l1.5-1.5a.75.75,0,0,0-1.06-1.06Z" fill={props.accentColor || "#8b6aac"} />
        </svg>
    );
};
