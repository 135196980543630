import * as React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import createCache from "@emotion/cache";
import {Store} from "redux";
import {ABTestingProvider} from "@web2/ab_testing";

import {abTestingInstance} from "../../src/ab_testing/ab_testing_instance";
import {EMOTION_CACHE_KEY} from "../../src/app/constants/emotion_cache_key";
import {GhCommonProviders} from "../../src/app/gh_common_providers";
import {IStore} from "../../src/app/reducers/hybrid_reducer";
import {bugsnagClient} from "../../src/app/utils/bugsnag/bugsnag_client";
import {isProduction} from "../../src/app/utils/read_environment_variables";
import {getClientStore} from "./get_client_store";

const bugsnagReact = bugsnagClient.getPlugin("react");

const ErrorBoundary = isProduction && bugsnagReact ? bugsnagReact.createErrorBoundary(React) : (props: {children: React.ReactNode}) => <>{props.children}</>;

const emotionCache = createCache({key: EMOTION_CACHE_KEY});

export const createHydrateApp = (providerStore: Store<IStore>, elem: Element, appElement: JSX.Element) => {
    const abTestsContext = abTestingInstance.getInitialClientABTestsContext();

    return hydrateRoot(
        elem,
        <Provider store={providerStore}>
            <ErrorBoundary>
                <ABTestingProvider value={abTestsContext}>
                    <GhCommonProviders emotionCache={emotionCache} userAgent={navigator.userAgent}>
                        <BrowserRouter>{appElement}</BrowserRouter>
                    </GhCommonProviders>
                </ABTestingProvider>
            </ErrorBoundary>
        </Provider>
    );
};

export const renderSimpleApp = (elem: Element, component: JSX.Element) => {
    const store = getClientStore();
    if (!store) {
        return;
    }

    const root = createRoot(elem);
    root.render(
        <Provider store={store}>
            <GhCommonProviders emotionCache={emotionCache} userAgent={navigator.userAgent}>
                {component}
            </GhCommonProviders>
        </Provider>
    );
};
