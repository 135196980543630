import React from "react";
import {css} from "@emotion/react";

import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {ApplicationSteps} from "./ApplicationModal";
import {IApplicationModalAgencyOffers} from "./ApplicationModalAgencyOffers";
import {VendorInfo} from "./VendorInfo";

interface IProps {
    agency?: {
        logo_picture?: {
            a_log_100: string;
        } | null;
        name: string;
        slug: string;
        market_type?: OfferMarketType;
        status: number;
        id: string;
        phone_number?: string;
    } | null;
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
        picture: {
            a_img_100x100: string;
        } | null;
    } | null;
    investment?: {
        developer?: {
            name: string;
            logo_picture?: {
                a_log_100: string;
            } | null;
            slug: string;
            type: VendorType;
        } | null;
        id: string;
        location?: {
            short_name: string;
        };
        name: string;
        main_image: {
            o_img_500: string;
        };
        phone_clip?: string | null;
    } | null;
    offer?: IApplicationModalAgencyOffers | null;
    isOfferListModal?: boolean;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    voivodeshipSlug?: string;
    currentPage?: ApplicationSteps;
    hidePhoneNumber?: boolean;
}

export const ApplicationModalShowNumber = (props: IProps) => {
    const {offer, investment, agent, agency} = props;

    return (
        <div css={applicationModalShowNumberHolder}>
            <p css={applicationModalShowNumberClaim}>Może chcesz skontaktować się telefonicznie?</p>

            <VendorInfo
                agent={agent ? agent : undefined}
                agency={agency ? agency : undefined}
                offer={props.offer}
                investment={investment ? investment : null}
                gtmSource={props.gtmSource}
                viewType={props.viewType}
                voivodeshipSlug={props.voivodeshipSlug}
                hidePhoneNumber={props.hidePhoneNumber}
            />
        </div>
    );
};

const applicationModalShowNumberClaim = css`
    font-size: 1.8rem;
    margin: 6rem auto;
    text-align: center;
    max-width: 300px;
`;

const applicationModalShowNumberHolder = css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
`;
