// Please do not change string values. They were introduced by FM and are used for analytics ever since, we want to have continuity in naming.

import {isEqual} from "lodash";
import {getIsMobile} from "@web2/react_utils";

import {searchFormFields, SearchFormValuesType} from "../../search/utils/form_types";
import {fromQueryToFormValues} from "../../search/utils/from_query_values";
import {QueryValues} from "../../search/utils/to_query_values";
import {hitGoogleTagManager} from "./gtm_init";

export const filterInteractionGTMEvent = (
    type: "type" | "size" | "price" | "rooms" | "more" | "square" | "market",
    isMobile: boolean,
    suffix: "opened" | "submited",
    payload: Record<string, string> = {}
) => {
    hitGoogleTagManager({event: `filter_${type}${isMobile ? "-mobile" : ""}_${suffix}`, ...payload});
};

export const filterInteractionPushToGTMEvent = (currentValues: SearchFormValuesType, latestQueryValues: QueryValues) => {
    const latestQuery: any = fromQueryToFormValues(searchFormFields, latestQueryValues);

    if (currentValues.offer_type && !isEqual(currentValues.offer_type, latestQuery.offer_type)) {
        filterInteractionGTMEvent("market", getIsMobile(), "submited", {market: getPolishMarketTypes(currentValues.offer_type)});
    }

    if (
        (currentValues.deal_type && !isEqual(currentValues.deal_type, latestQuery.deal_type)) ||
        (currentValues.deal_type == undefined && !isEqual(currentValues.deal_type, latestQuery.deal_type))
    ) {
        const payload = currentValues.deal_type === undefined ? "Na sprzedaż i wynajem" : currentValues.deal_type === "sell" ? "Na sprzedaż" : "Do Wynajęcia";
        filterInteractionGTMEvent("type", getIsMobile(), "submited", {type: payload});
    }

    if (currentValues.rooms && !isEqual(latestQuery.rooms, currentValues.rooms)) {
        filterInteractionGTMEvent("rooms", getIsMobile(), "submited", {rooms: currentValues.rooms.join(",")});
    }

    if (currentValues.price.lower || currentValues.price.upper) {
        if (currentValues.price.lower.toString() !== latestQuery.price.lower || currentValues.price.upper.toString() !== latestQuery.price.upper) {
            filterInteractionGTMEvent("price", getIsMobile(), "submited", {price: currentValues.price});
        }
    }

    if (currentValues.price_per_sqm.lower || currentValues.price_per_sqm.upper) {
        if (
            currentValues.price_per_sqm.lower.toString() !== latestQuery.price_per_sqm.lower ||
            currentValues.price_per_sqm.upper.toString() !== latestQuery.price_per_sqm.upper
        ) {
            filterInteractionGTMEvent("square", getIsMobile(), "submited", {price_per_square: currentValues.price_per_sqm});
        }
    }

    if (currentValues.size.lower || currentValues.size.upper) {
        if (currentValues.size.lower.toString() !== latestQuery.size.lower || currentValues.size.upper.toString() !== latestQuery.size.upper) {
            filterInteractionGTMEvent("size", getIsMobile(), "submited", {size: currentValues.size});
        }
    }

    if (
        (currentValues.floor && !isEqual(latestQuery.floor.lower, currentValues.floor.lower)) ||
        (currentValues.floor && !isEqual(latestQuery.floor.upper, currentValues.floor.upper)) ||
        (currentValues.construction_year && !isEqual(latestQuery.construction_year.lower, currentValues.construction_year.lower)) ||
        (currentValues.construction_year && !isEqual(latestQuery.construction_year.upper, currentValues.construction_year.upper)) ||
        (currentValues.features && !isEqual(latestQuery.features, currentValues.features))
    ) {
        let payload = {};

        if (currentValues.floor.lower !== latestQuery.floor.lower || currentValues.floor.upper !== latestQuery.floor.upper) {
            payload = {...payload, ...{floor: currentValues.floor}};
            filterInteractionGTMEvent("more", getIsMobile(), "submited", payload);
        }
        if (
            currentValues.construction_year.lower !== latestQuery.construction_year.lower ||
            currentValues.construction_year.upper !== latestQuery.construction_year.upper
        ) {
            payload = {...payload, ...{const_year: currentValues.construction_year}};
            filterInteractionGTMEvent("more", getIsMobile(), "submited", payload);
        }
        if (!isEqual(latestQuery.features, currentValues.features)) {
            payload = {...payload, ...{checkboxes: currentValues.features.join(",")}};
            filterInteractionGTMEvent("more", getIsMobile(), "submited", payload);
        }
    }
};

export const getPolishMarketTypes = (marketTypesEN: string[]) => {
    const polishMarketTypes: string[] = [];
    marketTypesEN.forEach((element: string) => {
        switch (element) {
            case "aftermarket__apartment":
            case "aftermarket__house":
                if (!polishMarketTypes.includes("Rynek wtórny")) {
                    polishMarketTypes.push("Rynek wtórny");
                }
                break;
            case "primary_market__apartment":
            case "primary_market__house":
                if (!polishMarketTypes.includes("Rynek Pierwotny")) {
                    polishMarketTypes.push("Rynek Pierwotny");
                }
                break;
            case "lot":
                polishMarketTypes.push("Działki");
                break;
        }
    });
    return polishMarketTypes.join(", ");
};
