import {Dispatch} from "redux";
import {appLink, IFullOfferDetailParams, parseOfferSlugToObject} from "@web2/gh_routes";

import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {enable301ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";

export const redirectOldLotsAtRoute = (services: Partial<IServices>, route: IRouteState<IFullOfferDetailParams>) => (dispatch: Dispatch) => {
    // we are now redirecting old lots i.e. /slug-123121l/ to standard detail path /slug-78445/
    // old lots slugs were regular offers but could not be fetched by id, only entire slug

    const redirectEndpointUrl = apiLink.offers.byPropertyId({})({id: route.params.offerId.toString()});

    return getRequest(services, redirectEndpointUrl).then(async (res: {slug: string}) => {
        const newSlug = parseOfferSlugToObject(res.slug);
        const offerUrl = appLink.fullOffer.detail.base(newSlug);
        dispatch(enable301ResponseState(offerUrl));
        return false;
    });
};
