import * as React from "react";

export const SatelliteIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20">
            <defs>
                <mask id="a" x="-0.13" y="2.11" width="20.26" height="15.78" maskUnits="userSpaceOnUse">
                    <polygon points="-0.13 2.11 20.13 2.11 20.13 17.89 -0.13 17.89 -0.13 2.11" fill="#fff" fillRule="evenodd" />
                </mask>
            </defs>
            <g mask="url(#a)">
                <path
                    d="M17.77,8.58a7.61,7.61,0,0,0-.61-1.91c1.53,0,2,.31,2,.44s-.18.64-1.43,1.47M10.54,17a7,7,0,0,1-6.26-3,34.74,34.74,0,0,0,6.25-1.28,2.16,2.16,0,0,0,3.93-1.37c.92-.38,1.77-.77,2.52-1.17A7,7,0,0,1,10.54,17M.81,12.66C.76,12.53,1,12,2.22,11.19a8.07,8.07,0,0,0,.54,1.91c-1.46,0-1.92-.31-1.95-.44M9.49,3.05A7,7,0,0,1,16.91,9.1a25.43,25.43,0,0,1-2.7,1.29,2.17,2.17,0,0,0-4.08,1.19c0,.07,0,.14,0,.22a33.18,33.18,0,0,1-6.41,1.26,7,7,0,0,1,5.73-10M20.08,6.84c-.24-.82-1.37-1.17-3.42-1.08a7.88,7.88,0,0,0-14.53,4.4c-1.73,1.05-2.46,2-2.21,2.77S1.28,14.08,3.23,14A7.88,7.88,0,0,0,17.89,9.6c1.69-1,2.43-2,2.19-2.76"
                    fill="#384850"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
};
