import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const ArrowDownIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M8.71,2.39,5,6.1,1.28,2.39a.75.75,0,0,0-1.06,0,.77.77,0,0,0,0,1.07L4.29,7.54a1,1,0,0,0,1.41,0L9.78,3.46A.76.76,0,1,0,8.71,2.39Z" />
        </SvgIcon>
    );
};
