import {Dispatch} from "redux";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAboutUsMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Mieszkania i domy na sprzedaż i wynajem - znajdź na mapie`;
    const description = `gethome.pl to nowy portal nieruchomości bez duplikatów i reklam, prezentujący tylko unikalne oferty! Sprawdź nas!✅`;
    const robots = "index, follow";

    dispatch(updateMetaData(title, description, route, {robots}));

    return Promise.resolve(true);
};
