import {pluralize} from "@web2/string_utils";

/**
 * Rooms have special case for rendering ranges. They are displayed (view-data) as an array of numbers (rooms count), but used as a range (api-data).
 * view-data: [1, 2, 3, 4, 5]
 * api-data: {lower: 1, upper: 5}
 *
 * (i) range [1..5] equals range [], because all rooms are selected, so no rooms fits that fine
 * (i) 5 means 5+ so upper bound is opened in this case
 */

export interface IRoomValue {
    lower: number | "";
    upper: number | "";
}

export const MIN_ROOMS = 1;
export const MAX_ROOMS = 5;
const MIN = MIN_ROOMS;
const MAX = MAX_ROOMS;

const getValidLower = (lower: IRoomValue["lower"]) => Math.max(lower || MIN, MIN);
const getValidUpper = (upper: IRoomValue["upper"]) => Math.min(upper || MAX, MAX);
const validateBound = (value: IRoomValue): IRoomValue => {
    const {lower: lowerValue, upper: upperValue} = value;
    const lower = lowerValue || 0;
    const upper = upperValue || 0;

    // open bounds
    if (!Number.isFinite(lower) && !Number.isFinite(upper)) {
        // undefined bounds
        return value;
    }
    if (!Number.isFinite(lower)) {
        // no left bound
        return upper < MIN
            ? {lower: "", upper: ""} // invalid upper bound
            : upper < MAX
              ? value
              : {lower: MIN, upper: MAX};
    }
    if (!Number.isFinite(upper)) {
        // no right bound
        return lower > MAX
            ? {lower: "", upper: ""} // invalid lower bound
            : lower > MIN
              ? value
              : {lower: MIN, upper: MAX};
    }
    // defined bounds
    if (upper < lower) {
        // error bound
        return {lower: "", upper: ""};
    }
    if (lower <= MIN && upper >= MAX) {
        // border bounds
        return {lower: MIN, upper: MAX};
    }
    if (lower <= MIN) {
        // border left bound
        return {lower: "", upper};
    }
    if (upper >= MAX) {
        // border right bound
        return {lower, upper: ""};
    }
    return value;
};

export const getRoomLabel = (value: number[], defaultLabel: string) => {
    if (value.length === 0) {
        return defaultLabel;
    }

    const roomsWho = pluralize(["pokój", "pokoje", "pokoi"]);
    const roomsWhom = pluralize(["pokoju", "pokoi", "pokoi"]);

    const lower = Math.min(...value);
    const upper = Math.max(...value);

    if (lower === upper) {
        return `${lower} ${roomsWho(lower)}`;
    }
    if (lower && lower > MIN && upper === MAX) {
        return `od ${lower} ${roomsWhom(lower)}`;
    }
    if (lower && upper && lower === MIN && upper < MAX) {
        return `do ${upper} ${roomsWhom(upper)}`;
    }
    const optionalPlus = (v: number) => (v === 5 ? "5+" : v);
    return `${lower} - ${optionalPlus(upper)} ${roomsWho(upper)}`;
};

export const isRoomInRange = (value: IRoomValue, rooms: number, threshold = 0) => {
    const {lower, upper} = value;
    if (!lower && !upper) {
        return false;
    }
    const validLower = getValidLower(lower);
    const validUpper = getValidUpper(upper);
    return validLower - threshold <= rooms && rooms <= validUpper + threshold;
};

export const updateRoomBounds = (inValue: IRoomValue, inOption: number) => {
    return validateBound(updateRoomBoundsNotValidated(inValue, inOption));
    // helper function
    function updateRoomBoundsNotValidated(value: IRoomValue, option: number): IRoomValue {
        const {lower, upper} = value;
        if (!lower && !upper) {
            return {lower: option, upper: option};
        }
        const validLower = getValidLower(lower);
        const validUpper = getValidUpper(upper);
        if (isRoomInRange(value, option)) {
            // cut the range
            const leftDistance = Math.abs(option - validLower);
            const rightDistance = Math.abs(option - validUpper);
            // cut (left or right) values up to the `option` point
            return leftDistance < rightDistance ? {lower: option + 1, upper} : {lower, upper: option - 1};
        } else {
            // expand the range => left or right
            return option < validLower ? {lower: option, upper} : {lower, upper: option};
        }
    }
};
