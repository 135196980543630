import {css} from "@linaria/core";
import {capitalizeFirst} from "@web2/nodash";

import {ISearchFeature} from "../../../app/interfaces/response/server_list_response";
import {dealTypeToUrl} from "../../../app/utils/deal_type_to_url";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {getThemeBreakpointCorrect} from "../../../styles/linaria_variable_factory";
import {OfferDealType, OfferType, UOfferTypes} from "../../utils/constants_offer";
import {getTagDataByFeature, getTagMetaText} from "../url_utils/tag_utils";
import {OfferListSeoLinkListItem} from "./OfferListSeoLinkListItem";
import {getListUrlWithFilterFeature} from "./OfferListSeoLinks";

interface IProps {
    offerType: UOfferTypes | OfferType;
    dealType: OfferDealType;
    activeFeatures?: string[];
    location?: {
        name: string;
        slug: string;
        short_name: string;
        location_type: string;
    } | null;
    availableFeatures?: ISearchFeature[];
}

export const OfferListFilterFeatureSeoLinks = (props: IProps) => {
    const dealType = props.dealType === "rent" ? "do wynajęcia" : "na sprzedaż";
    let name = "";
    let slug = "";
    let short_name: string | null = "";
    let location_type = "";
    if (props.location && props.location !== null) {
        name = props.location?.name ?? "";
        slug = props.location?.slug ?? "";
        short_name = props.location?.short_name ?? "";
        location_type = props.location?.location_type ?? "";
    }

    const availableFeaturesToShow = props.availableFeatures
        ?.filter((item) => !props.activeFeatures?.includes(item.feature))
        .map((item) => {
            const tagData = getTagDataByFeature(item.feature);
            if (!tagData) {
                return null;
            }

            const url = getListUrlWithFilterFeature(
                {
                    tag: tagData?.tag,
                    offerType: props.offerType,
                    ...(props.offerType !== "dzialki" ? {type: dealTypeToUrl(props.dealType)} : {})
                },
                slug
            );

            const offerType = props.offerType !== "dzialki" ? props.offerType : "działka";
            const tagMetaText = getTagMetaText(tagData.tag, tagData.metaText);

            let locationName = short_name;
            if (props.offerType === "dzialki") {
                if (location_type === LocationTypes.TOWN) {
                    locationName = name;
                } else if (location_type === LocationTypes.COUNTY) {
                    locationName = `powiat ${name}`;
                }
            }

            const seoName = `${capitalizeFirst(offerType)} ${dealType && props.offerType !== "dzialki" ? ` ${dealType}` : ""} ${tagMetaText} ${locationName}`;

            return <OfferListSeoLinkListItem key={item.feature} url={url} name={seoName} className={listItemFlexBasis} />;
        });

    return <>{props.availableFeatures?.length === 0 ? null : availableFeaturesToShow}</>;
};

const listItemFlexBasis = css`
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        flex-basis: 100%;
    }
}`;
