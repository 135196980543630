import {Dispatch} from "redux";

import {IStore} from "../../reducers/hybrid_reducer";
import {apiLink} from "../../routes/api_link";
import {IServices} from "../../services/IServices";
import {createRequestActionTypes} from "../../utils/request_response_utils/factories/create_request_action_types";
import {RequestState} from "../../utils/request_response_utils/factories/reduce_request_state";
import {getRequest} from "../../utils/request_response_utils/request";
import {catch400, catch403} from "../../utils/request_response_utils/response_error";
import {IUserProfile} from "../reducers/user_profile_reducer";

export const fetchUserProfileRequestTypes = createRequestActionTypes({type: "GET", name: "user/FETCH_USER_PROFILE"});

const userInfoLink = apiLink.userApi.user.info();

const createFetchUserProfile = (forceFetch = false) => {
    let concurrentFetchPromise: Promise<IUserProfile | null> | null = null;
    return (services: Partial<IServices> = {}) =>
        async (dispatch: Dispatch, getState: () => IStore) => {
            const userData = getState().user;
            if (!forceFetch && userData.requestState !== RequestState.None) {
                return concurrentFetchPromise != null ? concurrentFetchPromise : userData.profile;
            }

            // continue with profile fetch
            dispatch({type: fetchUserProfileRequestTypes.start});
            concurrentFetchPromise = getRequest(services, userInfoLink)
                .then((result: IUserProfile) => {
                    concurrentFetchPromise = null;
                    dispatch({type: fetchUserProfileRequestTypes.success, result});
                    return result;
                })
                .catch(
                    catch403((error) => {
                        concurrentFetchPromise = null;
                        // this fetch is global and starts the App, so cannot have any logic on 403, because it is normal behaviour for anonymous user
                        dispatch({type: fetchUserProfileRequestTypes.error});
                        return null;
                    })
                )
                .catch(
                    catch400((error) => {
                        concurrentFetchPromise = null;
                        dispatch({type: fetchUserProfileRequestTypes.error, error: error.appError});
                        return null;
                    })
                );
            return concurrentFetchPromise;
        };
};

export const resetFetchUserProfile = () => ({type: fetchUserProfileRequestTypes.reset});

export const fetchUserProfile = createFetchUserProfile(false);
export const forceFetchUserProfile = createFetchUserProfile(true);
