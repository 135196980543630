import {useEffect, useState} from "react";
import {matchPath} from "react-router";
import {isEqual} from "lodash";
import {appPath} from "@web2/gh_routes";
import {usePrevious} from "@web2/react_utils";
import {useUserDevice} from "@web2/user-device";

import {ISearchPageViewHitData, searchPageViewHit} from "../../../../../tracking/algolytics/views_hits/offer_list_view_hit";
import {gtmImpressionsWithOffers} from "../../../../../tracking/google_tag_manager/ecommerce_events/gtm_impressions";
import {gtmVirtualPageView} from "../../../../../tracking/google_tag_manager/gtm_page_view";
import {ViewType} from "../../../../../tracking/view_type/view_type";
import {generateOfferListBreadcrumbs} from "../../../../utils/generate_breadcrumbs";
import {cutVoivodeship, locationNameParser} from "../../../../utils/location_name_parser";
import {getDefaultOfferCountPerPage} from "../../../actions/fetch_offer_list_at_route";
import {getOfferListAffixes} from "../../../utils/get_offer_list_affixes";
import {IOfferListDynamicProps} from "../../OfferListDynamic";

type IOfferListTrackingProps = Pick<IOfferListDynamicProps, "latestQuery" | "viewType" | "viewTypeData" | "offersData" | "investment" | "locationData">;

export const useOfferListTracking = (props: IOfferListTrackingProps) => {
    const {latestQuery} = props;
    const prevLatestQuery = usePrevious(latestQuery, latestQuery);

    const {isMobile} = useUserDevice();

    const [shouldListTriggerVpv, setShouldListTriggerVpv] = useState(false);
    const prevViewType = usePrevious(props.viewType, null);
    const prevViewTypeData = usePrevious(props.viewTypeData, null);
    const {gtmListPrefix, gtmListSuffix} = getOfferListAffixes(latestQuery);

    useEffect(() => {
        // trigger logic on every search triggered by user interaction
        if (!isEqual(prevLatestQuery, props.latestQuery)) {
            algolyticsHit();
            setShouldListTriggerVpv(true);
        }
    }, [props.latestQuery]);

    useEffect(() => {
        // GTM 'listing' events
        // trigger a virtual page view for 'listing' after closing modal
        const validViewTypes = [ViewType.OFFER_LIST_ALL, ViewType.OFFER_LIST_FOR_SALE, ViewType.OFFER_LIST_FOR_RENT];
        const isViewTypeValid = validViewTypes.some((vType) => vType === props.viewType);
        if (isEqual(prevLatestQuery, props.latestQuery) && isViewTypeValid && prevViewType === ViewType.OFFER_IN_LISTING) {
            gtmVirtualPageView(props.viewType, props.viewTypeData);
        }

        // trigger a virtual page view for 'listing' after changing query
        if ((!isEqual(prevViewTypeData, props.viewTypeData) || !isEqual(prevLatestQuery, props.latestQuery)) && shouldListTriggerVpv) {
            gtmVirtualPageView(props.viewType, props.viewTypeData);
            gtmImpressionsHit();
            setShouldListTriggerVpv(false);
        }
    }, [props.viewType, props.viewTypeData, props.latestQuery, props.offersData]);

    const gtmImpressionsHit = () => {
        gtmImpressionsWithOffers({
            offers: props.offersData.offers,
            viewType: props.viewType,
            listPositionOffset: (props.offersData.page - 1) * getDefaultOfferCountPerPage({offerType: props.latestQuery.offer_type}),
            listPrefix: gtmListPrefix,
            listSuffix: gtmListSuffix
        });
    };

    const algolyticsHit = () => {
        const location = () => {
            if (props.latestQuery.geo_point && props.latestQuery.search_name) {
                return props.latestQuery.search_name;
            }
            if (props.latestQuery.investment && props.investment.investment) {
                return props.investment.investment.name;
            }
            if (props.locationData.location) {
                return cutVoivodeship(locationNameParser(props.locationData.location.path || props.locationData.location.short_name));
            }
            return "Cała Polska";
        };

        const tagRouteParam = matchPath<{tag?: string}>(window.location.pathname, {path: appPath.searchResult.universalTag})?.params.tag;

        const searchData: ISearchPageViewHitData = {
            breadcrumbs: generateOfferListBreadcrumbs(
                props.locationData.location?.path,
                props.latestQuery.deal_type,
                props.latestQuery.offer_type,
                tagRouteParam
            ),
            collectionCount: props.offersData.collection_count,
            currentMapBounds: {
                geoBoxTopRight: props.latestQuery.geo_box_top_right ?? null,
                geoBoxBottomLeft: props.latestQuery.geo_box_bottom_left ?? null
            },
            isMobile: isMobile,
            searchParams: props.latestQuery,
            selectedLocation: location()
        };
        searchPageViewHit(searchData);
    };

    useEffect(() => {
        algolyticsHit();
        gtmImpressionsHit();
    }, []);
};
