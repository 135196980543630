import {Dispatch, RefObject, SetStateAction, useState} from "react";

import {mapSizeClickGTMEvent} from "../../../tracking/google_tag_manager/map_size_click";
import {mapTypeGTMEvent} from "../../../tracking/google_tag_manager/map_type_click";
import {IGoogleMapApi} from "../components/map/desktop/GoogleMapOfferListDesktop";

enum MapType {
    ROADMAP = "roadmap",
    SATELLITE = "satellite"
}

export const useMapControls = (mapRef: RefObject<Pick<IGoogleMapApi, "getMapInstance">>, setIsMapBig?: Dispatch<SetStateAction<boolean>>) => {
    const [mapType, _setMapType] = useState<MapType>(MapType.ROADMAP);

    // map types
    const onSetSatelliteMapType = () => {
        _setMapType(MapType.SATELLITE);
        const map = mapRef.current && mapRef.current.getMapInstance();
        if (map) {
            map.setMapTypeId(MapType.SATELLITE);
        }
        mapTypeGTMEvent("satellite");
    };

    const onSetRoadMapType = () => {
        _setMapType(MapType.ROADMAP);
        const map = mapRef.current && mapRef.current.getMapInstance();
        if (map) {
            map.setMapTypeId(MapType.ROADMAP);
        }
        mapTypeGTMEvent("terrain");
    };

    // zoom
    const setMapZoom = (direction: "increase" | "decrease") => {
        const map = mapRef.current && mapRef.current.getMapInstance();
        const currentZoom = map?.getZoom();

        if (map && currentZoom) {
            if (direction === "increase") {
                map.setZoom(currentZoom + 1);
            } else {
                map.setZoom(currentZoom - 1);
            }
        }
    };

    // map size
    const toggleMapSize = () => {
        setIsMapBig?.((prevIsMapBig) => {
            const nextSize = prevIsMapBig ? "small" : "large";
            mapSizeClickGTMEvent(nextSize);
            return !prevIsMapBig;
        });
    };

    return {
        type: {
            mapType,
            onSetRoadMapType,
            onSetSatelliteMapType
        },
        setMapZoom,
        toggleMapSize
    };
};
