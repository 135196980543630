import {Dispatch} from "react";
import {safelyParsePage} from "@web2/string_utils";

import {IDeveloper} from "../../agency/actions/fetch_developer_at_route";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {RequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";

export interface IVendorBox {
    logo_picture: {
        link: string;
        a_log_100: string;
        a_log_180: string;
    } | null;
    name: string;
    offer_summary: {
        link: string;
        offer_count: number;
        rent: {
            count: number;
            link: string;
        };
        selling: {
            count: number;
            link: string;
        };
        sold: {
            count: number;
            link: string;
        };
    };
    slug: string;
}

export interface IVendorList {
    total_offers: number; // liczy oferty wszystkie lub w wybranym w parametrze mieście
    data: IVendorBox[];
}

export interface IVendorListResponse {
    page: number;
    count: number;
    next: string | null;
    previous: string | null;
    page_size: number;
    results: IVendorList;
}

export const fetchVendorList =
    (actionTypes: RequestActionTypes, type: VendorType) =>
    (services: Partial<IServices>, route: IRouteState<{location: string}>, prevRoute: IPrevRouteState | null, prevActionResult: IDeveloper) =>
    (dispatch: Dispatch<any>, getState: () => IStore) => {
        dispatch({type: actionTypes.start});
        const page = safelyParsePage(route.query.page);
        const locationID = getState().offerList.location.location?.id;
        const query = {location: locationID, type: type, page_size: 36, page};

        const url = appendQueryString(apiLink.agencies.agencyList({})({}), query);

        return getRequest(services, url)
            .then((result: IVendorListResponse) => {
                dispatch({type: actionTypes.success, result});
                return result;
            })
            .catch((err) => {
                dispatch({type: actionTypes.error, err});
            });
    };
