import {useMemo} from "react";

type UseMapReturnType<T> = Record<number | string, T>;

/**
 * Hook returns memoized object from an array based on provided key
 * @param list
 * @param key
 */

export const useMap = <ArrayElement extends Record<Key, number | string>, Key extends number | string>(
    list: ArrayElement[],
    key: Key
): UseMapReturnType<ArrayElement> => {
    return useMemo(() => {
        const listMap: UseMapReturnType<ArrayElement> = {};
        for (const item of list) {
            listMap[item[key]] = item;
        }

        return listMap;
    }, [list]);
};
