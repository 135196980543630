import {ReactNode} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Popover, PopoverProps} from "@web2/floating";

export const PopoverGH = (props: Omit<PopoverProps, "children"> & {children: ReactNode}) => {
    const {children, popover, ...popoverProps} = props;

    return (
        <Popover {...popoverProps} css={popoverCSS} popover={<TooltipContentWrapper>{popover}</TooltipContentWrapper>} arrow={{fill: "#37474f"}}>
            {({refs, getReferenceProps}) => (
                <div ref={refs.setReference} {...getReferenceProps()}>
                    {children}
                </div>
            )}
        </Popover>
    );
};

interface ITooltipProps {
    last?: boolean;
    minWidth?: number;
    enterDelay?: number;
}

const TooltipContentWrapper = styled.div<ITooltipProps>`
    height: auto;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    background-color: ${(props) => props.theme?.colors?.gray_dark || "#37474f"};
    color: #fff;
    text-align: center;
    border-radius: 0.6rem;
    position: relative;
    z-index: 1;

    a:focus-visible {
        background-color: rgba(255, 255, 255, 0.08);
        border: 0 none;
        outline: 1px rgba(255, 255, 255, 0.08);
    }
`;

const popoverCSS = css`
    z-index: 1;
`;
