import * as React from "react";
import {pluralize} from "@web2/string_utils";
import {BounceLoader} from "@web2/ui_utils";

import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {ISearchSuggestions, SearchTab} from "../../actions/fetch_search_all_action";
import {IActiveDropdownItemStore} from "../../reducers/active_dropdown_item_reducer";
import {ISearchInputValue} from "../../utils/ISearchInputValue";
import {resultsList, searchLoaderHolder} from "../atoms/atoms";
import {ResultsInfo} from "../atoms/ResultsInfo";
import {ResultsListItem} from "../atoms/ResultsListItem";

interface IOwnProps {
    fetchCombinedRequest: RequestState;
    fetchPlacesRequest: RequestState;
    activeItem: IActiveDropdownItemStore;
    suggestions: ISearchSuggestions[];
    onLinkClick: (option: ISearchInputValue) => void;
}

interface IProps extends IOwnProps {}

export const SearchSuggestionsTab = (props: IProps) => {
    const {fetchCombinedRequest, fetchPlacesRequest, suggestions} = props;

    let title: string;
    let subTitle: string;

    if (fetchCombinedRequest === RequestState.Success && fetchPlacesRequest !== RequestState.Waiting && suggestions.length === 0) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }
    // display list/null also on fetching because we do not want loader to interrupt list view
    if (fetchCombinedRequest === RequestState.Success || fetchCombinedRequest === RequestState.Waiting) {
        if (suggestions && suggestions.length === 0) {
            return (
                <div className={searchLoaderHolder}>
                    <BounceLoader color="#9069c0" />
                </div>
            );
        }

        return (
            <ul className={resultsList}>
                {suggestions.map((suggestions, idx) => {
                    const propertiesCount = suggestions.offer_count_total;
                    const propertiesFormat = pluralize(["oferta", "oferty", "ofert"])(propertiesCount);
                    const onCombinedClick = () => {
                        props.onLinkClick({
                            label: suggestions.name,
                            tabType: SearchTab.SearchSuggestions,
                            suggestions: suggestions
                        });
                    };

                    if (suggestions.suggest_type === "agency") {
                        title = suggestions.name;
                        subTitle = "Agencja Nieruchomości";
                    }

                    if (suggestions.suggest_type === "agent") {
                        title = suggestions.name;
                        subTitle = suggestions.agency_name || "";
                    }

                    if (suggestions.suggest_type === "developer") {
                        title = suggestions.name;
                        subTitle = "Deweloper";
                    }
                    if (suggestions.suggest_type === "location") {
                        title = suggestions.name;
                        subTitle = suggestions.full_name || "";
                    }

                    if (suggestions.suggest_type === "investment") {
                        title = suggestions.name;
                        subTitle = "Inwestycja";
                    }

                    return (
                        <ResultsListItem
                            key={idx}
                            idx={idx}
                            activeItemId={props.activeItem.id}
                            title={title}
                            subTitle={subTitle}
                            propertiesCount={propertiesCount}
                            propertiesFormat={propertiesFormat}
                            onClick={onCombinedClick}
                        />
                    );
                })}
            </ul>
        );
    }
    return null;
};
