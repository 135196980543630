import {includes, uniq} from "lodash";
import * as lscache from "lscache";
import {Dispatch} from "redux";
import {getCookie, removeCookie} from "@web2/request_utils";

import {VISITED_COOKIE_NAME, VISITED_LS_NAME} from "../constants/storage_keys";
import {IAction} from "../interfaces/IAction";

export interface ILoadLocalStorageVisitedToStore extends IAction {
    offers: string[];
}

export const LOAD_LOCAL_STORAGE_VISITED_TO_STORE = "LOAD_LOCAL_STORAGE_VISITED_TO_STORE";
export const loadLocalStorageVisitedToStore =
    () =>
    async (dispatch: Dispatch): Promise<any> => {
        const visitedFromCookie = getCookie(VISITED_COOKIE_NAME);
        const visitedFromLocalStorage: string[] = lscache.get(VISITED_LS_NAME) || [];
        const visited = uniq([...(visitedFromCookie ? JSON.parse(visitedFromCookie) : []), ...visitedFromLocalStorage]);

        if (visitedFromCookie) {
            removeCookie(VISITED_COOKIE_NAME);
            lscache.set(VISITED_LS_NAME, visited);
        }
        return dispatch({type: LOAD_LOCAL_STORAGE_VISITED_TO_STORE, offers: visited});
    };

export interface ISetVisitedOffer extends IAction {
    offer: string;
}

export const SET_VISITED_OFFER = "SET_VISITED_OFFER";
export const setVisitedOffer = (offer: string) => {
    const visitedFromCookie = getCookie(VISITED_COOKIE_NAME);
    const visitedFromLocalStorage: string[] = lscache.get(VISITED_LS_NAME) || [];
    const visited = uniq([...(visitedFromCookie ? JSON.parse(visitedFromCookie) : []), ...visitedFromLocalStorage]);

    if (visited) {
        if (includes(visited, offer)) {
            return;
        }
        const offersToBeSaved = [...visited, offer];
        lscache.set(VISITED_LS_NAME, offersToBeSaved);
    } else {
        lscache.set(VISITED_LS_NAME, [offer]);
    }
};
