import {find, includes} from "lodash";

import {ILocationShort} from "../list/actions/fetch_location_by_slug_at_route";

// Used in both title tag and h1 title on page.
// List of counties that exist in several voivodeships. If one of them, we should add voivodeship.
const exceptionCounties = ["bielski", "brzeski", "grodziski", "krośnieński", "nowodworski", "opolski", "ostrowski", "średzki", "świdnicki", "tomaszowski"];

const exceptionCities = ["Dobra", "Józefów", "Oleśnica"];

export const locationNameParser = (paths: ILocationShort[]) => {
    const findRegion = (type: string) => find(paths, (path) => path.type === type);
    const foundCityObject = findRegion("Miasto");
    const foundTownObject = findRegion("Miejscowość");
    const foundCityDistrictObject = findRegion("Dzielnica");
    const foundCityEstateObject = findRegion("Osiedle");
    const foundCountyObject = findRegion("Powiat");
    const foundVoivodeshipObject = findRegion("Województwo");
    const foundAggregationRegion = findRegion("Region agregacyjny");

    // Exception city found.
    if (foundCityObject && includes(exceptionCities, foundCityObject.name) && foundCountyObject) {
        return `${foundCityObject.name}, pow. ${foundCountyObject.name}`;
    }
    // Exception county found, no cities nor nothing.
    if (foundCountyObject && includes(exceptionCounties, foundCountyObject.name) && !foundCityObject && !foundTownObject && foundVoivodeshipObject) {
        return `pow. ${foundCountyObject.name}, woj. ${foundVoivodeshipObject.name}`;
    }
    // Rewrite how we display county if only town in it.
    if (foundCountyObject && foundTownObject) {
        return `${foundTownObject.name}, pow. ${foundCountyObject.name}`;
    }
    // Rewrite how we display county if nothing in it.
    if (foundCountyObject && !foundCityObject && !foundTownObject) {
        return `pow. ${foundCountyObject.name}`;
    }

    if (foundCityObject) {
        if (foundCityEstateObject) {
            return `${foundCityObject.name}, ${foundCityEstateObject.name}`;
        }
        if (foundCityDistrictObject) {
            return `${foundCityObject.name}, ${foundCityDistrictObject.name}`;
        }

        return foundCityObject.name;
    }

    if (foundAggregationRegion) {
        return foundAggregationRegion.name;
    }

    if (foundVoivodeshipObject) {
        return foundVoivodeshipObject.name;
    }

    return "";
};

export const locationNameWhereParser = (paths: ILocationShort[], nameWhere: string) => {
    const findRegion = (type: string) => find(paths, (path) => path.type === type);
    const foundCityObject = findRegion("Miasto");
    const foundTownObject = findRegion("Miejscowość");
    const foundCityDistrictObject = findRegion("Dzielnica");
    const foundCityEstateObject = findRegion("Osiedle");
    const foundCountyObject = findRegion("Powiat");
    const foundVoivodeshipObject = findRegion("Województwo");

    // Exception city found.
    if (foundCityObject && includes(exceptionCities, foundCityObject.name) && foundCountyObject) {
        return `${nameWhere}, pow. ${foundCountyObject.name}`;
    }
    // Exception county found, no cities nor nothing.
    if (foundCountyObject && includes(exceptionCounties, foundCountyObject.name) && !foundCityObject && !foundTownObject && foundVoivodeshipObject) {
        return `${nameWhere}, woj. ${foundVoivodeshipObject.name}`;
    }
    // Rewrite how we display county if only town in it.
    if (foundCountyObject && foundTownObject) {
        return `${nameWhere}, pow. ${foundCountyObject.name}`;
    }
    // Rewrite how we display county if nothing in it.
    if (foundCountyObject && !foundCityObject && !foundTownObject) {
        return nameWhere;
    }

    if (foundCityObject) {
        if (foundCityEstateObject) {
            return `${nameWhere}, ${foundCityObject.name}`;
        }
        if (foundCityDistrictObject) {
            return `${nameWhere}, ${foundCityObject.name}`;
        }
    }
    return nameWhere;
};

export function cutVoivodeship(location: string): string {
    const parts = location.split(", ");

    if (parts.length === 1) {
        return parts.join("");
    }

    return parts.slice(1).join(", ");
}
