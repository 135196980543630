import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const LegalStatusIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.08mm"} height={props.height || "8.69mm"} viewBox="0 0 22.9 24.63">
            <path
                d="M21.15,20.63h-.43a1.69,1.69,0,0,0,.18-.75v-.5a1.76,1.76,0,0,0-1.75-1.75h-5.5a1.75,1.75,0,0,0-1.75,1.75v.5a1.69,1.69,0,0,0,.17.75h-.42A1.75,1.75,0,0,0,9.9,22.38v.5a1.75,1.75,0,0,0,1.75,1.75h9.5a1.75,1.75,0,0,0,1.75-1.75v-.5A1.76,1.76,0,0,0,21.15,20.63ZM13.4,19.38a.25.25,0,0,1,.25-.25h5.5a.26.26,0,0,1,.25.25v.5a.25.25,0,0,1-.25.25h-5.5a.25.25,0,0,1-.25-.25Zm8,3.5a.25.25,0,0,1-.25.25h-9.5a.25.25,0,0,1-.25-.25v-.5a.25.25,0,0,1,.25-.25h9.5a.26.26,0,0,1,.25.25Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M10.07,11.86l-3.46-2-.75,1.3h0l1.08.63L4.4,16.19A1.72,1.72,0,0,0,2.49,17L.23,20.9a1.77,1.77,0,0,0-.17,1.33,1.71,1.71,0,0,0,.82,1.06l.43.25a1.7,1.7,0,0,0,.87.24,1.82,1.82,0,0,0,.46-.06A1.71,1.71,0,0,0,3.7,22.9L6,19a1.75,1.75,0,0,0-.25-2.07l2.54-4.4,1.08.62h0Zm-5.42,6.4L2.4,22.15a.25.25,0,0,1-.15.12.23.23,0,0,1-.19,0L1.62,22a.24.24,0,0,1-.11-.15.27.27,0,0,1,0-.19l2.25-3.89A.26.26,0,0,1,4,17.63a.31.31,0,0,1,.13,0l.43.25A.26.26,0,0,1,4.65,18.26Z"
                fill={props.mainColor || "#37474f"}
            />
            <rect x="11.47" y="2.15" width="1.5" height="4" transform="translate(2.51 12.66) rotate(-60)" fill={props.mainColor || "#37474f"} />
            <path
                d="M6.61,9.86l3.5-6.06.75-1.3h0a1,1,0,0,0-.37-1.37L8.76.13A1,1,0,0,0,7.4.5l-5,8.66a1,1,0,0,0,.36,1.37l1.73,1a1,1,0,0,0,1.37-.37h0Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M12.79,15.16l5-8.66a1,1,0,0,0-.37-1.37l-1.73-1a1,1,0,0,0-1.37.37h0l-.75,1.3-3.5,6.06-.75,1.3h0a1,1,0,0,0,.37,1.37l1.73,1A1,1,0,0,0,12.79,15.16Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
