import {ILocation} from "../list/actions/fetch_location_by_slug_at_route";
import {OfferDealType} from "./constants_offer";

export type UOfferTypesEN = "apartment" | "house" | "property" | "lot";
export const MINIMAL_OFFER_COUNT = 1;

export const locationOfferCount = (dealType: OfferDealType, offerType: UOfferTypesEN, location: ILocation) => {
    if (offerType === "property") {
        return location.offer_count[dealType];
    }

    return (location.offer_count as any)[`${offerType}_${dealType}`];
};
