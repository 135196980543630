import {combineReducers, Reducer} from "redux";

import {fetchInvestmentDetailOverpassPoiTypes, IOverpassPlace} from "../../../app/actions/fetch_overpass_map_poi";
import {IInvestmentDetailResponse} from "../../../app/interfaces/response/investment";
import {IInvestmentOfferResponse} from "../../../app/interfaces/response/investment_offer";
import {reduceRequestState, RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../../app/utils/request_response_utils/factories/reduce_response";
import {fetchInvestmentDetailTypes} from "../actions/fetch_investment_at_route";

export interface IInvestmentOffers {
    // TODO: add correct investment offer type:`IOfferDetailResponse` is not correct interface... investment offers objects contain maybe 1/2 of this type...
    offers: IInvestmentOfferResponse[];
    page: number;
    pages: number;
    totalInvestments: number;
    totalOffers: number;
}

export interface IInvestmentStore {
    investment: IInvestmentDetailResponse;
    investmentRequestState: RequestState;
    investmentPoi: IOverpassPlace[];
    investmentPoiRequestState: RequestState;
}

const investmentReducerObj: Record<keyof IInvestmentStore, Reducer<any, any>> = {
    investment: reduceResponse(fetchInvestmentDetailTypes),
    investmentRequestState: reduceRequestState(fetchInvestmentDetailTypes),
    investmentPoi: reduceResponseField(fetchInvestmentDetailOverpassPoiTypes, "elements", []),
    investmentPoiRequestState: reduceRequestState(fetchInvestmentDetailOverpassPoiTypes)
};

export const investmentReducer = combineReducers(investmentReducerObj);
