import type {Dispatch, ReactNode, SetStateAction} from "react";
import React, {Fragment, useRef, useState} from "react";
import {
    arrow,
    autoUpdate,
    ExtendedRefs,
    flip,
    FloatingArrow,
    FloatingFocusManager,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from "@floating-ui/react";

export interface PopoverProps {
    children: ({refs, getReferenceProps}: {refs: ExtendedRefs<unknown>; getReferenceProps: () => Record<string, unknown>}) => ReactNode;
    className?: string;
    placement?: Placement;
    popover: ReactNode;
    externalOpenState?: [boolean, Dispatch<SetStateAction<boolean>>];
    arrow?: {
        fill: string;
    };
    disableFocus?: boolean;
}

export const Popover = (props: PopoverProps) => {
    const internalOpenState = useState(false);
    const [isOpen, setIsOpen] = props.externalOpenState || internalOpenState;

    const arrowRef = useRef(null);
    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: props.placement || "bottom",
        middleware: [offset(10), flip({fallbackAxisSideDirection: "end"}), shift(), arrow({element: arrowRef})],
        whileElementsMounted: autoUpdate
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);

    const role = useRole(context);

    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss, role]);

    return (
        <Fragment>
            {props.children({refs, getReferenceProps})}

            {isOpen && (
                <FloatingFocusManager context={context} modal={false} disabled={props.disableFocus}>
                    <div className={props.className} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                        {props.popover}
                        {props.arrow ? <FloatingArrow ref={arrowRef} context={context} fill={props.arrow.fill} /> : null}
                    </div>
                </FloatingFocusManager>
            )}
        </Fragment>
    );
};
