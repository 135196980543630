import {Dispatch} from "redux";
import {addNotification} from "@web2/notifications";

import {apiLink} from "../../app/routes/api_link";
import {getRequest} from "../../app/utils/request_response_utils/request";

type PrivacyPolicyResponse = {
    privacy_policy_url: string | null;
    privacy_policy_file: string | null;
};

/**
 * fetch privacy policy links for offer
 * @param offerId : string
 * @constructor
 */
export const OfferPrivacyPolicyFetch =
    (offerId?: string) =>
    async (dispatch: Dispatch): Promise<PrivacyPolicyResponse | null> => {
        if (!offerId) {
            return null;
        }
        const url = apiLink.offers.privacyPolicy({})({offerId: offerId});
        return getRequest({}, url).catch((e) =>
            dispatch(addNotification({type: "error", content: "Wystąpił problem z pobraniem informacji o polityce prywatności."}))
        );
    };
