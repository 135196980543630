import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {isEmpty} from "lodash";
import {TickIcon} from "@web2/icons";

import {FormErrorMessage} from "../FormErrorMessage";

export interface IProps<T> {
    name: string;
    error?: string[] | null;
    errorOnBottom?: boolean;
    onAfterChange?: (name: string, value: T) => void;
    onChange: (name: string, value: T) => void;
    value: T;
    icon?: JSX.Element;
    label?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    testId?: string;
}

export const Checkbox: React.FC<IProps<boolean>> = (props) => {
    const onChange = () => {
        if (props.disabled) return;

        props.onChange(props.name, !props.value);
        props.onAfterChange && props.onAfterChange(props.name, !props.value);
    };

    const hasError = !!props.error && !isEmpty(props.error);
    const isActive = props.value && "active";
    const className = classNames("btn-checkbox", props.className, isActive);

    return (
        <CheckboxWrapper className={className} onClick={onChange} disabled={props.disabled}>
            {props.icon && <div css={additionalIcon}>{props.icon}</div>}
            {props.error && !props.errorOnBottom && <FormErrorMessage error={props.error} />}

            <div css={checkboxContentHolder} data-testid={props.testId}>
                <CheckboxIconHolder className="icon-checkbox" hasError={hasError} disabled={props.disabled}>
                    <IconWrapper>{props.value && <TickIcon height={10} width={14} mainColor="#fff" />}</IconWrapper>
                </CheckboxIconHolder>

                <CheckboxLabel hasError={hasError} disabled={props.disabled}>
                    {props.label}
                </CheckboxLabel>
                {props.error && props.errorOnBottom && <FormErrorMessage error={props.error} className="bottom-error" />}
            </div>
        </CheckboxWrapper>
    );
};

/*
Styles
 */
const INPUT_BORDER_FOCUS = "#45bc83";

const CheckboxWrapper = styled("div")<{disabled?: boolean}>`
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.4;
        `}

    position: relative;
    &.btn-checkbox {
        background-color: transparent;
        border: none;
        border-radius: 0;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: #37474f;
        line-height: 1.15;
        user-select: none;

        .icon-checkbox {
            background-color: #fff;
        }

        &.active,
        &.active:focus,
        &:active,
        &:active:focus,
        &:focus,
        &:hover {
            outline: 0;
            box-shadow: none;

            .icon-checkbox {
                border-color: ${INPUT_BORDER_FOCUS};
            }
        }

        &.active:focus,
        &:active,
        &:active:focus,
        &:focus {
            .icon-checkbox {
                box-shadow: 0 0 0 1px rgba(red(${INPUT_BORDER_FOCUS}), green(${INPUT_BORDER_FOCUS}), blue(${INPUT_BORDER_FOCUS}), 0.5);
            }
        }

        &.active {
            .icon-checkbox {
                background-color: ${INPUT_BORDER_FOCUS};
                border-color: ${INPUT_BORDER_FOCUS};
            }
        }
    }

    &.mt-20 {
        margin-top: 20px;
    }
`;

const IconWrapper = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

interface IStyledProps {
    hasError: boolean;
    disabled?: boolean;
}

const CheckboxLabel = styled.span<IStyledProps>`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding-top: 1px;
    ${(props) =>
        props.hasError &&
        css`
            color: ${props.theme.colors.brand_danger};
        `}
`;

const additionalIcon = css`
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckboxIconHolder = styled.div<IStyledProps>`
    flex-grow: 0;
    flex-shrink: 0;

    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
    border-radius: 3px;
    transition-property: border, background;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    border: 1px solid ${(props) => (props.hasError ? props.theme.colors.brand_danger : props.theme.colors.gray_brighter)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const checkboxContentHolder = css`
    display: flex;
    flex-direction: row;
`;
