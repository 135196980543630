import {isEqual} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {isServer} from "../../../app/utils/read_environment_variables";
import {enable301ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";
import {getOfferListUrl} from "../url_utils/get_offer_list_url";
import {validateOfferListTagWithParams, validateRangeFilterParams} from "../url_utils/tag_utils";
import {ISearchResultRouteParams} from "./fetch_offer_list_at_route";

export const redirectConstraintsAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const {page: strPage, rooms, ...restQuery} = route.query;
        const intPage = parseInt(strPage as string, 10);

        // Redirect if requested url contains page=1.
        if (!isNaN(intPage) && intPage === 1) {
            dispatch(enable301ResponseState(appendQueryString(route.pathname, restQuery)));
            return false; // stop data-fetcher
        }

        if (rooms) {
            // Do some crazy FutureMind frontend params validation. Don't ask, be aware.
            const invalidRoomsParams = rooms.split(",").filter((value: string) => isNaN(parseInt(value, 10)) || +value < 0 || +value > 5);
            if (invalidRoomsParams.length) {
                dispatch(enable301ResponseState(appendQueryString(route.pathname, {strPage, ...restQuery})));
                return false;
            }
        }

        // for tag/params -related redirects
        const currentParams = {...route.params, ...route.query};
        const locationType = getState().offerList.location.location?.location_type;
        const validatedParams = validateOfferListTagWithParams(currentParams, locationType);
        const validatedRangeFilterParams = validateRangeFilterParams(validatedParams);

        if (isServer && !isEqual(currentParams, validatedRangeFilterParams)) {
            // current params are invalid, redirect
            dispatch(enable301ResponseState(getOfferListUrl(validatedRangeFilterParams, locationType)));
            return false;
        }

        return true; // continue data-fetcher
    };
