import {css} from "@emotion/react";
import {appLink} from "@web2/gh_routes";
import {FacebookFIcon, FlagUaIcon, InstagramIcon, LinkedinIcon, YouTubeIcon} from "@web2/icons";

import {getOfferListUrl} from "../offer/list/url_utils/get_offer_list_url";
import {DealTypeSlug, OfferType} from "../offer/utils/constants_offer";
import {IFooterMenuData} from "./Footer";

const flagUa = css`
    position: relative;
    left: 1rem;
    top: 0.1rem;
`;

export const footer_links: IFooterMenuData = {
    partner: [
        {title: "rynekpierwotny.pl", url: "https://rynekpierwotny.pl/", target: "_blank"},
        {title: "buylando.pl", url: appLink.buylando(), target: "_blank"}
    ],
    search: [
        {
            title: "Nieruchomości",
            url: getOfferListUrl({
                offerType: OfferType.property
            })
        },
        {
            title: "Mieszkania",
            url: getOfferListUrl({
                offerType: OfferType.apartment,
                type: DealTypeSlug.SELL
            })
        },
        {
            title: "Domy",
            url: getOfferListUrl({
                offerType: OfferType.house,
                type: DealTypeSlug.SELL
            })
        },
        {
            title: "Działki",
            url: getOfferListUrl({
                offerType: OfferType.lot
            })
        },
        {
            title: "Mapa strony",
            url: appLink.siteMap.base()
        },
        {
            title: "Agencje nieruchomości",
            url: appLink.agency.base()
        },
        {
            title: "Deweloperzy",
            url: appLink.developer.base()
        }
    ],
    information: [
        {title: "O nas", url: appLink.aboutUs()},
        {title: "Dla Pośrednika", url: appLink.forAgent()},
        {title: "Dla Ukrainy", url: appLink.article.detailOrCategoryList({slug: "yak-znyati-kvartiru-v-polshi"}), icon: <FlagUaIcon size="1.4" css={flagUa} />},
        {title: "Kariera", url: "https://propertygroup.pl/pracuj-z-nami/"},
        {title: "Autorzy bloga", url: appLink.article.author.base()},
        {title: "Polityka prywatności", url: appLink.termsRodo(), target: "_blank"},
        {title: "Regulamin", url: appLink.terms(), target: "_blank"}
    ]
};

export const footer_social_links = [
    {url: "https://www.facebook.com/gethomepl/", icon: <FacebookFIcon size="3" fillColor="#fff" />},
    {url: "https://www.instagram.com/portal_gethome/", icon: <InstagramIcon size="3" fillColor="#fff" />},
    {url: "https://www.linkedin.com/showcase/gethomepl/", icon: <LinkedinIcon size="3" fillColor="#fff" />},
    {url: "https://www.youtube.com/channel/UCXrxhSeBouvm4bGlP5IvSsw", icon: <YouTubeIcon size="3" fillColor="#fff" />}
];
