import {map} from "lodash";

import {getOfferListUrl} from "../offer/list/url_utils/get_offer_list_url";
import {DealTypeSlug, OfferType} from "../offer/utils/constants_offer";
import {gtmNavigationMenuClick} from "../tracking/google_tag_manager/gtm_navigation_menu_click";

const NAV_REGIONS_FOR_SALE_FLATS = [
    {name: "Warszawa", slug: "warszawa"},
    {name: "Kraków", slug: "krakow"},
    {name: "Łódź", slug: "lodz"},
    {name: "Wrocław", slug: "wroclaw"},
    {name: "Poznań", slug: "poznan"},
    {name: "Gdańsk", slug: "gdansk"},
    {name: "Gdynia", slug: "gdynia"},
    {name: "Katowice", slug: "katowice"}
];

const NAV_REGIONS_FOR_RENT_FLATS = [
    {name: "Warszawa", slug: "warszawa"},
    {name: "Kraków", slug: "krakow"},
    {name: "Łódź", slug: "lodz"},
    {name: "Wrocław", slug: "wroclaw"},
    {name: "Poznań", slug: "poznan"},
    {name: "Gdańsk", slug: "gdansk"},
    {name: "Gdynia", slug: "gdynia"},
    {name: "Katowice", slug: "katowice"}
];

export const NAV_FOR_SALE_FLATS_SECOND_LEVEL = map(NAV_REGIONS_FOR_SALE_FLATS, (region) => {
    return {
        title: region.name,
        url: getOfferListUrl({
            offerType: OfferType.apartment,
            type: DealTypeSlug.SELL,
            location: region.slug
        }),
        onClick: () => gtmNavigationMenuClick("2", `Mieszkania ${region.name}`)
    };
});

export const NAV_FOR_RENT_FLATS_SECOND_LEVEL = map(NAV_REGIONS_FOR_RENT_FLATS, (region) => {
    return {
        title: `Wynajem ${region.name}`,
        url: getOfferListUrl({
            offerType: OfferType.apartment,
            type: DealTypeSlug.RENT,
            location: region.slug
        }),
        onClick: () => gtmNavigationMenuClick("2", `Mieszkania ${region.name}`)
    };
});
