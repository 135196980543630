import {DealTypeSlug, OfferDealType} from "../offer/utils/constants_offer";

export const translateDealTypeToReadablePL = (typeSlug?: DealTypeSlug | OfferDealType) => {
    switch (typeSlug) {
        case DealTypeSlug.RENT:
        case OfferDealType.RENT:
            return "na wynajem";
        case DealTypeSlug.SELL:
        case OfferDealType.SELL:
        default:
            return;
    }
};
