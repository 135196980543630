import {each, isEmpty, isPlainObject} from "lodash";
import * as pathToRegexp from "path-to-regexp";
import {IAuthorDetailParams, IIndividualOffersVerifyOrRenew} from "@web2/gh_routes";
import {prefixPath} from "@web2/prefix_path";

import {ghApiUrl} from "../utils/read_environment_variables";

type TDataQuery<T> = {
    [P in keyof T]?: TDataQuery<T[P]> | boolean;
};

/**
 * Params Interfaces
 */

interface IApiOfferDetailParams<Type = number | string> {
    offerId: Type;
}

interface IApiInvestmentDetailParams {
    investmentId: string | number;
}

interface IApiLocationDetailParams {
    slug: string;
}

interface IAgentDetailParams {
    agentSlug: string;
}

interface IAgencyDetailParams {
    agencyId: string;
}
interface IAgencyListParams {
    location?: string;
}
interface IDeveloperDetailParams {
    developerId: number | string;
}

interface IArticleDetailParams {
    articleSlug: string;
}

interface IApiSimilarOfferParams {
    offerId: string | undefined;
    size?: number;
}

interface IApiApplicationDetailParams {
    offerId: string | undefined;
}

interface IPageDetailParams {
    pageSlug: string;
}

interface IApiSimilarOfferParams {
    offerId: string | undefined;
}

export interface IIndividualOffersParams {
    uuid: string;
    propertyType: string;
}
interface IIndividualOffersPropertyTypeParams {
    propertyType: string;
}

export interface IOfferPicture {
    offerId: string;
}
export interface IInvestmentPicture {
    investmentId: string;
}

// allow all characters to be used in a search phrase
const SEARCH_PHRASE_PARAMETER = `:searchPhrase(.+)?/`;

/**
 * API path
 */

export const apiPath = prefixPath("/", {
    agent: prefixPath("agents/", {
        base: "",
        detail: ":agentSlug([\\w-]+)/"
    }),
    agencies: prefixPath("agencies/", {
        base: "",
        agency: ":agencyId([\\w-]+)/",
        agents: ":agencyId([\\w-]+)/agents/",
        registrations: prefixPath("registrations/", {
            simple: "simple/"
        })
    }),
    applications: "applications/",
    articles: prefixPath("articles/", {
        base: "",
        article: prefixPath(":articleSlug([\\w-]+)/", {
            base: "",
            related: "related/"
        }),
        authors: prefixPath("authors/", {
            base: "",
            detail: ":authorSlug([\\w-]+)/"
        }),
        authorArticles: "for-authors/",
        categories: "categories/"
    }),
    clipPhones: prefixPath("clipphones/", {
        rotatedNumbers: prefixPath("rotated/", {
            offer: "offer/:uuid([\\w-]+)/"
        })
    }),
    developers: prefixPath("developers/", {
        base: "",
        developer: prefixPath(":developerId([\\w-]+)/", {
            base: ""
        })
    }),
    investments: prefixPath("investments/", {
        detail: prefixPath(":investmentId([\\w-]+)/", {
            base: "",
            offers: "offers/",
            pictures: "pictures/"
        })
    }),
    locations: prefixPath("locations/", {
        base: "",
        detail: prefixPath(":slug(.+)?/", {
            base: "",
            statistics: "statistics/"
        })
    }),
    offers: prefixPath("offers/", {
        base: "",
        recommended: "recommended/",
        coordinates: "coordinates/",
        byPropertyId: "by_property_id/:id([\\w-]+)/",
        detail: prefixPath(":offerId([\\w-]+)/", {
            base: "",
            application: "application/",
            similar: "similar/",
            pictures: "pictures/",
            features: "features/",
            privacyPolicy: "privacy-policy/"
        }),
        individual: prefixPath("individual/", {
            base: "",
            baseWithParams: ":propertyType([\\w]+)/:uuid([\\w-]+)/",
            offerCreate: ":propertyType([\\w]+)/",
            edit: ":propertyType([\\w]+)/:uuid([\\w-]+)/",
            gallery: "gallery/",
            activate: ":propertyType([\\w]+)/:uuid([\\w-]+)/activate/",
            deactivate: ":propertyType([\\w]+)/:uuid([\\w-]+)/deactivate/",
            verify: "verify/:uuid([\\w-]+)/:token([\\w-]+)/", // verifies individual offer
            renew: "renew/:uuid([\\w-]+)/:token([\\w-]+)/", // renew expiring individual offer
            resend: ":propertyType([\\w]+)/:uuid([\\w-]+)/resend/"
        })
    }),
    offerList: prefixPath("offers/", {
        base: "",
        stats: "stats/"
    }),
    pages: prefixPath("pages/", {
        page: ":pageSlug([\\w-]+)"
    }),
    redirects: prefixPath("redirects/", {
        redirect: "redirect/"
    }),
    seoDescription: "seo-description/", // endpoint in case of a missing region,
    sessions: "sessions/",
    suggestions: prefixPath("suggestions/", {
        base: "",
        searchPhrase: SEARCH_PHRASE_PARAMETER
    }),
    userApi: prefixPath("user-api/", {
        oauth: prefixPath("oauth/", {
            authorize: "authorize/",
            token: "token/"
        }),
        proxy: prefixPath("proxy/", {
            favorites: prefixPath("favorites/", {
                favorite: "favorite/"
            }),
            user: prefixPath("user/", {
                info: "info/"
            })
        }),
        users: prefixPath("users/", {
            info: "info/",
            logout: "logout/"
        }),
        session: prefixPath("session/", {
            sync: "sync/"
        })
    }),
    metaData: prefixPath("metadata/", {
        base: "",
        page: "page/"
    }),
    siteMap: "sitemap.json"
});

/**
 * API link
 */

export const apiLink = {
    applications: createLink(apiPath.applications),
    articles: {
        base: createLink(apiPath.articles.base),
        article: {
            base: createLink<IArticleDetailParams>(apiPath.articles.article.base),
            related: createLink<IArticleDetailParams>(apiPath.articles.article.related)
        },
        authors: {
            base: createLink(apiPath.articles.authors.base),
            author: createLink<IAuthorDetailParams>(apiPath.articles.authors.detail)
        },
        authorArticles: createLink(apiPath.articles.authorArticles),
        categories: createLink(apiPath.articles.categories)
    },
    agent: {
        base: createLink(apiPath.agent.base),
        detail: createLink<IAgentDetailParams>(apiPath.agent.detail)
    },
    agencies: {
        base: createLink(apiPath.agencies.agency),
        agencyList: createLink<IAgencyListParams>(apiPath.agencies.base),
        agency: createLink<IAgencyDetailParams>(apiPath.agencies.agency),
        agents: createLink<IAgencyDetailParams>(apiPath.agencies.agents),
        registrations: {
            simple: createLink(apiPath.agencies.registrations.simple)
        }
    },
    developers: {
        base: createLink(apiPath.developers.base),
        developer: createLink<IDeveloperDetailParams>(apiPath.developers.developer.base)
    },
    offers: {
        base: createLink(apiPath.offers.base),
        coordinates: createLink(apiPath.offers.coordinates),
        detail: {
            base: createLink<IApiOfferDetailParams>(apiPath.offers.detail.base),
            features: createLink<IApiOfferDetailParams>(apiPath.offers.detail.features)
        },
        individual: {
            base: createLink(apiPath.offers.individual.base),
            baseWithParams: createLink<IIndividualOffersParams>(apiPath.offers.individual.baseWithParams),
            offerCreate: createLink<IIndividualOffersPropertyTypeParams>(apiPath.offers.individual.offerCreate),
            edit: createLink<IIndividualOffersParams>(apiPath.offers.individual.edit),
            gallery: createLink(apiPath.offers.individual.gallery),
            activate: createLink<IIndividualOffersParams>(apiPath.offers.individual.activate),
            deactivate: createLink<IIndividualOffersParams>(apiPath.offers.individual.deactivate),
            verify: createLink<IIndividualOffersVerifyOrRenew>(apiPath.offers.individual.verify),
            renew: createLink<IIndividualOffersVerifyOrRenew>(apiPath.offers.individual.renew),
            resend: createLink<IIndividualOffersParams>(apiPath.offers.individual.resend)
        },
        application: createLink<IApiApplicationDetailParams>(apiPath.offers.detail.application),
        byPropertyId: createLink<{id: string}>(apiPath.offers.byPropertyId),
        similar: createLink<IApiSimilarOfferParams>(apiPath.offers.detail.similar),
        pictures: createLink<IOfferPicture>(apiPath.offers.detail.pictures),
        privacyPolicy: createLink<IApiOfferDetailParams<string>>(apiPath.offers.detail.privacyPolicy) // accepts only UUID
    },
    offerList: {
        base: createLink(apiPath.offerList.base),
        stats: createLink(apiPath.offerList.stats)
    },
    investment: {
        detail: createLink<IApiInvestmentDetailParams>(apiPath.investments.detail.base),
        offers: createLink<IApiInvestmentDetailParams>(apiPath.investments.detail.offers),
        pictures: createLink<IInvestmentPicture>(apiPath.investments.detail.pictures)
    },
    locations: {
        base: createLink(apiPath.locations.base),
        detail: createLink<IApiLocationDetailParams>(apiPath.locations.detail.base),
        statistics: createLink<IApiLocationDetailParams>(apiPath.locations.detail.statistics)
    },
    pages: {
        page: createLink<IPageDetailParams>(apiPath.pages.page)
    },
    redirect: {
        redirects: createLink(apiPath.redirects.redirect)
    },
    rotatedNumbers: {
        offer: createLink<{uuid: string}>(apiPath.clipPhones.rotatedNumbers.offer)
    },
    seoDescription: createLinkNoParams(apiPath.seoDescription),
    sessions: createLink(apiPath.sessions),
    suggestion: {
        base: createLink(apiPath.suggestions.base),
        searchPhrase: createLink<{searchPhrase?: string}>(apiPath.suggestions.searchPhrase)
    },
    userApi: {
        favorites: {
            favorite: createLinkNoParams(apiPath.userApi.proxy.favorites.favorite)
        },
        oauth: {
            authorize: createLinkNoParams(apiPath.userApi.oauth.authorize),
            token: createLinkNoParams(apiPath.userApi.oauth.token)
        },
        user: {
            info: createLinkNoParams(apiPath.userApi.proxy.user.info)
        },
        users: {
            info: createLinkNoParams(apiPath.userApi.users.info), // is_authenticated info
            logout: createLinkNoParams(apiPath.userApi.users.logout)
        },
        session: {
            sync: createLinkNoParams(apiPath.userApi.session.sync)
        }
    },
    metaData: {
        page: createLinkNoParams(apiPath.metaData.page)
    }
};

/**
 * Utils
 */

const appendIncludeParams = <T>(pathname: string, dataQuery: TDataQuery<T>): string => {
    if (isEmpty(dataQuery)) {
        return pathname;
    }

    const includes: string[] = [];
    const addParams = (query: Record<string, any>, parentKey?: string) => {
        const prefix = parentKey ? `${parentKey}.` : "";

        if (parentKey && isEmpty(query)) {
            // set default when keys are not defined
            return includes.push(`i[]=${prefix}*`);
        }

        // keys are defined - append them to array
        each(query, (value: boolean | Record<string, any>, key: string) => {
            const currentKey = prefix + key;
            if (isPlainObject(value)) {
                // we have deep object
                return addParams(value as Record<string, any>, currentKey);
            } else if (value) {
                // we have boolean value - add when truthly
                return includes.push(`i[]=${currentKey}`);
            }
        });
    };

    addParams(dataQuery); // fills `includes` array
    return `${pathname}?${includes.join("&")}`;
};

function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return <TQuery>(dataQuery: TDataQuery<TQuery>) => {
        const includeParams = appendIncludeParams("", dataQuery);
        return (apiPathParams: TParams): string => ghApiUrl + compiler(apiPathParams as Object) + includeParams;
    };
}

function createLinkNoParams(apiPathElement: string) {
    return () => ghApiUrl + apiPathElement;
}
