import {Suspense} from "react";
import {IMarker} from "@web2/open-street-map";

import {ISimilarOffersFetch} from "../../application/actions/fetch_multilead_offers";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {MapLoader} from "../../offer/list/components/OfferListMapDesktop";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {ViewType} from "../../tracking/view_type/view_type";
import {getNormalizedCoordinates} from "../utils/get_normalized_coordinates";
import {mapHolder} from "./MultileadMap";
import {OpenStreetMapGH} from "./OpenStreetMapGH";

import aftermarketMarker from "../../styles/assets/svg/aftermarket_marker.svg";
import centerOfferPin from "../../styles/assets/svg/center_offer_pin.svg";
import checkedOffer from "../../styles/assets/svg/checked_offer.svg";
import primaryMarketMarker from "../../styles/assets/svg/primary_market_marker.svg";

interface IMultileadOsmMap {
    markers: Pick<ISimilarOffersFetch, "coordinates" | "market_type" | "id" | "name" | "offer_type">[];
    type: VendorType;
    viewType: ViewType | null;
    offer: {
        coordinates: {
            lat: number;
            lon: number;
        };
        market_type?: OfferMarketType;
        id: string;
        name: string;
    };
    activeOffers: ISimilarOffersFetch[];
}
export const MultileadOsmMap = (props: IMultileadOsmMap) => {
    const _markers: IMarker[] | undefined = [...props.markers, props.offer].map((marker) => {
        const checkedMarker = props.activeOffers.find((offer) => offer.id === marker.id);
        return {
            id: marker.id,
            coords: getNormalizedCoordinates(marker.coordinates),
            icon: {
                url: checkedMarker
                    ? checkedOffer
                    : marker.id === props.offer.id
                      ? centerOfferPin
                      : marker.market_type === OfferMarketType.PRIMARY_MARKET
                        ? primaryMarketMarker
                        : aftermarketMarker,
                sizes: checkedMarker ? [25, 25] : marker.id === props.offer.id ? [44, 60] : [20, 20]
            }
        };
    });

    return (
        <Suspense fallback={<MapLoader />}>
            <div css={mapHolder}>
                <OpenStreetMapGH markers={_markers} />
            </div>
        </Suspense>
    );
};
