import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";

const SET_OFFER_MODAL_STATE_ACTION = "set_offer_modal_state";
export const setOfferModalStateActionTypes = {
    open: SET_OFFER_MODAL_STATE_ACTION + "_open",
    close: SET_OFFER_MODAL_STATE_ACTION + "_close"
};

export const openOfferModalAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const showOfferOnList = getState().offer.offerDetailViewType === "offerList";
    const isModalOpen = getState().offerList.offerModalState;
    if (!isModalOpen && showOfferOnList) {
        dispatch({
            type: setOfferModalStateActionTypes.open
        });
    }

    return true;
};

export const closeOfferModalAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const isModalOpen = getState().offerList.offerModalState;

    if (isModalOpen) {
        dispatch({
            type: setOfferModalStateActionTypes.close
        });
    }

    return true;
};
