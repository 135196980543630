import * as React from "react";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const ArrowRightIcon = (props: IProps) => {
    return (
        <SvgIcon size={props.size || "1"} className={props.className} fillColor={props.fillColor}>
            <path d="M2.39,1.29,6.1,5,2.39,8.72a.75.75,0,0,0,0,1.06.77.77,0,0,0,1.07,0L7.54,5.71a1,1,0,0,0,0-1.41L3.46.22a.77.77,0,0,0-1.07,0A.77.77,0,0,0,2.39,1.29Z" />
        </SvgIcon>
    );
};
