import {Dispatch} from "redux";

import {apiLink, IIndividualOffersParams} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {enable404ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";
import {OfferDealType, OfferType, OfferTypeEN} from "../../offer/utils/constants_offer";
import {IndividualOffersTypeOfProperty} from "../utils/get_mapped_individual_offer_payload";

export interface IndividualOfferDetailResponse {
    advertiser: string;
    available_from: string;
    construction_year: number;
    contact: {
        name: string;
        email: string;
        phone: string;
    };
    coordinates: {
        lat: number;
        lon: number;
    };
    description: string;
    finishing_state: string | null;
    floor: number;
    gallery: {
        o_img_500: string;
        o_img_1500: string;
        id: string;
    }[];
    id: string;
    location: {
        building_number: string;
        city: string;
        district: string;
        street: string;
        voivodeship: string;
    };
    name: string;
    offer_type: OfferType;
    deal_type: OfferDealType;
    parking: string | null;
    price: number;
    rooms: number;
    size: number;
    title: string;
    type_of_property: IndividualOffersTypeOfProperty | null;
    // house
    lot_size: string | null;
    floors: number;
    heat: string | null;
    //lots
    group_media?: string[] | null;
    lot_shape?: string | null;
}

export const fetchIndividualOfferDetailActionTypes = createRequestActionTypes({name: "fetch-offers", type: "GET", view: "individual-offer-detail"});

const getEnglishOfferType = (propertyType: string) => {
    switch (propertyType) {
        case OfferType.lot:
            return OfferTypeEN.lot;
        case OfferType.house:
            return OfferTypeEN.house;
        case OfferType.apartment:
            return OfferTypeEN.apartment;
        case OfferType.property:
        default:
            return OfferTypeEN.property;
    }
};

export const fetchindIvidualOfferDetailAtRoute =
    (services: Partial<IServices>, route: IRouteState<IIndividualOffersParams>) =>
    async (dispatch: Dispatch): Promise<any> => {
        dispatch({type: fetchIndividualOfferDetailActionTypes.start});

        const propertyTypeEn = getEnglishOfferType(route.params.propertyType);
        const url = apiLink.offers.individual.baseWithParams({})({uuid: route.params.uuid, propertyType: propertyTypeEn});

        return getRequest(services, url)
            .then((json: IndividualOfferDetailResponse) => {
                const result = {
                    ...json,
                    offer_type: route.params.propertyType,
                    deal_type: json.offer_type
                };
                dispatch({type: fetchIndividualOfferDetailActionTypes.success, result});
                return result;
            })
            .catch(
                catch404((err) => {
                    dispatch(enable404ResponseState());
                })
            )
            .catch((err) => {
                return dispatch({type: fetchIndividualOfferDetailActionTypes.error, err});
            });
    };
