export const errorMessages = {
    required: "pole jest wymagane",
    unknownError: "Przepraszamy, wystąpił nieoczekiwany błąd. Spróbuj ponownie później.",
    incorrectEmail: "podaj poprawny adres email",
    incorrectRoom: "pokój musi być cyfrą",
    price: "podaj cenę",
    size: "podaj powierzchnię",
    lotSize: "podaj powierzchnię działki",
    lotShape: " podaj kształt działki",
    rooms: "podaj liczbę pokoi",
    floor: "podaj piętro",
    floors: "podaj liczbę kondygnacji",
    locationCity: "podaj miasto",
    locationVoivodeship: "podaj województwo",
    locationDistrict: "podaj dzielnicę",
    locationStreet: "podaj ulicę",
    locationStreetNumber: "podaj numer domu",
    title: "podaj tytuł",
    offer_details: "dodaj opis oferty",
    min_offer_details_length: (count: number) => `Opis oferty powinien mieć co najmniej ${count} znaków`,
    name: "podaj imię",
    consent: "Zaznaczenie tej zgody jest wymagane.",
    declaration: "Zaznaczenie tego oświadczenia jest wymagane.",
    terms_and_conditions: "Akceptacja regulaminu jest wymagana.",
    lotType: "podaj rodzaj działki",
    houseType: "podaj typ domu",
    propertyType: "wybierz typ nieruchomości",
    availableFrom: "podaj datę",
    finishingState: "podaj stan wykończenia",
    higherThenZero: "podaj wartość większą od zero"
};
