import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {numberWithDelimiter} from "@web2/string_utils";

import {TermsRodo} from "../../../../app/components/TermsRodo";
import {getThemeBreakpoint, getThemeVariable} from "../../../../styles/linaria_variable_factory";
import {ellipsis} from "../../../../styles/mixins_linaria";
import {OfferMarketType, PropertyType} from "../../../utils/constants_offer";
import {getMultileadOfferHeading} from "../../../utils/get_offer_heading";
import {VendorType} from "../agency_and_developer/agency_and_developer_utils";
import {OfferBoxGallery} from "./components/OfferBoxGallery";
import {IOfferBoxOffer, OFFERBOX_MAX_MOBILE_BREAKPOINT} from "./OfferBox";

interface IProps {
    offer: IOfferBoxOffer & {
        agency: {
            type?: VendorType;
            name: string;
        } | null;
    };
    className?: string;
    isInvestment?: boolean;
}

export const MultiOfferBox = (props: IProps) => {
    const {offer} = props;
    const {property} = props.offer;
    const propertyType = property.type;

    const getDotColor = () => {
        if (property.type === PropertyType.LOT) {
            return PropertyType.LOT;
        }

        return offer.market_type;
    };

    const mainHeaderHTML = (
        <>
            <TextWithDot bgColor={getDotColor()}>{getMultileadOfferHeading(offer).heading}</TextWithDot>
            <address className={offerBoxPropertyLocation}>{` ${getMultileadOfferHeading(offer).parsedLocation}`}</address>
        </>
    );
    const price = offer.price.total ? `${numberWithDelimiter(offer.price.total)} zł` : "Cena u dewelopera";

    return (
        <OfferBoxHolder className={props.className} propertyType={propertyType}>
            <aside className={offerBoxGalleryLink}>
                <OfferBoxGallery offerPictures={offer.pictures} propertyPlanPicture={property.plan_picture} offerName={offer.name} isMultilead={true} />
            </aside>

            <article className={offerBoxInfoHolder}>
                <h3 className={offerBoxHeading}>{mainHeaderHTML}</h3>

                <div className={offerMultileadDesc}>
                    <span className={investmentPrice}>
                        <img src={offer?.investment?.developer?.logo_picture?.a_log_100} width="60" loading="lazy" alt="logo developera" />{" "}
                        <span className={offerBoxPriceMultilead}>{price}</span>
                    </span>

                    <span className={dataController}>
                        <TermsRodo isPrivate={offer.is_private} offerId={offer.id} vendorName={offer.agency?.name} type={offer.agency?.type} />
                    </span>
                </div>
            </article>
        </OfferBoxHolder>
    );
};

export const OFFERBOX_WIDTH = 306;

export const OfferBoxHolder = styled.div<{
    propertyType?: PropertyType;
}>`
    display: flex;
    flex-direction: row;
    position: relative;
    transition: all 400ms ease-out;
    background-size: cover;
    border-radius: ${getThemeVariable("other-border_radius")};
    border: 1px solid ${getThemeVariable("colors-gray_very_dark")};
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    height: auto;
    width: 100%;
    margin-right: 0.5rem;

    &:hover {
        box-shadow: 0 2px 21px 2px rgba(0, 0, 0, 0.2);
    }

    a {
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    @media (max-width: ${getThemeBreakpoint().screen_sm}) {
        margin-top: 1rem;
        min-width: unset;
        max-width: ${OFFERBOX_WIDTH}px;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
        margin-right: 0;
        flex-direction: column;
        height: auto;
    }
    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        margin-top: 1rem;
    }
`;

const offerBoxGalleryLink = css`
    position: relative;
`;

const offerBoxInfoHolder = css`
    padding: 0.6rem 1rem 1rem;
    font-family: ${getThemeVariable("fonts-font_family")}; // for google map info window
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: space-between;
    background: #fff;
    position: relative;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        flex: 0 0 331px;
    }
`;
const offerBoxHeading = css`
    margin: 0 0.5rem;
    height: 6.9rem;
`;
interface ITextWithDotProps {
    bgColor: OfferMarketType.PRIMARY_MARKET | OfferMarketType.AFTERMARKET | "gray_light" | PropertyType.LOT;
}
const TextWithDot = styled.div<ITextWithDotProps>`
    display: -webkit-box;
    max-height: ${14 * 1.6 * 2}px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.8rem;
    padding-left: 1.3rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6;
    color: ${getThemeVariable("colors-gray_dark")};

    &:last-child {
        margin-right: 0;
    }

    &:before {
        position: absolute;
        top: 0.7rem;
        content: "";
        left: 0;
        width: 0.8rem;
        height: 0.8rem;
        background-color: ${(props) =>
            props.bgColor === OfferMarketType.PRIMARY_MARKET
                ? getThemeVariable("colors-primary_market")
                : props.bgColor === OfferMarketType.AFTERMARKET
                  ? getThemeVariable("colors-aftermarket")
                  : props.bgColor === PropertyType.LOT
                    ? getThemeVariable("colors-lot")
                    : getThemeVariable("colors-gray_light")};
        border-radius: 50%;
        vertical-align: middle;
    }
`;
const offerBoxPropertyLocation = css`
    ${ellipsis};
    color: ${getThemeVariable("colors-gray_lighter")};
    margin-left: 1.2rem;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
`;
const offerBoxPriceMultilead = css`
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0;
`;
const investmentPrice = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.2rem;
    img {
        margin-right: 2rem;
    }
`;
const offerMultileadDesc = css`
    display: block;
`;
const dataController = css`
    font-size: 1rem;
    font-style: normal;
    color: ${getThemeVariable("colors-gray_warm")};
    line-height: 1.2rem;
    display: block;

    @media (max-width: ${getThemeBreakpoint().screen_sm}) {
        padding-bottom: 2rem;
    }
`;
