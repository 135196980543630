import {css, Theme} from "@emotion/react";
import {fontFace} from "@web2/global_styles";

import {BrowserSupportView} from "../app/components/BrowserSupportView";
import {currentStaticsDir} from "../app/constants/current_statics_dir";

const fontVariant = "poppins";
const fontPath = `/${currentStaticsDir}/fonts/${fontVariant}/`;

export const globalStylesGh = (theme: Theme) => css`
    ${fontFace(fontPath, fontVariant, `${fontVariant}_light`, "300", "normal", "swap")};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_regular`, "400", "normal", "swap")};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_medium`, "500", "normal", "swap")};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_semibold`, "600", "normal", "swap")};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_bold`, "700", "normal", "swap")};

    /**
    based on weather "align-items" is supported by browser or not we show/hide browser upgrade message to a user.
    Because "align-items" has the closest correlation to expected browsers compatibility it is being used as indicator.
     */
    @supports not (align-items: center) {
        body {
            overflow: hidden;
        }
        ${BrowserSupportView} {
            display: block !important;
        }
    }

    /*
    Code below should work in case of IE10+, @supports doesn't work there, in order to show BrowserSupportView it needs to be set with @media.   
    */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        body {
            overflow: hidden;
        }
        ${BrowserSupportView} {
            display: block !important;
        }
    }
`;
