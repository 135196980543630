import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {IAgency} from "./fetch_agency_detail_at_route";
import {IAgencyOffer} from "./fetch_developer_offers_at_route";

export interface IAgencyOffersResponse {
    meta: {
        total_investments: number;
        total_offers: number;
        location_description: string;
        total_results: number;
    };
    data: IAgencyOffer[];
}

export const fetchAgencyOffersTypes = createRequestActionTypes({
    view: "Agency",
    type: "GET",
    name: "AgencyDetailOffers"
});

export const fetchAgencyOffersAtRoute =
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IAgency) => (dispatch: Dispatch) => {
        dispatch({type: fetchAgencyOffersTypes.start});

        // dynamic query param name as a workaround for https://app.clickup.com/t/20rfc9p
        // due to elastic configuration we have to download primary market agency offers from /developer/ endpoint
        const queryParam = prevActionResult.market_type === OfferMarketType.PRIMARY_MARKET ? "developer" : "agency";

        const url = appendQueryString(apiLink.offers.base({})(null), {[queryParam]: prevActionResult.id});

        return getRequest(services, url)
            .then((res: IAgencyOffersResponse) => {
                dispatch({type: fetchAgencyOffersTypes.success, result: res.data});
                return res;
            })
            .catch((err) => {
                dispatch({type: fetchAgencyOffersTypes.error, err});
            });
    };
