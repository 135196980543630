import {setDefaultOptions as setOSMDefaultOptions} from "@web2/open-street-map";

import {initSmartAdServer} from "../../src/advertisement/init_sas";
import {fetchSessionCookies} from "../../src/app/actions/fetch_session_cookie";
import {loadLocalStorageFavouritesToStore} from "../../src/app/actions/load_local_storage_favourites_to_store";
import {loadLocalStorageLastSearchToStore} from "../../src/app/actions/load_local_storage_last_search_to_store";
import {loadLocalStorageVisitedToStore} from "../../src/app/actions/load_local_storage_visited_to_store";
import {Error500Dynamic} from "../../src/app/components/Error500Dynamic";
import {GHNotifications} from "../../src/app/components/GHNotifications";
import {NotFoundDynamic} from "../../src/app/components/NotFoundDynamic";
import {fetchUserProfile} from "../../src/app/user/actions/fetch_user_profile";
import {checkIsAuthenticated} from "../../src/app/user/actions/is_authenticated_actions";
import {getSyncUserSessionCookieOnClient} from "../../src/app/user/actions/sync_user_session";
import {initDebugBearRUM} from "../../src/app/utils/init_debugbear_rum";
import {algolyticsId, enableAds, enableAuthorization, osmTileUrl, sasPlacementSite, userComId} from "../../src/app/utils/read_environment_variables";
import {NavigationGh} from "../../src/navigation/NavigationGh";
import {initAlgolytics} from "../../src/tracking/algolytics/init_algolytics";
import {ghGtmInit} from "../../src/tracking/google_tag_manager/gtm_init";
import {initUserCom} from "../../src/tracking/user_com/init_user_com";
import {createHydrateApp} from "./create_hydrate_app";
import {getClientStore} from "./get_client_store";
import {cacheUtmData} from "./save_utm_data";

import "../../src/styles/linaria_global_styles_gh";

const store = getClientStore();
export const prepareHydratedApp = async (config: any) => {
    if (!store) {
        return;
    }

    if (enableAuthorization) {
        getSyncUserSessionCookieOnClient();
    }
    fetchSessionCookies();

    // init scripts
    initAlgolytics(algolyticsId);
    initUserCom(userComId);
    ghGtmInit(store.getState());
    initDebugBearRUM();
    enableAds && initSmartAdServer(sasPlacementSite);

    cacheUtmData();

    if (osmTileUrl) {
        setOSMDefaultOptions({
            tileUrl: osmTileUrl,
            tileSize: 256,
            tileCrossOrigin: false,
            defaultMinZoom: 6.49,
            defaultMaxZoom: 16
        });
    }

    // middleware actions
    if (enableAuthorization) {
        if (await store.dispatch(checkIsAuthenticated())) {
            await store.dispatch(fetchUserProfile());
        }
    }
    await store.dispatch(loadLocalStorageFavouritesToStore());
    await store.dispatch(loadLocalStorageVisitedToStore());
    await store.dispatch(loadLocalStorageLastSearchToStore());

    hydrateAllViews(store, config);
};

const defaultConfig = [
    {reactDynamic: "error-500-dynamic", view: <Error500Dynamic />},
    {reactDynamic: "navigation", view: <NavigationGh />},
    {reactDynamic: "notifications", view: <GHNotifications />},
    {reactDynamic: "not-found-dynamic", view: <NotFoundDynamic />}
];

const hydrateAllViews = (store: any, config: any) => {
    const componentHooks = document.querySelectorAll("[react-dynamic]");
    const combineConfig = [...defaultConfig, ...config];

    componentHooks.forEach((element: Element) => {
        const appName = element.getAttribute("react-dynamic");
        const found = combineConfig.some((el) => {
            if (el.reactDynamic === appName) {
                createHydrateApp(store, element, el.view);
                return true;
            }
            return false;
        });

        if (!found) {
            throw new Error(`desktop-hybrid: element ${appName} not found`);
        }
    });
};
