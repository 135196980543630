import React, {HTMLAttributeAnchorTarget} from "react";
import styled from "@emotion/styled";

export interface IFooterMenuElement {
    title: string;
    url?: string;
    rel?: string;
    target?: HTMLAttributeAnchorTarget;
    titleAttribute?: string;
    icon?: JSX.Element;
    onClick?: () => void;
}

export interface IFooterMenuInteractiveElements {
    title: string;
    icon?: JSX.Element;
    onClick: () => void;
    titleAttribute?: never;
    target?: never;
    url?: never;
    rel?: never;
}

export const FooterMenuElement = (props: IFooterMenuElement | IFooterMenuInteractiveElements) => {
    return (
        <FooterMenuLi>
            <StyledLink href={props.url} rel={props.rel} target={props.target} title={props.titleAttribute} onClick={props.onClick}>
                {props.title}
            </StyledLink>

            {props.icon && props.icon}
        </FooterMenuLi>
    );
};

const FooterMenuLi = styled.li`
    list-style-type: none;
`;

const StyledLink = styled.a`
    line-height: 4rem;
    display: inline-block;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.gray_dark};
    cursor: pointer;

    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: ${(props) => props.theme.colors.gray_dark};
        text-decoration: underline;
    }

    @media (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        line-height: 1.9rem;
    }
`;
