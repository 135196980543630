import {Dispatch} from "redux";

import {IServices} from "../../services/IServices";
import {matchRoutesEngine} from "./match_routes_engine";

export interface IRouteFetch<TStore> {
    path: string | string[];
    exact?: boolean;
    fetch?: IFetchAction<TStore> | IFetchActionPure<TStore>;
    routes?: IRouteFetch<TStore>[];
}

export type IFetchAction<TStore> = (
    services: Partial<IServices>,
    route: IRouteState<any>,
    prevRoute: IPrevRouteState | null,
    prevActionResult: any
) => (dispatch: Dispatch, getState: () => TStore) => Promise<any>;

export type IFetchActionPure<TStore> = (
    route: IRouteState<any>,
    prevRoute: IPrevRouteState | null,
    prevActionResult: any
) => (dispatch: Dispatch, getState: () => TStore) => Promise<any>;

export interface IGivenRouteState extends IPrevRouteState {}

// location state is undefined until it is passed via history `pushState` or `replace`
export interface IRouteState<T, LocationState = any> extends IGivenRouteState {
    params: T;
    state?: LocationState;
}

export interface IPrevRouteState {
    pathname: string;
    query: any;
    hash: string;
    url: string;
}

export const createAppPathDataFetcher =
    <TStore>(fetchRoutes: IRouteFetch<TStore>[]) =>
    (services: Partial<IServices>, current: IGivenRouteState, prev: IPrevRouteState | null) =>
    async (dispatch: Dispatch): Promise<any> => {
        const matches = matchRoutesEngine<IFetchAction<any>>(fetchRoutes, current.pathname);
        const promises: Promise<any>[] = [];

        matches.forEach((match) => {
            const route: IRouteState<any> = {...current, params: match.params};
            promises.push(dispatch(match.fetch(services, route, prev, true))); // passing true allows matched promise chain to be executed
        });

        // dispatch all matched action sets asynchronously
        await Promise.all(promises);
    };
