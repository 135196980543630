import type {LatLngLiteral} from "leaflet";

const MIN_POLAND_LAT = 49.0;
const MAX_POLAND_LAT = 55.0;

const MIN_POLAND_LNG = 14.0;
const MAX_POLAND_LNG = 24.2;

export const convertToLatLngLiteralOfPoland = (values: number[]): LatLngLiteral => {
    if (values?.length !== 2) {
        throw new Error("Incorrect coordinates");
    }

    const coords =
        values[0] > MIN_POLAND_LAT
            ? ({
                  lat: values[0],
                  lng: values[1]
              } as LatLngLiteral)
            : ({
                  lat: values[1],
                  lng: values[0]
              } as LatLngLiteral);

    if (coords.lat < MIN_POLAND_LAT || coords.lat > MAX_POLAND_LAT) {
        throw new Error(`Incorrect latitude: ${coords.lat}`);
    }

    if (coords.lng < MIN_POLAND_LNG || coords.lng > MAX_POLAND_LNG) {
        throw new Error(`Incorrect longitude: ${coords.lng}`);
    }

    return coords;
};
