import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    size?: string;
}

export const FlagUaIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className}>
            <path d="M.4,2.2v2.61H9.6V2.04c-.75,.22-1.25,.52-2.64,.52-2.18,0-2.18-.74-4.35-.74-1.1,0-1.65,.19-2.2,.38Z" fill="#005bbb" />

            <path d="M.4,4.81v3c.55-.19,1.1-.38,2.2-.38,2.18,0,2.18,.74,4.35,.74,1.39,0,1.89-.3,2.64-.52v-2.84H.4Z" fill="#ffd500" />
        </SvgIcon>
    );
};
