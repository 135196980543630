import {css, Theme} from "@emotion/react";
import {Button} from "@web2/button";
import {FetchStateType} from "@web2/react_utils";

import {IApplicationFetchOffer} from "../../../application/actions/application_fetch_offer";
import {errorStyle, unknownError} from "../../../application/components/ApplicationModalAgencyOffers";
import {applicationModalOfferInfoText, offerInfoLocation, OfferPictureHolder} from "../../../application/components/ApplicationModalOfferInfo";
import {getOfferTitle} from "../../../offer/utils/utils";
import {mb_sm, mt_0} from "../../../styles/helpers";
import {styledButtonCTAModalPrivateOffers} from "../ApplicationModalPrivateOffers";
import {Header} from "./Header";

interface IProps {
    offer: IApplicationFetchOffer;
    fetchState: FetchStateType;
    onClick: () => void;
}

export const GetInfo = (props: IProps) => {
    const {onClick, offer, fetchState} = props;

    // Renders
    const offerPicture = offer?.pictures?.[0]?.o_img_500;
    const title = (offer && getOfferTitle(offer)) || offer?.name;
    const location = offer?.property.location?.short_name;

    const renderOfferInfo = () => (
        <div css={privateOffersModalInfoHolder}>
            <OfferPictureHolder picture={offerPicture} />
            <div css={applicationModalOfferInfoText}>
                <p css={[mt_0, mb_sm]}>{title}</p>
                <p css={offerInfoLocation}>{location}</p>
            </div>
        </div>
    );

    const renderError = () => <div css={errorStyle}>{unknownError}</div>;

    return (
        <>
            {fetchState == FetchStateType.Success ? renderOfferInfo() : fetchState == FetchStateType.Error ? renderError() : null}
            <div css={contentWrapper}>
                <Header text="Kontakt do właściciela oferty prywatnej jest dostępny za opłatą." />
                <p css={contentParagraph}>
                    Uzyskaj jednorazowy lub miesięczny dostęp do ofert prywatnych.{" "}
                    <strong>Kupując nieruchomość bezpośrednio od jej właściciela nie płacisz prowizji pośrednikowi</strong>, która wynosi od 3 do 5% wartości
                    transakcji.
                </p>
                <Button css={styledButtonCTAModalPrivateOffers} onClick={onClick}>
                    Wykup dostęp
                </Button>
            </div>
        </>
    );
};

const contentWrapper = css`
    display: flex;
    flex-direction: column;
`;

const contentParagraph = css`
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 3.5rem 0;
    line-height: 2.25rem;
`;

const privateOffersModalInfoHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    margin-bottom: 3.6rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: column;
        width: 33.5rem;
        margin-right: 2.5rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
`;
