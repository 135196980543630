import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const ExtraSpaceIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "9.44mm"} height={props.height || "9.71mm"} viewBox="0 0 26.77 27.51">
            <path d="M20.06,17v4.11H15.93a.75.75,0,0,0,0,1.5h4.88a.75.75,0,0,0,.75-.75V17a.75.75,0,0,0-1.5,0Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M5.66,16.25a.76.76,0,0,0-.75.75v4.86a.75.75,0,0,0,.75.75h4.88a.75.75,0,0,0,0-1.5H6.41V17A.75.75,0,0,0,5.66,16.25Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M4.91,6.77v4.86a.75.75,0,0,0,1.5,0V7.52h4.13a.75.75,0,0,0,0-1.5H5.66A.75.75,0,0,0,4.91,6.77Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M26.77,24.24a.61.61,0,0,0,0-.13.8.8,0,0,0,0-.15h0a.71.71,0,0,0-.15-.22,0,0,0,0,0,0,0h0L24,21.19A.75.75,0,0,0,23,22.25l1.24,1.24H4V3.35L5.28,4.59a.71.71,0,0,0,.53.22.75.75,0,0,0,.52-1.28L3.81,1h0l0,0,0,0L3.57.85A.75.75,0,0,0,3.28.79h0A.6.6,0,0,0,3,.85H3l-.06,0A.71.71,0,0,0,2.75,1L.22,3.53a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0L2.53,3.35V24.24a.74.74,0,0,0,.75.75H24.2L23,26.23a.75.75,0,0,0,0,1.06.73.73,0,0,0,.53.22.74.74,0,0,0,.53-.22l2.53-2.52h0a0,0,0,0,0,0,0,1.07,1.07,0,0,0,.15-.22h0a.8.8,0,0,0,0-.15.7.7,0,0,0,0-.14Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M20.21,12a6,6,0,1,0-6-6A6,6,0,0,0,20.21,12Zm-3-6.5h2.5V3a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V5.5h2.5a.5.5,0,0,1,0,1h-2.5V9a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V6.5h-2.5a.5.5,0,0,1,0-1Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
