import {numberWithDelimiter} from "@web2/string_utils";

import {sizeFormat} from "../../../../../app/utils/number";
import {IGenericListingParagraphWithStats} from "../../interfaces/interfaces_generic_listings";

export const StatisticsCheapestPartOne = (props: IGenericListingParagraphWithStats) => {
    const {statistics, locationDeclination} = props;

    return statistics.statisticsLocation ? (
        <p>
            Średnia cena mieszkania do wynajęcia{locationDeclination ? ` ${locationDeclination}` : null} to{" "}
            {numberWithDelimiter(sizeFormat(statistics.statisticsLocation.apartment.rent.avg_price))} zł.
        </p>
    ) : null;
};
