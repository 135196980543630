import * as React from "react";

export const BigFamilyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                d="M18.53,7.78a2.65,2.65,0,1,0-2.64-2.64A2.65,2.65,0,0,0,18.53,7.78Zm0-4.55a1.91,1.91,0,1,1-1.91,1.91A1.92,1.92,0,0,1,18.53,3.23Z"
                fill="#37474f"
            />
            <path
                d="M22.89,17.23v-5a.33.33,0,0,0-.13-.29.38.38,0,0,0-.31-.08.38.38,0,0,0-.38.37v5a.23.23,0,0,1-.06.16.24.24,0,0,1-.16.07H21V11a.36.36,0,0,0-.36-.36.37.37,0,0,0-.37.36V26.51a.23.23,0,0,1-.24.23h-.85a.24.24,0,0,1-.24-.23V20.69a.37.37,0,1,0-.73,0v5.82a.23.23,0,0,1-.06.16.28.28,0,0,1-.17.07h-.84a.25.25,0,0,1-.25-.23V11a.36.36,0,0,0-.35-.36.36.36,0,0,0-.36.36v6.47h-.89a.25.25,0,0,1-.25-.23V11a2.2,2.2,0,0,1,2.19-2.18h2.74A2.18,2.18,0,0,1,22.09,11a.38.38,0,0,0,.37.31.38.38,0,0,0,.37-.31,2.91,2.91,0,0,0-2.91-2.91H17.16A2.92,2.92,0,0,0,14.25,11v6.24a1,1,0,0,0,1,.95h.89v8.33a1,1,0,0,0,1,1H18a1,1,0,0,0,.59-.21,1.12,1.12,0,0,0,.61.21h.85a.94.94,0,0,0,.68-.29.92.92,0,0,0,.29-.68V18.18h.89A1,1,0,0,0,22.89,17.23Z"
                fill="#37474f"
            />
            <path d="M3.8,10A2.64,2.64,0,1,0,1.16,7.4,2.64,2.64,0,0,0,3.8,10Zm0-4.55A1.91,1.91,0,1,1,1.89,7.37,1.91,1.91,0,0,1,3.8,5.46Z" fill="#37474f" />
            <path
                d="M5,10.43H2.6A2.62,2.62,0,0,0,0,13v5.46a.89.89,0,0,0,.9.89L.65,21.63a.3.3,0,0,0,.09.28A.36.36,0,0,0,1,22h.62v4.55a.94.94,0,0,0,.26.64.94.94,0,0,0,.64.26h.74a1,1,0,0,0,.53-.18,1,1,0,0,0,.53.18h.74a.9.9,0,0,0,.9-.9v-2a.37.37,0,0,0-.73,0v2a.17.17,0,0,1,0,.12.16.16,0,0,1-.12,0H4.36a.19.19,0,0,1-.12,0,.16.16,0,0,1,0-.12V22.67a.37.37,0,0,0-.37-.36.36.36,0,0,0-.36.36v3.91a.17.17,0,0,1-.05.12.17.17,0,0,1-.12,0H2.53a.16.16,0,0,1-.12,0,.17.17,0,0,1-.05-.12V22H5.24v1.35a.37.37,0,1,0,.73,0V22h.62a.34.34,0,0,0,.27-.12.42.42,0,0,0,.1-.28L6.7,19.32a.89.89,0,0,0,.89-.89V13A2.61,2.61,0,0,0,5,10.43Zm1.88,8v.07a.18.18,0,0,1-.17.17H6.62L6,12.6a.37.37,0,0,0-.41-.33.35.35,0,0,0-.24.14.32.32,0,0,0-.07.27l.95,8.73H1.42l1-8.73a.36.36,0,0,0-.08-.27.35.35,0,0,0-.24-.14.37.37,0,0,0-.41.33L1,18.67H.89a.18.18,0,0,1-.17-.17V13A1.89,1.89,0,0,1,2.6,11.09H5A1.89,1.89,0,0,1,6.88,13Z"
                fill="#37474f"
            />
            <path
                d="M11.46,19.53a1.61,1.61,0,1,0-.5-3.1,1.63,1.63,0,0,0-1,1.5A1.61,1.61,0,0,0,11.46,19.53Zm-.63-2.16a.84.84,0,0,1,.63-.28v0a.88.88,0,0,1,.86.87.87.87,0,1,1-1.49-.56Z"
                fill="#37474f"
            />
            <path
                d="M14.23,18.33a1,1,0,0,0-.68.32l-1.36,1.44H10.76L9.4,18.65a1,1,0,0,0-.69-.28A1,1,0,0,0,8,20l1.51,1.69v4.85a1,1,0,0,0,1,.95h1.94a.95.95,0,0,0,1-1v-2a.39.39,0,0,0-.1-.34.38.38,0,0,0-.34-.09.37.37,0,0,0-.36.37v2a.24.24,0,0,1-.07.16.23.23,0,0,1-.16.06h-.61v-3a.36.36,0,0,0-.72,0v3h-.6a.22.22,0,0,1-.21-.22V21.53a.39.39,0,0,0-.1-.25L8.5,19.53a.28.28,0,0,1,.41-.38l1.52,1.52a.36.36,0,0,0,.27.12h1.64a.36.36,0,0,0,.27-.12l1.52-1.52a.26.26,0,0,1,.17-.07.29.29,0,0,1,.18.07.26.26,0,0,1,0,.35l-1.72,1.78a.39.39,0,0,0-.1.25v1.76a.37.37,0,0,0,.36.37.39.39,0,0,0,.31-.08.39.39,0,0,0,.13-.29V21.67L15,20a1,1,0,0,0,0-1.38A1,1,0,0,0,14.23,18.33Z"
                fill="#37474f"
            />
            <path
                d="M26.17,19.53a1.6,1.6,0,0,0,1.52-.75,1.63,1.63,0,0,0,0-1.7,1.61,1.61,0,1,0-1.52,2.45Zm0-2.44v0a.91.91,0,0,1,.7.25.89.89,0,0,1,.24.71.87.87,0,0,1-1.73,0A.88.88,0,0,1,26.17,17.09Z"
                fill="#37474f"
            />
            <path
                d="M29.72,18.61a1,1,0,0,0-.78-.28,1,1,0,0,0-.68.32L26.9,20.09H25.49l-1.37-1.44A1,1,0,1,0,22.7,20l1.51,1.68v4.85a1,1,0,0,0,1,1h1.94a1,1,0,0,0,.95-1v-2a.36.36,0,1,0-.72,0v2a.24.24,0,0,1-.23.22H26.5v-3a.38.38,0,0,0-.37-.3.38.38,0,0,0-.37.3v3h-.58a.22.22,0,0,1-.22-.22v-5a.39.39,0,0,0-.1-.25l-1.63-1.75a.24.24,0,0,1,0-.35.25.25,0,0,1,.35,0l1.52,1.52a.4.4,0,0,0,.27.12h1.73a.42.42,0,0,0,.26-.12l1.51-1.52a.27.27,0,0,1,.37,0,.26.26,0,0,1,.07.17.29.29,0,0,1-.07.18l-1.69,1.79a.45.45,0,0,0-.09.26v1.75a.37.37,0,0,0,.37.37.36.36,0,0,0,.36-.37V21.67L29.72,20a1,1,0,0,0,0-1.38Z"
                fill="#37474f"
            />
        </svg>
    );
};
