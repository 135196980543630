import {Dispatch} from "redux";
import {appLink, parseDeveloperSlugToObject} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {suffix} from "../../meta_data/actions/set_client_meta_data";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IDeveloper} from "./fetch_developer_at_route";

export const updateDeveloperDetailMetaData =
    (_1: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IDeveloper) => (dispatch: Dispatch) => {
        const url = `${ghCommonUrl}${appLink.developer.detail.base(parseDeveloperSlugToObject(prevActionResult.slug))}`;
        const description = `Zobacz inwestycje dewelopera ${prevActionResult.name} na gethome.pl. Sprawdź aktualną ofertę i znajdź swoje przyszłe mieszkanie! ✅`;
        const robots = prevActionResult.investment_summary.investment_count > 0 ? "index,follow" : "noindex,follow";
        const title = `${prevActionResult.name}${suffix}`;

        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                hideSuffix: true
            })
        );

        return Promise.resolve(true);
    };
