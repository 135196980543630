import {Dispatch} from "redux";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getSectionFromSlug, getSiteMapSectionData, SiteMapSection} from "../utils/siteMapUtils";
import {ISiteMapSectionData} from "./fetchSiteMapsAtRoute";

export const updateSiteMapMetaData =
    (_1: Partial<IServices>, route: IRouteState<{siteMapSlug: string}>, prevRoute: IPrevRouteState | null, prevActionResult: ISiteMapSectionData[]) =>
    (dispatch: Dispatch) => {
        const isMainSiteMapPage = Object.entries(route.params).length === 0;

        const sectionName = getSectionFromSlug(route.params.siteMapSlug);
        const sectionTitle = isMainSiteMapPage ? "" : getSiteMapSectionData(sectionName as SiteMapSection).title;
        const separator = isMainSiteMapPage ? "" : " - ";
        const metaTitle = `Mapa strony Gethome${separator}${sectionTitle}`;
        const desc = isMainSiteMapPage ? "najważniejszych podstronach serwisu" : sectionTitle;
        const metaDesc = `Mapa strony Gethome - odkrywaj i nawiguj po ${desc}`;

        const title = metaTitle;
        const description = metaDesc;
        const robots = "index, follow";
        dispatch(updateMetaData(title, description, route, {robots, hideSuffix: true}));

        return Promise.resolve(true);
    };
