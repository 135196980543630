import * as React from "react";
import {useEffect} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";
import {numberWithDelimiter, pluralize} from "@web2/string_utils";

import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {DealTypeSlug, OfferType} from "../../offer/utils/constants_offer";
import {gtmPromoClick, gtmPromoView, PromoTypes} from "../../tracking/google_tag_manager/ecommerce_events/gtm_promo";
import {sectionWrapper} from "./atoms";

import housesRentImg from "../../styles/assets/images/homepage/domy-do-wynajecia.jpg";
import housesImg from "../../styles/assets/images/homepage/domy-na-sprzedaz.jpg";
import houseIcon from "../../styles/assets/images/homepage/house.svg";
import houseRevertedIcon from "../../styles/assets/images/homepage/house_reverted.svg";
import apartmentsRentImg from "../../styles/assets/images/homepage/mieszkania-do-wynajecia.jpg";
import apartmentsImg from "../../styles/assets/images/homepage/mieszkania-na-sprzedaz.jpg";
import propertyIcon from "../../styles/assets/images/homepage/property.svg";

export const OfferTypeSection = () => {
    const links = [
        {
            bgImg: apartmentsImg,
            href: getOfferListUrl({offerType: OfferType.apartment, type: DealTypeSlug.SELL}),
            icon: houseRevertedIcon,
            offersCount: 79151,
            title: "Mieszkania na sprzedaż"
        },
        {
            bgImg: housesImg,
            href: getOfferListUrl({offerType: OfferType.house, type: DealTypeSlug.SELL}),
            icon: houseIcon,
            offersCount: 18172,
            title: "Domy na sprzedaż"
        },
        {
            bgImg: apartmentsRentImg,
            href: getOfferListUrl({offerType: OfferType.apartment, type: DealTypeSlug.RENT}),
            icon: propertyIcon,
            offersCount: 3784,
            title: "Mieszkania do wynajęcia"
        },
        {
            bgImg: housesRentImg,
            href: getOfferListUrl({offerType: OfferType.house, type: DealTypeSlug.RENT}),
            icon: propertyIcon,
            offersCount: 1268,
            title: "Domy do wynajęcia"
        }
    ];

    const offerPlural = pluralize(["oferta", "oferty", "ofert"]);

    useEffect(() => {
        const promos = links.map((linkType, idx) => ({
            type: PromoTypes.BIG,
            title: linkType.title,
            bannerImageUrl: linkType.bgImg,
            position: idx + 1
        }));
        gtmPromoView(promos);
    }, []);

    return (
        <section css={sectionWrapper}>
            <ul css={offerTypesHolder}>
                {map(links, (elem, i) => {
                    return (
                        <li key={i}>
                            <BigBox
                                href={elem.href}
                                onClick={() => gtmPromoClick({type: PromoTypes.BIG, title: elem.title, bannerImageUrl: elem.href, position: i + 1})}
                            >
                                <div css={bigBoxInfo}>
                                    <Icon icon={elem.icon} />

                                    <h3 css={bogBoxHeading}>{elem.title}</h3>

                                    <p css={bogBoxOffersCount}>
                                        {numberWithDelimiter(elem.offersCount)} {offerPlural(elem.offersCount)}
                                    </p>
                                </div>

                                <img css={bogBoxImage} src={elem.bgImg} alt={elem.title} width={560} height={500} loading="lazy" />
                            </BigBox>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

const offerTypesHolder = (theme: Theme) => css`
    display: grid;
    grid-template-rows: repeat(4, 280px);
    grid-gap: 4rem;
    max-width: 560px;
    margin: 3rem auto;
    padding: 0;
    list-style: none;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 280px);
        max-width: 1800px;
        padding: 0 1.5rem;
    }

    @media (min-width: ${theme.breakpoints_correct.screen_lg}) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }
`;

const BigBox = styled("a")`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 200ms ease-out;
    color: white;
    text-align: center;
    text-decoration: none;
    position: relative;
    border-radius: ${(props) => props.theme.other.border_radius};
    overflow: hidden;

    &:hover,
    &:focus {
        color: white;
        box-shadow: 0 0.2rem 2.1rem 0.2rem rgba(0, 0, 0, 0.2);
    }
`;

const commonTextStyles = css`
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
`;

const bogBoxHeading = (theme: Theme) => css`
    ${commonTextStyles};
    font-size: 1.8rem;
    line-height: 1.45;

    @media (min-width: ${theme.breakpoints_correct.screen_lg}) {
        font-size: 2.4rem;
    }
`;

const bogBoxOffersCount = (theme: Theme) => css`
    ${commonTextStyles};
    font-size: 1.4rem;
    line-height: 1.5;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        font-size: 2rem;
    }
`;

interface IIconProps {
    icon: string;
}

const Icon = styled.div<IIconProps>`
    width: 40px;
    height: 45px;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    background-image: url("${(props) => props.icon}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const bigBoxInfo = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 2rem;
`;

const bogBoxImage = css`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;
