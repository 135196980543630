import * as React from "react";

export const MapIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 20 20">
            <path
                d="M16.76,16.09a.74.74,0,0,1-.55.72l-6,1.64a.75.75,0,0,1-.95-.73V6.38l6.56-1.79a.78.78,0,0,1,.66.13.73.73,0,0,1,.29.6ZM4.23,15.76a.76.76,0,0,1-.67-.09.77.77,0,0,1-.32-.62V4.94a1.5,1.5,0,0,1,1-1.41l4.9-1.74L7.8,5.13a.73.73,0,0,0,0,.46.76.76,0,0,0,0,.21V14Zm5.35-11L10.68,2a.67.67,0,0,1,.07.31V4.41Zm7.8-1.2a2.26,2.26,0,0,0-2-.39L12.25,4V2.28a2.26,2.26,0,0,0-3-2.13l-5.52,2a3,3,0,0,0-2,2.83V15.05a2.26,2.26,0,0,0,3.07,2.1l.07,0,2.88-1.47v2.07A2.26,2.26,0,0,0,10,20a2.47,2.47,0,0,0,.6-.08l6-1.64a2.26,2.26,0,0,0,1.67-2.17V5.32A2.24,2.24,0,0,0,17.38,3.53Z"
                fill="#37474f"
                fillRule="evenodd"
            />
        </svg>
    );
};
