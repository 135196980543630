import * as React from "react";
import {css} from "@linaria/core";
import {areaFormat, numberFormat} from "@web2/string_utils";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {IBoundStats} from "../../actions/fetch_filter_stats";
import {FilterFooter} from "./SearchFiltersFooter";

export enum FilterStatsBoundsType {
    SIZE,
    PRICE,
    PRICE_PER_SQM
}

interface IProps {
    onCancel: () => void;
    onSave: () => void;
    type: FilterStatsBoundsType;
    average: {
        one_room: number;
        two_rooms: number;
        three_rooms: number;
        four_rooms: number;
    };
    filterBounds: IBoundStats;
}

export const HistogramFilterFooter = (props: IProps) => {
    const renderAverageSummary = () => {
        switch (props.type) {
            case FilterStatsBoundsType.SIZE:
                return (
                    <>
                        <hr className={divider} />

                        <p className={histogramTitle}>Średnie powierzchnie w tej okolicy</p>

                        <div className={histogramHolder}>
                            <div>
                                <p>
                                    <span>1 pokojowe </span>
                                    {areaFormat(props.average.one_room)}
                                </p>
                                <p>
                                    <span>2 pokojowe </span>
                                    {areaFormat(props.average.two_rooms)}
                                </p>
                            </div>

                            <div>
                                <p>
                                    <span>3 pokojowe </span>
                                    {areaFormat(props.average.three_rooms)}
                                </p>
                                <p>
                                    <span>4 pokojowe </span>
                                    {areaFormat(props.average.four_rooms)}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case FilterStatsBoundsType.PRICE:
                return (
                    <>
                        <hr className={divider} />

                        <p className={histogramTitle}>Średnie ceny w tej okolicy</p>

                        <div className={histogramHolder}>
                            <div>
                                <p>
                                    <span>1 pokojowe </span>
                                    {numberFormat(props.average.one_room / 1000, {unit: " tys. zł"})}
                                </p>
                                <p>
                                    <span>2 pokojowe </span>
                                    {numberFormat(props.average.two_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                            </div>

                            <div>
                                <p>
                                    <span>3 pokojowe </span>
                                    {numberFormat(props.average.three_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                                <p>
                                    <span>4 pokojowe </span>
                                    {numberFormat(props.average.four_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case FilterStatsBoundsType.PRICE_PER_SQM:
                return (
                    <>
                        <hr className={divider} />

                        <p className={histogramTitle}>Średnie ceny za m2 w tej okolicy</p>

                        <div className={histogramHolder}>
                            <div>
                                <p>
                                    <span>1 pokojowe </span>
                                    {numberFormat(props.average.one_room / 1000, {unit: " tys. zł"})}
                                </p>
                                <p>
                                    <span>2 pokojowe </span>
                                    {numberFormat(props.average.two_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                            </div>

                            <div>
                                <p>
                                    <span>3 pokojowe </span>
                                    {numberFormat(props.average.three_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                                <p>
                                    <span>4 pokojowe </span>
                                    {numberFormat(props.average.four_rooms / 1000, {unit: " tys. zł"})}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    return (
        <>
            <div className={filterFooterHolder}>
                <FilterFooter onCancel={props.onCancel} onSave={props.onSave} />
            </div>

            {renderAverageSummary()}
        </>
    );
};

const divider = css`
    width: 100%;
    height: 1px;
    background-color: ${getThemeVariable("colors-gray_very_dark")};
    border: none;
`;

const filterFooterHolder = css`
    margin-bottom: 2rem;
`;

const histogramTitle = css`
    font-size: 1.6rem;
    color: ${getThemeVariable("colors-gray_lighter")};
    margin: 1.6rem 0;
`;

const histogramHolder = css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 1.4rem;
        color: ${getThemeVariable("colors-gray_lighter")};
        display: flex;
        align-items: center;
        margin: 0 0 1rem;

        span {
            margin-right: 1.5rem;
        }
    }
`;
