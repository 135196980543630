import * as React from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {includes} from "lodash";
import {Button} from "@web2/button";
import {numberWithDelimiter, pluralize} from "@web2/string_utils";

import {
    ILocationStatistics,
    ILocationStatisticsApartmentAll,
    ILocationStatisticsApartmentRent,
    ILocationStatisticsApartmentSell,
    ILocationStatisticsHouseAll,
    ILocationStatisticsHouseRent,
    ILocationStatisticsHouseSell,
    ILocationStatisticsPropertyAll,
    ILocationStatisticsPropertyRent,
    ILocationStatisticsPropertySell
} from "../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../app/utils/number";
import {getThemeBreakpoint, getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {OfferDealType} from "../../utils/constants_offer";
import {parseDealType, parseRoom, parseRooms} from "../../utils/utils";
import {BigFamilyIcon} from "./icons/BigFamilyIcon";
import {FamilyIcon} from "./icons/FamilyIcon";
import {PairIcon} from "./icons/PairIcon";
import {SingleIcon} from "./icons/SingleIcon";
import {StatsIcon} from "./icons/StatsIcon";

interface IProps {
    hiderClass: string;
    setBodyClose: () => void;
    params: {
        deal_type?: OfferDealType;
        offer_type: string;
    };
    locationStatistics: ILocationStatistics;
    isMapBig: boolean;
}

export const OfferListStatsBody = (props: IProps) => {
    const isPrimary = includes(props.params.offer_type, "primary_market");
    const isPrimaryHouses = includes(props.params.offer_type, "primary_market__house");
    const isPrimaryApartment = includes(props.params.offer_type, "primary_market__apartment");

    const isAftermarket = includes(props.params.offer_type, "aftermarket");
    const isAftermarketHouses = includes(props.params.offer_type, "aftermarket__house");
    const isAftermarketApartments = includes(props.params.offer_type, "aftermarket__apartment");

    const isHouses = (isPrimaryHouses || isAftermarketHouses) && (!isPrimaryApartment || !isAftermarketApartments);

    const offersPlural = pluralize(["oferta", "oferty", "ofert"]);
    const locationDeclensionWhere = props.locationStatistics.location.name_declension_where;

    const houseOrApartment = (declensionWhat?: boolean) => {
        if ((isPrimaryHouses || isAftermarketHouses) && !isPrimaryApartment && !isAftermarketApartments) {
            return declensionWhat ? "Domy" : "domów";
        }

        if ((isPrimaryApartment || isAftermarketApartments) && !isPrimaryHouses && !isAftermarketHouses) {
            return declensionWhat ? "Mieszkania" : "mieszkań";
        }

        return declensionWhat ? "Mieszkania i Domy" : "mieszkań i domów";
    };

    const generateHeading = () => {
        return (
            <HeadingHolder>
                <StatsIconHolder>
                    <StatsIcon />
                </StatsIconHolder>

                <h3>
                    <span>
                        {houseOrApartment(true)} {locationDeclensionWhere}
                    </span>
                    {` - statystyki ${houseOrApartment()} ${parseDealType(props.params.deal_type)} portalu Gethome`}
                </h3>
            </HeadingHolder>
        );
    };

    const renderSingleApartmentsElement = (rooms: number, icon: JSX.Element, className?: string) => {
        const aptStats = (() => {
            if ((isPrimaryHouses || isAftermarketHouses) && (!isPrimaryApartment || !isAftermarketApartments)) {
                if (props.params.deal_type === OfferDealType.SELL) {
                    const sellItem = props.locationStatistics.house.sell[
                        parseRoom(rooms) as keyof ILocationStatisticsHouseSell
                    ] as ILocationStatisticsHouseSell;
                    return {
                        apartmentsCount: sellItem.count,
                        apartmentsAvgPrice: sellItem.avg_price,
                        apartmentAvgSize: sellItem.avg_size
                    };
                }
                if (props.params.deal_type === "rent") {
                    const rentItem = props.locationStatistics.house.rent[
                        parseRoom(rooms) as keyof ILocationStatisticsHouseRent
                    ] as ILocationStatisticsHouseRent;
                    return {
                        apartmentsCount: rentItem.count,
                        apartmentsAvgPrice: rentItem.avg_price,
                        apartmentAvgSize: rentItem.avg_size
                    };
                }

                const allItem = props.locationStatistics.house.all[parseRoom(rooms) as keyof ILocationStatisticsHouseAll] as ILocationStatisticsHouseAll;
                return {
                    apartmentsCount: allItem.count,
                    apartmentsAvgPrice: allItem.avg_price,
                    apartmentAvgSize: allItem.avg_size
                };
            }

            if ((isPrimaryApartment || isAftermarketApartments) && (!isPrimaryHouses || !isAftermarketHouses)) {
                if (props.params.deal_type === OfferDealType.SELL) {
                    const sellItem = props.locationStatistics.apartment.sell[
                        parseRoom(rooms) as keyof ILocationStatisticsApartmentSell
                    ] as ILocationStatisticsApartmentSell;
                    return {
                        apartmentsCount: sellItem.count,
                        apartmentsAvgPrice: sellItem.avg_price,
                        apartmentAvgSize: sellItem.avg_size
                    };
                }

                if (props.params.deal_type === "rent") {
                    const rentItem = props.locationStatistics.apartment.rent[
                        parseRoom(rooms) as keyof ILocationStatisticsApartmentRent
                    ] as ILocationStatisticsApartmentRent;
                    return {
                        apartmentsCount: rentItem.count,
                        apartmentsAvgPrice: rentItem.avg_price,
                        apartmentAvgSize: rentItem.avg_size
                    };
                }

                const allItem = props.locationStatistics.apartment.all[
                    parseRoom(rooms) as keyof ILocationStatisticsApartmentAll
                ] as ILocationStatisticsApartmentAll;
                return {
                    apartmentsCount: allItem.count,
                    apartmentsAvgPrice: allItem.avg_price,
                    apartmentAvgSize: allItem.avg_size
                };
            }

            if (props.params.deal_type === OfferDealType.SELL) {
                const sellItem = props.locationStatistics.property.sell[
                    parseRoom(rooms) as keyof ILocationStatisticsPropertySell
                ] as ILocationStatisticsPropertySell;
                return {
                    apartmentsCount: sellItem.count,
                    apartmentsAvgPrice: sellItem.avg_price,
                    apartmentAvgSize: sellItem.avg_size
                };
            }

            if (props.params.deal_type === "rent") {
                const rentItem = props.locationStatistics.property.rent[
                    parseRoom(rooms) as keyof ILocationStatisticsPropertyRent
                ] as ILocationStatisticsPropertyRent;
                return {
                    apartmentsCount: rentItem.count,
                    apartmentsAvgPrice: rentItem.avg_price,
                    apartmentAvgSize: rentItem.avg_size
                };
            }

            const allItem = props.locationStatistics.property.all[parseRoom(rooms) as keyof ILocationStatisticsPropertyAll] as ILocationStatisticsPropertyAll;
            return {
                apartmentsCount: allItem.count,
                apartmentsAvgPrice: allItem.avg_price,
                apartmentAvgSize: allItem.avg_size
            };
        })();

        const generateFirstText = () => {
            return `${locationDeclensionWhere.charAt(0).toUpperCase() + locationDeclensionWhere.slice(1)} ${pluralize(["jest", "są", "jest"])(
                aptStats.apartmentsCount
            )} ${numberWithDelimiter(aptStats.apartmentsCount)} ${offersPlural(aptStats.apartmentsCount)} ${
                rooms !== 1 ? `${houseOrApartment()} ` : ""
            }${parseRooms(rooms, true)}`;
        };

        const generateSecondText = () => {
            return `Średnia cena ${rooms !== 1 ? `${houseOrApartment()} ` : ""} ${parseRooms(rooms, true)} to ${numberWithDelimiter(
                aptStats.apartmentsAvgPrice
            )} zł`;
        };

        const generateThirdText = () => {
            return (
                <>
                    {`Średni metraż ${rooms !== 1 ? `${houseOrApartment()} ` : ""} ${parseRooms(rooms, true)} to ${sizeFormat(aptStats.apartmentAvgSize)}`} m
                    <sup>2</sup>
                </>
            );
        };

        return (
            <ApartmentsByRoomsSingle isMapBig={props.isMapBig} className={className}>
                <div className="svg-holder">{icon}</div>

                <div>
                    <h4>
                        {houseOrApartment(true)} {locationDeclensionWhere} {parseRooms(rooms)}:
                    </h4>

                    <p>{generateFirstText()}</p>

                    <p>{generateSecondText()}</p>

                    <p>{generateThirdText()}</p>
                </div>
            </ApartmentsByRoomsSingle>
        );
    };

    const generateFooter = () => {
        const primaryMarketCount = (() => {
            if (isPrimary) {
                if (isPrimaryHouses && !isPrimaryApartment) {
                    return numberWithDelimiter(props.locationStatistics.house.sell.primary_market.count);
                }

                if (isPrimaryApartment && !isPrimaryHouses) {
                    return numberWithDelimiter(props.locationStatistics.apartment.sell.primary_market.count);
                }

                return numberWithDelimiter(props.locationStatistics.property.sell.primary_market.count);
            }
        })();

        const aftermarketCount = (() => {
            if (isAftermarket) {
                if (isAftermarketHouses && !isAftermarketApartments) {
                    const housesCount = (dealType: string | undefined) => {
                        switch (dealType) {
                            case OfferDealType.SELL:
                                return props.locationStatistics.house.sell.aftermarket.count;
                            case OfferDealType.RENT:
                                return props.locationStatistics.house.rent.count;
                            default:
                                return props.locationStatistics.house.all.aftermarket.count;
                        }
                    };

                    return numberWithDelimiter(housesCount(props.params.deal_type));
                }

                if (isAftermarketApartments && !isAftermarketHouses) {
                    const apartmentsCount = (dealType: string | undefined) => {
                        switch (dealType) {
                            case OfferDealType.SELL:
                                return props.locationStatistics.apartment.sell.aftermarket.count;
                            case OfferDealType.RENT:
                                return props.locationStatistics.apartment.rent.count;
                            default:
                                return props.locationStatistics.apartment.all.aftermarket.count;
                        }
                    };

                    return numberWithDelimiter(apartmentsCount(props.params.deal_type));
                }

                const allCount = (dealType: string | undefined) => {
                    switch (dealType) {
                        case OfferDealType.SELL:
                            return props.locationStatistics.property.sell.aftermarket.count;
                        case OfferDealType.RENT:
                            return props.locationStatistics.property.rent.count;
                        default:
                            return props.locationStatistics.property.all.aftermarket.count;
                    }
                };

                return numberWithDelimiter(allCount(props.params.deal_type));
            }
        })();

        const primaryMarketCountInteger = primaryMarketCount ? parseInt(primaryMarketCount.replace(/\s/g, ""), 10) : 0;
        const afterMarketCountInteger = aftermarketCount ? parseInt(aftermarketCount.replace(/\s/g, ""), 10) : 0;
        const postingPlural = pluralize(["ogłoszenie", "ogłoszenia", "ogłoszeń"]);

        return (
            <FooterHolder>
                <div>
                    {props.params.deal_type !== "rent" && (
                        <StatsFooterText>
                            <h3>
                                {houseOrApartment(true)} bezpośrednio {locationDeclensionWhere} - oferty deweloperów
                            </h3>
                            <span>
                                {"\u00A0"}- {primaryMarketCount} {postingPlural(primaryMarketCountInteger)}
                            </span>
                        </StatsFooterText>
                    )}

                    <StatsFooterText>
                        <h3>
                            {houseOrApartment(true)} używane {locationDeclensionWhere} - oferty agencji nieruchomości
                        </h3>
                        <span>
                            {"\u00A0"}- {aftermarketCount} {postingPlural(afterMarketCountInteger)}
                        </span>
                    </StatsFooterText>
                </div>

                <Button variant="primary" size="sm" inverted>
                    <div onClick={props.setBodyClose}>Zamknij</div>
                </Button>
            </FooterHolder>
        );
    };

    return (
        <div className={cx(statsInfoBodyHider, props.hiderClass)}>
            <div className={holder}>
                {generateHeading()}

                <div className={apartmentsByRooms}>
                    <div className={cx(apartmentsByRoomsPair, props.isMapBig ? "big-map horizontal-border" : "horizontal-border")}>
                        {renderSingleApartmentsElement(isHouses ? 2 : 1, <SingleIcon />, "vertical-border")}

                        {renderSingleApartmentsElement(isHouses ? 3 : 2, <PairIcon />)}
                    </div>

                    <div className={cx(apartmentsByRoomsPair, props.isMapBig && "big-map")}>
                        {renderSingleApartmentsElement(isHouses ? 4 : 3, <FamilyIcon />, "vertical-border")}

                        {renderSingleApartmentsElement(isHouses ? 5 : 4, <BigFamilyIcon />)}
                    </div>
                </div>

                {generateFooter()}
            </div>
        </div>
    );
};

//Styles

const statsInfoBodyHider = css`
    width: 100%;
    height: 0;

    &.isActive {
        animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        @keyframes scale-in-ver-top {
            0% {
                transform: scaleY(0);
                transform-origin: 100% 0;
                opacity: 1;
                height: 0;
            }
            100% {
                transform: scaleY(1);
                transform-origin: 100% 0;
                opacity: 1;
                height: 100%;
            }
        }
    }

    &.inDeactivation {
        animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        @keyframes scale-out-ver-top {
            0% {
                transform: scaleY(1);
                transform-origin: 100% 0;
                opacity: 1;
                height: 100%;
            }
            100% {
                transform: scaleY(0);
                transform-origin: 100% 0;
                opacity: 1;
                height: 0;
            }
        }
    }

    &.isDeactivated {
        display: none;
    }
`;

const holder = css`
    border: 1px solid ${getThemeVariable("colors-gray_very_dark")};
    border-radius: ${getThemeVariable("other-border_radius")};
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

const HeadingHolder = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: ${getThemeVariable("colors-brand_primary")};

    h3 {
        margin: 5px 0;
        font-size: 14px;
    }

    span {
        font-weight: 600;
    }
`;

const StatsIconHolder = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
`;

const apartmentsByRooms = css`
    display: flex;
    flex-direction: column;
    width: 100%;

    border-top: 1px solid ${getThemeVariable("colors-gray_very_dark")};
    border-bottom: 1px solid ${getThemeVariable("colors-gray_very_dark")};
`;

const apartmentsByRoomsPair = css`
    display: flex;
    height: 50%;
    min-height: 140px;
    flex-direction: column;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        flex-direction: row;

        &.vertical-border {
            border-right: 1px solid ${getThemeVariable("colors-gray_very_dark")};
        }

        &.horizontal-border {
            border-bottom: 1px solid ${getThemeVariable("colors-gray_very_dark")};
        }

        &.big-map {
            flex-direction: column;

            &.vertical-border {
                border-right: none;
            }

            &.horizontal-border {
                border-bottom: none;
            }
        }
    }
`;

interface IApartmentsByRoomsSingleProps {
    isMapBig: boolean;
}

const ApartmentsByRoomsSingle = styled.div<IApartmentsByRoomsSingleProps>`
    padding: 15px 0;
    display: flex;
    border-bottom: 1px solid ${getThemeVariable("colors-gray_very_dark")};

    &:last-of-type {
        border-bottom: none;
    }

    svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    p,
    h4 {
        font-size: 12px;
    }

    h4 {
        margin-top: 0;
        font-size: 13px;
        color: ${getThemeVariable("colors-brand_primary")};
        font-weight: 600;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    span {
        font-weight: 600;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        width: ${(props) => (props.isMapBig ? "unset" : "50%")};
        padding: ${(props) => (props.isMapBig ? "15px 0" : "25px 10px 25px 20px")};
        border-bottom: ${(props) => (props.isMapBig ? `1px solid ${getThemeVariable("colors-gray_very_dark")}` : "none")};

        svg {
            margin-right: ${(props) => (props.isMapBig ? "10px" : "15px")};
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg_ipad}) {
        padding: ${(props) => (props.isMapBig ? "15px 0" : "25px 10px 25px 40px")};

        svg {
            margin-right: ${(props) => (props.isMapBig ? "10px" : "20px")};
        }
    }
`;

const FooterHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        padding-top: 1rem;
        justify-content: space-between;
        flex-direction: row;
    }
`;

const StatsFooterText = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h3 {
        font-size: 12px;
        color: ${getThemeVariable("colors-brand_primary")};
        font-weight: 600;

        &::first-letter {
            text-transform: uppercase;
        }
    }
`;
