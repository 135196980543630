import {hitGoogleTagManager} from "./gtm_init";

export const gtmSimilarOfferClick = () => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "usability",
        GAEventAction: "similar_offers",
        GAEventLabel: "click"
    };
    hitGoogleTagManager(gtmData);
};
