import {styled} from "@linaria/react";
import {Checkbox} from "@web2/form";

export const ListElementCheckboxBody = styled(Checkbox)`
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 1rem 1.5rem;
    text-decoration: none;
    color: unset;

    .icon-checkbox {
        margin-right: 1.5rem;
    }
`;
