import {Dispatch} from "redux";

import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateRenewIndividualOfferMetaData = (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => (dispatch: Dispatch) => {
    const title = `Odnowienie ogłoszenia prywatnego`;
    const description = "";
    const robots = "noindex, nofollow";
    const url = route.url;

    dispatch(
        updateMetaData(title, description, route, {
            canonical: url,
            url,
            robots,
            hideSuffix: false
        })
    );

    return Promise.resolve(true);
};
