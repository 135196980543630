export enum FormFieldType {
    // deprecated component-based FormFieldTypes
    Checkbox = "checkbox",
    Input = "input",
    InputRange = "input-range",
    Radio = "radio",
    Select = "select",
    SelectRange = "select-range",
    Textarea = "textarea",
    SelectRangeNumber = "select-range-number",
    PhoneField = "phone-field",
    Rooms = "rooms",
    // special-case-based FormFieldTypes
    SearchOfferType = "search-offer-type",
    FeatureList = "feature-list" // ie `features=[balconies,sea,second_floor]`
}

export type FormFieldClassMap = Record<string, FormFieldType>;
