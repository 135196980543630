import {Dispatch} from "redux";
import {appLink, IAuthorDetailParams} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {IAuthorDetailAuthor} from "../../app/interfaces/response/author_detail";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {enable301ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";

export const fetchAuthorDetailActionTypes = createRequestActionTypes({name: "authorDetailAuthor", type: "GET", view: "AuthorDetail"});

export const fetchAuthorDetailAtRoute = (services: Partial<IServices>, route: IRouteState<IAuthorDetailParams>) => (dispatch: Dispatch) => {
    dispatch({type: fetchAuthorDetailActionTypes.start});
    const url = apiLink.articles.authors.author({})({authorSlug: route.params.authorSlug});
    const redirectUrl = appLink.article.base();

    return getRequest({}, url)
        .then((response: IAuthorDetailAuthor) => {
            if (!response.is_featured) {
                dispatch(enable301ResponseState(redirectUrl));
                return false;
            }
            const result = {
                author: response
            };
            dispatch({type: fetchAuthorDetailActionTypes.success, result: result});
            return result;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        );
};
