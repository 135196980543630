export const googleMapsDefaultConfig = {
    styles: [
        {
            featureType: "administrative.land_parcel" as const,
            elementType: "labels" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi" as const,
            elementType: "labels.text" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.business" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.medical" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.park" as const,
            elementType: "labels.text" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.school" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi.sports_complex" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "transit.station.bus" as const,
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "transit.station.rail" as const,
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "transit.station.rail" as const,
            elementType: "labels.text" as const,
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        }
    ]
};
