import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {IPhoneNumberRevealOffer, PhoneNumberReveal} from "../../app/atoms/PhoneNumberReveal";
import {TermsRodo} from "../../app/components/TermsRodo";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferMarketType} from "../../offer/utils/constants_offer";
import {getOfferTitle} from "../../offer/utils/utils";
import {mb_sm, mt_0} from "../../styles/helpers";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {ApplicationSteps} from "./ApplicationModal";

interface IProps {
    agency?: {
        id: string;
        market_type?: OfferMarketType;
        name: string;
        phone_number?: string;
        status: number;
    };
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
    };
    investment?: {
        developer?: {
            name: string;
            type: VendorType;
        } | null;
        id: string;
        location?: {
            short_name: string;
        };
        name: string;
        main_image: {
            o_img_500: string;
        };
    } | null;
    offer?:
        | (IPhoneNumberRevealOffer & {
              id: string;
              pictures?:
                  | {
                        o_img_500: string;
                    }[]
                  | null;
              property: {
                  location?: {
                      path?: {name: string; slug: string; type: string}[];
                      short_name: string;
                  } | null;
              };
              agency?: {
                  type: VendorType;
              } | null;
          })
        | null;
    className?: string;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    voivodeshipSlug?: string;
    currentPage?: ApplicationSteps;
    hidePhoneNumber: boolean;
}

export const ApplicationModalOfferInfo = (props: IProps) => {
    const {agent, agency, investment, offer, gtmSource, viewType, voivodeshipSlug} = props;

    const offerPicture = investment ? investment.main_image.o_img_500 : offer?.pictures?.[0]?.o_img_500;
    const title = (offer && getOfferTitle(offer)) || (investment && investment.name) || offer?.name;
    const location = offer?.property.location?.short_name || investment?.location?.short_name || offer?.property.location?.short_name;
    const isPrivate = !!offer?.is_private;

    return (
        <div className={props.className}>
            <div css={applicationModalOfferInfoHolder}>
                <OfferPictureHolder picture={offerPicture} />
                <div css={applicationModalOfferInfoText}>
                    <p css={[mt_0, mb_sm]}>{title}</p>

                    <p css={offerInfoLocation}>{location}</p>
                </div>
            </div>
            {props.currentPage !== ApplicationSteps.PHONE_CONTACT && !props.hidePhoneNumber && (
                <div css={applicationModalPhoneNumberRevealHolder}>
                    <PhoneNumberReveal
                        offer={offer}
                        agent={agent}
                        agency={agency}
                        investment={investment}
                        gtmSource={gtmSource}
                        viewType={viewType}
                        voivodeshipSlug={voivodeshipSlug}
                    />
                </div>
            )}
            <div css={termsStyles}>
                <p css={rodoTerms}>
                    <TermsRodo
                        isPrivate={isPrivate}
                        vendorName={offer?.agency?.name || investment?.developer?.name}
                        offerId={props.offer?.id}
                        type={props.offer?.agency?.type}
                    />
                </p>
            </div>
        </div>
    );
};

const applicationModalOfferInfoHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        flex-direction: column;
        position: relative;
        height: 100%;
        margin-bottom: 0;
    }
`;

interface IOfferPictureHolder {
    picture?: string;
}

export const OfferPictureHolder = styled.div<IOfferPictureHolder>`
    width: 10rem;
    height: 10rem;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: ${(props) => props.theme.other.border_radius};
    background: url("${(props) => props.picture}") no-repeat center;
    background-size: cover;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 100%;
        height: 30.5rem;
    }
`;

export const applicationModalOfferInfoText = (theme: Theme) => css`
    margin-left: 1.5rem;
    margin-top: 1rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin-bottom: 0;
        margin-left: 0;
    }
`;

export const offerInfoLocation = (theme: Theme) => css`
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 0;
    color: ${theme.colors.gray_darker};
`;

const applicationModalPhoneNumberRevealHolder = (theme: Theme) => css`
    height: 4.2rem;
    margin-top: 2rem;
    margin-bottom: 3.3rem;

    > div {
        top: 1rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }
`;

const termsStyles = (theme: Theme) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        bottom: -1rem;
    }
`;

const rodoTerms = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: block;
        color: ${theme.colors.gray_darker};
        font-size: 0.8rem;
        margin: 0;
    }
`;
