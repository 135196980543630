import * as React from "react";
import {ReactNode} from "react";
import {useLocation} from "react-router";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {useMounted} from "@web2/react_utils";

import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";
import {FavouritesCountBadge} from "./FavouritesCountBadge";
import {MOBILE_NAV_WIDTH_LEVEL_1, MOBILE_NAV_WIDTH_LEVEL_2, MOBILE_NAV_WIDTH_LEVEL_3, MOBILE_NAV_WIDTH_LEVEL_4, MOBILE_NAV_WIDTH_LEVEL_5} from "./Navigation";

interface IProps {
    title: string | ReactNode;
    url?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    icon?: ReactNode;
    rel?: string;
    favouritesCount?: number;
    className?: string;
    target?: string;
    isActive?: (pathname: string) => boolean;
    disabled?: boolean;
    isButton?: boolean;
}

export const NavigationElement = (props: IProps) => {
    const location = useLocation();
    const isMounted = useMounted();
    const menuElementActive = (props.isActive && props.isActive(location.pathname)) || false;

    return (
        <li className={elementHolder}>
            <MenuElement
                as={props.url ? "a" : "div"}
                href={props.url}
                rel={props.rel}
                onClick={props.onClick}
                target={props.target}
                className={cx(
                    props.disabled && disabledMenuElement,
                    props.isButton && buttonMenuElement,
                    props.isButton && menuElementActive && activeButtonMenuElement,
                    menuElementActive && activeMenuElement,
                    props.className
                )}
            >
                {props.icon && <div className={iconHolder}>{props.icon}</div>}
                <span className={navigationElementText}>{props.title}</span>
                {isMounted && props.favouritesCount !== 0 && props.favouritesCount && <FavouritesCountBadge favouritesCount={props.favouritesCount} />}
            </MenuElement>
        </li>
    );
};

export const MenuElement = styled.a`
    position: relative;
    font-size: ${getThemeVariable("fonts-font_size_small")};
    color: ${getThemeVariable("colors-text_color")};
    padding: 2rem 2.5rem 2rem 4rem;
    height: ${getThemeVariable("main_nav-height")};
    cursor: pointer;
    width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    display: flex;
    align-items: center;
    transition: background-color 300ms ease-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: ${getThemeVariable("colors-text_color")};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        font-size: ${getThemeVariable("fonts-font_size_base")};
        padding: 2rem;
        width: unset;
        white-space: nowrap;
        color: #fff;

        &:hover,
        &:focus {
            background: ${getThemeVariable("colors-brand_primary_light")};
            color: #fff;
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        padding: 2rem 2.6rem;
    }
`; // use styled component to allow usage of 'as' prop

export const disabledMenuElement = css`
    opacity: 0.5;
`;

export const activeMenuElement = css`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        background: ${getThemeVariable("colors-brand_primary_dark")};
    }
`;

const buttonMenuElement = css`
    padding: 2rem 2.5rem 2rem 4rem !important;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        background: #fff;
        max-height: 4rem;
        margin-right: 1.6rem;
        border-radius: 0.4rem;
        padding: 2rem 3rem !important;

        span {
            color: ${getThemeVariable("colors-brand_primary")};
        }

        &:hover,
        &:focus {
            background: ${getThemeVariable("colors-brand_primary_light")};

            span {
                color: #fff;
            }
        }
    }
`;

const activeButtonMenuElement = css`
    background: ${getThemeVariable("colors-brand_primary_dark")};

    span {
        color: #fff;
    }
`;

const elementHolder = css`
    position: relative;
    display: flex;
    width: 100%;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: inline-block;
        width: unset;
    }
`;

export const navigationElementText = css``;

const iconHolder = css`
    margin-right: 15px;
    display: flex;
    align-items: center;
`;
