import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const InstagramIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M4.97356 2.94531C3.73899 2.94531 2.71606 3.9506 2.71606 5.20281C2.71606 6.45501 3.72136 7.4603 4.97356 7.4603C6.22576 7.4603 7.23106 6.43738 7.23106 5.20281C7.23106 3.96824 6.20813 2.94531 4.97356 2.94531ZM4.97356 6.64902C4.17991 6.64902 3.52735 5.99646 3.52735 5.20281C3.52735 4.40916 4.17991 3.7566 4.97356 3.7566C5.76721 3.7566 6.41977 4.40916 6.41977 5.20281C6.41977 5.99646 5.76721 6.64902 4.97356 6.64902Z" />
            <path d="M7.31908 3.40391C7.60156 3.40391 7.83055 3.17492 7.83055 2.89245C7.83055 2.60997 7.60156 2.38098 7.31908 2.38098C7.03661 2.38098 6.80762 2.60997 6.80762 2.89245C6.80762 3.17492 7.03661 3.40391 7.31908 3.40391Z" />
            <path d="M8.64194 1.56966C8.18338 1.09347 7.53083 0.846558 6.79009 0.846558H3.15693C1.62254 0.846558 0.599609 1.86949 0.599609 3.40388V7.0194C0.599609 7.77777 0.846523 8.43033 1.34035 8.90652C1.81654 9.36508 2.45146 9.59435 3.17457 9.59435H6.77245C7.53083 9.59435 8.16575 9.34744 8.6243 8.90652C9.10049 8.44797 9.3474 7.79541 9.3474 7.03703V3.40388C9.3474 2.66314 9.10049 2.02822 8.64194 1.56966ZM8.57139 7.03703C8.57139 7.58377 8.37739 8.02469 8.05993 8.32451C7.74247 8.62434 7.30155 8.78307 6.77245 8.78307H3.17457C2.64546 8.78307 2.20455 8.62434 1.88709 8.32451C1.56963 8.00705 1.4109 7.56613 1.4109 7.0194V3.40388C1.4109 2.87478 1.56963 2.43386 1.88709 2.1164C2.18691 1.81658 2.64546 1.65785 3.17457 1.65785H6.80772C7.33682 1.65785 7.77774 1.81658 8.0952 2.13404C8.39502 2.4515 8.57139 2.89241 8.57139 3.40388V7.03703Z" />
        </SvgIcon>
    );
};
