import {styled} from "@linaria/react";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface ISearchDotProps {
    type: "primary" | "after" | "lot";
}

export const SearchTypeDot = styled.div<ISearchDotProps>`
    display: inline-block;
    margin-right: 0.5rem;
    width: 11px;
    border-radius: 5rem;
    height: 11px;
    vertical-align: middle;
    background-color: ${(props) =>
        props.type === "primary"
            ? getThemeVariable("colors-primary_market")
            : props.type === "after"
              ? getThemeVariable("colors-aftermarket")
              : props.type === "lot"
                ? getThemeVariable("colors-lot")
                : "unset"};
`;
