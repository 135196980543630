import {css} from "@linaria/core";
import {styled} from "@linaria/react";

import {getThemeBreakpoint, getThemeBreakpointCorrect, getThemeVariable} from "../../../../styles/linaria_variable_factory";

export const wrapper = css`
    margin-bottom: 15px;
    padding: 0 10px 0 10px;
    border-top: 1px solid ${getThemeVariable("colors-gray_very_dark")};
`;

export const SearchBlend = styled.div<{isActive: boolean}>`
    top: 148px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: calc(100% - 148px);
    z-index: 1;
    position: fixed;
    right: 0;
    bottom: 0;
    transition: opacity 100ms ease-out;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    pointer-events: ${(props) => (props.isActive ? "all" : "none")};

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        top: 108px;
        height: calc(100% - 108px);
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        top: 158px;
        height: calc(100% - 158px);
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        top: 108px;
        height: calc(100% - 108px);
    }
`;
