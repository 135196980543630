import {IOfferModalGalleryPlanPicture} from "../../app/interfaces/response/offer_detail";

export enum ActiveModalGalleryTab {
    GALLERY = "Galeria",
    VIRTUAL_TOUR = "Wirtualny spacer",
    MAP = "Mapa",
    STREET_VIEW = "Street View",
    EDUCATION_POI = "Edukacja",
    TRANSPORT_POI = "Transport",
    SHOPPING_POI = "Sklepy"
}

export type IGalleryImage = {
    o_img_1500: string;
    o_img_800: string;
    o_img_500: string;
};

export type IGalleryImagesArray = IGalleryImage[];

export interface IGalleryProperty {
    plan_picture?: IOfferModalGalleryPlanPicture | null;
}
