import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {IVendorListResponse} from "../../application/actions/fetch_vendor_list_at_route";
import {fetchLocationBySlugTypes, ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {fetchDevelopersListActionTypes} from "../actions/fetch_developer_list_at_route";
import {fetchDevelopersListSeoDescriptionTypes} from "../actions/fetch_developers_list_seo_description";

export interface IDevelopersListStore {
    developersList: IVendorListResponse;
    seoDescription: string;
    location: ILocation;
}

const developerListReducerObj: Record<keyof IDevelopersListStore, Reducer<any, any>> = {
    developersList: reduceResponse(fetchDevelopersListActionTypes),
    seoDescription: reduceResponse(fetchDevelopersListSeoDescriptionTypes),
    location: reduceResponse(fetchLocationBySlugTypes)
};

export const developerListReducer = combineReducers(developerListReducerObj);
