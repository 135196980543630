import * as React from "react";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router";
import {useTheme} from "@emotion/react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {isEmpty} from "lodash";
import {Breadcrumbs} from "@web2/breadcrumbs";
import {OFFER_LIST_LIST_TESTID} from "@web2/gh_page_object_models";
import {appPath} from "@web2/gh_routes";
import {NotificationBellIcon} from "@web2/icons";
import {numberFormat, pluralize} from "@web2/string_utils";

import {Flex} from "../../../app/atoms/Flex";
import {NotificationSubscriptionButton} from "../../../app/components/NotificationSubscriptionButton";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferInvestment} from "../../../app/interfaces/response/offer_list";
import {mb_md, w100} from "../../../styles/helpers_linaria";
import {getThemeBreakpointCorrect} from "../../../styles/linaria_variable_factory";
import {generateOfferListBreadcrumbs} from "../../utils/generate_breadcrumbs";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {UrlTagSlug} from "../url_utils/tags";
import {getOfferListHeadingText} from "../utils/get_offer_list_heading_text";
import {OfferListSortButton} from "./OfferListSortButton";

interface IOwnProps {
    offerCount: number;
    offerLocation: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    shouldRenderLocationStats: boolean;
    latestQuery: IOfferListQuery;
    investment: {
        investment: IOfferInvestment | null;
    };
    page: number;
    isMapBig: boolean;
    headingH1?: string;
}

interface IProps extends IOwnProps {}
const offersPluralize = pluralize(["ogłoszenie", "ogłoszenia", "ogłoszeń"]);

export const OfferListHeader = (props: IProps) => {
    const [isNotificationsFormSubmitted, setIsNotificationsFormSubmitted] = useState(false);
    const tagRouteParam = useRouteMatch<{tag?: UrlTagSlug}>(appPath.searchResult.universalTag)?.params.tag;
    const theme = useTheme();

    const breadcrumbsData = generateOfferListBreadcrumbs(
        props.offerLocation.location?.path,
        props.latestQuery.deal_type,
        props.latestQuery.offer_type,
        tagRouteParam
    );

    const offerCountText = `${numberFormat(props.offerCount)} ${offersPluralize(props.offerCount)}`;

    const renderTitleSection = () => {
        const headingText = getOfferListHeadingText(tagRouteParam, props);

        return (
            <Flex justifyContent="space-between" style={{width: "100%"}}>
                <div className={cx(w100, mb_md)}>
                    <h1 data-testid={OFFER_LIST_LIST_TESTID.OFFER_LIST_HEADING_TEXT} className={heading}>
                        {props.headingH1 ? props.headingH1 : headingText}
                    </h1>{" "}
                    <span className={heading}>- {offerCountText}</span>
                </div>
            </Flex>
        );
    };

    return (
        <header className={headerHolder}>
            <OfferListBreadcrumbs breadcrumbs={breadcrumbsData} />

            <div className={noStatsButtonsHolder}>
                {renderTitleSection()}

                <HeaderButtonsHolder isMapBig={props.isMapBig}>
                    {props.offerLocation.location?.id && (
                        <NotificationSubscriptionButton
                            size="sm"
                            variant="primary"
                            inverted
                            icon={<NotificationBellIcon size="2" fillColor={theme.colors.brand_primary} />}
                            latestQuery={props.latestQuery}
                            isSubmitted={isNotificationsFormSubmitted}
                            setIsSubmitted={setIsNotificationsFormSubmitted}
                            className={sortButtonStyles}
                        >
                            Włącz powiadomienia
                        </NotificationSubscriptionButton>
                    )}
                    <OfferListSortButton latestQuerySort={props.latestQuery.sort} className={sortButtonStyles} />
                </HeaderButtonsHolder>
            </div>
        </header>
    );
};
//Styles

const headerHolder = css`
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    background: #fff;
`;

const OfferListBreadcrumbs = styled(Breadcrumbs)`
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: wrap;
`;

const heading = css`
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: inline;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin-top: 0.2rem;
    }
`;

interface IIsMapBigProps {
    isMapBig: boolean;
}

const HeaderButtonsHolder = styled.div<IIsMapBigProps>`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        width: ${(props) => (props.isMapBig ? "100%" : "50%")};
        align-self: center;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin-top: 1rem;
        width: 100%;
        flex-direction: ${(props) => (props.isMapBig ? "column" : "row")};
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_xl}) {
        margin-top: unset;
        width: unset;
    }
`;

const noStatsButtonsHolder = css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    flex-direction: column;

    @media (min-width: ${getThemeBreakpointCorrect().screen_xl}) {
        flex-direction: row;
    }
`;

const sortButtonStyles = css`
    display: none;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: block;
    }
`;
