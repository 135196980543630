import {includes} from "lodash";

import {OfferDealType} from "../../offer/utils/constants_offer";
import {TypeSearchFilterOptions} from "./TypeSearchFilterOptions";

export const getNewOfferType = (currentValues: {offer_type: string[]}, value: string, hasUserInteractedWithOfferType: boolean) => {
    const hasPrimaryHouses = includes(currentValues.offer_type.toString(), TypeSearchFilterOptions.PRIMARY_HOUSE);
    const hasAfterMarketHouses = includes(currentValues.offer_type.toString(), TypeSearchFilterOptions.AFTERMARKET_HOUSE);

    const hasPrimaryApartments = currentValues.offer_type && includes(currentValues.offer_type.toString(), TypeSearchFilterOptions.PRIMARY_APARTMENT);
    const hasAftermarketApartments = currentValues.offer_type && includes(currentValues.offer_type.toString(), TypeSearchFilterOptions.AFTERMARKET_APARTMENT);

    if (value === OfferDealType.RENT) {
        // if user has only PRIMARY_MARKET and switches to `for rent`, include AFTERMARKET offers, otherwise the result list will be empty

        const newOfferType = [...currentValues.offer_type].filter(
            (offerT) => offerT !== TypeSearchFilterOptions.PRIMARY_HOUSE && offerT !== TypeSearchFilterOptions.PRIMARY_APARTMENT
        );

        if (hasPrimaryHouses && !hasAfterMarketHouses) newOfferType.push(TypeSearchFilterOptions.AFTERMARKET_HOUSE);
        if (hasPrimaryApartments && !hasAftermarketApartments) newOfferType.push(TypeSearchFilterOptions.AFTERMARKET_APARTMENT);

        return newOfferType;
    }

    if (value !== OfferDealType.RENT && !hasUserInteractedWithOfferType) {
        // if user has NOT intentionally interacted with offerType filter,
        // set it to cover all market types -  'aftermarket' and 'primary_market'
        const newOfferType = [...currentValues.offer_type];
        if (hasAfterMarketHouses && !hasPrimaryHouses) newOfferType.push(TypeSearchFilterOptions.PRIMARY_HOUSE);
        if (hasAftermarketApartments && !hasPrimaryApartments) newOfferType.push(TypeSearchFilterOptions.PRIMARY_APARTMENT);

        return newOfferType;
    }

    return false;
};
