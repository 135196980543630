import {useRef, useState} from "react";

export const useDebouncedOfferBoxMouseEvents = (debounceInterval = 1000, clearOnLeave = false) => {
    const [hoveredOfferId, setHoveredOfferId] = useState<string | null>(null);

    const timeoutRef = useRef(0);

    const onMouseEnterOfferBox = (offerId: string) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
            setHoveredOfferId(offerId);
        }, debounceInterval);
    };
    const onMouseLeaveOfferBox = () => {
        clearTimeout(timeoutRef.current);
        clearOnLeave && setHoveredOfferId(null);
    };

    return {hoveredOfferId, onMouseEnterOfferBox, onMouseLeaveOfferBox};
};
