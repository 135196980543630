import * as React from "react";
import {isEmpty} from "lodash";
import {IFormFieldProps} from "@web2/form2";

import {OfferDealType} from "../../../offer/utils/constants_offer";
import {mobileFilterHolder} from "../atoms/atoms";

export interface ICustomMobileFilterChildProps<TValue> extends IFormFieldProps<string, TValue> {
    clearField: () => void;
    errorOnBottom?: boolean;
    id: string;
    dealType?: OfferDealType | [];
    offerType?: string;
}

interface IProps<TValue> extends IFormFieldProps<string, TValue> {
    errorOnBottom?: boolean;
    id: string;
    children: (props: ICustomMobileFilterChildProps<TValue>) => JSX.Element;
    onClearField?: () => void;
    dealType?: OfferDealType | [];
    offerType?: string;
}

export class CustomMobileFilter<T> extends React.PureComponent<IProps<T | []>> {
    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, []);
        this.props.onAfterChange(this.props.name, []);
    };
    /**
     * Render
     */

    public render() {
        const {value, error} = this.props;
        const hasError = !isEmpty(error) && "has-error";

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.props.onAfterChange,
                onChange: this.props.onChange,
                value: value,
                id: this.props.id,
                dealType: this.props.dealType,
                offerType: this.props.offerType
            });

        return (
            <div className={mobileFilterHolder}>
                {hasError && <div>error</div>}

                {renderEnchancedChild()}
            </div>
        );
    }
}
