export const RANGE_SETTINGS: {[key: string]: number} = {
    PRICE__LTE: 200000000,
    PRICE__GTE: 1,
    PRICE_STEP: 50000,
    SIZE__LTE: 5000000,
    SIZE__GTE: 1,
    SIZE_STEP: 5,
    PRICE_PER_SQM__LTE: 200000,
    PRICE_PER_SQM__GTE: 1,
    PRICE_PER_SQM_STEP: 1000,
    CONSTRUCTION_YEAR__GTE: 1700,
    CONSTRUCTION_YEAR__LTE: 2100,
    FLOOR__GTE: 1,
    FLOOR__LTE: 100
};
