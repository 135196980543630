import {numberWithDelimiter} from "@web2/string_utils";

import {ILocationStatisticsElementApartment} from "../../../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../../../app/utils/number";

type NumberOrNull = number | null;

export interface IStatsLocationDetailed {
    one_room: ILocationStatisticsElementApartment;
    two_rooms: ILocationStatisticsElementApartment;
    three_rooms: ILocationStatisticsElementApartment;
    four_rooms: ILocationStatisticsElementApartment;
}

const ListItem = (props: {rooms: number; commonString: string; price: number | null | undefined; apartmentRentStats?: IStatsLocationDetailed | undefined}) => {
    const {rooms, commonString, price, apartmentRentStats} = props;

    if (rooms === 1 && apartmentRentStats) {
        return <li>{`kawalerka ${commonString} ${apartmentRentStats && numberWithDelimiter(sizeFormat(apartmentRentStats.one_room.avg_price))} zł;`}</li>;
    }

    return price ? <li>{`mieszkanie ${rooms}-pokojowe ${commonString} ${numberWithDelimiter(sizeFormat(price))} zł${rooms === 4 ? "." : ";"}`}</li> : null;
};

export const AveragePricesRent = (props: {
    locationDeclination: string | undefined;
    apartmentRentStats: IStatsLocationDetailed | undefined;
    avgRentPricesPerRoom: Record<number, number | null | undefined>;
    rentDealPhraseCustomVariant: string;
}) => {
    const {locationDeclination, avgRentPricesPerRoom, apartmentRentStats, rentDealPhraseCustomVariant} = props;

    const commonString = `${rentDealPhraseCustomVariant} ${locationDeclination} kosztuje`;
    const numberOfRoomsToGenerate = [2, 3, 4];

    return (
        <ul>
            {apartmentRentStats && apartmentRentStats.one_room.count > 0 && (
                <ListItem rooms={1} commonString={commonString} price={avgRentPricesPerRoom[1]} apartmentRentStats={apartmentRentStats} />
            )}
            {numberOfRoomsToGenerate.map((room, index) => {
                const avgPricePerRoom = avgRentPricesPerRoom[room];
                const notZero = typeof avgPricePerRoom === "number" && avgPricePerRoom > 0;
                return notZero && <ListItem key={index} rooms={room} commonString={commonString} price={avgRentPricesPerRoom[room]} />;
            })}
        </ul>
    );
};
