import {combineReducers} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchPageDetailTypes, IPageDetailResponse} from "../actions/fetch_page_detail";

export interface IPagesStore {
    pageDetail: IPageDetailResponse;
}

export const pagesReducer = combineReducers({
    pageDetail: reduceResponse(fetchPageDetailTypes)
});
