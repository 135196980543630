import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const DistanceIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "12.13mm"} height={props.height || "12.32mm"} viewBox="0 0 34.4 34.93">
            <path
                d="M12.92,6.46a6.46,6.46,0,1,0-6.46,6.46A6.46,6.46,0,0,0,12.92,6.46Zm-11.11,0a4.65,4.65,0,1,1,4.65,4.65A4.65,4.65,0,0,1,1.81,6.46Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M30.32,26.77a4.08,4.08,0,1,0,4.08,4.08A4.08,4.08,0,0,0,30.32,26.77Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M29.62,26.11a13.15,13.15,0,0,0-2.44-4.58l-1.36,1.19a11.43,11.43,0,0,1,2.08,4Z" fill={props.mainColor || "#37474f"} />
            <path
                d="M8.8,16.94a8.88,8.88,0,0,1-.7-2.19,7,7,0,0,1-.13-1l-1.81.08a9.37,9.37,0,0,0,.16,1.23,10.49,10.49,0,0,0,.85,2.63,9.65,9.65,0,0,0,.68,1.17l1.48-1A6.77,6.77,0,0,1,8.8,16.94Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M19.19,19c-.68.08-1.28.18-2.37.37s-1.38.25-1.87.32l.26,1.79c.52-.07.82-.12,1.93-.33s1.63-.28,2.27-.36l.52,0L19.77,19Z"
                fill={props.mainColor || "#37474f"}
            />
            <path
                d="M11.69,19.65a4.63,4.63,0,0,1-1.67-1L8.78,20a6.21,6.21,0,0,0,2.33,1.4,7,7,0,0,0,2.81.28l-.12-1.8A5.42,5.42,0,0,1,11.69,19.65Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M21.07,18.9v1.81a6,6,0,0,1,4,1.29l1.15-1.39A7.71,7.71,0,0,0,21.07,18.9Z" fill={props.mainColor || "#37474f"} />
        </svg>
    );
};
