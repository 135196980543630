import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {IVendorListResponse} from "../../application/actions/fetch_vendor_list_at_route";
import {fetchLocationBySlugTypes, ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {fetchAgencyListActionTypes} from "../actions/fetch_agency_list_at_route";
import {fetchAgencyListSeoDescriptionTypes} from "../actions/fetch_agency_list_seo_description";

export interface IAgencyListStore {
    agencyList: IVendorListResponse;
    seoDescription: string;
    location: ILocation;
}

const agencyListReducerObj: Record<keyof IAgencyListStore, Reducer<any, any>> = {
    agencyList: reduceResponse(fetchAgencyListActionTypes),
    seoDescription: reduceResponse(fetchAgencyListSeoDescriptionTypes),
    location: reduceResponse(fetchLocationBySlugTypes)
};

export const agencyListReducer = combineReducers(agencyListReducerObj);
