import * as React from "react";
import {ReactNode, useState} from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {dropRight, isEmpty} from "lodash";

import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";
import {LogoAndBurger} from "./LogoAndBurger";
import {NavigationElement, navigationElementText} from "./NavigationElement";
import {NavigationElementWithDropdown} from "./NavigationElementWithDropdown";

export interface INavElement {
    title: string | ReactNode;
    url?: string;
    target?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    rel?: string;
    favouritesCount?: number;
    icon?: ReactNode;
    subElements?: INavElement[];
    isActive?: (pathname: string) => boolean;
    disabled?: boolean;
    titleDealTypeSuffix?: string;
    isButton?: boolean;
    className?: string;
}

export interface INavMiddleElement {
    title: string;
    icon: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export type NavLevelType = 0 | 1 | 2 | 3 | 4;

interface IProps {
    id: string;
    navLeftElements: INavElement[];
    navMiddleElement?: INavMiddleElement | null;
    navRightElements?: INavElement[];
    logo: {
        img: string;
        url: string;
        alt: string;
    };
    arrows: {
        left: ReactNode;
        right: ReactNode;
    };
    isMobile?: boolean;
    isPrinting?: boolean;
    favouritesCount?: number;
}

export const Navigation = (props: IProps) => {
    const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [isBlendVisible, setBlendVisible] = useState(false);
    const [navState, setNavState] = useState<number[]>([]);
    const [navRightState, setNavRightState] = useState<number[]>([]);

    if (props.isPrinting) {
        return null;
    }

    const onBurgerClose = () => {
        setMobileMenuVisible(false);
        setNavState([]);
        setBlendVisible(false);
        const body = document.getElementsByTagName("body");
        body[0].classList.remove("overflow-hidden");
    };

    const onBurgerOpen = () => {
        setMobileMenuVisible(true);
        setBlendVisible(true);
        const body = document.getElementsByTagName("body");
        body[0].classList.add("overflow-hidden");
    };

    const generateMobileMenuLabel = () => {
        if (isMobileMenuVisible && !isEmpty(navState)) {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            switch (navState.length) {
                case 1:
                    return props.navLeftElements[navState[0]].title;
                case 2:
                    return props.navLeftElements[navState[0]].subElements![navState[1]].title;
                case 3:
                    return props.navLeftElements[navState[0]].subElements![navState[1]]!.subElements![navState[2]].title;
                case 4:
                    return props.navLeftElements[navState[0]].subElements![navState[1]].subElements![navState[2]]!.subElements![navState[3]].title;
            }
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
        }

        if (isMobileMenuVisible && props.navRightElements && !isEmpty(navRightState)) {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            switch (navRightState.length) {
                case 1:
                    return props.navRightElements[navRightState[0]].title;
                case 2:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]].title;
                case 3:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]]!.subElements![navRightState[2]].title;
                case 4:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]].subElements![navRightState[2]]!.subElements![
                        navRightState[3]
                    ].title;
            }
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
        }

        return "Menu";
    };

    const onBackClick = () => {
        if (!isEmpty(navState)) {
            setNavState(dropRight(navState));
        }

        if (!isEmpty(navRightState)) {
            setNavRightState(dropRight(navRightState));
        }
    };

    const logoAndBurgerProps = {
        logo: props.logo,
        arrows: {
            left: props.arrows.left
        },
        isOpen: isMobileMenuVisible,
        setOpen: onBurgerOpen,
        setClose: onBurgerClose,
        onBack: onBackClick,
        label: generateMobileMenuLabel(),
        navState: !isEmpty(navState) ? navState : navRightState,
        isMobile: props.isMobile
    };

    const renderMobileMiddleElement = () => (
        <>
            {props.navMiddleElement && (
                <div className={mobileMiddleElementWrapper} onClick={props.navMiddleElement.onClick}>
                    {props.navMiddleElement.icon}
                </div>
            )}
        </>
    );

    const renderLogoAndBurger = () => {
        if (props.isMobile) {
            return (
                <div className={fixedNav}>
                    <LogoAndBurger {...logoAndBurgerProps} middleElement={renderMobileMiddleElement()} favouritesCount={props.favouritesCount} />
                </div>
            );
        }
        return <LogoAndBurger {...logoAndBurgerProps} middleElement={renderMobileMiddleElement()} />;
    };

    return (
        <>
            <Blend isActive={isBlendVisible} onClick={onBurgerClose} />

            <NavigationHolder id={props.id}>
                <GridFluidNav>
                    {renderLogoAndBurger()}

                    <div className={navHolder}>
                        <nav className={nav} style={isMobileMenuVisible ? {display: "flex"} : {}}>
                            <NavList navState={!isEmpty(navState) ? navState : navRightState}>
                                <NavigationElementWithDropdown
                                    navElements={props.navLeftElements}
                                    arrows={props.arrows}
                                    setNavState={setNavState}
                                    navState={navState}
                                />

                                {props.navRightElements && !isEmpty(props.navRightElements) && (
                                    <li className={navRightListElement}>
                                        <ul className={navRightHolder}>
                                            {props.navMiddleElement && !props.isMobile && (
                                                <NavigationElement
                                                    title={props.navMiddleElement.title}
                                                    icon={props.navMiddleElement.icon}
                                                    onClick={props.navMiddleElement.onClick}
                                                    css={desktopMiddleElement}
                                                />
                                            )}

                                            <NavigationElementWithDropdown
                                                navElements={props.navRightElements}
                                                arrows={props.arrows}
                                                setNavState={setNavRightState}
                                                navState={navRightState}
                                                isRightElement
                                            />
                                        </ul>
                                    </li>
                                )}
                            </NavList>
                        </nav>
                    </div>
                </GridFluidNav>
            </NavigationHolder>
        </>
    );
};

export const NAV_BG_LEVEL_2 = "#f9f9f9";
export const NAV_BG_LEVEL_3 = "#f2f2f2";
export const NAV_BG_LEVEL_4 = "#ececec";
export const MOBILE_NAV_WIDTH_LEVEL_1 = 320;
export const MOBILE_NAV_WIDTH_LEVEL_2 = 360;
export const MOBILE_NAV_WIDTH_LEVEL_3 = 375;
export const MOBILE_NAV_WIDTH_LEVEL_4 = 414;
export const MOBILE_NAV_WIDTH_LEVEL_5 = 500;

const NavigationHolder = styled.div`
    width: 100%;
    background: ${getThemeVariable("colors-brand_primary")};
    height: ${getThemeVariable("main_nav-height")};
    position: relative;
    font-weight: 500;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        z-index: ${getThemeVariable("main_nav-z_index")};
    }

    @media print {
        display: none !important;
    }
`;

const GridFluidNav = styled.section`
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const mobileMiddleElementWrapper = css`
    height: 60px;
    width: 50px;
    position: absolute;
    right: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: none;
    }
`;
const desktopMiddleElement = css`
    display: none;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: block;
    }
`;

const navHolder = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        justify-content: space-between;
    }
`;

interface IBlendProps {
    isActive: boolean;
}

const Blend = styled.div<IBlendProps>`
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 320px;
    z-index: ${getThemeVariable("main_nav-z_index")} - 1;
    display: ${(props) => (props.isActive ? "block" : "none")};
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"}) {
        right: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        right: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        right: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        right: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        right: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        right: 0;
    }
`;

const nav = css`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (max-width: calc(${getThemeBreakpoint().screen_lg} - 1px)) {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        height: calc(100% - ${getThemeVariable("main_nav-height")});
        position: fixed;
        right: 0;
        top: ${getThemeVariable("main_nav-height")};
        overflow: scroll;
        width: 320px;
        z-index: ${getThemeVariable("main_nav-z_index")};
        animation: fade-in 0.27s cubic-bezier(0.39, 0.575, 0.565, 1) both;

        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"}) {
            width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
            width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
            width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
            width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
            width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
        }
    }
`;

const move = (navState: number[], screenWidth: number) => {
    switch (navState.length) {
        case 1:
            return `-${screenWidth}px`;
        case 2:
            return `-${screenWidth * 2}px`;
        case 3:
            return `-${screenWidth * 3}px`;
        case 4:
            return `-${screenWidth * 3}px`;
        default:
            return "0";
    }
};

interface IMoveNavThemeProps {
    navState: number[];
}

const NavList = styled.ul<IMoveNavThemeProps>`
    margin: 0;
    padding-left: 0;
    list-style: none;

    @media (max-width: calc(${getThemeBreakpoint().screen_lg} - 1px)) {
        transition: transform 0.35s;

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"}) {
            transform: translateX(${(props) => move(props.navState, MOBILE_NAV_WIDTH_LEVEL_1)});
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
            transform: translateX(${(props) => move(props.navState, MOBILE_NAV_WIDTH_LEVEL_2)});
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
            transform: translateX(${(props) => move(props.navState, MOBILE_NAV_WIDTH_LEVEL_3)});
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
            transform: translateX(${(props) => move(props.navState, MOBILE_NAV_WIDTH_LEVEL_4)});
        }

        @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
            transform: translateX(${(props) => move(props.navState, MOBILE_NAV_WIDTH_LEVEL_2)});
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: flex;
        align-items: center;
    }
`;

const navRightListElement = css`
    flex-grow: 1;

    .${navigationElementText} {
        display: block;
    }
`;

const navRightHolder = css`
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
    list-style: none;
    flex-direction: column-reverse;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        flex-direction: row;
    }
`;

const fixedNav = css`
    width: 100%;
    z-index: 2;
    height: ${getThemeVariable("main_nav-height")};
    padding: 0 1rem;
`;
