import React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {appLink} from "@web2/gh_routes";
import {UserIcon} from "@web2/icons";
import {kmAppLink} from "@web2/km_routes";

import {apiLink} from "../app/routes/api_link";
import {loginClientViaRedirect} from "../app/user/login_via_redirect";
import {IsAuthenticatedStore} from "../app/user/reducers/is_authenticated_reducer";
import {appendQueryString} from "../app/utils/append_query_string";
import {getKMGHLink} from "../app/utils/get_km_gh_link";
import {enableAuthorization} from "../app/utils/read_environment_variables";
import {RequestState} from "../app/utils/request_response_utils/factories/reduce_request_state";
import {getOfferListUrl} from "../offer/list/url_utils/get_offer_list_url";
import {UrlTagSlug} from "../offer/list/url_utils/tags";
import {DealTypeSlug, OfferType} from "../offer/utils/constants_offer";
import {getThemeBreakpoint, getThemeBreakpointCorrect} from "../styles/linaria_variable_factory";
import {gtmNavigationMenuClick} from "../tracking/google_tag_manager/gtm_navigation_menu_click";
import {NAV_FOR_RENT_FLATS_SECOND_LEVEL, NAV_FOR_SALE_FLATS_SECOND_LEVEL} from "./navigation_links";

const isActive = (param: string) => (pathname: string) => pathname.includes(`/${param}`);

const NAV_FOR_SALE_FIRST_LEVEL = [
    {
        title: "Typ nieruchomości"
    },
    {
        title: "Mieszkania",
        url: getOfferListUrl({
            offerType: OfferType.apartment,
            type: DealTypeSlug.SELL
        }),
        onClick: () => gtmNavigationMenuClick("2", "Mieszkania")
    },
    {
        title: "Kawalerki",
        url: getOfferListUrl({
            tag: UrlTagSlug.STUDIO,
            offerType: OfferType.apartment,
            type: DealTypeSlug.SELL
        }),
        onClick: () => gtmNavigationMenuClick("2", "Kawalerki")
    },
    {
        title: "Domy",
        url: getOfferListUrl({
            offerType: OfferType.house,
            type: DealTypeSlug.SELL
        }),
        onClick: () => gtmNavigationMenuClick("2", "Domy")
    },
    {
        title: "Działki",
        url: getOfferListUrl({
            offerType: OfferType.lot
        }),
        onClick: () => gtmNavigationMenuClick("2", "Działki")
    },
    {
        title: "Popularne lokalizacje"
    },
    ...NAV_FOR_SALE_FLATS_SECOND_LEVEL
];

const NAV_FOR_RENT_FIRST_LEVEL = [
    {
        title: "Typ nieruchomości"
    },
    {
        title: "Wynajem mieszkania",
        url: getOfferListUrl({
            offerType: OfferType.apartment,
            type: DealTypeSlug.RENT
        }),
        onClick: () => gtmNavigationMenuClick("2", "Mieszkania"),
        titleDealTypeSuffix: "do wynajęcia"
    },
    {
        title: "Wynajem kawalerki",
        url: getOfferListUrl({
            tag: UrlTagSlug.STUDIO,
            offerType: OfferType.apartment,
            type: DealTypeSlug.RENT
        }),
        onClick: () => gtmNavigationMenuClick("2", "Kawalerki"),
        titleDealTypeSuffix: "do wynajęcia"
    },
    {
        title: "Wynajem domu",
        url: getOfferListUrl({
            offerType: OfferType.house,
            type: DealTypeSlug.RENT
        }),
        onClick: () => gtmNavigationMenuClick("2", "Domy")
    },
    {
        title: "Popularne lokalizacje"
    },
    ...NAV_FOR_RENT_FLATS_SECOND_LEVEL
];

export const generateLeftNavigation = (favouritesCount: number) => [
    {
        isActive: isActive(DealTypeSlug.SELL),
        title: "Na sprzedaż",
        subElements: NAV_FOR_SALE_FIRST_LEVEL,
        className: "for-sale"
    },
    {
        isActive: isActive(DealTypeSlug.RENT),
        title: "Do wynajęcia",
        subElements: NAV_FOR_RENT_FIRST_LEVEL,
        className: "for-rent"
    },
    {
        isActive: isActive("ulubione"),
        title: "Ulubione",
        url: appLink.favourites(),
        rel: "nofollow",
        onClick: () => gtmNavigationMenuClick("1", "Ulubione"),
        favouritesCount: favouritesCount
    },
    {
        isActive: isActive("blog"),
        title: "Blog",
        url: appLink.article.base(),
        onClick: () => gtmNavigationMenuClick("1", "Blog")
    },
    {
        isActive: isActive("o-nas"),
        title: "O nas",
        url: appLink.aboutUs(),
        onClick: () => gtmNavigationMenuClick("1", "O nas")
    },
    {
        isActive: isActive("dla-posrednika"),
        title: "Dla Pośrednika",
        url: appLink.forAgent(),
        onClick: () => gtmNavigationMenuClick("1", "Dla Pośrednika")
    }
];

export const generateRightNavigation = (isAuthenticatedStore: IsAuthenticatedStore, notify: (text: string) => void) => {
    const isAuthenticatedRequestError = isAuthenticatedStore.requestState === RequestState.Error;

    const submitGtmEvent = () => {
        const isTablet = window.matchMedia(`(min-width: ${getThemeBreakpoint().screen_lg}) and (max-width: ${getThemeBreakpoint().screen_lg_ipad})`).matches;
        const buttonAddOfferLabel = `Dodaj${isTablet ? "" : " bezpłatnie"} ogłoszenie`;
        gtmNavigationMenuClick("1", buttonAddOfferLabel);
    };

    return [
        ...(!enableAuthorization
            ? []
            : [
                  isAuthenticatedStore.response
                      ? {
                            title: "Twoje konto",
                            onClick: (e: React.MouseEvent<HTMLElement>) => {
                                e?.preventDefault();
                            },
                            subElements: authenticatedNavElements,
                            icon: <UserIcon size="3" fillColor="white" secondaryFillColor="#9069c0" className={userIconStyles} />
                        }
                      : {
                            title: "Zaloguj",
                            onClick: (e: React.MouseEvent<HTMLElement>) => {
                                e?.preventDefault();
                                if (isAuthenticatedRequestError) {
                                    notify("Serwis logujący chwilowo nie działa. Pracujemy nad tym");
                                    return;
                                }
                                loginClientViaRedirect();
                            },
                            disabled: isAuthenticatedRequestError,
                            icon: <UserIcon size="3" fillColor="white" secondaryFillColor="#9069c0" className={userIconStyles} />
                        }
              ]),
        {
            isActive: isActive("oferta/dodaj"),
            title: (
                <>
                    Dodaj <SpanInsideButton>bezpłatnie </SpanInsideButton>ogłoszenie
                </>
            ),
            url: appLink.offer.add,
            onClick: submitGtmEvent,
            isButton: true
        }
    ];
};

export const authenticatedNavElements = [
    {
        title: "Twoje dane",
        onClick: (e: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            window.location.href = getKMGHLink(kmAppLink.site.user.baseWithParams);
        }
    },
    {
        title: "Subskrypcje",
        onClick: (e: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            window.location.href = getKMGHLink(kmAppLink.site.user.subscriptionWithParams);
        }
    },
    {
        title: "Moje ogłoszenia",
        url: appLink.yourOffers()
    },
    {
        title: "Zmień hasło",
        onClick: (e: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            window.location.href = getKMGHLink(kmAppLink.site.user.passwordChange);
        }
    },
    {
        title: "Wyloguj",
        onClick: (e: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            window.location.href = appendQueryString(apiLink.userApi.users.logout(), {
                next: `${location.origin}${location.pathname}`
            });
        }
    }
];

const userIconStyles = css`
    margin-right: 1.5rem;
`;

const SpanInsideButton = styled.span`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: none;
    }
    @media (min-width: ${getThemeBreakpointCorrect().screen_xl}) {
        display: inline;
    }
`;
