interface IProps {
    agent?: string | null;
    agency?: string | null;
    developer?: string | null;
    investment?: string | null;
}

export const generateApplicationText = (props: IProps | null) => {
    let text = "Zainteresowała mnie ta nieruchomość. Proszę o niezobowiązujący kontakt w sprawie szczegółów oferty.";

    if (props?.agent) {
        text = "Zainteresowała mnie jedna z ofert. Proszę o niezobowiązujący kontakt w sprawie szczegółów.";
    } else if (props?.agency) {
        text = "Zainteresowała mnie nieruchomość w Państwa ofercie. Proszę o niezobowiązujący kontakt w sprawie szczegółów.";
    } else if (props?.developer) {
        text = "Zainteresowała mnie nieruchomość w Państwa inwestycji. Proszę o niezobowiązujący kontakt w sprawie szczegółów.";
    } else if (props?.investment) {
        text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.investment}. Proszę o niezobowiązujący kontakt w sprawie szczegółów.`;
    }
    return text;
};
