import {useSelector} from "react-redux";
import {NotificationsReduxContainer, useNotificationsRouteHandler} from "@web2/notifications";

import {IStore} from "../reducers/hybrid_reducer";

export const GHNotifications = () => {
    const notifications = useSelector((store: IStore) => store.notifications);
    useNotificationsRouteHandler();

    return <NotificationsReduxContainer notifications={notifications} />;
};
