/* eslint-disable-nex-line @typescript-eslint/no-explicit-any */

import {combineReducers} from "redux";

import {reduceReducers} from "../../utils/reduce_reducers";
import {reduceRequestState, RequestState} from "../../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../../utils/request_response_utils/factories/reduce_response";
import {fetchUserProfileRequestTypes} from "../actions/fetch_user_profile";

export interface IUserProfile {
    email: string;
    id: number;
    name: string | null;
    phone: string | null;
    uuid: string;
}

export interface IUserProfileStore {
    profile: IUserProfile | null;
    requestState: RequestState;
}

export const userProfileReducer = combineReducers({
    profile: reduceReducers(reduceResponse<IUserProfile>(fetchUserProfileRequestTypes)),
    requestState: reduceRequestState(fetchUserProfileRequestTypes)
});
