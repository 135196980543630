/* eslint-disable @typescript-eslint/no-explicit-any */
import {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {isFunction, isNumber} from "lodash";
import {createRange, FormErrorMessage} from "@web2/form_fields";
import {EyeCrossedIcon, EyeIcon} from "@web2/icons";

import {CloseButtonIcon} from "./CloseButtonIcon";
import {TextLimitCounter} from "./TextLimitCounter";

type InputSize = "lg" | "sm";

export interface IInputProps<T> {
    children?: ReactNode;
    error?: string[] | null;

    name: string;
    onAfterChange: (name: string, value: T) => void;
    onChange: (name: string, value: T) => void;
    value: T;
    // additional
    onBlur?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    onFocus?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    pattern?: string;
    placeholder?: string;
    type?: string;
    icon?: JSX.Element;
    iconPositionRight?: boolean;
    label?: string | ReactNode;
    labelAttachedOnBorder?: boolean;
    required?: boolean;
    labelClassName?: string;
    className?: string;
    groupClassName?: string;
    autoFocus?: boolean;
    focusOnClear?: boolean;
    submitOnClear?: boolean;
    maxLength?: number;
    maxNumberLength?: number;
    readOnly?: boolean;
    size?: InputSize;
    hideClearButton?: boolean;
    onClick?: () => void;
    errorOnBottom?: boolean;
    id?: string;
    callAfterChangeOnEnter?: boolean;
    isHorizontal?: boolean;
    errorClassName?: string;
    allowPasswordPreview?: boolean;
    multiple?: boolean;
    disabled?: boolean;
    onKeyDown?: (event?: React.KeyboardEvent) => void;
    inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
}

export const Input: React.FC<IInputProps<string>> = (props) => {
    const defaultValue = "";

    const input = useRef<HTMLInputElement | null>(null);

    const [latestFocusValue, setLatestFocusValue] = useState(props.value);
    const [shouldBeSubmitted, setShouldBeSubmitted] = useState(false);
    const [inputType, setInputType] = useState<string>(props.type || "text");

    useEffect(() => {
        if (props.submitOnClear && shouldBeSubmitted) {
            setLatestFocusValue(props.value);
            setShouldBeSubmitted(false);
            props.onAfterChange(props.name, props.value);
        }
    }, [props.submitOnClear, shouldBeSubmitted]);

    useEffect(() => {
        if (props.type) {
            setInputType(props.type);
        }
    }, [props.type]);

    const onFocus = () => {
        setLatestFocusValue(props.value);
        props.onFocus && props.onFocus();
    };
    const onKeyDown = (event: React.KeyboardEvent) => {
        if (props.type && props.type == "number") {
            if (event.code == "KeyE" || event.code == "Minus" || event.code == "Equal") {
                event.preventDefault();
            }
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        const {onAfterChange, value} = props;
        const hasValueChanged = isFunction(onAfterChange) && latestFocusValue !== value;
        if (hasValueChanged) {
            onAfterChange(props.name, value);
        }

        props.onBlur && props.onBlur(event);
    };

    const onKeyPress = (e: React.KeyboardEvent) => {
        // charCode 13 = Enter key
        if (e.charCode === 13) {
            setLatestFocusValue(props.value);
            if (!!props.callAfterChangeOnEnter) {
                props.onAfterChange(props.name, props.value);
            }
        }
    };

    const onChange = (e: {target: {files?: any[]} | HTMLInputElement}) => {
        let value = e.target.files ? e.target.files[0] : (e.target as HTMLInputElement).value;

        if (props.type === "number" && isNumber(props.maxNumberLength) && value.length > props.maxNumberLength) {
            value = value.slice(0, props.maxNumberLength);
        }
        props.onChange(props.name, value);
    };

    const clearField = () => {
        props.onChange(props.name, defaultValue);
        // setShouldBeSubmitted(true) was previously running on setTimeout and causing a no-op warning in test env
        // (update on unmounted component), not sure if that timer is needed here
        setShouldBeSubmitted(true);
        if (props.focusOnClear && input) {
            input.current && input.current.focus();
        }
    };

    const togglePasswordPreview = useCallback(() => {
        setInputType((prev) => (prev === "password" ? "text" : "password"));
    }, []);

    /**
     * Styles
     */
    const groupClassName = classNames(props.groupClassName);
    const labelClassName = classNames(props.labelClassName);
    const className = classNames(props.className);

    return (
        <InputWrapper isHorizontal={props.isHorizontal} error={props.error} errorOnBottom={props.errorOnBottom} className={groupClassName}>
            {props.label && !props.labelAttachedOnBorder && (
                <Label htmlFor={props.id} className={labelClassName} data-testid={`input-label-${props.name}`}>
                    {props.label}
                    {props.required && (
                        <span css={required} data-testid={`input-required-asterisk-${props.name}`}>
                            *
                        </span>
                    )}
                </Label>
            )}

            {props.error && !props.errorOnBottom && <FormErrorMessage className={props.errorClassName} error={props.error} />}

            <InputFieldWrapper icon={props.icon} iconPositionRight={props.iconPositionRight} size={props.size}>
                {props.label && props.labelAttachedOnBorder && (
                    <Label
                        htmlFor={props.id}
                        className={labelClassName}
                        data-testid={`input-label-${props.name}`}
                        labelAttachedOnBorder={props.labelAttachedOnBorder}
                    >
                        {props.label} {props.required && <span data-testid={`input-required-asterisk-${props.name}`}>*</span>}
                    </Label>
                )}
                <InputField
                    ref={input}
                    name={props.name}
                    id={props.id}
                    value={props.type && props.type == "number" && Number(props.value) == 0 ? parseInt(props.value) : props.value || defaultValue}
                    placeholder={props.placeholder}
                    type={inputType}
                    onFocus={onFocus}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    onChange={onChange}
                    autoFocus={props.autoFocus}
                    pattern={props.pattern}
                    maxLength={props.maxLength}
                    readOnly={props.readOnly}
                    sizeVariant={props.size as InputSize}
                    className={className}
                    hideClearButton={props.hideClearButton}
                    data-testid={`input-${props.name}`}
                    required={props.required}
                    disabled={props.disabled}
                    onKeyDown={onKeyDown}
                    inputMode={props.inputMode}
                />
                {!props.readOnly &&
                    !!props.value && // Show only when input is empty. Felt like it could be a global change. KS
                    !props.iconPositionRight && ( // do not allow to clear input when icon is positioned to right side
                        <ClearButton data-testid="input-clear-btn" onClick={clearField} hideClearButton={props.hideClearButton}>
                            <CloseButtonIcon height={10} width={10} />
                        </ClearButton>
                    )}

                {props.allowPasswordPreview ? (
                    <PasswordIconWrapper onClick={togglePasswordPreview}>
                        {inputType === "password" ? <EyeIcon size={"1.6"} /> : <EyeCrossedIcon size={"1.6"} />}
                    </PasswordIconWrapper>
                ) : (
                    props.icon
                )}
                {props.maxLength ? <TextLimitCounter value={props.value} maxLength={props.maxLength} /> : null}
            </InputFieldWrapper>

            {props.error && props.errorOnBottom && (
                <FormErrorMessage className={props.errorClassName} error={props.error} errorOnBottom={props.errorOnBottom} />
            )}
        </InputWrapper>
    );
};

// Input is typed as any for its very complex logic. In future we plan to refactor createRange so it doesn't depend so much on children's props and so on.
export const InputRange = createRange(Input as any);

/**
 * Input Styles
 */

interface IInputFieldThemeProps {
    sizeVariant: InputSize;
    hideClearButton?: boolean;
}

interface IInputWrapperThemeProps {
    isHorizontal?: boolean;
    error?: string[] | null;
    errorOnBottom?: boolean;
}

interface IInputFieldWrapperThemeProps {
    icon?: JSX.Element;
    iconPositionRight?: boolean;
    size?: InputSize;
}

interface IClearButtonThemeProps {
    hideClearButton?: boolean;
}

interface ILabelThemeProps {
    labelAttachedOnBorder?: boolean;
}

const InputField = styled.input<IInputFieldThemeProps>`
    display: block;
    background-image: none;
    background-color: ${(props) => (props.disabled ? props.theme.colors.gray_brighter : props.theme?.forms?.input_bg ?? "#fff")};
    outline: 0;
    color: ${(props) => props.theme?.forms?.input_color ?? "#333"};
    font-weight: ${(props) => props.theme?.forms?.input_font_weight ?? 400};
    line-height: ${(props) => props.theme?.fonts?.line_height_base ?? "4.2rem"};
    transition: ${(props) => props.theme?.forms?.input_transition ?? "border-color .2s ease-in-out, box-shadow .2s ease-in-out"};

    width: 100%;
    height: ${(props) => props.theme?.forms?.input_height_base ?? "4.2rem"};
    padding: ${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"}
        ${(props) => (props.hideClearButton ? props.theme?.padding?.padding_base_horizontal ?? "1.8rem" : "4rem")}
        ${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"} ${(props) => props.theme?.padding?.padding_base_horizontal ?? "1.8rem"};
    border: 1px solid ${(props) => props.theme?.forms?.input_border ?? "#909090"};
    border-radius: ${(props) => props.theme?.forms?.input_border_radius ?? ".4rem"};
    font-size: ${(props) => props.theme?.forms?.input_font_size_base ?? "1.2rem"};

    @media (max-width: ${(props) => props.theme?.breakpoints?.screen_md ?? "1024px"}) {
        // iOS font size zoom prevent hack
        //Base multiply value + 33.34%;
        font-size: 1.6rem;
        transform-origin: top left;
        transform: scale(0.75); //  12px to 16px

        //  size up
        width: calc(100% * 1.3334);
        height: calc(${(props) => props.theme?.forms?.input_height_base ?? "4.2rem"} * 1.3334);\
        padding: calc(${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"} * 1.3334)
            calc(${(props) => (props.hideClearButton ? props.theme?.padding?.padding_base_horizontal ?? "1.8rem" : "4rem")} * 1.3334)
            calc(${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"} * 1.3334) calc(${(props) =>
                props.theme?.padding?.padding_base_horizontal ?? "1.8rem"} * 1.3334);
        border-radius: calc(${(props) => props.theme?.forms?.input_border_radius ?? ".4rem"} * 1.3334);
        border: calc(1px * 1.3334) solid ${(props) => props.theme?.forms?.input_border ?? "#909090"};
    }

    &:active,
    &:focus {
        border: 1px solid ${(props) => props.theme?.forms?.input_border_focus ?? "#90caf9"};
        box-shadow: ${(props) => props.theme?.forms?.input_box_shadow_focus ?? "inset 0 0 0 1px #90caf9"};
    }

    &::placeholder {
        color: ${(props) => props.theme?.forms?.input_color_placeholder ?? "#333"};
    }

    ${(props) =>
        props.sizeVariant === "sm" &&
        css`
            height: ${props.theme?.forms?.input_height_small ?? "3.4rem"};
            padding: ${props.theme?.padding?.padding_small_vertical ?? ".6rem"}
                ${props.hideClearButton ? props.theme?.padding?.padding_base_horizontal ?? "1.8rem" : "4rem"}
                ${props.theme?.padding?.padding_small_vertical ?? ".6rem"} ${props.theme?.padding?.padding_base_horizontal ?? "1.8rem"};
            font-size: ${props.theme?.forms?.input_font_size_small ?? "1.2rem"};
            line-height: ${props.theme?.fonts?.line_height_small ?? 1.428571429};

            @media (max-width: ${props.theme?.breakpoints?.screen_md ?? "1024px"}) {
                // iOS font size zoom prevent hack
                //Base multiply value + 33.34%;
                transform-origin: top left;
                transform: scale(0.75);

                //  size up
                width: calc(100% * 1.3334);
                height: calc(${props.theme?.forms?.input_height_small ?? "3.4rem"} * 1.3334);
                padding: calc(${props.theme?.padding?.padding_small_vertical ?? ".6rem"} * 1.3334)
                    calc(${props.hideClearButton ? props.theme?.padding?.padding_base_horizontal ?? "1.8rem" : "4rem"} * 1.3334)
                    calc(${props.theme?.padding?.padding_small_vertical ?? ".6rem"} * 1.3334)
                    calc(${props.theme?.padding?.padding_base_horizontal ?? "1.8rem"} * 1.3334);
            }
        `}

    ${(props) =>
        props.sizeVariant === "lg" &&
        css`
            height: ${props.theme?.forms?.input_height_large ?? "5rem"};
            padding: ${props.theme?.padding?.padding_large_vertical ?? "1.2rem"}
                ${props.hideClearButton ? props.theme?.padding?.padding_base_horizontal ?? "1.8rem" : "5rem"}
                ${props.theme?.padding?.padding_large_vertical ?? "1.2rem"} ${props.theme?.padding?.padding_base_horizontal ?? "1.8rem"};
            font-size: ${props.theme?.forms?.input_font_size_large ?? "1.6rem"};
            line-height: ${props.theme?.fonts?.line_height_large ?? 1.3333333};
        `}
`;

const InputWrapper = styled.div<IInputWrapperThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${(props) => props.theme?.forms?.form_group_margin_bottom ?? "2rem"};

    ${(props) =>
        props.error &&
        props.errorOnBottom &&
        css`
            margin-bottom: 0;
        `}

    ${(props) =>
        props.isHorizontal &&
        css`
            flex-direction: row;
            align-items: center;

            > label {
                flex-grow: 0;
                width: auto;
                margin-bottom: 0;
            }

            > div {
                flex-grow: 1;
            }
        `}
    
    ${(props) =>
        props.error &&
        css`
        ${InputField} {
            border-color: ${props.theme?.forms?.error_message_color ?? "#ff5a5f"};
            box-shadow: ${props.theme?.forms?.error_message_input_box_shadow ?? "inset 0 0 0 1px #ff5a5f"};
            
            &:active,
            &:focus {
                border-color: ${props.theme?.forms?.error_message_color ?? "#ff5a5f"};
            }
        `}
`;

const InputFieldWrapper = styled.div<IInputFieldWrapperThemeProps>`
    position: relative;
    height: ${(props) =>
        (props.size === "sm" && (props.theme?.forms?.input_height_small ?? "3.4rem")) ||
        (props.size === "lg" && (props.theme?.forms?.input_height_large ?? "5rem")) ||
        (props.theme?.forms?.input_height_base ?? "4.2rem")};

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    ${(props) =>
        props.icon &&
        css`
            > svg,
            span > svg {
                position: absolute;
                left: 1.5rem;
                top: 50%;
                margin-top: -1%;
            }

            ${InputField} {
                padding-left: 4rem;
            }
        `}

    ${(props) =>
        props.icon &&
        props.iconPositionRight &&
        css`
            > svg,
            span > svg {
                right: 1.5rem;
                left: unset;
            }

            ${InputField} {
                padding-right: 4rem;
                padding-left: 1.8rem;
            }
        `}
`;

const Label = styled.label<ILabelThemeProps>`
    text-align: left;
    font-size: ${(props) => props.theme?.forms?.label_font_size ?? "1.2rem"};
    width: 100%;
    margin-bottom: ${(props) => props.theme?.forms?.label_vertical_margin ?? ".5rem"};
    margin-right: ${(props) => props.theme?.forms?.label_horizontal_margin ?? "2rem"};
    font-weight: ${(props) => props.theme?.forms?.label_font_weight ?? 500};
    color: ${(props) => props.theme?.forms?.label_color ?? "#000"};
    line-height: ${(props) => props.theme?.fonts?.line_height_base ?? "4.2rem"};

    ${(props) =>
        props.labelAttachedOnBorder &&
        css`
            position: absolute;
            top: -8px;
            left: 15px;
            z-index: 1;
            background-color: ${props.theme?.forms?.label_attached_on_border_background_color ?? "#fff"};
            padding: 0 5px;
            width: auto;
            font-weight: normal;
        `}
`;

const ClearButton = styled.div<IClearButtonThemeProps>`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.hideClearButton &&
        css`
            display: none;
        `}
`;

const PasswordIconWrapper = styled.span`
    cursor: pointer;

    > svg {
        position: absolute;
        top: 50%;
        margin-top: -1%;
        right: 1.5rem;
        left: unset;
    }
`;

const required = (theme: Theme) => css`
    color: ${theme.colors.brand_danger};
`;
