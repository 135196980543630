import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const ThumbUpIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M2.82,4.25l-1.4.29a1.31,1.31,0,0,0-.87.58,1.25,1.25,0,0,0-.19,1L.94,8.89A1.34,1.34,0,0,0,2.24,10a.59.59,0,0,0,.29,0l1.4-.29Z"
                fill={props.secondaryFillColor}
            />

            <path d="M3.21,9.76,2.14,4.63,3.26.82A1,1,0,0,1,3.88.14a1,1,0,0,1,.92,0l.1,0a1.74,1.74,0,0,1,1,1.69L5.77,3.62l2.56-.53a1.19,1.19,0,0,1,1,.24,1.23,1.23,0,0,1,.38.92L9.54,7A1.92,1.92,0,0,1,8,8.79ZM2.92,4.68l.87,4.25,4.1-.82A1.22,1.22,0,0,0,8.86,7L9,4.25A.33.33,0,0,0,8.86,4a.4.4,0,0,0-.34-.1L5,4.49l.1-2.61a1.09,1.09,0,0,0-.58-1l-.1,0a.4.4,0,0,0-.34,0C4,.82,4,.91,3.93,1Z" />
        </SvgIcon>
    );
};
