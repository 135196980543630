/**
 * converts first character in string to upper case, remaining to lower case
 * @param string - string to convert
 */

export const capitalize = (string: string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : "";
};

export const capitalizeFirst = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
