import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const NatureIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "8.1mm"} height={props.height || "9.12mm"} viewBox="0 0 22.97 25.85">
            <path
                d="M22.92,17.78,17,.67a1,1,0,0,0-1.89,0l-3.41,9.85a6.69,6.69,0,0,1,.48,8.58H15v-5a.75.75,0,0,1,1.5,0v5H22a1.26,1.26,0,0,0,.33-.05A1,1,0,0,0,22.92,17.78Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path d="M9.12,18.1a1,1,0,0,0,1,1,5.2,5.2,0,0,0,1-6.92l-1.94,5.6A1.13,1.13,0,0,0,9.12,18.1Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M11.69,10.52A6.74,6.74,0,1,0,6,21.81V25.1a.75.75,0,0,0,1.5,0V21.81a6.74,6.74,0,0,0,4.19-11.29ZM6.75,15.14a.76.76,0,0,0-.75.75v4.39a5.19,5.19,0,1,1,1.5,0V15.89A.76.76,0,0,0,6.75,15.14Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M15.75,13.35a.76.76,0,0,0-.75.75v11a.75.75,0,0,0,1.5,0v-11A.76.76,0,0,0,15.75,13.35Z" fill={props.mainColor || "#37474f"} />
        </svg>
    );
};
