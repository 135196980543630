import * as React from "react";
import {styled} from "@linaria/react";
import {map} from "lodash";
import {dateTimeFormat, formatPL} from "@web2/string_utils";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {availableFromDates} from "../../utils/available_from_dates";
import {dropdownList, dropdownListElement} from "../atoms/atoms";
import {IFilterChildProps} from "./SearchFiltersFilter";

export const availableFromOptions = [
    {value: formatPL(availableFromDates.now, dateTimeFormat.shortDateDashed), label: "Oddane do użytku"},
    {value: formatPL(availableFromDates.three_months, dateTimeFormat.shortDateDashed), label: "Do 3 miesięcy"},
    {value: formatPL(availableFromDates.six_months, dateTimeFormat.shortDateDashed), label: "Do 6 miesięcy"},
    {value: formatPL(availableFromDates.twelve_months, dateTimeFormat.shortDateDashed), label: "Do roku"},
    {value: formatPL(availableFromDates.twenty_four_months, dateTimeFormat.shortDateDashed), label: "Do dwóch lat"}
];

export const AvailableFromSelect = (props: IFilterChildProps<string>) => {
    const onAvailableFromSelect = (availableFrom: string) => {
        props.toggleDropdown && props.toggleDropdown();
        props.onChange(props.name, {lower: "", upper: availableFrom});
        props.onAfterChange && props.onAfterChange(props.name, {lower: "", upper: availableFrom});
    };
    return (
        <ul className={dropdownList}>
            {map(availableFromOptions, (o) => (
                <li className={dropdownListElement} key={o.value}>
                    <DropdownListElementBody onClick={() => onAvailableFromSelect(o.value)} isActive={false}>
                        {o.label}
                    </DropdownListElementBody>
                </li>
            ))}
        </ul>
    );
};

export const DropdownListElementBody = styled.div<{isActive?: boolean}>`
    padding: 1rem 1.5rem;
    display: block;
    text-decoration: none;
    color: ${(props) => (props.isActive ? getThemeVariable("colors-brand_primary") : "unset")};
    cursor: pointer;
    background-color: ${(props) => (props.isActive ? getThemeVariable("colors-filter_active_bg") : "unset")};
`;
