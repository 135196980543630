import * as React from "react";

export const SmallMapIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g opacity="0.54">
                <path d="M36,36H0V0H36ZM33,3H3V33H33Z" fill="#010101" />
                <polygon points="11.65 2.6 14.74 2.6 14.74 14.74 2.6 14.74 2.6 11.65 11.65 11.65 11.65 2.6" fill="#010101" />
                <polygon points="24.24 33.3 21.15 33.3 21.15 21.15 33.3 21.15 33.3 24.24 24.24 24.24 24.24 33.3" fill="#010101" />
            </g>
        </svg>
    );
};
