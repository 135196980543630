import {capitalize} from "@web2/nodash";

import {getMetaPage} from "../../../meta_data/utils/get_meta_page";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {OfferDealType, OfferTypePL} from "../../utils/constants_offer";
import {locationNameParser} from "../../utils/location_name_parser";
import {getOfferTypePluralized, translateOfferType} from "../../utils/utils";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {UrlTagSlug} from "../url_utils/tags";

// TODO: add unit tests

export const getOfferListHeadingText = (
    tagRouteParam: UrlTagSlug | undefined,
    props: {
        offerCount: number;
        latestQuery: IOfferListQuery;
        investment: {
            investment: {
                name: string | null;
            } | null;
        };
        offerLocation: {
            location: ILocation | null;
        };
        page: number;
    }
) => {
    const offerType = capitalize(translateOfferType(props.latestQuery.offer_type || "", OfferTypePL));
    const foundTag = getUrlTagDataBySlug(tagRouteParam);

    const tagPrefix = (() => {
        if (!foundTag) return null;

        switch (foundTag?.tag) {
            case UrlTagSlug.STUDIO:
                return offerType.toLowerCase() === OfferTypePL.apartment ? `Kawalerka` : `${offerType} 1 pokój`;
            case UrlTagSlug.TWO_ROOM:
                return `${offerType} 2 pokoje`;
            case UrlTagSlug.THREE_ROOM:
                return `${offerType} 3 pokoje`;
            case UrlTagSlug.FOUR_ROOM:
                return `${offerType} 4 pokoje`;
            case UrlTagSlug.FIVE_ROOM:
                return `${offerType} 5 pokoi`;
            default:
                return "";
        }
    })();

    const dealType = getDealType(props.latestQuery.offer_type, props.latestQuery.deal_type, !!tagPrefix);
    const isSingleDealType = dealType.includes("sprzedaż i wynajem") ? false : true;

    const location = () => {
        if (props.latestQuery.geo_point && props.latestQuery.search_name) {
            return props.latestQuery.search_name;
        }
        if (props.latestQuery.investment && props.investment.investment) {
            return props.investment.investment.name;
        }
        if (props.offerLocation.location) {
            return locationNameParser(props.offerLocation.location.path || props.offerLocation.location.short_name);
        }
        if (props.latestQuery.geo_box_bottom_left || props.latestQuery.geo_box_top_right) {
            return "";
        }
        return "Cała Polska";
    };

    const getLocationAndDealTypeString = () => {
        if (isSingleDealType) {
            return dealType + " " + location();
        }

        return location() + " - " + dealType;
    };

    const metaPageNumber = getMetaPage(props.page);

    switch (true) {
        case !!tagPrefix:
            return `${tagPrefix} ${location()} ${dealType}${metaPageNumber}`;
        case !!foundTag?.metaText:
            return `${offerType} ${foundTag?.metaText || ""} ${dealType == "do wynajęcia" ? `${dealType} ${location()}` : location()}${metaPageNumber}`;
        case foundTag?.tag === UrlTagSlug.INDIVIDUAL_OFFERS:
            return `${offerType} ${dealType} ${location()} bez pośredników${metaPageNumber}`;
        case foundTag?.tag === UrlTagSlug.PRIMARY_MARKET:
            return `Rynek pierwotny ${location()} - ${getOfferTypePluralized(offerType, props.offerCount).toLowerCase()} ${dealType}${metaPageNumber}`;
        default:
            return `${offerType} ${getLocationAndDealTypeString()}${metaPageNumber}`;
    }
};

const getDealType = (offerType: TypeSearchFilterOptions, offerDealType?: OfferDealType, withTagPrefix?: boolean) => {
    const isAftermarket = offerType.includes("aftermarket");
    const isPrimaryMarket = offerType.includes("primary_market");
    const isLot = offerType === TypeSearchFilterOptions.LOT;
    const forSale = offerDealType === OfferDealType.SELL;
    const forRent = offerDealType === OfferDealType.RENT;

    switch (true) {
        case withTagPrefix && isAftermarket && !forSale && !forRent:
            return "- sprzedaż i wynajem";
        case withTagPrefix && isAftermarket && forRent:
        case isAftermarket && forRent:
            return "do wynajęcia";
        case isAftermarket && !forSale && isAftermarket && !forRent:
            return "sprzedaż i wynajem";
        case isLot:
        case isAftermarket && forSale:
        case isPrimaryMarket && forSale:
        case withTagPrefix && isAftermarket && forSale:
        case withTagPrefix && isPrimaryMarket && forSale:
        default:
            return "na sprzedaż";
    }
};
