/**
 * Universal Request Id Service
 *
 * Validate request id so when action is called more than once at a time we can abort pending requests, fetch will experience ResponseAbortedError
 */
export interface IRequestIdService {
    resetOrStartAbortControllerSignal: (requestId: string | null) => AbortSignal | null;
}

export const createRequestIdService = (): IRequestIdService => {
    const _abortControllersMap: Record<string, AbortController> = {};

    function resetOrStartAbortControllerSignal(requestId: string | null) {
        if (!requestId) {
            return null;
        }

        if (_abortControllersMap[requestId]) {
            // abort previous request
            _abortControllersMap[requestId].abort();
        }

        // store and return a new signal
        _abortControllersMap[requestId] = new AbortController();
        return _abortControllersMap[requestId].signal;
    }

    /**
     * API
     */
    return {resetOrStartAbortControllerSignal};
};

// return new unique hash
function guid(): string {
    function s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}
