import {OfferDealType, OfferMarketType, OfferType} from "../../offer/utils/constants_offer";
import {hitGoogleTagManager} from "./gtm_init";

export const gtmMultileadView = () => {
    const gtmData = {
        event: "recommendation_modal_view"
    };
    hitGoogleTagManager(gtmData);
};
export const gtmMultileadClose = () => {
    const gtmData = {
        event: "recommendation_modal_close"
    };
    hitGoogleTagManager(gtmData);
};

interface IGtmMultilead {
    event?: string;
    multileadAction?: string;
    propertyVariant: OfferMarketType;
    offerType: OfferDealType;
}

export const gtmMultilead = (market_type: OfferMarketType, offerType: OfferDealType) => {
    const gtmData: IGtmMultilead = {
        event: "multilead",
        multileadAction: "view",
        propertyVariant: market_type,
        offerType: offerType
    };
    hitGoogleTagManager(gtmData);
};
export const gtmModalMultileadClose = () => {
    const gtmData = {
        event: "multilead",
        multileadAction: "close"
    };
    hitGoogleTagManager(gtmData);
};

interface IGtmMultileadCheckbox {
    event?: string;
    multileadAction?: string;
    propertyVariant: OfferMarketType;
}
export const gtmMultileadCheckbox = (checked: boolean, market_type: OfferMarketType) => {
    const gtmData: IGtmMultileadCheckbox = {
        event: "multilead",
        multileadAction: `${checked ? "check" : "uncheck"}`,
        propertyVariant: market_type
    };
    hitGoogleTagManager(gtmData);
};
export const gtmMultileadQuestionChange = () => {
    const gtmData = {
        event: "multilead",
        multileadAction: "queston_change_click"
    };
    hitGoogleTagManager(gtmData);
};
export const gtmMultileadSend = (offerCount: number) => {
    const gtmData = {
        event: "multilead",
        multileadAction: "send_button_click",
        offerCount: offerCount
    };
    hitGoogleTagManager(gtmData);
};
