import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const StreetViewIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "4.08mm"} height={props.height || "4.08mm"} viewBox="0 0 11.56 11.56">
            <path
                d="M4.91,2.89a3.72,3.72,0,0,1,.43-1.73H1.16A1.16,1.16,0,0,0,0,2.31V10.4a1.12,1.12,0,0,0,.34.81L6,5.54A3.72,3.72,0,0,1,4.91,2.89Z"
                fill={props.mainColor || "#fff"}
            />
            <path d="M8.67,0a2.89,2.89,0,1,0,2.89,2.89A2.89,2.89,0,0,0,8.67,0Z" fill={props.mainColor || "#fff"} />
            <path
                d="M5.52,7.7a.9.9,0,0,0-.32.68v3.18h4A1.16,1.16,0,0,0,10.4,10.4V6.94a5.24,5.24,0,0,0-1.73-.3A5.31,5.31,0,0,0,5.52,7.7Z"
                fill={props.mainColor || "#fff"}
            />
        </svg>
    );
};
