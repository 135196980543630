import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {appLink} from "@web2/gh_routes";
import {FetchStateType, useFetch} from "@web2/react_utils";
import {BounceLoader, CenterBox} from "@web2/ui_utils";

import {OfferPrivacyPolicyFetch} from "../../application/actions/application-privacy-policy-fetch";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";

type Props =
    | {
          isPrivate: true;
      }
    | (VendorProps & {
          isPrivate: false;
      });

export const TermsRodo = (props: Props) => {
    return props.isPrivate ? (
        <TermsRodoPrivateOffer />
    ) : (
        <TermsRodoVendor offerId={props.offerId} showTermsLink={props.showTermsLink} vendorName={props.vendorName} type={props.type} />
    );
};

const TermsRodoPrivateOffer = () => {
    return (
        <span>
            Administratorem danych jest Property Group Sp. z o.o.{" "}
            <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                więcej o ochronie danych
            </a>
            .
        </span>
    );
};

type VendorProps = {
    type?: VendorType;
    offerId?: string | undefined;
    showTermsLink?: boolean;
    vendorName?: string;
};

const TermsRodoVendor = (props: VendorProps) => {
    const dispatch = useDispatch();

    const {fetchStart: fetchPrivacyPolicyLink, response: privacyPolicyResponse, fetchState} = useFetch(() => dispatch(OfferPrivacyPolicyFetch(props.offerId)));

    useEffect(() => {
        if (props.offerId && props.vendorName) {
            fetchPrivacyPolicyLink();
        }
    }, [props.offerId]);

    const privacyPolicyLink = privacyPolicyResponse?.privacy_policy_file || privacyPolicyResponse?.privacy_policy_url || null;

    const hasPrivacyPolicy = !!privacyPolicyLink;
    const are = hasPrivacyPolicy ? "są:" : "jest";
    const andDeveloper = !hasPrivacyPolicy ? `i ${props.type === VendorType.DEVELOPER ? "deweloper którego" : "agencja której"} dotyczy zapytanie` : "";

    return props.offerId && fetchState !== FetchStateType.Success ? (
        <CenterBox>
            <BounceLoader size="10px" />
        </CenterBox>
    ) : (
        <span>
            Administratorami Twoich danych {are} Property Group Sp. z o.o. {andDeveloper}{" "}
            <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                dowiedz się więcej o przetwarzaniu Twoich danych
            </a>{" "}
            {hasPrivacyPolicy && (
                <>
                    oraz {props.vendorName}
                    <a href={privacyPolicyLink} target="_blank" rel="noopener nofollow">
                        {" "}
                        dowiedz się więcej o przetwarzaniu Twoich danych
                    </a>
                </>
            )}
            . {props.showTermsLink && <AcceptTerms />}
        </span>
    );
};

const AcceptTerms = () => {
    return (
        <>
            Akceptuję{" "}
            <a href={appLink.terms()} target="_blank" rel="noopener nofollow">
                regulamin
            </a>{" "}
            portalu.
        </>
    );
};
