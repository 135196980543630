import {paywallAlgolytics} from "../algolytics/init_algolytics";
import {hitGoogleTagManager} from "../google_tag_manager/gtm_init";

export enum PaywallStep {
    INFORMATION = "info",
    GET_ACCESS = "get_access",
    PAYMENT = "payment",
    OFFER_RETURN = "offer_return"
}
export enum PaywallPrice {
    ONE_TIME = "one-time",
    MONTHLY = "monthly"
}

export interface IPaywallEvent {
    offer_id: string;
    paywall_step: PaywallStep;
    paywall_price?: PaywallPrice;
}

/**
 * Sends hit to GTM and algolytics
 * @param props
 */
export const hitPaywallEvents = (props: IPaywallEvent) => {
    const {offer_id, paywall_step, paywall_price} = props;

    const data: IPaywallEvent = {
        offer_id,
        paywall_step,
        ...(paywall_price ? {paywall_price} : {})
    };

    hitGoogleTagManager({
        event: "paywall",
        ...data
    });

    paywallAlgolytics(mapAlgolyticsData(data));
};

// algolytics accepts values by dictionaries below
const paywallStepMap: Record<PaywallStep, number> = {
    [PaywallStep.GET_ACCESS]: 0,
    [PaywallStep.INFORMATION]: 1,
    [PaywallStep.PAYMENT]: 2,
    [PaywallStep.OFFER_RETURN]: 3
};

const paywallPriceMap: Record<PaywallPrice, number> = {
    [PaywallPrice.MONTHLY]: 0,
    [PaywallPrice.ONE_TIME]: 1
};

interface AlgolyticsPaywallEvent {
    offer_id: string;
    paywall_step: number;
    paywall_price?: number;
}

const mapAlgolyticsData = function (props: IPaywallEvent): AlgolyticsPaywallEvent {
    const {paywall_step, paywall_price} = props;

    return {
        offer_id: props.offer_id,
        paywall_step: paywallStepMap[paywall_step],
        ...(paywall_price ? {paywall_price: paywallPriceMap[paywall_price]} : {})
    };
};
