import {appPath} from "@web2/gh_routes";

import {updateAboutUsMetaData} from "../../../about_us/actions/update_about_us_meta_data";
import {fetchAgencyAgentsAtRoute} from "../../../agency/actions/fetch_agency_agents_at_route";
import {fetchAgencyDetailAtRoute} from "../../../agency/actions/fetch_agency_detail_at_route";
import {fetchAgencyListAtRoute} from "../../../agency/actions/fetch_agency_list_at_route";
import {fetchAgencyListSeoDescriptionAtRoute} from "../../../agency/actions/fetch_agency_list_seo_description";
import {fetchAgencyOffersAtRoute} from "../../../agency/actions/fetch_agency_offers_at_route";
import {fetchDeveloperDetailAtRoute} from "../../../agency/actions/fetch_developer_at_route";
import {fetchDeveloperOffersAtRoute} from "../../../agency/actions/fetch_developer_offers_at_route";
import {updateAgencyDetailMetaData} from "../../../agency/actions/update_agency_meta_data";
import {updateDeveloperDetailMetaData} from "../../../agency/actions/update_developer_meta_data";
import {updateAgencyRegisterMetaData} from "../../../agency_register/actions/update_agency_register_meta_data";
import {updateAgencyRegisterPremiumMetaData} from "../../../agency_register/actions/update_agency_register_premium_meta_data";
import {fetchAgentDetailAtRoute} from "../../../agent/actions/fetch_agent_detail_at_route";
import {fetchAgentOffersAtRoute} from "../../../agent/actions/fetch_agent_offers_at_route";
import {updateAgentMetaData} from "../../../agent/actions/update_agent_metadata";
import {fetchArticleCategoriesListAtRoute} from "../../../article/actions/fetch_article_category_list_at_route";
import {fetchArticleDetailAtRoute} from "../../../article/actions/fetch_article_detail";
import {fetchArticleListAtRoute, fetchArticleListInCategoryAtRoute} from "../../../article/actions/fetch_article_list_at_route";
import {fetchDistrictArticleListAtRoute} from "../../../article/actions/fetch_district_article_list_at_route";
import {fetchRelatedArticlesAtRoute} from "../../../article/actions/fetch_related_articles";
import {selectArticlePathActions} from "../../../article/actions/select_article_path_actions";
import {updateBlogCategoryMetaData} from "../../../article/actions/update_article_category_metadata";
import {updateArticleDetailMetaData} from "../../../article/actions/update_article_detail_meta_data";
import {updateBlogMetaData} from "../../../article/actions/update_article_metadata";
import {fetchAuthorArticlesAtRoute} from "../../../author/actions/fetch_author_articles_at_route";
import {fetchAuthorDetailAtRoute} from "../../../author/actions/fetch_author_detail_at_route";
import {fetchAuthorListAtRoute} from "../../../author/actions/fetch_author_list_at_route";
import {updateAuthorDetailMetaData} from "../../../author/actions/update_author_detail_meta_data";
import {updateAuthorListMetaData} from "../../../author/actions/update_author_list_meta_data";
import {updateBuylandoMetaData} from "../../../buylando/actions/update_buylando_meta_data";
import {fetchDevelopersListAtRoute} from "../../../developers/actions/fetch_developer_list_at_route";
import {fetchDevelopersListSeoDescriptionAtRoute} from "../../../developers/actions/fetch_developers_list_seo_description";
import {updateFavouriteMetaData} from "../../../favourites/actions/update_favourite_metadata";
import {updateForAgentMetaData} from "../../../for_agent/actions/update_for_agent_meta_data";
import {updateHomepageMetaData} from "../../../homepage/actions/update_homepage_meta_data";
import {fetchindIvidualOfferDetailAtRoute} from "../../../individual_offers/actions/fetch_individual_offer_detail";
import {fetchIndividualOffersAtRoute} from "../../../individual_offers/actions/fetch_individual_offers_action";
import {forceSessionSyncAtRoute} from "../../../individual_offers/actions/force_session_sync";
import {updateAddIndividualOfferMetaData} from "../../../individual_offers/actions/update_add_individual_offer_metadata";
import {updateRenewIndividualOfferMetaData} from "../../../individual_offers/actions/update_renew_offer_metadata";
import {updateVerifyIndividualOfferMetaData} from "../../../individual_offers/actions/update_verify_offer_metadata";
import {fetchCustomMetaData} from "../../../meta_data/actions/fetch_custom_meta_data";
import {closeOfferModalAtRoute, openOfferModalAtRoute} from "../../../offer/actions/toggle_offer_modal_at_route";
import {fetchOfferAtRoute} from "../../../offer/detail/actions/fetch_offer_detail_at_route";
import {fetchOfferFeatures} from "../../../offer/detail/actions/fetch_offer_features";
import {redirectOldLotsAtRoute} from "../../../offer/detail/actions/redirect_old_lots";
import {updateOfferMetaData} from "../../../offer/detail/actions/update_offer_metadata";
import {validateOfferDetailFetchAtRoute} from "../../../offer/detail/actions/validate_offer_detail_fetch";
import {fetchInvestmentDetailAtRoute} from "../../../offer/investment/actions/fetch_investment_at_route";
import {fetchInvestmentPoiAtRoute} from "../../../offer/investment/actions/fetch_investment_poi_at_route";
import {updateInvestmentDetailMetaData} from "../../../offer/investment/actions/update_investment_detail_metadata";
import {fetchLocationBySlugAtRoute} from "../../../offer/list/actions/fetch_location_by_slug_at_route";
import {fetchLocationStatisticsAtRoute} from "../../../offer/list/actions/fetch_location_statistics";
import {fetchOfferListAtRoute} from "../../../offer/list/actions/fetch_offer_list_at_route";
import {fetchOfferListInvestmentAtRoute} from "../../../offer/list/actions/fetch_offer_list_investment";
import {fetchOfferListMarkersAtRoute} from "../../../offer/list/actions/fetch_offer_list_markers";
import {fetchRecommendedLocationsAtRoute} from "../../../offer/list/actions/fetch_recommended_locations";
import {fetchOfferListSeoDescriptionAtRoute} from "../../../offer/list/actions/fetch_search_seo_description";
import {redirectConstraintsAtRoute} from "../../../offer/list/actions/redirect_constraints";
import {setViewModeForOfferDetail} from "../../../offer/list/actions/set_view_mode_for_offer_detail";
import {updateOfferListMetaData} from "../../../offer/list/actions/update_offer_list_meta_data";
import {fetchPageDetailAtRoute} from "../../../pages/actions/fetch_page_detail";
import {updatePageDetailMetaData} from "../../../pages/actions/update_page_detail_meta_data";
import {fetchFilterStatsAtRoute} from "../../../search/actions/fetch_filter_stats";
import {redirectSchemaWebsite} from "../../../search/actions/redirect_schema_website";
import {resetClientOnlySearchFormAtRoute} from "../../../search/actions/search_form_actions";
import {fetchSiteMapsAtRoute} from "../../../site_map/actions/fetchSiteMapsAtRoute";
import {updateSiteMapMetaData} from "../../../site_map/actions/update_sitemap_metadata";
import {
    setAgencyViewTypeAtRoute,
    setAgentViewTypeAtRoute,
    setArticleAuthorsViewTypeAtRoute,
    setArticleAuthorViewTypeAtRoute,
    setArticleDetailViewTypeAtRoute,
    setBlogArticleListViewTypeAtRoute,
    setDeveloperViewTypeAtRoute,
    setHomepageViewTypeAtRoute,
    setInvestmentDetailViewTypeAtRoute,
    setOfferDetailViewTypeAtRoute,
    setOfferListViewTypeAtRoute,
    setPageDetailViewTypeAtRoute
} from "../../../tracking/view_type/view_type_actions";
import {IStore} from "../../reducers/hybrid_reducer";
import {requireAuthenticationAtRoute} from "../../user/actions/is_authenticated_actions";
import {redirectAfterOauthLoginAtRoute} from "../../user/actions/redirect_after_oauth_login";
import {mapActions, reduceActions, strictMapActions} from "./action_helpers";
import {createAppPathDataFetcher, IRouteFetch} from "./create_app_path_data_fetcher";

const FETCH_ROUTES: IRouteFetch<IStore>[] = [
    {
        path: appPath.mainPage,
        exact: false,
        // matches all routes, triggers additional asynchronous promise chain
        fetch: reduceActions(fetchCustomMetaData),
        routes: [
            {
                path: appPath.mainPage,
                fetch: mapActions(setHomepageViewTypeAtRoute, resetClientOnlySearchFormAtRoute, updateHomepageMetaData)
            },
            {
                path: [appPath.siteMap.base, appPath.siteMap.detail],
                fetch: mapActions(fetchSiteMapsAtRoute, updateSiteMapMetaData)
            },
            {
                path: appPath.agent.detail,
                fetch: reduceActions(fetchAgentDetailAtRoute, mapActions(fetchAgentOffersAtRoute, updateAgentMetaData), setAgentViewTypeAtRoute)
            },
            {
                path: appPath.agency.detail,
                fetch: reduceActions(
                    fetchAgencyDetailAtRoute,
                    mapActions(fetchAgencyAgentsAtRoute, fetchAgencyOffersAtRoute, updateAgencyDetailMetaData, setAgencyViewTypeAtRoute)
                )
            },
            {
                path: appPath.agency.base,
                fetch: mapActions(fetchAgencyListAtRoute, fetchAgencyListSeoDescriptionAtRoute)
            },
            {
                path: appPath.agency.location,
                fetch: reduceActions(fetchLocationBySlugAtRoute, mapActions(fetchAgencyListAtRoute, fetchAgencyListSeoDescriptionAtRoute))
            },
            {
                path: appPath.agencyRegisterPremium,
                fetch: reduceActions(updateAgencyRegisterPremiumMetaData)
            },
            {
                path: appPath.agencyRegister,
                fetch: reduceActions(updateAgencyRegisterMetaData)
            },
            {
                path: appPath.forAgent,
                fetch: reduceActions(updateForAgentMetaData)
            },
            {
                path: appPath.developer.base,
                fetch: mapActions(fetchDevelopersListAtRoute, fetchDevelopersListSeoDescriptionAtRoute)
            },
            {
                path: appPath.developer.location,
                fetch: reduceActions(fetchLocationBySlugAtRoute, mapActions(fetchDevelopersListAtRoute, fetchDevelopersListSeoDescriptionAtRoute))
            },
            {
                path: appPath.developer.detail,
                fetch: reduceActions(
                    fetchDeveloperDetailAtRoute,
                    mapActions(fetchDeveloperOffersAtRoute, updateDeveloperDetailMetaData),
                    setDeveloperViewTypeAtRoute
                )
            },
            {
                // redirect old lots ie. /slug-123121l/ to standard detail path /slug-78445/
                path: appPath.offer.oldLotsDetail,
                fetch: reduceActions(redirectOldLotsAtRoute)
            },
            {
                path: appPath.offer.detail.base,
                fetch: reduceActions(
                    validateOfferDetailFetchAtRoute,
                    openOfferModalAtRoute,
                    strictMapActions(fetchOfferAtRoute, fetchOfferFeatures),
                    updateOfferMetaData,
                    setOfferDetailViewTypeAtRoute
                )
            },
            {
                path: appPath.investment.detail,
                fetch: reduceActions(
                    fetchInvestmentDetailAtRoute,
                    mapActions(fetchInvestmentPoiAtRoute, updateInvestmentDetailMetaData),
                    setInvestmentDetailViewTypeAtRoute
                )
            },
            {
                path: appPath.search,
                fetch: redirectSchemaWebsite
            },
            {
                path: [appPath.searchResult.universalTag, appPath.searchResult.universal],
                fetch: reduceActions(
                    closeOfferModalAtRoute,
                    fetchOfferListInvestmentAtRoute,
                    fetchLocationBySlugAtRoute,
                    redirectConstraintsAtRoute,
                    strictMapActions(
                        fetchOfferListAtRoute,
                        fetchOfferListSeoDescriptionAtRoute,
                        fetchLocationStatisticsAtRoute,
                        fetchRecommendedLocationsAtRoute,
                        fetchOfferListMarkersAtRoute,
                        fetchFilterStatsAtRoute,
                        resetClientOnlySearchFormAtRoute
                    ),
                    updateOfferListMetaData,
                    setOfferListViewTypeAtRoute,
                    setViewModeForOfferDetail
                ),
                exact: true
            },
            {
                path: appPath.article.author.base,
                fetch: reduceActions(fetchAuthorListAtRoute, updateAuthorListMetaData, setArticleAuthorsViewTypeAtRoute)
            },
            {
                path: appPath.article.author.detail,
                fetch: mapActions(
                    reduceActions(fetchAuthorDetailAtRoute, updateAuthorDetailMetaData, setArticleAuthorViewTypeAtRoute),
                    fetchAuthorArticlesAtRoute
                )
            },
            {
                path: appPath.article.base,
                fetch: mapActions(
                    reduceActions(fetchArticleListAtRoute, updateBlogMetaData),
                    fetchArticleCategoriesListAtRoute,
                    fetchDistrictArticleListAtRoute,
                    setBlogArticleListViewTypeAtRoute
                )
            },
            {
                path: appPath.article.detailOrCategoryList,
                fetch: reduceActions(
                    fetchArticleCategoriesListAtRoute,
                    selectArticlePathActions({
                        articleDetailActions: mapActions(
                            reduceActions(fetchArticleDetailAtRoute, updateArticleDetailMetaData, setArticleDetailViewTypeAtRoute),
                            fetchRelatedArticlesAtRoute
                        ),
                        articleCategoryActions: reduceActions(fetchArticleListInCategoryAtRoute, updateBlogCategoryMetaData, setBlogArticleListViewTypeAtRoute)
                    })
                )
            },
            {
                path: appPath.favourites,
                fetch: mapActions(updateFavouriteMetaData)
            },
            {
                path: appPath.aboutUs,
                fetch: updateAboutUsMetaData
            },
            {
                path: appPath.buylando,
                fetch: updateBuylandoMetaData
            },
            {
                path: appPath.oauth.redirect,
                fetch: redirectAfterOauthLoginAtRoute
            },
            {
                path: appPath.page.detail,
                fetch: reduceActions(fetchPageDetailAtRoute, updatePageDetailMetaData, setPageDetailViewTypeAtRoute)
            },
            {
                path: appPath.yourOffers,
                fetch: reduceActions(requireAuthenticationAtRoute, fetchIndividualOffersAtRoute)
            },
            {
                path: appPath.offer.verify,
                fetch: updateVerifyIndividualOfferMetaData
            },
            {
                path: appPath.offer.add,
                fetch: reduceActions(forceSessionSyncAtRoute, updateAddIndividualOfferMetaData)
            },
            {
                path: appPath.offer.edit,
                fetch: reduceActions(requireAuthenticationAtRoute, fetchindIvidualOfferDetailAtRoute)
            },
            {
                path: appPath.offer.edit,
                fetch: updateRenewIndividualOfferMetaData
            }
        ]
    }
];

export const appPathDataFetcher = createAppPathDataFetcher<IStore>(FETCH_ROUTES);
