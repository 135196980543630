import * as React from "react";
import {cx} from "@linaria/core";
import {isEmpty} from "lodash";
import {IFormFieldProps} from "@web2/form2";
import {CloseButtonIcon} from "@web2/icons";

import {ArrowDownIcon} from "../../../offer/list/components/icons/ArrowDownIcon";
import {ArrowUpIcon} from "../../../offer/list/components/icons/ArrowUpIcon";
import {OfferDealType} from "../../../offer/utils/constants_offer";
import {customFilterHolder, filterHolder, filterLabelText, mobileFilterHolder} from "../atoms/atoms";
import {ChildHolder} from "../atoms/ChildHolder";
import {FilterLabel} from "../atoms/FilterLabel";

export interface ICustomFilterChildProps<TValue> extends IFormFieldProps<string, TValue> {
    clearField: () => void;
    errorOnBottom?: boolean;
    setDropdownOpen: (isOpen: boolean) => void;
    id: string;
    dealType?: OfferDealType | [];
    offerType?: string;
}

interface IProps<TValue> extends IFormFieldProps<string, TValue> {
    errorOnBottom?: boolean;
    getLabel: (value: TValue, label: string) => string | JSX.Element;
    labelId?: string;
    id: string;
    label: string;
    children: (props: ICustomFilterChildProps<TValue>) => JSX.Element;
    onClearField?: () => void;
    isDropdownOpened: boolean;
    setDropdownOpen: (isOpen: boolean) => void;
    rightDropdown?: boolean;
    isMobileWidth: boolean;
    showOnMobile?: boolean;
    showLabelOnMobile?: boolean;
    dealType?: OfferDealType | [];
    offerType?: string;
    showClearIcon?: boolean;
    isCustomStyled?: boolean;
    onFilterCancel?: () => void;
    testId: string;
}

export class CustomFilter<T> extends React.PureComponent<IProps<T | []>> {
    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, []);
        this.props.onAfterChange(this.props.name, []);
    };

    private onClearFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.clearField();
        this.props.setDropdownOpen(false);
    };

    private openDropdown = () => {
        this.props.setDropdownOpen(true);
        if (this.props.onFilterCancel) {
            this.props.onFilterCancel();
        }
    };

    private closeDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (this.props.onFilterCancel) {
            this.props.onFilterCancel();
        }

        this.props.setDropdownOpen(false);
    };

    private onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            this.props.setDropdownOpen(false);
        }

        if (e.key === "Enter") {
            this.props.onChange(this.props.name, this.props.value);
            this.props.onAfterChange(this.props.name, this.props.value);
            this.props.setDropdownOpen(false);
        }
    };

    /**
     * Render
     */

    public render() {
        const {isDropdownOpened, value, error} = this.props;
        const hasError = !isEmpty(error) && "has-error";

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.props.onAfterChange,
                onChange: this.props.onChange,
                setDropdownOpen: () => this.props.setDropdownOpen(false),
                value: value,
                id: this.props.id,
                dealType: this.props.dealType,
                offerType: this.props.offerType
            });

        return (
            <div
                className={cx(this.props.showOnMobile ? mobileFilterHolder : this.props.isCustomStyled ? customFilterHolder : filterHolder)}
                onKeyDown={this.onKeyDownHandler}
            >
                {hasError && <div>error</div>}

                {this.props.label && (
                    <FilterLabel
                        data-testid={this.props.testId}
                        tabIndex={-1}
                        isActive={this.props.isDropdownOpened}
                        hasValues={!isEmpty(this.props.value)}
                        onClick={this.props.isDropdownOpened ? this.closeDropdown : this.openDropdown}
                        showOnMobile={this.props.showLabelOnMobile}
                    >
                        <span className={filterLabelText}>{this.props.getLabel(value, this.props.label)}</span>

                        {this.props.showClearIcon && !isEmpty(value) ? (
                            <div onClick={(e) => this.onClearFieldClick(e)}>
                                <CloseButtonIcon mainColor="#9069c0" width={9} height={9} />
                            </div>
                        ) : this.props.isDropdownOpened ? (
                            <ArrowUpIcon size="1.2" fillColor="#9069c0" />
                        ) : (
                            <ArrowDownIcon size="1.2" fillColor="#9069c0" />
                        )}
                    </FilterLabel>
                )}

                {(isDropdownOpened || this.props.isMobileWidth) && (
                    <ChildHolder bottomDropdown={this.props.showLabelOnMobile && this.props.label === "Dostępne od"} rightDropdown={this.props.rightDropdown}>
                        {renderEnchancedChild()}
                    </ChildHolder>
                )}
            </div>
        );
    }
}
