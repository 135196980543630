import {hitGoogleTagManager} from "./gtm_init";

type INavLevel = "1" | "2" | "3" | "4";

export const gtmNavigationMenuClick = (navLevel: INavLevel, label: string) => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "navigation",
        GAEventAction: `top_menu_${navLevel}`,
        GAEventLabel: label
    };
    hitGoogleTagManager(gtmData);
};
