import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {useTransitioningHistoryPush} from "./use_transitioning_history_push";

const useHash = (hashName: string) => {
    const location = useLocation();
    const [isHashPresent, setIsHashPresent] = useState(false);
    const {transitionHistory} = useTransitioningHistoryPush();

    useEffect(() => {
        const hash = location.hash.substring(1);
        setIsHashPresent(hash === hashName);
    }, [location.hash, hashName]);

    const setHash = (hashValue: string | null) => {
        if (hashValue) {
            transitionHistory(location.pathname + location.search + `#${hashName}`);
        } else {
            transitionHistory(location.pathname + location.search);
        }
    };

    return {isHashPresent, setHash};
};

export default useHash;
