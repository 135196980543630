import {IFeaturesTypes, SearchFeaturesApartment, SearchFeaturesHouse, SearchFeaturesLots} from "../../../app/interfaces/response/server_list_response";
import {IQueryParams} from "../../../app/utils/append_query_string";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {DealTypeSlug, OfferMarketType, OfferType, UDealTypesPL} from "../../utils/constants_offer";

export enum UrlTagSlug {
    STUDIO = "kawalerki",
    TWO_ROOM = "dwupokojowe",
    THREE_ROOM = "trzypokojowe",
    FOUR_ROOM = "czteropokojowe",
    FIVE_ROOM = "pieciopokojowe",
    TERRACES = "taras",
    INDIVIDUAL_OFFERS = "bez-posrednikow",
    PRIMARY_MARKET = "rynek-pierwotny",

    // apartments features
    GARDEN = "ogrodek",
    BALCONIES = "balkon",
    LAST_FLOOR = "ostatnie-pietro",
    RENOVATION = "do-remontu",
    INHABITANCY = "do-zamieszkania",
    // houses features
    DETACHED = "wolnostojace",
    TERRACED = "szeregowe",
    SEMI_DETACHED = "blizniak",
    GARAGE = "garaz",
    ATTIC = "poddasze-uzytkowe",

    // lots features
    BUILDING = "budowlana",
    RECREATION = "rekreacyjna",
    LAKE = "jezioro",
    SEA = "morze",
    MOUNTAINS = "gory",
    UTILITIES = "uzbrojona",
    HOUSE = "domek-letniskowy",
    RIVER = "rzeka"
}

export type ISearchPageUrlTag = {
    tag: UrlTagSlug;
    params?: IQueryParams;
    featureName?: IFeaturesTypes;
    metaText?: string;
    breadcrumbText: string;

    // params used to validate tags:
    marketType?: OfferMarketType; // undefined = tag not limited to market type
    offerType?: OfferType[]; // undefined = tag not limited to offer type
    dealType?: UDealTypesPL; // undefined = not limited to deal type
    locationTypes?: LocationTypes[]; // undefined = not limited to location type
};

/**
 * url builder will take these params into account when testing validity
 */
export const TAG_PARAMS_TO_WATCH = ["rooms", "price__lte", "individual_offer", "is_private", "market"];

/**
 * Rules below are used to validate offer URLs and when invalidated, will cause 301 redirects to other URLs.
 * Incorrect handling may result in mess that is hard to clean up.
 */
export const searchPageUrlTags: Record<string, ISearchPageUrlTag> = {
    STUDIO: {
        tag: UrlTagSlug.STUDIO,
        params: {
            rooms: "1"
        },
        offerType: [OfferType.apartment],
        breadcrumbText: UrlTagSlug.STUDIO
    },
    TWO_ROOM: {
        tag: UrlTagSlug.TWO_ROOM,
        params: {
            rooms: "2"
        },
        metaText: UrlTagSlug.TWO_ROOM,
        offerType: [OfferType.apartment],
        breadcrumbText: UrlTagSlug.TWO_ROOM
    },
    THREE_ROOM: {
        tag: UrlTagSlug.THREE_ROOM,
        params: {
            rooms: "3"
        },
        metaText: UrlTagSlug.THREE_ROOM,
        offerType: [OfferType.apartment],
        breadcrumbText: UrlTagSlug.THREE_ROOM,
        locationTypes: [LocationTypes.CITY]
    },
    FOUR_ROOM: {
        tag: UrlTagSlug.FOUR_ROOM,
        params: {
            rooms: "4"
        },
        metaText: UrlTagSlug.FOUR_ROOM,
        offerType: [OfferType.apartment],
        breadcrumbText: UrlTagSlug.FOUR_ROOM,
        locationTypes: [LocationTypes.CITY]
    },
    FIVE_ROOM: {
        tag: UrlTagSlug.FIVE_ROOM,
        params: {
            rooms: "5"
        },
        metaText: UrlTagSlug.FIVE_ROOM,
        offerType: [OfferType.apartment],
        breadcrumbText: "pięciopokojowe",
        locationTypes: [LocationTypes.CITY]
    },
    TERRACES: {
        tag: UrlTagSlug.TERRACES,
        featureName: SearchFeaturesApartment.TERRACES,
        offerType: [OfferType.apartment, OfferType.house],
        metaText: "z tarasem",
        breadcrumbText: "z tarasem"
    },
    INDIVIDUAL_OFFERS: {
        tag: UrlTagSlug.INDIVIDUAL_OFFERS,
        params: {
            is_private: true
        },
        locationTypes: [LocationTypes.CITY],
        offerType: [OfferType.apartment, OfferType.house, OfferType.lot],
        breadcrumbText: "bez pośredników"
    },

    PRIMARY_MARKET: {
        tag: UrlTagSlug.PRIMARY_MARKET,
        params: {
            market: OfferMarketType.PRIMARY_MARKET
        },
        dealType: DealTypeSlug.SELL,
        locationTypes: [LocationTypes.CITY],
        offerType: [OfferType.apartment, OfferType.house],
        breadcrumbText: "rynek pierwotny"
    },

    // apartments features
    GARDEN: {
        tag: UrlTagSlug.GARDEN,
        featureName: SearchFeaturesApartment.GARDEN,
        offerType: [OfferType.apartment],
        metaText: "z ogródkiem",
        breadcrumbText: "ogródek"
    },
    BALCONIES: {
        tag: UrlTagSlug.BALCONIES,
        featureName: SearchFeaturesApartment.BALCONIES,
        offerType: [OfferType.apartment],
        metaText: "z balkonem",
        breadcrumbText: "balkon"
    },
    LAST_FLOOR: {
        tag: UrlTagSlug.LAST_FLOOR,
        featureName: SearchFeaturesApartment.LAST_FLOOR,
        offerType: [OfferType.apartment],
        metaText: "na ostatnim piętrze",
        breadcrumbText: "ostatnie piętro"
    },
    RENOVATION: {
        tag: UrlTagSlug.RENOVATION,
        featureName: SearchFeaturesApartment.RENOVATION,
        offerType: [OfferType.apartment],
        metaText: "do remontu",
        breadcrumbText: "do remontu"
    },
    INHABITANCY: {
        tag: UrlTagSlug.INHABITANCY,
        featureName: SearchFeaturesApartment.INHABITANCY,
        offerType: [OfferType.apartment],
        metaText: "do zamieszkania",
        breadcrumbText: "do zamieszkania"
    },

    // houses features
    DETACHED: {
        tag: UrlTagSlug.DETACHED,
        featureName: SearchFeaturesHouse.DETACHED,
        offerType: [OfferType.house],
        metaText: "wolnostojące",
        breadcrumbText: "wolnostojący"
    },
    TERRACED: {
        tag: UrlTagSlug.TERRACED,
        featureName: SearchFeaturesHouse.TERRACED,
        offerType: [OfferType.house],
        metaText: "szeregowe",
        breadcrumbText: "szeregowy"
    },
    SEMI_DETACHED: {
        tag: UrlTagSlug.SEMI_DETACHED,
        featureName: SearchFeaturesHouse.SEMI_DETACHED,
        offerType: [OfferType.house],
        metaText: "w zabudowie bliźniaczej",
        breadcrumbText: "bliźniak"
    },
    GARAGE: {
        tag: UrlTagSlug.GARAGE,
        featureName: SearchFeaturesHouse.GARAGE,
        offerType: [OfferType.house],
        metaText: "z garażem",
        breadcrumbText: "z garażem"
    },
    ATTIC: {
        tag: UrlTagSlug.ATTIC,
        featureName: SearchFeaturesHouse.ATTIC,
        offerType: [OfferType.house],
        metaText: "z poddaszem użytkowym",
        breadcrumbText: "z poddaszem użytkowym"
    },

    // lots features
    BUILDING: {
        tag: UrlTagSlug.BUILDING,
        featureName: SearchFeaturesLots.BUILDING,
        offerType: [OfferType.lot],
        metaText: "budowlane",
        breadcrumbText: "budowlana"
    },
    RECREATION: {
        tag: UrlTagSlug.RECREATION,
        featureName: SearchFeaturesLots.RECREATION,
        offerType: [OfferType.lot],
        metaText: "rekreacyjne",
        breadcrumbText: "rekreacyjna"
    },
    LAKE: {
        tag: UrlTagSlug.LAKE,
        featureName: SearchFeaturesLots.LAKE,
        offerType: [OfferType.lot],
        metaText: "nad jeziorem",
        breadcrumbText: "nad jeziorem"
    },
    SEA: {
        tag: UrlTagSlug.SEA,
        featureName: SearchFeaturesLots.SEA,
        offerType: [OfferType.lot],
        metaText: "nad morzem",
        breadcrumbText: "nad morzem"
    },
    MOUNTAINS: {
        tag: UrlTagSlug.MOUNTAINS,
        featureName: SearchFeaturesLots.MOUNTAINS,
        offerType: [OfferType.lot],
        metaText: "w górach",
        breadcrumbText: "w górach"
    },
    UTILITIES: {
        tag: UrlTagSlug.UTILITIES,
        featureName: SearchFeaturesLots.UTILITIES,
        offerType: [OfferType.lot],
        metaText: "uzbrojone",
        breadcrumbText: "uzbrojona"
    },
    HOUSE: {
        tag: UrlTagSlug.HOUSE,
        featureName: SearchFeaturesLots.HOUSE,
        offerType: [OfferType.lot],
        metaText: "z domkiem",
        breadcrumbText: "z domkiem"
    },
    RIVER: {
        tag: UrlTagSlug.RIVER,
        featureName: SearchFeaturesLots.RIVER,
        offerType: [OfferType.lot],
        metaText: "nad rzeką",
        breadcrumbText: "nad rzeką"
    }
};
