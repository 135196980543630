import * as React from "react";
import {css} from "@linaria/core";

import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IProps {
    title: string;
    text: string;
}

export const ResultsInfo = (props: IProps) => {
    return (
        <div className={infoHolder}>
            <p>{props.title}</p>

            <p className={infoText}>{props.text}</p>
        </div>
    );
};

//Info
const infoHolder = css`
    padding: 10px 20px;
    background: ${getThemeVariable("colors-gray_warmer")};
    font-size: ${getThemeVariable("fonts-font_size_small")};
    font-weight: 600;

    p {
        margin: 0;
    }

    p:first-of-type {
        margin: 0 0 5px 0;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        padding: 20px 30px;
        font-size: ${getThemeVariable("fonts-font_size_big")};
    }
`;

const infoText = css`
    font-weight: 400;
    font-size: ${getThemeVariable("fonts-font_size_root")};

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        font-size: ${getThemeVariable("fonts-font_size_small")};
    }
`;
