import {isEqual} from "lodash";

import {ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputValue} from "./ISearchInputValue";

export const getSearchLocation = (offerListLocation: ILocation | null, searchValue: ISearchInputValue) => {
    const [location, subLocation, subSubLocation] = offerListLocation ? offerListLocation.slug.split("/") : [undefined, undefined, undefined];

    if (searchValue.tabType === SearchTab.SearchSuggestions && searchValue.suggestions.suggest_type === "location") {
        const [formLocation, formSubLocation, formSubSubLocation] = searchValue.suggestions.slug.split("/");
        if (
            !isEqual(
                {location, subLocation, subSubLocation},
                {
                    location: formLocation,
                    subLocation: formSubLocation,
                    subSubLocation: formSubSubLocation
                }
            )
        ) {
            return {location: formLocation, subLocation: formSubLocation, subSubLocation: formSubSubLocation};
        }
    }
    return {location, subLocation, subSubLocation};
};
