import {ApplicationFormFields} from "@web2/form";
import {getIsMobile} from "@web2/react_utils";

import {toJsonValues} from "../../app/utils/form/to_json_values";
import {agencyDeveloperAblative, VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {
    AlgolyticsSourceSection,
    applicationSentAlgolyticsHit,
    IAlgolyticsApplicationSentData
} from "../../tracking/algolytics/interaction/application_sent_hit";
import {applicationSuccessGTMEvent} from "../../tracking/google_tag_manager/applicationSuccessEvents";
import {IGtmOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {IEventWithOfferProps, IEventWithoutOfferProps} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_with_offer";
import {GtmInquiryOverbudget, gtmInquiryWithOffer, gtmInquiryWithoutOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_inquiry";
import {GtmContactType} from "../../tracking/google_tag_manager/utils/gtm_contact_type";
import {IApplicationFields} from "../actions/application_v2_post";
import {UserData} from "../components/Application";
import {ApplicationSource, ApplicationSourceSection} from "./ApplicationSource";

export type TrackApplicationPayload = {
    applicationFields: IApplicationFields;
    formTitle?: string;
    isModalOfferOnOfferList?: boolean;
    source: ApplicationSource | null;
    source_section: ApplicationSourceSection;
    algolyticsSourceSection: AlgolyticsSourceSection | null;
} & (PropsWithOffer | PropsWithoutOffer);

interface PropsWithOffer {
    offer: IApplicationAlgolyticsOffer & IGtmOffer;
    gtmInquiryData: Omit<IEventWithOfferProps, "offer">;
}

interface PropsWithoutOffer {
    offer?: never;
    gtmInquiryData: IEventWithoutOfferProps;
}

export const trackApplicationPost = (props: TrackApplicationPayload) => {
    // algolytics event
    const algolyticsProps: IApplicationAlgolyticsHitProps = {
        off: props.offer,
        formTitle: props.formTitle,
        isModalOfferOnOfferList: props.isModalOfferOnOfferList
    };
    applicationAlgolyticsHit(
        {
            ...props.applicationFields,
            source: props.source,
            source_section: props.source_section,
            algolyticsSourceSection: props.algolyticsSourceSection
        },
        algolyticsProps
    );

    /*
     * GTM events for application with offers
     */
    if (props.offer) {
        gtmInquiryWithOffer({
            offer: props.offer,
            contactType: GtmContactType.FORM,
            offer_status: props.offer?.overbudget ? GtmInquiryOverbudget.TRUE : GtmInquiryOverbudget.FALSE,
            gtmSource: props.gtmInquiryData.gtmSource,
            viewType: props.gtmInquiryData.viewType
        });

        applicationSuccessGTMEvent({
            agency: props.offer.agency?.id,
            agent: props.offer.agent?.id,
            offer: props.offer.id,
            investment: props.offer.investment?.id
        });
    }

    /*
     * GTM events for other applications - agent, agency, developer, investment
     */
    if (!props.offer) {
        gtmInquiryWithoutOffer({
            contactType: GtmContactType.FORM,
            gtmSource: props.gtmInquiryData.gtmSource,
            viewType: props.gtmInquiryData.viewType,
            agent: props.gtmInquiryData.agent,
            agency: props.gtmInquiryData.agency
        });

        applicationSuccessGTMEvent({
            agency: props.gtmInquiryData.agency?.id,
            agent: props.gtmInquiryData.agent?.id,
            investment: props.gtmInquiryData.investment?.id
        });
    }
};

export interface IApplicationAlgolyticsOffer {
    id: string;
    agent?: {
        id: string;
        last_name?: string;
        name: string;
    } | null;
    agency?: {
        id: string;
        name: string;
        type?: VendorType;
    } | null;
    investment?: {
        id: string;
        name: string;
        developer?: {
            id?: string;
        };
    } | null;
}

export interface IApplicationAlgolyticsHitProps {
    formTitle?: string;
    isModalOfferOnOfferList?: boolean;
    off?: IApplicationAlgolyticsOffer;
}

const applicationAlgolyticsHit = (formValues: UserData, props: IApplicationAlgolyticsHitProps) => {
    const isMobile = getIsMobile();

    if (formValues) {
        const jsonValues = toJsonValues(ApplicationFormFields, formValues);
        const source =
            props.formTitle ||
            jsonValues["source"] ||
            (props.off && props.off.agent && props.off.agency && `Kontakt z ${agencyDeveloperAblative(VendorType.AGENT)}`) ||
            (props.off && props.off.agency?.type && props.off.investment && `Kontakt z ${agencyDeveloperAblative(props.off.agency.type)}`) ||
            "";
        const data: IAlgolyticsApplicationSentData = {
            isModalOfferOnOfferList: props.isModalOfferOnOfferList,
            agent:
                props.off && props.off.agent
                    ? {
                          id: props.off.agent.id,
                          last_name: props.off.agent.last_name,
                          name: props.off.agent.name
                      }
                    : undefined,
            contact: {
                id: props.off && props.off.agency ? props.off.agency.id : "",
                name: props.off && props.off.agency ? props.off.agency.name : ""
            },
            developer: props.off && props.off.investment ? props.off.investment.developer?.id : undefined,
            financing_services: jsonValues["financing_services"],
            investment:
                props.off && props.off.investment
                    ? {
                          name: props.off.investment.name,
                          id: props.off.investment.id
                      }
                    : null,
            isMobile: isMobile,
            new_offers_consent: jsonValues["new_offers_consent"],
            offer: props.off && props.off.id ? {id: props.off.id} : undefined,
            source,
            source_section: jsonValues["source_section"] || null,
            user_email: jsonValues["email"],
            user_name: jsonValues["full_name"],
            user_phone: jsonValues["phone"],
            user_text: jsonValues["text"]
        };
        window && applicationSentAlgolyticsHit(data, window.location.pathname);
    }
};
