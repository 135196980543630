import {combineReducers, Reducer} from "redux";

import {IOfferDetailResponse} from "../../../app/interfaces/response/offer_detail";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {reduceRequestState, RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../../../app/utils/request_response_utils/factories/reduce_response";
import {ILocation} from "../../list/actions/fetch_location_by_slug_at_route";
import {setOfferDetailViewTypes} from "../../list/actions/set_view_mode_for_offer_detail";
import {reduceViewType} from "../../list/reducers/reduce_view_type";
import {IOfferDetailViewType} from "../../views/OfferListDetailSwitch";
import {fetchOfferTypes} from "../actions/fetch_offer_detail_at_route";
import {fetchOfferFeaturesTypes, IOfferFeatures} from "../actions/fetch_offer_features";
import {IInvestmentOffersStore, investmentOffersReducer} from "./investment_offers_reducer";

export const fetchOfferLocationDetailTypes = createRequestActionTypes({view: "OfferDetail", type: "GET", name: "locationDetail"});

export interface IOfferStore {
    offer: IOfferDetailResponse;
    locationDetail: ILocation | null;
    locationDetailState: RequestState;
    offerRequestState: RequestState;
    offerDetailViewType: IOfferDetailViewType | null;
    offerFeatures: IOfferFeatures[];
}

const offerReducerObj: Record<keyof IOfferStore, Reducer<any, any>> = {
    offer: reduceResponse(fetchOfferTypes),
    locationDetail: reduceResponse(fetchOfferLocationDetailTypes),
    locationDetailState: reduceRequestState(fetchOfferLocationDetailTypes),
    offerRequestState: reduceRequestState(fetchOfferTypes),
    offerDetailViewType: reduceViewType(setOfferDetailViewTypes),
    offerFeatures: reduceResponse(fetchOfferFeaturesTypes)
};

export const offerReducer = combineReducers(offerReducerObj);
