import * as React from "react";
import {ReactNode} from "react";
import {map} from "lodash";

import {DropdownListElement} from "./DropdownListElement";
import {INavElement} from "./Navigation";
import {NavigationElement} from "./NavigationElement";

interface IProps {
    navElements: INavElement[];
    arrows: {
        left: ReactNode;
        right: ReactNode;
    };
    setNavState: (update: (prev: number[]) => number[]) => void;
    navState: number[];
    isRightElement?: boolean;
    disabled?: boolean;
    titleDealTypeSuffix?: string;
}

export const NavigationElementWithDropdown = (props: IProps) => {
    return (
        <>
            {map(props.navElements, (element, itemIndex) => {
                if (element.subElements) {
                    return (
                        <DropdownListElement
                            setNavState={props.setNavState}
                            navState={props.navState}
                            dropdownElements={element.subElements}
                            key={itemIndex + `${element.url ? element.url : "empty-url"}`}
                            title={element.title}
                            url={element.url}
                            itemIndex={itemIndex}
                            arrow={props.arrows.right}
                            icon={element.icon}
                            isRightElement={props.isRightElement}
                            isActive={element.isActive}
                            titleDealTypeSuffix={element.titleDealTypeSuffix}
                            className={element.className}
                        />
                    );
                }

                return (
                    <NavigationElement
                        title={element.title}
                        key={itemIndex}
                        icon={element.icon}
                        url={element.url}
                        onClick={element.onClick}
                        rel={element.rel}
                        favouritesCount={element.favouritesCount}
                        target={element.target}
                        isActive={element.isActive}
                        disabled={element.disabled}
                        isButton={element.isButton}
                        className={element.className}
                    />
                );
            })}
        </>
    );
};
