import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {useMap} from "@web2/react_utils";

import {ILocationStatistics} from "../../../../app/interfaces/response/location_statistics";
import {IStore} from "../../../../app/reducers/hybrid_reducer";
import {IStatisticsGenerics} from "../interfaces/interfaces_generic_listings";

export const useGenericListingDescriptionParams = (): IStatisticsGenerics => {
    const {statistics} = useSelector((store: IStore) => store.offerList.location);
    const {latestQuery} = useSelector((store: IStore) => store.offerList);
    const countryOfferCountTotal = useSelector((store: IStore) => store.offerList.offers.meta?.total_offers) || 0;
    const featuresStats = useSelector((store: IStore) => store.offerList.offers.meta?.features) || [];
    const filterStats = useSelector((store: IStore) => store.search.filterStats);

    let flatsAvgPricePerSqmMultiRooms = 0;
    for (const key in filterStats.avg_price_per_sqm_per_rooms) {
        const avgPrice = filterStats.avg_price_per_sqm_per_rooms[key as "2" | "3" | "4"] || 0;
        if (key !== "1") {
            flatsAvgPricePerSqmMultiRooms += avgPrice;
        }
    }
    flatsAvgPricePerSqmMultiRooms = flatsAvgPricePerSqmMultiRooms / Object.keys(filterStats.avg_price_per_sqm_per_rooms).length - 1;

    const getFeatureObject = useMap(featuresStats, "feature");

    const flatsWithGardenCount = getFeatureObject.garden && getFeatureObject.garden.doc_count;
    const flatsWithBalconyCount = getFeatureObject.balconies && getFeatureObject.balconies.doc_count;
    const flatsWithTeraceCount = getFeatureObject.terraces && getFeatureObject.terraces.doc_count;

    const housesWithGarageCount = getFeatureObject.garage && getFeatureObject.garage.doc_count;
    const housesWithAttic = getFeatureObject.attic && getFeatureObject.attic.doc_count;
    const housesWithTeraceCount = getFeatureObject.terraces && getFeatureObject.terraces.doc_count;
    const terracedHousesCount = getFeatureObject.terraced && getFeatureObject.terraced.doc_count;
    const semidetachedHousesCount = getFeatureObject.semi_detached && getFeatureObject.semi_detached.doc_count;
    const detachedHousesCount = getFeatureObject.detached && getFeatureObject.detached.doc_count;

    const statisticsLocation = statistics && !isEmpty(statistics) ? (statistics as ILocationStatistics) : null;

    return {
        statisticsLocation,
        countryOfferCountTotal,
        flatsWithGardenCount,
        flatsWithBalconyCount,
        flatsWithTeraceCount,

        flatsAvgPricePerSqmStudio: filterStats.avg_price_per_sqm_per_rooms["1"] || 0,
        flatsAvgPricePerSqmMultiRooms: flatsAvgPricePerSqmMultiRooms || 0,

        housesWithGarageCount,
        housesWithAttic,
        housesWithTeraceCount,
        terracedHousesCount,
        semidetachedHousesCount,
        detachedHousesCount,

        avgPricePerSqmPerRooms: filterStats.avg_price_per_sqm_per_rooms,
        minSize: filterStats.size.lower_bound,
        maxSize: filterStats.size.upper_bound,
        avgPrice: filterStats ? filterStats.avg_price : 0,
        avgPricePerSqm: filterStats ? filterStats.avg_price_per_sqm : 0,

        latestQuery,
        dealType: latestQuery.deal_type
    };
};
