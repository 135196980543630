import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const HealthIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M4.25,8.28h0A.25.25,0,0,1,4,8.06L3.21,4.59l-.9,2a.25.25,0,0,1-.26.15H.34a.3.3,0,0,1-.3-.3.27.27,0,0,1,.3-.3H1.9L3,3.62a.28.28,0,0,1,.38-.15c.07,0,.15.11.15.19l.78,3.47L5,5.71a.25.25,0,0,1,.26-.15H6.75a.29.29,0,0,1,.3.3.28.28,0,0,1-.3.3H5.45l-.93,2A.31.31,0,0,1,4.25,8.28Z"
                fill={props.secondaryFillColor}
            />

            <path d="M5.63,8.84a.94.94,0,0,1-.56-.18L5,8.58a.28.28,0,0,1-.07-.37h0a.29.29,0,0,1,.37-.08l.11.08a.34.34,0,0,0,.41,0A28.45,28.45,0,0,0,8.06,6.31,4.68,4.68,0,0,0,9.07,5a1.55,1.55,0,0,0,.29-.89,2.51,2.51,0,0,0-.89-1.91A1.74,1.74,0,0,0,7.2,1.72a2,2,0,0,0-1.34.52l-.19.19-.19-.19a2.21,2.21,0,0,0-1.71-.52,2.24,2.24,0,0,0-1.46,1,2.24,2.24,0,0,0-.26,2.05.28.28,0,0,1-.15.38A.27.27,0,0,1,1.53,5h0a2.77,2.77,0,0,1,.3-2.58,2.62,2.62,0,0,1,3.84-.78A2.42,2.42,0,0,1,7.2,1.16a2.4,2.4,0,0,1,1.64.59A3,3,0,0,1,10,4.07a2.32,2.32,0,0,1-.33,1.19A7.37,7.37,0,0,1,8.51,6.72,13.92,13.92,0,0,1,6.27,8.58.87.87,0,0,1,5.63,8.84Z" />
        </SvgIcon>
    );
};
