import * as React from "react";
import {styled} from "@linaria/react";
import {TRANSACTION_TYPE_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";

import {OfferDealType, OfferType} from "../../../offer/utils/constants_offer";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {parseFormOfferTypeToSlug} from "../../utils/parse_offer_type_to_form_values";
import {TypeSearchFilterOptions} from "../../utils/TypeSearchFilterOptions";
import {dropdownList, dropdownListElement} from "../atoms/atoms";
import {DropdownListElementBody} from "../atoms/DropdownListElementBody";

interface IOwnProps {
    error?: string[];
    name: string;
    id: string;
    onAfterChange: (fieldName: string, value: OfferDealType | undefined | []) => void;
    onChange: (fieldName: string, value: OfferDealType | undefined | []) => void;
    value: OfferDealType | undefined | [];
    setDropdownOpen?: (isOpen: boolean) => void;
    offerType?: string;
    isMobile?: boolean;
}

export const DealTypeSelect = (props: IOwnProps) => {
    const onDealTypeSelect = (dealType: OfferDealType | undefined) => {
        props.setDropdownOpen && props.setDropdownOpen(false);
        if (props.isMobile) {
            return props.onChange(props.name, dealType);
        }
        props.onChange(props.name, dealType);
        return props.onAfterChange(props.name, dealType);
    };

    const offerType = props.offerType && parseFormOfferTypeToSlug(props.offerType?.split(",") as TypeSearchFilterOptions[]);
    const showAllDeals = offerType == OfferType.lot || offerType === OfferType.property;

    return (
        <List dealType={props.value as OfferDealType | undefined} className={dropdownList}>
            <li data-testid={TRANSACTION_TYPE_TESTID.FOR_SALE} className={dropdownListElement}>
                <DropdownListElementBody isActive={props.value === OfferDealType.SELL} onClick={() => onDealTypeSelect(OfferDealType.SELL)}>
                    Na sprzedaż
                </DropdownListElementBody>
            </li>

            <li data-testid={TRANSACTION_TYPE_TESTID.FOR_RENT} className={dropdownListElement}>
                <DropdownListElementBody isActive={props.value === OfferDealType.RENT} onClick={() => onDealTypeSelect(OfferDealType.RENT)}>
                    Do wynajęcia
                </DropdownListElementBody>
            </li>

            {showAllDeals && (
                <li className={dropdownListElement}>
                    <DropdownListElementBody isActive={!props.value} onClick={() => onDealTypeSelect(undefined)}>
                        Wszystkie
                    </DropdownListElementBody>
                </li>
            )}
        </List>
    );
};

const List = styled.ul<{dealType: OfferDealType | undefined}>`
    @media (max-width: calc(${getThemeBreakpointCorrect().screen_md} - 1px)) {
        padding: 0;
        display: flex;
        flex-direction: row;
        font-size: 12px;

        li {
            flex-grow: 1;
            flex-shrink: 1;
        }

        li:hover {
            background-color: unset;
        }

        li > div {
            border: 1px solid ${getThemeVariable("colors-gray_brighter")};
        }

        li:first-of-type > div {
            border-top-left-radius: ${getThemeVariable("other-border_radius")};
            border-bottom-left-radius: ${getThemeVariable("other-border_radius")};
            border: ${(props) =>
                props.dealType === OfferDealType.SELL
                    ? `1px solid ${getThemeVariable("colors-brand_primary")}`
                    : `1px solid ${getThemeVariable("colors-gray_brighter")}`};
            border-right: ${(props) => (props.dealType === OfferDealType.RENT ? "none" : `1px solid ${getThemeVariable("colors-brand_primary")}`)};
        }

        li:last-of-type > div {
            border-top-right-radius: ${getThemeVariable("other-border_radius")};
            border-bottom-right-radius: ${getThemeVariable("other-border_radius")};
            border-left: ${(props) => (props.dealType === OfferDealType.RENT ? `1px solid ${getThemeVariable("colors-brand_primary")}` : "none")};
            border-color: ${(props) =>
                props.dealType === OfferDealType.RENT ? getThemeVariable("colors-brand_primary") : getThemeVariable("colors-gray_brighter")};
        }
    }
`;
