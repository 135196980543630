import {combineReducers, Reducer} from "redux";

import {IAgent} from "../../agent/actions/fetch_agent_detail_at_route";
import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchAgencyAgentsTypes} from "../actions/fetch_agency_agents_at_route";
import {fetchAgencyDetailTypes, IAgency} from "../actions/fetch_agency_detail_at_route";
import {fetchAgencyOffersTypes} from "../actions/fetch_agency_offers_at_route";
import {fetchDeveloperDetailTypes, IDeveloper} from "../actions/fetch_developer_at_route";
import {fetchDeveloperOffersTypes, IAgencyOffer} from "../actions/fetch_developer_offers_at_route";

export interface IAgencyStore {
    agency: IAgency;
    agencyOffers: IAgencyOffer[];
    agencyAgents: IAgent[];
    developer: IDeveloper;
    developerOffers: IAgencyOffer[];
}

const agencyReducerObj: Record<keyof IAgencyStore, Reducer<any, any>> = {
    agency: reduceResponse(fetchAgencyDetailTypes),
    agencyAgents: reduceResponse(fetchAgencyAgentsTypes),
    agencyOffers: reduceResponse(fetchAgencyOffersTypes),
    developer: reduceResponse(fetchDeveloperDetailTypes),
    developerOffers: reduceResponse(fetchDeveloperOffersTypes)
};

export const agencyReducer = combineReducers(agencyReducerObj);
