import {CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM, RESET_ACTIVE_DROPDOWN_ITEM, SET_ACTIVE_DROPDOWN_ITEM} from "../actions/set_active_dropdown_item";

interface IAction {
    type: string;
}

export interface IActiveDropdownItemStore {
    id: number | null;
    label: string;
}

export interface IActiveItemAction extends IAction {
    item: {id: number; label: string};
}

const initialState = {label: "", id: null};
export const activeDropdownItemReducer = (state: IActiveDropdownItemStore = initialState, action: IActiveItemAction): IActiveDropdownItemStore => {
    switch (action.type) {
        case SET_ACTIVE_DROPDOWN_ITEM:
            return action.item;
        case RESET_ACTIVE_DROPDOWN_ITEM:
            return initialState;
        case CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM:
            return {...state, label: ""};
        default:
            return state;
    }
};
