import {numberFormat, pluralize} from "@web2/string_utils";

import {flatsDeclinations, housesDeclinations} from "../../dictionaries/declinations";
import {IGenericListingParagraphWithStats} from "../../interfaces/interfaces_generic_listings";
import {FeaturesFlats} from "./FeaturesFlats";

const returnCommonStringForLiHouses = (dataCountField: number) => {
    return `${numberFormat(dataCountField)} ${pluralize(housesDeclinations)(dataCountField)}`;
};

export const StatisticsByFeatures = (props: IGenericListingParagraphWithStats) => {
    const {propertyType, dealType, statistics, dealTypePhrase, isSell, locationDeclination} = props;
    const {
        flatsWithGardenCount,
        flatsWithBalconyCount,
        flatsWithTeraceCount

        // For next stages implementation

        // detachedHousesCount,
        // terracedHousesCount,
        // semidetachedHousesCount,
        // housesWithTeraceCount,
        // housesWithGarageCount,
        // housesWithAttic
    } = statistics;

    const returnCommonStringForLiFlats = (dataCountField: number) => {
        return `${numberFormat(dataCountField)} ${pluralize(flatsDeclinations)(dataCountField)} ${dealTypePhrase}`;
    };

    const li = {
        flatsWithGarden: `${returnCommonStringForLiFlats(flatsWithGardenCount)} z ogródkiem`,
        flatsWithBalcony: `${returnCommonStringForLiFlats(flatsWithBalconyCount)} z balkonem`,
        flatsWithTerace: `${returnCommonStringForLiFlats(flatsWithTeraceCount)} z tarasem`

        // For next stages implementation

        // housesDetached: `${returnCommonStringForLiHouses(detachedHousesCount)} wolnostojących`,
        // housesTerraced: `${returnCommonStringForLiHouses(terracedHousesCount)} szeregowych`,
        // housesSemidetached: `${returnCommonStringForLiHouses(semidetachedHousesCount)} typu bliźniak`,
        // housesWithTerace: `${returnCommonStringForLiHouses(housesWithTeraceCount)} z tarasem`,
        // housesWithGarage: `${returnCommonStringForLiHouses(housesWithGarageCount)} z garażem`,
        // housesWithAttic: `${returnCommonStringForLiHouses(housesWithAttic)} z poddaszem użytkowym`
    };

    const variantSell = "chcesz kupić mieszkanie";
    const variantRent = `interesują Cię mieszkania ${dealTypePhrase}`;
    const variant = isSell ? variantSell : variantRent;

    const heading = `Jeśli ${variant} ${locationDeclination ? locationDeclination : "w całym kraju"} z dodatkowymi udogodnieniami, na gethome.pl znajdziesz:`;

    const hasList = flatsWithBalconyCount > 0 || flatsWithGardenCount > 0 || flatsWithTeraceCount > 0;

    return (
        <>
            {hasList && (
                <>
                    <strong>{heading}</strong>
                    <FeaturesFlats
                        liItems={li}
                        flatTypesCount={{
                            flatsWithGardenCount,
                            flatsWithBalconyCount,
                            flatsWithTeraceCount
                        }}
                        isSell={isSell}
                    />
                </>
            )}
        </>
    );
};
