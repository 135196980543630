import {Dispatch} from "redux";
import {IFullOfferDetailParams} from "@web2/gh_routes";

import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catch404, catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {UrlTagSlug} from "../../list/url_utils/tags";

export interface IOfferFeatures {
    feature: UrlTagSlug;
    doc_count: number;
}

export const fetchOfferFeaturesTypes = createRequestActionTypes({view: "Offer", type: "GET", name: "OfferFeatures"});

export const fetchOfferFeatures = (services: Partial<IServices>, route: IRouteState<IFullOfferDetailParams>) => (dispatch: Dispatch) => {
    dispatch({type: fetchOfferFeaturesTypes.start});
    const url = apiLink.offers.detail.features({})({offerId: route.params.offerId});
    return getRequest(services, url, "fetchOfferFeatures")
        .then((response) => {
            dispatch({type: fetchOfferFeaturesTypes.success, result: response.features});
            return response.features;
        })
        .catch(catch404(() => true))
        .catch(catchAbortedError(() => false));
};
