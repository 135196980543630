import {useSelector} from "react-redux";
import {isUndefined} from "lodash";

import {IStore} from "../../../../app/reducers/hybrid_reducer";
import {getMappedLocationPath} from "../../../../app/utils/get_offer_location_obj_from_path";
import {parseFormOfferTypeToSlug} from "../../../../search/utils/parse_offer_type_to_form_values";
import {TypeSearchFilterOptions} from "../../../../search/utils/TypeSearchFilterOptions";
import {OfferTypePL} from "../../../utils/constants_offer";
import {translateOfferType} from "../../../utils/utils";
import {UrlTagSlug} from "../../url_utils/tags";
import {useTagFromPathname} from "./useTagFromPathname";

export const useGenericListingDescription = () => {
    const {location} = useSelector((store: IStore) => store.offerList.location);
    const total_offers = useSelector((store: IStore) => store.offerList.offers.meta?.total_offers);
    const offerType = useSelector((store: IStore) => store.offerList.latestQuery.offer_type);
    const propertyType = parseFormOfferTypeToSlug(offerType.split(",") as TypeSearchFilterOptions[]);
    const locationObject = getMappedLocationPath(location?.path);
    const listingType = translateOfferType(offerType, OfferTypePL);
    const activePage = parseInt(useSelector((store: IStore) => store.offerList.latestQuery.page));
    const hasTag = useTagFromPathname();
    const isPrimaryMarket = hasTag === UrlTagSlug.PRIMARY_MARKET;
    const district = locationObject.DISTRICT;
    const estate = locationObject.ESTATE;
    const notDistrictOrEstate = !district && !estate;

    const displayGenericListingCondition =
        !isUndefined(total_offers) &&
        total_offers > 0 &&
        listingType === OfferTypePL.apartment &&
        (!isUndefined(locationObject.TOWN) || !isUndefined(locationObject.CITY)) &&
        activePage === 1 &&
        (!hasTag || isPrimaryMarket) &&
        notDistrictOrEstate;

    return {location, total_offers, offerType, propertyType, locationObject, listingType, displayGenericListingCondition};
};
