import {DealTypeSlug, OfferDealType, UDealTypesPL} from "../../offer/utils/constants_offer";

export function dealTypeToUrl(dealType: OfferDealType): UDealTypesPL {
    switch (dealType) {
        case OfferDealType.RENT:
            return DealTypeSlug.RENT;

        case OfferDealType.SELL:
            return DealTypeSlug.SELL;

        default:
            return DealTypeSlug.SELL;
    }
}
