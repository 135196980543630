export type ApplicationSuccessGTMEventPayload = {
    agency?: string; // uuid
    agent?: string; // uuid
    offer?: string; // uuid
    investment?: string; // uuid
};

export const applicationSuccessGTMEvent = (payload: ApplicationSuccessGTMEventPayload) => {
    if ((window as any).dataLayer) {
        return (window as any).dataLayer.push({event: "application_success", ...payload});
    }
    return null;
};
