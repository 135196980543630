export enum PoiType {
    TRANSPORT = "transport",
    HEALTH = "health",
    EDUCATION = "education",
    FOOD = "food",
    SHOPS = "shops",
    SPORT = "sport",
    ENTERTAINMENT = "entertainment",
    ALL = "all",
    OFFERS = "offers"
}
