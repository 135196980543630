import {filter, includes} from "lodash";

import {
    ILoadLocalStorageVisitedToStore,
    ISetVisitedOffer,
    LOAD_LOCAL_STORAGE_VISITED_TO_STORE,
    SET_VISITED_OFFER
} from "../actions/load_local_storage_visited_to_store";
import {reduceReducers} from "../utils/reduce_reducers";

export interface IVisitedStore {
    offers: string[];
}

function localStorageVisitedReducer(state: IVisitedStore = {offers: []}, action: ILoadLocalStorageVisitedToStore) {
    switch (action.type) {
        case LOAD_LOCAL_STORAGE_VISITED_TO_STORE:
            return {
                ...state,
                offers: action.offers
            };
        default:
            return state;
    }
}

function customVisitedReducer(state: IVisitedStore = {offers: []}, action: ISetVisitedOffer) {
    switch (action.type) {
        case SET_VISITED_OFFER:
            return includes(state.offers, action.offer)
                ? {
                      ...state,
                      offers: filter(state.offers, (f) => f !== action.offer)
                  }
                : {
                      ...state,
                      offers: [...state.offers, action.offer]
                  };
        default:
            return state;
    }
}

export const visitedReducer = reduceReducers(localStorageVisitedReducer, customVisitedReducer);
