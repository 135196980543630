import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {LocationTypes} from "../../app/utils/get_location_from_path";
import {createDebouncedAction} from "../../app/utils/request_response_utils/create_debounced_action";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch400} from "../../app/utils/request_response_utils/response_error";
import {AutocompletePrediction, optimizedFetchPlaceList, resetPlaceListSearch, stopFetchPlaceListSearch} from "./fetch_search_places_action";

export enum SearchTab {
    // Placeholder tab
    Placeholder,
    // Places tab adds support google search suggestions
    Places,
    // SearchSuggestions holds combined suggestions for Regions, Offers, Agencies and Agents that are available through combined endpoint
    SearchSuggestions
}

export enum SuggestType {
    location = "location",
    agent = "agent",
    agency = "agency",
    developer = "developer",
    investment = "investment"
}

interface ILocation {
    id: string;
    name: string;
    full_name: string;
    location_type: LocationTypes;
    short_name: string;
    offer_count_sell: number;
    offer_count_rent: number;
    offer_count_total: number;
    suggest_type: SuggestType.location;
    slug: string;
}

interface IAgent {
    id: string;
    name: string;
    agency_name: string;
    offer_count_total: number;
    suggest_type: SuggestType.agent;
    slug: string;
}

interface IAgency {
    id: string;
    name: string;
    offer_count_total: number;
    suggest_type: SuggestType.agency;
    slug: string;
}

interface IDeveloper {
    id: string;
    name: string;
    offer_count_total: number;
    suggest_type: SuggestType.developer;
    slug: string;
}

interface IInvestment {
    id: string;
    name: string;
    offer_count_total: number;
    suggest_type: SuggestType.investment;
    slug: string;
    address: string;
}

export type ISearchSuggestions = ILocation | IAgent | IAgency | IDeveloper | IInvestment;

export const fetchAllListsType = createRequestActionTypes({view: "OfferList", type: "GET", name: "allSearch"});

// We need to be able to switch tabs always from region down to the places.
// We want to prevent situation in which offers were fetched faster than regions so instead of rendering regions we do render offers.
const fetchAllSearchSuggestionsLists = (searchInput: string) => (dispatch: Dispatch) => {
    return new Promise(async (resolve) => {
        const listApiLink = apiLink.suggestion.searchPhrase({})({searchPhrase: searchInput ? encodeURIComponent(searchInput) : searchInput});

        dispatch({type: fetchAllListsType.start});

        return getRequest({}, listApiLink, "fetchAllSearchSuggestionsLists")
            .then((response: ISearchSuggestions[]) => {
                if (response.length > 0) {
                    dispatch({type: fetchAllListsType.success, result: response});
                    resolve(SearchTab.SearchSuggestions);
                    return response; // important for batched fetch
                } else {
                    dispatch(optimizedFetchPlaceList(searchInput)).then(async (predictions: AutocompletePrediction[]) => {
                        // do not check if `predictions` is empty because even so we should land on `PlacesTab`
                    });
                    // resolve main promise
                    resolve(SearchTab.Places); // SWITCH to places
                }
            })
            .catch(
                catch400((err) => {
                    dispatch({type: fetchAllListsType.error, err});
                })
            );
    });
};

export const {action: fetchAllSearchLists} = createDebouncedAction(fetchAllSearchSuggestionsLists, 500);

export const stopFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.error});
    dispatch(stopFetchPlaceListSearch());
};

export const resetFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.reset});
    dispatch(resetPlaceListSearch());
};
