import * as React from "react";

export const PairIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                d="M19.61,7.69a2.69,2.69,0,1,0-1-5.18,2.69,2.69,0,0,0,1,5.18Zm0-4.64a1.95,1.95,0,1,1-1.94,2A1.95,1.95,0,0,1,19.61,3.05Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M23.59,11.82a.38.38,0,0,0-.36.38v5.09a.25.25,0,0,1-.25.23h-.91V11a.37.37,0,0,0-.36-.3.38.38,0,0,0-.37.3V26.66a.27.27,0,0,1-.25.25h-.87a.25.25,0,0,1-.23-.25V20.8a.38.38,0,1,0-.75,0v5.86a.25.25,0,0,1-.23.25h-.87a.26.26,0,0,1-.24-.25V11a.37.37,0,0,0-.37-.37.38.38,0,0,0-.38.37v6.57h-.89a.23.23,0,0,1-.23-.23V11a2.21,2.21,0,0,1,2.21-2.21H21A2.22,2.22,0,0,1,23.24,11,.38.38,0,1,0,24,11,3,3,0,0,0,21,8h-2.8a3,3,0,0,0-3,2.95v6.34a1,1,0,0,0,1,1h.9v8.39a1,1,0,0,0,1,1H19a.94.94,0,0,0,.6-.22,1,1,0,0,0,.61.22h.87a1,1,0,0,0,1-1V18.27H23a1,1,0,0,0,1-1V12.2a.38.38,0,0,0-.38-.38Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M9.78,10A2.69,2.69,0,1,0,7.19,7.24,2.7,2.7,0,0,0,9.78,10Zm0-4.63a1.94,1.94,0,1,1-1.9,1.91A1.95,1.95,0,0,1,9.78,5.33Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M11.08,10.31H8.61A2.59,2.59,0,0,0,6,12.9v5.6a.91.91,0,0,0,.91.91l-.26,2.34a.45.45,0,0,0,.09.3.4.4,0,0,0,.29.12h.62v4.62a.91.91,0,0,0,.91.91h.76a.9.9,0,0,0,.45-.18.91.91,0,0,0,.55.18h.75a.92.92,0,0,0,.65-.26.93.93,0,0,0,.27-.65V24.73a.38.38,0,0,0-.75,0v2.06a.18.18,0,0,1-.17.17h-.71a.17.17,0,0,1-.17-.17v-4a.38.38,0,1,0-.74,0v4a.18.18,0,0,1-.17.17h-.8a.18.18,0,0,1-.12-.05.16.16,0,0,1,0-.12V22.17h3v1.37a.37.37,0,0,0,.37.38.38.38,0,0,0,.38-.38V22.17h.62a.36.36,0,0,0,.28-.12.4.4,0,0,0,.09-.3l-.25-2.34a.9.9,0,0,0,.9-.91V13a2.58,2.58,0,0,0-2.6-2.64Zm1.86,8.19a.13.13,0,0,1,0,.12.16.16,0,0,1-.11.05H12.7L12,12.48a.35.35,0,0,0-.14-.25.33.33,0,0,0-.27-.09.35.35,0,0,0-.25.14.36.36,0,0,0-.08.28l1,8.92H7.41l1-8.87a.37.37,0,0,0-.09-.28.38.38,0,0,0-.25-.13.37.37,0,0,0-.4.33L7,18.72H6.87a.17.17,0,0,1-.17-.16V13a1.89,1.89,0,0,1,1.91-1.83h2.47A1.88,1.88,0,0,1,12.94,13Z"
                fill="#37474f"
                fillRule="evenodd"
            />
        </svg>
    );
};
