import * as React from "react";
import {useEffect, useRef} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {CheckIcon} from "@web2/icons";

import {CheckboxLogic, ICheckboxLogicProps, ICheckboxRenderProps, ICheckboxValue} from "./CheckboxLogic";

type CheckboxSize = "lg" | "sm";
type CheckboxType = "default" | "button" | "toggleSwitch";

export interface ICheckboxProps extends ICheckboxLogicProps {
    error?: string[];

    // additional
    onAfterChange?: (name: string, checked: boolean, value: ICheckboxValue) => void;
    onBlur?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    onFocus?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    label?: React.ReactNode;
    required?: boolean;
    labelClass?: string;
    className?: string;
    size?: CheckboxSize;
    checkboxType?: CheckboxType;
    errorOnBottom?: boolean;
    errorOnBottomIndent?: boolean;
    colorTheme?: {
        active: string;
        bar: string;
        inactive?: string;
    };
}

interface ITheme {
    forms: {
        error_message_color: string;
        error_message_font_size: string;
        error_message_line_height: number;
        error_message_padding_bottom: string;
    };
    checkboxes: {
        checkbox_border_radius: string;
        checkbox_transition: string;
        checkbox_icon_color: string;
        checkbox_icon_width_base: string;
        checkbox_icon_width_large: string;
        checkbox_icon_width_small: string;
        checkbox_icon_box_width_base: string;
        checkbox_icon_box_width_large: string;
        checkbox_icon_box_width_small: string;
        checkbox_icon_height_base: string;
        checkbox_icon_height_small: string;
        checkbox_icon_height_large: string;
        checkbox_icon_box_height_base: string;
        checkbox_icon_box_height_small: string;
        checkbox_icon_box_height_large: string;
        checkbox_icon_border: string;
        checkbox_icon_margin_right: string;
        checkbox_icon_margin_top: string;
        checkbox_label_font_size_base: string;
        checkbox_label_font_size_large: string;
        checkbox_label_font_size_small: string;
        checkbox_label_color: string;
        checkbox_outline: string;
        checkbox_focus_box_shadow: string;
        checkbox_icon_border_color: string;
        checkbox_icon_border_color_active: string;
        checkbox_icon_background_color_active: string;

        checkbox_btn_icon_color: string;
        checkbox_btn_background: string;
        checkbox_btn_border: string;
        checkbox_btn_border_radius: string;
        checkbox_btn_font_weight: string;
        checkbox_btn_padding: string;
        checkbox_btn_icon_left_padding: string;
    };
    fonts: {
        font_size_base: string;
        font_size_large: string;
        font_size_small: string;
        line_height_base: string;
        line_height_large: string;
        line_height_small: string;
    };
}

interface ICheckboxLabelTextThemeProps {
    isActive?: boolean;
    checkboxType?: CheckboxType;
}

interface ICheckboxToggleSwitchThemeProps {
    isActive?: boolean;
    colorTheme?: {
        active: string;
        bar: string;
        inactive: string;
    };
}

interface IThemeProps {
    checked?: boolean;
    checkboxType?: CheckboxType;
    size?: CheckboxSize;
}

interface ICheckboxError {
    bottom?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
    const didRenderedOnceRef = useRef(false);

    useEffect(() => {
        if (props.onAfterChange && didRenderedOnceRef.current) {
            props.onAfterChange(props.name, props.checked, props.value);
        }
    }, [props.checked]);

    useEffect(() => {
        didRenderedOnceRef.current = true;
    }, []);

    const className = classNames(props.className, props.checked && "active", props.readOnly && "read-only");
    const toggleTheme = {
        inactive: props.colorTheme?.inactive || "#fafafa",
        active: props.colorTheme?.active || "#45bc83",
        bar: props.colorTheme?.bar || "#b7e6d0"
    };

    return (
        <CheckboxLogic
            onChange={props.onChange}
            name={props.name}
            value={props.value}
            checked={props.checked}
            readOnly={props.readOnly}
            render={(renderProps: ICheckboxRenderProps) => (
                <CheckboxWrapper checkboxType={props.checkboxType} className={className} size={props.size} checked={props.checked}>
                    {props.error && !props.errorOnBottom && !props.errorOnBottomIndent && <CheckboxError>{props.error}</CheckboxError>}
                    <CheckboxInnerWrapper
                        data-testid={`checkbox-${props.name}`}
                        onClick={renderProps.onClick}
                        checkboxType={props.checkboxType}
                        checked={props.checked}
                    >
                        <CheckboxContent checkboxType={props.checkboxType} tabIndex={-1}>
                            <CheckboxIconWrap size={props.size} checkboxType={props.checkboxType}>
                                {props.checked && <CheckIcon />}
                            </CheckboxIconWrap>
                            {props.checkboxType === "toggleSwitch" && (
                                <CheckboxToggleSwitch isActive={props.checked} colorTheme={toggleTheme}>
                                    <ToggleSwitchCircle />
                                </CheckboxToggleSwitch>
                            )}
                            <CheckboxLabelText isActive={props.checked} className={props.labelClass} checkboxType={props.checkboxType}>
                                {props.label}
                                {props.error && props.errorOnBottomIndent && <CheckboxError bottom>{props.error}</CheckboxError>}
                            </CheckboxLabelText>
                        </CheckboxContent>
                    </CheckboxInnerWrapper>
                    {props.error && props.errorOnBottom && <CheckboxError bottom>{props.error}</CheckboxError>}
                </CheckboxWrapper>
            )}
        />
    );
};

/*
Styles
 */

//TODO: define read-only styles
//TODO: define checkbox sizes styles
const CheckboxWrapper = styled.div<IThemeProps>`
    position: relative;
    border: none;
    font-size: ${(props) => props.theme?.checkboxes?.checkbox_label_font_size_base ?? "1.2rem"};
    color: ${(props) => props.theme?.checkboxes?.checkbox_label_color ?? "#43444d"};
    line-height: ${(props) => props.theme?.fonts?.line_height_base ?? 1.428571429};
    user-select: none;
    cursor: pointer;

    &.active,
    &.active:focus,
    &:active,
    &:active:focus,
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
    }

    ${(props) =>
        props.size === "lg" &&
        css`
            font-size: ${props.theme?.checkboxes?.checkbox_label_font_size_large ?? "1.4rem"};
            line-height: ${props.theme?.fonts?.line_height_large ?? 1.3333333};
        `}

    ${(props) =>
        props.size === "sm" &&
        css`
            font-size: ${props.theme?.checkboxes?.checkbox_label_font_size_small ?? "1rem"};
            line-height: ${props.theme.fonts?.line_height_small ?? 1.428571429};
        `}
`;

const CheckboxInnerWrapper = styled.div<IThemeProps>`
    ${(props) =>
        props.checkboxType === "button" &&
        css`
            transition: ${props.theme?.checkboxes?.checkbox_transition ?? "border 0.2s ease-in-out, background .2s ease-in-out"};
            border: ${props.theme?.checkboxes?.checkbox_btn_border ?? `1px solid #909090`};
            text-align: center;
            width: 100%;
            border-radius: ${props.theme?.checkboxes?.checkbox_btn_border_radius ?? ".4rem"};

            ${props.checked &&
            css`
                background: ${props.theme?.checkboxes?.checkbox_btn_background ?? "#ececec"};
                font-weight: ${props.theme?.checkboxes?.checkbox_btn_font_weight ?? "500"};
            `}
        `}
`;

const CheckboxContent = styled.div<IThemeProps>`
    flex-direction: row;
    display: flex;
    max-width: 100%;
    cursor: pointer;

    ${(props) =>
        props.checkboxType === "button" &&
        css`
            padding: ${props.theme?.checkboxes?.checkbox_btn_padding ?? "1.2rem 3rem"};
            justify-content: center;
        `}

    ${(props) =>
        props.checkboxType === "toggleSwitch" &&
        css`
            flex-direction: row-reverse;
            justify-content: space-between;
        `}

    &:focus {
        outline: 0 none;
    }

    ${CheckboxInnerWrapper}:focus & {
        outline: ${(props) => props.theme?.checkboxes?.checkbox_outline ?? `2px solid #45bc83`};
    }
`;

export const CheckboxIconWrap = styled.div<IThemeProps>`
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.theme?.checkboxes?.checkbox_icon_width_base ?? "16px"};
    height: ${(props) => props?.theme?.checkboxes?.checkbox_icon_height_base ?? "16px"};
    margin-right: ${(props) => props?.theme?.checkboxes?.checkbox_icon_margin_right ?? "1.2rem"};
    margin-top: ${(props) => props?.theme?.checkboxes?.checkbox_icon_margin_top ?? "0.2rem"};
    border-radius: ${(props) => props?.theme?.checkboxes?.checkbox_border_radius ?? ".4rem"};
    transition: ${(props) => props?.theme?.checkboxes?.checkbox_transition ?? "border 0.2s ease-in-out, background .2s ease-in-out"};
    border: ${(props) => props?.theme?.checkboxes?.checkbox_icon_border ?? "1px solid #bbbbbb"};
    cursor: pointer;

    ${(props) =>
        props.checkboxType === "button" &&
        css`
            border: 0 none;
            position: absolute;
            left: ${props?.theme?.checkboxes?.checkbox_btn_icon_left_padding ?? "0.8rem"};
            top: 50%;
            transform: translateY(-50%);
            margin-top: 2px;
        `}

    ${CheckboxWrapper}.active:focus,
    ${CheckboxWrapper}:active,
    ${CheckboxWrapper}:active:focus,
    ${CheckboxWrapper}:focus & {
        box-shadow: ${(props) =>
            props?.theme?.checkboxes?.checkbox_focus_box_shadow ?? `0 0 0 1px rgba(red("#45bc83"), green("#45bc83"), blue("#45bc83"), 0.5)`};
    }

    ${CheckboxWrapper}:hover & {
        border-color: ${(props) => props?.theme?.checkboxes?.checkbox_icon_border_color ?? "#45bc83"};
    }

    ${CheckboxWrapper}.active & {
        ${(props) => {
            if (!props.checkboxType || props.checkboxType === "default") {
                return css`
                    background-color: ${props?.theme?.checkboxes?.checkbox_icon_background_color_active ?? "#45bc83"};
                    border-color: ${props?.theme?.checkboxes?.checkbox_icon_border_color_active ?? "#45bc83"};
                `;
            } else if (props.checkboxType === "button") {
                return css`
                    border: 0 none;
                `;
            }
        }}
    }

    ${(props) =>
        props.size === "lg" &&
        css`
            width: ${props?.theme?.checkboxes?.checkbox_icon_box_width_large ?? "40px"};
            height: ${props?.theme?.checkboxes?.checkbox_icon_box_height_large ?? "40px"};
        `}

    svg {
        ${(props) => {
            if (!props.checkboxType || props.checkboxType === "default") {
                return css`
                    fill: ${props?.theme?.checkboxes?.checkbox_icon_color ?? "#fff"};
                `;
            } else if (props.checkboxType === "button") {
                return css`
                    fill: ${props?.theme?.checkboxes?.checkbox_btn_icon_color ?? "#43444d"};
                `;
            }
        }}

        ${(props) =>
            props.size === "lg" &&
            css`
                width: ${props?.theme?.checkboxes?.checkbox_icon_width_large ?? "30px"};
                height: ${props?.theme?.checkboxes?.checkbox_icon_height_large ?? "30px"};
            `}
    }

    ${(props) =>
        props.checkboxType === "toggleSwitch" &&
        css`
            display: none;
        `}
`;

const CheckboxLabelText = styled.span<ICheckboxLabelTextThemeProps>`
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
`;

const CheckboxError = styled.div<ICheckboxError>`
    color: ${(props) => props?.theme?.forms?.error_message_color};
    font-size: ${(props) => props?.theme?.forms?.error_message_font_size};
    line-height: ${(props) => props?.theme?.forms?.error_message_line_height};
    padding-bottom: ${(props) => props?.theme?.forms?.error_message_padding_bottom};
`;

const ToggleSwitchCircle = styled.div<ICheckboxToggleSwitchThemeProps>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow:
        0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px rgba(0, 0, 0, 0.14),
        0 1px 3px rgba(0, 0, 0, 0.12);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    pointer-events: none;
`;

const CheckboxToggleSwitch = styled.div<ICheckboxToggleSwitchThemeProps>`
    display: flex;
    align-items: center;
    width: 34px;
    height: 14px;
    background: ${(props) => (props.isActive ? props.colorTheme?.bar : "#9e9e9e")};
    border-radius: 10px;
    transition-duration: 0.4s;
    transition-property: background-color, box-shadow;
    cursor: pointer;

    ${ToggleSwitchCircle} {
        background: ${(props) => (props.isActive ? props.colorTheme?.active : props.colorTheme?.inactive)};
        transform: ${(props) => (props.isActive ? "translate3d(18px, 0, 0)" : "")};
    }
`;
