import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css} from "@linaria/core";
import {isEqual, omit} from "lodash";
import {bindActionCreators, Dispatch} from "redux";
import {FormActions} from "@web2/form2";
import {useClickOutside} from "@web2/react_utils";

import {ISearchFeature} from "../../app/interfaces/response/server_list_response";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {GETHOME_NAVIGATION_ID} from "../../navigation/NavigationGh";
import {ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {SearchBlend} from "../../offer/list/components/atoms/atoms";
import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {getThemeBreakpoint} from "../../styles/linaria_variable_factory";
import {IFilterStats} from "../actions/fetch_filter_stats";
import {resetSearchForm, resetSearchFormWithQuery, searchFormActions} from "../actions/search_form_actions";
import {searchFormFields, SearchFormValuesType} from "../utils/form_types";
import {getNewOfferType} from "../utils/get_new_offer_type";
import {getStringQueryValuesFromFormValues} from "../utils/get_string_query_values_from_form";
import {useCachedSearchPrices} from "../utils/use_cached_prices";
import {useSearchSubmit} from "../utils/use_search_submit";
import {SearchAutocomplete} from "./SearchAutocomplete";
import {SearchFilters} from "./SearchFilters";

export const googlePlacesDivId = "google_places_tab_results";

interface IStateProps {
    formValues: SearchFormValuesType;
    filterStats: IFilterStats;
    filterStatsRequest: RequestState;
    latestQuery: IOfferListQuery;
    offerListLocation: ILocation | null;
    offersMeta: {
        features: ISearchFeature[];
        total_offers_not_collapsed: number;
    } | null;
}

interface IActionsProps {
    formActions: FormActions<Record<keyof typeof searchFormFields, any>>;
    resetSearchForm: typeof resetSearchForm;
    resetSearchFormWithQuery: typeof resetSearchFormWithQuery;
}

interface IOwnProps extends RouteComponentProps<{}> {}

export interface IPropsSearch extends IStateProps, IActionsProps, IOwnProps {}

export type IActiveDropdown =
    | "search"
    | "area"
    | "price"
    | "price_per_sqm"
    | "type"
    | "deal_type"
    | "rooms"
    | "more"
    | "available_from"
    | "features"
    | "floor"
    | "construction_year"
    | null;

const SearchC = (props: IPropsSearch) => {
    const [isDesktopFilterBlendActive, setDesktopFilterBlendActive] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState<IActiveDropdown>(null);
    const [isMobileWidth, setMobileWidth] = useState(false);

    const {onSubmit, isTransitioning} = useSearchSubmit();

    const dispatch = useDispatch();
    const searchRef = useRef<HTMLElement | null>(null);
    const blendRef = useRef<HTMLDivElement | null>(null);
    const searchRefHandle = (elem: HTMLElement | null) => {
        searchRef.current = elem;
    };

    const searchMobileFiltersRef = useRef<HTMLElement | null>(null);
    const searchMobileFiltersRefHandle = (elem: HTMLElement | null) => {
        searchMobileFiltersRef.current = elem;
    };

    useEffect(() => {
        setMobileWidth(window.innerWidth < 1024);
        (async () => {
            await dispatch(resetSearchFormWithQuery(props.latestQuery));
        })();
    }, []);

    const onFilterCancel = () => {
        dispatch(resetSearchFormWithQuery(props.latestQuery));
    };

    useClickOutside(
        isMobileWidth ? searchMobileFiltersRef : searchRef,
        (e) => {
            !isMobileWidth && setActiveDropdown(null);
            setDesktopFilterBlendActive(false);

            const navigationContainer = document.querySelector(`#${GETHOME_NAVIGATION_ID}`);
            const eventTargetIsBlendOrNav = e.target && (e.target === blendRef.current || navigationContainer?.contains(e.target as Node));
            if (!isMobileWidth && eventTargetIsBlendOrNav) {
                onFilterCancel();
            }
        },
        [props.latestQuery]
    );

    /**
     * relations between filters
     */

    const {getCachedPrices, setCachedPrices} = useCachedSearchPrices(props.formValues.offer_type);

    // we want to set `offer type` to show both houses and apartments when user switches `deal type` to `for sale`,
    // but NOT if he intentionally changed it in the past
    const hasUserInteractedWithOfferTypeRef = useRef<boolean>(false);

    const onAfterChange = (name?: string, value?: any) => {
        const currentValues = name ? {...props.formValues, [name]: value} : {...props.formValues};
        const queryValues = getStringQueryValuesFromFormValues(currentValues);

        const deconstructedGeoPoint = props.latestQuery.geo_point
            ? {lat: props.latestQuery.geo_point.split("|")[0], lng: props.latestQuery.geo_point.split("|")[1]}
            : {};

        const lastQueryValues = omit({...props.latestQuery, ...deconstructedGeoPoint}, [
            "is_active",
            "per_page",
            "page",
            "sort",
            "distance",
            "geo_point",
            "geo_box_bottom_left",
            "geo_box_top_right",
            "agency",
            "market"
        ]);

        if (isEqual(omit(queryValues, ["per_page", "page"]), lastQueryValues)) {
            return;
        }

        /*
         * side effects of filter changes
         */

        if (name === "deal_type" && !Array.isArray(value)) {
            // adjust current offer_type
            const newOfferType = getNewOfferType(currentValues, value, hasUserInteractedWithOfferTypeRef.current);
            if (newOfferType) {
                // update search form redux store, because fetch_offer_list_at_route is based on its values
                props.formActions.update({offer_type: newOfferType});
                currentValues.offer_type = newOfferType;
            }
        }

        if (name == "offer_type") {
            // remember that user interacted with offer_type`,
            hasUserInteractedWithOfferTypeRef.current = true;

            if (Array.isArray(value)) {
                const isPrimaryMarket = !!value.find((o) => o.includes("primary_market"));
                const isAftermarket = !!value.find((o) => o.includes("aftermarket"));
                if (isPrimaryMarket && !isAftermarket) {
                    currentValues.is_private = false;
                }
            }
        }

        setCachedPrices(props.formValues, name);
        // restore previously cached prices
        const previousPrices = getCachedPrices(value, props.formValues, name);
        if (previousPrices) {
            currentValues.price = previousPrices.price;
            currentValues.price_per_sqm = previousPrices.pricePerSqm;
        }

        return onSubmit(currentValues);
    };

    const onSearchSubmit = () => onSubmit(props.formValues);

    return (
        <>
            <div className={searchHolder} ref={searchRefHandle}>
                <div className={searchWrapper}>
                    <div id={googlePlacesDivId} />

                    <SearchAutocomplete
                        values={props.formValues}
                        onChange={props.formActions.update}
                        onAfterChange={onAfterChange}
                        onSubmit={onSearchSubmit}
                        onDropdownStatusChange={setDesktopFilterBlendActive}
                        wrapperRef={searchRef}
                        isSearchDropdownOpen={activeDropdown === "search"}
                        dealType={props.latestQuery.deal_type}
                        setSearchDropdownOpen={(isOpen: boolean) => {
                            !searchMobileFiltersRef.current && setActiveDropdown(isOpen ? "search" : null);
                            setDesktopFilterBlendActive(isOpen);
                        }}
                    />
                </div>
                <SearchFilters
                    filterStats={props.filterStats}
                    filterStatsRequest={props.filterStatsRequest}
                    values={props.formValues}
                    onAfterChange={onAfterChange}
                    onChange={props.formActions.update}
                    onSubmit={onSearchSubmit}
                    setDesktopFilterBlendActive={setDesktopFilterBlendActive}
                    offerType={props.latestQuery.offer_type}
                    latestQuery={props.latestQuery}
                    offersMeta={props.offersMeta}
                    filterState={activeDropdown}
                    setFilterState={setActiveDropdown}
                    mobileFiltersRefHandle={searchMobileFiltersRefHandle}
                    filterOnSubmit={onSubmit}
                    locationType={props.offerListLocation?.location_type}
                    onFilterCancel={onFilterCancel}
                />
            </div>

            <SearchBlend isActive={isDesktopFilterBlendActive} ref={blendRef} />
        </>
    );
};

export const Search = withRouter(connect(mapStateToProps, mapActionsToProps)(SearchC));

function mapStateToProps(state: IStore): IStateProps {
    return {
        formValues: state.search.formValues,
        filterStats: state.search.filterStats,
        filterStatsRequest: state.search.filterStatsRequest,
        latestQuery: state.offerList.latestQuery,
        offerListLocation: state.offerList.location.location,
        offersMeta: state.offerList.offers.meta
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        formActions: bindActionCreators({...searchFormActions}, dispatch),
        ...bindActionCreators(
            {
                resetSearchFormWithQuery,
                resetSearchForm
            },
            dispatch
        )
    };
}

const searchHolder = css`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: white;

    @media (max-width: calc(${getThemeBreakpoint().screen_sm} - 1px)) {
        flex-direction: column;
    }

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        flex-wrap: wrap;
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        flex-wrap: nowrap;
    }
`;

const searchWrapper = css`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        width: 60%;
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: 230px;
    }

    @media (min-width: ${getThemeBreakpoint().screen_xl}) {
        width: 370px;
    }
`;
