import {css} from "@linaria/core";

import {getThemeVariable} from "./linaria_variable_factory";

// sizes
export const xxxs = ".1rem";
export const xxs = ".2rem";
export const xs = ".3rem";
export const sm = ".5rem";
export const md = "1rem";
export const lg = "1.5rem";
export const xl = "2rem";
export const xxl = "2.5rem";
export const xxxl = "3rem";

export const w50 = css`
    width: 50%;
    flex-basis: 50%;
`;
export const m_0a = css`
    margin: 0 auto;
`;
export const p_0 = css`
    padding: 0;
`;
export const m_0 = css`
    margin: 0;
`;
export const w65 = css`
    width: 65%;
    flex-basis: 65%;
`;
export const tdn = css`
    text-decoration: none;
`;
export const mr_md = css`
    margin-right: ${md};
`;
export const dn = css`
    display: none;
`;

export const cover = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
};

export const getOfferTypeColorCSSVariable = (bgColor?: "primary_market" | "aftermarket" | "gray_light" | "lot") =>
    bgColor ? getThemeVariable(`colors-${bgColor}`) : getThemeVariable("colors-gray_light");

// width
export const wa = css`
    width: auto;
    flex-basis: auto;
`;

export const w100 = css`
    width: 100%;
    flex-basis: 100%;
`;

// height

export const h100 = css`
    height: 100%;
`;

// margins
export const mb_0 = css`
    margin-bottom: 0;
`;

export const mb_md = css`
    margin-bottom: ${md};
`;

export const mt_0 = css`
    margin-top: 0;
`;

export const mt_md = css`
    margin-top: ${md};
`;

// paddings
export const pt_xl = css`
    padding-top: ${xl};
`;

// font
export const fs14 = css`
    font-size: 1.4rem;
`;

// displays
export const df = css`
    display: flex;
`;
export const db = css`
    display: block;
`;
export const dib = css`
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
`;
export const di = css`
    display: inline;
`;
