import * as React from "react";
import {ReactNode} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

interface IProps {
    children?: ReactNode;
    wrapperColor: string;
    wrapperSize: string;
}

export const IconCircleWrapper: React.FC<IProps> = (props) => {
    const {wrapperColor, wrapperSize} = props;

    return (
        <IconWrapper wrapperColor={wrapperColor} wrapperSize={wrapperSize}>
            {props.children}
        </IconWrapper>
    );
};

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    ${(props: {wrapperColor: string; wrapperSize: string}) => css`
        background-color: ${props.wrapperColor};
        min-width: ${`${props.wrapperSize}rem`};
        width: ${`${props.wrapperSize}rem`};
        height: ${`${props.wrapperSize}rem`};
    `};
`;
