import {Dispatch, Middleware, MiddlewareAPI} from "redux";

import {PromiseAction} from "../../../../typings/custom/promise-middleware";
import {apiPath} from "../../routes/api_link";
import {IGivenRouteState} from "../../routes/data_fetcher/create_app_path_data_fetcher";
import {notifyBugsnag} from "../bugsnag/notify_bugsnag";
import {consoleError, consoleWarn} from "../logger";
import {isProduction} from "../read_environment_variables";
import {IActionResponseState} from "./response_state/response_state_actions";
import {getMatchedPathDefinition} from "./get_matched_path";
import {catch5xx, catch401, catch403, catch404, catch504, catchAbortedError, catchUnknownError, catchUnknownStatus, Response5xx} from "./response_error";

interface IPromiseMiddlewareOptions {
    errorAlertAction?: () => any;
    on403Response?: () => (dispatch: Dispatch) => Promise<void>;
    onStatusError?: (status: number) => IActionResponseState;
    notifyError: (error: {name: string; message: string; error?: Error}, context: string) => void;
    meta?: {currentRoute: IGivenRouteState};
}

const isPromise = (value: any) => typeof value === "object" && typeof value.then === "function";

export const promiseMiddleware =
    (options: IPromiseMiddlewareOptions): Middleware =>
    <TStore>({dispatch, getState}: MiddlewareAPI) =>
    (next: Dispatch): Dispatch =>
    (action: PromiseAction<any, TStore>) => {
        const {errorAlertAction, on403Response, onStatusError, notifyError, meta} = options;
        const reqUrl = (meta && meta.currentRoute && meta.currentRoute.url) || null;
        // object action
        if (typeof action !== "function") {
            return next(action);
        }
        const result = action(dispatch, getState);
        // function action
        if (!isPromise(result)) {
            return result;
        }

        // promise action
        return (
            result
                .then((data: any) => {
                    return data;
                })
                /**
                 * NOTE: when we catch something here that means we made a mistake:
                 * Either API responded with something it shouldn't have (backend error), or we did not expect specific response code in action (frontend error).
                 *
                 * As a result we cancel further data-fetching (return `undefined` or `false`).
                 * `undefined` means we should cancel next call (e.g. reduceActions)
                 * `false` means we should cancel all future calls (e.g. after strictMapActions)
                 */
                .catch(
                    catch401((err) => {
                        consoleError("middleware-error 401", err.message, " ; reqUrl: ", reqUrl);
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err, `promiseMiddleware: catch 401: ${reqUrl}`);
                    })
                )
                .catch(
                    catch403((err) => {
                        consoleError("middleware-error 403", err.message, " ; reqUrl: ", reqUrl);
                        on403Response && dispatch(on403Response());
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err, `promiseMiddleware: catch 403: ${reqUrl}`);
                    })
                )
                .catch(
                    catch404((err) => {
                        consoleError("middleware-error 404", err.message, " ; reqUrl: ", reqUrl);
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err, `promiseMiddleware: catch 404: ${reqUrl}`);
                    })
                )
                .catch(
                    catch504((err) => {
                        consoleError("middleware-error 504", err.message, " ; reqUrl: ", reqUrl);
                        onStatusError && dispatch(onStatusError(err.response.status));
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err, `promiseMiddleware: catch 504: ${reqUrl}`);
                        return false;
                    })
                )
                .catch(
                    catch5xx((err) => {
                        consoleError("middleware-error 5xx", err.response.status, err.message, " ; reqUrl: ", reqUrl);
                        onStatusError && dispatch(onStatusError(err.response.status));
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError({...err, error: getError5xxBody(err)}, `promiseMiddleware: catch 5xx: ${reqUrl}`);
                        return false;
                    })
                )
                .catch(
                    catchAbortedError(() => {
                        if (!isProduction) {
                            consoleWarn("Request stalled, fetch aborted");
                        }
                        return false;
                    })
                )
                .catch(
                    catchUnknownStatus((err) => {
                        consoleError("middleware-error unknown status", err.message, " ; reqUrl: ", reqUrl);
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err, `promiseMiddleware: catch unknown status: ${reqUrl}`);
                    })
                )
                .catch(
                    catchUnknownError((err) => {
                        consoleError("middleware-error unknown error: ", err.message, " ; reqUrl: ", reqUrl, " ; originalError: ", err.originalError);
                        const status = 500;
                        onStatusError && dispatch(onStatusError(status));
                        errorAlertAction && dispatch(errorAlertAction());
                        notifyError(err.originalError, `promiseMiddleware: catch unknown error, reqUrl: ${reqUrl}`);
                        return false;
                    })
                )
                .catch((err) => {
                    consoleError("promiseMiddleware catch all", err, " ; reqUrl: ", reqUrl);
                    const status = err && err.response && err.response.status ? err.response.status : 500;
                    onStatusError && dispatch(onStatusError(status));
                    notifyError(err, `promiseMiddleware: catch all: ${reqUrl}`);
                    return false;
                })
        );
    };

const getError5xxBody = (err: Response5xx) => {
    // manually creating error-like object so bugsnag has something more readable to report

    try {
        // try...catch block because `new URL()` will throw `TypeError` if something goes wrong, ie. when received string cannot be converted to url
        const url = new URL(err.message);
        if (err.message && url && url.pathname) {
            const matchedPath = getMatchedPathDefinition(apiPath, url.pathname);
            return {
                name: `${err.name}: ${url.host + (matchedPath ? matchedPath : url.pathname)}`, // ie. "Server Error: api.gethome.pl/offers/coordinates/"
                message: err.message
            };
        }
    } catch (caughtError: any) {
        // notify about additional error
        notifyBugsnag(
            {
                name: "getValidErrorBody",
                message: "Error while building error body",
                error: caughtError
            },
            "Error while building error body",
            {originalError: err}
        );
    }
};
