import {Dispatch} from "redux";
import {appLink, IInvestmentDetailParams, parseInvestmentSlugToObject, parseProvidedObjectToSlug} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../../app/actions/page_404_actions";
import {IInvestmentDetailResponse} from "../../../app/interfaces/response/investment";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catch404} from "../../../app/utils/request_response_utils/response_error";
import {enable301ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";
import {getOfferListUrl} from "../../list/url_utils/get_offer_list_url";
import {translateOfferType} from "../../utils/utils";

export const fetchInvestmentDetailTypes = createRequestActionTypes({view: "Investment", type: "GET", name: "Investment detail"});

export const fetchInvestmentDetailAtRoute = (services: Partial<IServices>, route: IRouteState<IInvestmentDetailParams>) => (dispatch: Dispatch) => {
    dispatch({type: fetchInvestmentDetailTypes.start});

    const url = apiLink.investment.detail({})({investmentId: route.params.investmentId});

    return getRequest({}, url)
        .then((investment: IInvestmentDetailResponse) => {
            const providedSlug = parseProvidedObjectToSlug({id: route.params.investmentId, slug: route.params.investmentSlug});

            if (investment.slug !== providedSlug) {
                const {investmentId, investmentSlug} = parseInvestmentSlugToObject(investment.slug);
                dispatch(enable301ResponseState(appLink.investment.detail({investmentId, investmentSlug})));
                return false;
            }

            if (!investment.is_active) {
                const offerType = translateOfferType(investment.investment_type);
                const [location, subLocation] = parseSlugToInvestmentLocation(investment.location.slug);
                dispatch(enable301ResponseState(getOfferListUrl({offerType, location, subLocation, type: "na-sprzedaz"})));
                return;
            }

            dispatch({type: fetchInvestmentDetailTypes.success, result: investment});
            return investment;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return Promise.resolve(false);
            })
        );
};

const parseSlugToInvestmentLocation = (slug: string) => slug.split("/").slice(0, 2);
