import {Dispatch} from "redux";

import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catch404} from "../../../app/utils/request_response_utils/response_error";
import {ISearchResultRouteParams} from "./fetch_offer_list_at_route";

export const fetchOfferListInvestmentTypes = createRequestActionTypes({view: "offerList", type: "GET", name: " investment"});

export const fetchOfferListInvestmentAtRoute = (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => (dispatch: Dispatch) => {
    if (!route.query.investment) {
        dispatch({type: fetchOfferListInvestmentTypes.reset});
        return Promise.resolve(true);
    }
    dispatch({type: fetchOfferListInvestmentTypes.start});

    const investmentApiLink = apiLink.investment.detail({})({investmentId: route.query.investment});
    return getRequest({}, investmentApiLink)
        .then((result) => {
            dispatch({type: fetchOfferListInvestmentTypes.success, result});
            return Promise.resolve(true);
        })
        .catch(
            catch404((err) => {
                dispatch({type: fetchOfferListInvestmentTypes.error, err});
                return Promise.resolve(true);
            })
        );
};
