import {getIsMobileMatch} from "@web2/react_utils";

export enum PlacementDest {
    DESKTOP = "desktop",
    MOBILE = "mobile"
}

export const AD_BREAKPOINT = 640;

export const calculatePlacementDest = (breakpoint = AD_BREAKPOINT): PlacementDest => {
    const match = getIsMobileMatch(false, breakpoint);
    return match?.matches ? PlacementDest.MOBILE : PlacementDest.DESKTOP;
};
