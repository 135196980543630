import {LocationTypes} from "./get_location_from_path";

type GenericPath = {type: string};
type MappedLocationPath<PathType> = Partial<Record<keyof typeof LocationTypes, PathType>>;

export const getMappedLocationPath = <PathType extends GenericPath>(locationPath: PathType[] | undefined): MappedLocationPath<PathType> => {
    if (locationPath) {
        const objectLocation: MappedLocationPath<PathType> = {};
        for (const element of locationPath) {
            const elementTranslated = translateType(element.type);
            if (elementTranslated) {
                objectLocation[elementTranslated] = element as PathType;
            }
        }
        return objectLocation;
    }
    return {};
};

function translateType(elementType: string) {
    switch (elementType) {
        case LocationTypes.TOWN:
            return "TOWN";
        case LocationTypes.CITY:
            return "CITY";
        case LocationTypes.DISTRICT:
            return "DISTRICT";
        case LocationTypes.VOIVODESHIP:
            return "VOIVODESHIP";
        case LocationTypes.COUNTY:
            return "COUNTY";
        case LocationTypes.ESTATE:
            return "ESTATE";
        default:
            return null;
    }
}
