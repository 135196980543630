import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const SecurityIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "6.17mm"} height={props.height || "8.77mm"} viewBox="0 0 17.5 24.87">
            <path
                d="M14.75,9.37H3.5V6.75a5.25,5.25,0,1,1,10.5,0,.74.74,0,0,0,.75.74.77.77,0,0,0,.75-.77A6.75,6.75,0,0,0,2,6.75V9.48a2.75,2.75,0,0,0-2,2.64v10a2.75,2.75,0,0,0,2.75,2.75h12a2.75,2.75,0,0,0,2.75-2.75v-10A2.75,2.75,0,0,0,14.75,9.37ZM16,22.12a1.25,1.25,0,0,1-1.25,1.25h-12A1.25,1.25,0,0,1,1.5,22.12v-10a1.25,1.25,0,0,1,1.25-1.25h12A1.25,1.25,0,0,1,16,12.12Z"
                fill={props.mainColor || "#37474f"}
            />
            <circle cx="8.75" cy="17.12" r="3" fill={props.accentColor || "#8b6aac"} />
        </svg>
    );
};
