type Declinations = [string, string, string];

export const flatsDeclinations: Declinations = ["mieszkanie", "mieszkania", "mieszkań"];
export const studioDeclinations: Declinations = ["kawalerka", "kawalerki", "kawalerek"];
export const housesDeclinations: Declinations = ["domy", "domów", "domów"];
export const offersDeclinations: Declinations = ["oferta", "oferty", "ofert"];
export const offersDeclinationsWhich: Declinations = ["ofertę", "oferty", "ofert"];
export const beDeclinations: Declinations = ["jest", "są", "jest"];
export const availableDeclinations: Declinations = ["dostępna", "dostępne", "dostępne"];
export const investmentsDeclinations: Declinations = ["inwestycji", "inwestycjach", "inwestycjach"];
export const newDeclinations: Declinations = ["nowe", "nowych", "nowych"];
export const roomsDeclinations: Declinations = ["pokojowe", "pokojowe", "pokojowych"];
