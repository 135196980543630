import * as React from "react";
import {useRef, useState} from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css, Theme} from "@emotion/react";
import classNames from "classnames";
import {bindActionCreators, Dispatch} from "redux";
import {FormActions, FormFieldType} from "@web2/form2";

import {setLocalStorageLastSearch} from "../../app/actions/load_local_storage_last_search_to_store";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {OfferDealType, OfferType} from "../../offer/utils/constants_offer";
import {SearchTab} from "../../search/actions/fetch_search_all_action";
import {searchFormActions} from "../../search/actions/search_form_actions";
import {googlePlacesDivId} from "../../search/components/Search";
import {SearchAutocomplete} from "../../search/components/SearchAutocomplete";
import {createDynamicTabUrl} from "../../search/utils/create_dynamic_url";
import {searchFormFields} from "../../search/utils/form_types";
import {getSearchLocation} from "../../search/utils/getSearchLocation";
import {ISearchInputValue} from "../../search/utils/ISearchInputValue";

export const homepageSearchFormFields = {
    search: FormFieldType.Input
};

interface IActionProps {
    formActions: FormActions<Record<keyof typeof searchFormFields, any>>;
    setLocalStorageLastSearch: typeof setLocalStorageLastSearch;
}

type FormValuesType = Record<keyof typeof homepageSearchFormFields, any>;

interface IStateProps {
    formValues: FormValuesType;
}

interface IOwnProps extends RouteComponentProps<{}> {
    className?: string;
}

interface IProps extends IOwnProps, IStateProps, IActionProps {}

const HomepageSearchC = (props: IProps) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const searchRef = useRef<HTMLElement | null>(null);
    const searchRefHandle = (elem: HTMLElement | null) => {
        searchRef.current = elem;
    };

    const onSubmit = (formValues: FormValuesType) => {
        const searchValue = formValues["search"] as ISearchInputValue;
        props.setLocalStorageLastSearch(searchValue);

        const {location, subLocation, subSubLocation} = getSearchLocation(null, searchValue);

        if (searchValue.tabType === SearchTab.Places) {
            const url = getOfferListUrl({
                offerType: OfferType.property,
                type: "na-sprzedaz",
                geo_point: `${searchValue.location.lat}|${searchValue.location.lng}`,
                search_name: searchValue.label,
                distance: "1km"
            });

            return (window.location.href = decodeURIComponent(url));
        }

        if (searchValue.tabType === SearchTab.SearchSuggestions) {
            const transformedUrl = createDynamicTabUrl(searchValue.suggestions, location, subLocation, subSubLocation);
            return (window.location.href = decodeURIComponent(transformedUrl));
        }
    };

    const onAfterChange = (name?: string, value?: any) => {
        const currentValues = name ? {...props.formValues, [name]: value} : props.formValues;
        return onSubmit(currentValues);
    };

    const onSearchSubmit = () => onSubmit(props.formValues);

    const className = classNames(props.className);

    return (
        <div className={props.className}>
            <div css={searchWrapper} className={className} ref={searchRefHandle}>
                <div id={googlePlacesDivId} />

                <SearchAutocomplete
                    values={props.formValues}
                    onChange={props.formActions.update}
                    onAfterChange={onAfterChange}
                    onSubmit={onSearchSubmit}
                    onDropdownStatusChange={() => null}
                    wrapperRef={searchRef}
                    isSearchDropdownOpen={isDropdownOpen}
                    dealType={OfferDealType.SELL}
                    setSearchDropdownOpen={setDropdownOpen}
                    isHomepageSearch
                />
            </div>
        </div>
    );
};

const HomepageSearchConnected = connect(mapStateToProps, mapActionsToProps)(HomepageSearchC);
export const HomepageSearch = withRouter<IOwnProps, typeof HomepageSearchConnected>(HomepageSearchConnected);

function mapStateToProps(state: IStore): IStateProps {
    return {
        formValues: state.search.formValues
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionProps {
    return {
        formActions: bindActionCreators({...searchFormActions}, dispatch),
        ...bindActionCreators(
            {
                setLocalStorageLastSearch
            },
            dispatch
        )
    };
}

const searchWrapper = (theme: Theme) => css`
    display: block;
    max-width: 737px;
    min-width: 300px;
    width: 100%;
    margin: 2rem auto 0;

    input {
        font-size: 14px;
        padding: 1.9rem 5rem 1.8rem 2rem;
        height: unset;
        line-height: 1.64;
        border: 1px solid #cfd8dc;
        font-weight: 500;
        color: ${theme.colors.text_color};

        @media screen and (min-width: ${theme.breakpoints.screen_md}) {
            padding: 1.9rem 5rem 1.8rem 2rem;
            font-size: 1.8rem;
        }

        &::placeholder {
            color: ${theme.colors.gray_lighter};
        }

        &:active,
        &:focus {
            border-color: #d1c4e9;
        }
    }
`;
