import {filter, includes} from "lodash";
import {combineReducers} from "redux";

import {fetchFavouriteOfferListTypes} from "../../favourites/actions/fetch_favourite_offer_list";
import {IOfferBoxOffer} from "../../offer/detail/components/offer_box/OfferBox";
import {
    ILoadLocalStorageFavouritesToStore,
    ISetFavouriteOffer,
    LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE,
    SET_FAVOURITE_OFFER
} from "../actions/load_local_storage_favourites_to_store";
import {reduceReducers} from "../utils/reduce_reducers";
import {reduceRequestState, RequestState} from "../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../utils/request_response_utils/factories/reduce_response";

export interface IFavouritesStore {
    favourites: string[];
    favouriteOfferList: (IOfferBoxOffer & {coordinates: {lat: number; lng: number}})[];
    favouriteOfferListRequest: RequestState;
}

function localStorageFavouritesReducer(state: string[] = [], action: ILoadLocalStorageFavouritesToStore) {
    switch (action.type) {
        case LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE:
            return action.offers;
        default:
            return state;
    }
}

function customFavouritesReducer(state: string[] = [], action: ISetFavouriteOffer) {
    switch (action.type) {
        case SET_FAVOURITE_OFFER:
            return includes(state, action.offer) ? filter(state, (f) => f !== action.offer) : [...state, action.offer];
        default:
            return state;
    }
}

export const favouritesReducer = combineReducers({
    favourites: reduceReducers(localStorageFavouritesReducer, customFavouritesReducer),
    favouriteOfferList: reduceResponse(fetchFavouriteOfferListTypes),
    favouriteOfferListRequest: reduceRequestState(fetchFavouriteOfferListTypes)
});
