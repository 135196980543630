import {MultiPolygon} from "geojson";
import {Dispatch} from "redux";

import {redirectOrEnable404ResponseState} from "../../../app/actions/page_404_actions";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {notifyBugsnag} from "../../../app/utils/bugsnag/notify_bugsnag";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {catch404, catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {fetchLocationBySlug} from "../../actions/fetch_location_by_slug";
import {createLocationSlug} from "../../utils/create_location_slug";
import {validateLocationSlugs} from "../../utils/validate_location_slugs";
import {ISearchResultRouteParams} from "./fetch_offer_list_at_route";

export interface ILocation {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    name_declension_about: string;
    name_declension_what: string;
    name_declension_where: string;
    slug: string;
    short_name: string;
    full_name: string;
    path: {
        name: string;
        slug: string;
        type: string;
    }[];
    components: string[];
    location_type: LocationTypes;
    offer_count: ILocationOfferCount;
    outline?: MultiPolygon;
    parents: string[];
    children?: {
        name: string;
        slug: string;
    }[];
}

export interface ILocationOfferCount {
    sell: number;
    rent: number;
    house_sell: number;
    house_rent: number;
    apartment_sell: number;
    apartment_rent: number;
    lot_sell: number;
    lot_rent: number;
    apartment_one_room_sell: number;
    apartment_one_room_rent: number;
    apartment_two_rooms_sell: number;
    apartment_two_rooms_rent: number;
    house_one_room_sell: number;
    house_one_room_rent: number;
    house_two_rooms_sell: number;
    house_two_rooms_rent: number;
}

export interface ILocationShort {
    name: string;
    slug: string;
    type: string;
}

export const fetchLocationBySlugTypes = createRequestActionTypes({name: "locationBySlug", type: "GET", view: "offer_list"});

export const fetchLocationBySlugAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const {location, subLocation, subSubLocation} = route.params;

        // validate location slug
        const isLocationSlugValid = validateLocationSlugs(location, subLocation, subSubLocation);
        if (!isLocationSlugValid) {
            notifyBugsnag(
                {message: `InvalidLocationSlug`},
                `This is not really an error and this notification should probably be removed soon after validating if we don't see too many new errors`,
                {invalidUrl: route.url}
            );
            return dispatch(redirectOrEnable404ResponseState(services, route.pathname));
        }

        // fetch location data
        if (location) {
            const locationSlug = createLocationSlug(location, subLocation, subSubLocation);

            if (locationSlug === getState().offerList.location.location?.slug) {
                // prevent refetching the same location
                return Promise.resolve(getState().offerList.location.location);
            }

            return fetchLocationBySlug(
                services,
                locationSlug,
                fetchLocationBySlugTypes,
                "fetchLocationBySlugAtRoute"
            )(dispatch)
                .catch(
                    catch404(async () => {
                        await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                        return Promise.resolve(false);
                    })
                )
                .catch(catchAbortedError(() => false));
        }

        // We are in "Cała Polska" scenario or some other search - via map coordinates or Places
        dispatch({type: fetchLocationBySlugTypes.reset});
        return Promise.resolve(true);
    };
