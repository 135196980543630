import {Dispatch} from "redux";

import {IAuthorListResponse} from "../../app/interfaces/response/author_list";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export const fetchAuthorListActionTypes = createRequestActionTypes({name: "authorListAuthors", type: "GET", view: "AuthorList"});

export const fetchAuthorListAtRoute = (services: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    dispatch({type: fetchAuthorListActionTypes.start});
    const url = apiLink.articles.authors.base({})(null);

    return getRequest({}, url).then((response: IAuthorListResponse) => {
        const result = {
            count: response.count,
            next: response.next,
            page: response.page,
            page_size: response.page_size,
            previous: response.previous,
            authors: response.results
        };

        dispatch({type: fetchAuthorListActionTypes.success, result: result});
        return result;
    });
};
