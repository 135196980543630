import {Dispatch} from "react";
import {capitalizeFirst} from "@web2/nodash";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getMetaPage} from "../../meta_data/utils/get_meta_page";
import {fetchListDescription} from "../../offer/list/actions/fetch_list_description";
import {ISearchResultRouteParams} from "../../offer/list/actions/fetch_offer_list_at_route";

export const fetchDevelopersListSeoDescriptionTypes = createRequestActionTypes({
    name: "developersListDescription",
    type: "GET",
    view: "agency_list"
});

export const fetchDevelopersListSeoDescriptionAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch<any>, getState: () => IStore) => {
        dispatch({type: fetchDevelopersListSeoDescriptionTypes.start});
        const result = await fetchListDescription(services, route.pathname);
        const {page} = route.query;
        const metaPageNumber = getMetaPage(Number(page));

        const location = getState().offerList.location.location;
        const locationName = route.params.location && location ? ` ${capitalizeFirst(location.name)}` : "";
        const cityDeclinationWhat = route.params.location ? ` z ${getState().offerList.location.location?.name_declension_what}` : "";
        const metaTitle = `Deweloperzy${locationName} - Mapa strony${metaPageNumber}`;
        const metaDesc = `Mapa strony - Wszyscy deweloperzy${cityDeclinationWhat} w jednym miejscu${metaPageNumber} - gethome.pl`;

        const title = metaTitle;
        const description = metaDesc;
        const index = page > 1 ? "noindex" : "index";
        const robots = `${index}, follow`;
        dispatch(updateMetaData(title, description, route, {robots}));

        return dispatch({type: fetchDevelopersListSeoDescriptionTypes.success, result});
    };
