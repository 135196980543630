import * as React from "react";
import {useEffect, useState} from "react";
import {useTheme} from "@emotion/react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {filter, includes, isEmpty, isEqual, map} from "lodash";
import {Button} from "@web2/button";
import {IRange} from "@web2/form_fields";
import {form, IFormProps} from "@web2/form2";
import {FILTER_NUMBER_OF_ROOMS_TESTID, FILTER_PROPERTY_TYPE_TESTID, TRANSACTION_TYPE_TESTID} from "@web2/gh_page_object_models";
import {CloseButtonIcon, FilterIcon, NotificationBellOutlineIcon} from "@web2/icons";
import {Modal} from "@web2/modal";
import {nbsp, numberFormat, pluralize} from "@web2/string_utils";

import {NotificationSubscriptionButton} from "../../app/components/NotificationSubscriptionButton";
import useHash from "../../app/hooks/use_hash";
import {IFeaturesTypes, ISearchFeature} from "../../app/interfaces/response/server_list_response";
import {LocationTypes} from "../../app/utils/get_location_from_path";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {OfferListSortButton} from "../../offer/list/components/OfferListSortButton";
import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {OfferDealType} from "../../offer/utils/constants_offer";
import {getThemeBreakpoint, getThemeBreakpointCorrect, getThemeVariable} from "../../styles/linaria_variable_factory";
import {filterInteractionGTMEvent} from "../../tracking/google_tag_manager/filter_interaction";
import {filterStatsDefaults, IFilterStats} from "../actions/fetch_filter_stats";
import {SearchFormValuesType} from "../utils/form_types";
import {getAreaFilterLabel, getAvailableFromLabel, getDealTypeLabel, getPriceFilterLabel, getTypeLabel} from "../utils/get_labels";
import {getRoomLabel} from "../utils/room_field_data_translations";
import {TypeSearchFilterOptions} from "../utils/TypeSearchFilterOptions";
import {filterTitle} from "./atoms/atoms";
import {SearchTypeDot} from "./atoms/SearchTypeDot";
import {AvailableFromSelect} from "./filters/AvailableFromSelect";
import {CustomAvailableFromFilter} from "./filters/CustomAvailableFromFilter";
import {CustomFilter, ICustomFilterChildProps} from "./filters/CustomSearchFilter";
import {CustomMobileFilter, ICustomMobileFilterChildProps} from "./filters/CustomSearchFilterMobile";
import {DealTypeSelect} from "./filters/DealTypeSelect";
import {FeaturesFilter} from "./filters/FeaturesFilter";
import {FiltersRange} from "./filters/FiltersRange";
import {FilterStatsBoundsType, HistogramFilterFooter} from "./filters/HistogramFilterFooter";
import {ObsoleteRangeFilterWithHistogram} from "./filters/obsolete_range_filter/ObsoleteRangeFilterWithHistogram";
import {OfferTypeSwitch} from "./filters/OfferTypeSwitch";
import {RoomsSelectMobile} from "./filters/RoomSelectMobile";
import {RoomsSelect} from "./filters/RoomsSelect";
import {IFilterChildProps, RangeFilter} from "./filters/SearchFiltersFilter";
import {FilterMobile, IFilterChildMobileProps} from "./filters/SearchFiltersFilterMobile";
import {SearchFiltersFilterMore} from "./filters/SearchFiltersFilterMore";
import {FilterFooter} from "./filters/SearchFiltersFooter";
import {IndividualOffersFilter} from "./IndividualOffersFilter";
import {IActiveDropdown} from "./Search";

interface IOwnProps {
    setDesktopFilterBlendActive?: (isOpen: boolean) => void;
    onMobileCloseModal?: () => void;
    onMobileResetForm?: () => void;
    filterStats: IFilterStats;
    filterStatsRequest: RequestState;
    offerType: string;
    latestQuery: IOfferListQuery;
    locationType: LocationTypes | undefined;
    offersMeta: {
        features?: ISearchFeature[];
        total_offers_not_collapsed: number;
    } | null;
    filterState: IActiveDropdown;
    setFilterState: (state: IActiveDropdown) => void;
    mobileFiltersRefHandle: (elem: HTMLElement | null) => void;
    filterOnSubmit: (formValues: SearchFormValuesType) => void;
    onFilterCancel: () => void;
}

export interface ISearchFiltersFormValues {
    offer_type: TypeSearchFilterOptions[];
    deal_type: OfferDealType | undefined | [];
    rooms: number[];
    size: IRange<string>;
    price: IRange<string>;
    price_per_sqm: IRange<string>;
    available_from: IRange<string>;
    floor: IRange<string>;
    construction_year: IRange<string>;
    features: IFeaturesTypes[];
    search: string;
    is_private: boolean;
}

interface IProps extends IOwnProps, IFormProps<ISearchFiltersFormValues> {}

const SearchFiltersC = (props: IProps) => {
    /**
     * State
     */

    const [isMobile, setMobile] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [isNotificationsFormSubmitted, setIsNotificationsFormSubmitted] = useState(false);

    const theme = useTheme();
    const desktop = theme.breakpoints.numeric.screen_md || 1024;
    const tablet = theme.breakpoints.numeric.screen_sm || 768;

    useEffect(() => {
        setMobile(window.innerWidth < desktop);
        setTablet(window.innerWidth > tablet);
    }, []);

    /**
     * Mobile specific
     */

    const {isHashPresent, setHash} = useHash("filter");

    const closeMobileModal = () => {
        props.setFilterState(null);
        props.onMobileCloseModal && props.onMobileCloseModal();
        setHash(null);
    };

    const submitMobileModal = () => {
        const params = {
            ...props.values
        };
        props.filterOnSubmit(params);
        closeMobileModal();
        props.onAfterChange && props.onAfterChange();
    };

    /**
     * General
     */

    const createFilterOnOpen =
        (filter: IActiveDropdown, gtmEventName?: "type" | "size" | "price" | "square" | "rooms" | "more" | "market") => (isOpen: boolean) => {
            props.setFilterState(isOpen ? filter : null);
            !isMobile && props.setDesktopFilterBlendActive && props.setDesktopFilterBlendActive(isOpen);
            if (isOpen) {
                gtmEventName && filterInteractionGTMEvent(gtmEventName, isMobile, "opened");
            }
        };

    const onTypeOpen = createFilterOnOpen("type", "market");
    const onDealTypeOpen = createFilterOnOpen("deal_type", "type");
    const onRoomsOpen = createFilterOnOpen("rooms", "rooms");
    const onPriceOpen = createFilterOnOpen("price", "price");
    const onPricePerSqmOpen = createFilterOnOpen("price_per_sqm", "square");
    const onAreaOpen = createFilterOnOpen("area", "size");
    const onMoreOpen = createFilterOnOpen("more", "more");

    // Additional
    const onAvailableFromOpen = (isOpen: boolean) => {
        props.setFilterState(isOpen ? "available_from" : "more");
    };

    /**
     * Filter Stats
     */

    const average = (type: FilterStatsBoundsType) => {
        if (type === FilterStatsBoundsType.SIZE) {
            const {1: one_room, 2: two_rooms, 3: three_rooms, 4: four_rooms} = props.filterStats.avg_sizes_per_rooms;
            return {one_room: one_room || 0, two_rooms: two_rooms || 0, three_rooms: three_rooms || 0, four_rooms: four_rooms || 0};
        }
        if (type === FilterStatsBoundsType.PRICE) {
            const {1: one_room, 2: two_rooms, 3: three_rooms, 4: four_rooms} = props.filterStats.avg_prices_per_rooms;
            return {one_room: one_room || 0, two_rooms: two_rooms || 0, three_rooms: three_rooms || 0, four_rooms: four_rooms || 0};
        }
        if (type === FilterStatsBoundsType.PRICE_PER_SQM) {
            const {1: one_room, 2: two_rooms, 3: three_rooms, 4: four_rooms} = props.filterStats.avg_price_per_sqm_per_rooms;
            return {one_room: one_room || 0, two_rooms: two_rooms || 0, three_rooms: three_rooms || 0, four_rooms: four_rooms || 0};
        }
        return {one_room: 0, two_rooms: 0, three_rooms: 0, four_rooms: 0};
    };

    const boundsData = (type: FilterStatsBoundsType) => {
        if (type === FilterStatsBoundsType.SIZE) {
            return props.filterStats.size;
        }
        if (type === FilterStatsBoundsType.PRICE) {
            return props.filterStats.price;
        }
        if (type === FilterStatsBoundsType.PRICE_PER_SQM) {
            return props.filterStats.price_per_sqm;
        }
        return filterStatsDefaults.price;
    };
    const rangeIsNotEmpty = (range: {lower: string; upper: string}) => !isEmpty(range.lower) || !isEmpty(range.upper);

    /**
     * Render
     */
    const typeProps = props.getFieldProps("offer_type");
    const dealTypeProps = props.getFieldProps("deal_type");
    const roomsProps = props.getFieldProps("rooms");
    const sizeProps = props.getFieldProps("size");
    const priceProps = props.getFieldProps("price");
    const pricePerSqmProps = props.getFieldProps("price_per_sqm");
    //Additional
    const isPrivateProps = props.getFieldProps("is_private");
    const availableFromProps = props.getFieldProps("available_from");
    const floorProps = props.getFieldProps("floor");
    const constructionYearProps = props.getFieldProps("construction_year");
    const featuresProps = props.getFieldProps("features");

    const isLot = props.offerType === "lot";
    const isPrimaryMarket = !includes(props.offerType, "aftermarket");
    const includesHouses = includes(props.offerType, "house");
    const includesApartment = includes(props.offerType, "apartment");
    const isProperties = includesHouses && includesApartment;
    const isRent = props.latestQuery.deal_type === OfferDealType.RENT;

    const offersPluralize = pluralize(["oferta", "oferty", "oferty"]);
    const filterPluralize = pluralize(["filtr", "filtry", "filtrów"]);
    const countActiveAdditionalFilters = () => {
        const {floor, construction_year, available_from} = props.values;
        const [isFloorActive, isConstructionYearActive, isAvailableFromActive] = map([floor, construction_year, available_from], rangeIsNotEmpty);
        const isIsPrivateSelected = isPrivateProps.value;

        if (includesHouses && includesApartment) {
            if (!isPrimaryMarket) {
                return filter([isConstructionYearActive], Boolean).length + featuresProps.value.length;
            }
            return filter([isConstructionYearActive, isAvailableFromActive, isIsPrivateSelected], Boolean).length;
        }
        if (isLot) {
            return featuresProps.value.length + (isIsPrivateSelected ? 1 : 0);
        }
        if (!isPrimaryMarket) {
            if (includesHouses) {
                return filter([isConstructionYearActive, isIsPrivateSelected], Boolean).length + featuresProps.value.length;
            }
            return filter([isFloorActive, isConstructionYearActive, isIsPrivateSelected], Boolean).length + featuresProps.value.length;
        }

        if (includesHouses) {
            return filter([isConstructionYearActive, isAvailableFromActive], Boolean).length + featuresProps.value.length;
        }

        return filter([isFloorActive, isConstructionYearActive, isAvailableFromActive], Boolean).length + featuresProps.value.length;
    };
    const filterMoreLabel = () => {
        const filterCounter = countActiveAdditionalFilters();
        return filterCounter === 0 ? "Więcej" : `${filterCounter} ${filterPluralize(filterCounter)}`;
    };
    const areAdditionalFiltersActive = countActiveAdditionalFilters() > 0;

    const countActiveFilters = () => {
        const baseActiveFilters = 2;
        const {price, size, price_per_sqm} = props.values;
        const [isPriceActive, isSizeActive, isPricePerSqmActive] = map([price, size, price_per_sqm], rangeIsNotEmpty);

        return (
            baseActiveFilters +
            filter([isPriceActive, isSizeActive, isPricePerSqmActive], Boolean).length +
            countActiveAdditionalFilters() +
            (isEmpty(props.values.rooms) ? 0 : 1)
        );
    };

    const onClearAllAdditionalFilters = () => {
        const params = {
            ...props.values,
            available_from: {lower: "", upper: ""},
            construction_year: {lower: "", upper: ""},
            floor: {lower: "", upper: ""},
            features: [],
            is_private: ""
        };

        props.filterOnSubmit(params);
    };

    const onAdditionalFiltersClear = () => {
        if (props.latestQuery.floor__gte || props.latestQuery.floor__lte) {
            floorProps.onChange("floor", {lower: props.latestQuery.floor__gte || "", upper: props.latestQuery.floor__lte || ""});
        } else {
            floorProps.onChange("floor", {lower: "", upper: ""});
        }
        const latestPrice = {upper: props.latestQuery.price__lte || "", lower: props.latestQuery.price__gte || ""};
        if (!isEqual(latestPrice, props.latestQuery)) {
            priceProps.onChange("price", latestPrice);
        }
        if (props.latestQuery.construction_year__gte || props.latestQuery.construction_year__lte) {
            constructionYearProps.onChange("construction_year", {
                lower: props.latestQuery.construction_year__gte || "",
                upper: props.latestQuery.construction_year__lte || ""
            });
        } else {
            constructionYearProps.onChange("construction_year", {lower: "", upper: ""});
        }
        if (props.latestQuery.available_from__lte) {
            availableFromProps.onChange("available_from", {lower: "", upper: props.latestQuery.available_from__lte || ""});
        } else {
            availableFromProps.onChange("available_from", {lower: "", upper: ""});
        }
        if (props.latestQuery.features) {
            const currenFeatures = props.latestQuery.features.replace(/\[|\]/g, "").split(",");
            featuresProps.onChange("features", currenFeatures as IFeaturesTypes[]);
        } else {
            featuresProps.onChange("features", []);
        }
        onMoreOpen(false);
    };

    const onPricePerSqmClear = () => {
        if (props.latestQuery.price_per_sqm__gte || props.latestQuery.price_per_sqm__lte) {
            pricePerSqmProps.onChange("price_per_sqm", {
                lower: props.latestQuery.price_per_sqm__gte || "",
                upper: props.latestQuery.price_per_sqm__lte || ""
            });
        } else {
            pricePerSqmProps.onChange("price_per_sqm", {lower: "", upper: ""});
        }
        onPricePerSqmOpen(false);
    };

    const onSizeClear = () => {
        if (props.latestQuery.size__gte || props.latestQuery.size__lte) {
            sizeProps.onChange("size", {
                lower: props.latestQuery.size__gte || "",
                upper: props.latestQuery.size__lte || ""
            });
        } else {
            sizeProps.onChange("size", {lower: "", upper: ""});
        }
        onAreaOpen(false);
    };

    const onPriceClear = () => {
        if (props.latestQuery.price__gte || props.latestQuery.price__lte) {
            priceProps.onChange("price", {
                lower: props.latestQuery.price__gte || "",
                upper: props.latestQuery.price__lte || ""
            });
        } else {
            priceProps.onChange("price", {lower: "", upper: ""});
        }
        onPriceOpen(false);
    };

    const onRoomsClear = () => {
        if (props.latestQuery.rooms) {
            roomsProps.onChange(
                "rooms",
                map(props.latestQuery.rooms.split(","), (x) => +x)
            );
        } else {
            roomsProps.onChange("rooms", []);
        }
        onRoomsOpen(false);
    };

    const onTypeClear = () => {
        typeProps.onChange("offer_type", props.latestQuery.offer_type.split(",") as any);
        onTypeOpen(false);
    };

    const areLocationStatsLoading = props.filterStatsRequest === RequestState.Waiting;

    const totalOffers = props.offersMeta?.total_offers_not_collapsed || 0;

    return (
        <>
            <CustomFilter
                label={"Rodzaj"}
                id={"typeFilter"}
                {...typeProps}
                getLabel={getTypeLabel}
                labelId="type-filter-field"
                errorOnBottom={true}
                isDropdownOpened={props.filterState === "type"}
                setDropdownOpen={onTypeOpen}
                isMobileWidth={false}
                dealType={dealTypeProps.value}
                isCustomStyled
                onFilterCancel={props.onFilterCancel}
                testId={FILTER_PROPERTY_TYPE_TESTID.PROPERTY_TYPE}
            >
                {(props: ICustomFilterChildProps<TypeSearchFilterOptions[]>) => (
                    <>
                        <OfferTypeSwitch {...props} />

                        <div className={filterFooterHolder}>
                            <FilterFooter
                                onCancel={onTypeClear}
                                onSave={() => {
                                    props.onChange(props.name, props.value);
                                    props.onAfterChange(props.name, props.value);
                                    onTypeOpen(false);
                                }}
                            />
                        </div>
                    </>
                )}
            </CustomFilter>

            {!isLot && !isProperties && (
                <CustomFilter
                    label={`Na sprzedaż i${nbsp}wynajem`}
                    id="dealTypeFilter"
                    {...dealTypeProps}
                    getLabel={getDealTypeLabel}
                    labelId="deal-type-filter-field"
                    errorOnBottom={true}
                    isDropdownOpened={props.filterState === "deal_type"}
                    setDropdownOpen={onDealTypeOpen}
                    isMobileWidth={false}
                    isCustomStyled
                    offerType={props.offerType}
                    onFilterCancel={props.onFilterCancel}
                    testId={TRANSACTION_TYPE_TESTID.TRANSACTION_TYPE}
                >
                    {(props: ICustomFilterChildProps<OfferDealType | undefined | []>) => <DealTypeSelect {...props} />}
                </CustomFilter>
            )}

            {!isLot && (
                <CustomFilter
                    label={"Pokoje"}
                    id={"roomsFilter"}
                    {...roomsProps}
                    getLabel={getRoomLabel}
                    labelId="rooms-filter-field"
                    errorOnBottom={true}
                    isDropdownOpened={props.filterState === "rooms"}
                    setDropdownOpen={onRoomsOpen}
                    isMobileWidth={false}
                    showClearIcon
                    onFilterCancel={props.onFilterCancel}
                    testId={FILTER_NUMBER_OF_ROOMS_TESTID.DROPDOWN_FILTER_ROOMS}
                >
                    {(props: ICustomFilterChildProps<any>) => (
                        <>
                            <RoomsSelect {...props} />

                            <div className={filterFooterHolder}>
                                <FilterFooter
                                    onCancel={onRoomsClear}
                                    onSave={() => {
                                        props.onChange(props.name, props.value);
                                        props.onAfterChange(props.name, props.value);
                                        onRoomsOpen(false);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </CustomFilter>
            )}

            <RangeFilter
                label={"Cena"}
                id={"priceFilter"}
                {...priceProps}
                onChange={priceProps.onChange}
                onAfterChange={priceProps.onAfterChange}
                getLabel={getPriceFilterLabel}
                isDropdownOpened={props.filterState === "price"}
                toggleDropdown={onPriceOpen}
                isMobileWidth={isMobile}
                latestQuery={props.latestQuery}
                rightDropdown
                onFilterCancel={props.onFilterCancel}
            >
                {(props: IFilterChildProps<string>) => (
                    <div className={filtersFilterBodyHolder}>
                        <ObsoleteRangeFilterWithHistogram
                            {...props}
                            name={"price"}
                            onChange={props.onChange}
                            onAfterChange={props.onAfterChange}
                            title="Cena"
                            params={props.latestQuery}
                            filterBounds={boundsData(FilterStatsBoundsType.PRICE)}
                            isLoading={areLocationStatsLoading}
                        />

                        {!isMobile && (
                            <HistogramFilterFooter
                                onCancel={onPriceClear}
                                onSave={() => {
                                    props.onChange(props.name, props.value);
                                    props.onAfterChange(props.name, props.value);
                                    props.toggleDropdown();
                                }}
                                type={FilterStatsBoundsType.PRICE}
                                average={average(FilterStatsBoundsType.PRICE)}
                                filterBounds={boundsData(FilterStatsBoundsType.PRICE)}
                            />
                        )}
                    </div>
                )}
            </RangeFilter>
            {!isRent && (
                <RangeFilter
                    label={"Cena za m2"}
                    labelSuffix={"za m2"}
                    id={"pricePerSqmFilter"}
                    {...pricePerSqmProps}
                    onChange={pricePerSqmProps.onChange}
                    onAfterChange={pricePerSqmProps.onAfterChange}
                    getLabel={getPriceFilterLabel}
                    isDropdownOpened={props.filterState === "price_per_sqm"}
                    toggleDropdown={onPricePerSqmOpen}
                    isMobileWidth={isMobile}
                    latestQuery={props.latestQuery}
                    rightDropdown
                    onFilterCancel={props.onFilterCancel}
                >
                    {(props: IFilterChildProps<string>) => (
                        <div className={filtersFilterBodyHolder}>
                            <ObsoleteRangeFilterWithHistogram
                                {...props}
                                name={"price_per_sqm"}
                                onChange={props.onChange}
                                onAfterChange={props.onAfterChange}
                                title="Cena za m2"
                                params={props.latestQuery}
                                filterBounds={boundsData(FilterStatsBoundsType.PRICE_PER_SQM)}
                                isLoading={areLocationStatsLoading}
                            />

                            {!isMobile && (
                                <HistogramFilterFooter
                                    onCancel={onPricePerSqmClear}
                                    onSave={() => {
                                        props.onChange(props.name, props.value);
                                        props.onAfterChange(props.name, props.value);
                                        props.toggleDropdown();
                                    }}
                                    type={FilterStatsBoundsType.PRICE_PER_SQM}
                                    average={average(FilterStatsBoundsType.PRICE_PER_SQM)}
                                    filterBounds={boundsData(FilterStatsBoundsType.PRICE_PER_SQM)}
                                />
                            )}
                        </div>
                    )}
                </RangeFilter>
            )}

            <RangeFilter
                label={"Powierzchnia"}
                errorOnBottom={true}
                id={"sizeFilter"}
                {...sizeProps}
                onChange={sizeProps.onChange}
                onAfterChange={sizeProps.onAfterChange}
                getLabel={getAreaFilterLabel}
                isDropdownOpened={props.filterState === "area"}
                toggleDropdown={onAreaOpen}
                isMobileWidth={isMobile}
                rightDropdown
                latestQuery={props.latestQuery}
                onFilterCancel={props.onFilterCancel}
            >
                {(props: IFilterChildProps<string>) => (
                    <div className={filtersFilterBodyHolder}>
                        <ObsoleteRangeFilterWithHistogram
                            {...props}
                            name={"size"}
                            onChange={props.onChange}
                            onAfterChange={props.onAfterChange}
                            title="Powierzchnia"
                            params={props.latestQuery}
                            filterBounds={boundsData(FilterStatsBoundsType.SIZE)}
                            isLoading={areLocationStatsLoading}
                        />

                        {!isMobile && (
                            <HistogramFilterFooter
                                onCancel={onSizeClear}
                                onSave={() => {
                                    props.onChange(props.name, props.value);
                                    props.onAfterChange(props.name, props.value);
                                    props.toggleDropdown();
                                }}
                                type={FilterStatsBoundsType.SIZE}
                                average={average(FilterStatsBoundsType.SIZE)}
                                filterBounds={boundsData(FilterStatsBoundsType.SIZE)}
                            />
                        )}
                    </div>
                )}
            </RangeFilter>

            <SearchFiltersFilterMore
                toggleDropdown={onMoreOpen}
                label={filterMoreLabel()}
                isDropdownOpened={!!["more", "available_from", "features"].find((el) => el === props.filterState)}
                hasValue={areAdditionalFiltersActive}
                rightDropdown
                isMobileWidth={isMobile}
                onClear={onClearAllAdditionalFilters}
            >
                <div className={filtersFilterBodyHolder}>
                    <div className={moreFiltersHeader}>Więcej filtrów</div>

                    {!includesHouses && !isLot && (
                        <FiltersRange
                            name={"floor"}
                            id={"floorFilter"}
                            onAfterChange={() => null}
                            onChange={floorProps.onChange}
                            value={floorProps.value}
                            title="Piętro"
                            clearOnFocus
                        />
                    )}
                    {!isLot && (
                        <FiltersRange
                            name={"construction_year"}
                            id={"constructionYearFilter"}
                            onAfterChange={() => null}
                            onChange={constructionYearProps.onChange}
                            value={constructionYearProps.value}
                            title="Rok budowy"
                            clearOnFocus
                        />
                    )}
                    {isPrimaryMarket && !isLot && (
                        <>
                            <div className={filterHeaderHolder}>
                                <p className={filterTitle}>Termin oddania</p>

                                <SearchTypeDot type="primary" />
                            </div>

                            <RangeFilter
                                label={"Termin oddania"}
                                id={"availableFromFilter"}
                                {...availableFromProps}
                                onAfterChange={() => null}
                                getLabel={getAvailableFromLabel}
                                labelId="available-from-filter-field"
                                errorOnBottom={true}
                                isDropdownOpened={props.filterState === "available_from"}
                                toggleDropdown={onAvailableFromOpen}
                                isMobileWidth={false}
                                onFilterCancel={props.onFilterCancel}
                            >
                                {(props: IFilterChildProps<string>) => <AvailableFromSelect {...props} />}
                            </RangeFilter>
                        </>
                    )}
                    <FeaturesFilter {...featuresProps} offerType={props.offerType} offersMeta={props.offersMeta} />

                    <div className={divider} />

                    {(isLot || !isPrimaryMarket) && <IndividualOffersFilter {...isPrivateProps} />}

                    <FilterFooter
                        onCancel={onAdditionalFiltersClear}
                        onSave={() => {
                            onMoreOpen(false);
                            props.onAfterChange && props.onAfterChange();
                        }}
                    />
                </div>
            </SearchFiltersFilterMore>

            <div className={mobileFiltersHolderWrapper}>
                <div className={mobileFiltersHolder}>
                    <div className={mobileFilterButtonsWrapper}>
                        <Button
                            className={cx(mobileFiltersButtonStyle, buttonWrapperStyle)}
                            variant="dark"
                            inverted
                            fullWidth
                            onClick={() => {
                                setHash("filter");
                            }}
                            icon={<FilterIcon size="2" fillColor={theme.colors.gray_darker} />}
                        >
                            <span className={filtersButtonContentWrapper}>
                                Filtruj <span className={filterCountStyle}>{countActiveFilters()}</span>
                            </span>
                        </Button>
                        <OfferListSortButton
                            className={buttonWrapperStyle}
                            textWrapperClassName={cx(filtersButtonContentWrapper, sortContentWrapper)}
                            latestQuerySort={props.latestQuery.sort}
                            useShortDisplayNames
                            fullWidth
                        />
                        <NotificationSubscriptionButton
                            className={cx(mobileFiltersButtonStyle, notificationsButtonStyle)}
                            variant="primary"
                            inverted
                            icon={<NotificationBellOutlineIcon size="2" fillColor={theme.colors.gray_darker} />}
                            latestQuery={props.latestQuery}
                            isSubmitted={isNotificationsFormSubmitted}
                            setIsSubmitted={setIsNotificationsFormSubmitted}
                        />
                    </div>

                    <MobileFiltersSidebar modalState={isHashPresent} type={isTablet ? "window" : "full"} forceScrollOnClose>
                        <div
                            className={closeButtonHolder}
                            onClick={() => {
                                setHash(null);
                                props.onFilterCancel();
                            }}
                        >
                            <CloseButtonIcon mainColor="#9069c0" width={9} height={9} />
                        </div>
                        <div className={mobileFiltersModalBodyHolder} ref={props.mobileFiltersRefHandle}>
                            {!isLot && !isProperties && (
                                <CustomMobileFilter id={"dealTypeFilter"} {...dealTypeProps} offerType={props.offerType} errorOnBottom={true}>
                                    {(props: ICustomMobileFilterChildProps<OfferDealType | undefined | []>) => <DealTypeSelect isMobile {...props} />}
                                </CustomMobileFilter>
                            )}

                            <div className={divider} />

                            <FilterTitle>Rodzaj rynku i nieruchomości</FilterTitle>

                            <CustomFilter
                                label={"Rodzaj"}
                                id={"typeFilter"}
                                {...typeProps}
                                getLabel={getTypeLabel}
                                labelId="type-filter-field"
                                errorOnBottom={true}
                                isDropdownOpened={props.filterState === "type"}
                                setDropdownOpen={onTypeOpen}
                                isMobileWidth={false}
                                dealType={dealTypeProps.value}
                                showOnMobile
                                showLabelOnMobile
                                onFilterCancel={props.onFilterCancel}
                                testId={TRANSACTION_TYPE_TESTID.TRANSACTION_TYPE}
                            >
                                {(props: ICustomFilterChildProps<TypeSearchFilterOptions[]>) => (
                                    <>
                                        <OfferTypeSwitch {...props} />

                                        <div className={filterFooterHolder}>
                                            <FilterFooter
                                                onCancel={onTypeClear}
                                                onSave={() => {
                                                    props.onChange(props.name, props.value);
                                                    onTypeOpen(false);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </CustomFilter>

                            <div className={divider} />

                            <FilterTitle>Liczba pokoi</FilterTitle>

                            <CustomMobileFilter id={"roomsFilter"} {...roomsProps} errorOnBottom={true}>
                                {(props: ICustomMobileFilterChildProps<number[]>) => <RoomsSelectMobile {...props} />}
                            </CustomMobileFilter>

                            <div className={divider} />

                            <FilterTitle>Cena</FilterTitle>

                            <FilterMobile
                                label={"Cena"}
                                {...priceProps}
                                onChange={priceProps.onChange}
                                onAfterChange={priceProps.onAfterChange}
                                latestQuery={props.latestQuery}
                                roundTo={50}
                            >
                                {(props: IFilterChildMobileProps<string>) => (
                                    <ObsoleteRangeFilterWithHistogram
                                        {...props}
                                        name={"price"}
                                        onChange={props.onChange}
                                        onAfterChange={props.onAfterChange}
                                        params={props.latestQuery}
                                        filterBounds={boundsData(FilterStatsBoundsType.PRICE)}
                                        isLoading={areLocationStatsLoading}
                                    />
                                )}
                            </FilterMobile>

                            {!isRent && (
                                <>
                                    <div className={divider} />

                                    <FilterTitle>Cena za m2</FilterTitle>

                                    <FilterMobile
                                        label={"Cena za m2"}
                                        {...pricePerSqmProps}
                                        onChange={pricePerSqmProps.onChange}
                                        onAfterChange={pricePerSqmProps.onAfterChange}
                                        latestQuery={props.latestQuery}
                                        roundTo={50}
                                    >
                                        {(props: IFilterChildMobileProps<string>) => (
                                            <ObsoleteRangeFilterWithHistogram
                                                {...props}
                                                name={"price_per_sqm"}
                                                onChange={props.onChange}
                                                onAfterChange={props.onAfterChange}
                                                params={props.latestQuery}
                                                filterBounds={boundsData(FilterStatsBoundsType.PRICE_PER_SQM)}
                                                isLoading={areLocationStatsLoading}
                                            />
                                        )}
                                    </FilterMobile>
                                </>
                            )}

                            <div className={divider} />

                            <FilterTitle>Powierzchnia</FilterTitle>

                            <FilterMobile
                                label={"Powierzchnia"}
                                errorOnBottom={true}
                                {...sizeProps}
                                onChange={sizeProps.onChange}
                                onAfterChange={sizeProps.onAfterChange}
                                latestQuery={props.latestQuery}
                            >
                                {(props: IFilterChildMobileProps<string>) => (
                                    <ObsoleteRangeFilterWithHistogram
                                        {...props}
                                        name={"size"}
                                        onChange={props.onChange}
                                        onAfterChange={props.onAfterChange}
                                        params={props.latestQuery}
                                        filterBounds={boundsData(FilterStatsBoundsType.SIZE)}
                                        isLoading={areLocationStatsLoading}
                                    />
                                )}
                            </FilterMobile>

                            {!isLot && !includesHouses && (
                                <>
                                    <div className={divider} />

                                    <FilterTitle>Piętro</FilterTitle>

                                    <FiltersRange
                                        name={"floor"}
                                        id={"floorFilter"}
                                        onAfterChange={() => null}
                                        onChange={floorProps.onChange}
                                        value={floorProps.value}
                                        enableOnBlur
                                        clearOnFocus
                                    />
                                </>
                            )}

                            {!isLot && (
                                <>
                                    <div className={divider} />
                                    <FilterTitle>Rok budowy</FilterTitle>

                                    <FiltersRange
                                        name={"construction_year"}
                                        id={"constructionYearFilter"}
                                        onAfterChange={() => null}
                                        onChange={constructionYearProps.onChange}
                                        value={constructionYearProps.value}
                                        enableOnBlur
                                        clearOnFocus
                                    />
                                </>
                            )}

                            {!isLot && isPrimaryMarket && (
                                <>
                                    <div className={divider} />

                                    <div className={filterHeaderHolder}>
                                        <p className={filterTitle}>Termin oddania</p>

                                        <SearchTypeDot type="primary" />
                                    </div>

                                    <CustomAvailableFromFilter
                                        label={"Dostępne od"}
                                        id={"availableFromFilter"}
                                        {...availableFromProps}
                                        onAfterChange={() => null}
                                        onChange={availableFromProps.onChange}
                                        getLabel={getAvailableFromLabel as any}
                                        labelId="available-from-filter-field"
                                        errorOnBottom={true}
                                        isDropdownOpened={props.filterState === "available_from"}
                                        setDropdownOpen={onAvailableFromOpen}
                                        isMobileWidth={false}
                                        showClearIcon
                                        showOnMobile
                                        showLabelOnMobile
                                    >
                                        {(props: IFilterChildProps<string>) => <AvailableFromSelect {...props} />}
                                    </CustomAvailableFromFilter>
                                </>
                            )}

                            <FeaturesFilter {...featuresProps} offerType={props.offerType} offersMeta={props.offersMeta} />

                            <div className={divider} />

                            {(isLot || !isPrimaryMarket) && <IndividualOffersFilter {...isPrivateProps} />}
                        </div>

                        <div className={buttonWrapper}>
                            <Button variant="primary" size="lg" fullWidth onClick={submitMobileModal}>
                                {totalOffers > 0 ? (
                                    <>
                                        Szukaj
                                        <span style={{display: "block", fontSize: "12px"}}>
                                            ({numberFormat(totalOffers)} {offersPluralize(totalOffers)})
                                        </span>
                                    </>
                                ) : (
                                    "Brak ofert"
                                )}
                            </Button>
                        </div>
                    </MobileFiltersSidebar>
                </div>
            </div>
        </>
    );
};

export const SearchFilters = form<ISearchFiltersFormValues, IProps>(SearchFiltersC);

const filterFooterHolder = css`
    padding: 1rem 1.5rem;
`;

const filtersFilterBodyHolder = css`
    padding: 1rem 1.5rem;
    width: 430px;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        overflow-y: auto;
        max-height: calc(100vh - 168px); // 168px is height of heder and filter bar plus 10px for margin from browser bottom line
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        max-height: calc(100vh - 118px); // 118px is height of heder and filter bar plus 10px for margin from browser bottom line
    }
`;

const mobileFiltersHolder = css`
    flex: 1;
    max-width: 100%;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: none;
    }
`;

const MobileFiltersSidebar = styled(Modal)`
    right: 0;
    height: 100% !important;
    animation: slide-in-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @keyframes slide-in-right {
        0% {
            transform: translateX(350px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
`;

const mobileFiltersModalBodyHolder = css`
    padding: 4.5rem 2rem 2.4rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 70px);
`;

const divider = css`
    display: block;
    width: 100%;
    height: 1px;
    margin: 1.5rem 0;
    border: 0;
    background-color: ${getThemeVariable("colors-gray_light")};
`;

const FilterTitle = styled.p`
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.66;
    color: ${getThemeVariable("colors-gray_dark")};
`;

const buttonWrapper = css`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.3);
    z-index: 15;

    @media screen and (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
`;

const mobileFiltersHolderWrapper = css`
    display: flex;
    width: 100%;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: none;
    }
`;

const moreFiltersHeader = css`
    padding: 2rem 0;
    border-bottom: 1px solid ${getThemeVariable("colors-gray_brighter")};
    margin-bottom: 2rem;
    font-size: 1.6rem;
`;

const filterHeaderHolder = css`
    display: flex;
    align-items: center;

    > p {
        margin-right: 1rem;
    }
`;
const closeButtonHolder = css`
    position: absolute;
    z-index: 8001;
    left: 0;
    top: 0;
    padding: 1rem 0;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
    background: ${getThemeVariable("colors-body_bg")};
    width: calc(100% - 2rem);

    svg {
        width: 15px;
        height: 15px;
    }
`;

// mobile filters + search
const mobileFilterButtonsWrapper = css`
    display: grid;
    grid-template-columns: 1fr 1fr 40px;
    grid-gap: 1rem;
    justify-content: space-between;
    max-width: 100%;
`;

const buttonWrapperStyle = css`
    flex: 1 1 auto;
`;

const mobileFiltersButtonStyle = css`
    flex: 1 1 50%;
    height: 3.8rem;
    font-weight: 400;
`;

const notificationsButtonStyle = css`
    border-color: ${getThemeVariable("colors-gray_light")};
    max-width: 4rem;

    & > svg {
        margin-right: 0;
    }
`;

const filterCountStyle = css`
    border-radius: 50%;
    color: #fff;
    background-color: ${getThemeVariable("colors-gray_darker")};
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-left: 0.5rem;
    align-self: center;
`;

export const filtersButtonContentWrapper = css`
    flex-grow: 2;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.5rem;
`;

export const sortContentWrapper = css`
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`;
