import {isEmpty} from "lodash";
import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IAgency} from "./fetch_agency_detail_at_route";

export const updateAgencyDetailMetaData =
    (_1: Partial<IServices>, route: IRouteState<{agencySlug: string}>, prevRoute: IPrevRouteState | null, prevActionResult: IAgency) =>
    (dispatch: Dispatch) => {
        const url = `${ghCommonUrl}${appLink.agency.detail({agencySlug: route.params.agencySlug})}`;
        const shouldIndex = !isEmpty(prevActionResult.about) || prevActionResult.offer_summary.offer_count > 0;

        const city =
            prevActionResult.about_data.agency_address && prevActionResult.about_data.agency_address.city && prevActionResult.about_data.agency_address.city;

        const description = `${prevActionResult.name} ${
            city ?? ""
        } to profesjonalne biuro nieruchomości. Poznaj najlepsze oferty i znajdź idealną nieruchomość dla siebie na GetHome.pl!`;
        const robots = shouldIndex ? "index,follow" : "noindex,follow";
        const title = `${prevActionResult.name} ${city ?? ""}`;

        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                hideSuffix: false
            })
        );

        return Promise.resolve(true);
    };
