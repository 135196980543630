import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const VirtualTourIcon = (props: IProps) => {
    return (
        <svg
            className={classNames(props.className, "svg-icon")}
            viewBox="0 0 26 13.32"
            width={props.width || 26}
            height={props.height || 13}
            fill={props.mainColor || "#fff"}
        >
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <g id="Layer_2-2-2" data-name="Layer 2-2">
                            <path
                                className="cls-1"
                                d="M9.86,6.54a3.48,3.48,0,0,1,.86,2.4,3.87,3.87,0,0,1-.62,2.14,4.36,4.36,0,0,1-1.91,1.59,7.18,7.18,0,0,1-3.13.65A10.84,10.84,0,0,1,2.34,13,8.2,8.2,0,0,1,0,12L1.34,9.22a5.92,5.92,0,0,0,1.73.8A7.22,7.22,0,0,0,5,10.3,2.82,2.82,0,0,0,6.45,10a1.14,1.14,0,0,0,.56-1c0-.84-.67-1.27-2-1.27H3.47V5.36L5.8,2.85H.68V0h9.39V2.31L7.38,5.21A4.27,4.27,0,0,1,9.86,6.54Z"
                            />
                            <path
                                className="cls-1"
                                d="M12.58,0h6.15a8.67,8.67,0,0,1,3.81.8,6.15,6.15,0,0,1,2.55,2.27A6.44,6.44,0,0,1,26,6.52,6.41,6.41,0,0,1,25.09,10a6,6,0,0,1-2.55,2.25,8.58,8.58,0,0,1-3.79.8H12.6Zm6,10.07a3.8,3.8,0,0,0,2.7-.94,3.46,3.46,0,0,0,1-2.62,3.49,3.49,0,0,0-1-2.63,3.8,3.8,0,0,0-2.7-.94H16.26v7.17Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
