import {css} from "@linaria/core";
import {numberWithDelimiter} from "@web2/string_utils";

import {getThemeBreakpoint, getThemeVariable} from "../../styles/linaria_variable_factory";

interface IProps {
    isInvestment: boolean;
    price?: {
        total: number | null;
    };
}

export const MultileadPrice = (props: IProps) => {
    const {isInvestment, price} = props;

    if (!isInvestment && price?.total) {
        return <div className={offerPrice}>{numberWithDelimiter(price.total)} zł</div>;
    }
    if (isInvestment && price?.total) {
        return (
            <div className={offerPrice}>
                {numberWithDelimiter(price.total)} zł - {numberWithDelimiter(Number(Number(price.total) * 1.1).toFixed())} zł
            </div>
        );
    }
    return <div>Cena u dewelopera</div>;
};

const offerPrice = css`
    display: block;
    padding: 1rem 0;
    text-align: center;
    background-color: ${getThemeVariable("colors-brand_primary")};
    width: 23.5rem;
    color: ${getThemeVariable("colors-gray_warmer")};
    float: right;

    @media (max-width: ${getThemeBreakpoint().screen_md}) {
        width: auto;
        max-width: 23.5rem;
        float: none;
        margin-top: 2rem;
    }
`;
