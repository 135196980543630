import styled from "@emotion/styled";
import {Modal} from "@web2/modal2";

import {ModalSimilarOffers} from "../../app/components/ModalSimilarOffers";
import {ISimilarOffersFetch} from "../actions/fetch_multilead_offers";

export interface IInactiveOfferModalHolder {
    hasNoSimilarOffers?: boolean;
    investment?: boolean;
}

interface IProps {
    modalState: boolean;
    similarOffers: ISimilarOffersFetch[] | null;
    onModalClose: (e?: React.MouseEvent) => void;
    offerId: string | undefined;
    hasNoSimilarOffers: boolean;
    investment?: boolean;
}

export const InactiveOfferModal = (props: IProps) => {
    const {modalState, onModalClose, similarOffers, offerId, hasNoSimilarOffers, investment} = props;

    return (
        <Modal modalState={modalState} type="full" onModalClose={onModalClose} shouldCloseOnOverlayClick={true}>
            <InactiveModalHolder hasNoSimilarOffers={hasNoSimilarOffers} investment={investment}>
                <ModalSimilarOffers similarOffers={similarOffers || []} offerId={offerId} closeModal={onModalClose} inactiveOffer={true} />
            </InactiveModalHolder>
        </Modal>
    );
};

// Investment param is introduced to adapt styling for inactive offer from /inwestycja/investment-slug/

export const InactiveModalHolder = styled.div<IInactiveOfferModalHolder>`
    overflow: ${(props) => (!props.hasNoSimilarOffers ? (props.investment ? "hidden" : "auto") : "hidden")};
    height: auto;
    padding: ${(props) => (!props.hasNoSimilarOffers ? (props.investment ? "2rem 3.5rem" : "2rem 2.5rem") : "2rem 3.5rem")};

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: ${(props) => (!props.hasNoSimilarOffers ? "auto" : "81rem")};
        height: ${(props) => (!props.hasNoSimilarOffers ? (props.investment ? "10rem" : "auto") : "10rem")};
        padding: ${(props) => (!props.hasNoSimilarOffers ? "2rem 2.5rem" : "2rem 2.5rem")};
    }
`;
