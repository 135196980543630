import {isEqual, omit} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catchAbortedError} from "../../app/utils/request_response_utils/response_error";
import {ISearchResultRouteParams} from "../../offer/list/actions/fetch_offer_list_at_route";
import {getOfferListFetchParams} from "../../offer/utils/get_offer_list_fetch_params";

export interface IFilterStats {
    avg_prices_per_rooms: {
        1?: number;
        2?: number;
        3?: number;
        4?: number;
    };
    avg_sizes_per_rooms: {
        1?: number;
        2?: number;
        3?: number;
        4?: number;
    };
    avg_price_per_sqm_per_rooms: {
        1?: number;
        2?: number;
        3?: number;
        4?: number;
        5?: number;
    };
    price: IBoundStats;
    size: IBoundStats;
    price_per_sqm: IBoundStats;
    avg_price: number;
    avg_price_per_sqm: number;
}

export interface IBoundStats {
    lower_bound: number;
    upper_bound: number;
}

export const filterStatsDefaults: IFilterStats = {
    avg_prices_per_rooms: {1: 0, 2: 0, 3: 0, 4: 0},
    avg_sizes_per_rooms: {1: 0, 2: 0, 3: 0, 4: 0},
    avg_price_per_sqm_per_rooms: {1: 0, 2: 0, 3: 0, 4: 0},
    price: {
        lower_bound: 0,
        upper_bound: 0
    },
    size: {
        lower_bound: 0,
        upper_bound: 0
    },
    price_per_sqm: {
        lower_bound: 0,
        upper_bound: 0
    },
    avg_price: 0,
    avg_price_per_sqm: 0
};

export const fetchFilterStatsTypes = createRequestActionTypes({name: "filterStats", type: "GET", view: "offer_list"});

export const fetchFilterStatsAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => (dispatch: Dispatch, getState: () => IStore) => {
        const location = getState().offerList.location.location;
        const params = getOfferListFetchParams(route, location, getState().search.formValues.offer_type);

        if (isEqual(omit(params, "page"), omit(getState().search.filterStatsLatestQuery, "page"))) {
            return Promise.resolve(getState().search.filterStats);
        }

        const url = appendQueryString(apiLink.offerList.stats({})(null), params as any);

        dispatch({type: fetchFilterStatsTypes.start, latestQuery: params});
        return getRequest(services, url, "fetchFilterStatsAtRoute")
            .then((result: IFilterStats) => {
                dispatch({type: fetchFilterStatsTypes.success, result});
                return result;
            })
            .catch(catchAbortedError(() => false));
    };
