import {combineReducers, Reducer} from "redux";
import {INotificationsStore, notificationsReducer} from "@web2/notifications";

import {agencyReducer, IAgencyStore} from "../../agency/reducers/agency_detail_reducer";
import {agencyListReducer, IAgencyListStore} from "../../agency/reducers/agency_list_reducer";
import {agentReducer, IAgentStore} from "../../agent/reducers/agent_detail_reducer";
import {articlesReducer, IArticlesStore} from "../../article/reducers/article_reducer";
import {authorsReducer, IAuthorsStore} from "../../author/reducers/author_reducer";
import {developerListReducer, IDevelopersListStore} from "../../developers/reducers/developers_list_reducer";
import {individualOffersReducer, IndividualOffersStore} from "../../individual_offers/reducers/individual_offers_reducer";
import {IMetaDataStore, metaDataReducer} from "../../meta_data/reducers/meta_data_reducer";
import {IOfferStore, offerReducer} from "../../offer/detail/reducers/offer_reducer";
import {IInvestmentStore, investmentReducer} from "../../offer/investment/reducers/investment_reducer";
import {IOfferListStore, offerListReducer} from "../../offer/list/reducers/offer_list_reducer";
import {IPagesStore, pagesReducer} from "../../pages/reducers/page_reducer";
import {ISearchStore, searchReducer} from "../../search/reducers/search_reudcer";
import {ISiteMapStore, siteMapReducer} from "../../site_map/reducer/site_map_reducer";
import {IViewTypeStore, viewTypeReducer} from "../../tracking/view_type/view_type_reducer";
import {isAuthenticatedReducer, IsAuthenticatedStore} from "../user/reducers/is_authenticated_reducer";
import {IUserProfileStore, userProfileReducer} from "../user/reducers/user_profile_reducer";
import {responseStateReducer, ResponseStateStore} from "../utils/request_response_utils/response_state/response_state_reducer";
import {favouritesReducer, IFavouritesStore} from "./favourites_reducer";
import {ILastSearchStore, lastSearchReducer} from "./last_search_reducer";
import {IOverpassMapPoiStore, overpassMapPoiReducer} from "./overpass_map_poi_reducer";
import {IVisitedStore, visitedReducer} from "./visited_reducer";

export interface IStore {
    agent: IAgentStore;
    agency: IAgencyStore; // TODO: can be null in investment view
    agencyList: IAgencyListStore;
    developersList: IDevelopersListStore;
    articles: IArticlesStore;
    authors: IAuthorsStore;
    favourites: IFavouritesStore;
    individualOffers: IndividualOffersStore;
    investment: IInvestmentStore;
    isAuthenticated: IsAuthenticatedStore;
    lastSearch: ILastSearchStore;
    notifications: INotificationsStore;
    metaData: IMetaDataStore;
    offer: IOfferStore;
    offerList: IOfferListStore;
    pages: IPagesStore;
    overpassMapPoi: IOverpassMapPoiStore;
    responseState: ResponseStateStore;
    search: ISearchStore;
    user: IUserProfileStore;
    visited: IVisitedStore;
    viewType: IViewTypeStore;
    siteMap: ISiteMapStore;
}

const hybridReducerObj: Record<keyof IStore, Reducer<any, any>> = {
    agent: agentReducer,
    agency: agencyReducer,
    agencyList: agencyListReducer,
    developersList: developerListReducer,
    articles: articlesReducer,
    authors: authorsReducer,
    metaData: metaDataReducer,
    favourites: favouritesReducer,
    investment: investmentReducer,
    individualOffers: individualOffersReducer,
    isAuthenticated: isAuthenticatedReducer,
    lastSearch: lastSearchReducer,
    notifications: notificationsReducer,
    offer: offerReducer,
    offerList: offerListReducer,
    overpassMapPoi: overpassMapPoiReducer,
    pages: pagesReducer,
    responseState: responseStateReducer,
    search: searchReducer,
    user: userProfileReducer,
    visited: visitedReducer,
    viewType: viewTypeReducer,
    siteMap: siteMapReducer
};

export const hybridReducer = combineReducers(hybridReducerObj);
