import React, {ReactNode} from "react";

interface IProviderProps {
    children?: ReactNode;
    value: Record<string, number>;
}

const ABTestingContext = React.createContext<Record<string, number>>({});

export const ABTestingProvider: React.FC<IProviderProps> = (props) => {
    return <ABTestingContext.Provider value={props.value}>{props.children}</ABTestingContext.Provider>;
};

export const useABTestVariant = (abTestId: string): number | null => {
    const context = React.useContext(ABTestingContext);
    if (context === undefined) {
        throw new Error("useABTestVariant must be used within an ABTestingProvider");
    }
    return context[abTestId] ?? null;
};
