/**
 * Allows to call a function only once in a given time frame.
 * Different from debounce in that it will call the function at the beginning of the time frame, not at the end.
 * @param func
 * @param delay
 */
export function throttle(func: () => void | Promise<any | void>, delay: number) {
    let inThrottle = false;
    return (...args: []) => {
        if (!inThrottle) {
            func(...args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, delay);
        }
    };
}
