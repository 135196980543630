import {hitGoogleTagManager} from "./gtm_init";

/**
 * this event is used inside GTM to tigger any user.com / userengage() tags sequence
 */
export const gtmUserComIsInitialized = () => {
    const gtmData = {
        event: "userengage",
        userengage_loaded: "yes"
    };
    hitGoogleTagManager(gtmData);
};
