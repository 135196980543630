import {Dispatch, SetStateAction, useState} from "react";
import {css, Theme} from "@emotion/react";
import {Button} from "@web2/button";

import {PaywallPrice} from "../../../tracking/common/hit_paywall_event";
import {styledButtonCTAModalPrivateOffers} from "../ApplicationModalPrivateOffers";
import {PackageOption} from "./PackageOption";

export const SelectPakage = (props: {onClick: () => void; setPaywallPrice: Dispatch<SetStateAction<PaywallPrice | undefined>>}) => {
    const [singlePackage, setSinglePackage] = useState(false);
    const [monthlyPackage, setMonthlyPackage] = useState(false);
    const [paymentIsActive, setPaymentIsActive] = useState(true);

    const {onClick, setPaywallPrice} = props;

    const handleSinglePackage = () => {
        setSinglePackage(true);
        setMonthlyPackage(false);
        paymentIsActive && setPaymentIsActive(false);
        setPaywallPrice(PaywallPrice.ONE_TIME);
    };

    const handleMonltyPackage = () => {
        setSinglePackage(false);
        setMonthlyPackage(true);
        paymentIsActive && setPaymentIsActive(false);
        setPaywallPrice(PaywallPrice.MONTHLY);
    };

    return (
        <div>
            <div css={packagesWrapper}>
                <PackageOption
                    header="Jednorazowy"
                    subHeader="Dostęp do danych kontaktowych jednej oferty"
                    price="9"
                    onClick={handleSinglePackage}
                    isActive={singlePackage}
                />
                <PackageOption
                    header="Miesięczny"
                    subHeader="Dostęp do danych wszystkich ofert"
                    price="49"
                    onClick={handleMonltyPackage}
                    isActive={monthlyPackage}
                />
            </div>
            <div css={noWorries}>
                <div css={infoIcon}>i</div>
                <div>Bez obaw. Jeśli okaże się, że oferta, o którą pytasz jest już nieaktualna - zwrócimy Ci pieniądze.</div>
            </div>
            <div css={center}>
                <Button css={styledButtonCTAModalPrivateOffers} onClick={onClick} disabled={paymentIsActive}>
                    Przejdź do płatności
                </Button>
            </div>
        </div>
    );
};

const packagesWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: row;
    }
`;

const noWorries = css`
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 3rem;
`;

const infoIcon = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border: solid 0.1rem;
    border-radius: 100%;
    margin-right: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
`;

const center = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
