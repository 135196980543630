import * as React from "react";

export const RefreshIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
            <defs>
                <mask id="a" x="0.01" y="3.03" width="32.19" height="13.59" maskUnits="userSpaceOnUse">
                    <polygon points="0.01 3.03 32.2 3.03 32.2 16.62 0.01 16.62 0.01 3.03" fill="#fff" fillRule="evenodd" />
                </mask>
            </defs>
            <g mask="url(#a)">
                <path
                    d="M12.46,15.24a1.38,1.38,0,0,0-1.38-1.39H4.62L9.38,9.38a12.19,12.19,0,0,1,20.13,4.56A1.39,1.39,0,0,0,32.12,13,15,15,0,0,0,13,3.9,15,15,0,0,0,7.45,7.39l-4.67,4.4V5.55A1.38,1.38,0,0,0,1.39,4.17,1.38,1.38,0,0,0,0,5.55v9.69a1.38,1.38,0,0,0,1.38,1.38h9.69a1.38,1.38,0,0,0,1.38-1.38"
                    fill="#fff"
                    fillRule="evenodd"
                />
            </g>
            <path
                d="M34.61,19.39H24.92a1.39,1.39,0,0,0,0,2.77h6.46l-4.76,4.47A12.19,12.19,0,0,1,6.49,22.07a1.39,1.39,0,0,0-1.77-.84A1.37,1.37,0,0,0,3.88,23,15,15,0,0,0,18,33a14.87,14.87,0,0,0,10.55-4.35l4.67-4.4v6.24a1.39,1.39,0,0,0,2.77,0V20.77a1.38,1.38,0,0,0-1.38-1.38"
                fill="#fff"
                fillRule="evenodd"
            />
        </svg>
    );
};
