import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {OfferDealType} from "../../offer/utils/constants_offer";
import {ISearchSuggestions} from "./fetch_search_all_action";

export const fetchLastSearchSuggestionsTypes = createRequestActionTypes({
    name: "lastSearchSuggestions",
    type: "GET",
    view: "offer_list"
});

export const fetchLastSearchSuggestions = (location_hint?: string, deal_type?: OfferDealType) => (dispatch: Dispatch) => {
    dispatch({type: fetchLastSearchSuggestionsTypes.start});
    const url = appendQueryString(apiLink.suggestion.base({})(null), {...(location_hint ? {location_hint} : {}), ...(deal_type ? {deal_type} : {})});
    return getRequest({}, url, "fetchLastSearchSuggestions").then((result: ISearchSuggestions[]) => {
        const sortedSuggestions = result.sort((a, b) => b.offer_count_total - a.offer_count_total);
        dispatch({type: fetchLastSearchSuggestionsTypes.success, result: sortedSuggestions});
        return result;
    });
};
