// Please do not change string values. They were introduced by FM and are used for analytics ever since, we want to have continuity in naming.

export const autoSearchGTMEvent = (enabled: boolean) => {
    if ((window as any).dataLayer) {
        if (enabled) {
            return (window as any).dataLayer.push({event: "automap_on"});
        }
        return (window as any).dataLayer.push({event: "automap_off"});
    }
    return null;
};
