import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const CrossIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || 20} height={props.height || 20} viewBox="0 0 20 20">
            <path
                d="M19.17.83a2.84,2.84,0,0,0-4,0L10,6,4.85.83a2.84,2.84,0,0,0-4,4L6,10,.83,15.15a2.84,2.84,0,0,0,4,4L10,14l5.15,5.16a2.84,2.84,0,0,0,4-4L14,10l5.16-5.15A2.84,2.84,0,0,0,19.17.83Z"
                fill={props.mainColor || "#e42233"}
            />
        </svg>
    );
};
