import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import {useLocation} from "react-router";
import {useTheme} from "@emotion/react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {isEmpty, map} from "lodash";

import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";
import {
    INavElement,
    MOBILE_NAV_WIDTH_LEVEL_1,
    MOBILE_NAV_WIDTH_LEVEL_2,
    MOBILE_NAV_WIDTH_LEVEL_3,
    MOBILE_NAV_WIDTH_LEVEL_4,
    MOBILE_NAV_WIDTH_LEVEL_5,
    NAV_BG_LEVEL_2,
    NAV_BG_LEVEL_3,
    NAV_BG_LEVEL_4,
    NavLevelType
} from "./Navigation";
import {activeMenuElement, disabledMenuElement, MenuElement, navigationElementText} from "./NavigationElement";

interface IProps {
    dropdownElements: INavElement[];
    navState: number[];
    setNavState: (update: (prev: number[]) => number[]) => void;
    title: string | ReactNode;
    url?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    itemIndex: number;
    arrow: ReactNode;
    icon?: ReactNode;
    isRightElement?: boolean;
    isActive?: (pathname: string) => boolean;
    disabled?: boolean;
    titleDealTypeSuffix?: string;
    className?: string;
}

export const DropdownListElement = (props: IProps) => {
    const [isMobile, setMobile] = useState(false);
    const theme = useTheme();
    const location = useLocation();
    const menuElementActive = (props.isActive && props.isActive(location.pathname)) || false;

    useEffect(() => {
        setMobile(window.innerWidth < theme.breakpoints.numeric.screen_lg);
    }, []);

    const onClick = (
        e: React.MouseEvent<HTMLElement>,
        level: NavLevelType,
        idx: number,
        subElementsLength: number,
        onClick: ((e: React.MouseEvent<HTMLElement>) => void) | undefined
    ) => {
        if (isMobile && subElementsLength > 0) {
            if (props.navState.length < 3) {
                e.preventDefault();
            }
            props.setNavState((prev) => [...prev.slice(0, level - 1), idx]);
        }
        if (onClick) {
            onClick(e); // if you need to prevent default you should do it in callback function
            // props.setBlendVisible(false);
        }
    };

    const mobileShowLevel = (level: NavLevelType, idx: number) => {
        if (isMobile && props.navState[level] === idx) {
            return {display: "block"};
        }
        return {};
    };

    return (
        <li className={topLevelListElement} data-testid="navigation-container">
            <MenuElement
                as={props.url ? "a" : "div"}
                onClick={(e) => onClick(e, 1, props.itemIndex, props.dropdownElements.length, props.onClick)}
                href={props.url}
                className={cx(topLevelListElementContent, props.disabled && disabledMenuElement, menuElementActive && activeMenuElement)}
            >
                <div className={iconAndTitleHolder}>
                    {props.icon && <div className={iconHolder}>{props.icon}</div>}
                    <span className={navigationElementText}>{props.title}</span>
                </div>

                <div className={topLevelArrow}>{!isEmpty(props.dropdownElements) && props.arrow}</div>
            </MenuElement>

            <FirstLevelHolder
                data-testid="navigation-first-level"
                className={cx(props.isRightElement && rightFirstLevelHolder, "first-level", props.className)}
                style={mobileShowLevel(0, props.itemIndex)}
                width={props.titleDealTypeSuffix ? 205 : FIRST_LEVEL_WIDTH}
            >
                {map(props.dropdownElements, (firstLevelItem, firstLevelItemIndex) => (
                    <li
                        key={firstLevelItemIndex}
                        className={cx(firstLevelListElement, `e${firstLevelItemIndex + 1}`)}
                        data-testid="navigation-first-level-element"
                    >
                        <SubElement
                            onClick={(e) =>
                                onClick(e, 2, firstLevelItemIndex, firstLevelItem.subElements ? firstLevelItem.subElements.length : 0, firstLevelItem.onClick)
                            }
                            level={1}
                            href={firstLevelItem.url}
                            width={props.titleDealTypeSuffix ? 205 : FIRST_LEVEL_WIDTH}
                            as={firstLevelItem.url ? "a" : "div"}
                        >
                            {firstLevelItem.title + (props.titleDealTypeSuffix ? " " + props.titleDealTypeSuffix : "")}

                            {!isEmpty(firstLevelItem.subElements) && props.arrow}
                        </SubElement>

                        {!isEmpty(firstLevelItem.subElements) && (
                            <ul
                                data-testid="navigation-second-level"
                                className={cx(subLevelHolder, "second-level")}
                                style={mobileShowLevel(1, firstLevelItemIndex)}
                            >
                                {map(firstLevelItem.subElements, (secondaryLevelItem, secondaryLevelItemIndex) => {
                                    const secondaryLevelItemTitle = props.titleDealTypeSuffix
                                        ? `${firstLevelItem.title} ${props.titleDealTypeSuffix} ${secondaryLevelItem.title}`
                                        : secondaryLevelItem.title;
                                    const width = props.titleDealTypeSuffix ? 50 : 25;
                                    return (
                                        <SecondLevelListElement key={secondaryLevelItemIndex} width={width} data-testid="navigation-second-level-element">
                                            <SubElement
                                                onClick={(e) =>
                                                    onClick(
                                                        e,
                                                        3,
                                                        secondaryLevelItemIndex,
                                                        secondaryLevelItem.subElements ? secondaryLevelItem.subElements.length : 0,
                                                        secondaryLevelItem.onClick
                                                    )
                                                }
                                                level={2}
                                                href={secondaryLevelItem.url}
                                            >
                                                {secondaryLevelItemTitle}

                                                {!isEmpty(secondaryLevelItem.subElements) && props.arrow}
                                            </SubElement>

                                            {!isEmpty(secondaryLevelItem.subElements) && (
                                                <ul
                                                    data-testid="navigation-third-level"
                                                    className={cx(subLevelHolder, "third-level")}
                                                    style={mobileShowLevel(2, secondaryLevelItemIndex)}
                                                >
                                                    {map(secondaryLevelItem.subElements, (thirdLevelListElement, thirdLevelItemIndex) => {
                                                        return (
                                                            <li key={thirdLevelItemIndex} data-testid="navigation-third-level-element">
                                                                <SubElement
                                                                    onClick={(e) =>
                                                                        onClick(
                                                                            e,
                                                                            4,
                                                                            thirdLevelItemIndex,
                                                                            thirdLevelListElement.subElements ? thirdLevelListElement.subElements.length : 0,
                                                                            thirdLevelListElement.onClick
                                                                        )
                                                                    }
                                                                    level={3}
                                                                    href={thirdLevelListElement.url}
                                                                >
                                                                    {thirdLevelListElement.title}
                                                                </SubElement>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </SecondLevelListElement>
                                    );
                                })}
                            </ul>
                        )}
                    </li>
                ))}
            </FirstLevelHolder>
        </li>
    );
};

const FIRST_LEVEL_WIDTH = 180;

// TOP LEVEL

const topLevelListElement = css`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: inline-block;
        width: unset;
        position: relative;

        &:hover {
            background: ${getThemeVariable("colors-brand_primary_light")};

            .first-level {
                display: grid;
            }
        }
    }
`;

const topLevelListElementContent = css`
    height: ${getThemeVariable("main_nav-height")};
    padding: 2rem 2.5rem 2rem 4rem;
    font-size: ${getThemeVariable("fonts-font_size_small")};
    color: ${getThemeVariable("colors-text_color")};
    cursor: pointer;
    width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: ${getThemeVariable("colors-text_color")};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: unset;
        font-size: ${getThemeVariable("fonts-font_size_base")};
        padding: 2rem 3rem;
        white-space: nowrap;
        color: #fff;

        &:hover,
        &:focus {
            background: ${getThemeVariable("colors-brand_primary_light")};
            color: #fff;
        }

        &.active {
            background: ${getThemeVariable("colors-brand_primary_dark")};
        }
    }
`;

const topLevelArrow = css`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: none;
    }
`;

export interface IFirstLevelHolderProps {
    width?: number | null;
}
// FIRST LEVEL
const FirstLevelHolder = styled.ul<IFirstLevelHolderProps>`
    display: none;
    position: absolute;
    background: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    right: -${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    height: 100%;
    z-index: ${getThemeVariable("main_nav-z_index")};

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    &.for-sale,
    &.for-rent {
        > li > div {
            font-weight: 700;

            &:hover {
                background: #fff;
                cursor: default;
            }
        }

        @media (min-width: ${getThemeBreakpoint().screen_lg}) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(5, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 0;

            .e1 {
                grid-area: e1;
            }
            .e2 {
                grid-area: e2;
            }
            .e3 {
                grid-area: e3;
            }
            .e4 {
                grid-area: e4;
            }
            .e5 {
                grid-area: e5;
            }
            .e6 {
                grid-area: e6;
            }
            .e7 {
                grid-area: e7;
            }
            .e8 {
                grid-area: e8;
            }
            .e9 {
                grid-area: e9;
            }
            .e10 {
                grid-area: e10;
            }
            .e11 {
                grid-area: e11;
            }
            .e12 {
                grid-area: e12;
            }
            .e13 {
                grid-area: e13;
            }
        }
    }

    &.for-sale {
        @media (min-width: ${getThemeBreakpoint().screen_lg}) {
            grid-template-areas:
                "e1 e6 e6"
                "e2 e7 e11"
                "e3 e8 e12"
                "e4 e9 e13"
                "e5 e10 e14";

            .e6 {
                > div {
                    width: 100%;
                }
            }
            .e14 {
                grid-area: e14;
            }
        }
    }

    &.for-rent {
        @media (min-width: ${getThemeBreakpoint().screen_lg}) {
            grid-template-areas:
                "e1 e5 e5"
                "e2 e6 e10"
                "e3 e7 e11"
                "e4 e8 e12"
                " . e9 e13";

            .e5 {
                > div {
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        top: ${getThemeVariable("main_nav-height")};
        left: 0;
        height: unset;
        width: ${(props) => props.width + "px"};
        padding: 0;
    }
`;

const rightFirstLevelHolder = css`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        left: initial;
        right: 0;
    }
`;

//OTHER LEVELS
const subLevelHolder = css`
    display: none;
    position: absolute;
    right: -${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    top: 0;
    padding: 0;
    background: #fff;
    list-style: none;

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        right: -${MOBILE_NAV_WIDTH_LEVEL_1 * 2}px;
        left: unset;
        padding: 0;
    }
`;

interface ISubElementProps {
    level: 1 | 2 | 3;
    width?: number | null;
}

const SubElement = styled.a<ISubElementProps>`
    height: ${getThemeVariable("main_nav-height")};
    background: #fff;
    font-size: ${getThemeVariable("fonts-font_size_small")};
    color: ${getThemeVariable("colors-text_color")};
    padding: 2rem 2.5rem 2rem 4rem;
    width: ${MOBILE_NAV_WIDTH_LEVEL_1 + "px"};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: ${getThemeVariable("colors-text_color")};
    }

    &:hover {
        background: ${(props) => (props.level === 2 ? NAV_BG_LEVEL_3 : props.level === 3 ? NAV_BG_LEVEL_4 : NAV_BG_LEVEL_2)};
    }
    
    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: ${(props) => (props.level === 1 ? props.width + "px" : props.level === 2 ? "100%" : "200px")};
        padding: 1.5rem 2rem;
        font-size: ${(props) => (props.level > 1 ? "1rem" : getThemeVariable("fonts-font_size_small"))};
        background: ${(props) => (props.level === 2 ? NAV_BG_LEVEL_2 : props.level === 3 ? NAV_BG_LEVEL_3 : "#fff")};
        height: ${(props) => (props.level === 2 ? "4rem" : props.level === 3 ? "4rem" : getThemeVariable("main_nav-height"))}};
    }
`;

const firstLevelListElement = css`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        &:hover {
            .second-level {
                display: block;
                background: ${NAV_BG_LEVEL_2};
                width: ${MOBILE_NAV_WIDTH_LEVEL_1 * 1.5}px;
            }
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        &:hover {
            .second-level {
                width: ${MOBILE_NAV_WIDTH_LEVEL_1 * 2}px;
            }
        }
    }
`;

const iconAndTitleHolder = css`
    display: flex;
    align-items: center;
`;

const iconHolder = css`
    margin-right: 15px;
    display: flex;
    align-items: center;
`;

interface IMenuListElementProps {
    width: number;
}
const SecondLevelListElement = styled.li<IMenuListElementProps>`
    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: ${(props) => (props.width ? props.width + "%" : "33%")};
        display: inline-block;
        vertical-align: middle;

        &:hover {
            .third-level {
                display: block;
                height: 100%;
                background: ${NAV_BG_LEVEL_3};
            }
        }
    }

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        width: ${(props) => props.width + "%"};
    }
`;
