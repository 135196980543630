import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const FacebookFIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M7.13145 3.83456H5.78296V2.95015C5.78296 2.61801 6.00309 2.54058 6.15813 2.54058C6.31283 2.54058 7.10975 2.54058 7.10975 2.54058V1.08043L5.79918 1.07532C4.34433 1.07532 4.01325 2.16434 4.01325 2.86125V3.83456H3.17188V5.33916H4.01325C4.01325 7.27009 4.01325 9.59665 4.01325 9.59665H5.78296C5.78296 9.59665 5.78296 7.24716 5.78296 5.33916H6.97711L7.13145 3.83456Z" />
        </SvgIcon>
    );
};
