import * as React from "react";
import {isEmpty} from "lodash";
import {IFormFieldProps} from "@web2/form2";

import {RoundDirection} from "../../../app/utils/number";
import {OfferDealType} from "../../../offer/utils/constants_offer";
import {TypeSearchFilterOptions} from "../../utils/TypeSearchFilterOptions";
import {mobileFilterHolder} from "../atoms/atoms";

interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export interface IFilterChildMobileProps<TRangeValue> extends IFormFieldProps<string, IRange<any>> {
    clearField: () => void;
    errorOnBottom?: boolean;
    label: string;
    latestQuery?: {
        rooms?: string;
        offer_type: TypeSearchFilterOptions;
        page: string;
        per_page: number;
        deal_type: OfferDealType;
        geo_point?: string;
        distance?: string;
        name?: string;
    };
    roundTo?: number;
    roundDirection?: RoundDirection;
}

interface IProps<TRangeValue> extends IFormFieldProps<string, IRange<TRangeValue>> {
    errorOnBottom?: boolean;
    label: string;
    children: (props: IFilterChildMobileProps<TRangeValue>) => JSX.Element;
    onClearField?: () => void;
    latestQuery?: {
        rooms?: string;
        offer_type: TypeSearchFilterOptions;
        page: string;
        per_page: number;
        deal_type: OfferDealType;
        geo_point?: string;
        distance?: string;
        name?: string;
    };
    roundTo?: number;
    roundDirection?: RoundDirection;
}

export class FilterMobile<T> extends React.PureComponent<IProps<T | "">, {}> {
    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, {lower: "", upper: ""});
        this.props.onAfterChange(this.props.name, {lower: "", upper: ""});
    };

    public render() {
        const {value, error} = this.props;
        const hasError = !isEmpty(error) && "has-error";

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.props.onAfterChange,
                onChange: this.props.onChange,
                label: this.props.label,
                value: value,
                latestQuery: this.props.latestQuery,
                roundTo: this.props.roundTo,
                roundDirection: this.props.roundDirection
            });

        return (
            <div className={mobileFilterHolder}>
                {hasError && <div>error</div>}

                {renderEnchancedChild()}
            </div>
        );
    }
}
