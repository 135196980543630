const removeElementsList = ["tel\\.", "tel:", "telefon:", "\\+\\d{2} ", "\\(asaricrm.com\\)", "mieszko.my", "ZADZWOŃ:"];

const phonePatternsList = [
    "\\d{9}", // xxxxxxxxx
    "\\d{3}-\\d{3}-\\d{3}", // xxx-xxx-xxx
    "\\d{3} \\d{3} \\d{3}", // xxx xxx xxx
    "\\d{3}-\\d{2}-\\d{2}", // xxx-xx-xx
    "\\d{3} \\d{2} \\d{2}", // xxx xx xx
    "\\d{2}-\\d{3}-\\d{2}-\\d{2}", // xx-xxx-xx-xx
    "\\d{2} \\d{3} \\d{2} \\d{2}" //  xx xxx xx xx
];

const domainsToMatchPattern = ["com", "eu", "pl"].join("|");
const messagePatternsList = [
    "[\\w\\-\\.]+@([\\w-]+\\.?)+([\\w-]{2,4})?", // email validation pattern, also includes bad emails like awrzesniak@liderhaus without domain
    `(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.(${domainsToMatchPattern})\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)` // url pattern
];

export const replaceContactInText = (text: string): string => {
    const flags = "gmi";
    const removePatterns = new RegExp(removeElementsList.join("|"), flags);
    const phonePatternsRegExp = new RegExp(phonePatternsList.join("|"), flags);
    const messagePatternsRegExp = new RegExp(messagePatternsList.join("|"), flags);

    return text
        .replace(removePatterns, " ")
        .replace(phonePatternsRegExp, "<span data-contact-type='phone' ></span>")
        .replace(messagePatternsRegExp, "<span data-contact-type='message' ></span>");
};
