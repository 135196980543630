import {ICoordinates, IElasticCoordinates, IGoogleCoordinates} from "../interfaces/response/offer_list";

/**
 * Function returns normalized coordinates in LatLngLiteral format https://developers.google.com/maps/documentation/javascript/examples/map-latlng-literal
 * we can get `longitude` under two different keys: `lon` (elastic format) or `lng` (google/ whatever gh api is using).
 *
 * TODO: We probably should normalize this also on api side to get rid of this function
 * @param coordinates - all formats allowed
 */

export const getNormalizedCoordinates = (coordinates: ICoordinates): google.maps.LatLngLiteral => ({
    lat: coordinates.lat,
    lng: (coordinates as IGoogleCoordinates).lng || (coordinates as IElasticCoordinates).lon
});
