import * as React from "react";
import {useDispatch} from "react-redux";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {COMMON_PAGE_SECTIONS} from "@web2/gh_page_object_models";
import {ArrowDownIcon} from "@web2/icons";
import {addNotification} from "@web2/notifications";

import {PopoverGH} from "../app/components/PopoverGH";
import {notifyBugsnag} from "../app/utils/bugsnag/notify_bugsnag";
import {db, mb_0, mt_md, pt_xl} from "../styles/helpers_linaria";
import {getThemeBreakpointCorrect, getThemeVariable} from "../styles/linaria_variable_factory";
import {footer_links, footer_social_links} from "./footer_links";
import {FooterMenuElement, IFooterMenuElement} from "./FooterMenuElement";
import {PanelExpandable} from "./PanelExpandable";

export interface IProps {
    addBottomOffset?: boolean;
}

export interface IFooterMenuData {
    partner: IFooterMenuElement[];
    search: IFooterMenuElement[];
    information: IFooterMenuElement[];
}

declare global {
    interface Window {
        Cookiebot: {renew: () => void};
    }
}

export const Footer = (props: IProps) => {
    const dispatch = useDispatch();

    const openCookieModal = () => {
        try {
            window.Cookiebot.renew();
        } catch (e: any) {
            dispatch(addNotification({content: "Nie udało się otworzyć okna ustawień cookies, spróbuj później", type: "error"}));
            notifyBugsnag(
                {
                    message: "Failed to open Cookiebot modal",
                    error: e
                },
                "Failed to open Cookiebot modal"
            );
        }
    };

    return (
        <footer className={footer}>
            <FooterPanelsHolder addBottomOffset={props.addBottomOffset} date-testid={COMMON_PAGE_SECTIONS.FOOTER_LOWER}>
                <div className={footerPanelGroup}>
                    <div className={footerSearchColumn}>
                        <PanelExpandable open={false} title={"Szukaj"} panelArrowIcon={<ArrowDownIcon fillColor="#fff" />}>
                            <ul className={footerPanelList}>
                                {footer_links.search.map((element) => (
                                    <FooterMenuElement
                                        key={element.title.toLowerCase()}
                                        title={element.title}
                                        url={element.url}
                                        rel={element.rel}
                                        target={element.target}
                                        titleAttribute={element.titleAttribute}
                                        icon={element.icon}
                                    />
                                ))}
                            </ul>
                        </PanelExpandable>
                    </div>

                    <div className={footerInfoColumn}>
                        <PanelExpandable title={"Informacje"} panelArrowIcon={<ArrowDownIcon fillColor="#fff" />}>
                            <ul className={footerPanelList}>
                                {footer_links.information.map((element) => (
                                    <FooterMenuElement
                                        key={element.title.toLowerCase()}
                                        title={element.title}
                                        url={element.url}
                                        rel={element.rel}
                                        target={element.target}
                                        titleAttribute={element.titleAttribute}
                                        icon={element.icon}
                                    />
                                ))}
                            </ul>

                            <PopoverGH popover={<TooltipContent />}>
                                <FooterMenuElement title="Punkt kontaktowy dla organów" />
                            </PopoverGH>

                            <FooterMenuElement title="Ustawienia cookies" onClick={openCookieModal} />
                        </PanelExpandable>
                    </div>

                    <div className={footerPartnerColumn}>
                        <div>
                            <PanelExpandable open={false} title={"Partner serwisu"} panelArrowIcon={<ArrowDownIcon fillColor="#fff" />}>
                                <ul className={footerPanelList}>
                                    {footer_links.partner.map((element) => (
                                        <FooterMenuElement
                                            key={element.title.toLowerCase()}
                                            title={element.title}
                                            url={element.url}
                                            rel={element.rel}
                                            target={element.target}
                                            titleAttribute={element.titleAttribute}
                                            icon={element.icon}
                                        />
                                    ))}
                                </ul>
                            </PanelExpandable>
                        </div>

                        <div>
                            <h3 className={cx(footerColumnHeading, pt_xl)}>Dołącz do nas</h3>

                            <ul className={footerSocialList}>
                                {footer_social_links.map((item, idx: number) => (
                                    <li key={idx}>
                                        <a href={item.url} target="_blank">
                                            {item.icon}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <p className={mb_0}>Wydawcą portalu gethome.pl jest PropertyGroup Sp. z o.o.</p>
                    </div>
                </div>
            </FooterPanelsHolder>
        </footer>
    );
};

const TooltipContent = () => {
    return (
        <div className={tooltipTextStyle}>
            <p>
                Punkt kontaktowy dla organów państw członkowskich, Komisji Europejskiej i Rady Usług Cyfrowych{" "}
                <span className={cx(mt_md, db)}>
                    E-mail: <a href={"mailto:biuro@gethome.pl"}>biuro@gethome.pl</a>
                </span>
                <span className={cx(mt_md, db)}>Język: polski, angielski</span>
            </p>
            <p>Punkt kontaktowy wyznaczyliśmy zgodnie z art. 11 rozporządzenia 2022/2065 w sprawie jednolitego rynku usług cyfrowych oraz zmiany</p>
        </div>
    );
};

const tooltipTextStyle = css`
    text-align: left;
    max-width: 300px;

    a,
    a:visited,
    a:active,
    a:focus,
    a:hover {
        color: inherit;
    }
`;

const footer = css`
    width: 100%;
    background: ${getThemeVariable("colors-gray_darker")};
    color: #fff;

    a {
        color: #fff;

        &:hover, &:focus, &:active, &:visited {
            color: #fff;
        }

        @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
            line-height: 1.5;
            margin-bottom: 0.7rem;
        }
    }

    @media print {
        display: none; !important;
    }
`;

interface IFooterPanelsHolderProps {
    addBottomOffset?: boolean;
}

const FooterPanelsHolder = styled.div<IFooterPanelsHolderProps>`
    margin: 0 auto;
    padding: 1.5rem 2rem ${(props) => (props.addBottomOffset ? "7rem" : "1.5rem")} 2rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        padding: 1.5rem 2rem 3rem;
    }
`;

const FOOTER_MAX_WIDTH = 1600;
const footerPanelGroup = css`
    max-width: ${FOOTER_MAX_WIDTH}px;
    margin: 0 auto;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: flex;
        flex-direction: row;
        gap: 2.5rem;
    }
`;

const footerPanelList = css`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const footerSearchColumn = css`
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        order: 2;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 250px;
    }
`;

const footerInfoColumn = css`
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        order: 3;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 250px;
    }
`;

const footerPartnerColumn = css`
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        order: 1;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const footerColumnHeading = css`
    font-size: 1.4rem;
    margin-bottom: 0.8rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        margin-top: 1.5rem;
    }
`;

const footerSocialList = css`
    margin: 0;
    list-style: none;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
`;
