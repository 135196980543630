import {MutableRefObject, useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@web2/button";
import {PhoneFillIcon} from "@web2/icons";
import {phoneNumberFormatNoPolishPrefix} from "@web2/string_utils";
import {BounceLoader} from "@web2/ui_utils";

import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {mr_md} from "../../styles/helpers";
import {PHONE_NUMBER_INTERACTION_TYPE, showPhoneNumberAlgolyticsHit} from "../../tracking/algolytics/click/show_phone_number_click";
import {GtmInquiryOverbudget, gtmInquiryWithOffer, gtmInquiryWithoutOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_inquiry";
import {phoneNumberCallGTMEvent, phoneNumberRevealGTMEvent} from "../../tracking/google_tag_manager/phone_number";
import {GtmContactType} from "../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {useRevealedNumber} from "../hooks/use_revealed_number";
import {getIsPrimaryMarket} from "../utils/get_is_primary_market";

export interface IPhoneNumberRevealOffer {
    id: string;
    agency?: {name: string; status: number} | null;
    deal_type: OfferDealType;
    name: string;
    property: {
        location?: {path?: {name: string; slug: string; type: string}[]} | null;
        size: number | null;
        type: PropertyType;
    };
    market_type: OfferMarketType;
    slug: string;
    investment?: {
        name: string;
        phone_clip?: string | null;
    } | null;
    is_private: boolean;
    overbudget: boolean;
}

export interface IPhoneNumberRevealProps {
    agency?: {
        id: string;
        name: string;
        phone_number?: string;
        status: number;
        market_type?: OfferMarketType;
    };
    agent?: {
        id: string;
        name: string;
        last_name?: string;
        phone_number?: string;
    };
    investment?: {
        id: string;
        name: string;
        developer?: {
            name: string;
        } | null;
        phone_clip?: string | null;
    } | null;

    isDeveloper?: boolean;
    isOfferListModal?: boolean;
    gtmSource: GtmSource;
    viewType: ViewType | null;
    buttonSize?: "lg" | "sm";
    voivodeshipSlug?: string;
    center?: boolean;
    offer?: IPhoneNumberRevealOffer | null;

    hasActiveSubscription?: MutableRefObject<boolean>;
    openModal?: () => void;
}

const CustomizedPhoneIcon = () => {
    const theme = useTheme();
    return (
        <PhoneFillIcon
            css={css`
                margin: 0 1rem 0 0.34rem;
                flex-grow: 0;
                flex-shrink: 0;
            `}
            size="1.8"
            fillColor={theme.colors.brand_primary}
        />
    );
};

export function getVoivodeshipPhonePrefix(voivodeshipSlug?: string) {
    switch (voivodeshipSlug) {
        case "dolnoslaskie":
            return "71";
        case "kujawsko-pomorskie":
            return "52";
        case "lubelskie":
            return "81";
        case "lubuskie":
            return "95";
        case "lodzkie":
            return "42";
        case "malopolskie":
            return "12";
        case "opolskie":
            return "77";
        case "podkarpackie":
            return "17";
        case "podlaskie":
            return "85";
        case "pomorskie":
            return "58";
        case "slaskie":
            return "32";
        case "swietokrzyskie":
            return "41";
        case "warminsko-mazurskie":
            return "89";
        case "wielkopolskie":
            return "61";
        case "zachodniopomorskie":
            return "91";
        case "mazowieckie":
            return "22";
        default:
            return null;
    }
}

export const PhoneNumberReveal = (props: IPhoneNumberRevealProps) => {
    const {agent, agency, investment, offer} = props;
    const theme = useTheme();
    const investmentPhoneClip = investment?.phone_clip || offer?.investment?.phone_clip;
    const isPrimaryMarket = getIsPrimaryMarket(props);
    const phoneNumber = agent?.phone_number || agency?.phone_number || investmentPhoneClip;
    const {getRotatedNumber, rotatedNumber, isRotatedNumberLoading} = useRevealedNumber({uuid: offer?.id, fallbackNumber: phoneNumber, isPrimaryMarket});
    const [error404, setError404] = useState(false);

    useEffect(() => {
        props.hasActiveSubscription && getRotatedNumber();
    }, []);

    const hitGtmInquiryOnPhoneInteraction = () => {
        offer
            ? gtmInquiryWithOffer({
                  viewType: props.viewType,
                  offer,
                  offer_status: props.offer?.overbudget ? GtmInquiryOverbudget.TRUE : GtmInquiryOverbudget.FALSE,
                  contactType: GtmContactType.PHONE,
                  gtmSource: props.gtmSource
              })
            : gtmInquiryWithoutOffer({
                  contactType: GtmContactType.PHONE,
                  gtmSource: props.gtmSource,
                  viewType: props.viewType,
                  agent,
                  agency,
                  investment
              });
    };
    const hitAlgolytics = (action_type: PHONE_NUMBER_INTERACTION_TYPE) => {
        if (rotatedNumber && rotatedNumber !== "404") {
            const algolyticsHitData = {
                id: offer?.id,
                agency: agency && {id: agency.id, name: agency.name},
                agent: agent && {id: agent.id, name: agent.name, last_name: agent.last_name},
                investment: investment && {id: investment.id, name: investment.name}
            };

            showPhoneNumberAlgolyticsHit(window.location.pathname, algolyticsHitData, action_type, props.isOfferListModal);
        }
        return;
    };

    const onClickRevealPhoneNumber = async () => {
        const _rotatedNumber = await getRotatedNumber();

        if (_rotatedNumber && _rotatedNumber !== "404") {
            hitAlgolytics(PHONE_NUMBER_INTERACTION_TYPE.REVEAL);
            hitGtmInquiryOnPhoneInteraction();
            phoneNumberRevealGTMEvent();
        }

        if (_rotatedNumber === "404") {
            props.openModal?.();
        }

        if (!_rotatedNumber && _rotatedNumber === "404") {
            setError404(true);
        }
        return;
    };

    const onClickPhoneNumber = () => {
        hitAlgolytics(PHONE_NUMBER_INTERACTION_TYPE.CALL);
        hitGtmInquiryOnPhoneInteraction();
        phoneNumberCallGTMEvent();
    };

    const getPrivateOrVoivodeshipPhonePrefix =
        props.offer?.is_private && props.agent?.phone_number
            ? phoneNumberFormatNoPolishPrefix(props.agent?.phone_number).slice(0, 3)
            : getVoivodeshipPhonePrefix(props.voivodeshipSlug);

    if (!!phoneNumber) {
        return rotatedNumber && !error404 ? (
            <PhoneWrapper center={props.center}>
                <CustomizedPhoneIcon />

                <PhoneNumberLink is_private={props.offer?.is_private} onClick={onClickPhoneNumber} href={`tel:+${rotatedNumber}`}>
                    {phoneNumberFormatNoPolishPrefix(rotatedNumber)}
                </PhoneNumberLink>
            </PhoneWrapper>
        ) : (
            <PhoneWrapper center={props.center}>
                <CustomizedPhoneIcon />

                <p css={[phoneNumberStyle, mr_md]}>{getPrivateOrVoivodeshipPhonePrefix}</p>

                <Button
                    variant="primary"
                    onClick={onClickRevealPhoneNumber}
                    isLoading={isRotatedNumberLoading}
                    loaderIcon={<BounceLoader color={theme.colors.brand_primary_light} size="2rem" opacity={0.8} />}
                    size={props.buttonSize ? props.buttonSize : undefined}
                    css={showPhoneNumberButton}
                >
                    Pokaż numer
                </Button>
            </PhoneWrapper>
        );
    }

    return null;
};

const phoneNumberStyle = (theme: Theme) => css`
    display: inline-block;
    color: ${theme.colors.brand_primary};
    font-size: 2rem;
    font-weight: 500;
`;

interface IPhoneNumberLinkStyle {
    is_private?: boolean;
}
const PhoneNumberLink = styled.a<IPhoneNumberLinkStyle>`
    ${({theme}) => phoneNumberStyle(theme)};
    width: ${(props) => (props.is_private ? "auto" : "143px")};
    text-align: left;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        color: ${({theme}) => theme.colors.brand_primary_dark};
        text-decoration: none;
    }
`;

const showPhoneNumberButton = css`
    width: 110px;
`;

const PhoneWrapper = styled.div<{center?: boolean}>`
    height: 2.4rem;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: ${(props) => (props.center ? "center" : "flex-start")};
`;
