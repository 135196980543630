import {Dispatch, SetStateAction} from "react";
import {useSelector} from "react-redux";
import {useMounted} from "@web2/react_utils";

import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferInvestment, IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {osmMapEnable} from "../../../app/utils/read_environment_variables";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {ViewType} from "../../../tracking/view_type/view_type";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {MAX_MAP_OFFER_MARKER_COUNT} from "../actions/fetch_offer_list_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {useOfferListMapCommonLogic} from "./map/use_offer_list_map_common_logic";
import {OfferListMapDesktop} from "./OfferListMapDesktop";
import {OfferListMapOsm} from "./OfferListMapOsm";
import {SortType} from "./OfferListSortButton";

interface IStateProps {
    hoveredOfferId: string | null;
    setFavouriteOffer: typeof setFavouriteOffer;
    isMapBig: boolean;
    setMapBig: Dispatch<SetStateAction<boolean>>;
    goToOffer: (offerSlug: string, sort?: SortType) => void;

    locationData: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    latestQuery: IOfferListQuery;
    offersData: {
        meta: IOfferListApiResponseMeta;
        collection_count: number;
        offers: IOfferListOfferResponse[];
        requestState: RequestState;
        page: number;
        pageCount: number;
    };
    investment: {
        investment: IOfferInvestment | null;
        requestState: RequestState;
    };
    favouriteOffers: string[];
    visitedOffers: string[];
    viewType: ViewType | null;
}

interface Props extends IStateProps {}

export const OfferListDynamicMap = (props: Props) => {
    const {isMapInManualBrowsingMode, mapPolygon} = useOfferListMapCommonLogic(props);
    const isMounted = useMounted();

    const markers = useSelector((state: IStore) => state.offerList.markers.markers);
    const markersRequestState = useSelector((state: IStore) => state.offerList.markers.markersRequestState);

    return (
        <>
            {!osmMapEnable && (
                <OfferListMapDesktop
                    manualMapBrowsingMode={isMapInManualBrowsingMode}
                    hoveredOfferId={props.hoveredOfferId}
                    displayLimitInfo={props.offersData.collection_count >= MAX_MAP_OFFER_MARKER_COUNT}
                    locationData={mapPolygon()}
                    markers={markers}
                    markersRequestState={markersRequestState}
                    favouriteOffers={props.favouriteOffers}
                    visitedOffers={props.visitedOffers}
                    setFavouriteOffer={props.setFavouriteOffer}
                    isMapBig={props.isMapBig}
                    setMapBig={props.setMapBig}
                    onOfferClick={props.goToOffer}
                    viewType={props.viewType}
                    sort={props.latestQuery.sort}
                />
            )}

            {osmMapEnable && isMounted && (
                <OfferListMapOsm
                    hoveredOfferId={props.hoveredOfferId}
                    markers={markers}
                    locationData={mapPolygon()}
                    isMapBig={props.isMapBig}
                    setMapBig={props.setMapBig}
                    displayLimitInfo={props.offersData.collection_count >= MAX_MAP_OFFER_MARKER_COUNT}
                    markersRequestState={markersRequestState}
                    onOfferClick={props.goToOffer}
                    sort={props.latestQuery.sort}
                />
            )}
        </>
    );
};
