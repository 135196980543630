import {IOfferDetailFormPlaceholder, IOfferDetailInvestment, IOfferDetailInvestmentDeveloper} from "../../app/interfaces/response/offer_detail";

export function showFormPlaceholder(
    investment?: {
        lead_form_configuration?: IOfferDetailFormPlaceholder;
    } | null,
    developer?: {
        lead_form_configuration: IOfferDetailFormPlaceholder;
    }
): [boolean, IOfferDetailFormPlaceholder] {
    if (investment && investment.lead_form_configuration) {
        return [!!investment.lead_form_configuration.show_form_placeholder, investment.lead_form_configuration];
    }

    if (developer) {
        return [!!developer.lead_form_configuration.show_form_placeholder, developer.lead_form_configuration];
    }

    return [false, {show_form_placeholder: false, form_placeholder_phone: "", form_placeholder_text: ""}];
}
