import {hitGoogleTagManager} from "./gtm_init";

export const gtmNotificationsBtnClick = () => {
    const gtmData = {
        event: "notification_on",
        action: "form_open"
    };
    hitGoogleTagManager(gtmData);
};

// push user email and consent to dataLayer so marketing can update user.com data
export const gtmNotificationConsentSubmit = ({email}: {email: string}) => {
    const gtmData = {
        event: "notification_on",
        action: "form_submitted",
        email,
        zgoda_marketingowa: "tak"
    };
    hitGoogleTagManager(gtmData);
};
