import {cssBreakpointFactory, cssVariableFactory} from "@web2/linaria_styles";
import {themeGh} from "@web2/themes";

export const getThemeVariable = cssVariableFactory<typeof themeGh>();
export const getThemeBreakpoint = cssBreakpointFactory(themeGh.breakpoints);
export const getThemeBreakpointCorrect = cssBreakpointFactory(themeGh.breakpoints_correct);

const transformThemeToCssVariables = (theme: Object, base = "--"): string =>
    Object.entries(theme).reduce((css, [key, value]) => {
        const newBase = base + `${key}`;
        if (typeof value !== "object") {
            return css + newBase + `: ${value};\n`;
        }
        return css + transformThemeToCssVariables(value, newBase + "-");
    }, ``);

export const getThemeVariableList = transformThemeToCssVariables(themeGh);
