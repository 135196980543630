import {Dispatch} from "redux";

import {IAgency} from "../../agency/actions/fetch_agency_detail_at_route";
import {IAgencyOffersResponse} from "../../agency/actions/fetch_agency_offers_at_route";
import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export const fetchAgentOffersTypes = createRequestActionTypes({
    view: "AgentView",
    type: "GET",
    name: "AgentDetailOffers"
});

export const fetchAgentOffersAtRoute =
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IAgency) => (dispatch: Dispatch) => {
        dispatch({type: fetchAgentOffersTypes.start});

        const url = appendQueryString(apiLink.offers.base({})(null), {agent: prevActionResult.id});

        return getRequest(services, url)
            .then((res: IAgencyOffersResponse) => {
                dispatch({type: fetchAgentOffersTypes.success, result: res.data});
                return res;
            })
            .catch((err) => {
                dispatch({type: fetchAgentOffersTypes.error, err});
            });
    };
