import {capitalizeFirst} from "@web2/nodash";
import {numberWithDelimiter} from "@web2/string_utils";

import {ILocationStatisticsElementApartment} from "../../../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../../../app/utils/number";
import {returnPlurizedValues} from "../../utils/return-pluralized-values";
import {Heading} from "./Heading";

export const PrimaryMarketCity = (props: {
    locationName: string;
    locationDeclination: string | undefined;
    statistics: ILocationStatisticsElementApartment;
    uniqueInvestments: number | null | undefined;
}) => {
    const {locationName, locationDeclination, statistics, uniqueInvestments} = props;

    const headingString = `Nowe mieszkania ${locationName} - rynek pierwotny`;
    const pluralized = returnPlurizedValues(statistics.count);
    const primaryMarketLinkTitle = `Nowe mieszkania ${locationName}`;
    const primaryMarketTextWithoutLink = ` czekają właśnie na Ciebie!`;

    return (
        <>
            <Heading content={headingString} />

            <p>
                Interesuje Cię rynek pierwotny? {locationDeclination && capitalizeFirst(locationDeclination)} mamy{" "}
                {numberWithDelimiter(sizeFormat(statistics.count))} {pluralized.newPluralize} {pluralized.flatsPluralize} w {uniqueInvestments}{" "}
                {pluralized && uniqueInvestments && uniqueInvestments > 1 ? `${pluralized.investmentsPluralize} deweloperskich` : "inwestycji deweloperskiej"}.{" "}
                {primaryMarketLinkTitle + primaryMarketTextWithoutLink}
            </p>
            <p>
                Średnia powierzchnia mieszkań na rynku pierwotnym {locationDeclination} to {statistics.avg_size} mkw.{" "}
            </p>
            <p>
                {" "}
                Najmniejsze nowe mieszkanie ma {statistics.min_size} mkw. Największe mieszkanie od dewelopera ma powierzchnię {statistics.max_size} mkw.{" "}
            </p>
            <p>
                Już teraz <strong>znajdź i zamieszkaj</strong> w wymarzonym M w nowej inwestycji {locationDeclination}.
            </p>
        </>
    );
};
