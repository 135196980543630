import {OfferDealType} from "../../../offer/utils/constants_offer";
import {ViewType} from "../../view_type/view_type";

export const getListNamePrefix = (viewType: ViewType | null) => {
    switch (viewType) {
        case ViewType.AGENCY:
            return "agencja";
        case ViewType.AGENT:
            return "agent";
        case ViewType.DEVELOPER:
            return "deweloper";
        case ViewType.INVESTMENT:
            return "inwestycja";
        default:
            return "na";
    }
};

export const getListNameSuffix = (offerList: {deal_type: OfferDealType}[]) => {
    const hasRent = !!offerList.find((offer) => offer.deal_type === OfferDealType.RENT);
    const hasSell = !!offerList.find((offer) => offer.deal_type === OfferDealType.SELL);

    switch (true) {
        case hasRent && hasSell:
            return "wszystkie";
        case hasRent:
            return "wynajem";
        case hasSell:
            return "sprzedaz";
        default:
            return "";
    }
};
