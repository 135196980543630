import {isEmpty} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {fetchGooglePlace} from "../components/search_autocomplete/PlacesTab";
import {ISearchInputValue} from "../utils/ISearchInputValue";
import {SearchTab} from "./fetch_search_all_action";
import {AutocompletePrediction} from "./fetch_search_places_action";
import {searchFormActions} from "./search_form_actions";

const getGooglePlaceInfo = async (place: AutocompletePrediction) => {
    return new Promise((resolve) => {
        fetchGooglePlace(place, resolve);
    });
};

export const getItemOnClick = async (option: ISearchInputValue) => {
    if (option.tabType === SearchTab.Places) {
        return await getGooglePlaceInfo(option.place);
    }
    return option;
};

export const setItemOnEnter = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const state = getState().search;
    const activeItem = state.activeDropdownItem;
    const currentTab = state.currentTab;
    const places = state.places;
    const {fetchAllRequest} = state;
    const suggestions = state.suggestions;
    const inputValue = state.formValues["search"].label;

    /**
     * ACTIVE ITEM NOT DEFINED - when user hit enter without highlighting any item - take first
     */

    if (activeItem.id == null) {
        if (isEmpty(inputValue)) {
            const {lastSearch, lastSearchSuggestions} = getState().lastSearch;
            const lastSearchSet = [...lastSearch, ...lastSearchSuggestions];

            const pickedEntity = lastSearchSet[0];

            // Handling autosuggestion search with Google
            if (pickedEntity.suggest_type === "place") {
                const placeField = await getGooglePlaceInfo(pickedEntity);
                dispatch(searchFormActions.update({search: placeField}));
                return {search: placeField};
            }

            const combinedField = {
                label: pickedEntity.name,
                suggestions: pickedEntity,
                tabType: currentTab
            };

            if (pickedEntity.suggest_type === "location") {
                combinedField.label = pickedEntity.short_name;
            }

            dispatch(searchFormActions.update({search: combinedField}));
            return {search: combinedField};
        }

        switch (currentTab) {
            case SearchTab.SearchSuggestions:
                if (suggestions.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const combinedFirstItem = suggestions[0];
                    const combinedField = {
                        label: combinedFirstItem.suggest_type === "location" ? combinedFirstItem.short_name : "",
                        suggestions: combinedFirstItem,
                        tabType: currentTab
                    };
                    dispatch(searchFormActions.update({search: combinedField}));
                    return {search: combinedField};
                }
                return false;
            case SearchTab.Places:
                if (places.length) {
                    if (fetchAllRequest === RequestState.Waiting) {
                        return false;
                    }
                    const place = places[0];
                    const placeField = await getGooglePlaceInfo(place);
                    dispatch(searchFormActions.update({search: placeField}));
                    return {search: placeField};
                }
                return false;
            default:
                return false;
        }
    }

    /**
     * ACTIVE ITEM IS DEFINED
     */

    if (isEmpty(inputValue)) {
        const {lastSearch, lastSearchSuggestions} = getState().lastSearch;
        const lastSearchSet = [...lastSearch, ...lastSearchSuggestions];

        const pickedEntity = lastSearchSet[activeItem.id];

        // Handling autosuggestion search with Google
        if (pickedEntity.suggest_type === "place") {
            const placeField = await getGooglePlaceInfo(pickedEntity);
            dispatch(searchFormActions.update({search: placeField}));
            return {search: placeField};
        }

        const combinedField = {
            label: pickedEntity.name,
            suggestions: pickedEntity,
            tabType: SearchTab.SearchSuggestions
        };

        if (pickedEntity.suggest_type === "location") {
            combinedField.label = pickedEntity.short_name;
        }

        dispatch(searchFormActions.update({search: combinedField}));
        return {search: combinedField};
    }

    switch (currentTab) {
        case SearchTab.SearchSuggestions:
            if (suggestions.length) {
                if (fetchAllRequest === RequestState.Waiting) {
                    return false;
                }
                const combinedFirstItem = suggestions[activeItem.id];
                const combinedField = {
                    label: combinedFirstItem.suggest_type === "location" ? combinedFirstItem.short_name : "",
                    suggestions: combinedFirstItem,
                    tabType: currentTab
                };
                dispatch(searchFormActions.update({search: combinedField}));
                return {search: combinedField};
            }
            return false;
        case SearchTab.Places:
            const place = places[activeItem.id];
            const placeField = await getGooglePlaceInfo(place);
            dispatch(searchFormActions.update({search: placeField}));
            return {search: placeField};

        default:
            return false;
    }
};
