import {useState} from "react";

import {apiLink} from "../routes/api_link";
import {notifyBugsnag} from "../utils/bugsnag/notify_bugsnag";
import {getRequest} from "../utils/request_response_utils/request";
import {catch5xx, catch404} from "../utils/request_response_utils/response_error";

interface IProps {
    uuid?: string; // offer uuid
    fallbackNumber?: string | null;
    isPrimaryMarket?: boolean;
}

/**
 * Hook handles rotated/revealed phone number state and fetching, should fetch only when used on aftermarket offers.
 * For primary market it should simply reveal the fallback number.
 *
 * note: if we accidentally fetch a primary market offer, api will return a clip_phone
 *
 * @param uuid
 * @param fallbackNumber
 * @param isPrimaryMarket
 */

export const useRevealedNumber = ({uuid, fallbackNumber, isPrimaryMarket}: IProps) => {
    const [rotatedNumber, setRotatedNumber] = useState<string | null>(null);
    const [isRotatedNumberLoading, setIsRotatedNumberLoading] = useState(false);

    const getRotatedNumber = async (): Promise<string | null> => {
        // missing offer id or primary market - show fallback number:
        if ((!uuid || isPrimaryMarket) && fallbackNumber) {
            setRotatedNumber(fallbackNumber);
            return fallbackNumber;
        }

        // aftermarket offer - fetch rotated number:
        if (uuid) {
            setIsRotatedNumberLoading(true);
            const url = apiLink.rotatedNumbers.offer({})({uuid});

            return getRequest({}, url)
                .then((res) => {
                    if (res.clip_number) {
                        setRotatedNumber(res.clip_number);
                        setIsRotatedNumberLoading(false);

                        return res.clip_number;
                    }

                    fallbackNumber && setRotatedNumber(fallbackNumber);
                    setIsRotatedNumberLoading(false);
                    return fallbackNumber;
                })
                .catch(
                    catch404(() => {
                        setIsRotatedNumberLoading(false);
                        return "404";
                    })
                )
                .catch(
                    catch5xx((err) => {
                        const message = `useRotatedNumber: error while fetching rotated phone number`;

                        notifyBugsnag({message}, message, {apiUrl: url});
                        fallbackNumber && setRotatedNumber(fallbackNumber);
                        setIsRotatedNumberLoading(false);
                        return fallbackNumber;
                    })
                );
        }

        if (fallbackNumber) {
            setRotatedNumber(fallbackNumber);
        }
        return fallbackNumber || null;
    };

    return {
        getRotatedNumber,
        rotatedNumber,
        isRotatedNumberLoading
    };
};
