import {find, includes, isEmpty} from "lodash";
import * as lscache from "lscache";
import {Dispatch} from "redux";

import {ISearchSuggestions, SearchTab} from "../../search/actions/fetch_search_all_action";
import {AutocompletePrediction} from "../../search/actions/fetch_search_places_action";
import {ISearchInputValue} from "../../search/utils/ISearchInputValue";
import {LAST_SEARCH_LS_KEY} from "../constants/storage_keys";
import {IAction} from "../interfaces/IAction";

const MAX_LS_LAST_SEARCH = 4;

export interface ILastSearchPlace extends AutocompletePrediction {
    suggest_type: "place";
    name: string;
    short_name: string;
    agency_name: string;
    offer_count_total: number;
}

export type ILastSearch = ILastSearchPlace | ISearchSuggestions;

export interface ILoadLocalStorageLastSearchToStore extends IAction {
    lastSearch: ILastSearch[];
}

export const SET_LOCAL_STORAGE_LAST_SEARCH = "SET_LOCAL_STORAGE_LAST_SEARCH_TO_STORE";

export const loadLocalStorageLastSearchToStore =
    () =>
    async (dispatch: Dispatch): Promise<any> => {
        const lastSearch: ILastSearch[] = lscache.get(LAST_SEARCH_LS_KEY);
        if (lastSearch && lastSearch.length) {
            dispatch({type: SET_LOCAL_STORAGE_LAST_SEARCH, lastSearch});
        }
        return;
    };

export const getLocalStorageLastSearch = () => {
    const lastSearch = lscache.get(LAST_SEARCH_LS_KEY);
    if (!lastSearch) {
        return [];
    }
    return lastSearch;
};

export const setLocalStorageLastSearch = (values: ISearchInputValue) => async (dispatch: Dispatch) => {
    const priorSearches = getLocalStorageLastSearch();
    let value;

    switch (values.tabType) {
        case SearchTab.Places:
            if (!!find(priorSearches, {place_id: values.place.place_id})) {
                break;
            }
            value = {
                ...values.place,
                suggest_type: "place"
            };
            break;
        case SearchTab.SearchSuggestions:
            const {suggest_type} = values.suggestions;

            if (!!find(priorSearches, {id: values.suggestions.id})) {
                break;
            }
            value = {
                ...values.suggestions,
                suggest_type: suggest_type
            };
            break;
        default:
            value = [];
            break;
    }

    if (!isEmpty(value) && !includes(priorSearches, value)) {
        const lastSearch = priorSearches.length === MAX_LS_LAST_SEARCH ? [...priorSearches.slice(1, MAX_LS_LAST_SEARCH), value] : [...priorSearches, value];
        dispatch({type: SET_LOCAL_STORAGE_LAST_SEARCH, lastSearch});
        return lscache.set(LAST_SEARCH_LS_KEY, lastSearch);
    }
    dispatch({type: SET_LOCAL_STORAGE_LAST_SEARCH, lastSearch: priorSearches});
    return lscache.set(LAST_SEARCH_LS_KEY, priorSearches);
};
