import {numberWithDelimiter, pluralize} from "@web2/string_utils";

import {sizeFormat} from "../../../../../app/utils/number";
import {IGenericListingParagraphRegular, StatisticsLocationGenerics} from "../../interfaces/interfaces_generic_listings";
import {returnPlurizedValues} from "../../utils/return-pluralized-values";

const getRoomCount = (total: number) => {
    return numberWithDelimiter(sizeFormat(total)) || 0;
};

export const StatisticsByRooms = (props: IGenericListingParagraphRegular & {statisticsLocation: StatisticsLocationGenerics}) => {
    const {propertyType, listingTypePhrase, dealTypePhrase, isSell, locationDeclination, statisticsLocation} = props;

    const stats = statisticsLocation;
    const offerPluralism = pluralize(["oferta", "oferty", "ofert"]);

    const oneRoomFlatsCountRent = stats.apartment.rent.one_room.count;
    const twoRoomFlatsCountRent = stats.apartment.rent.two_rooms.count;
    const threeRoomFlatsCountRent = stats.apartment.rent.three_rooms.count;
    const fourRoomFlatsCountRent = stats.apartment.rent.four_rooms.count;

    const oneRoomFlatsCountSell = stats.apartment.sell.one_room.count;
    const twoRoomFlatsCountSell = stats.apartment.sell.two_rooms.count;
    const threeRoomFlatsCountSell = stats.apartment.sell.three_rooms.count;
    const fourRoomFlatsCountSell = stats.apartment.sell.four_rooms.count;

    const oneRoomTotal = isSell ? oneRoomFlatsCountSell : oneRoomFlatsCountRent;
    const twoRoomTotal = isSell ? twoRoomFlatsCountSell : twoRoomFlatsCountRent;
    const threeRoomTotal = isSell ? threeRoomFlatsCountSell : threeRoomFlatsCountRent;
    const fourRoomTotal = isSell ? fourRoomFlatsCountSell : fourRoomFlatsCountRent;

    const fiveRoomFlatsCountRent =
        stats.apartment.rent.count -
        stats.apartment.rent.one_room.count -
        stats.apartment.rent.two_rooms.count -
        stats.apartment.rent.three_rooms.count -
        stats.apartment.rent.four_rooms.count;

    const fiveRoomFlatsCountSell =
        stats.apartment.sell.count -
        stats.apartment.sell.one_room.count -
        stats.apartment.sell.two_rooms.count -
        stats.apartment.sell.three_rooms.count -
        stats.apartment.sell.four_rooms.count;

    const fiveRoomTotal = isSell ? fiveRoomFlatsCountSell : fiveRoomFlatsCountRent;

    const li = {
        flatsStudio: isSell
            ? `kawalerki ${dealTypePhrase} (${oneRoomTotal} ${offerPluralism(oneRoomTotal)})`
            : `${getRoomCount(oneRoomTotal)} ${returnPlurizedValues(oneRoomTotal).studioPluralize} ${dealTypePhrase}; `,
        flatsTwoRoom: isSell
            ? `mieszkania 2 pokoje ${dealTypePhrase} (${twoRoomTotal} ${offerPluralism(twoRoomTotal)})`
            : `${getRoomCount(twoRoomTotal)} 2-${returnPlurizedValues(twoRoomTotal).roomsPluralize} ${
                  returnPlurizedValues(twoRoomTotal).flatsPluralize
              } ${dealTypePhrase};`,
        flatsThreeRoom: isSell
            ? `mieszkania 3 pokoje  ${dealTypePhrase} (${threeRoomTotal} ${offerPluralism(threeRoomTotal)})`
            : `${getRoomCount(threeRoomTotal)} 3-${returnPlurizedValues(threeRoomTotal).roomsPluralize} ${
                  returnPlurizedValues(threeRoomTotal).flatsPluralize
              } ${dealTypePhrase};`,
        flatsFourRoom: isSell
            ? `mieszkania 4 pokoje  ${dealTypePhrase} (${fourRoomTotal} ${offerPluralism(fourRoomTotal)})`
            : `${getRoomCount(fourRoomTotal)} 4-${returnPlurizedValues(fourRoomTotal).roomsPluralize} ${
                  returnPlurizedValues(fourRoomTotal).flatsPluralize
              } ${dealTypePhrase};`,
        flatsFiveRoom: isSell
            ? `mieszkania 5 pokoi i więcej (${fourRoomTotal} ${offerPluralism(fourRoomTotal)})`
            : `${getRoomCount(fiveRoomTotal)} 5-${returnPlurizedValues(fourRoomTotal).roomsPluralize} i więcej ${
                  returnPlurizedValues(fiveRoomTotal).flatsPluralize
              } ${dealTypePhrase}.`
    };

    // Example of HREF Link inside list for future implementations

    // <li>
    //         <a
    //             href={getOfferListUrl({
    //                 offerType: OfferType.apartment,
    //                 type: UrlDealType.sell,
    //                 tag: UrlTagSlug.STUDIO
    //             })}
    //             title={li.flatsStudio}
    //         >
    //             {li.flatsStudio}
    //         </a>
    // </li>

    const hasEmptyRentList =
        oneRoomFlatsCountRent > 0 || twoRoomFlatsCountRent > 0 || threeRoomFlatsCountRent > 0 || fourRoomFlatsCountRent > 0 || fiveRoomFlatsCountRent > 0;

    const hasEmptySellList =
        oneRoomFlatsCountSell > 0 || twoRoomFlatsCountSell > 0 || threeRoomFlatsCountSell > 0 || fourRoomFlatsCountSell > 0 || fiveRoomFlatsCountSell > 0;

    if ((isSell && !hasEmptySellList) || (!isSell && !hasEmptyRentList)) {
        return null;
    }

    return (
        <>
            <strong>
                Jeśli szukasz {listingTypePhrase}
                {!isSell && ` ${dealTypePhrase}`}
                {locationDeclination ? ` ${locationDeclination}` : " w całym kraju"}, na portalu gethome.pl znajdziesz:
            </strong>
            <ul>
                {!isSell && (
                    <>
                        {oneRoomFlatsCountRent > 0 && <li>{li.flatsStudio}</li>}
                        {twoRoomFlatsCountRent > 0 && <li>{li.flatsTwoRoom}</li>}
                        {threeRoomFlatsCountRent > 0 && <li>{li.flatsThreeRoom}</li>}
                        {fourRoomFlatsCountRent > 0 && <li>{li.flatsFourRoom}</li>}
                        {fiveRoomFlatsCountRent > 0 && <li>{li.flatsFiveRoom}</li>}
                    </>
                )}
                {isSell && (
                    <>
                        {oneRoomFlatsCountSell > 0 && <li>{li.flatsStudio}</li>}
                        {twoRoomFlatsCountSell > 0 && <li>{li.flatsTwoRoom}</li>}
                        {threeRoomFlatsCountSell > 0 && <li>{li.flatsThreeRoom}</li>}
                        {fourRoomFlatsCountSell > 0 && <li>{li.flatsFourRoom}</li>}
                        {fiveRoomFlatsCountSell > 0 && <li>{li.flatsFiveRoom}</li>}
                    </>
                )}
            </ul>
        </>
    );
};
