import {numberWithDelimiter} from "@web2/string_utils";

import {
    ILocationStatisticsApartmentAll,
    ILocationStatisticsApartmentRent,
    ILocationStatisticsApartmentSell,
    ILocationStatisticsParent
} from "../../../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../../../app/utils/number";
import {nowrap} from "../../../../../styles/helpers";

export const SellPrices = (props: {
    locationDeclination: string | undefined;
    locationName: string;
    statistics: ILocationStatisticsParent<ILocationStatisticsApartmentAll, ILocationStatisticsApartmentSell, ILocationStatisticsApartmentRent>;
    hasPrimaryMarket: boolean | undefined;
    hasAfterMarket: boolean | undefined;
}) => {
    const {locationDeclination, locationName, statistics, hasPrimaryMarket, hasAfterMarket} = props;
    return (
        <>
            <h2> Ceny mieszkań {locationDeclination}</h2>
            <>
                {statistics?.sell.avg_price_per_sqm && statistics?.sell.avg_price_per_sqm > 0 ? (
                    <p>
                        Średnia cena mieszkania na sprzedaż {locationDeclination} to {numberWithDelimiter(sizeFormat(statistics?.sell.avg_price_per_sqm))} zł za
                        m<sup>2</sup>;
                    </p>
                ) : null}
                <ul>
                    {statistics?.all.aftermarket.avg_price_per_sqm &&
                    statistics?.sell.avg_price_per_sqm !== statistics?.all.aftermarket.avg_price_per_sqm &&
                    statistics?.sell.avg_price_per_sqm > 0 ? (
                        <li>
                            średnia cena mieszkań na rynku wtórnym {locationDeclination} to{" "}
                            {numberWithDelimiter(sizeFormat(statistics?.all.aftermarket.avg_price_per_sqm))} zł za m<sup>2</sup> ;
                        </li>
                    ) : null}
                    {hasPrimaryMarket && statistics.sell.primary_market && statistics.sell.primary_market.avg_price_per_sqm > 0 ? (
                        <li>
                            rynek pierwotny {locationDeclination} to z kolei wydatek średnio{" "}
                            {numberWithDelimiter(sizeFormat(statistics?.sell.primary_market.avg_price_per_sqm))} zł za m<sup>2</sup>.
                        </li>
                    ) : null}
                </ul>
                <p>Gdzie znajdują się najdroższe mieszkania {locationDeclination}?</p>
                <ul>
                    {hasAfterMarket && (
                        <>
                            {statistics.all.aftermarket.max_price && (
                                <li>
                                    najdroższe mieszkanie na rynku wtórnym {locationDeclination} znajdziesz w cenie{" "}
                                    <span css={nowrap}>{numberWithDelimiter(sizeFormat(statistics.all.aftermarket.max_price))}</span> zł;
                                </li>
                            )}
                            {statistics.all.aftermarket.max_price_per_sqm ? (
                                <li>
                                    najwyższa cena metra kwadratowego z rynku wtórnego {locationDeclination} wynosi{" "}
                                    {numberWithDelimiter(sizeFormat(statistics.all.aftermarket.max_price_per_sqm))} zł/m<sup>2</sup>;
                                </li>
                            ) : null}
                        </>
                    )}
                    {hasPrimaryMarket && (
                        <>
                            {statistics.sell.primary_market && statistics.sell.primary_market.max_price > 0 && (
                                <li>
                                    za najdroższe nowe mieszkanie z rynku pierwotnego {locationDeclination} należy zapłacić{" "}
                                    {numberWithDelimiter(sizeFormat(statistics.sell.primary_market.max_price))} zł;
                                </li>
                            )}
                            {statistics.sell.primary_market && statistics.sell.primary_market.max_price_per_sqm > 0 && (
                                <li>
                                    najwyższa cena metra kw. nowego mieszkania na rynku pierwotnym sięga{" "}
                                    {numberWithDelimiter(sizeFormat(statistics.sell.primary_market.max_price_per_sqm))} zł/m<sup>2</sup>.
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </>
        </>
    );
};
