import {useEffect, useRef, useState} from "react";
import {useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {ArrowWithOrientationIcon} from "@web2/icons";

import {IApplicationOffer} from "../../application/components/Application";
import {ExternalContent} from "../../offer/atoms/ExternalContent";
import {ExternalContentMaskedContacts} from "../../offer/atoms/ExternalContentMaskedContacts";
import {cubicTransition} from "../../styles/mixins";
import {IGtmOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {NoPrint} from "../atoms/NoPrint";
import {getIsPrimaryMarket} from "../utils/get_is_primary_market";

export enum HideContactDataType {
    MASK = 1,
    REMOVE = 2
}
type IExpandLinkSide = "left" | "right" | "center";
type IProps = IHideContactDataScenarios & {
    description?: string;
    descriptionJsx?: JSX.Element;
    minHeight?: number;
    lessCopy?: string;
    moreCopy?: string;
    expandLinkSide?: IExpandLinkSide;
};

export type IHideContactDataScenarios =
    | ({
          hideContactDataType?: HideContactDataType.REMOVE | never;
      } & MaskedContactTypes)
    | ({
          hideContactDataType: HideContactDataType.MASK;
      } & Required<MaskedContactTypes>); // require props because we mask contacts with application modal and rotated phone numbers

type MaskedContactTypes = {
    offer?: IGtmOffer & {
        agency?: {
            phone_number?: string;
        } | null;
        overbudget: boolean;
    };
    gtmSource?: GtmSource;
};

export const ExpansionPanelReadMore = (props: IProps) => {
    const [panelOpened, togglePanel] = useState(false);
    const expansionPanelClassName = classNames(panelOpened && "expanded");
    const theme = useTheme();

    const [elementHeight, setElementHeight] = useState<number | null>(null);
    const elementRef = useRef<HTMLElement | null>(null);
    const elementRefHandle = (elem: HTMLElement | null) => {
        elementRef.current = elem;
    };

    useEffect(() => {
        elementRef.current && setElementHeight(elementRef.current.offsetHeight);
    }, [props.description, props.descriptionJsx]);

    const readMore = panelOpened ? (
        <>
            {props.lessCopy ? props.lessCopy : "Czytaj mniej"}{" "}
            <ArrowWithOrientationIcon width={12} height={12} orientation={"up"} fill={theme.colors.brand_primary} />
        </>
    ) : (
        <>
            {props.moreCopy ? props.moreCopy : "Czytaj więcej"}{" "}
            <ArrowWithOrientationIcon width={12} height={12} orientation={"down"} fill={theme.colors.brand_primary} />
        </>
    );

    const isPrimaryMarket = props.hideContactDataType === HideContactDataType.MASK && getIsPrimaryMarket({offer: props.offer});

    const content = (
        <div ref={elementRefHandle}>
            {props.description &&
                (props.hideContactDataType === HideContactDataType.MASK ? (
                    <ExternalContentMaskedContacts
                        content={props.description}
                        offer={props.offer}
                        fallbackNumber={(props.offer.agent?.phone_number || props.offer.agency?.phone_number) ?? undefined}
                        isPrimaryMarket={isPrimaryMarket}
                        gtmSource={props.gtmSource}
                        hidePhoneNumber={props.offer.overbudget}
                    />
                ) : (
                    <ExternalContent content={props.description} removeContactData={props.hideContactDataType === HideContactDataType.REMOVE} />
                ))}

            {props.descriptionJsx && <ExternalContent>{props.descriptionJsx}</ExternalContent>}
        </div>
    );

    return (
        <>
            <ExpandableContent minHeight={props.minHeight} className={expansionPanelClassName}>
                {content}
            </ExpandableContent>

            <ExpandLinkHolder expandLinkSide={props.expandLinkSide}>
                {elementHeight && elementHeight > (props.minHeight ?? 200) && (
                    <ExpandLink className="js-expand-description" onClick={() => togglePanel(!panelOpened)}>
                        {readMore}
                    </ExpandLink>
                )}
            </ExpandLinkHolder>
        </>
    );
};

/*
styles
 */

interface IExpandableContent {
    minHeight?: number;
}

const ExpandableContent = styled.div<IExpandableContent>`
    min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "unset")};
    max-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "200px")};
    transition: max-height 300ms ${cubicTransition};
    overflow: hidden;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_lg_ipad}) {
        max-height: 110px !important;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.screen_xl}) {
        max-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "200px")} !important;
    }

    &.expanded {
        transition: min-height 300ms ${cubicTransition};
        max-height: 100% !important;
        height: auto;
    }
`;

interface IExpandLinkSideHolder {
    expandLinkSide?: IExpandLinkSide;
}

const ExpandLinkHolder = styled(NoPrint)<IExpandLinkSideHolder>`
    text-align: ${(props) => (props.expandLinkSide ? props.expandLinkSide : "right")};
`;

const ExpandLink = styled.span`
    display: inline-block;
    margin: 2.5rem 0 1rem;
    color: ${(props) => props.theme.colors.brand_primary};
    font-size: 1.4rem;
    cursor: pointer;

    .svg-icon {
        margin-left: 1rem;
    }
`;
