import {RefObject, useEffect} from "react";
import {useSelector} from "react-redux";

import {IStore} from "../../../../../app/reducers/hybrid_reducer";
import {getNormalizedCoordinates} from "../../../../../app/utils/get_normalized_coordinates";
import {gtmOfferListEvents} from "../../../../../tracking/google_tag_manager/gtm_offer_list_events";
import {IGoogleMapApi} from "./GoogleMapOfferListDesktop";

/**
 * Hook places and opens google map infowindow presenting offerdata. Offer to show is selected by offer id from offer list store.
 * @param mapRef: ref to GoogleMap component
 * @param panFunction: custom pan function
 * @param hoveredOfferId: string, id of an offer hovered on list
 */
export const useOfferBoxHoverListingActionDesktop = (
    mapRef: RefObject<IGoogleMapApi>,
    panFunction: (map: google.maps.Map, position?: google.maps.LatLng | google.maps.LatLngLiteral | null) => void,
    hoveredOfferId?: string | null
) => {
    const map = mapRef.current?.getMapInstance();
    const offer = useSelector((store: IStore) => hoveredOfferId && store.offerList.offers.offers.find((offer) => offer.id === hoveredOfferId));

    useEffect(() => {
        if (!offer) {
            // OfferBox blur
            mapRef.current?.closeInfoWindows();
        }

        if (hoveredOfferId && offer && map) {
            // OfferBox hover
            const coordinates = getNormalizedCoordinates(offer?.coordinates);
            panFunction(map, coordinates);
            mapRef.current?.openMarkerInfoWindow(hoveredOfferId);
            gtmOfferListEvents.offerBox.hover();
        }
    }, [hoveredOfferId, offer, map]);
};
