import * as React from "react";

export interface ICheckboxLogic {
    render: React.FC<ICheckboxRenderProps>;
}

export interface ICheckboxLogicProps {
    name: string;
    checked: boolean;
    value: ICheckboxValue;
    readOnly?: boolean;
    onChange: (name: string, checked: boolean, value: ICheckboxValue) => void;
    onClick?: () => void;
}

export interface ICheckboxRenderProps {
    onChange: () => void;
    onKeyDown: (event: React.KeyboardEvent) => void;
    onClick: () => void;
}

export type ICheckboxValue = boolean | string | number;

export const CheckboxLogic: React.FC<ICheckboxLogic & ICheckboxLogicProps> = (props) => {
    const onChange = () => {
        if (!props.readOnly) {
            props.onChange(props.name, !props.checked, props.value);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        // keyCode 32 === 'space'
        if (event.keyCode === 32) {
            onChange();
        }
    };

    return props.render({onChange, onKeyDown, onClick: onChange});
};
