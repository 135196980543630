import React, {MouseEvent, useEffect, useReducer, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import * as lscache from "lscache";
import {darken} from "polished";
import {themeGh} from "@web2/global_styles";
import {Modal} from "@web2/modal2";
import {FetchStateType} from "@web2/react_utils";
import {BounceLoader} from "@web2/ui_utils";

import {IApplicationFetchOffer} from "../../application/actions/application_fetch_offer";
import {errorStyle, loaderWrapper} from "../../application/components/ApplicationModalAgencyOffers";
import {hitPaywallEvents, PaywallPrice, PaywallStep} from "../../tracking/common/hit_paywall_event";
import {IGtmOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {gtmInquiryWithOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_inquiry";
import {GtmContactType} from "../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {Activated} from "./atoms/Activated";
import {GetInfo} from "./atoms/GetInfo";
import {SelectPakage} from "./atoms/SelectPakage";
import {SUBSCRIPTION_KEY} from "./utils/get_subsciption_status";

export enum SUBSCRIPTION_STATUS {
    TRUE = "TRUE",
    FALSE = "FALSE"
}

enum ModalPrivateOffersActionTypes {
    ASK_FOR_OFFER = "ASK_FOR_OFFER",
    SELECT_PACKAGE = "SELECT_PACKAGE",
    PACKAGE_ACTIVATED = "PACKAGE_ACTIVATED"
}

interface IProps {
    viewType: ViewType | null;
    modalState: boolean;
    offerId: string | undefined;
    onModalClose?: (e?: React.MouseEvent) => void;
    fetchOffer: () => void;
    offer: IApplicationFetchOffer;
    fetchState: FetchStateType;
    onOfferClick?: (e: React.MouseEvent<any, any>, offer: IGtmOffer) => void;
}

// An interface for our state
interface IViewStateModalPrivateOffers {
    askForOffer: boolean;
    selectPackage: boolean;
    packageActivated: boolean;
}

// An interface for our actions
const INITIAL_VIEW_STATE_MODAL_PRIVATE_OFFERS = {
    askForOffer: true,
    selectPackage: true,
    packageActivated: false
};

interface IActionSwitchViewModalPrivateOffers {
    type: ModalPrivateOffersActionTypes;
}

const reducer = (state: IViewStateModalPrivateOffers, action: IActionSwitchViewModalPrivateOffers) => {
    const {type} = action;

    switch (type) {
        case ModalPrivateOffersActionTypes.ASK_FOR_OFFER:
            return {
                askForOffer: true,
                selectPackage: false,
                packageActivated: false
            };
        case ModalPrivateOffersActionTypes.SELECT_PACKAGE:
            return {
                askForOffer: false,
                selectPackage: true,
                packageActivated: false
            };
        case ModalPrivateOffersActionTypes.PACKAGE_ACTIVATED:
            return {
                askForOffer: false,
                selectPackage: false,
                packageActivated: true
            };
        default:
            return state;
    }
};
const unknownError = "Przepraszamy, wystąpił nieoczekiwany błąd.";

export const ApplicationModalPrivateOffers = (props: IProps) => {
    const {fetchState, offer, fetchOffer, onOfferClick} = props;
    const [paywallPrice, setPaywallPrice] = useState<PaywallPrice | undefined>();
    const [state, dispatch] = useReducer<
        (state: IViewStateModalPrivateOffers, action: IActionSwitchViewModalPrivateOffers) => typeof INITIAL_VIEW_STATE_MODAL_PRIVATE_OFFERS
    >(reducer, INITIAL_VIEW_STATE_MODAL_PRIVATE_OFFERS);

    const switchHeader = () => {
        if (state.askForOffer) {
            return "Zapytaj o ofertę";
        }
        if (state.selectPackage) {
            return "Wybierz pakiet";
        }
        if (state.packageActivated) {
            return "Aktywowaliśmy Twój dostęp za darmo!";
        }
        return;
    };

    useEffect(() => {
        hitPaywallEvents({offer_id: props.offer.id, paywall_step: PaywallStep.INFORMATION});
    }, []);

    const handlePayment = () => {
        hitPaywallEvents({offer_id: props.offer.id, paywall_step: PaywallStep.PAYMENT, paywall_price: paywallPrice});
        gtmInquiryWithOffer({
            viewType: props.viewType,
            offer,
            contactType: GtmContactType.PHONE,
            gtmSource: GtmSource.PAYWALL
        });
        lscache.set(SUBSCRIPTION_KEY, true);
        dispatch({type: ModalPrivateOffersActionTypes.PACKAGE_ACTIVATED});
    };
    const handleGetAccess = () => {
        hitPaywallEvents({offer_id: props.offer.id, paywall_step: PaywallStep.GET_ACCESS});
        dispatch({type: ModalPrivateOffersActionTypes.SELECT_PACKAGE});
    };

    const handleOfferReturn = (e: MouseEvent<any, any>) => {
        hitPaywallEvents({offer_id: props.offer.id, paywall_step: PaywallStep.OFFER_RETURN});

        closeModal(e);
        onOfferClick?.(e, offer);
    };

    const header = <div css={privateOffersModalHeader(themeGh, state.packageActivated)}>{switchHeader()}</div>;

    const closeModal = (e?: MouseEvent) => {
        props.onModalClose?.(e);
    };
    const onModalClose = (e?: MouseEvent) => {
        closeModal(e);
    };

    const renderError = () => <div css={errorStyle}>{unknownError}</div>;

    return (
        <Modal modalState={props.modalState} header={header} css={privateOffersModal(themeGh, state)} onModalClose={onModalClose} type="window">
            <PrivateOffersModalHolder packageActivated={state.packageActivated}>
                {fetchState === FetchStateType.Waiting || fetchState === FetchStateType.None ? (
                    <div css={loaderWrapper}>
                        <BounceLoader color="#9069c0" size="5px" />
                    </div>
                ) : (
                    <>
                        {fetchState == FetchStateType.Success ? (
                            <>
                                <>{state.askForOffer && <GetInfo offer={offer} fetchState={fetchState} onClick={handleGetAccess} />}</>
                                <>{!state.askForOffer && state.selectPackage && <SelectPakage onClick={handlePayment} setPaywallPrice={setPaywallPrice} />}</>
                                <>{!state.askForOffer && !state.selectPackage && state.packageActivated && <Activated onClick={handleOfferReturn} />}</>
                            </>
                        ) : fetchState == FetchStateType.Error ? (
                            renderError()
                        ) : null}
                    </>
                )}
            </PrivateOffersModalHolder>
        </Modal>
    );
};

export const styledButtonCTAModalPrivateOffers = (theme: Theme) => css`
    display: flex;
    width: 100%;
    border-radius: ${theme.borders.border_radius_base};
    background-color: ${theme.colors.green_darker};
    border-color: ${theme.colors.green_darker};

    &:hover:enabled,
    &:active:enabled,
    &:focus:enabled {
        background-color: ${darken(0.1, theme.colors.green_darker)};
        border-color: ${darken(0.1, theme.colors.green_darker)};
    }

    &:disabled,
    &:hover:disabled,
    &:active:disabled,
    &:focus:disabled {
        background-color: ${darken(0.1, theme.colors.gray_light)};
        border-color: ${darken(0.1, theme.colors.gray_light)};
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: fit-content;
    }
`;

const PrivateOffersModalHolder = styled.div<{packageActivated: boolean}>`
    overflow: auto;
    height: 100%;
    padding: 0 1.6rem 1.6rem;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 81rem;
        height: ${(props) => (props.packageActivated ? "17rem" : "40rem")};
        padding: 0 2.8rem 2.8rem;
    }
`;

const privateOffersModal = (theme: Theme, state: typeof INITIAL_VIEW_STATE_MODAL_PRIVATE_OFFERS) => css`
    height: 100%;
    width: 100%;
    max-width: 70rem;
    border-radius: ${theme.other.border_radius};
    background-color: ${state.askForOffer || state.packageActivated ? theme.colors.body_bg : theme.colors.gray_very_dark};

    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        height: unset;
        width: unset;
        max-width: unset;

        .modal-close-button {
            padding: 2.8rem;
        }
    }
`;

export const privateOffersModalHeader = (theme: Theme, activatedPackage: boolean) => css`
    color: ${theme.colors.brand_primary};
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    padding: 1.6rem;
    text-align: ${activatedPackage ? "center" : "left"};

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 2.8rem;
    }
`;
