import {debounce} from "lodash";

import {hitGoogleTagManager} from "./gtm_init";

export const gtmOfferListEvent = (element: "listing" | "map_pin", action: "hover" | "click") => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "usability",
        GAEventAction: `${element}_${action}`,
        GAEventNoInteraction: false
    };
    hitGoogleTagManager(gtmData);
};

export const gtmOfferListEvents = {
    pin: {
        hover: debounce(() => gtmOfferListEvent("map_pin", "hover"), 1000),
        click: () => gtmOfferListEvent("map_pin", "click")
    },
    offerBox: {
        hover: () => gtmOfferListEvent("listing", "hover"),
        click: () => gtmOfferListEvent("listing", "click")
    }
};

export const gtmOfferListSortEvent = (sort_type: string) => {
    const gtmData = {
        event: "offers_sorting",
        sort_type: sort_type
    };
    hitGoogleTagManager(gtmData);
};
