import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
    accentColor?: string;
}

export const ImageIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "6.35mm"} height={props.height || "4.66mm"} viewBox="0 0 18 13.2">
            <path d="M5.63,7.25A.93.93,0,0,0,5.35,7,1,1,0,0,0,4,7.25L0,13.2H4.8L7.1,9.44Z" fill={props.accentColor || "#b1b7be"} />
            <path d="M12.25,3.8a1,1,0,0,0-.33-.34,1,1,0,0,0-1.37.34L7.1,9.44,4.8,13.2H18Z" fill={props.mainColor || "#fff"} />
            <path d="M7.8,3.6A1.8,1.8,0,1,0,6,1.8,1.81,1.81,0,0,0,7.8,3.6Z" fill={props.mainColor || "#fff"} />
        </svg>
    );
};
