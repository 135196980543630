import {ReactNode} from "react";
import {numberFormat} from "@web2/string_utils";

import {OfferType, UOfferTypes} from "../../../../utils/constants_offer";
import {firstLetterUppercase} from "../../../../utils/investment_utils";
import {IGenericListingParagraphWithStats} from "../../interfaces/interfaces_generic_listings";
import {returnPlurizedValues} from "../../utils/return-pluralized-values";

export const StatisticsTotals = (props: IGenericListingParagraphWithStats) => {
    const {statistics, propertyType, isSell, locationDeclination, dealTypePhrase} = props;
    const {minSize, maxSize, countryOfferCountTotal} = statistics;

    // Ready for next stages with more listing types
    const switchListingPhrase = (listingType: UOfferTypes) => {
        switch (listingType) {
            case OfferType.apartment:
                return "mieszkań";
            // case OfferType.house:
            //     return "domów";
        }
    };

    const spaceRange = (
        <>
            {minSize !== maxSize ? (
                <>
                    od <strong>{minSize || 0}</strong> m<sup>2</sup> do <strong>{maxSize}</strong> m<sup>2</sup>
                </>
            ) : (
                <>
                    <strong>{minSize || 0}</strong> m<sup>2</sup>
                </>
            )}
        </>
    );

    const sharedContent = (
        <>
            {switchListingPhrase(propertyType)} {dealTypePhrase}
            {minSize !== maxSize ? <> o powierzchni {spaceRange}</> : ""}.
        </>
    );
    // Ready for next stages with more listing types
    const switchContent = (listingType: UOfferTypes) => {
        switch (listingType) {
            case OfferType.apartment:
                return sharedContent;
            // case OfferType.house:
            //     return "domów zarówno z rynku pierwotnego, jak i wtórnego.";
        }
    };

    let content: string | ReactNode = "";

    if (isSell) {
        content = switchContent(propertyType);
    }
    if (!isSell) {
        content = sharedContent;
    }

    const pluralized = returnPlurizedValues(countryOfferCountTotal);

    return (
        <p>
            {locationDeclination ? firstLetterUppercase(locationDeclination) : "W całym kraju"} {pluralized.availablePluralize} {pluralized.bePluralize}&nbsp;
            <strong>{numberFormat(countryOfferCountTotal)}</strong> {pluralized.offersPluralize} {content}
        </p>
    );
};
