interface ILiItems {
    flatsWithGarden: string;
    flatsWithBalcony: string;
    flatsWithTerace: string;
}

interface IFlatsTypesCount {
    flatsWithGardenCount: number;
    flatsWithBalconyCount: number;
    flatsWithTeraceCount: number;
}

export const FeaturesFlats = (props: {isSell?: boolean; liItems: ILiItems; flatTypesCount: IFlatsTypesCount}) => {
    const {isSell, liItems, flatTypesCount} = props;

    return (
        <ul>
            {flatTypesCount.flatsWithGardenCount > 0 && <li>{liItems.flatsWithGarden};</li>}
            {flatTypesCount.flatsWithBalconyCount > 0 && <li>{liItems.flatsWithBalcony};</li>}
            {flatTypesCount.flatsWithTeraceCount > 0 && <li>{liItems.flatsWithTerace}.</li>}
        </ul>
    );
};
