import {millisecondsToHours} from "date-fns";

const NEW_OFFERS_MAX_HOURS_AGE = 3 * 24;

export const getIsNewOffer = (offer: {created_at: string}) => {
    const current = Date.parse(new Date().toUTCString());
    const created = Date.parse(offer.created_at);

    const diff = millisecondsToHours(current - created);

    return diff < NEW_OFFERS_MAX_HOURS_AGE;
};
