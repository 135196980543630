import * as React from "react";
import {ChangeEvent, useRef} from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {IFormFieldProps} from "@web2/form2";
import {SEARCH_INPUT_FIELD_TESTID} from "@web2/gh_page_object_models";

import {LoupeIcon} from "../../offer/list/components/icons/LoupeIcon";
import {getThemeBreakpoint, getThemeVariable} from "../../styles/linaria_variable_factory";

interface IOwnProps {
    autoFocus?: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
    activeItemLabel?: string;
    placeholder: string;
    isDropdownOpen: boolean;
    readOnly?: boolean;
    isPlaceholderValue: boolean; // whether we display value as a placeholder (font color difference)
    isHomepageSearch?: boolean;
}

interface IProps extends IOwnProps, IFormFieldProps<string, any> {}

export const SearchAutocompleteInput = (props: IProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    /**
     * Callback
     */

    const onMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
        // Safari unselects selected onFocus input value. This solves the problem.
        e.preventDefault();
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newInputValue = {
            ...props.value,
            label: (e.target as HTMLInputElement).value
        };

        props.onChange(props.name, newInputValue);
        props.openDropdown(); // dropdown should be opened after every change
    };

    const onDivClick = () => {
        inputRef.current && inputRef.current.focus();
        props.openDropdown();
    };
    const onIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        inputRef.current && inputRef.current.focus();
        props.isDropdownOpen ? props.closeDropdown() : props.openDropdown();
    };

    const onFocus = () => {
        inputRef.current && inputRef.current.select();
    };

    /**
     * Render
     */

    return (
        <div className={inputWrapper} onClick={onDivClick}>
            <MultiSelectInput
                ref={inputRef}
                type="search"
                autoComplete="off"
                name={props.name}
                value={props.activeItemLabel || props.value.label || ""}
                placeholder={props.placeholder}
                isPlaceholderValue
                onChange={onChange}
                onMouseUp={onMouseUp}
                readOnly={props.readOnly}
                autoFocus={props.autoFocus}
                onFocus={onFocus}
                data-testid={SEARCH_INPUT_FIELD_TESTID}
            />

            <div className={props.isHomepageSearch ? homepageIconWrapper : iconWrapper} onClick={onIconClick}>
                <LoupeIcon />
            </div>
        </div>
    );
};

const inputWrapper = css`
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    width: 100%;
`;

const iconWrapper = css`
    position: absolute;
    display: block;
    top: 10px;
    right: 12px;
    cursor: pointer;
    z-index: 1;
`;

const homepageIconWrapper = css`
    padding: 10px;
    color: white;
    cursor: text;
    background-color: ${getThemeVariable("colors-brand_primary")};
    border-radius: ${getThemeVariable("other-border_radius")};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: 10px;
    width: 36px;
    height: 36px;

    @media screen and (min-width: ${getThemeBreakpoint().screen_md}) {
        height: 45px;
        width: 45px;
    }

    svg {
        width: 18px;
        height: 18px;

        @media screen and (min-width: ${getThemeBreakpoint().screen_md}) {
            height: 25px;
            width: 25px;
        }
    }

    path {
        fill: #fff;
    }
`;

export const MultiSelectInput = styled.input<{isPlaceholderValue: boolean}>`
    width: 100%;
    height: 38px;
    font-size: ${getThemeVariable("fonts-font_size_base")};
    border-radius: ${getThemeVariable("other-border_radius")};
    border: 1px solid ${getThemeVariable("colors-gray_brighter")};
    padding: 9px 30px 9px 13px;

    &::-webkit-search-cancel-button {
        display: none;
    }

    &::placeholder {
        color: ${(props) => (props.isPlaceholderValue ? getThemeVariable("colors-text_color") : getThemeVariable("colors-gray_brighter"))};
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid rgb(90, 157, 230);
    }
`;
