import {isEqual, omit} from "lodash";
import {Dispatch} from "redux";
import {safelyParsePage} from "@web2/string_utils";

import {ICoordinates} from "../../../app/interfaces/response/offer_list";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {OfferMarketType} from "../../utils/constants_offer";
import {getOfferListFetchParams, IOfferListFetchParams} from "../../utils/get_offer_list_fetch_params";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {parsedOfferParams} from "./fetch_offer_list";
import {getDefaultOfferCountPerPage, ISearchResultRouteParams} from "./fetch_offer_list_at_route";

export interface IListingMarker {
    id: string;
    coordinates: ICoordinates;
    coordinates_accurate: boolean;
    offer_type: string[];
    price?: {
        total: number;
        currency: string;
    };
    market_type?: OfferMarketType;
    size?: number;
    slug: string;
    rooms?: number;
    investment?: {
        name: string;
        offer_count: number;
    };
}

export const DEFAULT_MARKERS_PER_PAGE = 500;

export const fetchOfferListMarkersTypes = createRequestActionTypes({
    view: "offerList",
    type: "GET",
    name: " offerListMarkers"
});

export const fetchOfferListMarkersAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>, prevRoute: IPrevRouteState | null) =>
    async (dispatch: Dispatch, getState: () => IStore) => {
        if (prevRoute == null) {
            // first markers fetch should be done clientside on offer list mount, subsequent fetches will be handled here
            return true;
        }

        const location = getState().offerList.location.location;
        const params = getOfferListFetchParams(route, location, getState().search.formValues.offer_type);

        // warning: passing latestQuery instead of generated params here will result in a race condition
        return dispatch(fetchOfferListMarkers(services, params));
    };

export const fetchOfferListMarkers =
    (services: Partial<IServices>, query?: IOfferListQuery | IOfferListFetchParams) => (dispatch: Dispatch, getState: () => IStore) => {
        const offerlListParams = query || getState().offerList.latestQuery;
        const offersPerPage = getDefaultOfferCountPerPage({offerType: offerlListParams.offer_type});
        const parsedOfferListPage = (offerlListParams.page && safelyParsePage(offerlListParams.page)) || 1;

        const markersPage = (() => {
            if (parsedOfferListPage > 1) {
                const upperBoundOfferIdx = parsedOfferListPage * offersPerPage - offersPerPage;
                return Math.ceil(upperBoundOfferIdx / DEFAULT_MARKERS_PER_PAGE).toString();
            }
            return "1";
        })();

        const fetchParams = {
            ...offerlListParams,
            ...(markersPage ? {page: markersPage} : {}),
            per_page: DEFAULT_MARKERS_PER_PAGE
        };

        // avoid fetching when marker query did not change
        const {latestQuery} = getState().offerList.markers;
        if (isEqual(omit(latestQuery, "sort"), omit(fetchParams, "sort"))) {
            return Promise.resolve(true);
        }

        dispatch({type: fetchOfferListMarkersTypes.start});

        const url = appendQueryString(apiLink.offers.coordinates({})(null), parsedOfferParams(fetchParams));
        return getRequest(services, url, "fetchOfferListMarker")
            .then((result: IListingMarker[]) => {
                const response = {
                    result,
                    latestQuery: fetchParams
                };
                return dispatch({type: fetchOfferListMarkersTypes.success, result: response});
            })
            .catch(catchAbortedError(() => false));
    };
