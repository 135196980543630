import {OfferMarketType} from "../../offer/utils/constants_offer";

interface IProps {
    agency?: {
        market_type?: OfferMarketType;
    };
    offer?: {
        market_type: OfferMarketType;
    } | null;
    investment?: {
        id: string;
    } | null;
}

export const getIsPrimaryMarket = ({offer, agency, investment}: IProps) => {
    return !!investment?.id || offer?.market_type === OfferMarketType.PRIMARY_MARKET || agency?.market_type === OfferMarketType.PRIMARY_MARKET;
};
