import * as React from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {includes} from "lodash";
import {numberWithDelimiter, pluralize} from "@web2/string_utils";

import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {OfferDealType} from "../../utils/constants_offer";
import {parseDealType} from "../../utils/utils";

interface IProps {
    params: {
        deal_type?: OfferDealType;
        offer_type: string;
    };
    locationStatistics: ILocationStatistics;
    isMapBig: boolean;
}

export const OfferListStatsHeaderInfo = (props: IProps) => {
    const locationDeclensionWhere = props.locationStatistics.location?.name_declension_where;
    const propertyPlural = pluralize(["mieszkanie i dom", "mieszkania i domy", "mieszkań i domów"]);

    const flatsPluralizm = pluralize(["mieszkanie", "mieszkania", "mieszkań"]);
    const housePluralizm = pluralize(["dom", "domy", "domów"]);

    const generatePrimaryMarketText = () => {
        const isPrimary = includes(props.params.offer_type, "primary_market");
        const isPrimaryHouses = includes(props.params.offer_type, "primary_market__house");
        const isPrimaryApartment = includes(props.params.offer_type, "primary_market__apartment");
        if (isPrimary) {
            if (isPrimaryHouses && !isPrimaryApartment) {
                return `Rynek pierwotny - ${numberWithDelimiter(props.locationStatistics.house.sell.primary_market.count)} ${housePluralizm(
                    props.locationStatistics.house.sell.primary_market.count
                )} na sprzedaż ${locationDeclensionWhere}`;
            }
            if (isPrimaryApartment && !isPrimaryHouses) {
                return `Rynek pierwotny - ${numberWithDelimiter(props.locationStatistics.apartment.sell.primary_market.count)} ${flatsPluralizm(
                    props.locationStatistics.apartment.sell.primary_market.count
                )} na sprzedaż ${locationDeclensionWhere}`;
            }
            return `Rynek pierwotny - ${numberWithDelimiter(props.locationStatistics.property.sell.primary_market.count)} ${propertyPlural(
                props.locationStatistics.property.sell.primary_market.count
            )} na sprzedaż ${locationDeclensionWhere}`;
        }
    };

    const generateAftermarketText = () => {
        const isAftermarket = includes(props.params.offer_type, "aftermarket");
        const isAftermarketHouses = includes(props.params.offer_type, "aftermarket__house");
        const isAftermarketApartments = includes(props.params.offer_type, "aftermarket__apartment");

        if (isAftermarket) {
            if (isAftermarketHouses && !isAftermarketApartments) {
                const housesCount = (dealType?: string) => {
                    switch (dealType) {
                        case OfferDealType.SELL:
                            return props.locationStatistics.house.sell.aftermarket.count;
                        case OfferDealType.RENT:
                            return props.locationStatistics.house.rent.count;
                        default:
                            return props.locationStatistics.house.all.aftermarket.count;
                    }
                };

                return `Rynek wtórny - ${numberWithDelimiter(housesCount(props.params.deal_type))} ${housePluralizm(
                    housesCount(props.params.deal_type)
                )} ${parseDealType(props.params.deal_type)} ${locationDeclensionWhere}`;
            }

            if (isAftermarketApartments && !isAftermarketHouses) {
                const apartmentsCount = (dealType?: string) => {
                    switch (dealType) {
                        case OfferDealType.SELL:
                            return props.locationStatistics.apartment.sell.aftermarket.count;
                        case OfferDealType.RENT:
                            return props.locationStatistics.apartment.rent.count;
                        default:
                            return props.locationStatistics.apartment.all.aftermarket.count;
                    }
                };

                return `Rynek wtórny - ${numberWithDelimiter(apartmentsCount(props.params.deal_type))} ${flatsPluralizm(
                    apartmentsCount(props.params.deal_type)
                )} ${parseDealType(props.params.deal_type)} ${locationDeclensionWhere}`;
            }

            const allCount = (dealType: string | undefined) => {
                switch (dealType) {
                    case OfferDealType.SELL:
                        return props.locationStatistics.property.sell.aftermarket.count;
                    case OfferDealType.RENT:
                        return props.locationStatistics.property.rent.count;
                    default:
                        return props.locationStatistics.property.all.aftermarket.count;
                }
            };

            return `Rynek wtórny - ${numberWithDelimiter(allCount(props.params.deal_type))} ${propertyPlural(allCount(props.params.deal_type))} ${parseDealType(
                props.params.deal_type
            )} ${locationDeclensionWhere}`;
        }
    };

    return (
        <Holder isMapBig={props.isMapBig}>
            {(!props.params.deal_type || props.params.deal_type === OfferDealType.SELL) && includes(props.params.offer_type, "primary_market") ? (
                <div className={dotAndTextHolder}>
                    <div>
                        <div className={cx(propertyDot, blueDot)} />
                    </div>
                    <p className={marketHeading}>{generatePrimaryMarketText()}</p>
                </div>
            ) : null}

            {includes(props.params.offer_type, "aftermarket") ? (
                <div className={dotAndTextHolder}>
                    <div>
                        <div className={cx(propertyDot, redDot)} />
                    </div>
                    <p className={marketHeading}>{generateAftermarketText()}</p>
                </div>
            ) : null}
        </Holder>
    );
};

interface IHolderProps {
    isMapBig: boolean;
}

const Holder = styled.div<IHolderProps>`
    margin-bottom: 1.5rem;

    @media (min-width: 1014px) {
        margin-bottom: ${(props) => (props.isMapBig ? "1.5rem" : "0")};
        padding-right: 1rem;
    }

    p {
        margin: 0.5rem 0;
        display: inline-block;
    }
`;

const propertyDot = css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 0.8rem;
    display: inline-block;
`;

const redDot = css`
    background-color: #ef5451;
`;

const blueDot = css`
    background-color: #00a9ff;
`;

const marketHeading = css`
    font-size: 1.2rem;
`;

const dotAndTextHolder = css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`;
