import React, {useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import {Modal} from "@web2/modal2";
import {FetchStateType, usePrevious, useStateRef} from "@web2/react_utils";
import {BounceLoader} from "@web2/ui_utils";

import {IPhoneNumberRevealOffer} from "../../app/atoms/PhoneNumberReveal";
import {ModalFormSuccessMessage} from "../../app/components/ModalFormSuccessMessage";
import {ModalMultilead} from "../../app/components/ModalMultilead";
import {LeadType, ModalSimilarOffers} from "../../app/components/ModalSimilarOffers";
import {IInvestmentSellStatusType} from "../../app/interfaces/response/investment";
import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {gtmMultileadClose} from "../../tracking/google_tag_manager/gtm_multilead";
import {ViewType} from "../../tracking/view_type/view_type";
import {IApplicationResponse} from "../actions/application_v2_post";
import {ISimilarOffersFetch} from "../actions/fetch_multilead_offers";
import {TrackApplicationPayload} from "../utils/track_application";
import {UserData} from "./Application";
import {ApplicationModalHolder, ApplicationSteps, successModal} from "./ApplicationModal";
import {ApplicationModalForm, IApplicationModalContentProps} from "./ApplicationModalForm";
import {ApplicationModalOfferInfo} from "./ApplicationModalOfferInfo";
import {ApplicationModalShowNumber} from "./ApplicationModalShowNumber";

export interface IApplicationModalAgencyOffers extends IPhoneNumberRevealOffer {
    pictures?:
        | {
              o_img_500: string;
          }[]
        | null;
    property: {
        location?: {
            path?: {name: string; slug: string; type: string}[];
            short_name: string;
        } | null;
        distance_from_region?: {distance?: number};
        floor?: number | null;
        rooms?: number | null;
        room_number?: number | null;
        size: number | null;
        type: PropertyType;
    };
    market_type: OfferMarketType;
    slug: string;
    is_private: boolean;
    overbudget: boolean;
    agency?: {
        logo_picture?: {
            a_log_100: string;
        } | null;
        slug: string;

        type: VendorType;

        id: string;
        name: string;
        phone_number?: string;
        status: number;
        market_type?: OfferMarketType;
    } | null;
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
        picture: {
            a_img_100x100: string;
        } | null;
        market_type?: OfferMarketType;
    } | null;
    investment?: {
        developer?: {
            name: string;
            logo_picture: {
                a_log_100: string;
            } | null;
            slug: string;
            //type: VendorType;
        };
        pictures?:
            | {
                  o_img_500: string;
              }[]
            | null;
        location?: {
            short_name: string;
        };
        investment_summary?: {
            offer_count: number;
        };
        id: string;
        name: string;
        phone_clip?: string | null;
        is_active: boolean;
        limited_presentation: boolean;
        offer_count?: number;
        sell_status: IInvestmentSellStatusType;
        slug: string;
    } | null;
    coordinates: {
        lat: number;
        lon: number;
    };
    coordinates_accurate: boolean;
    price?: {
        total: number | null;
        per_sqm: number | null;
        currency: string;
    };
}

interface IProps extends Omit<IApplicationModalContentProps, "offer" | "voivodeshipSlug" | "vendorRodoData"> {
    modalHeader?: string;
    modalState: boolean;
    onModalClose?: (e?: React.MouseEvent) => void;
    isOfferListModal?: boolean;
    viewType: ViewType | null;
    offerId: string | undefined;
    leadType?: LeadType;
    offer: IApplicationModalAgencyOffers | null;
    fetchState: FetchStateType;
    fetchSimilarOffer: () => Promise<ISimilarOffersFetch[] | null>;
    similarOffers: ISimilarOffersFetch[] | null;
}

export const unknownError = "Przepraszamy, wystąpił nieoczekiwany błąd.";

export const ApplicationModalAgencyOffers = (props: IProps) => {
    const {investment, gtmSource, isOfferListModal, viewType, leadType = LeadType.STANDARD, offer, fetchState} = props;
    const [currentPage, setCurrentPage, setCurrentPageRef] = useStateRef(ApplicationSteps.FORM);
    const [applicationUserData, setApplicationUserData] = useState<UserData>();
    const [applicationTrackingPayload, setApplicationTrackingPayload] = useState<TrackApplicationPayload>();

    const prevModalState = usePrevious(props.modalState, props.modalState);
    const applicationHolderRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!prevModalState && props.modalState && currentPage !== ApplicationSteps.FORM) {
            // make sure that we are showing form after opening modal
            setCurrentPage(ApplicationSteps.FORM);
        }
    }, [props.modalState]);

    const onMultileadApplicationSuccess = () => {
        applicationHolderRef.current?.scroll(0, 0);
    };

    const onApplicationSuccess = (res: IApplicationResponse) => {
        setCurrentPage(ApplicationSteps.CONFIRMATION);
        setTimeout(() => {
            if (currentPage == ApplicationSteps.FORM && props.similarOffers && props.similarOffers.length > 0) {
                setCurrentPage(ApplicationSteps.SIMILAR_OFFERS);
                return;
            }
            closeModal();
        }, 1000);
    };

    const onModalClose = (e?: React.MouseEvent) => {
        const hasPhoneNumber = !!investment?.phone_clip || !!offer?.agent?.phone_number || !!offer?.agency?.phone_number || !!offer?.investment?.phone_clip;
        if (currentPage === ApplicationSteps.FORM && hasPhoneNumber && !offer?.overbudget) {
            setCurrentPage(ApplicationSteps.PHONE_CONTACT);
            return;
        }
        closeModal(e);
    };

    const closeModal = (e?: React.MouseEvent) => {
        if (currentPage == ApplicationSteps.SIMILAR_OFFERS) {
            gtmMultileadClose();
        }
        props.onModalClose?.(e);
    };

    const renderContent = () => {
        switch (currentPage) {
            case ApplicationSteps.PHONE_CONTACT:
                return (
                    <ApplicationModalShowNumber
                        agent={offer?.agent ? offer.agent : null}
                        agency={offer?.agency ? offer.agency : null}
                        isOfferListModal={isOfferListModal}
                        offer={offer}
                        investment={investment}
                        viewType={viewType}
                        gtmSource={gtmSource}
                        voivodeshipSlug={offer?.property.location?.path?.[0].slug}
                        currentPage={currentPage}
                        hidePhoneNumber={offer?.overbudget}
                    />
                );
            case ApplicationSteps.SIMILAR_OFFERS:
                if (!props.similarOffers || (props.similarOffers && props.similarOffers.length === 0)) {
                    notifyBugsnag(
                        {message: "ApplicationModal failed to fetch similar offers but still tried to show them"},
                        "ApplicationModal failed to fetch similar offers but still tried to show them"
                    );

                    props.onModalClose?.();
                    return;
                }

                return (
                    <>
                        {props.offer ? (
                            <>
                                {props.offer.deal_type === OfferDealType.RENT || props.offer.property.type === PropertyType.LOT ? (
                                    <ModalSimilarOffers
                                        similarOffers={props.similarOffers}
                                        offerId={props.offerId}
                                        closeModal={closeModal}
                                        applicationUserData={applicationUserData}
                                        applicationTrackingPayload={applicationTrackingPayload}
                                    />
                                ) : (
                                    <ModalMultilead
                                        similarOffers={props.similarOffers}
                                        offerId={props.offerId}
                                        closeModal={closeModal}
                                        applicationUserData={applicationUserData}
                                        applicationTrackingPayload={applicationTrackingPayload}
                                        viewType={viewType}
                                        type={props.offer.agency?.type}
                                        offer={props.offer}
                                        onApplicationSuccess={onMultileadApplicationSuccess}
                                    />
                                )}
                            </>
                        ) : null}
                    </>
                );
            case ApplicationSteps.CONFIRMATION:
                return <ModalFormSuccessMessage>Wysłano zapytanie</ModalFormSuccessMessage>;

            case ApplicationSteps.FORM:
            default:
                return (
                    <ApplicationModalForm
                        {...props}
                        offer={offer || undefined}
                        onSuccess={onApplicationSuccess}
                        setApplicationUserData={setApplicationUserData}
                        setApplicationTrackingPayload={setApplicationTrackingPayload}
                    />
                );
        }
    };

    const renderOfferInfoHolder = () => {
        switch (currentPage) {
            case ApplicationSteps.CONFIRMATION:
            case ApplicationSteps.SIMILAR_OFFERS:
                return null;
            case ApplicationSteps.PHONE_CONTACT:
            case ApplicationSteps.FORM:
            default:
                return (
                    <div css={applicationModalOfferInfoHolder}>
                        <ApplicationModalOfferInfo
                            agent={offer?.agent ? offer.agent : undefined}
                            agency={offer?.agency ? offer.agency : undefined}
                            offer={offer}
                            investment={investment}
                            viewType={viewType}
                            gtmSource={gtmSource}
                            voivodeshipSlug={offer?.property.location?.path?.[0].slug}
                            currentPage={currentPage}
                            hidePhoneNumber={!!offer?.overbudget}
                        />
                    </div>
                );
        }
    };
    const renderError = () => <div css={errorStyle}>{unknownError}</div>;

    return (
        <Modal
            modalState={props.modalState}
            onModalClose={onModalClose}
            type="window"
            header={
                currentPage !== ApplicationSteps.SIMILAR_OFFERS && currentPage !== ApplicationSteps.CONFIRMATION ? (
                    <div css={applicationModalHeader}>Zapytaj o ofertę</div>
                ) : (
                    <></>
                )
            }
            closeButton={
                !(
                    currentPage === ApplicationSteps.CONFIRMATION ||
                    (currentPage === ApplicationSteps.SIMILAR_OFFERS &&
                        props.offer?.deal_type !== OfferDealType.RENT &&
                        props.offer?.property.type !== PropertyType.LOT)
                )
            }
            css={currentPage === ApplicationSteps.CONFIRMATION ? successModal : applicationModal}
        >
            <ApplicationModalHolder currentPage={currentPage} ref={applicationHolderRef}>
                {!investment && (fetchState === FetchStateType.Waiting || fetchState === FetchStateType.None) ? (
                    <div css={loaderWrapper}>
                        <BounceLoader color="#9069c0" />
                    </div>
                ) : (
                    <>
                        {investment || fetchState == FetchStateType.Success
                            ? renderOfferInfoHolder()
                            : fetchState == FetchStateType.Error
                              ? renderError()
                              : null}
                        {renderContent()}
                    </>
                )}
            </ApplicationModalHolder>
        </Modal>
    );
};

const applicationModal = (theme: Theme) => css`
    height: 100%;
    width: 100%;
    max-width: 70rem;
    background: ${theme.colors.gray_warmer};
    border-radius: ${theme.other.border_radius};
    border: 1px solid ${theme.colors.gray_very_dark};
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        height: unset;
        width: unset;
        max-width: unset;

        .modal-close-button {
            padding: 2.8rem;
        }
    }
`;

const applicationModalHeader = (theme: Theme) => css`
    color: ${theme.colors.brand_primary};
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    padding: 1.6rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 2.8rem;
    }
`;

const applicationModalOfferInfoHolder = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: block;
        width: 33.5rem;
        margin-right: 2.5rem;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
    }
`;
export const loaderWrapper = css`
    position: relative;
    padding: 0;
    background: #fff;
    height: 100%;
    width: 100vw;
    max-width: 1024px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const errorStyle = (theme: Theme) => css`
    height: fit-content;
    color: ${theme.colors.brand_danger};
    padding: 1rem;
    margin-right: 1.5rem;
    border-radius: 0.4rem;
    border: 0.1rem solid ${theme.colors.brand_danger};
`;
