import * as React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";

import {IconCircleWrapper} from "./IconCircleWrapper";

interface IProps {
    className?: string;
    size: string;
    children?: React.ReactNode;
    fillColor?: string;
    style?: React.CSSProperties;
    wrapperColor?: string;
    wrapperSize?: string;
}

export const SvgIcon: React.FC<IProps> = (props: IProps) => {
    const className = classNames(props.className);

    const iconBase = () => {
        return (
            <SvgIconBase width={10} height={10} className={className} fillColor={props.fillColor} size={props.size} viewBox="0 0 10 10">
                {props.children}
            </SvgIconBase>
        );
    };

    return props.wrapperSize && props.wrapperColor ? (
        <IconCircleWrapper wrapperColor={props.wrapperColor} wrapperSize={props.wrapperSize}>
            {iconBase()}
        </IconCircleWrapper>
    ) : (
        iconBase()
    );
};

interface ISvgIconBaseProps {
    fillColor?: string;
    size: string;
}

const SvgIconBase = styled.svg<ISvgIconBaseProps>`
    fill: ${(props) => props.fillColor || props.theme.icons.icon_default_color};
    transform: ${(props) => `scale(${props.size})`};
`;
