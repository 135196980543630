import * as React from "react";

interface IProps {
    className?: string;
    size: string;
    children?: React.ReactNode;
    fillColor?: string;
    style?: React.CSSProperties;
}

export const SvgIcon: React.FC<IProps> = (props: IProps) => {
    return (
        <svg
            width={10}
            height={10}
            className={props.className}
            style={{fill: props.fillColor || "909090", transform: `scale(${props.size})`}}
            viewBox="0 0 10 10"
        >
            {props.children}
        </svg>
    );
};
