import * as React from "react";
import {css} from "@linaria/core";

import {getThemeBreakpointCorrect} from "../../../../styles/linaria_variable_factory";

import img from "./../icons/no-results.png";

const noResultsWrapper = css`
    position: relative;
    padding: 3rem 1.7rem 0 0;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        padding-top: 9rem;
    }

    /*@media screen and (min-width: 750px) {
        padding-top: 9rem;
    }*/
`;

export const noResultsHeadline = css`
    margin: 1rem auto 0;
    max-width: 500px;
    font-size: 2rem;
    color: #b1b7bd;
    font-weight: 500;
    line-height: 1.616;
    text-align: center;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        font-size: 2.6rem;
    }

    /*@media screen and (min-width: 750px) {
        font-size: 2.6rem;
    }*/
`;

export const noResultImg = css`
    display: block;
    margin: 0 auto;
    width: 75%;
    max-width: 360px;
`;

export const NoResults = () => (
    <div className={noResultsWrapper}>
        <h2 className={noResultsHeadline}>Nie znaleźliśmy ofert w podanych przez Ciebie kryteriach</h2>

        <img className={noResultImg} src={img} alt="Nie znaleźliśmy ofert w podanych przez Ciebie kryteriach" />
    </div>
);
