import * as React from "react";
import {isEmpty} from "lodash";
import {IFormFieldProps} from "@web2/form2";
import {CloseButtonIcon} from "@web2/icons";

import {ArrowDownIcon} from "../../../offer/list/components/icons/ArrowDownIcon";
import {ArrowUpIcon} from "../../../offer/list/components/icons/ArrowUpIcon";
import {OfferDealType} from "../../../offer/utils/constants_offer";
import {TypeSearchFilterOptions} from "../../utils/TypeSearchFilterOptions";
import {filterHolder, filterLabelText} from "../atoms/atoms";
import {ChildHolderWide} from "../atoms/ChildHolder";
import {FilterLabel} from "../atoms/FilterLabel";

interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export interface IFilterChildProps<TRangeValue> extends IFormFieldProps<string, IRange<any>> {
    clearField: () => void;
    errorOnBottom?: boolean;
    toggleDropdown: () => void;
    label: string;
    latestQuery?: {
        rooms?: string;
        offer_type: TypeSearchFilterOptions;
        page: string;
        per_page: number;
        deal_type: OfferDealType;
        geo_point?: string;
        distance?: string;
        name?: string;
    };
}

interface IProps<TRangeValue> extends IFormFieldProps<string, IRange<TRangeValue>> {
    errorOnBottom?: boolean;
    getLabel?: (value: IRange<TRangeValue>, defaultLabel: string, labelSuffix?: string) => string | JSX.Element;
    labelId?: string;
    id: string;
    label: string;
    labelSuffix?: string;
    children: (props: IFilterChildProps<TRangeValue>) => JSX.Element;
    onClearField?: () => void;
    isDeactivated?: boolean;
    isDropdownOpened: boolean;
    toggleDropdown: (isOpen: boolean) => void;
    rightDropdown?: boolean;
    isMobileWidth: boolean;
    latestQuery?: {
        rooms?: string;
        offer_type: TypeSearchFilterOptions;
        page: string;
        per_page: number;
        deal_type: OfferDealType;
        geo_point?: string;
        distance?: string;
        name?: string;
    };
    onFilterCancel: () => void;
}

export class RangeFilter<T> extends React.PureComponent<IProps<T | "">, {}> {
    private clearField = () => {
        this.props.onClearField && this.props.onClearField();
        this.props.onChange(this.props.name, {lower: "", upper: ""});
        this.props.onAfterChange(this.props.name, {lower: "", upper: ""});
    };

    private onLabelClick = () => {
        this.props.toggleDropdown(true);
        this.props.onFilterCancel();
        ///this.props.onAfterChange(this.props.name, this.props.value);
    };

    private onClearFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.clearField();
        this.props.toggleDropdown(false);
    };

    private onUpArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.props.toggleDropdown(false);
    };

    private onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            this.props.toggleDropdown(false);
        }

        if (e.key === "Enter") {
            this.props.onChange(this.props.name, this.props.value);
            this.props.onAfterChange(this.props.name, this.props.value);
            this.props.toggleDropdown(false);
        }
    };

    /**
     * Render
     */

    public render() {
        const {isDropdownOpened, value, error} = this.props;
        const hasError = !isEmpty(error) && "has-error";

        const renderEnchancedChild = () =>
            this.props.children({
                clearField: this.clearField,
                error: this.props.error,
                errorOnBottom: this.props.errorOnBottom,
                name: this.props.name,
                onAfterChange: this.props.onAfterChange,
                onChange: this.props.onChange,
                toggleDropdown: () => this.props.toggleDropdown(false),
                label: this.props.label,
                value: value,
                latestQuery: this.props.latestQuery
            });

        const label = this.props.getLabel ? this.props.getLabel(value, this.props.label, this.props.labelSuffix) : this.props.label;

        return (
            <div className={filterHolder} onKeyDown={this.onKeyDownHandler}>
                {hasError && <div>error</div>}

                {this.props.label && (
                    <FilterLabel
                        isActive={this.props.isDropdownOpened}
                        hasValues={!!value.lower || !!value.upper}
                        tabIndex={-1}
                        onClick={this.props.isDeactivated ? () => null : this.props.isDropdownOpened ? this.onUpArrowClick : this.onLabelClick}
                    >
                        <span className={filterLabelText}>{label}</span>

                        {value.lower || value.upper ? (
                            <div onClick={(e) => this.onClearFieldClick(e)}>
                                <CloseButtonIcon mainColor="#9069c0" width={9} height={9} />
                            </div>
                        ) : this.props.isDropdownOpened ? (
                            <ArrowUpIcon size="1.2" fillColor="#9069c0" />
                        ) : (
                            <ArrowDownIcon size="1.2" fillColor="#9069c0" />
                        )}
                    </FilterLabel>
                )}

                {(isDropdownOpened || this.props.isMobileWidth) && (
                    <ChildHolderWide rightDropdown={this.props.rightDropdown}>{renderEnchancedChild()}</ChildHolderWide>
                )}
            </div>
        );
    }
}
