import {ViewType} from "../../view_type/view_type";
import {GtmContentType} from "./gtm_content_type";

interface IData {
    offer?: {property: {type: string}};
    viewType: ViewType | null;
}

export const getGtmInquiryBrand = ({offer, viewType}: IData): GtmContentType | null => {
    if (offer) {
        switch (offer.property.type) {
            case "apartment":
                return GtmContentType.FLAT;
            case "house":
                return GtmContentType.HOUSE;
            case "lot":
                return GtmContentType.LOT;
            default:
                return null;
        }
    }

    switch (viewType) {
        case ViewType.INVESTMENT:
            return GtmContentType.INVESTMENT;
        case ViewType.DEVELOPER:
            return GtmContentType.DEVELOPER;
        case ViewType.AGENCY:
            return GtmContentType.AGENCY;
        case ViewType.AGENT:
            return GtmContentType.AGENT;
        default:
            return null;
    }
};
