import {css} from "@emotion/react";

export const Header = (props: {text: string; className?: string}) => {
    return (
        <h6 className={props.className} css={contentHeader}>
            {props.text}
        </h6>
    );
};

const contentHeader = css`
    display: flex;
    font-size: 1.9rem;
    font-weight: 500;
    margin: 0 0 2rem 0;
    line-height: 2.85rem;
`;
