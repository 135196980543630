import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const TransportIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "6.59mm"} height={props.height || "8.09mm"} viewBox="0 0 18.69 22.93">
            <path d="M1.23,21.65a.75.75,0,0,0,0,1.06.79.79,0,0,0,.53.22.75.75,0,0,0,.53-.22L5,20H2.88Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M16.39,22.71a.79.79,0,0,0,.53.22.77.77,0,0,0,.53-.22.74.74,0,0,0,0-1.06L15.81,20H13.69Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M12.48,0H6.21A6.22,6.22,0,0,0,0,6.21v11A2.83,2.83,0,0,0,2.82,20H15.87a2.83,2.83,0,0,0,2.82-2.82v-11A6.22,6.22,0,0,0,12.48,0ZM6.21,1.5h6.27a4.72,4.72,0,0,1,4.71,4.71v4.56A24.35,24.35,0,0,1,9.34,12,24.25,24.25,0,0,1,1.5,10.77V6.21A4.71,4.71,0,0,1,6.21,1.5Zm9.66,17h-13A1.32,1.32,0,0,1,1.5,17.18V12.33a25.76,25.76,0,0,0,7.84,1.16,25.78,25.78,0,0,0,7.85-1.16v4.85A1.33,1.33,0,0,1,15.87,18.5Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M14.23,14.14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.23,14.14Z" fill={props.accentColor || "#8b6aac"} />
            <path d="M5.08,14.14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,5.08,14.14Z" fill={props.accentColor || "#8b6aac"} />
        </svg>
    );
};
