import * as React from "react";

export const BigMapIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g opacity="0.54">
                <path d="M28.79,28.79H7.21V7.21H28.79ZM27,9H9V27H27Z" fill="#010101" />
                <polygon points="33.23 25.19 35.98 25.19 35.98 35.98 25.19 35.98 25.19 33.23 33.23 33.23 33.23 25.19" fill="#010101" />
                <polygon points="10.81 0.03 10.81 2.77 2.77 2.77 2.77 10.81 0.03 10.81 0.03 0.03 10.81 0.03" fill="#010101" />
            </g>
        </svg>
    );
};
