import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IStore} from "../reducers/hybrid_reducer";
import {RequestState} from "../utils/request_response_utils/factories/reduce_request_state";

export const useIsLoadingUntilStop = (requestStateSelector: (store: IStore) => RequestState) => {
    const requestState = useSelector(requestStateSelector);
    const [isLoading, _setIsLoading] = useState(false);

    useEffect(() => {
        if (requestState === RequestState.Success || requestState === RequestState.Error) {
            _setIsLoading(false);
        }
    }, [requestState]);

    const startLoadingUntilStop = () => {
        _setIsLoading(true);
    };

    return {isLoading, startLoadingUntilStop};
};
