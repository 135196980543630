import * as React from "react";
import {css} from "@linaria/core";
import {filter, includes, uniq, without} from "lodash";
import {FILTER_PROPERTY_TYPE_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";

import {OfferDealType, OfferMarketType} from "../../../offer/utils/constants_offer";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {TypeSearchFilterOptions} from "../../utils/TypeSearchFilterOptions";
import {dropdownList, dropdownListElement} from "../atoms/atoms";
import {ListElementCheckboxBody} from "../atoms/ListElementCheckboxBody";
import {SearchTypeDot} from "../atoms/SearchTypeDot";

interface IOwnProps {
    error?: string[];
    name: string;
    id: string;
    onAfterChange: (fieldName: string, value: TypeSearchFilterOptions[]) => void;
    onChange: (fieldName: string, value: TypeSearchFilterOptions[]) => void;
    value: TypeSearchFilterOptions[];
    dealType?: OfferDealType | [];
}

export const OfferTypeSwitch = (props: IOwnProps) => {
    const {PRIMARY_HOUSE, PRIMARY_APARTMENT, AFTERMARKET_HOUSE, AFTERMARKET_APARTMENT, LOT} = TypeSearchFilterOptions;
    const {value, name} = props;

    const renderOnlyAftermarket = props.dealType === OfferDealType.RENT;
    const calculateTypeArray = (type: TypeSearchFilterOptions | null) => {
        if (type === LOT) {
            return [LOT];
        }
        if (type) {
            if (includes(value, type)) {
                if (value.length > 1) {
                    return filter(value, (aT) => aT !== type);
                }
                return value;
            }
            return [...filter(value, (aT) => aT !== LOT), type];
        }
        return [];
    };

    const onChange = (type: TypeSearchFilterOptions | null) => {
        const newValue = calculateTypeArray(type);
        props.onChange(name, newValue);
    };

    const onParentTypeChange = (parent: OfferMarketType) => {
        const valueIncludes = (option: TypeSearchFilterOptions) => includes(value, option);
        const [APARTMENT, HOUSE] = parent === OfferMarketType.PRIMARY_MARKET ? [PRIMARY_APARTMENT, PRIMARY_HOUSE] : [AFTERMARKET_APARTMENT, AFTERMARKET_HOUSE];
        if (
            (value.length === 1 && (valueIncludes(APARTMENT) || valueIncludes(HOUSE))) ||
            (value.length === 2 && valueIncludes(APARTMENT) && valueIncludes(HOUSE))
        ) {
            return value;
        }
        if ((valueIncludes(APARTMENT) && !valueIncludes(HOUSE)) || (!valueIncludes(APARTMENT) && valueIncludes(HOUSE))) {
            return props.onChange(name, without(value, LOT, HOUSE, APARTMENT));
        }
        if (!valueIncludes(APARTMENT) || !valueIncludes(HOUSE)) {
            return props.onChange(name, uniq([...without(value, LOT), HOUSE, APARTMENT]));
        }
        if (valueIncludes(APARTMENT) && valueIncludes(HOUSE)) {
            return props.onChange(name, without(value, APARTMENT, HOUSE));
        }
    };

    return (
        <div className={dropdownList} data-testid={FILTER_PROPERTY_TYPE_TESTID.LIST_PROPERTY_TYPE}>
            {!renderOnlyAftermarket && (
                <>
                    <li className={dropdownListElement} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_PRIMARY_MARKET}>
                        <ListElementCheckboxBody
                            name="primary_market"
                            label="Rynek pierwotny"
                            onChange={() => onParentTypeChange(OfferMarketType.PRIMARY_MARKET)}
                            value={includes(value, PRIMARY_HOUSE) || includes(value, PRIMARY_APARTMENT)}
                            icon={<SearchTypeDot type="primary" />}
                        />
                    </li>

                    <li className={listElementIndent} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_PRIMARY_MARKET_HAUSES}>
                        <ListElementCheckboxBody
                            name="primary_house"
                            label="Domy"
                            onChange={() => onChange(PRIMARY_HOUSE)}
                            value={includes(value, PRIMARY_HOUSE)}
                        />
                    </li>

                    <li className={listElementIndent} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_PRIMARY_MARKET_APARTMENT}>
                        <ListElementCheckboxBody
                            name="primary_apartment"
                            label="Mieszkania"
                            onChange={() => onChange(PRIMARY_APARTMENT)}
                            value={includes(value, PRIMARY_APARTMENT)}
                        />
                    </li>
                </>
            )}

            <li className={dropdownListElement} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_AFTERMARKET}>
                <ListElementCheckboxBody
                    name="aftermarket"
                    label="Rynek wtórny"
                    onChange={() => onParentTypeChange(OfferMarketType.AFTERMARKET)}
                    value={includes(value, AFTERMARKET_HOUSE) || includes(value, AFTERMARKET_APARTMENT)}
                    icon={<SearchTypeDot type="after" />}
                />
            </li>

            <li className={listElementIndent} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_AFTERMARKET_HAUSES}>
                <ListElementCheckboxBody
                    name="aftermarket_house"
                    label="Domy"
                    onChange={() => onChange(AFTERMARKET_HOUSE)}
                    value={includes(value, AFTERMARKET_HOUSE)}
                />
            </li>

            <li className={listElementIndent} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_AFTERMARKET_APARTMENT}>
                <ListElementCheckboxBody
                    name="aftermarket_apartment"
                    label="Mieszkania"
                    onChange={() => onChange(AFTERMARKET_APARTMENT)}
                    value={includes(value, AFTERMARKET_APARTMENT)}
                />
            </li>

            {!renderOnlyAftermarket && (
                <li className={dropdownListElement} data-testid={FILTER_PROPERTY_TYPE_TESTID.CHECKBOX_LOTS}>
                    <ListElementCheckboxBody
                        name="lot"
                        label="Działki"
                        onChange={() => onChange(LOT)}
                        value={includes(value, LOT)}
                        icon={<SearchTypeDot type="lot" />}
                    />
                </li>
            )}
        </div>
    );
};

const listElementIndent = css`
    transition:
        background-color 100ms ease-out 0s,
        opacity 100ms ease-out 0s;
    color: ${getThemeVariable("colors-gray_darker")};
    line-height: 1.35;
    padding: 0 0 0 35px;
    white-space: nowrap;

    &:hover {
        background-color: ${getThemeVariable("colors-gray_very_bright")};
    }
`;
