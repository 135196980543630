import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {appLink} from "@web2/gh_routes";
import {addNotification} from "@web2/notifications";
import {useIsMobile} from "@web2/react_utils";

import {IStore} from "../app/reducers/hybrid_reducer";
import {ArrowLeftIcon} from "../offer/list/components/icons/ArrowLeftIcon";
import {ArrowRightIcon} from "../offer/list/components/icons/ArrowRightIcon";
import {Navigation} from "./Navigation";
import {generateLeftNavigation, generateRightNavigation} from "./NavigationData";

import logo from "../../src/styles/assets/gethome_white.svg";

export const GETHOME_NAVIGATION_ID = "gethome-navigation";

export const NavigationGh = () => {
    const isMobile = useIsMobile(true);
    const favourites = useSelector((store: IStore) => store.favourites.favourites);

    const isAuthenticated = useSelector((store: IStore) => store.isAuthenticated);
    const dispatch = useDispatch();
    const notify = (text: string) => {
        dispatch(addNotification({type: "info", content: text}));
    };

    return (
        <Navigation
            id={GETHOME_NAVIGATION_ID}
            logo={{
                img: logo,
                url: appLink.mainPage(),
                alt: "GetHome Logo"
            }}
            arrows={{
                left: <ArrowLeftIcon size="1.1" />,
                right: <ArrowRightIcon size="1.1" />
            }}
            navLeftElements={generateLeftNavigation(favourites.length)}
            navRightElements={generateRightNavigation(isAuthenticated, notify)}
            isMobile={isMobile}
            favouritesCount={favourites.length}
        />
    );
};
