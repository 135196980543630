import {combineReducers} from "redux";
import {fromJsonToFormValues, reduceFormValues} from "@web2/form2";

import {reduceRequestState, RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../app/utils/request_response_utils/factories/reduce_response";
import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {reduceLatestQuery} from "../../offer/list/reducers/reduce_latest_query";
import {fetchFilterStatsTypes, filterStatsDefaults, IFilterStats} from "../actions/fetch_filter_stats";
import {fetchAllListsType, ISearchSuggestions, SearchTab} from "../actions/fetch_search_all_action";
import {AutocompletePrediction, fetchPlaceListTypes} from "../actions/fetch_search_places_action";
import {searchFormTypes} from "../actions/search_form_actions";
import {searchFormFields} from "../utils/form_types";
import {activeDropdownItemReducer, IActiveDropdownItemStore} from "./active_dropdown_item_reducer";
import {currentTabReducer} from "./current_tab_reducer";

export interface ISearchStore {
    activeDropdownItem: IActiveDropdownItemStore;
    formValues: Record<keyof typeof searchFormFields, any>; // TODO: this needs strict types
    currentTab: SearchTab;
    filterStats: IFilterStats;
    filterStatsRequest: RequestState;
    filterStatsLatestQuery: IOfferListQuery;
    // query
    fetchAllRequest: RequestState;
    // query places
    places: AutocompletePrediction[];
    fetchPlacesRequest: RequestState;
    // query search suggestions
    suggestions: ISearchSuggestions[];
    fetchSuggestionsRequest: RequestState;
}

export const searchReducer = combineReducers({
    activeDropdownItem: activeDropdownItemReducer,
    formValues: reduceFormValues(searchFormTypes, fromJsonToFormValues(searchFormFields, {is_private: false})),
    currentTab: currentTabReducer,
    // query
    fetchAllRequest: reduceRequestState(fetchAllListsType),
    // filter stats
    filterStats: reduceResponse<IFilterStats>(fetchFilterStatsTypes, filterStatsDefaults),
    filterStatsRequest: reduceRequestState(fetchFilterStatsTypes),
    filterStatsLatestQuery: reduceLatestQuery(fetchFilterStatsTypes),
    // query places
    places: reduceResponseField<AutocompletePrediction[]>(fetchPlaceListTypes, "places", []),
    fetchPlacesRequest: reduceRequestState(fetchPlaceListTypes),
    // query search suggestions
    suggestions: reduceResponse<AutocompletePrediction[]>(fetchAllListsType),
    fetchSuggestionsRequest: reduceRequestState(fetchAllListsType)
});
