import {useSelector} from "react-redux";
import {css} from "@linaria/core";
import {OFFER_LIST_LIST_TESTID} from "@web2/gh_page_object_models";
import {BedIcon} from "@web2/icons";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {ISearchFiltersFormValues} from "../../../search/components/SearchFilters";
import {useSearchSubmit} from "../../../search/utils/use_search_submit";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {hitGoogleTagManager} from "../../../tracking/google_tag_manager/gtm_init";
import {OFFERBOX_MAX_MOBILE_BREAKPOINT, OFFERBOX_MAX_WIDTH, OFFERBOX_STANDARD_HEIGHT, OFFERBOX_WIDTH} from "../../detail/components/offer_box/OfferBox";
import {GroupSelect} from "./GroupSelect";

type IRoomsFilterValue = ISearchFiltersFormValues["rooms"];

export const OfferListRoomsFilter = () => {
    const searchFormValues = useSelector((state: IStore) => state.search.formValues);
    const searchFormRooms = useSelector((state: IStore) => state.search.formValues.rooms);
    const {onSubmit, isTransitioning} = useSearchSubmit();

    const onChange = (fieldName: string, value: IRoomsFilterValue) => {
        const newFormValues = {
            ...searchFormValues,
            rooms: value
        };

        const GAEventLabel = value.join("-") || "";

        hitGoogleTagManager({
            event: "GAEvent",
            GAEventCategory: "usability",
            GAEventAction: "filter_rooms_tile",
            GAEventLabel: GAEventLabel,
            GAEventNoInteraction: false
        });
        onSubmit(newFormValues);
    };

    const options = [
        {value: 1, label: "1"},
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 4, label: "4"},
        {value: 5, label: "5+"}
    ];

    return (
        <div data-testid={OFFER_LIST_LIST_TESTID.NUMBER_OF_ROOMS_BANNER} className={offerListRoomsFilterBoxWrapper}>
            <BedIcon className={bedIconStyle} size="3.2" />
            <div className={roomFilterHeading}>Chcesz zobaczyć lepiej dopasowane oferty?</div>
            <div className={roomFilterText}>Wybierz liczbę pokoi</div>
            <div className={roomFilterBox}>
                <GroupSelect name="rooms" id={"rooms"} onChange={onChange} value={searchFormRooms} options={options} />
            </div>
        </div>
    );
};

const offerListRoomsFilterBoxWrapper = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 0;
    background-color: white;
    padding: 5.6rem 0 4.2rem 0;
    border-radius: ${getThemeVariable("other-border_radius")};
    border: 1px solid ${getThemeVariable("colors-gray_very_dark")};
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    height: ${OFFERBOX_STANDARD_HEIGHT + "px"};
    width: ${OFFERBOX_WIDTH}px;
    min-width: ${OFFERBOX_WIDTH}px;
    transition: all 400ms ease-out;

    &:hover {
        box-shadow: 0 2px 21px 2px rgba(0, 0, 0, 0.2);
    }

    a {
        text-decoration: none;
    }

    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        width: 100%;
        max-width: ${OFFERBOX_MAX_WIDTH}px;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
        margin-right: 0;
    }

    @media (min-width: 640px) {
        order: 1;
    }
`;

const bedIconStyle = css`
    display: flex;
    flex-shrink: 0;
    margin: 0.5rem 1rem 0.5rem 0.34rem;
`;

const roomFilterHeading = css`
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
`;

const roomFilterText = css`
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
`;

const roomFilterBox = css`
    width: 100%;
    margin-bottom: 2.4rem;
    padding: 0 1.2rem 0 1.2rem;
`;
