import styled from "@emotion/styled";

export const Price = styled.p`
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.brand_primary};

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        font-size: 22px;
        line-height: 26px;
    }
`;

const PricePer = styled.p`
    margin: 0 0 0 15px;
    color: ${(props) => props.theme.colors.gray_lighter};
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        font-size: 17px;
        line-height: 26px;
    }
`;

export const PricePerMonth = styled(PricePer)`
    text-transform: uppercase;
`;

export const PricePerSqm = styled(PricePer)`
    text-transform: lowercase;
`;
