/**
 * Route Service (universal)
 *
 * `getRoute` is used in request.ts to check if request url contains certain query param (currently only `utm_campaign`)
 */

interface IGivenRouteState {
    pathname: string;
    query: Record<string, string | string[]>;
}

export interface IRouteService {
    init: (givenRoute: IGivenRouteState) => void;
    getRoute: () => IGivenRouteState | null;
}

export const createRouteService = (): IRouteService => {
    let route: IGivenRouteState | null = null;

    return {
        init: (givenRoute: IGivenRouteState) => {
            route = givenRoute;
        },
        getRoute: (): IGivenRouteState | null => {
            return route;
        }
    };
};
