import {IInvestmentSellStatusType} from "../../app/interfaces/response/investment";
import {apiLink} from "../../app/routes/api_link";
import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404, IAppError} from "../../app/utils/request_response_utils/response_error";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";

export interface IApplicationFetchOffer {
    agency?: {
        id: string;
        name: string;
        logo_picture?: {
            a_log_100: string;
        } | null;
        slug: string;
        status: number;
        type: VendorType;
        phone_number?: string;
    } | null;
    agent?: {
        id: string;
        last_name?: string;
        name: string;
        phone_number?: string;
        picture: {
            a_img_100x100: string;
        } | null;
        market_type?: OfferMarketType;
    } | null;
    deal_type: OfferDealType;
    id: string;
    market_type: OfferMarketType;
    name: string;
    offer_type?: string[];
    overbudget: boolean;
    pictures?:
        | {
              o_img_500: string;
          }[]
        | null;
    property: {
        distance_from_region?: {distance?: number};

        floor?: number | null;
        group_lot_info?: {
            shape?: string | null;
        };
        location?: {
            path?: {name: string; slug: string; type: string}[];
            short_name: string;
        } | null;
        lot_type?: string | null;
        room_number?: number | null;
        rooms?: number | null;
        signature?: string | null;
        size: number | null;
        type: PropertyType;
    };

    investment?: {
        developer?: {
            name: string;
            logo_picture: {
                a_log_100: string;
            } | null;
            slug: string;
        };
        pictures?:
            | {
                  o_img_500: string;
              }[]
            | null;
        location?: {
            short_name: string;
        };
        investment_summary?: {
            offer_count: number;
        };
        id: string;
        name: string;
        phone_clip?: string | null;
        is_active: boolean;
        limited_presentation: boolean;
        offer_count?: number;
        sell_status: IInvestmentSellStatusType;
        slug: string;
    } | null;
    slug: string;
    is_private: boolean;
    coordinates: {
        lat: number;
        lon: number;
    };
    coordinates_accurate: boolean;
}

export const applicationFetchOffer = (offerId: string | undefined, triesLeft = 1): Promise<IApplicationFetchOffer | null> => {
    if (!offerId) {
        Promise.resolve(null);
    }
    const url = apiLink.offers.application({})({offerId: offerId});
    return getRequest({}, url)
        .then((res: IApplicationFetchOffer) => {
            return res;
        })
        .catch(catch404(() => null))
        .catch((error: IAppError) => {
            // application get may be rejected due to incorrect cookies, attempt to fetch sessionid and csrftoken and try again x times
            if (!triesLeft) {
                notifyBugsnag({message: `applicationFetchOffer: triesLeft limit reached`}, `applicationFetchOffer:  (triesLeft: ${triesLeft})`, error ?? {});
                // rethrow to handle failure further down
                throw error;
            }

            return applicationFetchOffer(offerId, --triesLeft);
        });
};
