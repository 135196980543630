import {ILocationStatistics, ILocationStatisticsResponse} from "../../app/interfaces/response/location_statistics";

export const normalizeLocationStatisticsResponse = (stats: ILocationStatisticsResponse): ILocationStatistics =>
    //TODO: Commented keys are currentely unused and should be removed if not needed in next stages of Generic Listings
    ({
        location: stats.location,
        property: {
            all: {
                count: stats.property,
                avg_price: stats.property_avg_price,
                // min_price: stats.property_min_price,
                // max_price: stats.property_max_price,
                avg_price_per_sqm: stats.property_avg_price_per_sqm,
                // min_price_per_sqm: stats.property_min_price_per_sqm,
                // max_price_per_sqm: stats.property_max_price_per_sqm,
                avg_size: stats.property_avg_price,
                // min_size: stats.property_min_price,
                // max_size: stats.property_max_price,
                one_room: {
                    count: stats.property_one_room,
                    avg_size: stats.property_one_room_avg_size,
                    // min_size: stats.property_one_room_min_size,
                    // max_size: stats.property_one_room_max_size,
                    avg_price_per_sqm: stats.property_one_room_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_one_room_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_one_room_max_price_per_sqm,
                    avg_price: stats.property_one_room_avg_price
                    // min_price: stats.property_one_room_min_price,
                    // max_price: stats.property_one_room_max_price
                },
                two_rooms: {
                    count: stats.property_two_rooms,
                    avg_size: stats.property_two_rooms_avg_size,
                    // min_size: stats.property_two_rooms_min_size,
                    // max_size: stats.property_two_rooms_max_size,
                    avg_price_per_sqm: stats.property_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_two_rooms_max_price_per_sqm,
                    avg_price: stats.property_two_rooms_avg_price
                    // min_price: stats.property_two_rooms_min_price,
                    // max_price: stats.property_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.property_three_rooms,
                    avg_size: stats.property_three_rooms_avg_size,
                    // min_size: stats.property_three_rooms_avg_size,
                    // max_size: stats.property_three_rooms_avg_size,
                    avg_price_per_sqm: stats.property_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_three_rooms_max_price_per_sqm,
                    avg_price: stats.property_three_rooms_avg_price
                    // min_price: stats.property_three_rooms_min_price,
                    // max_price: stats.property_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.property_four_rooms,
                    avg_size: stats.property_four_rooms_avg_size,
                    // min_size: stats.property_four_rooms_min_size,
                    // max_size: stats.property_four_rooms_min_size,
                    avg_price_per_sqm: stats.property_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_four_rooms_max_price_per_sqm,
                    avg_price: stats.property_four_rooms_avg_price
                    // min_price: stats.property_four_rooms_min_price,
                    // max_price: stats.property_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.property_five_or_more_rooms,
                    avg_size: stats.property_five_or_more_rooms_avg_size,
                    // min_size: stats.property_five_or_more_rooms_min_size,
                    // max_size: stats.property_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.property_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.property_five_or_more_rooms_avg_price
                    // min_price: stats.property_five_or_more_rooms_min_price,
                    // max_price: stats.property_five_or_more_rooms_max_price
                },
                aftermarket: {
                    count: stats.property_aftermarket,
                    avg_size: stats.property_aftermarket_avg_size,
                    // min_size: stats.property_aftermarket_min_size,
                    // max_size: stats.property_aftermarket_max_size,
                    avg_price_per_sqm: stats.property_aftermarket_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_aftermarket_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_aftermarket_max_price_per_sqm,
                    avg_price: stats.property_aftermarket_avg_price
                    // min_price: stats.property_aftermarket_min_price,
                    // max_price: stats.property_aftermarket_max_price
                }
            },
            sell: {
                count: stats.property_sell,
                avg_price: stats.property_sell_avg_price,
                // min_price: stats.property_sell_min_price,
                // max_price: stats.property_sell_max_price,
                avg_price_per_sqm: stats.property_sell_avg_price_per_sqm,
                // min_price_per_sqm: stats.property_sell_min_price_per_sqm,
                // max_price_per_sqm: stats.property_sell_max_price_per_sqm,
                avg_size: stats.property_sell_avg_size,
                // min_size: stats.property_sell_min_size,
                // max_size: stats.property_sell_max_size,
                one_room: {
                    count: stats.property_sell_one_room,
                    avg_size: stats.property_sell_one_room_avg_size,
                    // min_size: stats.property_sell_one_room_min_size,
                    // max_size: stats.property_sell_one_room_max_size,
                    avg_price_per_sqm: stats.property_sell_one_room_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_one_room_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_one_room_max_price_per_sqm,
                    avg_price: stats.property_sell_one_room_avg_price
                    // min_price: stats.property_sell_one_room_min_price,
                    // max_price: stats.property_sell_one_room_max_price
                },
                two_rooms: {
                    count: stats.property_sell_two_rooms,
                    avg_size: stats.property_sell_two_rooms_avg_size,
                    // min_size: stats.property_sell_two_rooms_min_size,
                    // max_size: stats.property_sell_two_rooms_max_size,
                    avg_price_per_sqm: stats.property_sell_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_two_rooms_max_price_per_sqm,
                    avg_price: stats.property_sell_two_rooms_avg_price
                    // min_price: stats.property_sell_two_rooms_min_price,
                    // max_price: stats.property_sell_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.property_sell_three_rooms,
                    avg_size: stats.property_sell_three_rooms_avg_size,
                    // min_size: stats.property_sell_three_rooms_min_size,
                    // max_size: stats.property_sell_three_rooms_max_size,
                    avg_price_per_sqm: stats.property_sell_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_three_rooms_max_price_per_sqm,
                    avg_price: stats.property_sell_three_rooms_avg_price
                    // min_price: stats.property_sell_three_rooms_min_price,
                    // max_price: stats.property_sell_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.property_sell_four_rooms,
                    avg_size: stats.property_sell_four_rooms_avg_size,
                    // min_size: stats.property_sell_four_rooms_min_size,
                    // max_size: stats.property_sell_four_rooms_max_size,
                    avg_price_per_sqm: stats.property_sell_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_four_rooms_max_price_per_sqm,
                    avg_price: stats.property_sell_four_rooms_avg_price
                    // min_price: stats.property_sell_four_rooms_min_price,
                    // max_price: stats.property_sell_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.property_sell_five_or_more_rooms,
                    avg_size: stats.property_sell_five_or_more_rooms_avg_size,
                    // min_size: stats.property_sell_five_or_more_rooms_min_size,
                    // max_size: stats.property_sell_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.property_sell_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.property_sell_five_or_more_rooms_avg_price
                    // min_price: stats.property_sell_five_or_more_rooms_min_price,
                    // max_price: stats.property_sell_five_or_more_rooms_max_price
                },
                aftermarket: {
                    count: stats.property_sell_aftermarket,
                    avg_size: stats.property_sell_aftermarket_avg_size,
                    // min_size: stats.property_sell_aftermarket_min_size,
                    // max_size: stats.property_sell_aftermarket_max_size,
                    avg_price_per_sqm: stats.property_sell_aftermarket_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_aftermarket_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_aftermarket_max_price_per_sqm,
                    avg_price: stats.property_sell_aftermarket_avg_price
                    // min_price: stats.property_sell_aftermarket_min_price,
                    // max_price: stats.property_sell_aftermarket_max_price
                },
                primary_market: {
                    count: stats.property_sell_primary_market,
                    avg_price: stats.property_sell_primary_market_avg_price,
                    // min_price: stats.property_sell_primary_market_min_price,
                    // max_price: stats.property_sell_primary_market_max_price,
                    avg_price_per_sqm: stats.property_sell_primary_market_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_sell_primary_market_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_sell_primary_market_max_price_per_sqm,
                    avg_size: stats.property_sell_primary_market_avg_size
                    // min_size: stats.property_sell_primary_market_min_size,
                    // max_size: stats.property_sell_primary_market_max_size
                }
            },
            rent: {
                count: stats.property_rent,
                avg_price: stats.property_rent_avg_price,
                // min_price: stats.property_rent_min_price,
                // max_price: stats.property_rent_max_price,
                avg_price_per_sqm: stats.property_rent_avg_price_per_sqm,
                // min_price_per_sqm: stats.property_rent_min_price_per_sqm,
                // max_price_per_sqm: stats.property_rent_max_price_per_sqm,
                avg_size: stats.property_rent_avg_size,
                // min_size: stats.property_rent_min_size,
                // max_size: stats.property_rent_max_size,
                one_room: {
                    count: stats.property_rent_one_room,
                    avg_size: stats.property_rent_one_room_avg_size,
                    // min_size: stats.property_rent_one_room_min_size,
                    // max_size: stats.property_rent_one_room_max_size,
                    avg_price_per_sqm: stats.property_rent_one_room_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_rent_one_room_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_rent_one_room_max_price_per_sqm,
                    avg_price: stats.property_rent_one_room_avg_price
                    // min_price: stats.property_rent_one_room_min_price,
                    // max_price: stats.property_rent_one_room_max_price
                },
                two_rooms: {
                    count: stats.property_rent_two_rooms,
                    avg_size: stats.property_rent_two_rooms_avg_size,
                    // min_size: stats.property_rent_two_rooms_min_size,
                    // max_size: stats.property_rent_two_rooms_max_size,
                    avg_price_per_sqm: stats.property_rent_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_rent_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_rent_two_rooms_max_price_per_sqm,
                    avg_price: stats.property_rent_two_rooms_avg_price
                    // min_price: stats.property_rent_two_rooms_min_price,
                    // max_price: stats.property_rent_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.property_rent_three_rooms,
                    avg_size: stats.property_rent_three_rooms_avg_size,
                    // min_size: stats.property_rent_three_rooms_min_size,
                    // max_size: stats.property_rent_three_rooms_max_size,
                    avg_price_per_sqm: stats.property_rent_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_rent_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_rent_three_rooms_max_price_per_sqm,
                    avg_price: stats.property_rent_three_rooms_avg_price
                    // min_price: stats.property_rent_three_rooms_min_price,
                    // max_price: stats.property_rent_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.property_rent_four_rooms,
                    avg_size: stats.property_rent_four_rooms_avg_size,
                    // min_size: stats.property_rent_four_rooms_min_size,
                    // max_size: stats.property_rent_four_rooms_max_size,
                    avg_price_per_sqm: stats.property_rent_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_rent_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_rent_four_rooms_max_price_per_sqm,
                    avg_price: stats.property_rent_four_rooms_avg_price
                    // min_price: stats.property_rent_four_rooms_min_price,
                    // max_price: stats.property_rent_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.property_rent_five_or_more_rooms,
                    avg_size: stats.property_rent_five_or_more_rooms_avg_size,
                    // min_size: stats.property_rent_five_or_more_rooms_min_size,
                    // max_size: stats.property_rent_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.property_rent_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.property_rent_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.property_rent_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.property_rent_five_or_more_rooms_avg_price
                    // min_price: stats.property_rent_five_or_more_rooms_min_price,
                    // max_price: stats.property_rent_five_or_more_rooms_max_price
                }
            }
        },
        apartment: {
            all: {
                count: stats.apartment,
                avg_price: stats.apartment_avg_price,
                min_price: stats.apartment_min_price,
                max_price: stats.apartment_max_price,
                avg_price_per_sqm: stats.apartment_avg_price_per_sqm,
                min_price_per_sqm: stats.apartment_min_price_per_sqm,
                max_price_per_sqm: stats.apartment_max_price_per_sqm,
                avg_size: stats.apartment_avg_price,
                min_size: stats.apartment_avg_price,
                max_size: stats.apartment_avg_price,
                one_room: {
                    count: stats.apartment_one_room,
                    avg_size: stats.apartment_one_room_avg_size,
                    min_size: stats.apartment_one_room_min_size,
                    max_size: stats.apartment_one_room_max_size,
                    avg_price_per_sqm: stats.apartment_one_room_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_one_room_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_one_room_max_price_per_sqm,
                    avg_price: stats.apartment_one_room_avg_price,
                    min_price: stats.apartment_one_room_min_price,
                    max_price: stats.apartment_one_room_max_price
                },
                two_rooms: {
                    count: stats.apartment_two_rooms,
                    avg_size: stats.apartment_two_rooms_avg_size,
                    min_size: stats.apartment_two_rooms_min_size,
                    max_size: stats.apartment_two_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_two_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_two_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_two_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_two_rooms_avg_price,
                    min_price: stats.apartment_two_rooms_min_price,
                    max_price: stats.apartment_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.apartment_three_rooms,
                    avg_size: stats.apartment_three_rooms_avg_size,
                    min_size: stats.apartment_three_rooms_min_size,
                    max_size: stats.apartment_three_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_three_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_three_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_three_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_three_rooms_avg_price,
                    min_price: stats.apartment_three_rooms_min_price,
                    max_price: stats.apartment_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.apartment_four_rooms,
                    avg_size: stats.apartment_four_rooms_avg_size,
                    min_size: stats.apartment_four_rooms_min_size,
                    max_size: stats.apartment_four_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_four_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_four_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_four_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_four_rooms_avg_price,
                    min_price: stats.apartment_four_rooms_min_price,
                    max_price: stats.apartment_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.apartment_five_rooms
                },
                aftermarket: {
                    count: stats.apartment_aftermarket,
                    avg_size: stats.apartment_aftermarket_avg_size,
                    min_size: stats.apartment_aftermarket_min_size,
                    max_size: stats.apartment_aftermarket_max_size,
                    avg_price_per_sqm: stats.apartment_aftermarket_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_aftermarket_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_aftermarket_max_price_per_sqm,
                    avg_price: stats.apartment_aftermarket_avg_price,
                    min_price: stats.apartment_aftermarket_min_price,
                    max_price: stats.apartment_aftermarket_max_price
                }
            },
            sell: {
                count: stats.apartment_sell,
                avg_price: stats.apartment_sell_avg_price,
                min_price: stats.apartment_sell_min_price,
                max_price: stats.apartment_sell_max_price,
                avg_price_per_sqm: stats.apartment_sell_avg_price_per_sqm,
                min_price_per_sqm: stats.apartment_sell_min_price_per_sqm,
                max_price_per_sqm: stats.apartment_sell_max_price_per_sqm,
                avg_size: stats.apartment_sell_avg_size,
                min_size: stats.apartment_sell_avg_size,
                max_size: stats.apartment_sell_avg_size,
                one_room: {
                    count: stats.apartment_sell_one_room,
                    avg_size: stats.apartment_sell_one_room_avg_size,
                    min_size: stats.apartment_sell_one_room_min_size,
                    max_size: stats.apartment_sell_one_room_max_size,
                    avg_price_per_sqm: stats.apartment_sell_one_room_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_one_room_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_one_room_max_price_per_sqm,
                    avg_price: stats.apartment_sell_one_room_avg_price,
                    min_price: stats.apartment_sell_one_room_min_price,
                    max_price: stats.apartment_sell_one_room_max_price
                },
                two_rooms: {
                    count: stats.apartment_sell_two_rooms,
                    avg_size: stats.apartment_sell_two_rooms_avg_size,
                    min_size: stats.apartment_sell_two_rooms_min_size,
                    max_size: stats.apartment_sell_two_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_sell_two_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_two_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_two_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_sell_two_rooms_avg_price,
                    min_price: stats.apartment_sell_two_rooms_min_price,
                    max_price: stats.apartment_sell_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.apartment_sell_three_rooms,
                    avg_size: stats.apartment_sell_three_rooms_avg_size,
                    min_size: stats.apartment_sell_three_rooms_min_size,
                    max_size: stats.apartment_sell_three_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_sell_three_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_three_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_three_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_sell_three_rooms_avg_price,
                    min_price: stats.apartment_sell_three_rooms_min_price,
                    max_price: stats.apartment_sell_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.apartment_sell_four_rooms,
                    avg_size: stats.apartment_sell_four_rooms_avg_size,
                    min_size: stats.apartment_sell_four_rooms_min_size,
                    max_size: stats.apartment_sell_four_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_sell_four_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_four_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_four_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_sell_four_rooms_avg_price,
                    min_price: stats.apartment_sell_four_rooms_min_price,
                    max_price: stats.apartment_sell_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.apartment_sell_five_rooms
                },
                aftermarket: {
                    count: stats.apartment_sell_aftermarket,
                    avg_size: stats.apartment_sell_aftermarket_avg_size,
                    min_size: stats.apartment_sell_aftermarket_min_size,
                    max_size: stats.apartment_sell_aftermarket_max_size,
                    avg_price_per_sqm: stats.apartment_sell_aftermarket_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_aftermarket_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_aftermarket_max_price_per_sqm,
                    avg_price: stats.apartment_sell_aftermarket_avg_price,
                    min_price: stats.apartment_sell_aftermarket_min_price,
                    max_price: stats.apartment_sell_aftermarket_max_price
                },
                primary_market: {
                    count: stats.apartment_sell_primary_market,
                    avg_price: stats.apartment_sell_primary_market_avg_price,
                    min_price: stats.apartment_sell_primary_market_min_price,
                    max_price: stats.apartment_sell_primary_market_max_price,
                    avg_price_per_sqm: stats.apartment_sell_primary_market_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_sell_primary_market_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_sell_primary_market_max_price_per_sqm,
                    avg_size: stats.apartment_sell_primary_market_avg_size,
                    min_size: stats.apartment_sell_primary_market_min_size,
                    max_size: stats.apartment_sell_primary_market_max_size
                }
            },
            rent: {
                count: stats.apartment_rent,
                avg_price: stats.apartment_rent_avg_price,
                min_price: stats.apartment_rent_min_price,
                max_price: stats.apartment_rent_max_price,
                avg_price_per_sqm: stats.apartment_rent_avg_price_per_sqm,
                min_price_per_sqm: stats.apartment_rent_min_price_per_sqm,
                max_price_per_sqm: stats.apartment_rent_max_price_per_sqm,
                avg_size: stats.apartment_rent_avg_size,
                min_size: stats.apartment_rent_min_size,
                max_size: stats.apartment_rent_max_size,
                one_room: {
                    count: stats.apartment_rent_one_room,
                    avg_size: stats.apartment_rent_one_room_avg_size,
                    min_size: stats.apartment_rent_one_room_min_size,
                    max_size: stats.apartment_rent_one_room_max_size,
                    avg_price_per_sqm: stats.apartment_rent_one_room_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_rent_one_room_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_rent_one_room_max_price_per_sqm,
                    avg_price: stats.apartment_rent_one_room_avg_price,
                    min_price: stats.apartment_rent_one_room_min_price,
                    max_price: stats.apartment_rent_one_room_max_price
                },
                two_rooms: {
                    count: stats.apartment_rent_two_rooms,
                    avg_size: stats.apartment_rent_two_rooms_avg_size,
                    min_size: stats.apartment_rent_two_rooms_min_size,
                    max_size: stats.apartment_rent_two_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_rent_two_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_rent_two_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_rent_two_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_rent_two_rooms_avg_price,
                    min_price: stats.apartment_rent_two_rooms_min_price,
                    max_price: stats.apartment_rent_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.apartment_rent_three_rooms,
                    avg_size: stats.apartment_rent_three_rooms_avg_size,
                    min_size: stats.apartment_rent_three_rooms_min_size,
                    max_size: stats.apartment_rent_three_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_rent_three_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_rent_three_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_rent_three_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_rent_three_rooms_avg_price,
                    min_price: stats.apartment_rent_three_rooms_min_price,
                    max_price: stats.apartment_rent_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.apartment_rent_four_rooms,
                    avg_size: stats.apartment_rent_four_rooms_avg_size,
                    min_size: stats.apartment_rent_four_rooms_min_size,
                    max_size: stats.apartment_rent_four_rooms_max_size,
                    avg_price_per_sqm: stats.apartment_rent_four_rooms_avg_price_per_sqm,
                    min_price_per_sqm: stats.apartment_rent_four_rooms_min_price_per_sqm,
                    max_price_per_sqm: stats.apartment_rent_four_rooms_max_price_per_sqm,
                    avg_price: stats.apartment_rent_four_rooms_avg_price,
                    min_price: stats.apartment_rent_four_rooms_min_price,
                    max_price: stats.apartment_rent_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.apartment_rent_five_rooms
                }
            }
        },
        house: {
            all: {
                count: stats.house,
                avg_price: stats.house_avg_price,
                // min_price: stats.house_min_price,
                // max_price: stats.house_max_price,
                avg_price_per_sqm: stats.house_avg_price_per_sqm,
                // min_price_per_sqm: stats.house_min_price_per_sqm,
                // max_price_per_sqm: stats.house_max_price_per_sqm,
                avg_size: stats.house_avg_price,
                // min_size: stats.house_min_price,
                // max_size: stats.house_max_price,
                two_rooms: {
                    count: stats.house_two_rooms,
                    avg_size: stats.house_two_rooms_avg_size,
                    // min_size: stats.house_two_rooms_min_size,
                    // max_size: stats.house_two_rooms_max_size,
                    avg_price_per_sqm: stats.house_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_two_rooms_max_price_per_sqm,
                    avg_price: stats.house_two_rooms_avg_price
                    // min_price: stats.house_two_rooms_min_price,
                    // max_price: stats.house_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.house_three_rooms,
                    avg_size: stats.house_three_rooms_avg_size,
                    // min_size: stats.house_three_rooms_min_size,
                    // max_size: stats.house_three_rooms_max_size,
                    avg_price_per_sqm: stats.house_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_three_rooms_max_price_per_sqm,
                    avg_price: stats.house_three_rooms_avg_price
                    // min_price: stats.house_three_rooms_min_price,
                    // max_price: stats.house_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.house_four_rooms,
                    avg_size: stats.house_four_rooms_avg_size,
                    // min_size: stats.house_four_rooms_min_size,
                    // max_size: stats.house_four_rooms_max_size,
                    avg_price_per_sqm: stats.house_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_four_rooms_max_price_per_sqm,
                    avg_price: stats.house_four_rooms_avg_price
                    // min_price: stats.house_four_rooms_min_price,
                    // max_price: stats.house_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.house_five_or_more_rooms,
                    avg_size: stats.house_five_or_more_rooms_avg_size,
                    // min_size: stats.house_five_or_more_rooms_min_size,
                    // max_size: stats.house_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.house_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.house_five_or_more_rooms_avg_price
                    // min_price: stats.house_five_or_more_rooms_min_price,
                    // max_price: stats.house_five_or_more_rooms_max_price
                },
                aftermarket: {
                    count: stats.house_aftermarket,
                    avg_size: stats.house_aftermarket_avg_size,
                    // min_size: stats.house_aftermarket_min_size,
                    // max_size: stats.house_aftermarket_max_size,
                    avg_price_per_sqm: stats.house_aftermarket_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_aftermarket_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_aftermarket_max_price_per_sqm,
                    avg_price: stats.house_aftermarket_avg_price
                    // min_price: stats.house_aftermarket_min_price,
                    // max_price: stats.house_aftermarket_max_price
                }
            },
            sell: {
                count: stats.house_sell,
                avg_price: stats.house_sell_avg_price,
                // min_price: stats.house_sell_min_price,
                // max_price: stats.house_sell_max_price,
                avg_price_per_sqm: stats.house_sell_avg_price_per_sqm,
                // min_price_per_sqm: stats.house_sell_min_price_per_sqm,
                // max_price_per_sqm: stats.house_sell_max_price_per_sqm,
                avg_size: stats.house_sell_avg_size,
                // min_size: stats.house_sell_min_size,
                // max_size: stats.house_sell_max_size,
                two_rooms: {
                    count: stats.house_sell_two_rooms,
                    avg_size: stats.house_sell_two_rooms_avg_size,
                    // min_size: stats.house_sell_two_rooms_min_size,
                    // max_size: stats.house_sell_two_rooms_max_size,
                    avg_price_per_sqm: stats.house_sell_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_two_rooms_max_price_per_sqm,
                    avg_price: stats.house_sell_two_rooms_avg_price
                    // min_price: stats.house_sell_two_rooms_min_price,
                    // max_price: stats.house_sell_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.house_sell_three_rooms,
                    avg_size: stats.house_sell_three_rooms_avg_size,
                    // min_size: stats.house_sell_three_rooms_min_size,
                    // max_size: stats.house_sell_three_rooms_max_size,
                    avg_price_per_sqm: stats.house_sell_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_three_rooms_max_price_per_sqm,
                    avg_price: stats.house_sell_three_rooms_avg_price
                    // min_price: stats.house_sell_three_rooms_min_price,
                    // max_price: stats.house_sell_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.house_sell_four_rooms,
                    avg_size: stats.house_sell_four_rooms_avg_size,
                    // min_size: stats.house_sell_four_rooms_min_size,
                    // max_size: stats.house_sell_four_rooms_max_size,
                    avg_price_per_sqm: stats.house_sell_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_four_rooms_max_price_per_sqm,
                    avg_price: stats.house_sell_four_rooms_avg_price
                    // min_price: stats.house_sell_four_rooms_min_price,
                    // max_price: stats.house_sell_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.house_sell_five_or_more_rooms,
                    avg_size: stats.house_sell_five_or_more_rooms_avg_size,
                    // min_size: stats.house_sell_five_or_more_rooms_min_size,
                    // max_size: stats.house_sell_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.house_sell_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.house_sell_five_or_more_rooms_avg_price
                    // min_price: stats.house_sell_five_or_more_rooms_min_price,
                    // max_price: stats.house_sell_five_or_more_rooms_max_price
                },
                aftermarket: {
                    count: stats.house_sell_aftermarket,
                    avg_size: stats.house_sell_aftermarket_avg_size,
                    // min_size: stats.house_sell_aftermarket_min_size,
                    // max_size: stats.house_sell_aftermarket_max_size,
                    avg_price_per_sqm: stats.house_sell_aftermarket_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_aftermarket_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_aftermarket_max_price_per_sqm,
                    avg_price: stats.house_sell_aftermarket_avg_price
                    // min_price: stats.house_sell_aftermarket_min_price,
                    // max_price: stats.house_sell_aftermarket_max_price
                },
                primary_market: {
                    count: stats.house_sell_primary_market,
                    avg_price: stats.house_sell_primary_market_avg_price,
                    avg_price_per_sqm: stats.house_sell_primary_market_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_sell_primary_market_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_sell_primary_market_max_price_per_sqm,
                    avg_size: stats.house_sell_primary_market_avg_size
                    // min_size: stats.house_sell_primary_market_min_size,
                    // max_size: stats.house_sell_primary_market_max_size,
                    // min_price: stats.house_sell_primary_market_min_price,
                    // max_price: stats.house_sell_primary_market_max_price
                }
            },
            rent: {
                count: stats.house_rent,
                avg_price: stats.house_rent_avg_price,
                // min_price: stats.house_rent_min_price,
                // max_price: stats.house_rent_max_price,
                avg_price_per_sqm: stats.house_rent_avg_price_per_sqm,
                // min_price_per_sqm: stats.house_rent_min_price_per_sqm,
                // max_price_per_sqm: stats.house_rent_max_price_per_sqm,
                avg_size: stats.house_rent_avg_size,
                // min_size: stats.house_rent_min_size,
                // max_size: stats.house_rent_max_size,
                two_rooms: {
                    count: stats.house_rent_two_rooms,
                    avg_size: stats.house_rent_two_rooms_avg_size,
                    // min_size: stats.house_rent_two_rooms_min_size,
                    // max_size: stats.house_rent_two_rooms_max_size,
                    avg_price_per_sqm: stats.house_rent_two_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_rent_two_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_rent_two_rooms_max_price_per_sqm,
                    avg_price: stats.house_rent_two_rooms_avg_price
                    // min_price: stats.house_rent_two_rooms_min_price,
                    // max_price: stats.house_rent_two_rooms_max_price
                },
                three_rooms: {
                    count: stats.house_rent_three_rooms,
                    avg_size: stats.house_rent_three_rooms_avg_size,
                    // min_size: stats.house_rent_three_rooms_min_size,
                    // max_size: stats.house_rent_three_rooms_max_size,
                    avg_price_per_sqm: stats.house_rent_three_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_rent_three_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_rent_three_rooms_max_price_per_sqm,
                    avg_price: stats.house_rent_three_rooms_avg_price
                    // min_price: stats.house_rent_three_rooms_min_price,
                    // max_price: stats.house_rent_three_rooms_max_price
                },
                four_rooms: {
                    count: stats.house_rent_four_rooms,
                    avg_size: stats.house_rent_four_rooms_avg_size,
                    // min_size: stats.house_rent_four_rooms_min_size,
                    // max_size: stats.house_rent_four_rooms_max_size,
                    avg_price_per_sqm: stats.house_rent_four_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_rent_four_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_rent_four_rooms_max_price_per_sqm,
                    avg_price: stats.house_rent_four_rooms_avg_price
                    // min_price: stats.house_rent_four_rooms_min_price,
                    // max_price: stats.house_rent_four_rooms_max_price
                },
                five_rooms: {
                    count: stats.house_rent_five_or_more_rooms,
                    avg_size: stats.house_rent_five_or_more_rooms_avg_size,
                    // min_size: stats.house_rent_five_or_more_rooms_min_size,
                    // max_size: stats.house_rent_five_or_more_rooms_max_size,
                    avg_price_per_sqm: stats.house_rent_five_or_more_rooms_avg_price_per_sqm,
                    // min_price_per_sqm: stats.house_rent_five_or_more_rooms_min_price_per_sqm,
                    // max_price_per_sqm: stats.house_rent_five_or_more_rooms_max_price_per_sqm,
                    avg_price: stats.house_rent_five_or_more_rooms_avg_price
                    // min_price: stats.house_rent_five_or_more_rooms_min_price,
                    // max_price: stats.house_rent_five_or_more_rooms_max_price
                }
            }
        },
        additional_stats: {
            apartment_sell_primary_market_unique_investments: stats.apartment_sell_primary_market_unique_investments,
            house_sell_primary_market_unique_investments: stats.house_sell_primary_market_unique_investments
        }
    });
