import {Libraries, Library, Loader} from "@googlemaps/js-api-loader";

import {GOOGLE_MAPS_API_KEY} from "../../app/constants/keys";

/**
 * GoogleLoader is a helper type that describes part of the google.maps object that is created in window after api initialization.
 * `importLibrary` method is untyped, but available in window, it is also mentioned in the documentation as a way to load additional
 * libraries on the go, ie. here https://developers.google.com/maps/documentation/javascript/libraries?hl=pl
 *
 * if that fails, we can always store reference to the Loader class instead of google.maps object
 */
type GoogleLoader = {importLibrary: (lib: Library) => Promise<void>};

export const loadGoogleMapsApi = async (libraries: Libraries) => {
    let mapLoader: Loader | GoogleLoader | null = null;

    if (!window.google) {
        mapLoader = new Loader({
            apiKey: GOOGLE_MAPS_API_KEY,
            region: "PL",
            language: "pl"
        });
    }

    if (window.google && !mapLoader) {
        mapLoader = google.maps as unknown as GoogleLoader;
    }

    for (const lib of libraries) {
        await mapLoader?.importLibrary(lib);
    }
};
