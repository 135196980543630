import {capitalizeFirst} from "@web2/nodash";
import {pluralize} from "@web2/string_utils";

import {getMetaPage} from "../../../meta_data/utils/get_meta_page";
import {OfferTypePL} from "../../utils/constants_offer";
import {DEFAULT_SELECTED_LOCATION} from "../actions/update_offer_list_meta_data";
import {getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {UrlTagSlug} from "../url_utils/tags";

interface IProps {
    offersCount: number;
    currentLocationName: string;
    currentLocationNameWhere: string | null;
    dealType: string;
    offerTypePL: string;
    page: number;
    tag?: string;
}

export const getOfferListDescription = (props: IProps) => {
    const {offersCount, currentLocationName, currentLocationNameWhere, dealType, offerTypePL, page, tag} = props;
    const locationDescription =
        currentLocationName === DEFAULT_SELECTED_LOCATION ? " w Polsce" : currentLocationName === "" ? "" : " " + currentLocationNameWhere;
    const currentLocationNameString = currentLocationName === "" ? "" : " " + currentLocationName;
    const inString = currentLocationName === "" ? "" : " w";
    const offerTypePLLowerCase = offerTypePL.toLowerCase();
    const dealTypeVerb = getDealTypeVerbPL(dealType.toLowerCase());
    const offerTypeDeclensionWhat = getOfferTypeDeclensionWhat(offerTypePL.toLowerCase());
    const offerTypePlural = getOfferTypePlural(offerTypePL.toLowerCase());

    const pluralUnique = pluralize(["unikalną", "unikalne", "unikalnych"])(offersCount);
    const pluralStudio = pluralize(["kawalerki", "kawalerek", "kawalerek"])(offersCount);
    const pluralOfferType = getPluralizedOfferType(offerTypePL.toLowerCase(), offersCount); // mieszkanie, mieszkania, mieszkań
    const pluralOfferNoun = pluralize(["ofertę", "oferty", "ofert"])(offersCount);
    const pluralOfferNounThis = pluralize(["oferta", "oferty", "ofert"])(offersCount);
    const uniqueOfferTypeName = `${pluralUnique} ${pluralOfferNoun} ${pluralOfferType}`;
    const offerTypeSingularVerbosePL = getOfferTypeSingularVerbosePL(offerTypePL);

    const pluralTwoRoom =
        offerTypePL.toLowerCase() == "domy"
            ? pluralize(["dwupokojowego", "dwupokojowych", "dwupokojowych"])(offersCount)
            : pluralize(["2 pokojowego", "2 pokojowych", "2 pokojowych"])(offersCount);
    const pluralTwoRoomString = offerTypePL.toLowerCase() == "domy" ? "dwupokojowe" : "2 pokojowe";
    const twoRoomAdjective = getTwoRoomAdjective(offerTypePL);
    const twoRoomString =
        offerTypePLLowerCase !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${pluralTwoRoomString}`
            : `${capitalizeFirst(pluralTwoRoomString)} ${offerTypePL.toLowerCase()}`;

    const pluralThreeRoom =
        offerTypePL.toLowerCase() == "domy"
            ? pluralize(["trzypokojowego", "trzypokojowych", "trzypokojowych"])(offersCount)
            : pluralize(["3 pokojowego", "3 pokojowych", "3 pokojowych"])(offersCount);
    const pluralThreeRoomString = offerTypePL.toLowerCase() == "domy" ? "trzypokojowe" : "3 pokojowe";
    const threeRoomAdjective = getThreeRoomAdjective(offerTypePL);
    const threeRoomString =
        offerTypePLLowerCase !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${pluralThreeRoomString}`
            : `${capitalizeFirst(pluralThreeRoomString)} ${offerTypePL.toLowerCase()}`;

    const pluralFourRoom =
        offerTypePL.toLowerCase() == "domy"
            ? pluralize(["czteropokojowego", "czteropokojowych", "czteropokojowych"])(offersCount)
            : pluralize(["4 pokojowego", "4 pokojowych", "4 pokojowych"])(offersCount);
    const pluralFourRoomString = offerTypePL.toLowerCase() == "domy" ? "czteropokojowe" : "4 pokojowe";
    const fourRoomAdjective = getFourRoomAdjective(offerTypePL);
    const fourRoomString =
        offerTypePLLowerCase !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${pluralFourRoomString}`
            : `${capitalizeFirst(pluralFourRoomString)} ${offerTypePL.toLowerCase()}`;

    const pluralFiveRoom =
        offerTypePL.toLowerCase() == "domy"
            ? pluralize(["pięciopokojowego", "pięciopokojowych", "pięciopokojowych"])(offersCount)
            : pluralize(["5 pokojowego", "5 pokojowych", "5 pokojowych"])(offersCount);
    const pluralFiveRoomString = offerTypePL.toLowerCase() == "domy" ? "pięciopokojowe" : "5 pokojowe";
    const fiveRoomAdjective = getFiveRoomAdjective(offerTypePL);
    const fiveRoomString =
        offerTypePLLowerCase !== OfferTypePL.house
            ? `${capitalizeFirst(offerTypePL)} ${pluralFiveRoomString}`
            : `${capitalizeFirst(pluralFiveRoomString)} ${offerTypePL.toLowerCase()}`;

    /*
     * tagged descriptions
     */
    const foundTag = getUrlTagDataBySlug(tag);
    const metaPageNumber = getMetaPage(page);

    // /t/kawalerki/
    if (foundTag?.tag === UrlTagSlug.STUDIO && offerTypePLLowerCase !== OfferTypePL.house) {
        if ((dealType == "na sprzedaż" || dealType == "do wynajęcia") && offerTypePLLowerCase !== OfferTypePL.property) {
            return `Kawalerki ${dealType}${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralStudio} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia kawalerki${currentLocationNameString} w serwisie gethome.pl`;
        }

        return `Chcesz ${dealTypeVerb} kawalerkę${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. Sprawdź gethome.pl✅`;
    }

    // /t/dwupokojowe/
    if (foundTag?.tag === UrlTagSlug.TWO_ROOM) {
        if ((dealType == "na sprzedaż" || dealType == "do wynajęcia") && offerTypePLLowerCase !== OfferTypePL.property) {
            return `${twoRoomString} ${dealType}${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${pluralTwoRoom} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia ${pluralOfferType} ${pluralTwoRoom}${currentLocationNameString} w serwisie gethome.pl`;
        }

        return `Chcesz ${dealTypeVerb} ${twoRoomAdjective} ${offerTypeSingularVerbosePL}${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. Sprawdź gethome.pl✅`;
    }

    // /t/trzypokojowe/
    if (foundTag?.tag === UrlTagSlug.THREE_ROOM) {
        if ((dealType == "na sprzedaż" || dealType == "do wynajęcia") && offerTypePLLowerCase !== OfferTypePL.property) {
            return `${threeRoomString} ${dealType}${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${pluralThreeRoom} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia ${pluralOfferType} ${pluralThreeRoom}${currentLocationNameString} w serwisie gethome.pl`;
        }

        return `Chcesz ${dealTypeVerb} ${threeRoomAdjective} ${offerTypeSingularVerbosePL}${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. Sprawdź gethome.pl✅`;
    }

    // /t/czteropokojowe/
    if (foundTag?.tag === UrlTagSlug.FOUR_ROOM) {
        if ((dealType == "na sprzedaż" || dealType == "do wynajęcia") && offerTypePLLowerCase !== OfferTypePL.property) {
            return `${fourRoomString} ${dealType}${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${pluralFourRoom} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia ${pluralOfferType} ${pluralFourRoom}${currentLocationNameString} w serwisie gethome.pl`;
        }

        return `Chcesz ${dealTypeVerb} ${fourRoomAdjective} ${offerTypeSingularVerbosePL}${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. Sprawdź gethome.pl✅`;
    }

    // /t/pięciopokojowe/
    if (foundTag?.tag === UrlTagSlug.FIVE_ROOM) {
        if ((dealType == "na sprzedaż" || dealType == "do wynajęcia") && offerTypePLLowerCase !== OfferTypePL.property) {
            return `${fiveRoomString} ${dealType}${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${pluralFiveRoom} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia ${pluralOfferType} ${pluralFiveRoom}${currentLocationNameString}w serwisie gethome.pl`;
        }

        return `Chcesz ${dealTypeVerb} ${fiveRoomAdjective} ${offerTypeSingularVerbosePL}${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. Sprawdź gethome.pl✅`;
    }

    if (foundTag?.tag === UrlTagSlug.INDIVIDUAL_OFFERS) {
        return `${capitalizeFirst(
            offerTypePL
        )} ${dealType}${currentLocationNameString} bez pośredników - prywatne oferty ${offerTypeDeclensionWhat}${locationDescription}. Aktualne ogłoszenia ${offerTypePlural}${currentLocationNameString} bezpośrednio od właścicieli w serwisie gethome.pl`;
    }

    if (foundTag?.tag === UrlTagSlug.PRIMARY_MARKET) {
        return `Nowe ${offerTypePL} ${dealType} rynek pierwotny${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${dealType}${inString}${currentLocationNameString} ✅. Aktualne ogłoszenia nowe ${offerTypePLLowerCase}${currentLocationNameString} w serwisie gethome.pl`;
    }

    /*
     * regular description
     */
    if (dealType == "na sprzedaż" || dealType == "do wynajęcia") {
        return `${capitalizeFirst(offerTypePL)} ${
            foundTag?.metaText && dealType == "na sprzedaż" ? foundTag.metaText : dealType
        }${currentLocationNameString} - ${offersCount} ${pluralOfferNounThis} ${pluralOfferType} ${dealType}${locationDescription}${metaPageNumber} ✅. Aktualne ogłoszenia ${offerTypePLLowerCase}${currentLocationNameString} w serwisie gethome.pl`;
    }

    return `Chcesz ${dealTypeVerb} ${offerTypeSingularVerbosePL}${locationDescription}? Mamy dla Ciebie ${offersCount} ${uniqueOfferTypeName} ${dealType}${metaPageNumber}. ✅ Sprawdź na gethome.pl!`;
};

/*
 * helpers
 */

const getDealTypeVerbPL = (dealType: string) => {
    switch (dealType) {
        case "do wynajęcia":
            return "wynająć";
        case "na sprzedaż":
            return "kupić";
        default:
            return "kupić lub wynająć";
    }
};

const getOfferTypeSingularVerbosePL = (offerType: string) => {
    switch (offerType.toLowerCase()) {
        case OfferTypePL.apartment:
            return "mieszkanie";

        case OfferTypePL.house:
            return "dom";

        case OfferTypePL.lot:
            return "działkę";

        case OfferTypePL.property:
            return "nieruchomość";

        default:
            return "nieruchomość";
    }
};

const getPluralizedOfferType = (oT: string, count: number) => {
    switch (oT) {
        case OfferTypePL.apartment:
            return pluralize(["mieszkania", "mieszkań", "mieszkań"])(count);
        case OfferTypePL.lot:
            return pluralize(["działki", "działek", "działek"])(count);
        case OfferTypePL.house:
            return pluralize(["domu", "domów", "domów"])(count);
        case OfferTypePL.property:
        default:
            // exception
            return "nieruchomości";
    }
};

const getOfferTypeDeclensionWhat = (offerType: OfferTypePL | string) => {
    switch (offerType) {
        case OfferTypePL.apartment:
            return "mieszkań";
        case OfferTypePL.house:
            return "domów";
        case OfferTypePL.lot:
            return "działek";
        case OfferTypePL.property:
        default:
            return "nieruchomości";
    }
};

const getOfferTypePlural = (offerType: OfferTypePL | string) => {
    switch (offerType) {
        case OfferTypePL.apartment:
            return "mieszkania";
        case OfferTypePL.house:
            return "domy";
        case OfferTypePL.lot:
            return "działki";
        case OfferTypePL.property:
        default:
            return "nieruchomości";
    }
};

const getTwoRoomAdjective = (oT: string) => {
    switch (oT.toLowerCase()) {
        case OfferTypePL.apartment:
            return "dwupokojowe";
        case OfferTypePL.house:
            return "dwupokojowy";
        case OfferTypePL.property:
            return "dwupokojową";
        default:
            return "";
    }
};

const getThreeRoomAdjective = (oT: string) => {
    switch (oT.toLowerCase()) {
        case OfferTypePL.apartment:
            return "trzypokojowe";
        case OfferTypePL.house:
            return "trzypokojowy";
        case OfferTypePL.property:
            return "trzypokojową";
        default:
            return "";
    }
};

const getFourRoomAdjective = (oT: string) => {
    switch (oT.toLowerCase()) {
        case OfferTypePL.apartment:
            return "czteropokojowe";
        case OfferTypePL.house:
            return "czteropokojowy";
        case OfferTypePL.property:
            return "czteropokojową";
        default:
            return "";
    }
};

const getFiveRoomAdjective = (oT: string) => {
    switch (oT.toLowerCase()) {
        case OfferTypePL.apartment:
            return "pięciopokojowe";
        case OfferTypePL.house:
            return "pięciopokojowy";
        case OfferTypePL.property:
            return "pięciopokojową";
        default:
            return "";
    }
};
