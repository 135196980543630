import {numberWithDelimiter} from "@web2/string_utils";

import {ILocationStatisticsApartmentSell} from "../../../../../app/interfaces/response/location_statistics";
import {sizeFormat} from "../../../../../app/utils/number";
import {nowrap} from "../../../../../styles/helpers";

export const CheapFlats = (props: {
    locationName: string;
    locationDeclination: string | undefined;
    statistics: ILocationStatisticsApartmentSell;
    hasPrimaryMarket: boolean | undefined;
    hasAfterMarket: boolean | undefined;
}) => {
    const {locationName, locationDeclination, statistics, hasPrimaryMarket, hasAfterMarket} = props;

    return (
        <>
            <h3>Tanie mieszkania {locationName}. Okazja z rynku pierwotnego i wtórnego </h3>
            <p>
                {hasPrimaryMarket && (
                    <>
                        {statistics.primary_market.min_price > 0 && (
                            <>
                                Według naszej bazy najtańsze mieszkanie z rynku pierwotnego {locationDeclination} to wydatek{" "}
                                {numberWithDelimiter(sizeFormat(statistics.primary_market.min_price))} zł.
                            </>
                        )}{" "}
                        {statistics.primary_market.min_price_per_sqm > 0 && (
                            <>
                                Co więcej, najniższa cena mieszkania za metr kwadratowy na rynku pierwotnym wynosi{" "}
                                {numberWithDelimiter(sizeFormat(statistics.primary_market.min_price_per_sqm))} zł/m
                                <sup>2</sup>.
                            </>
                        )}
                    </>
                )}
            </p>
            {hasAfterMarket && (
                <p>
                    {statistics.aftermarket.min_price && (
                        <>Najtańsze mieszkanie z rynku wtórnego kosztuje {numberWithDelimiter(sizeFormat(statistics.aftermarket.min_price))} zł. </>
                    )}
                    {statistics.aftermarket.min_price_per_sqm ? (
                        <>
                            {" "}
                            Najniższa cena metra kwadratowego mieszkania z drugiej ręki wynosi{" "}
                            <span css={nowrap}>{numberWithDelimiter(sizeFormat(statistics.aftermarket.min_price_per_sqm))}</span> zł/m
                            <sup>2</sup>.
                        </>
                    ) : null}
                </p>
            )}
        </>
    );
};
