import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const TickIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || 20} height={props.height || 16.02} viewBox="0 0 20 16.02">
            <path
                d="M19.39.6a2.05,2.05,0,0,0-2.9,0L7.32,10,3.7,6A2.06,2.06,0,1,0,.41,8.51a2.16,2.16,0,0,0,.25.27L7.21,16,19.42,3.49A2.06,2.06,0,0,0,19.39.6Z"
                fill={props.mainColor || "#45bc83"}
            />
        </svg>
    );
};
