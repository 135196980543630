import {combineReducers} from "redux";

import {IAuthorDetailArticle, IAuthorDetailAuthor} from "../../app/interfaces/response/author_detail";
import {IAuthorListAuthor} from "../../app/interfaces/response/author_list";
import {reduceRequestState, RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchAuthorArticlesTypes} from "../actions/fetch_author_articles_at_route";
import {fetchAuthorDetailActionTypes} from "../actions/fetch_author_detail_at_route";
import {fetchAuthorListActionTypes} from "../actions/fetch_author_list_at_route";

export interface IAuthorsStore {
    authorDetail: {
        author: IAuthorDetailAuthor;
    };
    authorDetailRequestState: RequestState;
    authorDetailArticleList: {
        count: number;
        next: number;
        page: number;
        page_size: number;
        previous: number;
        articles: IAuthorDetailArticle[];
    };
    authorDetailArticleListRequestState: RequestState;
    authorList: {
        count: number;
        next: number;
        page: number;
        page_size: number;
        previous: number;
        authors: IAuthorListAuthor[];
    };
    authorListRequestState: RequestState;
}

export const authorsReducer = combineReducers({
    authorDetail: reduceResponse(fetchAuthorDetailActionTypes),
    authorDetailRequestState: reduceRequestState(fetchAuthorDetailActionTypes),
    authorDetailArticleList: reduceResponse(fetchAuthorArticlesTypes),
    authorDetailArticleListRequestState: reduceRequestState(fetchAuthorArticlesTypes),
    authorList: reduceResponse(fetchAuthorListActionTypes),
    authorListRequestState: reduceRequestState(fetchAuthorListActionTypes)
});
