import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {css, cx} from "@linaria/core";
import {InputRange} from "@web2/form";
import {IRange} from "@web2/form_fields";

import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {filterTitle} from "../atoms/atoms";
import {RANGE_SETTINGS} from "./obsolete_range_filter/config";

interface IProps {
    name: string;
    id: string;
    onAfterChange?: any;
    onChange: (name: string, value: IRange<string>) => void;
    value: IRange<string>;
    enableOnBlur?: boolean;
    title?: string;
    clearOnFocus?: boolean;
}

export const FiltersRange = (props: IProps) => {
    const [minValid, setMinValid] = useState(true);
    const [maxValid, setMaxValid] = useState(true);

    useEffect(() => {
        const {upper, lower} = props.value;
        validateRangeBounds(props.name, props.value);

        const min = lower.trim() !== "" ? parseInt(lower) : "";
        const max = upper.trim() !== "" ? parseInt(upper) : "";
        let newMin = min;
        let newMax = max;
        const rangeMin = RANGE_SETTINGS[props.name.toUpperCase() + "__GTE"];
        const rangeMax = RANGE_SETTINGS[props.name.toUpperCase() + "__LTE"];
        if (min !== "") {
            if (min < rangeMin) {
                newMin = rangeMin;
            }
        }
        if (max !== "") {
            if (max < rangeMin || max > rangeMax) {
                newMax = rangeMax;
            }
        }

        if (min !== newMin || max !== newMax) {
            props.onChange(props.name, {lower: newMin.toString(), upper: newMax.toString()});
        }
    }, []);

    const checkUpperAndLowerValues = useCallback(() => {
        const {upper, lower} = props.value;

        const upperNumber = parseInt(upper, 10);
        const lowerNumber = parseInt(lower, 10);

        if (upperNumber > 0 && lowerNumber > 0) {
            if (upperNumber < lowerNumber) {
                props.onChange(props.name, {lower: lower, upper: lower});
            }

            if (lowerNumber > upperNumber) {
                props.onChange(props.name, {lower: upper, upper: upper});
            }
        }
    }, [props.value]);

    const validateRangeBounds = (name: string, value: IRange<string>) => {
        if (!value) return;

        const {upper, lower} = value;
        const min = lower.trim() !== "" ? parseInt(lower) : undefined;
        const max = upper.trim() !== "" ? parseInt(upper) : undefined;
        const rangeMin = RANGE_SETTINGS[name.toUpperCase() + "__GTE"];
        const rangeMax = RANGE_SETTINGS[name.toUpperCase() + "__LTE"];

        let minValid = true;
        let maxValid = true;

        if (min !== undefined) {
            minValid = min >= rangeMin && min <= rangeMax;
        }

        if (max !== undefined) {
            maxValid = max >= rangeMin && max <= rangeMax;
        }

        setMinValid(minValid);
        setMaxValid(maxValid);
    };

    const onChangeHandler = (name: string, value: IRange<string>) => {
        validateRangeBounds(name, value);
        props.onChange(name, value);
    };

    return (
        <>
            {props.title && <p className={filterTitle}>{props.title}</p>}

            <div className={cx(inputWrapper, !minValid && minError, !maxValid && maxError)}>
                <InputRange
                    name={props.name}
                    value={props.value}
                    onChange={(fieldName, value) => onChangeHandler(fieldName, value)}
                    onAfterChange={props.onAfterChange}
                    enableOnBlur={props.enableOnBlur}
                    clearOnFocus={props.clearOnFocus}
                    onBlur={checkUpperAndLowerValues}
                />
            </div>
        </>
    );
};

const inputWrapper = css`
    /* disable input arrows */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        
        &.minError {
            .range-input-lower input {
                border-color: ${getThemeVariable("colors-brand_danger")};

            }


    /* disable input arrows Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .range-input-lower {
        margin-bottom: 0;
    }

    .range-input-upper {
        margin: 0 !important;
    }

    @media (max-width: calc(${getThemeBreakpointCorrect().screen_md} - 1px)) {
        .range-input-lower,
        .range-input-upper {
            input {
                line-height: 1.64;
            }
        }
    }

    label {
        display: none;
    }
    input {
        text-align: right;
    }
`;

const minError = css`
    .range-input-lower input {
        border-color: ${getThemeVariable("colors-brand_danger")};
    }
`;
const maxError = css`
    .range-input-upper input {
        border-color: ${getThemeVariable("colors-brand_danger")};
    }
`;
