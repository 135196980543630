import {filter, isEmpty, isEqual, orderBy} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {IOfferType, OfferDealType, OfferType, OfferTypeEN} from "../../utils/constants_offer";
import {locationOfferCount, MINIMAL_OFFER_COUNT} from "../../utils/filter_recommended_locations";
import {getLocationTypeToFetch} from "../../utils/get_location_type_to_fetch";
import {translateOfferType} from "../../utils/utils";
import {ILocation} from "./fetch_location_by_slug_at_route";
import {dealTypeFromUrl, ISearchResultRouteParams, offerTypeFromUrl} from "./fetch_offer_list_at_route";

export const fetchRecommendedLocationsTypes = createRequestActionTypes({
    name: "recommendedLocations",
    type: "GET",
    view: "offer_list"
});

export const fetchRecommendedLocationsAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => (dispatch: Dispatch, getState: () => IStore) => {
        const params = {
            deal_type: dealTypeFromUrl(route.params.type) || OfferDealType.SELL,
            offer_type: translateOfferType(offerTypeFromUrl(<OfferType>route.params.offerType), OfferTypeEN) as IOfferType
        };

        const location = getState().offerList.location.location;
        const locationType = !isEmpty(location) ? getLocationTypeToFetch(location) : [{type: "Wojewodztwo"}];

        const urls = locationType.map((item) => {
            const url = apiLink.locations.base({})(null);
            return appendQueryString(url, {...params, ...item});
        });

        if (isEqual(getState().offerList.location.recommendedLatestQuery, urls)) {
            // prevent fetching the same data
            return Promise.resolve(true);
        }
        dispatch({type: fetchRecommendedLocationsTypes.start, latestQuery: urls});

        const fetchPromises = urls.map((url, index) =>
            getRequest(services, url, `fetchRecommendedLocationsAtRoute${index}`).catch(catchAbortedError(() => false))
        );

        return Promise.all(fetchPromises).then((responses: ILocation[][]) => {
            const allLocations = ([] as ILocation[]).concat(...responses);
            const filteredLocations = filter(
                allLocations,
                (location) => locationOfferCount(params.deal_type, params.offer_type, location) >= MINIMAL_OFFER_COUNT
            );
            const sortedLocations = orderBy(filteredLocations, (location) => locationOfferCount(params.deal_type, params.offer_type, location), ["desc"]);
            const result = !isEmpty(location) ? filter(sortedLocations, (loc: ILocation) => loc.id !== location.id) : sortedLocations;

            dispatch({type: fetchRecommendedLocationsTypes.success, result});
        });
    };
