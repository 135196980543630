import {getCookie} from "@web2/request_utils";

export const buildABTestCookie = (abTestId: string, abTestVariant: number, expires?: Date, domain?: string): string => {
    return `${abTestId}=${abTestVariant}; Path=/; Domain=${domain}; Expires=${expires ? new Date(expires).toUTCString() : null};`;
};

export const getABTestVariantFromDocumentCookies = (abTestId: string): number | null => {
    const variantFromDocumentCookie = getCookie(abTestId);
    return variantFromDocumentCookie ? parseInt(variantFromDocumentCookie) : null;
};
