import * as React from "react";

export const FamilyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path d="M15,19.56A1.61,1.61,0,1,0,13.37,18,1.61,1.61,0,0,0,15,19.56Zm0-2.46a.87.87,0,1,1-.87.87A.87.87,0,0,1,15,17.1Z" fill="#37474f" />
            <path
                d="M22.08,7.8a2.65,2.65,0,1,0-2.63-2.65A2.67,2.67,0,0,0,22.08,7.8Zm0-4.56a1.91,1.91,0,1,1-1.91,1.91A1.91,1.91,0,0,1,22.08,3.24Z"
                fill="#37474f"
            />
            <path
                d="M26,11.86a.37.37,0,0,0-.36.37v5a.23.23,0,0,1-.24.23H24.5V11a.37.37,0,0,0-.36-.3.38.38,0,0,0-.37.3V26.44a.26.26,0,0,1-.24.25h-.85a.25.25,0,0,1-.23-.25V20.68a.37.37,0,0,0-.74,0v5.76a.25.25,0,0,1-.23.25h-.85a.25.25,0,0,1-.23-.25V11a.37.37,0,0,0-.37-.37.38.38,0,0,0-.37.37v6.46h-.88a.24.24,0,0,1-.23-.23V11a2.18,2.18,0,0,1,2.18-2.17h2.74A2.17,2.17,0,0,1,25.64,11a.39.39,0,0,0,.16.38.38.38,0,0,0,.41,0,.36.36,0,0,0,.16-.38,2.9,2.9,0,0,0-2.9-2.9H20.72A2.92,2.92,0,0,0,17.8,11v6.23a1,1,0,0,0,1,1h.89v8.24a1,1,0,0,0,1,1h.85a.89.89,0,0,0,.6-.22.94.94,0,0,0,.6.22h.85a1,1,0,0,0,.69-.28,1,1,0,0,0,.28-.69V18.2h.89a1,1,0,0,0,1-1v-5a.39.39,0,0,0-.11-.27A.35.35,0,0,0,26,11.86Z"
                fill="#37474f"
            />
            <path
                d="M7.33,10A2.65,2.65,0,1,0,4.78,7.35,2.64,2.64,0,0,0,7.33,10Zm0-4.54A1.91,1.91,0,0,1,8.72,8.74a1.91,1.91,0,0,1-2.1.4,1.91,1.91,0,0,1,.71-3.66Z"
                fill="#37474f"
            />
            <path
                d="M8.6,10.37H6.17a2.55,2.55,0,0,0-2.55,2.54v5.51a.89.89,0,0,0,.89.9l-.25,2.3a.39.39,0,0,0,.09.29.36.36,0,0,0,.28.12h.61v4.54a.89.89,0,0,0,.89.89h.75a.77.77,0,0,0,.45-.18.85.85,0,0,0,.53.18H8.6a.89.89,0,0,0,.64-.25.9.9,0,0,0,.26-.64v-2a.37.37,0,1,0-.73,0v2a.18.18,0,0,1-.17.17H7.9a.18.18,0,0,1-.17-.17V22.62a.36.36,0,0,0-.15-.37.38.38,0,0,0-.41,0,.35.35,0,0,0-.16.37v3.95a.17.17,0,0,1-.17.17H6.05a.16.16,0,0,1-.11,0,.17.17,0,0,1,0-.12V22H8.8v1.35a.38.38,0,0,0,.37.37.37.37,0,0,0,.37-.37V22h.61a.36.36,0,0,0,.28-.12.39.39,0,0,0,.09-.29l-.25-2.3a.89.89,0,0,0,.89-.9V13a2.54,2.54,0,0,0-.73-1.83A2.52,2.52,0,0,0,8.6,10.37Zm1.83,8.05a.13.13,0,0,1,0,.12.16.16,0,0,1-.11.05h-.09l-.67-6.08a.4.4,0,0,0-.14-.26.36.36,0,0,0-.27-.08.35.35,0,0,0-.25.14.37.37,0,0,0-.07.27l1,8.77H5l1-8.72a.38.38,0,0,0-.08-.27.31.31,0,0,0-.25-.13.36.36,0,0,0-.39.33l-.68,6.08H4.46a.16.16,0,0,1-.16-.16V13a1.85,1.85,0,0,1,1.87-1.79H8.6A1.85,1.85,0,0,1,10.43,13Z"
                fill="#37474f"
            />
            <path
                d="M17.79,18.34a1,1,0,0,0-.69.3l-1.36,1.44H14.33L13,18.64a1,1,0,0,0-1.39,0,.94.94,0,0,0-.29.69,1,1,0,0,0,.29.7l1.58,1.68v4.84a1,1,0,0,0,.95.95H16a1,1,0,0,0,1-.95V24.5a.37.37,0,0,0-.37-.36.36.36,0,0,0-.36.36v2a.23.23,0,0,1-.23.23h-.61V23.68a.37.37,0,0,0-.37-.37.38.38,0,0,0-.37.37v3.06h-.61a.24.24,0,0,1-.23-.23v-5a.3.3,0,0,0-.1-.25l-1.66-1.79a.23.23,0,0,1-.07-.23.24.24,0,0,1,.17-.17.23.23,0,0,1,.24.06l1.49,1.57a.37.37,0,0,0,.27.11h1.71a.37.37,0,0,0,.27-.11l1.48-1.57a.24.24,0,0,1,.34,0,.24.24,0,0,1,0,.36L16.3,21.29a.38.38,0,0,0-.09.25V23.3a.37.37,0,0,0,.62.26.36.36,0,0,0,.11-.26V21.67L18.52,20a1,1,0,0,0,.28-.69.93.93,0,0,0-1-1Z"
                fill="#37474f"
            />
        </svg>
    );
};
