import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const CookHatIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M2.3,8.05l-.37-.16v.25a.73.73,0,0,0,.44.67l.14.06a6.23,6.23,0,0,0,5,0l.15-.06a.74.74,0,0,0,.43-.67l0-.25-.37.16A6.79,6.79,0,0,1,2.3,8.05Z"
                fill={props.secondaryFillColor}
            />

            <path d="M7.66,1.3a1.51,1.51,0,0,0-.3,0A2.78,2.78,0,0,0,5,.06,2.77,2.77,0,0,0,2.63,1.32a1.51,1.51,0,0,0-.3,0A2.21,2.21,0,0,0,.05,3.42,2.14,2.14,0,0,0,1.37,5.34v2.8a1.29,1.29,0,0,0,.77,1.18l.15.06a6.79,6.79,0,0,0,5.41,0l.15-.06a1.29,1.29,0,0,0,.77-1.18V5.35a2.31,2.31,0,0,0,1.09-1,2.09,2.09,0,0,0,.24-1A2.2,2.2,0,0,0,7.66,1.3Zm.4,6.84a.74.74,0,0,1-.43.67l-.15.06a6.23,6.23,0,0,1-5,0l-.14-.06a.73.73,0,0,1-.44-.67V7.89l.37.16a6.79,6.79,0,0,0,5.41,0l.37-.16Zm1.15-4a1.67,1.67,0,0,1-1,.77L8.06,5V7.27l-.58.27a6.23,6.23,0,0,1-5,0l-.58-.25V5.46h.24a2,2,0,0,0,2-1.08l-.52-.21c-.35.9-1.77.74-2,.71A1.56,1.56,0,0,1,.61,3.42,1.64,1.64,0,0,1,2.33,1.85l.37,0,.21.05L3,1.75a2.28,2.28,0,0,1,4,0l.1.19.21,0a2.21,2.21,0,0,1,.37,0A1.66,1.66,0,0,1,9.37,3.44,1.57,1.57,0,0,1,9.21,4.11Z" />
        </SvgIcon>
    );
};
