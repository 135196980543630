import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const InformationIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "10.88mm"} height={props.height || "11.78mm"} viewBox="0 0 30.83 33.39">
            <path
                d="M2.33,31.28a.65.65,0,0,0,.64,0l9-4.84.53.24L21,31.29a.63.63,0,0,0,.63,0,.64.64,0,0,0,.32-.56V27.24a1.64,1.64,0,0,1-1.43,0L12,22.58l-.05,0L3.45,27.19a1.64,1.64,0,0,1-1.43,0v3.48A.66.66,0,0,0,2.33,31.28Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M22.92,17.7a1,1,0,0,0-1,1v12a.64.64,0,0,1-.32.56.63.63,0,0,1-.63,0l-8.51-4.6-.53-.24L3,31.29a.65.65,0,0,1-.64,0A.66.66,0,0,1,2,30.72V9.48A1.86,1.86,0,0,1,3.88,7.62h8.47a1,1,0,0,0,0-2H3.88A3.88,3.88,0,0,0,0,9.48V30.72a2.67,2.67,0,0,0,3.93,2.35l8-4.35,8,4.35a2.72,2.72,0,0,0,1.27.32,2.67,2.67,0,0,0,2.66-2.67v-12A1,1,0,0,0,22.92,17.7Z"
                fill={props.mainColor || "#37474f"}
            />
            <path d="M22.81,0a8,8,0,1,0,8,8A8,8,0,0,0,22.81,0Zm0,14a6,6,0,1,1,6-6A6,6,0,0,1,22.81,14Z" fill={props.accentColor || "#8b6aac"} />
            <path
                d="M22.73,4.26a.63.63,0,0,0,.48-.19.67.67,0,0,0,.19-.47.7.7,0,0,0-.2-.49.64.64,0,0,0-.49-.21.78.78,0,0,0-.53.2.65.65,0,0,0-.21.49.62.62,0,0,0,.23.48A.78.78,0,0,0,22.73,4.26Z"
                fill={props.accentColor || "#8b6aac"}
            />
            <path
                d="M24.17,11a1.33,1.33,0,0,1-.44-.11.47.47,0,0,1-.23-.21.9.9,0,0,1-.09-.35c0-.15,0-.32,0-.53v-4a.33.33,0,0,0,0-.16.19.19,0,0,0-.19-.09l-.13,0-.22.06a3.18,3.18,0,0,1-.37.06L22,5.75l-.38,0-.3,0a.11.11,0,0,0-.11.06.24.24,0,0,0,0,.13.19.19,0,0,0,0,.12.11.11,0,0,0,.11.07,2,2,0,0,1,.47.1.42.42,0,0,1,.26.23A1.29,1.29,0,0,1,22.2,7c0,.22,0,.5,0,.86v2c0,.19,0,.35,0,.48a.75.75,0,0,1-.09.32.58.58,0,0,1-.27.21,1.78,1.78,0,0,1-.5.11.14.14,0,0,0-.11.07.2.2,0,0,0,0,.13.19.19,0,0,0,0,.12.1.1,0,0,0,.09.06,6.42,6.42,0,0,0,.74-.05,6.57,6.57,0,0,1,.78,0,5.85,5.85,0,0,1,.73,0,5.36,5.36,0,0,0,.59.05.11.11,0,0,0,.11-.06.19.19,0,0,0,0-.12.2.2,0,0,0,0-.13A.14.14,0,0,0,24.17,11Z"
                fill={props.accentColor || "#8b6aac"}
            />
        </svg>
    );
};
