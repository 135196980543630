import {Dispatch} from "redux";
import {appLink} from "@web2/gh_routes";

import {fetch404Redirect, redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {IAgencyAboutData, IOfferSummary, IPlaceholderConfiguration} from "../../app/interfaces/response/offer_list";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {retrieveIDFromSlug} from "../../app/utils/id_from_slug";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {enable301ResponseState, enable302ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferMarketType} from "../../offer/utils/constants_offer";

export type MarketType = "primary_market" | "aftermarket";

export interface IAgency {
    about: string;
    about_data: IAgencyAboutData;
    address: string;
    agency_type: string;
    agent_count: number;
    background_picture: {
        a_bg_768x340: string;
        a_bg_1366x460: string;
        a_bg_1440x460: string;
        a_bg_1920x460: string;
    } | null;
    created_at: string;
    email: string;
    facebook: string;
    hide_seo_description: boolean;
    id: string;
    is_active: boolean;
    lead_form_configuration: IPlaceholderConfiguration;
    link: string;
    linkedin: string;
    logo_picture: {
        a_log_360: string;
        a_log_180: string;
        a_log_100: string;
    } | null;
    market_type: OfferMarketType;
    name: string;
    offer_summary: IOfferSummary;
    overbudget: boolean;
    picture: {
        a_log_360: string;
        a_log_180: string;
        a_log_100: string;
    } | null;
    phone_display: string;
    phone_number: string;
    satisfied_customer_count: number | null;
    slug: string;
    status: number;
    specialization: string;
    type: VendorType.AGENCY;
    updated_at: string;
}

export const fetchAgencyDetailTypes = createRequestActionTypes({view: "Agency", type: "GET", name: "AgencyDetail"});

export const fetchAgencyDetailAtRoute = (services: Partial<IServices>, route: IRouteState<{agencySlug: string}>) => (dispatch: Dispatch) => {
    dispatch({type: fetchAgencyDetailTypes.start});
    const id = retrieveIDFromSlug(route.params.agencySlug);
    const url = apiLink.agencies.agency({})({agencyId: id});
    return getRequest(services, url)
        .then(async (res: IAgency) => {
            dispatch({type: fetchAgencyDetailTypes.success, result: res});
            // check if agency is active
            if (!res.is_active) {
                const newPath = await fetch404Redirect(route.pathname, services);
                dispatch(enable302ResponseState(newPath || appLink.mainPage()));
                return false;
            }
            // validate agency slug
            if (route.params.agencySlug !== res.slug) {
                dispatch(enable301ResponseState(appLink.agency.detail({agencySlug: res.slug})));
                return false;
            }
            return res;
        })
        .catch(
            catch404(async (err) => {
                dispatch({type: fetchAgencyDetailTypes.error, err});
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        );
};
