import {IABTestConfiguration} from "@web2/ab_testing";

export enum OngoingABTests {}

export const ongoingABTests: IABTestConfiguration[] = [
    // {
    //     id: "e241c866-d748-4515-b827-abaa096ce3d8",
    //     expires: new Date(2024, 11, 8),
    //     name: OngoingABTests.TEST_IMPLEMENTATION,
    //     path: [appPath.offer.detail.base],
    //     variants: 1,
    //     withSSR: false
    // }
];
