import {IStatsLocationDetailed} from "./AveragePricesRent";
import {ListItemRooms} from "./ListItemRooms";

export const CheapestFlats = (props: {
    isSell?: boolean;
    dealTypePhrase: string;
    apartmentRentStats: IStatsLocationDetailed | undefined;
    minRentPricesPerRoom: Record<number, number | null | undefined>;
    locationDeclination: string | undefined;
    rentDealPhraseCustomVariant: string;
}) => {
    const {minRentPricesPerRoom, locationDeclination, rentDealPhraseCustomVariant, apartmentRentStats} = props;

    const commonString = `${rentDealPhraseCustomVariant} ${locationDeclination} znajdziesz w cenie`;
    const numberOfRoomsToGenerate = [2, 3, 4];

    return (
        <ul>
            {apartmentRentStats && apartmentRentStats?.one_room.count > 0 && (
                <ListItemRooms rooms={1} commonString={commonString} price={minRentPricesPerRoom[1]} apartmentRentStats={apartmentRentStats} />
            )}
            {numberOfRoomsToGenerate.map((room, index) => {
                const minPricePerRoom = minRentPricesPerRoom[room];
                const notZero = typeof minPricePerRoom === "number" && minPricePerRoom > 0;

                return (
                    notZero && (
                        <ListItemRooms key={index} rooms={room} commonString={commonString} price={minRentPricesPerRoom[room]} flatNameVariant={"mieszkania"} />
                    )
                );
            })}
        </ul>
    );
};
