import {appLink, parseDeveloperSlugToObject, parseInvestmentSlugToObject} from "@web2/gh_routes";

import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {OfferType} from "../../offer/utils/constants_offer";
import {ISearchSuggestions} from "../actions/fetch_search_all_action";

export const createDynamicTabUrl = (
    searchSuggestions: ISearchSuggestions,
    location: string | undefined,
    subLocation: string | undefined,
    subSubLocation: string | undefined
) => {
    const {id, suggest_type, slug} = searchSuggestions;
    let url = " ";

    switch (suggest_type) {
        case "location":
            url = getOfferListUrl({
                location,
                subLocation,
                subSubLocation,
                offerType: OfferType.apartment,
                type: "na-sprzedaz"
            });
            break;
        case "investment":
            url = appLink.investment.detail(parseInvestmentSlugToObject(slug));
            break;
        case "developer":
            url = appLink.developer.detail.base(parseDeveloperSlugToObject(slug));
            break;
        case "agency":
            url = appLink.agency.detail({agencySlug: id});
            break;
        case "agent":
            url = appLink.agent.detail({agentSlug: id});
            break;
    }

    return url;
};
