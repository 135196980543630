import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ApplicationFormFields} from "@web2/form";
import {handleFormikPost, IFormikSubmitFn} from "@web2/formik_utils";
import {useCallbackOnIntersection} from "@web2/react_utils";

import {IInvestmentSellStatusType} from "../../app/interfaces/response/investment";
import {IApplicationPicture, IOfferDetailFormPlaceholder} from "../../app/interfaces/response/offer_detail";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {toJsonValues} from "../../app/utils/form/to_json_values";
import {saveUserDataToLS} from "../../offer/detail/actions/user_saved_data_ls";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferDealType, OfferMarketType, PropertyType} from "../../offer/utils/constants_offer";
import {AlgolyticsSourceSection} from "../../tracking/algolytics/interaction/application_sent_hit";
import {gtmAddWithOffer, gtmAddWithoutOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_add";
import {gtmCheckoutWithOffer} from "../../tracking/google_tag_manager/ecommerce_events/gtm_checkout";
import {GtmContactType} from "../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {applicationV2Post, IApplicationFields, IApplicationResponse} from "../actions/application_v2_post";
import {useApplicationInitialValues} from "../use_application_initial_values";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {generateApplicationText} from "../utils/generate_application_text";
import {showFormPlaceholder} from "../utils/show_form_placeholder";
import {TrackApplicationPayload, trackApplicationPost} from "../utils/track_application";
import {ApplicationForm} from "./ApplicationForm";
import {ApplicationPlaceholder} from "./ApplicationPlaceholder";

interface IProps extends IApplicationV2DataOwnProps {
    isOfferListModalOffer?: boolean;
    horizontal?: boolean;
    onSuccess?: (res: IApplicationResponse) => void;
    formTitle?: string;
    applicationSource: ApplicationSource | null;
    applicationSourceSection: ApplicationSourceSection;
    algolyticsSourceSection?: AlgolyticsSourceSection;
    gtmSource: GtmSource;
    resetAfterSubmit?: boolean;
    openModal?: () => void;
    setApplicationUserData?: (userData: UserData) => void;
    setApplicationTrackingPayload?: (payload: TrackApplicationPayload) => void;
}

export interface IApplicationV2DataOwnProps {
    offer?: IApplicationOffer;
    investment?: IApplicationInvestment | null;
}

export interface IApplicationOfferAgent {
    id: string;
    name: string;
    last_name?: string;
    phone_number?: string;
    picture: {
        a_img_100x100: string;
    } | null;
}

export interface IApplicationOfferAgency {
    id: string;
    name: string;
    type: VendorType;
    logo_picture?: {
        a_log_100: string;
    } | null;
    slug: string;
    status: number;
}

export interface IApplicationOfferProperty {
    distance_from_region?: {distance: number} | {};
    floor?: number | null;
    group_lot_info?: {shape?: string | null};
    location?: {
        path?: {name: string; slug: string; type: string}[];
        short_name: string;
    } | null;
    lot_type?: string | null;
    room_number?: number | null;
    rooms?: number | null;
    signature?: string | null;
    size: number | null;
    type: PropertyType;
}

export interface IApplicationOfferInvestment {
    id: string;
    name: string;
    developer?: {
        id?: string;
        name: string;
        logo_picture: {
            a_log_100: string;
        } | null;
        slug: string;
    };
    pictures?:
        | {
              o_img_500: string;
          }[]
        | null;
    phone_clip?: string | null;
    lead_form_configuration?: IOfferDetailFormPlaceholder;
    location?: {
        short_name: string;
    };

    investment_summary?: {
        offer_count: number;
    };
    is_active: boolean;
    limited_presentation: boolean;
    offer_count?: number;
    sell_status: IInvestmentSellStatusType;
    slug: string;
}

export interface IApplicationOffer {
    agency?: IApplicationOfferAgency | null;
    agent?: IApplicationOfferAgent | null;
    id: string;
    name: string;
    slug: string;
    deal_type: OfferDealType;
    offer_type?: string[];
    market_type: OfferMarketType;
    overbudget: boolean;
    pictures?: IApplicationPicture[] | null;
    property: IApplicationOfferProperty;
    investment?: IApplicationOfferInvestment | null;
    is_private: boolean;
}

interface IApplicationInvestment {
    developer: {
        id: string;
        name: string;
        logo_picture: {
            a_log_100: string;
        } | null;
        slug: string;
        type: VendorType;
    };
    id: string;
    lead_form_configuration?: IOfferDetailFormPlaceholder;
    location?: {
        short_name: string;
    };
    name: string;
    main_image: {
        o_img_500: string;
    };
    phone_clip?: string | null;
}

export const emptyApplicationValues = {
    full_name: "",
    email: "",
    phone: "",
    new_offers_consent: false,
    financing_services: false,
    text: ""
};

export interface UserData {
    email: string;
    full_name: string;
    phone: string;
    text: string;
    financing_services: boolean;
    new_offers_consent: boolean;
    source: string | ApplicationSource | null;
    source_section: ApplicationSourceSection;
    algolyticsSourceSection: AlgolyticsSourceSection | null;
}

const getApplicationText = (props: {offer?: {name: string}; investment?: {name: string} | null}) => {
    switch (true) {
        case !!props.offer:
            return generateApplicationText(null);
        case !!props.investment?.name:
            return generateApplicationText({investment: props.investment?.name});
        default:
            return "";
    }
};

export const Application = (props: IProps) => {
    const viewType = useSelector((store: IStore) => store.viewType.current);
    const generatedApplicationText = getApplicationText(props);

    const [wasInteractionHit, setWasInteractionHit] = useState(false);
    const {applicationInitialValues, initApplicationForm, clearForm} = useApplicationInitialValues(generatedApplicationText);
    const [applicationError, setApplicationError] = useState<string | null>(null);
    const [isApplicationSent, setIsApplicationSent] = useState<boolean>(false);

    const formRef = useRef<HTMLDivElement | null>(null);
    useCallbackOnIntersection({
        ref: formRef,
        callback: () => {
            if (props.offer) {
                gtmAddWithOffer({
                    offer: props.offer,
                    contactType: GtmContactType.FORM,
                    gtmSource: props.gtmSource,
                    viewType
                });
            }
            if (props.investment) {
                gtmAddWithoutOffer({
                    contactType: GtmContactType.FORM,
                    gtmSource: props.gtmSource,
                    viewType,
                    investment: props.investment
                });
            }
        },
        deps: [viewType],
        triggerOnce: true
    });

    const gtmCheckout = () => {
        if (props.offer) {
            gtmCheckoutWithOffer({
                offer: props.offer,
                contactType: GtmContactType.FORM,
                gtmSource: props.gtmSource,
                viewType: viewType
            });
            setWasInteractionHit(true);
        }
    };

    useEffect(() => {
        initApplicationForm(gtmCheckout);
    }, []);
    /**
     * Callbacks
     */

    const onUpdate = () => {
        if (props.offer && !wasInteractionHit) {
            gtmCheckout();
        }
    };

    const onSubmit: IFormikSubmitFn<typeof emptyApplicationValues> = (formValues, formikHelpers) => {
        const {offer, investment} = props;

        if (!formValues) {
            throw new Error("No data to send");
        }
        const jsonValues = toJsonValues(ApplicationFormFields, formValues);

        const additionalData = {
            ...(offer && {
                offer: offer.id,
                ...(offer.agent && {agent: offer.agent.id}),
                ...(offer.investment && {investment: offer.investment.id}),
                ...(offer.agency && {agency: offer.agency.id})
            }),
            ...(investment && {
                agency: investment.developer.id,
                investment: investment.id
            }),
            source: props.applicationSource,
            source_section: props.applicationSourceSection
        };
        handleFormikPost(applicationV2Post({...jsonValues, ...additionalData}), formikHelpers, {
            onSuccess: (res) => {
                saveUserDataToLS({
                    user_name: jsonValues["full_name"],
                    user_email: jsonValues["email"],
                    user_phone: jsonValues["phone"]
                });
                props.setApplicationUserData &&
                    props.setApplicationUserData({
                        full_name: jsonValues["full_name"],
                        email: jsonValues["email"],
                        phone: jsonValues["phone"],
                        text: jsonValues["text"],
                        new_offers_consent: jsonValues["new_offers_consent"],
                        financing_services: jsonValues["financing_services"],
                        source: props.applicationSource,
                        source_section: props.applicationSourceSection,
                        algolyticsSourceSection: props.algolyticsSourceSection ? props.algolyticsSourceSection : null
                    });

                const applicationTrackingPayload = {
                    applicationFields: jsonValues as IApplicationFields,
                    formTitle: props.formTitle,
                    isModalOfferOnOfferList: props.isOfferListModalOffer,
                    source: props.applicationSource,
                    source_section: props.applicationSourceSection,
                    algolyticsSourceSection: props.algolyticsSourceSection || null,
                    offer: props.offer,
                    gtmInquiryData: {
                        viewType: viewType,
                        contactType: GtmContactType.FORM,
                        gtmSource: props.gtmSource
                    }
                };

                trackApplicationPost(applicationTrackingPayload);
                // save application tracking data for further use in multilead
                props.setApplicationTrackingPayload?.(applicationTrackingPayload);

                props.onSuccess?.(res as IApplicationResponse);
                setIsApplicationSent(true);
                if (props.resetAfterSubmit) {
                    clearForm();
                }
                props.openModal ? props.openModal() : null;
            },
            onNonFieldError: (error) => setApplicationError(error)
        });
    };

    /**
     * Render
     */

    // TODO: showFormPlaceholder should also check developer, for now we dont have this data, add developer as a second argument
    const investmentConfig = props.offer ? props.offer.investment : props.investment || null;
    const [showPlaceholder, placeholderConfig] = showFormPlaceholder(investmentConfig);

    const getOfferPropertyType = (offerPropertyType: PropertyType | undefined) => {
        switch (offerPropertyType) {
            case PropertyType.APARTMENT:
                return "to mieszkanie";
            case PropertyType.LOT:
                return "tę działkę";
            case PropertyType.HOUSE:
                return "ten dom";
            default:
                return "";
        }
    };

    const renderApplicationForm = () => {
        return showPlaceholder ? (
            <ApplicationPlaceholder config={placeholderConfig} />
        ) : (
            <ApplicationForm
                initialValues={applicationInitialValues}
                onUpdate={onUpdate}
                onSubmit={onSubmit}
                formError={applicationError}
                onFormErrorClick={() => setApplicationError(null)}
                showSuccess={isApplicationSent}
                financialServicesPropertyType={getOfferPropertyType(props.offer?.property.type)}
            />
        );
    };

    return <div ref={formRef}>{renderApplicationForm()}</div>;
};
