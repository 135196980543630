import {Dispatch} from "redux";
import {IArticleDetailAndListParams} from "@web2/gh_routes";
import {safelyParsePage} from "@web2/string_utils";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {IArticleCategory} from "../../app/interfaces/response/article_category_list";
import {IArticleListResponse} from "../../app/interfaces/response/article_list";
import {apiLink} from "../../app/routes/api_link";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch400} from "../../app/utils/request_response_utils/response_error";
import {ArticleCategorySlug} from "../utils/category_name_utils";

const defaultParams = {
    exclude_districts: "True",
    page_size: 7
};

export const fetchArticleListActionTypes = createRequestActionTypes({name: "articleListArticles", type: "GET", view: "Blog"});

export const fetchArticleListAtRoute = (services: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleListActionTypes.start});
    const page = safelyParsePage(route.query.page);
    const url = appendQueryString(apiLink.articles.base({})(null), {...defaultParams, page});

    return getRequest({}, url).then((response: IArticleListResponse) => {
        const result = {
            page: response.page,
            page_size: response.page_size,
            count: response.count,
            articles: response.results
        };
        dispatch({type: fetchArticleListActionTypes.success, result});
        return result;
    });
};

export const fetchArticleListInCategoryAtRoute =
    (
        services: Partial<IServices>,
        route: IRouteState<IArticleDetailAndListParams>,
        prevRoute: IPrevRouteState | null,
        prevActionResult: {categories: IArticleCategory[]}
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchArticleListActionTypes.start});
        const category = prevActionResult.categories.find((categoryData) => {
            return categoryData.slug === route.params.slug;
        });

        if (!category) {
            // we didn't recognize this category slug, show 404
            await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
            return false;
        }

        const url = appendQueryString(apiLink.articles.base({})(null), {
            page: route.query.page,
            page_size: route.params.slug === ArticleCategorySlug.DISTRICTS_DESCRIPTION ? false : 22,
            category: category.id,
            ...(category.id === 3 ? {sort: "title"} : {})
        });

        return getRequest({}, url)
            .then((response: IArticleListResponse) => {
                const result = {
                    page: response.page,
                    page_size: response.page_size,
                    count: response.count,
                    articles: response.results
                };

                dispatch({type: fetchArticleListActionTypes.success, result});
                return result;
            })
            .catch(
                catch400(async (err) => {
                    // bad request - can occur when someone removes article category, but we are still fetching that ID from api
                    notifyBugsnag({message: `Error 400 fetching category id: ${category.id}`}, err.message);
                    console.error(`Error 400 fetching category id: ${category.id}`, "; route: ", route, "; API error.message: ", err.message);
                    await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                    return false;
                })
            );
    };
