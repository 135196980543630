import {GeoJSON} from "geojson";
import {assign, each, has, isEmpty, map, reduce} from "lodash";

import {IMapInternalData} from "./GoogleMap";

type gMap = google.maps.Map;
type gMapMarker = google.maps.Marker;
type gMapMarkerOpts = google.maps.MarkerOptions;
type gMapInfoWindowOpts = google.maps.InfoWindowOptions;

export interface MarkerOptions {
    /**
     * The offset from the marker's position to the tip of an InfoWindow
     * that has been opened with the marker as anchor.
     */
    anchorPoint?: {height: number; width: number};
    clickable?: boolean;
    cursor?: string;
    draggable?: boolean;
    icon?: string | google.maps.Icon | google.maps.Symbol;
    label?: string | google.maps.MarkerLabel;
    opacity?: number;
    place?: google.maps.Place;
    shape?: google.maps.MarkerShape;
    scaledSize?: {height: number; width: number};
    title?: string;
    visible?: boolean;
    zIndex?: number;
}

export interface PolygonOptions {
    clickable?: boolean;
    draggable?: boolean;
    editable?: boolean;
    /** The fill color. All CSS3 colors are supported except for extended named colors. */
    fillColor?: string;
    fillOpacity?: number;
    /**
     * When true, edges of the polygon are interpreted as geodesic and will follow
     * the curvature of the Earth. When false, edges of the polygon are rendered as
     * straight lines in screen space. Note that the shape of a geodesic polygon may
     * appear to change when dragged, as the dimensions are maintained relative to
     * the surface of the earth. Defaults to false.
     */
    geodesic?: boolean;
    strokeColor?: string;
    strokeOpacity?: number;
    strokePosition?: google.maps.StrokePosition;
    strokeWeight?: number;
    visible?: boolean;
    zIndex?: number;
}

export interface PolygonDefinition {
    coords: GeoJSON.Position[][];
    infoWindow?: gMapInfoWindowOpts;
    options?: PolygonOptions;
}

export interface PolygonGroupDefinition {
    list: PolygonDefinition[];
    options?: PolygonOptions;
}

export interface MarkerDefinition {
    id?: number | string;
    coords: GeoJSON.Position;
    icon?: string | {url: string; scaledSize?: any};
    onOpen?: (marker: gMapMarker) => void;
    infoWindow?: gMapInfoWindowOpts;
    infoWindowOfferBox?: gMapInfoWindowOpts;
    options?: MarkerOptions;
}

export interface MarkerGroupDefinition {
    list: MarkerDefinition[];
    options?: MarkerOptions;
}

/**
 * Removes all markers rendered on the map.
 * @param markers {Dictionary<gMapMarker[]> markers renderes on the map, divided into groups.
 */
export function removeAllMarkers(markers: Record<string, gMapMarker[]>): void {
    for (const groupName in markers) {
        if (has(markers, groupName)) {
            removeMarkerGroup(markers, groupName);
        }
    }
}

/**
 * Removes all markers rendered on the map, belonging to particular group.
 * @param markers {Dictionary<gMapMarker[]> markers renderes on the map, divided into groups.
 * @param groupName {string} name of the group of markers to remove
 */
export function removeMarkerGroup(markers: Record<string, gMapMarker[]>, groupName: string): void {
    if (isEmpty(markers[groupName])) {
        return;
    }

    for (const marker of markers[groupName]) {
        marker.setMap(null);
    }

    markers[groupName] = []; // necessary to remove all references to markers
}

export function updateMarkers(mapData: IMapInternalData, groupsDef: Record<string, MarkerGroupDefinition>): Record<string, gMapMarker[]> {
    removeAllMarkers(mapData.markers);

    const iteratee = (acc: Record<string, gMapMarker[]>, groupDef: MarkerGroupDefinition, groupName: string) => {
        acc[groupName] = updateMarkerGroup(mapData, groupName, groupDef);
        return acc;
    };

    return reduce(groupsDef, iteratee, {} as Record<string, gMapMarker[]>);
}

export function updateMarkerGroup(mapData: IMapInternalData, groupName: string, groupDef: MarkerGroupDefinition): gMapMarker[] {
    return map(groupDef.list, (markerDef: MarkerDefinition) => addMarker(mapData, groupName, groupDef, markerDef));
}

export function addMarker(mapData: IMapInternalData, groupName: string, groupDef: MarkerGroupDefinition, markerDef: MarkerDefinition): gMapMarker {
    const markerOptions = buildMarkerOptions(mapData, groupName, groupDef, markerDef);
    const marker = new google.maps.Marker(markerOptions);
    if (markerDef.infoWindow) {
        addMarkerInfoWindow(mapData, marker, markerDef);
    }
    if (markerDef.infoWindowOfferBox) {
        addMarkerInfoWindowOfferBox(mapData, marker, markerDef);
    }
    if (markerDef.onOpen) {
        bindInfoWindowEvents(marker, markerDef.onOpen);
    }

    return marker;
}

export function addMarkerInfoWindow(mapData: IMapInternalData, marker: gMapMarker, markerDef: MarkerDefinition): void {
    if (markerDef.infoWindow && markerDef.infoWindow.content) {
        const infoWindow = new google.maps.InfoWindow(markerDef.infoWindow);
        mapData.refInfoWindows.push(infoWindow);

        // infoWindow events
        marker.addListener("mouseover", () => {
            infoWindow.open(mapData.map, marker);
        });
        marker.addListener("mouseout", () => {
            infoWindow.close();
        });
    }
}

function addMarkerInfoWindowOfferBox(mapData: IMapInternalData, marker: gMapMarker, markerDef: MarkerDefinition): void {
    if (markerDef.infoWindowOfferBox && markerDef.infoWindowOfferBox.content) {
        const infoWindow = new google.maps.InfoWindow(markerDef.infoWindowOfferBox);
        mapData.refInfoWindows.push(infoWindow);

        // infoWindow events
        marker.addListener("click", () => {
            each(mapData.refInfoWindows, (infoWindow) => infoWindow.close());
            infoWindow.open(mapData.map, marker);
        });
    }
}

export function buildMarkerOptions(mapData: IMapInternalData, groupName: string, groupDef: MarkerGroupDefinition, markerDef: MarkerDefinition): gMapMarkerOpts {
    const defaultOptions = getDefaultMarkerOptions(mapData);
    const groupOptions = getGroupMarkerOptions(groupDef.options);
    const markerOptions = assign({}, defaultOptions, groupOptions, markerDef.options, {
        position: {lat: markerDef.coords[1], lng: markerDef.coords[0]},
        icon:
            {
                url: markerDef.icon,
                ...(markerDef.options &&
                    markerDef.options.scaledSize && {
                        scaledSize: new google.maps.Size(markerDef.options.scaledSize.width, markerDef.options.scaledSize.height)
                    }),
                ...(markerDef.options &&
                    markerDef.options.anchorPoint && {anchor: new google.maps.Point(markerDef.options.anchorPoint.width, markerDef.options.anchorPoint.height)})
            } || ""
    }) as gMapMarkerOpts;
    return markerOptions;
}

export function getDefaultMarkerOptions(mapData: IMapInternalData): {map: gMap | undefined} {
    const defaultOptions = {
        map: mapData.map
    };

    return defaultOptions;
}

export function getGroupMarkerOptions(groupOptions: MarkerOptions | undefined): MarkerOptions | {} {
    return groupOptions || {};
}

function bindInfoWindowEvents(marker: gMapMarker, onOpen?: (marker: gMapMarker) => void) {
    onOpen && marker.addListener("click", () => onOpen(marker));
}

export function calculateRefMarkersMap(
    groupsDef: Record<string, MarkerGroupDefinition>,
    markerGroups: Record<string, gMapMarker[]>
): Record<string, gMapMarker> {
    return reduce(
        groupsDef,
        (acc, groupDef, groupName) => {
            // get markers-ref-map for single group definition
            const markerGroup = markerGroups[groupName];
            const refMarkersMap = reduce(groupDef.list, (acc, markerDef, idx) => (markerDef.id == null ? acc : {...acc, [markerDef.id]: markerGroup[idx]}), {});

            return {...acc, ...refMarkersMap};
        },
        {}
    );
}
