import {capitalize} from "lodash";
import {numberWithDelimiter} from "@web2/string_utils";

import {sizeFormat} from "../../../app/utils/number";
import {OfferTypeSinglePL} from "../../utils/constants_offer";
import {pluralizedRoomsNumber, translateOfferType} from "../../utils/utils";

export interface IInfoWindowData {
    coordinates_accurate?: boolean;
    offer_type: string[] | string;
    price?: {
        total: number | null;
        currency: string;
    };
    size?: number | null;
    rooms?: string | number;
    investment?: {
        name: string;
        offer_count?: number;
    } | null;
}

// warning: this is not a react component, it returns a string literal
export const renderDesktopOfferTooltip = (marker: IInfoWindowData): string => {
    const {offer_type: _offer_type, price, investment} = marker;

    const investmentLine = investment ? `${investment.name} - ${investment.offer_count}` : "";

    const offerType = translateOfferType(Array.isArray(_offer_type) ? _offer_type[1] : _offer_type, OfferTypeSinglePL);
    const area = marker.size ? ` ${sizeFormat(marker.size)}&nbsp;m<sup>2</sup>` : "";
    const roomsAsString = typeof marker.rooms === "number" ? marker.rooms.toString() : marker.rooms;
    const rooms = roomsAsString ? `, ${pluralizedRoomsNumber(parseInt(roomsAsString))}` : "";
    const firstLine = capitalize(offerType) + area + rooms;
    const areCoordinatesAccurate = marker.coordinates_accurate !== false;
    const secondLine = price?.total ? `${numberWithDelimiter(price.total)} ${price?.currency === "pln" ? "zł" : price?.currency.toUpperCase()}` : "";

    return `
            <div class="map-tooltip-holder">
                <div class="map-tooltip">
                    ${investment ? `<div class="map-tooltip--tag">${investmentLine}</div>` : ""}
                    <span class="map-tooltip--first-line">${firstLine}</span>
                    ${secondLine ? `<span class="map-tooltip--second-line">${secondLine}</span>` : ""}
                    ${areCoordinatesAccurate ? "" : `<span class="map-tooltip--inaccurate-location">Lokalizacja przybliżona</span>`}
                </div>
            </div>
        `;
};
