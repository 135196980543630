import {isArray, reduce} from "lodash";

import {FormFieldType} from "../form_interfaces";

const strEmpty = (value: any, defaultValue: any = "") => (value == null ? defaultValue : value);

export const fromJsonToFormValues = (formFields: Record<string, FormFieldType>, jsonValues: Record<string, any>) => {
    return reduce(
        formFields,
        (acc: any, fieldType: FormFieldType, field: string) => {
            switch (fieldType) {
                case FormFieldType.Checkbox:
                    return {...acc, [field]: strEmpty(jsonValues[field]) || undefined};
                case FormFieldType.Input:
                case FormFieldType.Radio:
                case FormFieldType.Select:
                case FormFieldType.Textarea:
                    return {...acc, [field]: jsonValues[field] ?? ""};
                case FormFieldType.SelectRange:
                case FormFieldType.InputRange:
                case FormFieldType.SelectRangeNumber:
                    return {
                        ...acc,
                        [field]: {
                            lower: jsonValues[field]?.lower ?? "",
                            upper: jsonValues[field]?.upper ?? ""
                        }
                    };
                case FormFieldType.SearchOfferType:
                    if (jsonValues[field] == null) {
                        return {...acc, [field]: []};
                    }
                    const arrayValues = isArray(jsonValues[field]) ? jsonValues[field] : [jsonValues[field]];
                    return {...acc, [field]: arrayValues};
                case FormFieldType.FeatureList:
                    if (jsonValues[field] == null) {
                        return {...acc, [field]: []};
                    }
                    const arrayValues2 = isArray(jsonValues[field]) ? jsonValues[field] : [jsonValues[field]];
                    return {...acc, [field]: arrayValues2};
                case FormFieldType.PhoneField:
                    const arr = strEmpty(jsonValues[field]).split(" ");
                    const code = parseInt(arr[0], 10);
                    const num = arr[1];
                    return {
                        ...acc,
                        [field]: {
                            code: isFinite(code) ? code : 48,
                            number: !!num ? num : ""
                        }
                    };
                case FormFieldType.Rooms:
                    return {...acc, [field]: strEmpty(jsonValues[field], [])};
                default:
                    throw new Error(`fromJsonValues: not implemented for ${field}, formFieldType is ${fieldType}`);
            }
        },
        {}
    );
};
