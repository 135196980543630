import {Dispatch} from "redux";
import {appPath} from "@web2/gh_routes";

import {IArticleListArticle} from "../../app/interfaces/response/article_list";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateBlogMetaData =
    (
        _1: Partial<IServices>,
        route: IRouteState<{}>,
        prevRoute: IPrevRouteState | null,
        prevActionResult: {page: number; count: number; page_size: number; articles: IArticleListArticle[]}
    ) =>
    (dispatch: Dispatch) => {
        const baseUrl = `${ghCommonUrl}${appPath.article.base}`;
        const baseUrlWithPage = (page: number) => `${baseUrl}?page=${page}`;

        const page = parseInt(route.query.page, 10) || 1;
        const nextPage = page + 1;
        const prevPage = page - 1;
        const pageCount = Math.ceil(prevActionResult.count / prevActionResult.page_size);
        const isFirstPage = page === 1;
        const currentUrl = appendQueryString(appendQueryString(baseUrl, {}), {page});

        const relNext = nextPage <= pageCount ? baseUrlWithPage(nextPage) : undefined;
        const relPrev = (() => {
            if (page === 1) {
                return;
            }
            if (page > 2) {
                return baseUrlWithPage(prevPage);
            }
            return baseUrl;
        })();
        const canonical = page === 1 ? baseUrl : currentUrl;
        const description = `Informacje ze świata nieruchomości. Trendy aranżacyjne i porady. Bądź na bieżąco i sprawdź blog Gethome${
            isFirstPage ? "!" : ` - strona ${page}!`
        } ✅`;
        const title = `Gethome Blog - Porady | Wiadomości | Inspiracje${isFirstPage ? "" : ` - strona ${page}.`}`;
        const robots = "index, follow";

        dispatch(
            updateMetaData(title, description, route, {
                canonical,
                relNext,
                relPrev,
                robots,
                hideSuffix: true
            })
        );
        return Promise.resolve();
    };
