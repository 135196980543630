import * as React from "react";
import {css} from "@linaria/core";
import {Button} from "@web2/button";

import {GreenDarkButton} from "../../../app/atoms/Buttons";
import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IProps {
    onCancel: () => void;
    onSave: () => void;
}

export const FilterFooter: React.FunctionComponent<IProps> = (props) => {
    return (
        <div className={filterFooter}>
            <Button variant="link" onClick={props.onCancel} size="sm" className="linkTextColor">
                Anuluj
            </Button>

            <GreenDarkButton variant="success" type="submit" onClick={props.onSave} size="sm">
                Wybierz
            </GreenDarkButton>
        </div>
    );
};

const filterFooter = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    .linkTextColor {
        color: ${getThemeVariable("colors-text_color")};

        &:is(:hover, :focus, :active, &:focus:active) {
            color: ${getThemeVariable("colors-text_color")};
        }
    }
`;
