import {Dispatch} from "redux";
import {appLink, IAuthorDetailParams} from "@web2/gh_routes";
import {safelyParsePage} from "@web2/string_utils";

import {IAuthorDetailAuthor} from "../../app/interfaces/response/author_detail";
import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAuthorDetailMetaData =
    (_1: Partial<IServices>, route: IRouteState<IAuthorDetailParams>, prevRoute: IPrevRouteState | null, prevActionResult: {author: IAuthorDetailAuthor}) =>
    (dispatch: Dispatch) => {
        const page = safelyParsePage(route.query.page);
        const isFirstPage = page === 1;
        const title = `${prevActionResult.author.name} - blog GetHome.pl${isFirstPage ? "" : ` - strona ${page}`}`;
        const description = `${
            prevActionResult.author.name
        } - wszystkie artykuły na blogu GetHome.pl. Czytaj eksperckie komentarze, analizy oraz porady naszych autorów${
            isFirstPage ? "." : ` - strona ${page}.`
        }`;
        const robots = isFirstPage ? "index, follow" : "noindex";
        const canonical = `${ghCommonUrl}${appLink.article.author.detail({authorSlug: route.params.authorSlug})}`;

        dispatch(updateMetaData(title, description, route, {robots, canonical, hideSuffix: true}));

        return Promise.resolve(true);
    };
