import * as React from "react";
import classNames from "classnames";

import {IIcon} from "../../IIcon";
import {SvgIcon} from "./SvgIcon";

export const EyeCrossedIcon = (props: IIcon) => {
    const {fillColor, size, wrapperColor, wrapperSize} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor} wrapperColor={wrapperColor} wrapperSize={wrapperSize}>
            <path d="M2.57 6.89992C2.34 6.75992 2.12 6.59992 1.89 6.42992C1.13 5.81992 0.65 5.21992 0.61 5.16992C0.63 5.14992 1.1 4.53992 1.89 3.90992C2.12 3.72992 2.34 3.56992 2.57 3.42992C2.22 3.91992 2.01 4.51992 2.01 5.16992C2.01 5.81992 2.22 6.40992 2.57 6.89992L2.91 6.55992C2.64 6.16992 2.49 5.67992 2.49 5.16992C2.49 3.87992 3.46 2.80992 4.71 2.65992C4.9 2.64992 5.1 2.64992 5.29 2.65992C5.69 2.70992 6.07 2.84992 6.39 3.07992L6.88 2.58992C6.37 2.36992 5.85 2.22992 5.34 2.18992C5.23 2.16992 5.12 2.16992 5 2.16992C4.88 2.16992 4.77 2.16992 4.65 2.18992C4.14 2.22992 3.62 2.36992 3.09 2.59992C2.59 2.82992 2.09 3.13992 1.59 3.52992C0.74 4.20992 0.23 4.86992 0.23 4.86992L0 5.16992L0.22 5.44992C0.22 5.44992 0.74 6.11992 1.59 6.79992C1.8 6.95992 2.01 7.10992 2.22 7.24992L2.57 6.89992V6.89992Z" />
            <path d="M9.76982 4.87C9.71982 4.81 9.21982 4.18 8.39982 3.53C8.11982 3.31 7.83982 3.12 7.55982 2.96L8.16982 2.35L7.81982 2L1.81982 7.98L2.16982 8.33L2.88982 7.61C2.95982 7.64 3.01982 7.68 3.08982 7.72C3.73982 8.01 4.37982 8.16 4.99982 8.16C5.61982 8.16 6.25982 8.01 6.90982 7.72C7.40982 7.49 7.90982 7.18 8.39982 6.79C9.25982 6.11 9.76982 5.45 9.76982 5.45L9.99982 5.16L9.76982 4.86V4.87ZM5.28982 7.67C5.09982 7.68 4.89982 7.68 4.69982 7.67C4.20982 7.61 3.76982 7.4 3.40982 7.1L4.46982 6.04C4.62982 6.13 4.79982 6.2 4.98982 6.2C5.55982 6.2 6.01982 5.74 6.01982 5.17C6.01982 4.97 5.94982 4.8 5.85982 4.65L6.91982 3.59C7.27982 4.02 7.48982 4.57 7.48982 5.17C7.48982 6.45 6.52982 7.52 5.27982 7.67H5.28982ZM8.09982 6.43C7.87982 6.6 7.65982 6.76 7.42982 6.9C7.77982 6.41 7.98982 5.81 7.98982 5.17C7.98982 4.53 7.77982 3.92 7.42982 3.43C7.65982 3.57 7.87982 3.73 8.09982 3.91C8.89982 4.54 9.36982 5.15 9.38982 5.17C9.36982 5.18 8.89982 5.8 8.09982 6.43V6.43Z" />
        </SvgIcon>
    );
};
