import {ILocationStatistics} from "../../../../../app/interfaces/response/location_statistics";
import {dealTypePL, flatPluralize, housePluralize, OfferDealType, OfferType, OfferTypePL} from "../../../../utils/constants_offer";
import {firstLetterUppercase} from "../../../../utils/investment_utils";
import {IGenericSeoDescription} from "../../interfaces/interfaces_generic_listings";
import {AfterMarketCity} from "./AfterMarketCity";
import {CheapFlats} from "./CheapFlats";
import {FindAndLive} from "./FindAndLive";
import {Heading} from "./Heading";
import {SellPrices} from "./SellPrices";
import {StatisticsByFeatures} from "./StatisticsByFeatures";
import {StatisticsByRooms} from "./StatisticsByRooms";
import {StatisticsCheapestPartOne} from "./StatisticsCheapestPartOne";
import {StatisticsCheapestPartTwo} from "./StatisticsCheapestPartTwo";
import {StatisticsTotals} from "./StatisticsTotals";
const primaryOffersCheck = (statistics: ILocationStatistics | {} | null): boolean => {
    if (statistics && "apartment" in statistics) {
        return (statistics.apartment?.sell?.primary_market?.count ?? 0) > 0;
    }
    return false;
};

interface IProps extends IGenericSeoDescription {}

export const DefaultGenericContent = (props: IProps) => {
    const {dealType, propertyType, location, statistics, listingType, locationObject} = props;

    const dealTypePhrase = dealTypePL(dealType);
    const isSell = dealType === OfferDealType.SELL;
    const locationDeclinationWhere = location?.name_declension_where || "";
    const locationDeclinationWhat = location?.name_declension_what;
    const city = locationObject.CITY?.name;
    const town = locationObject.TOWN?.name;
    const locationName = city || town || "";
    const locationSlug = location?.slug;
    const locationType = location?.location_type;
    const hasPrimaryOffers = primaryOffersCheck(statistics.statisticsLocation);
    const headingOneContentApartment = () => {
        switch (isSell) {
            case true:
                return `${firstLetterUppercase(OfferType.apartment)} ${locationName} - oferty`;
            case false:
                return `Wynajem ${OfferTypePL.apartment} ${(locationObject.CITY || locationObject.TOWN) && locationName}`;
        }
    };
    const statsLocationApartmentSell = statistics.statisticsLocation?.apartment.sell;
    const statsLocationApartmentSellAftermarket = statistics.statisticsLocation?.apartment.sell.aftermarket;
    const statsLocationApartmentSellPrimaryMarket = statistics.statisticsLocation?.apartment.sell.primary_market;
    const hasPrimaryMarket = statsLocationApartmentSellPrimaryMarket && statsLocationApartmentSellPrimaryMarket.count > 0;
    const hasAfterMarket = statsLocationApartmentSellAftermarket && statsLocationApartmentSellAftermarket.count > 0;

    const headingTwoContent = `${propertyType === OfferType.apartment ? firstLetterUppercase(flatPluralize(1)) : firstLetterUppercase(housePluralize(1))} ${
        locationName ? ` ${locationName}` : null
    } wynajem - ceny`;

    return (
        <>
            <Heading content={headingOneContentApartment()} />
            <StatisticsTotals
                statistics={statistics}
                propertyType={propertyType}
                dealType={dealType}
                dealTypePhrase={dealTypePhrase}
                listingTypePhrase={listingType}
                isSell={isSell}
                locationDeclination={locationDeclinationWhere}
            />
            {statistics.statisticsLocation && (
                <StatisticsByRooms
                    dealType={dealType}
                    propertyType={propertyType}
                    dealTypePhrase={dealTypePhrase}
                    listingTypePhrase={listingType}
                    isSell={isSell}
                    locationDeclination={locationDeclinationWhere}
                    statisticsLocation={statistics.statisticsLocation}
                />
            )}
            {!isSell && (
                <StatisticsByFeatures
                    propertyType={propertyType}
                    dealType={dealType}
                    statistics={statistics}
                    dealTypePhrase={dealTypePhrase}
                    listingTypePhrase={listingType}
                    isSell={isSell}
                    locationDeclination={locationDeclinationWhere}
                />
            )}
            <FindAndLive
                locationDeclination={[locationDeclinationWhere, locationDeclinationWhat]}
                locationName={locationName}
                variant={isSell ? 3 : 1}
                locationSlug={locationSlug}
                locationType={locationType}
                hasPrimaryOffers={hasPrimaryOffers}
            />
            {!isSell && (
                <>
                    <Heading content={headingTwoContent} />
                    <StatisticsCheapestPartOne
                        dealType={dealType}
                        propertyType={propertyType}
                        statistics={statistics}
                        dealTypePhrase={dealTypePhrase}
                        listingTypePhrase={listingType}
                        locationDeclination={locationDeclinationWhere}
                        locationObject={locationObject}
                    />
                    <StatisticsCheapestPartTwo
                        statistics={statistics}
                        dealType={dealType}
                        propertyType={propertyType}
                        dealTypePhrase={dealTypePhrase}
                        listingTypePhrase={listingType}
                        isSell={isSell}
                        locationDeclination={locationDeclinationWhere}
                    />

                    <FindAndLive variant={2} locationDeclination={locationDeclinationWhat} locationSlug={locationSlug} locationType={locationType} />
                </>
            )}
            {isSell && (
                <>
                    {hasAfterMarket && (
                        <AfterMarketCity
                            locationName={locationName}
                            locationDeclination={locationDeclinationWhere}
                            statistics={statsLocationApartmentSellAftermarket}
                        />
                    )}

                    {statistics.statisticsLocation?.apartment && (
                        <SellPrices
                            locationDeclination={locationDeclinationWhere}
                            locationName={locationName}
                            statistics={statistics.statisticsLocation?.apartment}
                            hasPrimaryMarket={hasPrimaryMarket}
                            hasAfterMarket={hasAfterMarket}
                        />
                    )}
                    {statsLocationApartmentSell && (
                        <CheapFlats
                            locationName={locationName}
                            locationDeclination={locationDeclinationWhere}
                            statistics={statsLocationApartmentSell}
                            hasPrimaryMarket={hasPrimaryMarket}
                            hasAfterMarket={hasAfterMarket}
                        />
                    )}
                </>
            )}
        </>
    );
};
