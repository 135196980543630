import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {fetchListDescription} from "./fetch_list_description";
import {ISearchResultRouteParams} from "./fetch_offer_list_at_route";

export const fetchOfferListSeoDescriptionTypes = createRequestActionTypes({
    name: "offerListDescription",
    type: "GET",
    view: "offer_list"
});

export const fetchOfferListSeoDescriptionAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        if (route.pathname === getState().offerList.seoDescriptionLatestQuery) {
            // prevent refetch
            return Promise.resolve(true);
        }

        dispatch({type: fetchOfferListSeoDescriptionTypes.start, latestQuery: route.pathname});
        const description = await fetchListDescription(services, route.pathname);

        return dispatch({
            type: fetchOfferListSeoDescriptionTypes.success,
            result: description
        });
    };
