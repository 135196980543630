import {isArray, isEmpty, reduce} from "lodash";
import {FormFieldClassMap, FormFieldType} from "@web2/form2";

const strEmpty = (value: any) => value == null || value === "";

export type QueryValues = Record<string, any>;
/**
 * NOTE: do not add values to query that are empty in form
 * This is because we do not need query params to contain empty keys, which looks ugly and is unnecessary.
 */
export const toQueryValues = (types: FormFieldClassMap, formValues: Record<string, any>): QueryValues => {
    return reduce(
        types,
        (acc: QueryValues, type: FormFieldType, key: string) => {
            switch (type) {
                case FormFieldType.Input:
                    return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
                case FormFieldType.Radio:
                    return strEmpty(formValues[key]) ? acc : {...acc, [key]: formValues[key]};
                case FormFieldType.Checkbox:
                    if (!formValues[key]) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: formValues[key]
                    };
                case FormFieldType.Select:
                    if (formValues[key] == null || formValues[key] === "") {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: formValues[key]
                    };
                case FormFieldType.Rooms:
                    if (!formValues[key].length) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: formValues[key] && formValues[key].join(",")
                    };
                case FormFieldType.InputRange:
                case FormFieldType.SelectRange:
                case FormFieldType.SelectRangeNumber:
                    if (formValues[key] == null) {
                        return acc;
                    }
                    let query = {...acc};
                    const {lower, upper} = formValues[key];
                    query = strEmpty(lower) ? query : {...query, [`${key}__gte`]: lower};
                    query = strEmpty(upper) ? query : {...query, [`${key}__lte`]: upper};
                    return query;
                case FormFieldType.SearchOfferType:
                    if (!isArray(formValues[key]) || isEmpty(formValues[key])) {
                        return acc;
                    }
                    return {...acc, [key]: formValues[key].join(",")};
                case FormFieldType.FeatureList:
                    if (!formValues[key].length) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: formValues[key] && `[${formValues[key].join(",")}]`
                    };
                default:
                    throw new Error(`toQueryValues: not implemented for ${key}, formFieldType is ${type}`);
            }
        },
        {}
    );
};
