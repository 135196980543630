import {reduce} from "lodash";
import {FormField} from "@web2/form";

import {IFormValues} from "../../actions/form/create_form_action_types";
import {FormFieldMap, JsonValues} from "./from_json_values";

const isEmpty = (value: any) => value == null || value === "";

/**
 * NOTE: always add values to json (as null or [empty]) that are empty in form (do not use `undefined`)
 * This is because we want to be able to patch keys that user removed values from form.
 */
export const toJsonValues = (types: FormFieldMap, formValues: IFormValues): JsonValues => {
    return reduce(
        types,
        (acc: JsonValues, type: any, key: string) => {
            switch (type) {
                /**
                 * Field
                 */
                case FormField.Input:
                case FormField.InputSearch:
                case FormField.Textarea:
                    return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
                case FormField.Switch:
                case FormField.Radio:
                case FormField.Checkbox:
                case FormField.Rooms:
                    return {...acc, [key]: isEmpty(formValues[key]) ? [] : formValues[key]};
                case FormField.RadioField:
                case FormField.CheckboxField:
                    return {...acc, [key]: !!formValues[key]};
                case FormField.Select:
                    return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
                case FormField.Datepicker:
                case FormField.InputOptional:
                    return {...acc, [key]: isEmpty(formValues[key]) ? undefined : formValues[key]};
                case FormField.SelectAsync:
                    return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key].value};
                case FormField.Phone:
                    const phone = formValues[key];
                    if (phone == null || phone.code == null || !phone.number) {
                        // invalid formValue
                        return {...acc, [key]: null};
                    }
                    return {...acc, [key]: `${phone.code} ${phone.number}`};
                case FormField.InputRange:
                case FormField.SelectRange:
                    const rangeValues = formValues[key];
                    return isEmpty(rangeValues.lower) && isEmpty(rangeValues.upper)
                        ? {...acc, [key]: {}}
                        : {
                              ...acc,
                              [key]: {
                                  lower: isEmpty(rangeValues.lower) ? undefined : rangeValues.lower,
                                  upper: isEmpty(rangeValues.upper) ? undefined : rangeValues.upper
                              }
                          };
                default:
                    throw new Error(`toJsonValues: not implemented for ${key}, formFieldType is ${type.formFieldType}`);
            }
        },
        {}
    );
};
