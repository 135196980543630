import {isFinite} from "lodash";

import {ViewType} from "../../view_type/view_type";
import {hitGoogleTagManager} from "../gtm_init";
import {getGtmProductWithOffer} from "../utils/get_gtm_product";
import {GtmContactType} from "../utils/gtm_contact_type";
import {getListNamePrefix, getListNameSuffix} from "../utils/gtm_list_name";
import {GtmSource} from "../utils/gtm_source";
import {IGtmOffer} from "./gtm_event_typings";

interface IEventWithOffersProps {
    offers: IGtmOffer[];
    viewType: ViewType | null;
    listPositionOffset: number;
    listPrefix?: string;
    listSuffix?: string;
}

export const gtmImpressionsWithOffers = (props: IEventWithOffersProps) => {
    const products = props.offers.map((offer, index) =>
        getGtmProductWithOffer({
            offer,
            contactType: GtmContactType.UNKNOWN,
            gtmSource: GtmSource.UNKNOWN,
            viewType: props.viewType,
            listPrefix: props.listPrefix || getListNamePrefix(props.viewType),
            listSuffix: props.listSuffix || getListNameSuffix(props.offers),
            ...(isFinite(props.listPositionOffset) ? {listPosition: props.listPositionOffset + 1 + index} : {})
        })
    );

    const gtmData = {
        event: "product_list_impressions",
        ecommerce: {
            impressions: products
        }
    };
    hitGoogleTagManager(gtmData);
    return true;
};

export const getSearchResultsListNamePrefix = (offerType: string) => {
    const hasApartments = !!offerType.match("apartment");
    const hasHouse = !!offerType.match("house");
    const hasLots = !!offerType.match("lot");

    switch (true) {
        case hasApartments && hasHouse:
            return "wszystkie";
        case hasApartments:
            return "mieszkanie";
        case hasHouse:
            return "dom";
        case hasLots:
            return "dzialka";
        default:
            return "";
    }
};
