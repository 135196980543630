import {deburr} from "lodash";
import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {enable302ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";
import {ILocation} from "../../offer/list/actions/fetch_location_by_slug_at_route";
import {ISearchResultRouteParams} from "../../offer/list/actions/fetch_offer_list_at_route";
import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {createLocationSlug} from "../../offer/utils/create_location_slug";

export const redirectSchemaWebsite = (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => (dispatch: Dispatch) => {
    if (!route.query.q) {
        dispatch(enable302ResponseState(getOfferListUrl({offerType: "nieruchomosci", type: "na-sprzedaz"})));
        return Promise.resolve(false);
    }

    // google searchbox redirects user to gethome.pl/search/?q={search_query}. `search_query` contains percent-encoded special characters and diacritics
    const decodedQuery = deburr(decodeURI(route.query.q)); // decode URI and remove diacritics
    const validQuery = decodedQuery.replace(/[^\w-]/g, ""); // remove all special characters except `-`
    const locationApiUrl = apiLink.locations.detail({})({slug: createLocationSlug(validQuery, undefined, undefined)});

    // fetch received region from locations api
    return getRequest(services, locationApiUrl)
        .then((response: ILocation) => {
            const searchResultUrl = getOfferListUrl({offerType: "nieruchomosci", type: "na-sprzedaz", location: response.slug});
            dispatch(enable302ResponseState(searchResultUrl));
            return Promise.resolve(false);
        })
        .catch(
            catch404(async () => {
                dispatch(enable302ResponseState(getOfferListUrl({offerType: "nieruchomosci", type: "na-sprzedaz"})));
                return Promise.resolve(false);
            })
        );
};
