import * as React from "react";
import {ReactNode} from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {isEmpty} from "lodash";
import {MOBILE_NAV_TESTID} from "@web2/gh_page_object_models";

import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";
import {FavouritesCountBadge} from "./FavouritesCountBadge";
import {
    MOBILE_NAV_WIDTH_LEVEL_2,
    MOBILE_NAV_WIDTH_LEVEL_3,
    MOBILE_NAV_WIDTH_LEVEL_4,
    MOBILE_NAV_WIDTH_LEVEL_5,
    NAV_BG_LEVEL_2,
    NAV_BG_LEVEL_3
} from "./Navigation";

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    onBack: () => void;
    label: string | ReactNode;
    navState: number[];
    arrow?: ReactNode;
    favouritesCount?: number;
}

export const BurgerMenu = (props: IProps) => {
    return (
        <BurgerMenuHolder isActive={props.isOpen} className={cx(props.isOpen && activeBurgerMenuHolder)}>
            {props.isOpen && (
                <div className={menuLabelHolder} onClick={props.onBack}>
                    <div className={arrowHolder}>{!isEmpty(props.navState) && props.arrow}</div>
                    {props.label}
                </div>
            )}

            {!props.isOpen && props.favouritesCount !== 0 && props.favouritesCount && (
                <FavouritesCountBadge favouritesCount={props.favouritesCount} className={burgerFavouritesCountBadge} />
            )}

            <BurgerButtonHolder isActive={props.isOpen} onClick={props.isOpen ? () => props.setClose() : () => props.setOpen()}>
                <button
                    data-testid={MOBILE_NAV_TESTID.MOBILE_MENU}
                    className={cx(burgerButton, props.isOpen && openBurgerMenu)}
                    aria-label="Kliknij aby otworzyć menu"
                />
            </BurgerButtonHolder>
        </BurgerMenuHolder>
    );
};

const BurgerMenuHolder = styled.div<{isActive: boolean}>`
    display: flex;
    height: ${getThemeVariable("main_nav-height")};
    align-items: center;
    font-size: ${getThemeVariable("fonts-font_size_small")};
    z-index: ${getThemeVariable("main_nav-z_index")};
    background: #fff;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        display: none;
    }
`;

const activeBurgerMenuHolder = css`
    background: ${NAV_BG_LEVEL_2};
    justify-content: space-between;
    width: 320px;
    position: fixed;

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_3 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_4 + "px"};
    }

    @media (min-width: ${MOBILE_NAV_WIDTH_LEVEL_5 + "px"}) {
        width: ${MOBILE_NAV_WIDTH_LEVEL_2 + "px"};
    }
`;

const menuLabelHolder = css`
    display: flex;
    align-items: center;
`;

const arrowHolder = css`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IBurgerButtonHolderProps {
    isActive: boolean;
}

const BurgerButtonHolder = styled.div<IBurgerButtonHolderProps>`
    width: ${getThemeVariable("main_nav-height")};
    height: ${getThemeVariable("main_nav-height")};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.isActive ? NAV_BG_LEVEL_3 : getThemeVariable("colors-brand_primary"))};
`;

const burgerButton = css`
    border: none;
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top: 3px solid;
    border-bottom: 3px solid;
    color: #fff;
    transition: all 0.25s ease-in-out;

    &:focus {
        outline: none;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: currentColor;
        transform: translate(-50%, -50%);
        transition: transform 0.25s ease-in-out;
    }
`;

const openBurgerMenu = css`
    border-color: transparent;
    color: ${getThemeVariable("colors-text_color")};

    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;

const burgerFavouritesCountBadge = css`
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    position: absolute;
    left: unset;
    pointer-events: none;
    margin-left: 0;
`;
