import {matchPath} from "react-router";
import {upperFirst} from "lodash";
import {Dispatch} from "redux";
import {appLink, appPath, parseInvestmentSlugToObject, parseOfferSlugToObject} from "@web2/gh_routes";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {ghCommonUrl} from "../../../app/utils/read_environment_variables";
import {updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {OfferMarketType} from "../../utils/constants_offer";
import {getOfferTitle, offerDescription} from "../../utils/utils";

export const updateOfferMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch, getState: () => IStore) => {
    const offer = getState().offer.offer;
    const isCanonicalUrl = !!matchPath(route.pathname, {exact: true, strict: true, path: appPath.offer.detail.base});

    const url = `${ghCommonUrl}${appLink.fullOffer.detail.base(parseOfferSlugToObject(offer.slug))}`;
    const investmentUrl = offer.investment ? `${ghCommonUrl}${appLink.investment.detail(parseInvestmentSlugToObject(offer.investment?.slug))}` : "";
    const isInvestment = offer.investment;

    const canonical = isInvestment ? investmentUrl : url;
    const description = offerDescription(offer);
    const image = (offer.main_image && offer.main_image.o_img_500) || undefined;
    const robots = (() => {
        if ((!offer.is_active && offer.market_type === OfferMarketType.PRIMARY_MARKET) || !isCanonicalUrl) {
            return "noindex, follow";
        }
        return "index, follow";
    })();

    const title = upperFirst(getOfferTitle(offer));

    dispatch(
        updateMetaData(title, description, route, {
            canonical,
            url,
            robots,
            image
        })
    );
    return Promise.resolve(true);
};
