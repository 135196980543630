import {useCallback, useMemo} from "react";
import {ControlProps, GroupBase, OptionProps, StylesConfig, Theme, ValueContainerProps} from "react-select";
import {useTheme} from "@emotion/react";

import {SelectSize} from "./Select";

interface ISelectProps {
    size?: SelectSize;
    error?: string[];
    isMulti?: boolean;
    asyncSelect?: boolean;
}

interface IHook<Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>> {
    getTheme: (theme: Theme) => Theme;
    styles: StylesConfig<Option, IsMulti, Group>;
}

export const useSelectTheme = <Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>>(): IHook<
    Option,
    IsMulti,
    Group
> => {
    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getTheme = useCallback(
        ({colors, spacing, ...theme}: Theme): Theme => {
            return {
                colors: {
                    ...colors
                },
                spacing: {...spacing, controlHeight: 48},
                borderRadius: 4
            };
        },
        [theme]
    );

    const styles: StylesConfig<Option, IsMulti, Group> = useMemo(() => {
        return {
            control: (provided, state: ControlProps<Option, IsMulti, Group> & ISelectProps) => {
                return {
                    ...provided,
                    minHeight:
                        state.size === "lg"
                            ? theme.forms.input_height_large
                            : state.size === "sm"
                              ? theme.forms.input_height_small
                              : theme.forms.input_height_base,
                    borderColor:
                        state.isFocused && !state.error
                            ? theme.forms.input_border_focus
                            : state.error
                              ? theme.forms.error_message_color
                              : theme.forms.input_border,
                    borderRadius:
                        state.size === "lg"
                            ? theme.forms.input_border_radius_large
                            : state.size === "sm"
                              ? theme.forms.input_border_radius_small
                              : theme.forms.input_border_radius,
                    boxShadow:
                        state.isFocused && !state.error
                            ? theme.forms.input_box_shadow_focus
                            : state.error
                              ? theme.forms.error_message_input_box_shadow
                              : theme.forms.input_box_shadow,
                    backgroundColor: theme.forms.input_bg,
                    color: theme.forms.input_color,
                    ":hover": {
                        borderColor:
                            state.isFocused && !state.error
                                ? theme.forms.input_border_focus
                                : state.error
                                  ? theme.forms.error_message_color
                                  : theme.forms.input_border
                    }
                };
            },
            indicatorsContainer: (provided) => {
                return {
                    ...provided,
                    cursor: "pointer"
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    zIndex: 1000
                };
            },
            //   any: Hard to tell what Select really expects, some of its own typings extend { [key: string]: any }
            //   eslint-disable-next-line @typescript-eslint/no-explicit-any
            option: (provided, state: OptionProps<Option, IsMulti, Group> & ISelectProps) => {
                return {
                    ...provided,
                    color: theme.forms.input_color,
                    backgroundColor: state.isSelected ? theme.forms.select_option_bg_selected : theme.forms.select_option_bg,
                    fontSize: theme.forms.select_option_font_size,
                    padding:
                        state.size === "lg"
                            ? `1rem ${theme.padding.padding_large_horizontal}`
                            : state.size === "sm"
                              ? `1rem ${theme.padding.padding_small_horizontal}`
                              : `1rem ${theme.padding.padding_base_horizontal}`,
                    ":hover": {
                        backgroundColor: state.isSelected ? theme.forms.select_option_bg_selected_hover : theme.forms.select_option_bg_hover
                    }
                };
            },
            // any: Hard to tell what Select really expects, some of its own typings extend { [key: string]: any }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            valueContainer: (provided, state: ValueContainerProps<Option, IsMulti, Group> & ISelectProps) => {
                return {
                    ...provided,
                    padding:
                        state.size === "lg"
                            ? `0.3rem ${theme.padding.padding_large_horizontal}`
                            : state.size === "sm"
                              ? `0.3rem ${theme.padding.padding_small_horizontal}`
                              : `0.3rem ${theme.padding.padding_base_horizontal}`,
                    cursor: state.asyncSelect ? "text" : "default"
                };
            },
            placeholder: (provided) => {
                return {
                    ...provided,
                    color: theme.forms.input_color_placeholder,
                    fontSize: theme.forms.input_font_size_base,
                    marginLeft: 0,
                    marginRight: 0,
                    fontWeight: theme.forms.input_font_weight
                };
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            input: (provided) => {
                return {
                    ...provided,
                    margin: 0,
                    fontSize: theme.forms.input_font_size_base,
                    fontWeight: theme.forms.input_font_weight
                };
            },
            singleValue: (provided) => {
                return {
                    ...provided,
                    marginLeft: 0,
                    marginRight: 0,
                    fontSize: theme.forms.input_font_size_base,
                    fontWeight: theme.forms.input_font_weight
                };
            },
            multiValue: (provided) => {
                return {
                    ...provided,
                    backgroundColor: theme.forms.select_multi_value_bg,
                    borderRadius: theme.forms.select_multi_value_border_radius
                };
            },
            multiValueLabel: (provided) => {
                return {
                    ...provided,
                    color: "#fff",
                    fontSize: theme.forms.input_font_size_base,
                    paddingLeft: "1rem"
                };
            },
            multiValueRemove: (provided) => {
                return {
                    ...provided,
                    color: "#fff",
                    ":hover": {
                        backgroundColor: "unset",
                        color: "#fff",
                        cursor: "pointer",
                        borderTopRightRadius: theme.forms.select_multi_value_border_radius,
                        borderTopLeftRadius: theme.forms.select_multi_value_border_radius
                    }
                };
            }
        };
    }, []);

    return {
        getTheme,
        styles
    };
};
