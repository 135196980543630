import {styled} from "@linaria/react";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";

interface IChildHolderProps {
    rightDropdown?: boolean;
    bottomDropdown?: boolean;
}

export const ChildHolder = styled.div<IChildHolderProps>`
    position: absolute;
    z-index: 1001;
    box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.2);
    border-radius: ${getThemeVariable("other-border_radius")};
    right: ${(props) => (props.rightDropdown ? 0 : "unset")};
    left: ${(props) => (props.rightDropdown ? "unset" : 0)};
    background: ${getThemeVariable("colors-body_bg")};
    min-width: 140px;
    max-width: 220px;
    max-height: 600px;
    bottom: ${(props) => (props.bottomDropdown ? "50px" : "unset")};
    top: ${(props) => (props.bottomDropdown ? "unset" : "50px")};

    label {
        display: block;
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .range-input-lower,
    .range-input-upper {
        margin-bottom: 1rem;
    }

    &:before {
        width: 12px;
        height: 12px;
        content: "";
        position: absolute;
        transform: rotate(45deg);
        border-radius: 0.1rem;
        background-color: white;
        z-index: 12;
        bottom: ${(props) => (props.bottomDropdown ? "-6px" : "unset")};
        top: ${(props) => (props.bottomDropdown ? "unset" : "-6px")};
        right: ${(props) => (props.rightDropdown ? "10px" : "unset")};
        left: ${(props) => (props.rightDropdown ? "unset" : "10px")};
`;

export const ChildHolderWide = styled.div<IChildHolderProps>`
    position: absolute;
    top: 50px;
    right: ${(props) => (props.rightDropdown ? 0 : "unset")};
    left: ${(props) => (props.rightDropdown ? "unset" : 0)};
    z-index: 1001;
    background: ${getThemeVariable("colors-body_bg")};
    box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.2);
    border-radius: ${getThemeVariable("other-border_radius")};
    min-width: 140px;
    max-width: 430px;

    label {
        display: block;
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .range-input-lower,
    .range-input-upper {
        margin-bottom: 1rem;
    }

    &:before {
        width: 12px;
        height: 12px;
        content: "";
        position: absolute;
        top: -6px;
        transform: rotate(45deg);
        border-radius: 0.1rem;
        background-color: white;
        z-index: 12;
        right: ${(props) => (props.rightDropdown ? "10px" : "unset")};
        left: ${(props) => (props.rightDropdown ? "unset" : "10px")};
`;
