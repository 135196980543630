export enum GtmSource {
    SIDEBAR = "sidebar",
    GALLERY = "gallery",
    BOTTOM = "bottom",
    ASK_FOR_OFFER = "ask_for_offer",
    ASK_FOR_PRICE = "ask_for_price",
    MOBILE_FLOATING = "mobile_floating",
    MULTILEAD = "multilead",
    PAYWALL = "paywall",
    UNKNOWN = "na"
}

export const getGtmSource = (source: GtmSource) => {
    switch (source) {
        case GtmSource.SIDEBAR:
            return "sidebar";
        case GtmSource.GALLERY:
            return "galeria";
        case GtmSource.BOTTOM:
            return "na dole";
        case GtmSource.ASK_FOR_OFFER:
            return "zapytaj o ofertę";
        case GtmSource.ASK_FOR_PRICE:
            return "zapytaj o cenę";
        case GtmSource.MOBILE_FLOATING:
            return "mobile floating";
        case GtmSource.MULTILEAD:
            return "multilead";
        case GtmSource.PAYWALL:
            return "paywall";
        case GtmSource.UNKNOWN:
        default:
            return "";
    }
};
