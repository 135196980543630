import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const TwitterIcon = (props: IProps) => {
    return (
        <svg width={props.width || 22} height={props.height || 22} className={classNames(props.className, "svg-icon")} viewBox="0 0 22 22">
            <rect width="22" height="22" rx="3.76" fill={props.mainColor || "#7bc5f8"} />
            <polygon points="18.69 4.96 18.69 4.96 18.69 4.96 18.69 4.96" fill="#fff" />
            <path
                d="M18.09,6.53a2.26,2.26,0,0,1-.6.08V6.54a4.84,4.84,0,0,0,1.2-1.37.25.25,0,0,0,0-.21l-1,.43-.84.37h0a3.34,3.34,0,0,0-2.43-1,3,3,0,0,0-3.16,2.76,4.49,4.49,0,0,0,.13,1c0,.08,0-.08,0,0A9.81,9.81,0,0,1,7.55,7.42C5,6.14,4.87,5.25,4.87,5.25a3.44,3.44,0,0,0-.2,2.91A3.66,3.66,0,0,0,5.81,9.49h0A2.65,2.65,0,0,1,5,9.36a1.83,1.83,0,0,1-.57-.28c-.24.6.25,1.67,1.1,2.44a4.52,4.52,0,0,0,1.41.73l-1.42,0c-.08,1.39,2.9,2.21,2.9,2.21h0a4.88,4.88,0,0,1-3,1.08,5.77,5.77,0,0,1-1.62-.24,7.75,7.75,0,0,0,5.28,1.89c5.31-.28,8.31-4.85,8.46-9.34h0a3.55,3.55,0,0,0,.78-.65,5.52,5.52,0,0,0,.87-1.16S18.42,6.44,18.09,6.53Z"
                fill="#fff"
            />
            <path d="M18.69,5c0,.09,0,0,0,0Z" fill="#fff" />
        </svg>
    );
};
