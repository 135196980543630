import React from "react";
import {css, Theme} from "@emotion/react";

import {Footer} from "../../footer/Footer";
import {HomepageSearch} from "../../homepage/components/HomepageSearch";
import {OfferTypeSection} from "../../homepage/components/OfferTypeSection";
import {Flex} from "../atoms/Flex";

import bgImage from "../../../assets/404.png";

export const NotFoundDynamic = () => {
    return (
        <>
            <section css={notFoundHolder}>
                <Flex alignItems="center" justifyContent="space-between">
                    <div css={notFoundCopyHolder}>
                        <h1 css={notFoundHeading}>Uuups!</h1>

                        <p css={notFoundCopy}>
                            Przepraszamy, nie możemy wyświetlić tej strony. <br />
                            Możliwe, że zmienił się jej adres lub została usunięta. <br />
                            Skorzystaj z menu głównego lub wyszukiwarki, by znaleźć informacje, których szukasz.
                        </p>

                        <p css={notFoundErrorCode}>kod błędu: 404</p>

                        <HomepageSearch css={notFoundSearch} />
                    </div>
                </Flex>
            </section>

            <OfferTypeSection />

            <Footer />
        </>
    );
};

const notFoundHolder = (theme: Theme) => css`
    max-width: 1300px;
    margin: 3rem auto;
    padding: 0 1rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        background-image: url("${bgImage}");
        padding: 3rem 1.5rem;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 6rem 1.5rem;
    }
`;

const notFoundCopyHolder = (theme: Theme) => css`
    max-width: 100%;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        max-width: 552px;
    }
`;

const notFoundHeading = (theme: Theme) => css`
    margin: 2rem 0 0;
    color: ${theme.colors.gray_darker};
    font-size: 45px;
    font-weight: 600;
    text-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
    line-height: 1.5;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 5.5rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 6.5rem;
    }
`;

const notFoundCopy = (theme: Theme) => css`
    margin: 0.5rem 0;
    font-weight: 400;
    line-height: 1.58;
    font-size: 1.6rem;
    color: ${theme.colors.gray_darker};
`;

const notFoundErrorCode = (theme: Theme) => css`
    font-size: 1.6rem;
    line-height: 1.56;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${theme.colors.gray_light};
`;

const notFoundSearch = css`
    box-shadow: 0 1.4rem 1.4rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 2rem;
`;
