import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

export interface IIcon {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const PhoneFillIcon = (props: IIcon) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M2.07,4.34A8.3,8.3,0,0,0,5.66,7.93l1.2-1.2a.51.51,0,0,1,.55-.13,6.25,6.25,0,0,0,1.94.31.55.55,0,0,1,.55.54v1.9a.55.55,0,0,1-.55.55A9.25,9.25,0,0,1,.1.65.55.55,0,0,1,.65.1h1.9a.55.55,0,0,1,.54.55,5.94,5.94,0,0,0,.32,1.94.54.54,0,0,1-.14.55Z" />
        </SvgIcon>
    );
};
