import * as React from "react";

export const StatsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.91" height="20" viewBox="0 0 20.91 20">
            <path
                d="M17.48,14.4h-.67V4.81A.81.81,0,0,0,16,4H14.52a.81.81,0,0,0-.81.81V14.4H12V9.15a.82.82,0,0,0-.81-.81H9.74a.82.82,0,0,0-.82.81V14.4H7.24V11.68a.81.81,0,0,0-.81-.81H5a.81.81,0,0,0-.81.81V14.4h-.7a.3.3,0,1,0,0,.6h14a.3.3,0,0,0,0-.6Zm-10.84,0H4.74V11.68A.21.21,0,0,1,5,11.47H6.43a.22.22,0,0,1,.21.21Zm4.78,0H9.53V9.15A.2.2,0,0,1,9.74,9h1.48a.2.2,0,0,1,.2.2Zm4.79,0h-1.9V4.81a.22.22,0,0,1,.21-.21H16a.21.21,0,0,1,.21.21Z"
                fill="#37474f"
                fillRule="evenodd"
            />
            <path
                d="M17.85,2.93A10.62,10.62,0,0,0,10.46,0a10.64,10.64,0,0,0-7.4,2.93,9.7,9.7,0,0,0,0,14.14A10.64,10.64,0,0,0,10.46,20a10.62,10.62,0,0,0,7.39-2.93,9.7,9.7,0,0,0,0-14.14ZM17.3,16.55a10,10,0,0,1-13.69,0,9,9,0,0,1,0-13.1,10,10,0,0,1,13.69,0,9,9,0,0,1,0,13.1Z"
                fill="#37474f"
                fillRule="evenodd"
            />
        </svg>
    );
};
