import {matchPath} from "react-router";
import {Request} from "express";
import {reduce} from "lodash";

import {IABTestConfiguration} from "./interfaces/IABTestConfiguration";
import {IAppendServerRequestHeadersMethod} from "./utils/ab_testing_ssr";
import {getABTestVariantFromDocumentCookies} from "./utils/ab_testing_utils";
import {getABTestData} from "./utils/get_ab_test_data";

export interface IABTestContainerProps {
    id: string;
    variants: number;
    withSSR: boolean;
}

export interface IABTestingAPI {
    getABTestContainerProps: (name: string) => IABTestContainerProps;
    initOngoingABTestsVariant: (pathname: string, req: Request, appendServerRequestHeadersMethod?: IAppendServerRequestHeadersMethod) => string[];
    getInitialClientABTestsContext: () => Record<string, number>;
    getInitialSSRABTestsContext: (data: string[]) => Record<string, number>;
}

interface IABTestingOptions {
    domain: string;
}

/**
 * Creates AB testing instance
 * @param config {IABTestConfiguration[]} AB tests configuration
 * @param options {IABTestingOptions} instance options object
 * @returns {IABTestingAPI} AB testing instance
 */
export const initABTestingInstance = (config: IABTestConfiguration[], options: IABTestingOptions): IABTestingAPI => {
    return {
        getABTestContainerProps: getABTestContainerProps(config),
        initOngoingABTestsVariant: initOngoingABTestsVariant(config, options),
        getInitialClientABTestsContext: getInitialClientABTestsContext(config),
        getInitialSSRABTestsContext
    };
};

/*
 * initABTestingInstance methods
 */

const initOngoingABTestsVariant =
    (config: IABTestConfiguration[], options: IABTestingOptions) =>
    (pathname: string, req: Request, appendServerRequestHeadersMethod?: IAppendServerRequestHeadersMethod) => {
        const matchedABTests = matchOngoingABTestsByPathname(config, pathname);

        const results: string[] = [];

        if (matchedABTests.length) {
            matchedABTests.forEach((abTest) => {
                const abTestData = getABTestData(abTest, options.domain, req, appendServerRequestHeadersMethod);

                if (abTest.withSSR) {
                    results.push(abTestData);
                }
            });
        }

        return results;
    };

const getABTestContainerProps =
    (config: IABTestConfiguration[]) =>
    (name: string): IABTestContainerProps => {
        const abTestConfig = config.find((abTest) => abTest.name === name);

        if (abTestConfig) {
            return {
                id: abTestConfig.id,
                variants: abTestConfig.variants,
                withSSR: abTestConfig.withSSR
            };
        }

        throw new Error("AB test not found");
    };

const getInitialClientABTestsContext = (config: IABTestConfiguration[]) => (): Record<string, number> => {
    const matchedABTests = matchOngoingABTestsByPathname(config, window.location.pathname);

    if (matchedABTests.length) {
        return reduce(
            matchedABTests,
            (acc: Record<string, number>, current: IABTestConfiguration) => {
                const variant = getABTestVariantFromDocumentCookies(current.id);
                if (variant !== null) {
                    return {...acc, [current.id]: variant};
                }
                return acc;
            },
            {}
        );
    }

    return {};
};

const getInitialSSRABTestsContext = (ABTests: string[]): Record<string, number> => {
    return reduce(
        ABTests,
        (acc: Record<string, number>, current: string) => {
            const [id, variant] = current.split(".");
            if (id && variant) {
                return {...acc, [id]: parseInt(variant)};
            }
            return acc;
        },
        {}
    );
};

/*
 * helpers
 */
const matchOngoingABTestsByPathname = (config: IABTestConfiguration[], pathname: string): IABTestConfiguration[] => {
    return config.filter(({path, matchOnAllPaths}) => {
        if (matchOnAllPaths) {
            return true;
        }
        if (Array.isArray(path)) {
            return path.some((value) => matchPath(pathname, {path: value, exact: true, strict: true, sensitive: true}));
        }
        return matchPath(pathname, {path, exact: true, strict: true, sensitive: true});
    });
};
