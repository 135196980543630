import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@web2/button";
import {FormikForm, IFormikSubmitFn} from "@web2/formik_utils";
import {appLink} from "@web2/gh_routes";
import {AtEmailIcon, PersonFillIcon, PhoneFillIcon} from "@web2/icons";

import {PopoverGH} from "../../app/components/PopoverGH";
import {mb_md, mt_0, pb_sm} from "../../styles/helpers";
import {emptyApplicationValues} from "./Application";

interface IProps {
    initialValues: typeof emptyApplicationValues;
    buttonText?: string;
    formError?: string | null;
    onFormErrorClick?: () => void;
    onUpdate: () => void; // TODO: type this
    onSubmit: IFormikSubmitFn<typeof emptyApplicationValues>;
    showSuccess: boolean;
    financialServicesPropertyType?: string;
}

export const ApplicationForm = (props: IProps) => {
    return (
        <FormikForm onSubmit={props.onSubmit} onChange={props.onUpdate} initialValues={props.initialValues} enableReinitialize>
            <FormikForm.Textarea name="text" errorOnBottom />

            <FormikForm.Input name="full_name" icon={<PersonFillIcon size="1.8" fillColor="#999" />} placeholder="Imię i nazwisko" errorOnBottom />

            <FormikForm.Input name="email" icon={<AtEmailIcon size="1.8" fillColor="#999" />} placeholder="Email" errorOnBottom />

            <div css={[pb_sm]}>
                <FormikForm.Input name="phone" icon={<PhoneFillIcon size="1.8" fillColor="#999" />} placeholder="Telefon" errorOnBottom />
            </div>

            <FormikForm.Checkbox
                name="new_offers_consent"
                label={"Chcę otrzymywać mailem lub SMSem linki do nowych ofert nieruchomości. Zgodę mogę zawsze odwołać."}
                css={[mb_md]}
            />

            <PopoverGH popover={<TooltipCheckboxContent />} placement="left">
                <FormikForm.Checkbox
                    name="financing_services"
                    label={`Proszę o przedstawienie oferty kredytu na ${props.financialServicesPropertyType || "zakup nieruchomości"}.`}
                    css={[mb_md]}
                />
            </PopoverGH>

            <p css={applicationFormTerms}>
                Akceptuję{" "}
                <a href={appLink.terms()} target="_blank">
                    regulamin serwisu
                </a>{" "}
                i zamawiam usługę wyszukiwania dla mnie podobnych nieruchomości i otrzymywania o nich powiadomień.
            </p>

            {props.formError && (
                <div css={formErrorHolder}>
                    <p>{props.formError}</p>

                    <Button onClick={() => props.onFormErrorClick && props.onFormErrorClick()}>Ok</Button>
                </div>
            )}

            {props.showSuccess ? (
                <FormSuccessSend>Zgłoszenie zostało wysłane</FormSuccessSend>
            ) : (
                <FormikForm.Button variant="success" type="submit" fullWidth>
                    {props.buttonText || "Wyślij"}
                </FormikForm.Button>
            )}
        </FormikForm>
    );
};

const TooltipCheckboxContent = () => {
    return (
        <div css={tooltipTextStyle}>
            <p css={mt_0}>Proszę o telefoniczny i niezobowiązujący kontakt ze strony specjalisty Credipass sp. z o.o. Zgodę mogę zawsze odwołać.</p>
            <a href="https://credipass.pl/polityka-prywatnosci/" target="_blank">
                Klauzula informacyjna Credipass sp. z o.o. o ochronie danych osobowych.
            </a>
        </div>
    );
};

const tooltipTextStyle = css`
    text-align: left;
    max-width: 30rem;
    padding: 0.5rem;

    a,
    a:visited,
    a:active,
    a:focus,
    a:hover {
        color: inherit;
    }
`;

const applicationFormTerms = (theme: Theme) => css`
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin: 1.3rem 0 1.3rem;
    color: ${theme.colors.gray};

    > a {
        font-weight: 400;
        text-decoration: underline;
    }
`;

const formErrorHolder = css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
`;

export const FormSuccessSend = styled.p`
    margin: 3rem 0 2rem;
    color: #45bc83;
    text-align: center;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
`;
