import {useEffect} from "react";
import {useFormikContext} from "formik";
import {useMounted} from "@web2/react_utils";

/*
 * this component exists because Formik does not provide an easy way to handle onChange callbacks
 */

export const OnChangeHandlingComponent = (props: {onChange: (formValues: Record<string, unknown>) => void}) => {
    const formikProps = useFormikContext<Record<string, unknown>>();
    const isMounted = useMounted();

    useEffect(() => {
        if (isMounted) {
            props.onChange(formikProps.values);
        }
    }, [formikProps.values]);

    return null;
};
