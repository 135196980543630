import {gtmUserComIsInitialized} from "../google_tag_manager/gtm_user_com_is_initialized";

declare global {
    interface Window {
        civchat: {
            apiKey: string;
        };
        userengage?: () => void;
    }
}

let isUSCInitialized = false;

export const initUserCom = (userComId?: string): void => {
    if (!userComId) {
        return;
    }

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }

    // initialize only once
    if (isUSCInitialized) {
        return;
    }

    (function () {
        window.civchat = {apiKey: userComId};
        const firstScriptTag = document.getElementsByTagName("script")[0];

        const usc = document.createElement("script");
        usc.type = "text/javascript";
        usc.async = true;
        usc.dataset.cfasync = "false";
        usc.src = ("https:" == document.location.protocol ? "https://" : "http://") + "gethome.user.com/widget.js";
        usc.onload = waitForUserEngageReady(gtmUserComIsInitialized);

        firstScriptTag.parentNode?.insertBefore(usc, firstScriptTag);

        isUSCInitialized = true;
    })();
};

const waitForUserEngageReady = (callback: () => void) => {
    let userEngageIsReady = false;
    const interval = setInterval(() => {
        if (typeof window.userengage === "function" && !userEngageIsReady) {
            userEngageIsReady = true;
            callback();
            clearInterval(interval);
        }
    }, 200);
    return null;
};
