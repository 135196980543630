export function getAgentFullName(agent?: {name: string; last_name?: string}): string | null {
    return agent?.name && agent?.last_name ? `${agent.name} ${agent.last_name}` : null;
}

export interface ICircleNumber {
    label: string;
    value: number | string;
}

export function mapAgentNumbers(agent?: any): ICircleNumber[] {
    if (!agent) {
        return [];
    }

    return [
        {
            label: "Liczba mieszkań w ofercie",
            value: agent.offer_summary.offer_count
        },
        {
            label: "Mieszkań na wynajem",
            value: agent.offer_summary.rent.count
        },
        {
            label: "Mieszkań na sprzedaż",
            value: agent.offer_summary.selling.count
        }
    ];
}
