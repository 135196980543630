import {Dispatch} from "redux";
import {appLink, IPageDetailParams} from "@web2/gh_routes";

import {IPrevRouteState, IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IPageDetailResponse} from "./fetch_page_detail";

export const updatePageDetailMetaData =
    (_1: Partial<IServices>, route: IRouteState<IPageDetailParams>, prevRoute: IPrevRouteState | null, prevActionResult: IPageDetailResponse) =>
    (dispatch: Dispatch) => {
        const lang = "pl";
        const url = `${ghCommonUrl}${appLink.page.detail({slug: route.params.slug})}`;
        const title = `${prevActionResult.title}`;
        const description = prevActionResult.description;
        const robots = "index, follow";
        const maxImagePreview = "large";

        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                lang,
                maxImagePreview
            })
        );

        return Promise.resolve(true);
    };
