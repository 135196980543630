import {useSelector} from "react-redux";

import {ILocationStatistics} from "../../../../app/interfaces/response/location_statistics";
import {IOfferInvestment} from "../../../../app/interfaces/response/offer_list";
import {IStore} from "../../../../app/reducers/hybrid_reducer";
import {ILocation} from "../../actions/fetch_location_by_slug_at_route";

interface IProps {
    locationData: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    investment: {
        investment: IOfferInvestment | null;
    };
}

// TODO: consider removing investment reference. I don't know if we can even search investment listings anymore
// TODO: remove Google POI (search_name param) related "polygon". Polygon prop might as well be typed "undefined" when its used later
export const useOfferListMapCommonLogic = (props: IProps) => {
    const latestQuery = useSelector((store: IStore) => store.offerList.latestQuery);
    const isMapInManualBrowsingMode = !!(latestQuery.geo_box_top_right && latestQuery.geo_box_bottom_left);

    const mapPolygon = () => {
        // Take Polygon from investment
        if (latestQuery.investment && props.investment.investment) {
            return props.investment.investment;
        }
        if (latestQuery.search_name && latestQuery.geo_point) {
            return {location: {name: undefined, outline: undefined}};
        }
        return props.locationData;
    };

    return {isMapInManualBrowsingMode, mapPolygon};
};
