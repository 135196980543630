import {SearchTab} from "../actions/fetch_search_all_action";
import {IUpdateCurrentTabAction, UPDATE_CURRENT_TAB} from "../actions/update_current_tab";

const initialState = SearchTab.Placeholder;
export const currentTabReducer = (state: SearchTab = initialState, action: IUpdateCurrentTabAction): SearchTab => {
    switch (action.type) {
        case UPDATE_CURRENT_TAB:
            return action.currentTab;
        default:
            return state;
    }
};
