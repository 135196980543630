import {combineReducers} from "redux";

import {IArticleCategory} from "../../app/interfaces/response/article_category_list";
import {IArticleListArticle} from "../../app/interfaces/response/article_list";
import {reduceRequestState, RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse, reduceResponseField} from "../../app/utils/request_response_utils/factories/reduce_response";
import {fetchArticleCategoriesListActionTypes} from "../actions/fetch_article_category_list_at_route";
import {fetchArticleDetailTypes, IArticleDetailResponse} from "../actions/fetch_article_detail";
import {fetchArticleListActionTypes} from "../actions/fetch_article_list_at_route";
import {fetchDistrictArticleListActionTypes} from "../actions/fetch_district_article_list_at_route";
import {fetchRelatedArticlesAtRouteTypes, IArticleRelatedArticles} from "../actions/fetch_related_articles";

export interface IArticlesStore {
    articleList: {
        page: number;
        count: number;
        page_size: number;
        articles: IArticleListArticle[];
    };
    categoryList: {
        page: number;
        count: number;
        page_size: number;
        categories: IArticleCategory[];
    };
    articleDetail: {
        article: IArticleDetailResponse;
        headlines: {
            text: string;
            id: string;
        }[];
    };
    districtArticleList: IArticleListArticle[];
    articleListRequestState: RequestState;
    categoryListRequestState: RequestState;
    relatedArticles: IArticleRelatedArticles[];
}

export const articlesReducer = combineReducers({
    articleList: reduceResponse(fetchArticleListActionTypes),
    articleListRequestState: reduceRequestState(fetchArticleListActionTypes),
    districtArticleList: reduceResponseField(fetchDistrictArticleListActionTypes, "articles"),
    categoryList: reduceResponse(fetchArticleCategoriesListActionTypes),
    categoryListRequestState: reduceRequestState(fetchArticleCategoriesListActionTypes),
    articleDetail: reduceResponse(fetchArticleDetailTypes),
    relatedArticles: reduceResponse(fetchRelatedArticlesAtRouteTypes)
});
