import {useSelector} from "react-redux";
import {css} from "@linaria/core";
import {MarkerWithCircleIcon} from "@web2/icons";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {useSearchSubmit} from "../../../search/utils/use_search_submit";
import {OfferBoxHolder} from "../../detail/components/offer_box/OfferBox";

interface Props {
    distance: number;
}
export const OfferListExtendSearch = (props: Props) => {
    const searchFormValues = useSelector((state: IStore) => state.search.formValues);
    const {onSubmit} = useSearchSubmit();

    const onClick = () => {
        onSubmit(searchFormValues, {distance: props.distance});
    };

    return (
        <OfferBoxHolder className={wrapperStyle} onClick={onClick}>
            <MarkerWithCircleIcon size="8" className={iconStyle} />
            <div className={label}>Zobacz więcej ofert z okolicy</div>
        </OfferBoxHolder>
    );
};

const iconStyle = css`
    margin-bottom: 3.5rem;
`;

const wrapperStyle = css`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 10;
`;

const label = css`
    font-weight: 500;
    font-size: 1.8rem;
`;
