import * as React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {isEmpty, map} from "lodash";
import {SEARCH_DROPDOWN_TESTID} from "@web2/gh_page_object_models";
import {useMounted} from "@web2/react_utils";
import {pluralize} from "@web2/string_utils";

import {ILastSearch} from "../../app/actions/load_local_storage_last_search_to_store";
import {ILastSearchStore} from "../../app/reducers/last_search_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {OfferDealType} from "../../offer/utils/constants_offer";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../styles/linaria_variable_factory";
import {ISearchSuggestions, SearchTab} from "../actions/fetch_search_all_action";
import {AutocompletePrediction} from "../actions/fetch_search_places_action";
import {IActiveDropdownItemStore} from "../reducers/active_dropdown_item_reducer";
import {ISearchInputValue} from "../utils/ISearchInputValue";
import {resultsList} from "./atoms/atoms";
import {ResultsInfo} from "./atoms/ResultsInfo";
import {ResultsListItem} from "./atoms/ResultsListItem";
import {fetchGooglePlace, PlacesTab} from "./search_autocomplete/PlacesTab";
import {SearchSuggestionsTab} from "./search_autocomplete/SearchSuggestionsTab";

interface IProps {
    dropdownIsOpen: boolean;
    search: ISearchInputValue;
    selectedTab: SearchTab;
    requestState: {
        fetchPlacesRequest: RequestState;
        fetchSuggestionsRequest: RequestState;
    };
    activeItem: IActiveDropdownItemStore;
    places: AutocompletePrediction[];
    onLinkClick: (option: ISearchInputValue) => void;
    lastSearch: ILastSearchStore;
    dealType?: OfferDealType;
    suggestions: ISearchSuggestions[];
}

export const SearchAutocompleteDropdown = (props: IProps) => {
    const isMounted = useMounted();

    const renderActiveTabResults = () => {
        const {selectedTab} = props;
        switch (selectedTab) {
            case SearchTab.Places:
                return (
                    <PlacesTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        places={props.places}
                        fetchPlacesRequest={props.requestState.fetchPlacesRequest}
                    />
                );
            case SearchTab.SearchSuggestions:
                return (
                    <SearchSuggestionsTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        suggestions={props.suggestions}
                        fetchCombinedRequest={props.requestState.fetchSuggestionsRequest}
                        fetchPlacesRequest={props.requestState.fetchPlacesRequest}
                    />
                );

            default:
                return null;
        }
    };

    const renderSuggestedRegions = () => {
        const {lastSearch, lastSearchSuggestionsRequest, lastSearchSuggestions} = props.lastSearch;

        const onCombinedClick = (lS: ISearchSuggestions) => {
            props.onLinkClick({
                label: lS.name,
                tabType: SearchTab.SearchSuggestions,
                suggestions: lS
            });
        };
        const onPlaceClick = (lS: ILastSearch) => {
            if (lS.suggest_type !== "place") {
                return;
            }

            fetchGooglePlace(lS, props.onLinkClick);
        };

        interface IPropertiesCount {
            offer_count_sell?: number;
            offer_count_total: number;
            offer_count_rent?: number;
        }

        const propertiesCount = (suggestion: IPropertiesCount): number => {
            if (props.dealType === OfferDealType.SELL && suggestion.offer_count_sell) {
                return suggestion.offer_count_sell;
            }
            if (props.dealType === OfferDealType.RENT && suggestion.offer_count_rent) {
                return suggestion.offer_count_rent;
            }
            return suggestion.offer_count_total;
        };

        const getLastSearchProps = (lS: ILastSearch) => {
            const {suggest_type, name, offer_count_total} = lS;

            if (suggest_type !== "place") {
                let title = "";
                let subtitle = "";

                if (suggest_type === "agency") {
                    title = name;
                    subtitle = "Agencja Nieruchomości";
                }

                if (suggest_type === "agent") {
                    title = name;
                    subtitle = lS.agency_name || "";
                }

                if (suggest_type === "developer") {
                    title = name;
                    subtitle = "Deweloper";
                }
                if (suggest_type === "location") {
                    title = name;
                    subtitle = lS.full_name || "";
                }

                if (suggest_type === "investment") {
                    title = name;
                    subtitle = "Inwestycja";
                }

                return {
                    title: title,
                    onClick: () => onCombinedClick(lS),
                    propertiesCount: propertiesCount(lS),
                    propertiesCountFormat: pluralize(["oferta", "oferty", "ofert"])(offer_count_total),
                    subTitle: subtitle
                };
            }
            if (lS.suggest_type === "place") {
                return {
                    title: lS.description || "",
                    onClick: () => onPlaceClick(lS)
                };
            }

            return {
                title: "",
                onClick: () => null
            };
        };
        const lastSearchRender = (
            <>
                {map(lastSearch, (lS, idx) => {
                    const {title, onClick, propertiesCount, propertiesCountFormat, subTitle} = getLastSearchProps(lS);

                    return (
                        !isEmpty(title) && (
                            <ResultsListItem
                                idx={idx}
                                key={idx}
                                onClick={onClick}
                                title={title}
                                subTitle={subTitle}
                                activeItemId={props.activeItem.id}
                                propertiesCount={propertiesCount}
                                propertiesFormat={propertiesCountFormat}
                            />
                        )
                    );
                })}
            </>
        );

        const renderLastSearchSuggestions = () => {
            if (lastSearchSuggestionsRequest === RequestState.Success && lastSearchSuggestions.length) {
                const activeItemId = props.activeItem.id ? props.activeItem.id - lastSearch.length : null;
                return (
                    <>
                        {map(
                            lastSearchSuggestions,
                            (lSS: ISearchSuggestions, idx) =>
                                lSS.suggest_type === "location" &&
                                lSS.short_name && (
                                    <ResultsListItem
                                        activeItemId={activeItemId}
                                        propertiesCount={propertiesCount(lSS)}
                                        propertiesFormat={pluralize(["oferta", "oferty", "ofert"])(propertiesCount(lSS))}
                                        idx={idx}
                                        key={idx}
                                        onClick={() => onCombinedClick(lSS)}
                                        title={lSS.short_name}
                                        subTitle={lSS.full_name ? lSS.full_name : ""}
                                    />
                                )
                        )}
                    </>
                );
            }
        };
        return (
            <>
                <ul className={resultsList}>
                    {isMounted && !isEmpty(lastSearch) && (
                        <>
                            <li className={searchResultHeader} data-testid={SEARCH_DROPDOWN_TESTID.LAST_SEARCH_HEADLINE}>
                                Ostatnie wyszukania
                            </li>
                            {lastSearchRender}
                        </>
                    )}

                    {isMounted && !isEmpty(lastSearchSuggestions) && (
                        <>
                            <li className={searchResultHeader} data-testid={SEARCH_DROPDOWN_TESTID.SUGGESTED_LOCATIONS_HEADLINE}>
                                Sugerowane lokalizacje
                            </li>
                            {renderLastSearchSuggestions()}
                        </>
                    )}
                </ul>
            </>
        );
    };

    const renderDropdownContent = () => {
        if (props.search.label && isEmpty(props.search.label) && isEmpty(props.places)) {
            return (
                <ResultsInfo
                    title="Niestety nic nie znaleźliśmy"
                    text="Zmień parametry wyszukiwania"
                    data-testid={SEARCH_DROPDOWN_TESTID.SUGGESTIONS_NOT_FOUND}
                />
            );
        } else if (!props.search.label) {
            return <ResultWrapper>{renderSuggestedRegions()}</ResultWrapper>;
        } else {
            return <ResultWrapper>{renderActiveTabResults()}</ResultWrapper>;
        }
    };

    return <>{renderDropdownContent()}</>;
};

export const ResultWrapper = styled.div`
    overflow: auto;
    flex-shrink: 1;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        max-height: 29.8rem;
        overflow: auto;
        padding-right: 1rem;
    }
`;

const searchResultHeader = css`
    background: ${getThemeVariable("colors-gray_very_bright")};
    font-size: 1.4rem;
    border-radius: 0.4rem;
    text-align: center;
    padding: 0.7rem 1.5rem;
`;
