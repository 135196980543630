import * as pathToRegexp from "path-to-regexp";
import {prefixPath} from "@web2/prefix_path";

export const kmAppPath = prefixPath("/", {
    site: prefixPath(":site([\\w-]+)/", {
        base: "",
        passwordChange: prefixPath("przypomnij-haslo/", {
            base: ""
        }),
        user: prefixPath("konto/", {
            edit: "edycja/",
            subscription: "subskrypcje/",
            passwordChange: "edycja/"
        })
    })
});

export const kmAppLink = {
    site: {
        base: parseParams<{site: string}>(kmAppPath.site.base),
        passwordChange: {
            base: parseParams<{site: string}>(kmAppPath.site.passwordChange.base)
        },
        user: {
            baseWithParams: parseParams<{site: string}>(kmAppPath.site.user.edit),
            subscriptionWithParams: parseParams<{site: string}>(kmAppPath.site.user.subscription),
            passwordChange: parseParams<{site: string}>(kmAppPath.site.user.passwordChange)
        }
    }
};

function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}
