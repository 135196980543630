import {IGtmProduct} from "../utils/get_gtm_product";
import {getListNamePrefix, getListNameSuffix} from "../utils/gtm_list_name";
import {gtmEcommerceEventWithOffer, IEventWithOfferProps} from "./gtm_event_with_offer";

// 'add' event should be considered the same as 'view'

interface IGtmDetailData {
    event: "product_click";
    ecommerce: {
        click: {
            actionField: {
                list: string;
            };
            products: [IGtmProduct];
        };
    };
}

export const gtmOfferClick = (props: IEventWithOfferProps) => {
    gtmEcommerceEventWithOffer(props, getGtmDetailData);
};

const getGtmDetailData = (product: IGtmProduct, props: IEventWithOfferProps): IGtmDetailData => {
    const list = `${props.listPrefix || getListNamePrefix(props.viewType)}_${props.listSuffix}`;
    return {
        event: "product_click",
        ecommerce: {
            click: {
                actionField: {
                    list
                },
                products: [product]
            }
        }
    };
};
