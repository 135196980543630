import {Dispatch} from "redux";
import {appLink, IDeveloperDetailParams, parseDeveloperSlugToObject, parseProvidedObjectToSlug} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {IAgencyAboutData, IOfferInvestmentSummary, IPlaceholderConfiguration} from "../../app/interfaces/response/offer_list";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {enable301ResponseState} from "../../app/utils/request_response_utils/response_state/response_state_actions";
import {VendorType} from "../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferMarketType} from "../../offer/utils/constants_offer";

export interface IDeveloper {
    about: string;
    about_data: IAgencyAboutData;
    address: string;
    agency_type: string;
    created_at: string;
    email: string;
    experience: number | null;
    facebook: string;
    id: string;
    investment_summary: IOfferInvestmentSummary;
    is_active: boolean;
    lead_form_configuration: IPlaceholderConfiguration;
    link: string;
    linkedin: string;
    logo_picture: {
        a_log_360: string;
        a_log_180: string;
        a_log_100: string;
    } | null;
    market_type: OfferMarketType;
    name: string;
    phone_number: string;
    satisfied_customer_count?: string | number;
    slug: string;
    status: number;
    specialization: string;
    type: VendorType.DEVELOPER;
    updated_at: string;
}

export const fetchDeveloperDetailTypes = createRequestActionTypes({view: "Agency", type: "GET", name: "DeveloperDetail"});

export const fetchDeveloperDetailAtRoute =
    (services: Partial<IServices>, route: IRouteState<IDeveloperDetailParams>) =>
    (dispatch: Dispatch): Promise<IDeveloper | boolean | void> => {
        dispatch({type: fetchDeveloperDetailTypes.start});
        const url = apiLink.developers.developer({})({developerId: route.params.developerId});
        return getRequest(services, url)
            .then((res: IDeveloper) => {
                const providedSlug = parseProvidedObjectToSlug({id: route.params.developerId, slug: route.params.developerSlug});

                if (res.slug !== providedSlug) {
                    const {developerId, developerSlug} = parseDeveloperSlugToObject(res.slug);
                    dispatch(enable301ResponseState(appLink.developer.detail.base({developerId, developerSlug})));
                    return false;
                }
                dispatch({type: fetchDeveloperDetailTypes.success, result: res});
                return res;
            })
            .catch(
                catch404(async (err) => {
                    await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                    return false;
                })
            )
            .catch((err) => {
                dispatch({type: fetchDeveloperDetailTypes.error, err});
            });
    };
