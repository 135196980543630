import * as lscache from "lscache";

import {SAVED_USER_INFO_LS_NAME} from "../../utils/constants_offer";

export const loadUserSavedDataFromLS = () => {
    const savedData: {user_email: string; user_name: string; user_phone?: string} | undefined = lscache.get(SAVED_USER_INFO_LS_NAME);
    if (savedData && savedData.user_name && savedData.user_email) {
        return {
            user_email: savedData.user_email,
            user_name: savedData.user_name,
            user_phone: savedData.user_phone
        };
    }

    return undefined;
};

export const saveUserDataToLS = (userInfo: {user_email: string; user_name: string; user_phone?: string}) => {
    lscache.set(SAVED_USER_INFO_LS_NAME, userInfo);
};
