import {fetchSessionCookies} from "../../app/actions/fetch_session_cookie";
import {apiLink} from "../../app/routes/api_link";
import {notifyBugsnag} from "../../app/utils/bugsnag/notify_bugsnag";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {catch403, IAppError} from "../../app/utils/request_response_utils/response_error";
import {emptyApplicationValues} from "../components/Application";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";

interface IApplicationBase {
    email: string;
    full_name: string;
    message: string;
    phone: string;
}

interface IApplicationPrimaryMarketOffer extends IApplicationBase {
    developer: string;
    investment: string;
    offer: string;
}

interface IApplicationInvestmentOffer extends IApplicationBase {
    developer: string;
    investment: string;
}

interface IApplicationSecondaryMarketOffer extends IApplicationBase {
    agent: string;
    offer: string;
}

export type IApplication = IApplicationPrimaryMarketOffer | IApplicationInvestmentOffer | IApplicationSecondaryMarketOffer;

export type IApplicationFields = typeof emptyApplicationValues;
export interface IApplicationResponse extends IApplicationFields {
    agent: string;
    agency: string;
    source: ApplicationSource;
    source_section: ApplicationSourceSection;
}

export const applicationV2Post = (jsonData: Partial<IApplication>, triesLeft = 1): Promise<IApplicationResponse | IAppError> => {
    const url = apiLink.applications({})(null);
    return postRequest({}, url, jsonData)
        .then((res: IApplicationResponse) => {
            return res;
        })
        .catch(
            catch403(async (error) => {
                // application post may be rejected due to incorrect cookies, attempt to fetch sessionid and csrftoken and try again x times
                notifyBugsnag({message: `applicationPost: 403 error`}, `applicationPost: catch 403 (triesLeft: ${triesLeft})`, error.responseError ?? {});
                if (!triesLeft) {
                    // rethrow to handle failure further down
                    throw error;
                }

                await fetchSessionCookies();
                return applicationV2Post(jsonData, --triesLeft);
            })
        );
};
