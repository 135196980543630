import * as React from "react";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const ArrowLeftIcon = (props: IProps) => {
    return (
        <SvgIcon size={props.size || "1"} css={props.className} fillColor={props.fillColor}>
            <path d="M7.61,8.71,3.9,5,7.61,1.28a.75.75,0,0,0,0-1.06.77.77,0,0,0-1.07,0L2.46,4.29a1,1,0,0,0,0,1.41L6.54,9.78A.76.76,0,1,0,7.61,8.71Z" />
        </SvgIcon>
    );
};
