import {IGenericListingParagraphWithStats} from "../../interfaces/interfaces_generic_listings";
import {objectIsFalsy} from "../../utils/check_for_object_where_all_values_are_falsy";
import {AveragePricesRent} from "./AveragePricesRent";
import {CheapestFlats} from "./CheapestFlats";

export const StatisticsCheapestPartTwo = (props: IGenericListingParagraphWithStats) => {
    const {dealTypePhrase, listingTypePhrase, isSell, locationDeclination, statistics} = props;
    const rentApartmentStats = statistics && statistics.statisticsLocation?.apartment.rent;

    const avgRentPricesPerRoom = {
        1: rentApartmentStats?.one_room.avg_price,
        2: rentApartmentStats?.two_rooms.avg_price,
        3: rentApartmentStats?.three_rooms.avg_price,
        4: rentApartmentStats?.four_rooms.avg_price
    };

    const minRentPricesPerRoom = {
        1: rentApartmentStats?.one_room.min_price,
        2: rentApartmentStats?.two_rooms.min_price,
        3: rentApartmentStats?.three_rooms.min_price,
        4: rentApartmentStats?.four_rooms.min_price
    };

    const rentDealPhraseCustomVariant = "na wynajem";

    const isEmptyListAvgRentPricesPerRoom = objectIsFalsy(avgRentPricesPerRoom);
    const isEmptyListMinRentPricesPerRoom = objectIsFalsy(minRentPricesPerRoom);

    return (
        <>
            <>
                {!isEmptyListAvgRentPricesPerRoom && (
                    <>
                        <p>Zastanawiasz się ile kosztuje wynajem mieszkania {locationDeclination ? `${locationDeclination}` : null}? Uśredniając:</p>
                        <AveragePricesRent
                            apartmentRentStats={rentApartmentStats}
                            locationDeclination={locationDeclination}
                            avgRentPricesPerRoom={avgRentPricesPerRoom}
                            rentDealPhraseCustomVariant={rentDealPhraseCustomVariant}
                        />
                    </>
                )}
                {!isEmptyListMinRentPricesPerRoom && (
                    <>
                        <p>
                            <strong>
                                Interesują Cię tanie {listingTypePhrase} {rentDealPhraseCustomVariant}
                                {locationDeclination ? ` ${locationDeclination}` : null}? Według ofert z naszej bazy najtańsze:
                            </strong>
                        </p>
                        <CheapestFlats
                            isSell={isSell}
                            dealTypePhrase={dealTypePhrase}
                            minRentPricesPerRoom={minRentPricesPerRoom}
                            apartmentRentStats={rentApartmentStats}
                            locationDeclination={locationDeclination}
                            rentDealPhraseCustomVariant={rentDealPhraseCustomVariant}
                        />
                    </>
                )}
            </>
        </>
    );
};
