import {MutableRefObject, useRef} from "react";
import {matchPath, useHistory, useLocation} from "react-router";
import {debounce, omit} from "lodash";
import {appPath, ISearchResultUniversalParams, ISearchResultUniversalTagParams} from "@web2/gh_routes";
import {LeafletMap} from "@web2/open-street-map";

import {parseSearch} from "../../../../../app/utils/request_response_utils/parse_search";
import {searchHereGTMEvent} from "../../../../../tracking/google_tag_manager/search_here_click";
import {UOfferTypes} from "../../../../utils/constants_offer";
import {IOfferListQuery} from "../../../reducers/offer_list_reducer";
import {getOfferListUrl} from "../../../url_utils/get_offer_list_url";
import {getParamsByTag} from "../../../url_utils/tag_utils";

export const useTriggerOsmMapSearch = (props: {onAfterSearch?: () => void; leafletRef: MutableRefObject<LeafletMap | null>}) => {
    const location = useLocation();
    const refLocation = useRef(location);
    refLocation.current = location; // we should use ref in functions called from event listeners — static scope variables
    const history = useHistory();

    // we use debounce, because zooming may trigger update very often
    const triggerMapSearch = debounce(() => {
        const map = props.leafletRef.current;
        if (map) {
            const mapBounds = map.getBounds();
            if (mapBounds) {
                const northEast = mapBounds && mapBounds.getNorthEast();
                const southWest = mapBounds && mapBounds.getSouthWest();
                // user moved the map, remove params related to google location search
                const {geo_point, search_name, ...currentQuery} = parseSearch(refLocation.current.search) as Partial<IOfferListQuery>;
                const matchedListingRoute = matchPath<ISearchResultUniversalParams & {offerType: UOfferTypes}>(refLocation.current.pathname, {
                    path: appPath.searchResult.universal
                });
                const routeParams = matchedListingRoute?.params;

                if (routeParams) {
                    // remove location from pathname params
                    const {location: _, subLocation, subSubLocation, ...routeParamsWithoutLocation} = routeParams;

                    const geoBoxQuery = {
                        geo_box_bottom_left: `${southWest.lat}|${southWest.lng}`,
                        geo_box_top_right: `${northEast.lat}|${northEast.lng}`
                    };
                    const pageShouldReset = () => {
                        // If user is manually searching using map ("search here", "search when dragging") whenever he chooses a new location
                        // we need to make sure he is being showed first page of the results. The issue was user being for example on ?page=7
                        // and manually searching on location that had only 2 pages therefore he was showed empty results.
                        if (
                            (!currentQuery.geo_box_bottom_left && !currentQuery.geo_box_top_right) ||
                            (currentQuery.geo_box_bottom_left !== geoBoxQuery.geo_box_bottom_left &&
                                currentQuery.geo_box_top_right !== geoBoxQuery.geo_box_top_right)
                        ) {
                            //User changed location, need to reset page to 1
                            return true;
                        }
                        return false;
                    };
                    const query = {...currentQuery, ...geoBoxQuery, ...(pageShouldReset() ? {page: "1"} : {})};
                    const parsedQuery = query.page && query.page === "1" ? omit(query, "page") : query;

                    const matchedTagRoute = matchPath<ISearchResultUniversalTagParams>(refLocation.current.pathname, {
                        path: appPath.searchResult.universalTag
                    });
                    const tagParams = matchedTagRoute && getParamsByTag(routeParams.offerType, matchedTagRoute.params.tag);

                    const url =
                        getOfferListUrl({
                            ...routeParamsWithoutLocation,
                            ...parsedQuery,
                            ...(tagParams ? tagParams : {})
                        }) + location.hash || "";

                    history.push(url);

                    searchHereGTMEvent();
                    props.onAfterSearch && props.onAfterSearch();
                }
            }
        }
    }, 500);

    return triggerMapSearch;
};
