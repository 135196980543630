import {DomUtils, parseDocument} from "htmlparser2";
import {deburr} from "lodash";
import {Dispatch} from "redux";
import {IArticleDetailAndListParams} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../app/actions/page_404_actions";
import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404} from "../../app/utils/request_response_utils/response_error";
import {bindRelatedPhrasesToText} from "../utils/bind_related_phrases_to_text";

export interface IArticleDetailResponse {
    id: string;
    created_at: string;
    updated_at: string;
    publication_date: string;
    content_updated_date: string;
    link: string;
    type: "article" | "district";
    title: string;
    slug: string;
    lead: string;
    text: string;
    image: {
        link: string; // old size (deprecated)
        a_img_310x175: string; // list size
        a_img_555x321: string; // list size
        a_img_414x200: string;
        a_img_639x200: string;
        a_img_899x320: string;
        a_img_1210x420: string;
    };
    author: IArticleDetailAuthor;
    categories: {
        id: string;
        name: string;
        slug: string;
    }[];
    is_featured: boolean;
    is_opinion: boolean;
    gallery: unknown[];
    local_phrases?: {
        phrase: string;
        destination_url: string;
    }[];
}

export interface IArticleDetailAuthor {
    description: string;
    email: string | null;
    facebook_profile: string | null;
    id: string;
    image: {
        aut_img_80x80: string;
        aut_img_160x160: string;
    };
    instagram_profile: string | null;
    is_featured: boolean;
    linkedin_profile: string | null;
    name: string;
    twitter_profile: string | null;
    slug: string;
    web_page: string | null;
}

export interface IArticleHeadlineData {
    text: string;
    id: string;
}

export const fetchArticleDetailTypes = createRequestActionTypes({view: "Blog", type: "GET", name: " articleDetail"});

export const fetchArticleDetailAtRoute = (services: Partial<IServices>, route: IRouteState<IArticleDetailAndListParams>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchArticleDetailTypes.start});
    const articleApiLink = apiLink.articles.article.base({})({articleSlug: route.params.slug});
    return getRequest({}, articleApiLink)
        .then(async (response: IArticleDetailResponse) => {
            const originalText = response.local_phrases ? bindRelatedPhrasesToText(response.local_phrases, response.text, route.pathname) : response.text;
            const {text, headlines} = extractHeadlines(originalText, response.type);

            const result = {
                article: {
                    ...response,
                    text // overwrite received text with parsed html
                },
                headlines
            };
            dispatch({type: fetchArticleDetailTypes.success, result});
            return result;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            })
        );
};

/**
 * Function parses article to extract information about h2 headers, this information
 * is cleaned up and returned as headline data containing h2 text and its 'slugified' id.
 * Ids are also assigned to <h2> tags in article body.
 *
 * Stored header information is used later to construct interactive table of contents.
 * @param text
 * @param type
 */

// TODO: check if `htmlparser.Parser` is faster?
//  Multiple runs in `bindRelatedPhrasesToText` seem to be faster than one run of `extractHeadlines`
const extractHeadlines = (text: string, type: "article" | "district") => {
    const headlinesList: IArticleHeadlineData[] = [];
    const parsedArticleTextDOM = parseDocument(text, {decodeEntities: true});

    DomUtils.findAll((childNode) => {
        if (childNode.tagName === "h2") {
            const childNodeText = DomUtils.getText(childNode);
            // slugify h2 text to create a clean id
            let slugifiedText = deburr(childNodeText) // deburr cleans up diacritics
                .trim()
                .replace(/\s+/g, "-") // replaces white space chars with dash
                .replace(/[^\w-]+/g, "") // removes non-word chars, except dash
                .toLowerCase();
            if (type === "district") {
                // district article headers are prefixed with a number, we don't want it in id
                slugifiedText = slugifiedText.replace(/^\d+-/gm, "");
            }
            // update <h2> id
            childNode.attribs.id = slugifiedText;
            // store parsed header information
            headlinesList.push({id: slugifiedText, text: childNodeText});
        }
        return false;
    }, parsedArticleTextDOM.childNodes);

    return {
        text: DomUtils.getOuterHTML(parsedArticleTextDOM),
        headlines: headlinesList
    };
};
