import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const BirettaIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M6.47,6.5a.26.26,0,0,0,.27.24A.25.25,0,0,0,7,6.5V5l-.55.23Z" fill={props.secondaryFillColor} />

            <polygon points="6.9 4.47 9.24 3.46 5 1.82 0.76 3.46 4.97 5.31 6.47 4.66 6.9 4.47" fill={props.secondaryFillColor} />

            <path d="M9.62,3.09,5,1.27.35,3.09a.38.38,0,0,0-.27.37.42.42,0,0,0,.24.38l1,.44V6.91C1.34,7.7,3.05,8.73,5,8.73S8.59,7.74,8.66,7V4.28l1-.44a.38.38,0,0,0,.24-.38A.46.46,0,0,0,9.62,3.09Zm-.07.54L7,4.73V5l1.09-.48V6.91c0,.35-1.26,1.3-3.14,1.3s-3.15-.92-3.15-1.3V4.52L5,5.89l1.5-.66V5L5,5.62.42,3.6c-.14,0-.1-.24,0-.28L5,1.55,9.55,3.32A.17.17,0,0,1,9.55,3.63Z" />

            <polygon points="6.47 5.24 7.02 5 7.02 4.73 6.47 4.96 6.47 5.24" />

            <path d="M9.55,3.32,5,1.55.42,3.32c-.1,0-.14.24,0,.28L5,5.62,6.47,5v-.3h0L5,5.31.76,3.46,5,1.82,9.24,3.46l-2.34,1A.26.26,0,0,1,7,4.66v.07l2.53-1.1A.17.17,0,0,0,9.55,3.32Z" />

            <path d="M6.9,4.47l-.43.19h0V5L7,4.73V4.66A.26.26,0,0,0,6.9,4.47Z" />
        </SvgIcon>
    );
};
