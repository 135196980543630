import React, {ReactNode} from "react";
import {EmotionCache} from "@emotion/cache";
import {CacheProvider, Global, ThemeProvider} from "@emotion/react";
import {GhThemeProvider, themeGh} from "@web2/global_styles";
import {UserDeviceProvider} from "@web2/user-device";

import {globalStylesGh} from "../styles/global_emotion_styles_gh";

interface IProps {
    children?: ReactNode;
    emotionCache: EmotionCache;
    userAgent: string | undefined;
}

// We use two different theme providers `<ThemeProvider theme={themeRp}>` and `<GhThemeProvider>` because the first one provides the theme only to app code
// and the second only to packages' code. Issue is probably caused by the way yarn 2 workspaces work - this it is not present in pre-built npm packages
// or in some initial tests done in yarn 3 by Michal Skoczylas

export const GhCommonProviders: React.FC<IProps> = (props) => {
    return (
        <CacheProvider value={props.emotionCache}>
            <GhThemeProvider>
                <ThemeProvider theme={themeGh}>
                    <Global styles={globalStylesGh} />
                    <UserDeviceProvider userAgent={props.userAgent}>{props.children}</UserDeviceProvider>
                </ThemeProvider>
            </GhThemeProvider>
        </CacheProvider>
    );
};
