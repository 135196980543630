import {FC, ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import {RoundedCheckIcon} from "@web2/icons";

import {ml_lg} from "../../styles/helpers";

export const ModalFormSuccessMessage: FC<{children?: ReactNode}> = (props) => {
    const theme = useTheme();

    return (
        <div css={applicationModalSuccessMessage}>
            <RoundedCheckIcon size="1.8" fillColor={theme.colors.brand_success} />
            <div css={[ml_lg]}>{props.children}</div>
        </div>
    );
};

const applicationModalSuccessMessage = css`
    font-size: 1.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
