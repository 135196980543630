import * as React from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {filter, includes, inRange, isEqual, map, max, min, range} from "lodash";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {updateRoomBounds} from "../../utils/room_field_data_translations";

interface IOwnProps {
    error?: string[];
    name: string;
    id: string;
    onAfterChange: (fieldName: string, value: number[]) => void;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
}

export const RoomsSelectMobile = (props: IOwnProps) => {
    const options = [
        {value: 1, label: "1"},
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 4, label: "4"},
        {value: 5, label: "5 i więcej"}
    ];
    const {value, name} = props;

    const onChange = (room: number) => {
        // Check if user unchecked all values one by one.
        if (isEqual(value, [room])) {
            return props.onChange(name, []);
        }
        const {lower, upper} = updateRoomBounds({lower: min(value) || "", upper: max(value) || ""}, room);
        const newValue = range(lower || 0, (upper || 5) + 1);
        return props.onChange(name, filter(newValue));
    };

    const isDisabled = (idx: number): boolean => {
        if (Math.min(...props.value) < Math.max(...props.value)) {
            return inRange(idx, Math.min(...props.value) + 1, Math.max(...props.value));
        }

        return false;
    };

    return (
        <ul className={list}>
            {map(options, (o) => (
                <RoomFilterBlockItem key={o.label} active={includes(value, o.value)} onClick={() => onChange(o.value)}>
                    <div>{o.label}</div>
                </RoomFilterBlockItem>
            ))}
        </ul>
    );
};

const list = css`
    display: flex;
    justify-content: center;
    margin: 1rem auto 2rem;
    padding-left: 0;
    list-style-type: none;

    @media screen and (min-width: 375px) {
        padding-left: 1.7rem;
    }
`;

const RoomFilterBlockItem = styled.li<{active: boolean}>`
    transition: opacity 100ms ease-out;
    user-select: none;

    z-index: ${(props) => (props.active ? 1 : "unset")};

    &:first-of-type > div {
        border-radius: ${getThemeVariable("other-border_radius")} 0 0 ${getThemeVariable("other-border_radius")};
    }

    &:last-of-type > div {
        border-radius: 0 ${getThemeVariable("other-border_radius")} ${getThemeVariable("other-border_radius")} 0;
    }

    &:first-of-type {
        border-radius: ${getThemeVariable("other-border_radius")} 0 0 ${getThemeVariable("other-border_radius")};
    }

    &:last-of-type {
        border-radius: 0 ${getThemeVariable("other-border_radius")} ${getThemeVariable("other-border_radius")} 0;
    }

    > div {
        margin-left: -0.1rem;
        padding: 1.1rem 1.7rem 0.9rem;
        font-size: 1.4rem;
        line-height: 1.64;
        white-space: nowrap;
    }

    > div {
        color: ${(props) => (props.active ? getThemeVariable("colors-brand_primary") : getThemeVariable("colors-gray_dark"))};
        border: 1px solid ${(props) => (props.active ? getThemeVariable("colors-brand_primary") : "#ddd")};
        background-color: ${(props) => (props.active ? "#eee8f6" : "white")};
    }

    &.disabled {
        opacity: 0.5;
    }
`;
