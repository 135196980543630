import {enableRUM} from "./read_environment_variables";

let isRUMInitialized = false;
export const initDebugBearRUM = (userComId?: string): void => {
    if (!enableRUM) {
        return;
    }

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }

    // initialize only once
    if (isRUMInitialized) {
        return;
    }

    (function () {
        const firstScriptTag = document.getElementsByTagName("script")[0];
        const rum = document.createElement("script");
        rum.type = "text/javascript";
        rum.async = true;
        rum.src = ("https:" == document.location.protocol ? "https://" : "http://") + "cdn.debugbear.com/DfOeduVK1L14.js";

        firstScriptTag.parentNode?.insertBefore(rum, firstScriptTag);

        isRUMInitialized = true;
    })();
};
