import {useState} from "react";

import {loadUserSavedDataFromLS} from "../offer/detail/actions/user_saved_data_ls";

export const defaultApplicationValues = {
    full_name: "",
    email: "",
    phone: "",
    new_offers_consent: false,
    financing_services: false,
    text: ""
};

export const useApplicationInitialValues = (applicationText: string) => {
    const [applicationInitialValues, setApplicationInitialValues] = useState<typeof defaultApplicationValues>(defaultApplicationValues);

    const initApplicationForm = (onRestoreFormData: () => void) => {
        const savedUserData = loadUserSavedDataFromLS();

        if (savedUserData) {
            setApplicationInitialValues({
                ...defaultApplicationValues,
                full_name: savedUserData.user_name,
                email: savedUserData.user_email,
                phone: savedUserData.user_phone || "",
                text: applicationText
            });
            onRestoreFormData();
        } else {
            setApplicationInitialValues({
                ...defaultApplicationValues,
                text: applicationText
            });
        }
    };

    const clearForm = () => {
        setApplicationInitialValues({
            ...defaultApplicationValues,
            text: applicationText
        });
    };

    return {
        applicationInitialValues,
        initApplicationForm,
        clearForm
    };
};
