import * as React from "react";
import {css} from "@linaria/core";
import {includes, upperFirst} from "lodash";

import {ILocationStatistics, ILocationStatisticsApartmentAll} from "../../../app/interfaces/response/location_statistics";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {LocationTypes} from "../../../app/utils/get_location_from_path";
import {parseFormDealTypeToSlug, parseFormOfferTypeToSlug} from "../../../search/utils/parse_offer_type_to_form_values";
import {TypeSearchFilterOptions} from "../../../search/utils/TypeSearchFilterOptions";
import {getThemeBreakpoint, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {translateDealTypeToReadablePL} from "../../../translate-utils/translate_deal_type";
import {dealTypePL, OfferDealType, OfferMarketType, OfferType, OfferTypeEN, OfferTypePL, PropertyType, UOfferTypes} from "../../utils/constants_offer";
import {locationNameParser} from "../../utils/location_name_parser";
import {translateOfferType} from "../../utils/utils";
import {ILocationOfferCount, ILocationShort} from "../actions/fetch_location_by_slug_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {getOfferListUrl} from "../url_utils/get_offer_list_url";
import {UrlTagSlug} from "../url_utils/tags";
import {wrapper} from "./atoms/atoms";
import {OfferListFilterFeatureSeoLinks} from "./OfferListFilterFeatureSeoLinks";
import {OfferListSeoLinkListItem} from "./OfferListSeoLinkListItem";

type RoomsNumber = "one_room" | "two_rooms" | "three_rooms" | "four_rooms" | "five_rooms";
interface IProps {
    apartmentStatistics?: Pick<ILocationStatisticsApartmentAll, RoomsNumber>;
    locationStatistics: ILocationStatistics | null;
    offerType: TypeSearchFilterOptions;
    dealType: OfferDealType;
    location: {
        name: string;
        slug: string;
        short_name: string;
        location_type: LocationTypes;
        path: ILocationShort[];
        offer_count: ILocationOfferCount;
    } | null;
    latestQuery: IOfferListQuery;
    activeFeatures?: string[];
    offerListMeta: IOfferListApiResponseMeta;
}

interface IGetListUrlWithFilterFeature {
    offerType: UOfferTypes | OfferType;
    tag?: UrlTagSlug;
    type?: string;
    location?: string;
    subLocation?: string;
    subSubLocation?: string;
}
export const getListUrlWithFilterFeature = (params: IGetListUrlWithFilterFeature, locationSlug: string) => {
    const offerParams = {...params};
    if (locationSlug !== "") {
        const [location, subLocation, subSubLocation] = locationSlug.split("/");

        offerParams.location = location;
        offerParams.subLocation = subLocation;
        offerParams.subSubLocation = subSubLocation;
    }

    return getOfferListUrl(offerParams);
};

export const OfferListSeoLinks = (props: IProps) => {
    // Casting: latestQuery.offerType comes is a string that looks like `TypeSearchFilterOptions.join(",")
    //  and seems to be incorrectly typed in store as `UOfferTypes`
    const offerTypeSlug = parseFormOfferTypeToSlug(props.offerType.split(",") as TypeSearchFilterOptions[]);
    const {location} = props;
    const showRoomsApartments = (rooms: RoomsNumber, notRooms: string) =>
        props.apartmentStatistics &&
        props.apartmentStatistics[rooms].count > 0 &&
        offerTypeSlug === OfferTypePL.apartment &&
        props.latestQuery.rooms !== notRooms;
    const includesHouses = includes(props.offerType, "house");
    const includesApartments = includes(props.offerType, "apartment");
    const offerType = includesApartments ? PropertyType.APARTMENT : includesHouses ? PropertyType.HOUSE : PropertyType.LOT;

    const checkOffersAvailability = (rooms: "one_room" | "two_rooms", offerType: PropertyType, dealType: OfferDealType) => {
        if (offerType === PropertyType.LOT) {
            return;
        }
        return props.location && props.location.offer_count[`${offerType}_${rooms}_${dealType}`] > 0;
    };

    const showOneRoomApartments = showRoomsApartments("one_room", "1") && checkOffersAvailability("one_room", offerType, props.dealType);
    const showTwoRoomsApartments = showRoomsApartments("two_rooms", "2") && checkOffersAvailability("two_rooms", offerType, props.dealType);
    const showThreeRoomsApartments = showRoomsApartments("three_rooms", "3");
    const showFourRoomsApartments = showRoomsApartments("four_rooms", "4");
    const showFiveRoomsApartments = showRoomsApartments("five_rooms", "5");

    const availableFeaturesToShow = props.offerListMeta.features.filter((item) => !props.activeFeatures?.includes(item.feature));
    const isCityOrTown = location?.location_type == LocationTypes.CITY || props.location?.location_type == LocationTypes.TOWN;
    const isCity = location?.location_type == LocationTypes.CITY;
    const isVoivodeship = location?.location_type == LocationTypes.VOIVODESHIP;
    const isCounty = location?.location_type == LocationTypes.COUNTY;
    const isDistrictOrEstate = location?.location_type == LocationTypes.DISTRICT || props.location?.location_type == LocationTypes.ESTATE;
    const showPrivateOffersLink =
        isCity &&
        typeof props.offerListMeta.total_individual_offers === "number" &&
        props.offerListMeta.total_individual_offers > 0 &&
        !props.latestQuery.is_private;

    if (
        (!props.dealType && offerTypeSlug !== OfferType.lot) ||
        (offerTypeSlug === OfferType.lot && isDistrictOrEstate) ||
        isCounty ||
        (isVoivodeship && offerTypeSlug === OfferType.apartment) ||
        (!showOneRoomApartments && !showTwoRoomsApartments && !isCityOrTown && offerTypeSlug !== OfferType.lot) ||
        (props.offerListMeta.features && availableFeaturesToShow?.length == 0 && !isDistrictOrEstate) ||
        offerTypeSlug == OfferType.property
    ) {
        return null;
    }

    const splitRegionSlug = props.location ? props.location.slug.split("/") : [];
    const locationParams = {location: splitRegionSlug[0], subLocation: splitRegionSlug[1], subSubLocation: splitRegionSlug[2]};
    const dealType = translateDealTypeToReadablePL(props.dealType);

    const studios = props.dealType === OfferDealType.SELL ? "Kawalerki" : "Kawalerka";

    const path = props.location?.path;

    const linkName = path ? locationNameParser(path) : "";

    // markets links
    const isAftermarket = props.latestQuery.offer_type.includes(OfferMarketType.AFTERMARKET);
    const houseOrApartmentOfferTypeEn =
        offerTypeSlug === OfferType.house ? OfferTypeEN.house : offerTypeSlug === OfferType.apartment ? OfferTypeEN.apartment : null;
    const showMarketLinks = location && location.location_type === LocationTypes.CITY;
    const locationStatsForOfferType = houseOrApartmentOfferTypeEn && props.locationStatistics?.[houseOrApartmentOfferTypeEn];

    const showPrimaryMarketLink =
        (isAftermarket &&
            props.dealType !== OfferDealType.RENT &&
            showMarketLinks &&
            locationStatsForOfferType?.[props.dealType] &&
            locationStatsForOfferType[props.dealType].primary_market?.count &&
            locationStatsForOfferType[props.dealType].primary_market?.count > 0) ||
        false;

    return (
        <div className={wrapper}>
            <h3 className={heading}>Zobacz także</h3>

            <ul className={list}>
                {showOneRoomApartments && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.STUDIO,
                                offerType: OfferTypePL.apartment,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`${studios}${dealType ? ` ${dealType}` : ""} ${linkName}`}
                        className={listItemFlexBasis}
                    />
                )}

                {showTwoRoomsApartments && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.TWO_ROOM,
                                offerType: OfferTypePL.apartment,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`Mieszkania${dealType ? ` ${dealType}` : ""} 2 pokoje ${linkName}`}
                        className={listItemFlexBasis}
                    />
                )}

                {isCity && showThreeRoomsApartments && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.THREE_ROOM,
                                offerType: OfferTypePL.apartment,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`Mieszkania${dealType ? ` ${dealType}` : ""} 3 pokoje ${linkName}`}
                        className={listItemFlexBasis}
                    />
                )}

                {isCity && showFourRoomsApartments && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.FOUR_ROOM,
                                offerType: OfferTypePL.apartment,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`Mieszkania${dealType ? ` ${dealType}` : ""} 4 pokoje ${linkName}`}
                        className={listItemFlexBasis}
                    />
                )}

                {isCity && showFiveRoomsApartments && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.FIVE_ROOM,
                                offerType: OfferTypePL.apartment,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`Mieszkania${dealType ? ` ${dealType}` : ""} 5 pokoi ${linkName}`}
                        className={listItemFlexBasis}
                    />
                )}

                {showPrimaryMarketLink && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.PRIMARY_MARKET,
                                offerType: offerTypeSlug,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`Rynek pierwotny ${location?.short_name} ${offerTypeSlug}`}
                        className={listItemFlexBasis}
                    />
                )}

                {showPrivateOffersLink && (
                    <OfferListSeoLinkListItem
                        url={getOfferListUrl(
                            {
                                tag: UrlTagSlug.INDIVIDUAL_OFFERS,
                                offerType: offerTypeSlug,
                                type: parseFormDealTypeToSlug(props.dealType),
                                ...locationParams
                            },
                            location?.location_type
                        )}
                        name={`${upperFirst(translateOfferType(props.offerType, OfferTypePL))} ${dealTypePL(props.dealType)} ${
                            location?.short_name || ""
                        } bezpośrednio`}
                        className={listItemFlexBasis}
                    />
                )}
                {!isDistrictOrEstate && (
                    <OfferListFilterFeatureSeoLinks
                        offerType={offerTypeSlug}
                        dealType={props.dealType}
                        location={props.location}
                        availableFeatures={props.offerListMeta.features}
                        activeFeatures={props.activeFeatures}
                    />
                )}
            </ul>
        </div>
    );
};

/**
 * Styles
 */

const heading = css`
    color: ${getThemeVariable("colors-brand_primary")};
`;

const list = css`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
`;

const listItemFlexBasis = css`
    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        flex-basis: 100%;
    }
}
`;
