import {Dispatch} from "redux";
import {appLink, parseInvestmentSlugToObject} from "@web2/gh_routes";
import {capitalizeFirst} from "@web2/nodash";

import {IInvestmentDetailResponse} from "../../../app/interfaces/response/investment";
import {IPrevRouteState, IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {ghCommonUrl} from "../../../app/utils/read_environment_variables";
import {updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {OfferTypeDeclensionWhat, OfferTypePL} from "../../utils/constants_offer";
import {locationNameParser} from "../../utils/location_name_parser";
import {translateOfferType} from "../../utils/utils";

export const updateInvestmentDetailMetaData =
    (_1: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: IInvestmentDetailResponse) =>
    async (dispatch: Dispatch) => {
        const investment = prevActionResult;
        const url = `${ghCommonUrl}${appLink.investment.detail(parseInvestmentSlugToObject(investment.slug))}`;

        const robots = (() => {
            if (!investment.is_active) {
                return "noindex, follow";
            }
            return "index, follow";
        })();

        const location = locationNameParser(investment.location.path);
        const description = `Szukasz ${translateOfferType(investment.investment_type, OfferTypeDeclensionWhat)} w ${
            investment.name
        } w ${location}? Sprawdź aktualną ofertę ${investment.developer.name} na gethome.pl! ✅`;

        const title = `${investment.name} - ${capitalizeFirst(translateOfferType(investment.investment_type, OfferTypePL))} na sprzedaż ${location}`;
        dispatch(
            updateMetaData(title, description, route, {
                canonical: url,
                url,
                robots,
                hideSuffix: false
            })
        );
        return true;
    };
