import {matchPath} from "react-router";
import {each, isArray} from "lodash";

import {IRouteFetch} from "./create_app_path_data_fetcher";

interface IMatchedRoute<T> {
    params: any;
    fetch: T;
}

export const matchRoutesEngine = <T>(routes: IRouteFetch<any>[], pathname: string): IMatchedRoute<T>[] => {
    let result: IMatchedRoute<any>[] = [];

    each(routes, (route) => {
        const paths = isArray(route.path) ? route.path : [route.path];
        let match: any = null;
        each(paths, (path) => {
            match = matchPath(pathname, {exact: route.exact !== false, strict: true, path});
            if (match != null) {
                return false; // break
            }
        });
        if (match == null) {
            // path does not match
            return true; // continue to the next one
        }

        const thisFetches = route.fetch ? [{params: match.params, fetch: route.fetch}] : [];
        const deepFetches = route.routes ? matchRoutesEngine(route.routes, pathname) : [];
        result = [...thisFetches, ...deepFetches];

        // if any route has found on the current level then stop search process on this level
        return false; // break
    });

    return result;
};
