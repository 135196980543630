import {UrlTagSlug} from "../url_utils/tags";
import {GenericListingComponent} from "./components/GenericListingComponent";
import {useGenericListingDescription} from "./hooks/useGenericListingDescription";
import {useGenericListingDescriptionParams} from "./hooks/useGenericListingDescriptionParams";

export const GenericListingDescription = (props: {currentTag?: UrlTagSlug}) => {
    const {location, offerType, propertyType, locationObject, listingType, displayGenericListingCondition} = useGenericListingDescription();

    const statistics = useGenericListingDescriptionParams();
    return displayGenericListingCondition ? (
        <>
            <GenericListingComponent
                statistics={statistics}
                dealType={statistics.latestQuery.deal_type}
                propertyType={propertyType}
                location={location}
                offerType={offerType}
                locationObject={locationObject}
                listingType={listingType}
            />
        </>
    ) : null;
};
