import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@web2/button";

interface IProps {
    offersLength: number;
    multileadOffersView: boolean;
    toggleMultileadView: () => void;
}

export const MultileadCTA = (props: IProps) => {
    const isDisabled = props.offersLength === 0;

    return (
        <ApplicationButton
            disabled={isDisabled}
            onClick={(e) => {
                if (!props.multileadOffersView) {
                    e.preventDefault();
                    props.toggleMultileadView();
                }
            }}
            variant={!isDisabled ? "primary" : "bright"}
            type={!props.multileadOffersView ? "button" : "submit"}
        >
            {!props.multileadOffersView ? "Zapisz zmiany" : "Wyślij zapytanie"}
        </ApplicationButton>
    );
};

const ApplicationButton = styled(Button)<{disabled: boolean}>`
    display: flex;
    justify-self: flex-end;
    border-radius: 0;
    max-width: 30rem;
    margin: 0 auto;

    ${(props) =>
        props.disabled &&
        css`
            color: #fff;
        `}
`;
