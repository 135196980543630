import {matchPath} from "react-router";
import {appPath} from "@web2/gh_routes";

import {getCachedUtmData} from "../../../../client/utils/save_utm_data";
import {getAgentFullName} from "../../../agent/utils/utils_agent";
import {applicationAlgolytics} from "../init_algolytics";
import {getTrackedDesktopSiteData, getTrackedMobileSiteData} from "../tracked_site";

export interface IAlgolyticsApplicationSentData {
    isModalOfferOnOfferList?: boolean;
    isMobile: boolean | null;
    agent?: {
        id: string;
        name: string;
        last_name?: string;
    };
    user_email: string;
    user_name: string;
    user_phone?: string;
    user_text: string;
    developer?: string;
    financing_services: boolean;
    new_offers_consent: boolean;
    source: string | null;
    source_section: AlgolyticsSourceSection | null;
    contact: {
        id: string;
        name: string;
    };
    investment?: {
        name: string;
        id: string;
    } | null;
    offer?: {
        id: string;
    };
}

export const applicationSentAlgolyticsHit = (applicationSentData: IAlgolyticsApplicationSentData, currentPath: string) => {
    const viewType = (() => {
        const matchApplicationPath = (path: string) => matchPath(currentPath, {exact: true, strict: true, path});
        if (applicationSentData.isModalOfferOnOfferList) {
            return "modal";
        }
        if (matchApplicationPath(appPath.offer.detail.base)) {
            return "full_offer";
        }
        if (matchApplicationPath(appPath.agency.detail)) {
            return "agency";
        }
        if (matchApplicationPath(appPath.investment.detail)) {
            return "investment";
        }
        if (matchApplicationPath(appPath.searchResult.universal) || matchApplicationPath(appPath.searchResult.universalTag)) {
            return "offer_list";
        }

        return null;
    })();

    const applicationData = {
        agency_id: applicationSentData.contact.id ? applicationSentData.contact.id : null,
        agency_name: applicationSentData.contact.name ? applicationSentData.contact.name : null,
        agent_id: applicationSentData.agent ? applicationSentData.agent.id : null,
        agent_name: applicationSentData.agent ? getAgentFullName(applicationSentData.agent) ?? "na" : null,
        financing_services: applicationSentData.financing_services,
        investment_id: applicationSentData.investment ? applicationSentData.investment.id : null,
        investment_name: applicationSentData.investment ? applicationSentData.investment.name : null,
        new_offers_consent: applicationSentData.new_offers_consent,
        offer_id: applicationSentData.offer ? applicationSentData.offer.id : null,
        source: applicationSentData.source,
        source_section: applicationSentData.source_section,
        user_email: applicationSentData.user_email,
        user_name: applicationSentData.user_name,
        user_phone: applicationSentData.user_phone,
        user_text: applicationSentData.user_text,
        view_type: viewType
    };

    const utmData = getCachedUtmData();

    const data = {
        ...applicationData,
        ...utmData,
        ...(applicationSentData.isMobile ? getTrackedMobileSiteData() : getTrackedDesktopSiteData())
    };

    applicationAlgolytics(data);
};

export enum AlgolyticsSourceSection {
    OFFER_MODAL_BOTTOM = "offer_modal_bottom",
    BUTTON = "button",
    OFFER_MODAL_VERTICAL = "offer_modal_vertical",
    FULL_OFFER_VERTICAL = "full_offer_vertical",
    GALLERY_MODAL_SIDE = "gallery_modal_side",
    OFFER_DESCRIPTION = "offer_description"
}
