import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const TrainIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path
                d="M1.79,9.9a.28.28,0,0,1-.21-.09.33.33,0,0,1,0-.47L2.82,8.1a.33.33,0,0,1,.47.47L2.05,9.81A.35.35,0,0,1,1.79,9.9Z"
                fill={props.secondaryFillColor}
            />

            <path
                d="M8.25,9.9A.29.29,0,0,1,8,9.81L6.8,8.57a.33.33,0,0,1,.47-.47L8.51,9.34a.33.33,0,0,1,0,.47A.31.31,0,0,1,8.25,9.9Z"
                fill={props.secondaryFillColor}
            />

            <path d="M6.37.1H3.67A2.67,2.67,0,0,0,1,2.75V7.46a1.2,1.2,0,0,0,1.2,1.2H7.78A1.2,1.2,0,0,0,9,7.46V2.75A2.61,2.61,0,0,0,6.37.1Zm2,7.36A.57.57,0,0,1,7.82,8H2.26a.57.57,0,0,1-.56-.56v-2A11.64,11.64,0,0,0,5,5.92a10.36,10.36,0,0,0,3.34-.51Zm0-2.74A10.36,10.36,0,0,1,5,5.24,10.22,10.22,0,0,1,1.7,4.72v-2a2,2,0,0,1,2-2H6.41a2,2,0,0,1,2,2v2Z" />

            <path d="M3.72,6.69a.52.52,0,0,1-.52.51.55.55,0,0,1-.51-.51.52.52,0,0,1,1,0Z" fill={props.secondaryFillColor} />

            <path d="M7.65,6.69a.51.51,0,0,1-.51.51.56.56,0,0,1-.56-.51.52.52,0,0,1,.52-.51A.55.55,0,0,1,7.65,6.69Z" fill={props.secondaryFillColor} />
        </SvgIcon>
    );
};
